import React, { useRef, useState } from 'react';
import styles from './KnowledgeDropMenu.module.scss';
import Dropdown from './Dropdown';
import WatchDemoModal from '../../Modals/WatchDemoModal';
import { ReactComponent as KnowledgeHatIcon } from '../../../assets/img/icons/knowledge-hat.svg';
import cs from 'classnames';
import { ReactComponent as RadarIcon } from '../../../assets/img/icons/radar_icon.svg';
import LatestNewsModal from '../../Modals/LatestNewsModal';
import { TellUsModal } from '../../Modals/WidgetModal';
import { ReactComponent as TriangleIcon } from '../../../assets/img/icons/play-white-arrow.svg';

const KnowledgeDropMenu = ({ className, isMobile }) => {
  const containerRef = useRef();
  const [menuActive, setMenuActive] = useState(false);
  const [openWatchDemo, setOpenWatchDemo] = useState(false);
  const [openNews, setOpenNews] = useState(false);
  const [openTellUs, setOpenTellUs] = useState(false);
  const toggle = () => setMenuActive(!menuActive);

  const options = [
    {
      text: 'Knowledge base',
      icon: <KnowledgeHatIcon />,
      onClick: () => {
        window.open('https://help.otterfish.com/knowledge', '_blank');
        toggle();
      },
    },
    // {text: "Watch demo", icon: <PlayIcon />,
    //   onClick: () => {
    //     setOpenWatchDemo(true);
    //     toggle();
    //   }
    // },
    {
      text: 'What’s new',
      icon: <RadarIcon />,
      stroke: true,
      onClick: () => {
        setOpenNews(true);
        toggle();
      },
    },
  ];

  const onHover = () => {
    if (menuActive) return;
    toggle();
  };

  return (
    <div>
      {!isMobile && (
          <div className={cs(styles.container, className)} ref={containerRef} onMouseLeave={toggle}>
              <span className={styles.hoverBox} onMouseEnter={onHover}></span>
              <div className={cs(styles.icon, { [styles.active]: menuActive })} onClick={toggle}>
                  <KnowledgeHatIcon />
              </div>

            {menuActive && (
                <Dropdown
                    containerClassName={styles.dropDownContainer}
                    options={options}
                    containerRef={containerRef}
                    isActive={menuActive}
                    onClose={() => setMenuActive(false)}
                />
            )}
        </div>
      )}

      {isMobile && (
        <li>
          {options?.map((opt, idx) => (
            <div key={opt.text + idx} className={styles.mobileContainer} onClick={opt.onClick}>
              <div className={styles.mobileIcon}>{opt.icon}</div>
              <div className={styles.mobileText}>
                {opt.text}
                <TriangleIcon className={styles.arrow} />
              </div>
            </div>
          ))}
        </li>
      )}

      <LatestNewsModal open={openNews} onClose={() => setOpenNews(false)} handleOpenTellUs={() => setOpenTellUs(true)} />
      <TellUsModal open={openTellUs} onClose={() => setOpenTellUs(false)} />
      <WatchDemoModal open={openWatchDemo} onClose={() => setOpenWatchDemo(false)} />
    </div>
  );
};

export default KnowledgeDropMenu;
