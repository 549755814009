/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import styles from './BillingAddress.module.scss';
import LogoSVG from '../../../../components/IconsSvg/LogoSVG';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputControl from '../../../../expert/components/FormControls/InputControl';
import Dropdown from '../../../../components/FormControls/Dropdown';
import ButtonCustom from '../../../../expert/components/Buttons/ButtonCustom';
import { useHistory, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getCities, getCountries } from '../../../../actions/config.actions';

export default () => {
  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const dispatch = useDispatch();
  const me = useSelector(state => state.auth.user);
  const countries = useSelector(state => state.config.countries.data);
  const cities = useSelector(state => state.config.cities.data);

  const formik = useFormik({
    initialValues: {
      first_name: me.first_name || '',
      last_name: me.last_name || '',
      company_name: currentOrg?.name || '',
      address: '',
      address2: '',
      country: null,
      state: null,
      city: null,
      zipcode: '',
    },
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required('First name is required'),
      last_name: Yup.string().required('Last name is required'),
      company_name: Yup.string(),
      address: Yup.string().required('Address is required'),
      country: Yup.object().nullable().required('Country is required'),
      city: Yup.object().nullable().required('City is required'),
      zipcode: Yup.string().required('Zip code is required'),
    }),
    enableReinitialize: true,
  });

  const history = useHistory();

  useEffect(() => {
    localStorage.removeItem('OTTERFISH_SELECTED_BILLING_ADDRESS');
    localStorage.removeItem('OTTERFISH_CREDIT_CARD_DETAILS');

    dispatch(getCountries());
  }, []);

  useEffect(() => {
    if (formik.values.country) {
      dispatch(getCities(formik.values.country.id));
    }
  }, [formik.values.country]);

  if (!JSON.parse(localStorage.getItem('OTTERFISH_SELECTED_BILLING_PLAN'))) {
    return <Redirect to="/upgrade-plan" />;
  }

  const selectedPlan = JSON.parse(localStorage.getItem('OTTERFISH_SELECTED_BILLING_PLAN'));

  const handleSubmit = e => {
    formik.handleSubmit(e);
    if (formik.isValid) {
      localStorage.setItem('OTTERFISH_SELECTED_BILLING_ADDRESS', JSON.stringify(formik.values));
      history.push('credit-card-details');
    }
  };

  const handleCountryChange = country => {
    if (formik.values.country?.id !== country.id) {
      formik.setFieldValue('country', country);
      formik.setFieldValue('city', null);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <LogoSVG />
        </div>
        <div className={styles.subHeader}>Let's Finish Powering You Up With {selectedPlan?.name} Plan</div>
        <div className={styles.step}>
          Billing address / <span className={styles.gray}>Card details</span>
        </div>
        <div className={styles.formRow}>
          <InputControl
            name="first_name"
            label="First name"
            type="text"
            placeholder="Enter your first name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.first_name}
            errorMessage={formik.touched.first_name ? formik.errors.first_name : null}
            className={styles.input}
          />
          <InputControl
            name="last_name"
            label="Last name"
            type="text"
            placeholder="Enter your last name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.last_name}
            errorMessage={formik.touched.last_name ? formik.errors.last_name : null}
            className={styles.input}
          />
        </div>
        <div className={styles.formRow}>
          <InputControl
            name="company_name"
            label="Company name"
            type="text"
            placeholder="Enter your company name (optional)"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.company_name}
            errorMessage={formik.touched.company_name ? formik.errors.company_name : null}
            className={styles.inputFull}
          />
        </div>
        <div className={styles.formRow}>
          <InputControl
            name="address"
            label="Address"
            type="text"
            placeholder="Enter your billing address"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address}
            errorMessage={formik.touched.address ? formik.errors.address : null}
            className={styles.inputFull}
          />
        </div>
        <div className={styles.formRow}>
          <InputControl
            name="address2"
            label="Address 2"
            type="text"
            placeholder="Address line 2 (optional)"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address2}
            errorMessage={formik.touched.address2 ? formik.errors.address2 : null}
            className={styles.inputFull}
          />
        </div>
        <div className={styles.formRow}>
          <div className={styles.dropdown}>
            <div className={styles.label}>Country</div>
            <Dropdown
              items={countries}
              getId={i => i.id}
              getDisplayValue={i => i.name}
              placeholder="Select country"
              renderItem={i => <div className={styles.option}>{i}</div>}
              selectedItem={formik.values.country}
              onSelect={handleCountryChange}
              onBlur={() => formik.setFieldTouched('country')}
            />
            <div className={styles.errorMessage}>{formik.touched.country ? formik.errors.country : null}</div>
          </div>
          <div className={styles.dropdown}>
            <div className={styles.label}>City</div>
            <Dropdown
              items={cities}
              getId={i => i.id}
              getDisplayValue={i => i.name}
              placeholder="Select city"
              renderItem={i => <div className={styles.option}>{i}</div>}
              selectedItem={formik.values.city}
              onSelect={i => formik.setFieldValue('city', i)}
              onBlur={() => formik.setFieldTouched('city')}
            />
            <div className={styles.errorMessage}>{formik.touched.city ? formik.errors.city : null}</div>
          </div>
        </div>
        <div className={styles.formRow}>
          {/* <div className={styles.dropdown}>
            <div className={styles.label}>State/Region</div>
            <Dropdown
              items={MOCK_DROPDOWN_ITEMS}
              getId={i => i}
              getDisplayValue={i => i}
              placeholder="Select State/Region"
              renderItem={i => <div className={styles.option}>{i}</div>}
              selectedItem={formik.values.state}
              onSelect={i => formik.setFieldValue('state', i)}
            />
          </div> */}
          <InputControl
            name="zipcode"
            label="Zip Code"
            type="text"
            placeholder="00000"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.zipcode}
            errorMessage={formik.touched.zipcode ? formik.errors.zipcode : null}
            className={styles.inputFull}
          />
        </div>
        <div className={styles.navigation}>
          <ButtonCustom onClick={handleSubmit} type="button">
            Continue
          </ButtonCustom>
          <div className={styles.button}>
            <ButtonCustom outline clearBg onClick={() => history.push('/upgrade-plan')}>
              Go back
            </ButtonCustom>
          </div>
        </div>
      </div>
      <div className={styles.footer}>© 2021 Otterfish Limited</div>
    </div>
  );
};
