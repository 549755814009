import React from 'react';
import PropTypes from 'prop-types';
import styles from './Modals.module.scss';
import { CloseIcon } from '../../expert/components/IconsSvg/ReactIcons/ReactIcons';
import { Modal as ReactModal } from 'react-responsive-modal';
import cs from 'classnames';

export const Modal = ({
  title,
  onClose,
  open,
  children,
  maxWidth,
  width,
  loading,
  className,
  closeBtnClassName,
  backgroundColor = '#fff',
  borderRadius,
  customStyles = {},
  ...props
}) => {
  const modalStyles = {
    overlay: {
      backdropFilter: 'blur(3px)',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    modal: {
      background: backgroundColor,
      padding: 0,
      borderRadius: borderRadius || '4px',
      maxWidth: maxWidth || '420px',
      width: width || '100%',
      boxShadow: ' 0 0 20px rgba(0, 0, 0, 0.1)',
    },
    closeButton: {
      display: 'none',
    },
  };

  for (const key of Object.keys(modalStyles)) {
    Object.assign(modalStyles[key], customStyles[key]);
  }

  return (
    <ReactModal center open={open} onClose={onClose} styles={modalStyles}>
      <div className={cs(styles.root, className)}>
        <button data-search="close-btn" onClick={onClose} className={cs(styles.close, closeBtnClassName)} disabled={loading}>
          <CloseIcon size={30} />
        </button>
        {title && (
          <div className={styles.head}>
            <p className={styles.title}>{title}</p>
          </div>
        )}
        {children}
      </div>
    </ReactModal>
  );
};

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export const ModalMedium = ({ maxWidth, subtitle, open, onClose, title, children, classNames, alternate }) => {
  const modalStyles = {
    overlay: {
      backdropFilter: 'blur(3px)',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    modal: {
      background: '#fff',
      padding: 0,
      borderRadius: '5px',
      maxWidth: maxWidth || '1120px',
      width: '100%',
      boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
    },
    closeButton: {
      display: 'none',
    },
  };
  return (
    <ReactModal center open={open} onClose={onClose} styles={modalStyles}>
      <div className={cs(styles.rootMedium, classNames)}>
        <button data-search="close-btn" onClick={onClose} className={cs(styles.close, { [styles.alternate]: alternate })}>
          <CloseIcon size={30} />
        </button>
        {title && !alternate && (
          <div className={styles.head}>
            <p data-name="title" className={styles.title}>
              {title}
            </p>
            {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
          </div>
        )}
        {children}
      </div>
    </ReactModal>
  );
};

ModalMedium.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
};
