import React, { useState } from 'react';
import styles from './ConnectFacebookAccount.module.scss';
import LogoSVG from '../../../components/IconsSvg/LogoSVG';
import Button from '../../../expert/components/Buttons/ButtonCustom';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { ReactComponent as FacebookLogo } from '../../../assets/img/icons/fb_f_logo.svg';
import { MdChevronRight } from 'react-icons/md';
import { useSelector } from 'react-redux';
import LoaderModal from '../../../expert/components/Modal/LoaderModal';
import LogoModal from '../../../components/Modals/LogoModal';
import AcceptFacebookPermissionsModal from '../../../components/Modals/AcceptFacebookPermissionsModal';

export default ({ setUserData }) => {
  const history = useHistory();
  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const fbIntegration = useSelector(state => state.ad_integration.ad_integrations
    .find(item => item.type === 'facebook' && item.organization_id === currentOrg?.organization_id));
  const hasFbIntegrationToken = !!fbIntegration?.params?.ACCESS_TOKEN;
  const loading = useSelector(state => state.ad_integration.isLoading);
  const [openAcceptFacebookPermissionsModal, setOpenAcceptFacebookPermissionsModal] = useState(false);
  const userId = useSelector(state => state.auth.user.id);

  const connectFacebook = () => {
    window.FB.login(
      response => {
        if (response.status === 'unknown') return;
        setUserData(response);
        localStorage.setItem('OTTERFISH_FB_USER_DATA', JSON.stringify(response));
        // window.analytics.track('facebook_connect', {
        //   userId: userId,
        // });
        history.push('/connect-facebook/import-ad-account');
      },
      { scope: 'public_profile, email, pages_show_list, pages_read_engagement, ads_management, business_management' }
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.logo}>
        <LogoSVG />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>Connect Facebook</div>
        <div className={styles.description}>Please connect your Facebook account to continue</div>
        <button
          className={styles.faceBookButton}
          onClick={fbIntegration ? connectFacebook : setOpenAcceptFacebookPermissionsModal}
          disabled={hasFbIntegrationToken || loading}>
          <FacebookLogo />
          Connect Your Facebook
        </button>
        <Button
          clearBg
          outline
          className={styles.alreadyHaveAccount}
          onClick={() => history.push('/')}
        >
          Skip this step
          <MdChevronRight />
        </Button>
      </div>
      <div className={styles.footer}>
        © {dayjs().format('YYYY')} Otterfish Limited
      </div>
      <LoaderModal
        open={loading}
        titleClassName={styles.loadingTitle}
        text='We are checking connected organizations, it may take a few moments'
      />
      <LogoModal
        open={hasFbIntegrationToken && !loading}
        onClose={() => history.push('/')}
        text='Looks like you already connect your Facebook account'
      />
      <AcceptFacebookPermissionsModal
        open={openAcceptFacebookPermissionsModal}
        onClose={()=>setOpenAcceptFacebookPermissionsModal(false)}
        onClickNext={connectFacebook}
      />
    </div>
  );
}
