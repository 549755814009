import { instance } from '../shared/utils/api.config.creator';

export const createBrief = async (organizationID, data) => {
  // convert the data to FormData for uploading logo image as a file
  const formData = new FormData();

  // Notice: should be able to add null value for key (brand_provides_product can be null)
  for (const key in data) {
    formData.append(key, data[key]);
  }

  const response = await instance.post(`/organizations/${organizationID}/campaign-briefs`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response.data;
};

export const updateBrief = async (organizationID, briefID, data) => {
  const formData = new FormData();
  for (const key in data) {
    formData.append(key, data[key]);
  }
  const response = await instance.patch(`/organizations/${organizationID}/campaign-briefs/${briefID}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response.data;
};

export const deleteBriefLogo = async (organizationID, briefID) => {
  const response = await instance.delete(`/organizations/${organizationID}/campaign-briefs/${briefID}/brand-logo`);
  return response.data;
};

export const deleteBrief = async (organizationID, briefID) => {
  const response = await instance.delete(`/organizations/${organizationID}/campaign-briefs/${briefID}`);
  return response.data;
};

export const getBrief = async (organizationID, id) => {
  const response = await instance.get(`/organizations/${organizationID}/campaign-briefs/${id}`);
  return response.data;
};

export const getBriefItem = async (organizationID, briefID, briefItemID) => {
  const response = await instance.get(`/organizations/${organizationID}/campaign-briefs/${briefID}/items/${briefItemID}`);
  return response.data;
};

export const getBriefList = async (organizationID, params) => {
  const response = await instance.get(`/organizations/${organizationID}/campaign-briefs`, { params });
  return response.data;
};

/**
 * Add visual asset to the brief item
 * @param {number} organizationID
 * @param {number} briefID
 * @param {{file: File, description: string}} data
 * @return {Promise<*>}
 */
export const briefAddVisualAsset = async (organizationID, briefID, data) => {
  try {
    const formData = new FormData();
    const description = {};

    for (let i = 0; i < data.length; i++) {
      const asset = data[i];
      const newFileName = i + '_' + asset.file.name;
      formData.append('files', asset.file, newFileName);
      if (asset.description) {
        description[newFileName] = asset.description;
      }
    }

    formData.append('descriptions', JSON.stringify(description));

    return await instance.post(`/organizations/${organizationID}/campaign-briefs/${briefID}/visual-assets`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  } catch (e) {
    return new Error(e);
  }
};

export const getBriefVisualAssets = async (organizationID, briefID) => {
  return await instance.get(`/organizations/${organizationID}/campaign-briefs/${briefID}/visual-assets`);
};

export const deleteBriefVisualAsset = async (organizationID, briefID, assetID) => {
  return await instance.delete(`/organizations/${organizationID}/campaign-briefs/${briefID}/visual-assets/${assetID}`);
};

export const getBriefItems = async (organizationID, briefID, params) => {
  return await instance.get(`/organizations/${organizationID}/campaign-briefs/${briefID}/items`, { params });
};

/**
 * Add brief items to brief
 * @param {number} organizationID
 * @param {number} campaignID
 * @param {{}} data
 * @return {Promise<*>}
 */
export const briefAddItem = async (organizationID, campaignID, data) => {
  const response = await instance.post(`/organizations/${organizationID}/campaign-briefs/${campaignID}/items`, data);
  return response.data;
};

export const briefEditItem = async (organizationID, campaignID, briefID, data) => {
  const response = await instance.patch(`/organizations/${organizationID}/campaign-briefs/${campaignID}/items/${briefID}`, data);
  return response.data;
};

/**
 * Invite member to brief item
 * @param {number} organizationID
 * @param {number} campaignID
 * @param {number} briefItemID
 * @param {{}} data
 * @return {Promise<*>}
 */
export const inviteMemberToBriefItem = async (organizationID, campaignID, briefItemID, data) => {
  return await instance.post(`/organizations/${organizationID}/campaign-briefs/${campaignID}/items/${briefItemID}/invites`, data);
};

export const getBriefOrganization = async (organizationID, briefID, briefItemID) => {
  const response = await instance.get(`/organizations/${organizationID}/campaign-briefs/${briefID}/items/${briefItemID}/collaborators`);
  return response.data;
};

export const getBriefInvitedCollaborators = async (organizationID, briefID, briefItemID) => {
  const response = await instance.get(
    `/organizations/${organizationID}/campaign-briefs/${briefID}/items/${briefItemID}/invites?accepted_at=null`
  );
  return response.data;
};

export const deleteInvitedBriefCollaborator = async (organizationID, briefID, briefItemID, inviteId) => {
  const response = await instance.delete(
    `/organizations/${organizationID}/campaign-briefs/${briefID}/items/${briefItemID}/invites/${inviteId}`
  );
  return response.data;
};

export const getBoardsForCreatorBrief = async (organizationID, creatorBriefID) => {
  return await instance.get(`/organizations/${organizationID}/content-boards?campaign_brief_item_id=${creatorBriefID}`);
};

export const approveBriefItem = async (organizationID, campaignID, briefItemID) => {
  return await instance.post(`/organizations/${organizationID}/campaign-briefs/${campaignID}/items/${briefItemID}/collaborators/approve`);
};

export const archiveBrief = async (organizationId, briefItemID) => {
  const response = await instance.post(`/organizations/${organizationId}/campaign-briefs/${briefItemID}/archive`);
  return response.data;
};

export const unzipBrief = async (organizationId, briefItemID) => {
  const response = await instance.delete(`/organizations/${organizationId}/campaign-briefs/${briefItemID}/archive`);
  return response.data;
};
