import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Discussion.module.scss';
import DiscussionInput from './DiscussionInput';
import { AddIcon } from '../IconsSvg/ReactIcons/ReactIcons';
import ButtonCustom from '../Buttons/ButtonCustom';
import DiscussionRooms from './DiscussionRooms';
import DiscussionCreateModal from './DiscussionCreateModal';
import dayjs from 'dayjs';
import {
  clearDiscussionsState,
  createDiscussionRoom,
  createDiscussionRoomComment,
  getDiscussionRoomComments,
  getDiscussionRoomOrganizations,
  getDiscussionRooms,
  setDiscussionsIsExpanded,
} from '../../../actions/discussion.actions';
import LoadingContainer from '../../../components/LoadingContainer';
import useDiscussionsSockets from './useDiscussionsSockets';
import { ReactComponent as DoubleArrowIcon } from '../../../assets/img/icons/double_arrow.svg';
import { ReactComponent as MessageCount } from '../../../assets/img/icons/message-count.svg';
import cs from 'classnames';
import useTooltip from '../../../components/Menu/components/MenuInner/components/useTooltip';
import DiscussionMessagesContainer from './DiscussionMessagesContainer';
import { useMediaQuery } from 'react-responsive/src';
import MessageIcon from '../IconsSvg/MessageIcon';
import { useRouteMatch } from 'react-router-dom';

/**
 * @param collaborators
 * @param {Number} objectItemID - brief item ID or content board item ID or (depends on itemType)
 * @param {'campaign_brief_item' | 'content_board'} itemType
 */
const Discussion = ({ collaborators, objectItemID, itemType }) => {
  const dispatch = useDispatch();
  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const discussionRooms = useSelector(state => state.discussion.discussionRooms);
  const discussionRoomsLoading = useSelector(state => state.discussion.discussionRoomsLoading);
  const comments = useSelector(state => state.discussion.comments);
  const isExpanded = useSelector(state => state.discussion.isExpanded);
  const commentsLoading = useSelector(state => state.discussion.commentsLoading);
  const collaboratorsRoom = useSelector(state => state.discussion.collaboratorsRoom);
  const [roomIndex, setRoomIndex] = useState(0);
  const [discussionCreateModalState, setDiscussionCreateModalState] = useState(false);
  const [lastCheckMessagesDate, setLastCheckMessagesDate] = useState(dayjs().unix());
  const [newMessagesCount, setNewMessagesCount] = useState(0);
  const user = useSelector(state => state.user.data);
  const isDesktopLandscape = useMediaQuery({
    query: '(min-width: 992px) and (orientation: landscape)',
  });
  const userId = useSelector(state => state.user.data?.id);
  const match = useRouteMatch();
  const creatorBriefId = match.params.creatorBriefID;
  const campaignBriefId = match.params.briefItemID;
  const boardId = match.params.boardId;
  const showMobileSideBar = useSelector(state => state.sidebarMenu.showMobileSideBar);

  const isLimitRoomsCount = useMemo(() => discussionRooms.filter(room => +room.creator_id === user.id).length > 4, [discussionRooms, user]);

  useDiscussionsSockets(roomIndex, { object_id: objectItemID, object_type: itemType });
  const { toolTip, onEnter, onLeave } = useTooltip(isExpanded ? 'Expand' : 'Collapse', 'top', styles.tooltip);

  useEffect(() => {
    return () => {
      dispatch(clearDiscussionsState());
    };
  }, []);

  useEffect(() => {
    if (currentOrganizationID) {
      dispatch(getDiscussionRooms(currentOrganizationID, { object_id: objectItemID, object_type: itemType }));
    }
  }, [currentOrganizationID]);

  useEffect(() => {
    if (discussionRooms.length) {
      setDiscussionRoom(0);
      updateNewMessagesDate();
    }
  }, [discussionRooms]);

  const createRoom = (name, collaboratorIDs) => {
    if (currentOrganizationID) {
      dispatch(
        createDiscussionRoom(
          currentOrganizationID,
          {
            name: name,
            object_id: objectItemID,
            object_type: itemType,
            collaborator_id: [currentOrganizationID, ...collaboratorIDs], // add current organization to the room by default
          },
          creatorBriefId
        )
      );
    }
    setDiscussionCreateModalState(false);
    updateNewMessagesDate();
  };

  const addCommentMessage = (commentMessage, attachments) => {
    const currentRoomId = discussionRooms[roomIndex].id;
    if (currentRoomId) {
      dispatch(createDiscussionRoomComment(currentOrganizationID, currentRoomId, commentMessage, user, attachments));
      window.analytics.track('discussion_reply_sent', {
        category: 'User Communications',
        label: discussionRooms[roomIndex].name,
        userId: userId,
        discussion_room_id: currentRoomId,
        ...(boardId && { content_board_id: boardId }),
        ...(campaignBriefId && !creatorBriefId && { campaign_brief_id: campaignBriefId }),
        ...(campaignBriefId && creatorBriefId && { creator_brief_id: creatorBriefId }),
      });
      updateNewMessagesDate();
    }
  };

  const setDiscussionRoom = index => {
    setRoomIndex(index);
    const initialRoomID = discussionRooms[index].id;
    dispatch(getDiscussionRoomComments(currentOrganizationID, initialRoomID));
    dispatch(getDiscussionRoomOrganizations(currentOrganizationID, initialRoomID));
    updateNewMessagesDate();
  };

  const updateNewMessagesDate = () => {
    setLastCheckMessagesDate(dayjs().unix());
  };

  const { oldMessages, newMessages } = useMemo(() => {
    const reversedComments = [...comments].reverse();

    let { oldMessages, otherPeoplesMessages } = reversedComments.reduce(
      (acc, comment) => {
        if (+comment.creator_id === userId) acc.isOther = false;
        acc.isOther ? acc.otherPeoplesMessages.push(comment) : acc.oldMessages.push(comment);
        return acc;
      },
      { oldMessages: [], otherPeoplesMessages: [], isOther: true }
    );

    oldMessages = oldMessages.reverse();
    otherPeoplesMessages = otherPeoplesMessages.reverse();

    const newMessages = [];
    for (const comment of otherPeoplesMessages) {
      if (dayjs(comment.created_at).unix() > lastCheckMessagesDate) {
        newMessages.push(comment);
        continue;
      }
      oldMessages.push(comment);
    }

    return {
      oldMessages: oldMessages,
      newMessages: newMessages,
    };
  }, [comments, lastCheckMessagesDate, userId]);

  return (
    <div
      className={cs(
        styles.wrapper,
        { [styles.backgroundMobile]: !isExpanded && !isDesktopLandscape },
        { [styles.mobile]: !isDesktopLandscape }
      )}
    >
      <div className={cs(styles.root, { [styles.expanded]: isExpanded })}>
        <div className={styles.discussionHeader} onClick={() => dispatch(setDiscussionsIsExpanded(!isExpanded))}>
          <div className={styles.discussionTitle}>
            Discussion
            {!!newMessagesCount && <span className={styles.notificationCounter}>{newMessagesCount} new</span>}
          </div>
          <div className={styles.buttonsContainer}>
            {currentOrganizationID && !isExpanded && !isLimitRoomsCount && !discussionRoomsLoading && (
              <ButtonCustom
                onClick={event => {
                  event.stopPropagation();
                  setDiscussionCreateModalState(true);
                }}
                Icon={AddIcon}
                parentWidth
                className={styles.createButton}
              />
            )}
            <span className={styles.expandedButtonWrapper} onMouseEnter={onEnter} onMouseLeave={onLeave}>
              <ButtonCustom Icon={DoubleArrowIcon} className={cs(styles.expandButton, { [styles.expanded]: isExpanded })} />
              {toolTip}
            </span>
          </div>
        </div>

        <>
          <div className={styles.roomSelectWrapper}>
            {!discussionRoomsLoading && !!discussionRooms.length && (
              <div className={styles.roomSelectRootContainer}>
                <div className={styles.borderedBox} />
                <div className={styles.roomSelectListContainer}>
                  <div className={styles.roomSelectList}>
                    {discussionRooms.map((room, idx) => (
                      <div
                        key={room.id}
                        className={cs(styles.room, { [styles.active]: roomIndex === idx })}
                        onClick={() => {
                          if (idx === roomIndex) return;
                          setDiscussionRoom(idx);
                        }}
                      >
                        {room.name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {discussionRoomsLoading && (
              <LoadingContainer width={'100%'} height={'52px'} borderRadius={'3px'} margin={'0 10px 0 0'} animationDelay={`0s`} />
            )}
          </div>

          <DiscussionRooms collaborators={collaboratorsRoom} />

          <div className={styles.chatBody}>
            <DiscussionMessagesContainer
              comments={comments}
              commentsLoading={commentsLoading}
              collaboratorsRoom={collaboratorsRoom}
              lastCheckMessagesDate={lastCheckMessagesDate}
              setNewMessagesCount={setNewMessagesCount}
              oldMessages={oldMessages}
              newMessages={newMessages}
            />

            <div className={styles.discussionFooter}>
              <DiscussionInput
                roomIndex={roomIndex}
                onSubmitMessage={addCommentMessage}
                disabled={discussionRoomsLoading || !discussionRooms.length}
                onFocus={updateNewMessagesDate}
              />
            </div>
          </div>
        </>
      </div>
      <DiscussionCreateModal
        createRoom={createRoom}
        modalStatus={discussionCreateModalState}
        onClose={() => setDiscussionCreateModalState(false)}
        availableOrganizations={collaborators}
      />
      {!isDesktopLandscape && !showMobileSideBar && (
        <div className={styles.iconMessage}>
          <MessageIcon onClick={() => dispatch(setDiscussionsIsExpanded(!isExpanded))} />
          {!!newMessages.length && (
            <div className={styles.back}>
              <MessageCount />
              <div className={styles.count}>{newMessages.length}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Discussion;
