import React from 'react';

function SuccessSVG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="98" height="147" fill="none" viewBox="0 0 98 147">
      <circle cx="46.745" cy="77.114" r="41.825" fill="#50E3C2"></circle>
      <circle cx="67.658" cy="112.404" r="13.07" fill="#50E3C2"></circle>
      <circle cx="67.658" cy="141.158" r="5.228" fill="#50E3C2"></circle>
      <circle cx="74.193" cy="5.228" r="5.228" fill="#50E3C2"></circle>
      <circle cx="17.991" cy="43.132" r="5.228" fill="#50E3C2"></circle>
      <circle cx="88.57" cy="129.395" r="9.149" fill="#50E3C2"></circle>
      <circle cx="41.825" cy="72.193" r="40.825" stroke="#4E3B8B" strokeWidth="2"></circle>
      <path
        stroke="#4E3B8B"
        strokeLinecap="round"
        strokeWidth="2"
        d="M70.272 73.193c0 15.159-12.289 27.447-27.448 27.447-15.158 0-27.447-12.288-27.447-27.447"
      ></path>
    </svg>
  );
}

export default SuccessSVG;
