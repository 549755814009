import { instance } from '../shared/utils/api.config.creator';

// ### Discussion Rooms ###
export const createDiscussionRoom = async (organizationID, data) => {
  const response = await instance.post(`/organizations/${organizationID}/discussion-rooms`, data);
  return response.data;
};

export const getDiscussionRooms = async (organizationID, params) => {
  const response = await instance.get(`/organizations/${organizationID}/discussion-rooms`, { params });
  return response.data;
};

// ### Discussion Rooms Organizations ###
/**
 * Add collaborator/organization to the room
 * @param {Number} organizationID
 * @param {Number} discussionRoomID
 * @param {{collaborator_id: Array<Number>}} data
 * @return {Promise<*>}
 */
export const createDiscussionRoomOrganizations = async (organizationID, discussionRoomID, data) => {
  const response = await instance.post(`/organizations/${organizationID}/discussion-rooms/${discussionRoomID}/collaborators`, data);
  return response.data;
};

/**
 * Get discussion room comments
 * @param {Number} organizationID
 * @param {Number} discussionRoomID
 * @return {Promise<*>}
 */
export const getDiscussionRoomOrganizations = async (organizationID, discussionRoomID) => {
  const response = await instance.get(`/organizations/${organizationID}/discussion-rooms/${discussionRoomID}/collaborators`);
  return response.data;
};

// ### Discussion Rooms Comments ###
export const createDiscussionRoomComment = async (organizationID, discussionRoomID, data) => {
  const response = await instance.post(`/organizations/${organizationID}/discussion-rooms/${discussionRoomID}/comments`, data);
  return response.data;
};

export const getDiscussionRoomComments = async (organizationID, discussionRoomID) => {
  const response = await instance.get(`/organizations/${organizationID}/discussion-rooms/${discussionRoomID}/comments`, {
    params: {
      order_type: 'asc',
      page_size: 5000,
    },
  });
  return response.data;
};

// ### Discussion Rooms Comments Attachments ###
/**
 * Add discussion rooms comments attachment
 * @param {Number} organizationID
 * @param {Number} discussionRoomID
 * @param {Number} commentID
 * @param {File} file
 * @return {Promise<*>}
 */
export const addDiscussionRoomCommentAttachment = async (organizationID, discussionRoomID, commentID, file) => {
  const formData = new FormData();
  formData.append('file', file);
  const response = await instance.post(
    `/organizations/${organizationID}/discussion-rooms/${discussionRoomID}/comments/${commentID}/attachments`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );
  return response.data;
};

/**
 * @param {Number} organizationID
 * @param {Number} discussionRoomID
 * @param {Number} commentID
 * @param {Number} attachmentID
 * @return {Promise<*>}
 */
export const getDiscussionRoomCommentAttachment = async (organizationID, discussionRoomID, commentID, attachmentID) => {
  const response = await instance.get(
    `/organizations/${organizationID}/discussion-rooms/${discussionRoomID}/comments/${commentID}/attachments/${attachmentID}`
  );
  return response.data;
};
