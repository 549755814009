import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ForgotPassword.module.scss';
import { clearFailure, resetPasswordRequest } from '../../../actions/auth.actions';
import ForgotPasswordStep1 from './ForgotPasswordStep1';
import ForgotPasswordStep2 from './ForgotPasswordStep2';
import ErrorModal from '../../../expert/components/Modal/ErrorModal';
import LeftPartForgotReset from '../../../components/AuthTwoSideLayout/LeftPartForgotReset';
import AuthTwoSideLayout from '../../../components/AuthTwoSideLayout';

const ForgotPassword = ({}) => {
  const dispatch = useDispatch();
  const failure = useSelector(state => state.auth.failure);
  const [email, setEmail] = useState('');
  const [step, setStep] = useState(0);

  const onSubmitFirstFlow = async value => {
    const response = await dispatch(resetPasswordRequest(value));
    if (response.success) {
      setEmail(value);
      setStep(1);
    }
  };

  const renderSwitch = () => {
    switch (step) {
      case 0:
        return <ForgotPasswordStep1 onSendForm={onSubmitFirstFlow} />;
      case 1:
        return <ForgotPasswordStep2 email={email} />;
      default:
        return null;
    }
  };

  return (
    <AuthTwoSideLayout leftPartContent={<LeftPartForgotReset/>} registration={false}>
      <div className={styles.root}>
        {renderSwitch()}
        <ErrorModal
          open={!!failure.message}
          onClose={() => dispatch(clearFailure())}
          text={failure?.message}
        />
      </div>
    </AuthTwoSideLayout>
  );
};

export default ForgotPassword;
