import React from 'react';
import styles from './Checkbox.module.scss';
import cs from 'classnames';
import { ReactComponent as CheckmarkSVG } from '../../../assets/img/icons/checkmark.svg';

export default React.forwardRef(({ className, onChange = () => {}, checked, ...props }, ref) => {
  return (
    <div className={cs(styles.checkboxContainer, { [styles.checked]: checked }, className)}>
      <input ref={ref} className={styles.checkbox} checked={checked} {...props} type="checkbox" onChange={onChange} />
      <div className={styles.mark}>
        <CheckmarkSVG />
      </div>
    </div>
  );
});
