import React from 'react';
import styles from './ReleaseNewsItem.module.scss';
import { ReactComponent as PurpleFoot } from '../../../../../assets/img/icons/purple_foot.svg';
import dayjs from 'dayjs';


const ReleaseNewsItem = ({data}) => {
  return (
    <div className={styles.newsItem}>
      <div className={styles.itemTitle}>{data?.title}</div>
      <div className={styles.itemDate}>{dayjs(data?.created_at).format('ll')}</div>
      <div className={styles.innovationsList}>

        {data?.content?.items?.map((text, idx) => (
          <div className={styles.innovationItem} key={text + idx}>
            <div className={styles.icon}>
              <PurpleFoot />
            </div>
            <div className={styles.text} dangerouslySetInnerHTML={{__html: text}} />
          </div>
        ))}

      </div>

      {data?.image_list_location && (
        <div className={styles.imgContainer}>
          <img className={styles.img} src={data?.image_list_location} />
        </div>
      )}

      {data?.content?.details && (
        <div className={styles.footerText} dangerouslySetInnerHTML={{__html: data.content.details}} />
      )}
    </div>
  );
};


export default ReleaseNewsItem;
