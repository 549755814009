import React from 'react';
import styles from './Visuals.module.scss';
import SectionWrapper from '../SectionWrapper';
import VisualCard from './VisualCard';
import { useSelector } from 'react-redux';
import LoadingState from './LoadingState';
import SelectCallToAction from './SelectCallToAction';
import VisualsCounter from '../VisualsCounter';
import usePlatformPlacementSettings from '../../hooks/usePlatformPlacementSettings';
import VisualsAccessWrapper from './VisualsAccessWrapper';
import SectionStatusesContainer from '../SectionStatusesContainer';
import { DELIVERABLE_STATUSES } from '../../hooks/useStatusesState';
import DownloadAllButton from '../../../components/DownloadAllButton';

const Visuals = ({
  callToActionValue,
  setCallToAction,
  visualAssetsFlow,
  openAsset,
  deliverableConceptStatus,
  deliverableContentStatus,
  contentStatus,
  onApprove,
  onSave,
}) => {
  const { visualAssets, onCreateVisualAssets, onUpdateVisualAssets, onDeleteVisualAssets } = visualAssetsFlow;
  const { deliverableLoading } = useSelector(state => state.contentApproval);
  const { settings } = usePlatformPlacementSettings();

  const isMultipleVisuals = settings?.visualsMaxCount > 1;
  const maxVisualsCount = settings?.visualsMaxCount || 1;
  const isDisplayAddButton = isMultipleVisuals && visualAssets.length < maxVisualsCount;
  const isDisabledSection = deliverableContentStatus === DELIVERABLE_STATUSES.APPROVED;

  const AddVisualCard = () => {
    if (isDisabledSection) return null;
    return (
      <VisualCard
        isStoryTile={settings?.isStoryTile}
        onCreateVisual={onCreateVisualAssets}
        onDeleteVisual={onDeleteVisualAssets}
        onUpdateVisual={onUpdateVisualAssets}
        fileTypes={settings?.fileTypes}
        hideIcons
      />
    );
  };

  return (
    <SectionWrapper>
      <div className={styles.root}>
        <div className={styles.headerContainer}>
          <div className={styles.headerColumn}>
            <div className={styles.sectionTitle}>Upload Content</div>
            <VisualsCounter
              counterText={isMultipleVisuals ? `you can add up to ${maxVisualsCount} visuals` : null}
              currentCount={visualAssets.length}
              totalCount={maxVisualsCount}
            />
          </div>

          <div className={styles.headerColumn}>
            <SectionStatusesContainer
              status={contentStatus}
              isChanged={visualAssetsFlow.isSectionChanged}
              isVisualExists={visualAssetsFlow.isVisualExists}
              onSave={onSave}
              onApprove={() => onApprove('content')}
              sectionName="Content"
            />
            {!!visualAssets?.length && <DownloadAllButton assets={visualAssets} />}
          </div>
        </div>

        {deliverableLoading ? (
          <LoadingState />
        ) : (
          <>
            <div className={styles.callToActionContainer}>
              <div className={styles.sectionSubTitle}>Choose call to action</div>
              <SelectCallToAction disabled={isDisabledSection} callToActionValue={callToActionValue} setCallToAction={setCallToAction} />
            </div>

            <div className={styles.list}>
              {visualAssets.length ? (
                <>
                  {visualAssets.map(asset => (
                    <VisualCard
                      key={asset.id}
                      isStoryTile={settings?.isStoryTile}
                      asset={asset}
                      fileTypes={settings?.fileTypes}
                      onDeleteVisual={onDeleteVisualAssets}
                      onUpdateVisual={onUpdateVisualAssets}
                      openAsset={openAsset}
                      isDisabledSection={isDisabledSection}
                      lengthItem={visualAssets.length}
                    />
                  ))}
                  {isDisplayAddButton && <AddVisualCard />}
                </>
              ) : (
                <AddVisualCard />
              )}
            </div>
          </>
        )}
      </div>
    </SectionWrapper>
  );
};

export default Visuals;
