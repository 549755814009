import { EXTERNAL_PHOTOS_TYPES } from '../actions/externalPhotos.actions';

const initialState = {
  data: [],
  loading: false,
  failure: {},
  totalCount: 0,
};

export default (state = initialState, { type, payload, totalCount }) => {
  switch (type) {
    case EXTERNAL_PHOTOS_TYPES.GET_EXTERNAL_PHOTOS.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case EXTERNAL_PHOTOS_TYPES.GET_EXTERNAL_PHOTOS.SUCCESS: {
      return {
        ...state,
        data: payload,
        loading: false,
        totalCount: totalCount,
      };
    }
    case EXTERNAL_PHOTOS_TYPES.GET_EXTERNAL_PHOTOS.FAILURE: {
      return {
        ...state,
        failure: payload,
        loading: false,
        totalCount: 0,
      };
    }
    default: {
      return state;
    }
  }
};
