import { instance } from '../shared/utils/api.config.creator';


export const getAllReleases = async () => {
  const response = await instance.get('/releases');
  return response.data;
};

export const getReleaseUpdates = async (releaseId) => {
  const response = await instance.get(`/releases/${releaseId}/updates`);
  return response.data;
};
