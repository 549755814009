import React from 'react';
import styles from './Alert.module.scss';
import cs from 'classnames';
import { CloseSvg } from '../IconsSvg/CloseSvg';

const Alert = ({ type, message, open, onClose }) => {
  let classes = [];

  if (type === 'danger') {
    classes.push(styles.danger);
  }

  return (
    <div className={cs(styles.root, classes.join(', '), open ? styles.open : '')}>
      <p className={styles.message}>{message}</p>
      {onClose ? (
        <button className={styles.close} onClick={onClose}>
          <CloseSvg />
        </button>
      ) : null}
    </div>
  );
};

export default Alert;
