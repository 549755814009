import React from 'react';
import AttachmentImageTile from './AttachmentImageTile';
import DefaultAttachmentTile from './DefaultAttachmentTile';
import AttachmentVideoTile from './AttachmentVideoTile';

const AttachmentTile = props => {
  const { mime_type: mimetype } = props.attachment;

  let attachmentComponent = <DefaultAttachmentTile {...props} />;

  if (mimetype.startsWith('image/')) {
    attachmentComponent = <AttachmentImageTile {...props} />;
  } else if (mimetype.startsWith('video/')) {
    attachmentComponent = <AttachmentVideoTile {...props} />;
  }

  return attachmentComponent;
};

export default AttachmentTile;
