import React from 'react';

function UnlockSVG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" fill="none" viewBox="0 0 10 12">
      <g clipPath="url(#clip0)">
        <path
          fill="#4E3B8B"
          d="M8.929 6H3.393V3.582c0-.928.707-1.7 1.591-1.709.893-.01 1.623.752 1.623 1.688v.375c0 .311.239.562.536.562h.714c.297 0 .536-.25.536-.562v-.375c0-1.97-1.53-3.57-3.404-3.563-1.875.007-3.382 1.629-3.382 3.598v2.402h-.536C.48 6 0 6.503 0 7.124v3.75c0 .621.48 1.125 1.071 1.125H8.93c.591 0 1.071-.504 1.071-1.125v-3.75C10 6.503 9.52 6 8.929 6zM5.893 9.561c0 .518-.4.937-.893.937s-.893-.42-.893-.937V8.437c0-.518.4-.938.893-.938s.893.42.893.938v1.125z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H10V12H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default UnlockSVG;
