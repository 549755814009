import React, { useState } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import ConnectFacebook from './ConnectFacebookAccount';
import ImportAdAccount from './ImportAdAccount';

export default () => {
  const match = useRouteMatch();

  const [fbUserData, setFbUserData] = useState(null);
  const businessAccountState = useState(null);
  const adAccountState = useState(null);

  // response.authResponse.userID

  return (
    <Switch>
      <Route path={`${match.path}`} exact>
        <ConnectFacebook userData={fbUserData} setUserData={setFbUserData} />
      </Route>
      <Route path={`${match.path}/import-ad-account`}>
        <ImportAdAccount userData={fbUserData} businessAccountState={businessAccountState} adAccountState={adAccountState} />
      </Route>
      <Redirect to={`${match.path}`} />
    </Switch>
  );
};
