/* eslint-disable camelcase */
import { instance } from '../shared/utils/api.config.creator';

const STRIPE_ROUTE = '/stripe';

export const createCheckoutSession = async (user_id, pricing_plan_id, success_route, cancel_route) => {
  const response = await instance.post(`${STRIPE_ROUTE}/checkout`, { user_id, pricing_plan_id, success_route, cancel_route });
  return response.data;
};

export const createCustomerPortal = async (user_id, return_route) => {
  const response = await instance.post(`${STRIPE_ROUTE}/customer-portal`, { user_id, return_route });
  return response.data;
};
