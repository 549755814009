import React from 'react';

export const CloseSmallSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" fill="none" viewBox="0 0 7 7">
      <path
        fill="#fff"
        d="M4.457 3.75l2.09 2.11c.156.143.156.286 0 .43l-.488.487c-.144.157-.287.157-.43 0l-2.11-2.09-2.109 2.09c-.143.157-.286.157-.43 0L.492 6.29c-.156-.143-.156-.286 0-.43l2.09-2.109-2.09-2.11c-.156-.143-.156-.286 0-.43L.98.724c.144-.157.287-.157.43 0l2.11 2.09L5.629.723c.143-.157.286-.157.43 0l.488.488c.156.143.156.286 0 .43L4.457 3.75z"
      ></path>
    </svg>
  );
};
