import React, { useEffect, useState } from 'react';
import styles from './HeaderComponent.module.scss';
import SearchBar from '../../../SearchBar';
import SelectDropdown from '../../../../expert/components/FormControls/SelectDropdown';
import SelectInputArrowIcon from '../SelectInputArrowIcon';
import cs from 'classnames';
import CalendarRange from '../../../CalendarRange';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import ButtonCustom from '../../../../expert/components/Buttons/ButtonCustom';
import { ReactComponent as Plus } from '../../../../assets/img/icons/plus.svg';
import { useMediaQuery } from 'react-responsive/src';
import { useSelector } from 'react-redux';
import ClearFilterCross from '../ClearFilterCross';

const HeaderComponent = ({ textsConfig = {}, filterFunctions = {}, statusOptions, filter, switcher }) => {
  const organizationUsers = useSelector(state => state.user.organizationUsers);
  const userId = useSelector(state => state.auth.user.id);
  const [searchValue, setSearchValue] = useState(filter.searchValue);
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  useEffect(() => {
    setSearchValue(filter.searchValue);
  }, [switcher]);

  const clearAllFilter = () => {
    setSearchValue('');
    filterFunctions.clearAllHandler();
  };

  const getShowClearFilters = () => {
    const isDate =
      filter.dateStart &&
      filter.dateEnd &&
      `${dayjs(filter.dateStart).format('DD MMM YYYY')} / ${dayjs(filter.dateEnd).format('DD MMM YYYY')}`;

    return !!searchValue || filter.memberSelectIndex !== -1 || filter.statusSelectIndex !== -1 || !!isDate;
  };

  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          {isMobile && <div className={styles.description}>{textsConfig.title}</div>}
          <div className={styles.row}>
            {isMobile ? (
              <div className={styles.buttonsContainer} onClick={() => textsConfig.createButtonAction(userId) || null}>
                <Link to={textsConfig.createButtonRoute}>
                  <ButtonCustom Icon={Plus} className={styles.createButton}>
                    {textsConfig.createButtonText}
                  </ButtonCustom>
                </Link>
              </div>
            ) : (
              <div className={styles.filters}>
                <div className={styles.filtersBlock}>
                  <div className={styles.searchBar}>
                    <SearchBar
                      searchValue={searchValue}
                      onChangeSearchValue={val => setSearchValue(val)}
                      onClear={() => {
                        setSearchValue('');
                        filterFunctions.onClearSearch();
                      }}
                      onSubmit={() => filterFunctions.onSearch(searchValue)}
                      placeholder={textsConfig.searchPlaceholder}
                      designVersion="V2.3"
                    />
                  </div>
                  <SelectDropdown
                    options={statusOptions}
                    optionKey={opt => opt.key}
                    renderOption={opt => opt.name}
                    selectedId={filter.statusSelectIndex}
                    onSelected={filterFunctions.setStatusIndex}
                    onClear={filterFunctions.clearStatusSelectHandler}
                    placeholderClassName={styles.dropdownPlaceholder}
                    placeholder={textsConfig.statusPlaceholder}
                    withNothing
                    IconCross={ClearFilterCross}
                    IconArrow={SelectInputArrowIcon}
                    className={cs(styles.dropdown, styles.dropdownStatesStyles, {
                      [styles.filledSelectInput]: filter.statusSelectIndex !== -1,
                    })}
                    inputClassName={styles.selectDropdownInput}
                    isHeaderSelect
                  />
                  <SelectDropdown
                    options={organizationUsers}
                    optionKey={user => user.id}
                    renderOption={user => `${user.first_name} ${user.last_name}`}
                    selectedId={filter.memberSelectIndex}
                    onSelected={filterFunctions.setMemberIndex}
                    onClear={filterFunctions.clearMemberSelectHandler}
                    placeholderClassName={styles.dropdownPlaceholder}
                    placeholder={textsConfig.memberPlaceholder}
                    withNothing
                    IconCross={ClearFilterCross}
                    IconArrow={SelectInputArrowIcon}
                    className={cs(styles.dropdown, styles.dropdownStatesStyles, {
                      [styles.filledSelectInput]: filter.memberSelectIndex !== -1,
                    })}
                    inputClassName={styles.selectDropdownInput}
                    isHeaderSelect
                  />
                  <CalendarRange
                    className={cs(styles.dropdownStatesStyles, {
                      [styles.filledSelectInput]: filter.dateStart && filter.dateEnd,
                    })}
                    inputClassName={styles.selectDropdownInput}
                    IconArrow={SelectInputArrowIcon}
                    IconCross={ClearFilterCross}
                    onSubmit={filterFunctions.applyDateFilterHandler}
                    onCancel={filterFunctions.clearDateFilterHandler}
                    buttonText={
                      filter.dateStart &&
                      filter.dateEnd &&
                      `${dayjs(filter.dateStart).format('DD MMM YYYY')} / ${dayjs(filter.dateEnd).format('DD MMM YYYY')}`
                    }
                  />
                  {getShowClearFilters() && (
                    <span className={styles.clearAllFilterButton} onClick={clearAllFilter}>
                      Clear all
                    </span>
                  )}
                </div>
                <div
                  className={styles.buttonsContainer}
                  onClick={() => textsConfig.createButtonAction && textsConfig.createButtonAction(userId)}
                >
                  <Link to={textsConfig.createButtonRoute}>
                    <ButtonCustom Icon={Plus} className={styles.createButton}>
                      {textsConfig.createButtonText}
                    </ButtonCustom>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderComponent;
