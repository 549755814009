import React from 'react';
import styles from './LoadingState.module.scss';
import LoadingContainer from '../../../../../../components/LoadingContainer';

const LoadingState = () => {
  return (
    <div className={styles.root}>
      <LoadingContainer width="100%" height="200px" borderRadius="5px" />
    </div>
  );
};

export default LoadingState;
