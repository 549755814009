import React from 'react';
import styles from './Auth.module.scss';
import Alert from '../../components/Alerts';
import LogoSVG from '../../components/IconsSvg/LogoSVG';

const Auth = ({ failure, onCloseAlert, children }) => {
  return (
    <div className={styles.root}>
      <div className={styles.flex}>
        <div className={styles.col5}>
          <div className={styles.logo}>
            <LogoSVG />
          </div>
        </div>
        <div className={styles.col7}>
          <div className={styles.wrap}>
            <div className={styles.alert}>
              <Alert
                open={failure.status}
                type="danger"
                message={failure.status !== 409 ? failure.message : failure.errors.body[0].message}
                onClose={onCloseAlert}
              />
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
