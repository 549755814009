import React from 'react';
import styles from './BriefSubHeader.module.scss';
import ButtonCustom from '../../../../expert/components/Buttons/ButtonCustom';
// import PdfSVG from '../../../../../expert/components/IconsSvg/PdfSVG';

function BriefSubHeader({ buttonTitle, canSubmit, title, description, onCancel }) {
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.title}>{title || 'Create a brief'}</div>
        <div className={styles.text}>
          {description ||
            'Fill out the template below and share with brands or agencies to collaborate and discuss on your campaign requirements. You can\n' +
              '          share a blank brief if required.'}
        </div>
      </div>
      <div className={styles.navigationButtons}>
        {/* <ButtonCustom outline onClick={onSaveDraft} className={styles.opacityButton}>*/}
        {/*  Save Draft*/}
        {/* </ButtonCustom>*/}
        {/* <ButtonCustom outline onClick={onPreviewPDF} className={styles.opacityButton}>*/}
        {/*  Preview PDF*/}
        {/*  <span>*/}
        {/*    <PdfSVG />*/}
        {/*  </span>*/}
        {/* </ButtonCustom>*/}
        {onCancel && (
          <ButtonCustom outline onClick={onCancel} className={styles.opacityButton}>
            Cancel
          </ButtonCustom>
        )}
        <ButtonCustom disabled={!canSubmit} outline type={'submit'}>
          {buttonTitle}
        </ButtonCustom>
      </div>
    </div>
  );
}

export default BriefSubHeader;
