import React from 'react';

const LampSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
      <g clipPath="url(#clip0_403_12039)">
        <path
          d="M9.62681 22C9.36679 21.8731 9.07806 21.7836 8.8519 21.6114C8.45412 21.3083 8.29913 20.8531 8.26469 20.3686C8.23944 20.007 8.25953 19.6414 8.25953 19.2637H13.7763C13.7516 19.7763 13.7844 20.2969 13.6879 20.7934C13.5702 21.3996 13.138 21.7767 12.5399 21.9506L12.3775 22H9.62681Z"
          fill="#46CFB0"
        />
        <path
          d="M0 10.8276C0.167036 10.4355 0.463798 10.2886 0.885694 10.3069C1.4023 10.3293 1.91891 10.3104 2.43207 10.3127C2.862 10.3127 3.17828 10.6169 3.17139 11.0095C3.16451 11.4021 2.85512 11.684 2.43322 11.6863C1.91661 11.6891 1.40001 11.6696 0.887416 11.6926C0.463798 11.7115 0.168758 11.5617 0 11.1714V10.8276Z"
          fill="#46CFB0"
        />
        <path
          d="M13.7922 17.8666H8.19271C8.16516 17.7604 8.1422 17.6571 8.11235 17.5555C7.93154 16.9454 7.5791 16.4603 7.07914 16.0677C5.81632 15.0827 5.02763 13.7992 4.70389 12.2293C3.98753 8.75828 6.36335 5.27921 9.85676 4.68626C13.2778 4.10479 16.4802 6.17065 17.2425 9.54409C17.8165 12.0806 17.0485 14.2372 15.0825 15.9449C14.5424 16.4138 14.07 16.9138 13.8633 17.6169C13.8341 17.7013 13.8151 17.7817 13.7922 17.8666ZM11.3584 6.8761C10.886 6.86692 10.5605 7.13154 10.5433 7.54023C10.5261 7.94892 10.8475 8.23707 11.3147 8.25085C11.9586 8.27306 12.5696 8.54046 13.0227 8.99832C13.4759 9.45618 13.737 10.0699 13.7526 10.7139C13.7652 11.1502 14.0568 11.4636 14.446 11.4601C14.8478 11.4555 15.1348 11.1295 15.1273 10.6818C15.1043 8.65151 13.3863 6.91686 11.3584 6.8761Z"
          fill="#46CFB0"
        />
        <path
          d="M11.689 1.59466C11.689 1.88166 11.6924 2.16867 11.689 2.45567C11.6832 2.86781 11.3761 3.18007 10.991 3.17203C10.6058 3.164 10.3182 2.85863 10.3154 2.45452C10.3115 1.87478 10.3115 1.29503 10.3154 0.715281C10.3182 0.308309 10.6081 0.00752865 10.991 0.00178857C11.3738 -0.00395151 11.6832 0.301421 11.689 0.715855C11.6924 1.00745 11.689 1.30134 11.689 1.59466Z"
          fill="#46CFB0"
        />
        <path
          d="M20.4138 11.6868C20.1268 11.6868 19.8398 11.6868 19.5528 11.6868C19.1389 11.6817 18.8267 11.3809 18.8301 10.998C18.8336 10.6152 19.1355 10.3201 19.5407 10.3167C20.1277 10.3121 20.7148 10.3121 21.3018 10.3167C21.3931 10.3153 21.4838 10.3321 21.5686 10.3661C21.6533 10.4001 21.7305 10.4506 21.7955 10.5147C21.8606 10.5788 21.9123 10.6552 21.9475 10.7395C21.9828 10.8237 22.0009 10.9142 22.0009 11.0055C22.0009 11.3872 21.6961 11.6811 21.2926 11.6874C21.001 11.6903 20.7071 11.6868 20.4138 11.6868Z"
          fill="#46CFB0"
        />
        <path
          d="M5.67427 4.90731C5.64844 5.25631 5.52962 5.47099 5.27247 5.59612C5.01531 5.72126 4.75586 5.69887 4.55094 5.50658C4.0837 5.06344 3.62813 4.60806 3.18423 4.14044C2.94085 3.88328 2.97758 3.47344 3.22613 3.2249C3.47467 2.97635 3.88394 2.93789 4.14454 3.18127C4.61293 3.62326 5.0664 4.08189 5.51011 4.54913C5.60539 4.65245 5.63639 4.82293 5.67427 4.90731Z"
          fill="#46CFB0"
        />
        <path
          d="M18.9907 3.796C18.9431 3.88612 18.8903 4.06694 18.7755 4.1892C18.3679 4.622 17.9437 5.03873 17.5184 5.45202C17.2251 5.73902 16.8095 5.73271 16.5374 5.45546C16.2653 5.17822 16.2705 4.76665 16.5592 4.47391C16.9706 4.05488 17.3858 3.63968 17.8048 3.22831C17.9011 3.12543 18.0276 3.05573 18.166 3.02923C18.3044 3.00272 18.4476 3.02078 18.5751 3.08079C18.8432 3.20478 18.9689 3.42347 18.9907 3.796Z"
          fill="#46CFB0"
        />
        <path
          d="M17.1142 16.3271C17.2043 16.3742 17.3851 16.4265 17.5074 16.5413C17.9402 16.9482 18.3564 17.373 18.7702 17.7978C19.0572 18.0882 19.0515 18.5084 18.7777 18.7793C18.5039 19.0502 18.0889 19.0502 17.7967 18.7621C17.3758 18.3511 16.9604 17.9359 16.5505 17.5165C16.3336 17.2961 16.2767 17.0332 16.4001 16.7467C16.5184 16.4775 16.7405 16.3518 17.1142 16.3271Z"
          fill="#46CFB0"
        />
        <path
          d="M5.70627 17.0543C5.62361 17.2075 5.56851 17.3866 5.45313 17.5089C5.04558 17.9411 4.62139 18.3584 4.19548 18.7717C4.13202 18.8365 4.05631 18.888 3.97277 18.9233C3.88922 18.9586 3.7995 18.977 3.7088 18.9773C3.61811 18.9776 3.52825 18.9599 3.44445 18.9253C3.36064 18.8906 3.28456 18.8396 3.22062 18.7753C3.15668 18.711 3.10616 18.6346 3.07198 18.5506C3.0378 18.4666 3.02064 18.3766 3.02152 18.2859C3.02239 18.1952 3.04127 18.1056 3.07706 18.0223C3.11285 17.9389 3.16483 17.8636 3.23 17.8005C3.64558 17.3757 4.06652 16.9548 4.49282 16.5377C4.58994 16.4354 4.71701 16.3665 4.85575 16.341C4.99448 16.3156 5.13773 16.3348 5.26486 16.3959C5.50996 16.513 5.67125 16.7644 5.70627 17.0543Z"
          fill="#46CFB0"
        />
      </g>
      <defs>
        <clipPath id="clip0_403_12039">
          <rect width="22.0034" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LampSVG;
