import React from 'react';
import styles from './TabCollaborators.module.scss';
import TextField from '../../../../../components/FormControls/TextField';
import OrganizationTypeDropdown from '../../components/OrganizationTypeDropdown';
import { TYPES_CAMPAIGNS } from '../../../../../shared/constants/constants';

function TabCollaborators({ formik }) {
  return (
    <>
      <div className={styles.tabSubHeading}>
        At any time you can share the master brief with collaborators such as Brand, Talent agency or Media agency. Upon invite they’ll be
        able to view and comment on your brief. You can suggest content creators further down the briefing form.
      </div>
      <div>
        <h3>I am a</h3>
        <OrganizationTypeDropdown formik={formik} fieldName="brandType" error={formik.touched.brandType && formik.errors.brandType} />
      </div>
      <div className={styles.split} />
      <div>
        <h3>I want to collaborate with</h3>
        {[TYPES_CAMPAIGNS.AGENCY, TYPES_CAMPAIGNS.TALENT_MANAGER].includes(formik.values.inviteOrganizationType) && (
          <p>
            <span className={styles.warning}>Warning!</span> If you intend use multiple{' '}
            {formik.values.inviteOrganizationType === TYPES_CAMPAIGNS.AGENCY ? 'Media' : 'Talent'} agencies we would advise to create
            duplicate briefs for each agency. This way you can separate the final details from each brief.{' '}
          </p>
        )}
        <div className={styles.row}>
          <TextField
            name={'inviteEmail'}
            placeholder={'Type Email to invite'}
            className={styles.input}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.inviteEmail?.trim()}
            error={formik.touched.inviteEmail && formik.errors.inviteEmail}
          />
          <OrganizationTypeDropdown
            formik={formik}
            fieldName="inviteOrganizationType"
            error={formik.touched.inviteOrganizationType && formik.errors.inviteOrganizationType}
          />
        </div>
        <div className={styles.info}>You will be able to invite more collaborators when the board is created.</div>
      </div>
    </>
  );
}

export default TabCollaborators;
