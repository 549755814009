import React from 'react';

function SentEmail() {
  return (
    <svg width="126" height="118" viewBox="0 0 126 118" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M68.3196 110.584C92.8225 106.744 109.574 83.7682 105.734 59.2652C101.895 34.7622 78.9184 18.0111 54.4154 21.8507C29.9124 25.6902 13.1614 48.6663 17.0009 73.1693C20.8404 97.6723 43.8166 114.423 68.3196 110.584Z" fill="#50E3C2"/>
  <path d="M54.0099 0L55.9999 4.61L60.6099 6.6L55.9999 8.59L54.0099 13.19L52.0199 8.59L47.4199 6.6L52.0199 4.61L54.0099 0Z" fill="#EA3E4A"/>
  <path d="M119.81 46.3398L121.38 49.9798L125.01 51.5498L121.38 53.1198L119.81 56.7598L118.24 53.1198L114.6 51.5498L118.24 49.9798L119.81 46.3398Z" fill="#EA3E4A"/>
  <path d="M3.84 49.8799L5 52.5699L7.69 53.7299L5 54.8899L3.84 57.5699L2.68 54.8899L0 53.7299L2.68 52.5699L3.84 49.8799Z" fill="#EA3E4A"/>
  <path d="M98.4194 11.5201C100.719 12.1101 102.099 14.4601 101.499 16.7601C100.909 19.0601 98.5594 20.4401 96.2594 19.8401C93.9594 19.2501 92.5794 16.9001 93.1794 14.6001C93.7794 12.3101 96.1194 10.9301 98.4194 11.5201Z" fill="#F7AA50"/>
  <path d="M14.6898 22.9699C14.6898 24.5399 13.4198 25.8099 11.8498 25.8099C10.2798 25.8099 9.00977 24.5399 9.00977 22.9699C9.00977 21.3999 10.2798 20.1299 11.8498 20.1299C13.4098 20.1299 14.6898 21.3999 14.6898 22.9699Z" fill="#F7AA50"/>
  <path d="M31.4102 53.2803V85.1003C31.4102 87.1903 33.1002 88.8803 35.1902 88.8803H88.2402C90.3302 88.8803 92.0202 87.1903 92.0202 85.1003V53.2803H31.4102Z" fill="#D9DDE4"/>
  <path d="M90.6702 50.5804L63.6702 30.8504C62.5102 30.0004 60.9202 30.0004 59.7602 30.8504L32.7702 50.5804C31.9202 51.2004 31.4102 52.2004 31.4102 53.2504V53.2704H92.0202V53.2504C92.0302 52.2004 91.5202 51.2104 90.6702 50.5804Z" fill="#F2F0F7"/>
  <path d="M83.1198 47.9707H40.4498C39.7998 47.9707 39.2598 48.5007 39.2598 49.1607V82.8807C39.2598 83.5307 39.7898 84.0707 40.4498 84.0707H83.1298C83.7798 84.0707 84.3198 83.5407 84.3198 82.8807V49.1507C84.3098 48.5007 83.7798 47.9707 83.1198 47.9707Z" fill="white"/>
  <path d="M46.2094 58.4305H77.4994C77.8294 58.4305 78.0994 58.1605 78.0994 57.8305C78.0994 57.5005 77.8294 57.2305 77.4994 57.2305H46.2094C45.8794 57.2305 45.6094 57.5005 45.6094 57.8305C45.6094 58.1605 45.8794 58.4305 46.2094 58.4305Z" fill="#4E3B8B"/>
  <path d="M77.4994 62.3906H46.2094C45.8794 62.3906 45.6094 62.6606 45.6094 62.9906C45.6094 63.3206 45.8794 63.5906 46.2094 63.5906H77.4994C77.8294 63.5906 78.0994 63.3206 78.0994 62.9906C78.0994 62.6606 77.8294 62.3906 77.4994 62.3906Z" fill="#4E3B8B"/>
  <path d="M77.4994 67.54H46.2094C45.8794 67.54 45.6094 67.81 45.6094 68.14C45.6094 68.47 45.8794 68.74 46.2094 68.74H77.4994C77.8294 68.74 78.0994 68.47 78.0994 68.14C78.0994 67.81 77.8294 67.54 77.4994 67.54Z" fill="#4E3B8B"/>
  <path d="M35.1895 88.8803H88.3095C90.3595 88.8803 92.0295 87.2203 92.0295 85.1603V53.2803L35.1895 88.8803Z" fill="#FAFAFA"/>
  <path d="M31.4102 53.2803V85.1703C31.4102 87.2203 33.0702 88.8903 35.1302 88.8903H88.2502L31.4102 53.2803Z" fill="#F2F0F7"/>
</svg>
  );
}

export default SentEmail;
