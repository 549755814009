/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React  from 'react';
import styles from './TeamMemberCell.module.scss';
import { useMediaQuery } from 'react-responsive/src';
import avatar from '../../../assets/img/no-avatar.svg';
import cs from 'classnames';
import TeamMemberTabletCell from './TeamMemberTabletCell';
import { useHistory } from 'react-router-dom';
import { ReactComponent as TrashGraySVG } from '../../../assets/img/icons/trash_gray.svg';

const TeamMemberCell = ({ member, org, handleRemove }) => {
  const history = useHistory()
  const isHorizontal = useMediaQuery({
    query: '(min-width: 750px)',
  });

  const onRemove = (member) => {
    handleRemove(member);
  };

  let status = {
    color: '',
    text: '',
  };

  switch (member.status_in_organization.toLowerCase()) {
    case 'active':  status = {
      color: 'green',
      text: 'Accepted',
    };
      break;
    case 'pending':  status = {
      color: 'orange',
      text: 'Pending',
    };
      break;
    case 'inactive':  status = {
      color: 'red',
      text: 'Removed',
    };
      break;
  }

  if (Object.keys(member).length > 0 && member.role_in_organization?.toLowerCase() === 'owner') {
    status = {
      color: 'darkgreen',
      text: 'Admin',
    };
  }

  const toMemberPage = () => {
    if (member.status_in_organization.toLowerCase() === 'pending') return;
    history.push(`/team-member/${member.user_id}`)
  }

  if (isHorizontal) return <TeamMemberTabletCell member={member} org={org} handleRemove={handleRemove} />;
  return (
    <div className={styles.wrapper}>
      <div className={styles.flexRow} onClick={toMemberPage}>
        <div className={styles.avatar}>
          <img src={member.photo_location || avatar} alt="avatar" />
        </div>
        <div className={styles.nameWrapper}>
          {member.first_name ?
            <div className={styles.name}>{member.first_name} {member.last_name}</div>
            :
            <div className={styles.name}>{member.email}</div>
          }
          <div className={cs(styles.status, styles[status.color])}>{status.text}</div>
        </div>
      </div>
      {
        org.role?.toLowerCase() === 'owner' &&
        member.role_in_organization?.toLowerCase() !== 'owner' ?
        <div className={styles.iconTrash}>
          <TrashGraySVG onClick={() => onRemove(member)} />
        </div>
          :
        <div className={styles.emptyBlock}></div>
    }
    </div>
  );
};

export default TeamMemberCell;
