import React from 'react';
import SelectDropdown from '../../../../../../expert/components/FormControls/SelectDropdown';
import styles from './SelectCallToAction.module.scss';
import { ReactComponent as IconArrow } from '../../../../../../assets/img/icons/play-white-arrow.svg';

const DELIVERABLE_CALL_TO_ACTIONS = [
  { label: 'Apply Now', value: 'apply_now' },
  { label: 'Book Now', value: 'book_travel' },
  { label: 'Call Now', value: 'call_now' },
  { label: 'Contact Us', value: 'contact_us' },
  { label: 'Download', value: 'download' },
  { label: 'Get Directions', value: 'get_directions' },
  { label: 'Get Quote', value: 'get_quote' },
  { label: 'Get Showtimes', value: 'get_showtimes' },
  { label: 'Learn More', value: 'learn_more' },
  { label: 'Listen Now', value: 'listen_now' },
  { label: 'Order Now', value: 'order_now' },
  { label: 'Request Time', value: 'request_time' },
  { label: 'Save', value: 'save' },
  { label: 'See Menu', value: 'see_menu' },
  { label: 'Send Message', value: 'message_page' },
  { label: 'Send Whatsapp Message', value: 'whatsapp_message' },
  { label: 'Shop Now', value: 'shop_now' },
  { label: 'Sign Up', value: 'sign_up' },
  { label: 'Subscribe', value: 'subscribe' },
  { label: 'Watch More', value: 'watch_more' },
  { label: 'Get Offer', value: 'get_offer' },
  { label: 'Play Game', value: 'play_game' },
];

export const getCallToActionLabelByValue = value => {
  const cta = DELIVERABLE_CALL_TO_ACTIONS.find(cta => cta.value === value);
  if (cta) {
    return cta.label;
  }
  return null;
};

const SelectCallToAction = ({ setCallToAction, callToActionValue, disabled }) => {
  return (
    <SelectDropdown
      withNothing
      className={styles.callToAction}
      inputClassName={styles.callToActionInput}
      activeInputClassName={styles.callToActionInputActive}
      dropdownClassName={styles.callToActionDropdown}
      placeholder={'None'}
      options={DELIVERABLE_CALL_TO_ACTIONS.map((call, idx) => ({ id: idx, name: call.label })) || []}
      selectedId={DELIVERABLE_CALL_TO_ACTIONS.findIndex(cta => cta.value === callToActionValue)}
      onSelected={index => {
        if (index === -1) {
          setCallToAction(null);
        } else {
          setCallToAction(DELIVERABLE_CALL_TO_ACTIONS[index].value);
        }
      }}
      optionKey={option => option.id}
      renderOption={option => option.name}
      IconArrow={CallToActionArrowIcon}
      disabled={disabled}
    />
  );
};

const CallToActionArrowIcon = () => {
  return (
    <div className={styles.arrowIcon}>
      <IconArrow />
    </div>
  );
};

export default SelectCallToAction;
