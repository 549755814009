import React from 'react';
import styles from './CollaboratorsDropdown.module.scss';
import CollaboratorCell from '../CollaboratorCell';
import { useMediaQuery } from 'react-responsive/src';
import { Modal } from '../../../../Modals';

const CollaboratorsDropdown = React.forwardRef(({ collaborators }, ref) => {
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  const ContentCollaborators = () => (
    <div className={styles.listContainer} ref={ref}>
      <div className={styles.title}>Collaborators</div>
      <div className={styles.list}>
        {collaborators.map((collaborator, idx) => (
          <CollaboratorCell key={idx} collaborator={collaborator} />
        ))}
      </div>
    </div>
  );

  if (isMobile)
    return (
      <Modal open={true} onClose={() => {}} className={styles.modal}>
        <ContentCollaborators />
      </Modal>
    );

  return <ContentCollaborators />;
});

export default CollaboratorsDropdown;
