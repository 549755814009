import React from 'react';
import styles from './ToastNotification.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as ErrorIcon } from '../../assets/img/icons/errorInformationIcon.svg';
import { ReactComponent as CrossIcon } from '../../assets/img/icons/close.svg';
import { ReactComponent as CheckedIcon } from '../../assets/img/icons/checked.svg';
import { deleteNotification } from '../../actions/toastNotification.actions';
import cs from 'classnames';
import ButtonCustom from '../../expert/components/Buttons/ButtonCustom';

const ToastNotification = () => {
  const notification = useSelector(state => state.toastNotification.notification);
  const dispatch = useDispatch();

  const onClose = item => {
    dispatch(deleteNotification(item));
  };

  return (
    <div className={styles.root}>
      {notification.map((item, index) => (
        <div key={item + index} className={cs(styles.notificationBlock)}>
          {item.type && (
            <span
              className={cs(styles.icon, { [styles.successIcon]: item.type === 'success' }, { [styles.errorIcon]: item.type === 'error' })}
            >
              {item.type === 'success' && <CheckedIcon />}
              {item.type === 'error' && <ErrorIcon />}
            </span>
          )}
          <div className={styles.content}>
            {item.title && <h3 className={styles.title}>{item.title}</h3>}
            {item.text && <span className={styles.text}>{item.text}</span>}
            <div className={styles.footer}>
              <div className={styles.buttonsContainer}>
                {item.primaryButtonData && (
                  <ButtonCustom
                    clearBg
                    onClick={() => item.primaryButtonData.action(() => onClose(item))}
                    className={cs(styles.btn, styles.primaryButton)}
                  >
                    {item.primaryButtonData.text}
                  </ButtonCustom>
                )}
                {item.secondaryButtonData && (
                  <ButtonCustom
                    outline
                    onClick={() => item.secondaryButtonData.action(() => onClose(item))}
                    className={cs(styles.btn, styles.secondaryButton)}
                  >
                    {item.secondaryButtonData.text}
                  </ButtonCustom>
                )}
              </div>
              {item.dateString && <span className={styles.date}>{item.dateString}</span>}
            </div>
          </div>

          <CrossIcon className={styles.closeIcon} onClick={() => onClose(item)} />
        </div>
      ))}
    </div>
  );
};

export default ToastNotification;
