import React from 'react';
import styles from './VisualBlock.module.scss';
import cs from 'classnames';
import { ReactComponent as EditSVG } from '../../../../assets/img/icons/edit_pen.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/img/icons/trash.svg';
import EditorComponent from '../../../../components/EditorComponent';
import { validateImage } from '../../../../shared/utils/helpers';

function VisualBlock({ imgUrl, description, setDescription, setFileImage, deleteVisualElement, deleteButton, editButton }) {
  const handleUploadVisualElements = function (event) {
    const file = validateImage(event.target.files[0]);
    if (!file) return;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setFileImage(file, reader.result);
    };
  };

  return (
    <div className={styles.root}>
      <div className={styles.visualImgBlock}>
        <img className={styles.visualImg} src={imgUrl} />
        <span className={cs(styles.imgButton, { [styles.edit]: !editButton }, editButton)}>
          <input
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            onChange={handleUploadVisualElements}
            className={styles.editImageInput}
          />
          <EditSVG />
        </span>
        <span
          className={cs(styles.imgButton, { [styles.delete]: !deleteButton }, deleteButton)}
          onClick={() => deleteVisualElement(imgUrl)}
        >
          <TrashIcon />
        </span>
      </div>
      <EditorComponent
        wrapperClassName={styles.editorWrapperClassName}
        editorClassName={styles.editorClassName}
        initialValue={description}
        onChange={text => setDescription(text)}
        placeholder={'Share any description about the image. (Optional)'}
      />
    </div>
  );
}

export default VisualBlock;
