import React from 'react';
import { Modal } from '../../../../../components/Modals';
import Preview from '../Preview';
import styles from './MobilePreview.module.scss'
import TrashSVG from '../../../../../components/IconsSvg/TrashSVG';
import DownloadSVG from '../../../../../expert/components/IconsSvg/DownloadSVG';
import { ReactComponent as IconClose } from '../../../../../assets/img/icons/close.svg';

const ModalContent = ({ asset, assetsPrev, callToActionPrev, onClose, isDisabledDelete, onDownloadAsset, onDelete }) => {
  const initialIndex = assetsPrev.findIndex((item) => item.id === asset?.id);

  return (
    <>
      <Preview
        assets={assetsPrev}
        callToAction={callToActionPrev}
        initialIndex={initialIndex}
      />
      <div className={styles.footerPreview}>
        {
          asset.extension && (
            <div className={styles.btn} onClick={onDownloadAsset}>
              <DownloadSVG/>
              Download
            </div>
          )
        }
        {!isDisabledDelete && (
          <div className={styles.btn} onClick={onDelete}>
            <TrashSVG/>
            Delete
          </div>
        )
        }
      </div>
      <div className={styles.btnClose} onClick={onClose}>
        <IconClose/>
      </div>
    </>
  )
};

const MobilePreview = props => {
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      className={styles.previewModal}
      width={'auto'}
      backgroundColor={'transparent'}
    >
      <ModalContent {...props} />
    </Modal>
  );
};


export default MobilePreview;
