/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styles from './ImportAssetsAudiences.module.scss';
import FacebookSVG from '../../components/IconsSvg/FacebookSVG';
import InstagramSVG from '../../components/IconsSvg/InstagramSVG';
import { useDispatch, useSelector } from 'react-redux';
import { clearFailure as clearFailureAssets, getAssets, getFacebookPixels, importAssets } from '../../actions/assets.actions';
import cs from 'classnames';
import { getAudiences, clearFailure as clearFailureAudiences } from '../../actions/audiences.actions';
import ButtonCustom from '../../expert/components/Buttons/ButtonCustom';
import { useHistory } from 'react-router-dom';
import CirclesLoader from '../../components/CirclesLoader';
import ImportFacebookAssetsModal from '../../components/Modals/ImportFacebookAssetsModal';
import ImportPixelsModal from '../../components/Modals/ImportPixelsModal';
import ImportInstagramAssetsModal from '../../components/Modals/ImportInstagramAssetsModal';
import LoaderModal from '../../expert/components/Modal/LoaderModal';
import SuccessModal from '../../expert/components/Modal/SuccessModal';
import ErrorModal from '../../expert/components/Modal/ErrorModal';
import AuthTwoSideLayout from '../../components/AuthTwoSideLayout';
import LeftPartForgotReset from '../../components/AuthTwoSideLayout/LeftPartForgotReset';
import { ReactComponent as TriangleIcon } from '../../assets/img/icons/triangle.svg';
import { ReactComponent as EditSVG } from '../../assets/img/icons/edit_pen.svg';
import PlusSVG from '../../expert/components/IconsSvg/PlusSVG';
import placeholders from '../../components/Avatar/components/placeholders';
import { useLocation } from 'react-router';
import ImportAudiencesModal from '../../components/Modals/ImportAudiencesModal';

const ImportAssetsAudiences = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const fbAssets = useSelector(state => state.assets.assets.filter(item => item.type === 'page'));
  const igAssets = useSelector(state => state.assets.assets.filter(item => item.type === 'instagram_account'));
  const fbPixel = useSelector(state => state.assets.facebookPixels);
  const entirePixList = useSelector(state => state.assets.entirePixList);
  const audiences = useSelector(state => state.audience.audiences);
  const assetsLoading = useSelector(state => state.assets.loading);
  const audiencesLoading = useSelector(state => state.audience.loading);
  const failureAssets = useSelector(state => state.assets.failure);
  const failureAudiences = useSelector(state => state.audience.failure);
  const [selectPixel, setSelectPixel] = useState([]);
  const [selectedFB, setSelectedFB] = useState([]);
  const [selectedIG, setSelectedIG] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpenAudiences, setIsOpenAudiences] = useState(false);
  const [isOpenPixels, setIsOpenPixels] = useState(false);
  const [isOpenFacebook, setIsOpenFacebook] = useState(false);
  const [isOpenInstagram, setIsOpenInstagram] = useState(false);
  const location = useLocation();

  const onSelectFB = id => {
    setSelectedFB([...selectedFB, id]);
  };

  const onRemoveFB = id => {
    setSelectedFB(selectedFB.filter(item => item !== id));
  };

  const onCancelFB = () => {
    setSelectedFB(fbAssets.map(item => item.external_id));
  };

  const onSelectPixel = id => {
    setSelectPixel([...selectPixel, id]);
  };

  const onRemovePixel = id => {
    setSelectPixel(selectPixel.filter(item => item !== id));
  };

  const onCancelPixel = () => {
    setSelectPixel(fbPixel.map(item => item.external_id));
  };

  const onSelectIG = id => {
    setSelectedIG([...selectedIG, id]);
  };

  const onRemoveIG = id => {
    setSelectedIG(selectedIG.filter(item => item !== id));
  };

  const onCancelIG = () => {
    setSelectedIG(igAssets.map(item => item.external_id));
  };

  useEffect(() => {
    if (!currentOrg) return;
    dispatch(getAssets());
    dispatch(getAudiences());
    dispatch(getFacebookPixels());
  }, [currentOrg]);

  useEffect(() => {
    if (fbAssets && selectedFB.length !== fbAssets.length) setSelectedFB(fbAssets.map(item => item.external_id));
    if (fbPixel && selectPixel.length !== fbPixel.length) setSelectPixel(fbPixel.map(item => item.external_id));
    if (igAssets && selectedIG.length !== igAssets.length) setSelectedIG(igAssets.map(item => item.external_id));
  }, [fbAssets.length, igAssets.length, fbPixel.length]);

  const onImport = async () => {
    setLoading(true);

    const importDataFB = selectedFB.map(item => {
      return { type: 'page', id: item };
    });

    const importDataIG = selectedIG.map(item => {
      return { type: 'instagram_account', id: item };
    });

    const importDataPixel = [];
    const importData = importDataFB.concat(importDataIG).concat(importDataPixel);
    selectPixel.forEach(item => {
      if (!entirePixList.find(currentItem => currentItem.external_id === item)) {
        importData.push({ type: 'pixel', id: item });
      }
    });

    await dispatch(importAssets(importData)).then(() => {
      setIsSuccess(true);
    });

    localStorage.removeItem('OTTERFISH_FB_USER_DATA');
    setLoading(false);
  };

  const onSkip = () => {
    localStorage.removeItem('OTTERFISH_FB_USER_DATA');
    handleRedirectFromModal();
  };

  const getAvatar = index => {
    const PlaceHolder = placeholders[index];
    return <PlaceHolder />;
  };

  const handleRedirectFromModal = () => {
    if (!history?.length) {
      history.push('/');
      return;
    }

    if (location.state?.source === 'smart-ads') {
      history.push('/create-test/creative');
    } else if (location.state?.source === 'platforms-audiences') {
      history.push('/platforms/facebook/pages');
    } else {
      history.goBack();
    }
  };

  const onImportModalClose = () => {
    setIsOpenAudiences(false);
    dispatch(getAudiences());
  };

  return (
    <AuthTwoSideLayout leftPartContent={<LeftPartForgotReset />}>
      {(assetsLoading || audiencesLoading) && !loading && (
        <div className={styles.loader}>
          <CirclesLoader />
        </div>
      )}
      <div className={styles.root}>
        <div className={styles.body}>
          <div className={styles.title}>Import your assets</div>
          <div
            className={cs(styles.button, { [styles.done]: selectedFB.length })}
            onClick={() => {
              if (!selectedFB.length) setIsOpenFacebook(true);
            }}
          >
            {!selectedFB.length ? (
              <>
                <div className={styles.assets}>
                  <div>
                    <FacebookSVG />
                  </div>
                  <span className={cs(styles.added, styles.tablet)}>{selectedFB.length} facebook pages added</span>
                  <span className={cs(styles.added, styles.mobile)}>Facebook pages</span>
                </div>
                <div className={styles.import} onClick={() => setIsOpenFacebook(true)}>
                  <PlusSVG />
                  <span>Import</span>
                </div>
              </>
            ) : (
              <>
                <div className={styles.assets}>
                  <div>
                    <FacebookSVG />
                  </div>
                  <span className={cs(styles.added, styles.tablet)}>{selectedFB.length} facebook pages added</span>
                  <span className={cs(styles.added, styles.mobile)}>{selectedFB.length} FB pages added</span>
                  {[...Array(selectedFB.length).keys()].map(index => {
                    if (index < 3) return getAvatar(index);
                  })}
                  {selectedFB.length > 3 && (
                    <div className={styles.dots}>
                      <div className={styles.dot} />
                      <div className={styles.dot} />
                      <div className={styles.dot} />
                    </div>
                  )}
                </div>
                <div className={styles.edit} onClick={() => setIsOpenFacebook(true)}>
                  <EditSVG />
                  <span>Edit</span>
                </div>
              </>
            )}
          </div>

          <div
            className={cs(styles.button, { [styles.done]: selectPixel.length })}
            onClick={() => {
              if (!selectPixel.length) setIsOpenPixels(true);
            }}
          >
            {!selectPixel.length ? (
              <>
                <div className={styles.assets}>
                  <div>
                    <FacebookSVG />
                  </div>
                  <span className={cs(styles.added, styles.tablet)}>Pixel</span>
                  <span className={cs(styles.added, styles.mobile)}>Pixel</span>
                </div>
                <div className={styles.import} onClick={() => setIsOpenPixels(true)}>
                  <PlusSVG />
                  <span>Import</span>
                </div>
              </>
            ) : (
              <>
                <div className={styles.assets}>
                  <div>
                    <FacebookSVG />
                  </div>
                  <span className={cs(styles.added, styles.tablet)}>{selectPixel.length} Pixel</span>
                  <span className={cs(styles.added, styles.mobile)}>{selectPixel.length} Pixel</span>
                  {[...Array(selectPixel.length).keys()].map(index => {
                    if (index < 3) return getAvatar(index + 3);
                  })}
                  {selectPixel.length > 3 && (
                    <div className={styles.dots}>
                      <div className={styles.dot} />
                      <div className={styles.dot} />
                      <div className={styles.dot} />
                    </div>
                  )}
                </div>
                <div className={styles.edit} onClick={() => setIsOpenPixels(true)}>
                  <EditSVG />
                  <span>Edit</span>
                </div>
              </>
            )}
          </div>

          <div
            className={cs(styles.button, { [styles.done]: audiences.length })}
            onClick={() => {
              if (audiences.length) setIsOpenAudiences(true);
            }}
          >
            {!audiences.length ? (
              <>
                <div className={styles.assets}>
                  <div>
                    <FacebookSVG />
                  </div>
                  <span className={styles.added}>Audiences</span>
                </div>
                <div className={styles.import} onClick={() => setIsOpenAudiences(true)}>
                  <PlusSVG />
                  <span>Import</span>
                </div>
              </>
            ) : (
              <>
                <div className={styles.assets}>
                  <div>
                    <FacebookSVG />
                  </div>
                  <span className={styles.added}>{audiences.length} audiences added</span>
                </div>

                <div className={styles.edit} onClick={() => setIsOpenAudiences(true)}>
                  <EditSVG />
                  <span>Edit</span>
                </div>
              </>
            )}
          </div>

          <div
            className={cs(styles.button, { [styles.done]: selectedIG.length })}
            onClick={() => {
              if (!selectedIG.length) setIsOpenInstagram(true);
            }}
          >
            {!selectedIG.length ? (
              <>
                <div className={styles.assets}>
                  <div>
                    <InstagramSVG />
                  </div>
                  <span className={cs(styles.added, styles.tablet)}>{selectedIG.length} Instagram accounts added</span>
                  <span className={cs(styles.added, styles.mobile)}>Instagram Accounts</span>
                </div>
                <div className={styles.import} onClick={() => setIsOpenInstagram(true)}>
                  <PlusSVG />
                  <span>Import</span>
                </div>
              </>
            ) : (
              <>
                <div className={styles.assets}>
                  <div>
                    <InstagramSVG />
                  </div>
                  <span className={cs(styles.added, styles.tablet)}>{selectedIG.length} Instagram accounts added</span>
                  <span className={cs(styles.added, styles.mobile)}>{selectedIG.length} IG accounts added</span>
                  {[...Array(selectedIG.length).keys()].map(index => {
                    if (index < 3) return getAvatar(index + 6);
                  })}
                  {selectedIG.length > 3 && (
                    <div className={styles.dots}>
                      <div className={styles.dot} />
                      <div className={styles.dot} />
                      <div className={styles.dot} />
                    </div>
                  )}
                </div>
                <div className={styles.edit} onClick={() => setIsOpenInstagram(true)}>
                  <EditSVG />
                  <span>Edit</span>
                </div>
              </>
            )}
          </div>
          <div className={styles.buttons}>
            <ButtonCustom disabled={!selectedFB.length} onClick={onImport}>
              Continue
            </ButtonCustom>
          </div>
          <div className={styles.footer}>
            <ButtonCustom outline onClick={() => history.goBack()}>
              <div className={styles.btn}>
                <TriangleIcon className={styles.leftBtn} /> Go back
              </div>
            </ButtonCustom>
            <ButtonCustom parentWidth outline onClick={onSkip}>
              <div className={styles.btn}>
                Skip this step <TriangleIcon className={styles.rightBtn} />
              </div>
            </ButtonCustom>
          </div>
        </div>
      </div>
      <ImportFacebookAssetsModal
        open={isOpenFacebook}
        onClose={() => setIsOpenFacebook(false)}
        onSelect={onSelectFB}
        onRemove={onRemoveFB}
        selected={selectedFB}
        onCancel={onCancelFB}
      />
      <ImportInstagramAssetsModal
        open={isOpenInstagram}
        onClose={() => setIsOpenInstagram(false)}
        onSelect={onSelectIG}
        onRemove={onRemoveIG}
        selected={selectedIG}
        onCancel={onCancelIG}
        selectedFbPages={selectedFB}
      />
      <ImportAudiencesModal open={isOpenAudiences} onClose={onImportModalClose} />
      <ImportPixelsModal
        open={isOpenPixels}
        onClose={() => setIsOpenPixels(false)}
        onSelect={onSelectPixel}
        onRemove={onRemovePixel}
        selected={selectPixel}
        onCancel={onCancelPixel}
      />
      <LoaderModal open={loading} />
      <SuccessModal
        open={isSuccess && !failureAssets.message && !failureAudiences.message}
        onClose={() => {
          setIsSuccess(false);
          handleRedirectFromModal();
        }}
        text={'Your assets and audiences have been successfully imported'}
      />
      <ErrorModal
        open={!!(failureAssets.message || failureAudiences.message)}
        onClose={() => {
          setIsSuccess(false);
          dispatch(clearFailureAssets());
          dispatch(clearFailureAudiences());
        }}
        text={failureAssets.message || failureAudiences.message}
      />
    </AuthTwoSideLayout>
  );
};

export default ImportAssetsAudiences;
