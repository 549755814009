import React, { useEffect, useState } from 'react';

const useDynamicResize = (ref) => {
  const [dynamicStyles, setDynamicStyles] = useState({});
  const [elementSize, setElementSize] = useState(null);
  const [screen, setScreen] = useState({width: window.innerWidth, height: window.innerHeight});

  const onResize = () => setScreen({width: window.innerWidth, height: window.innerHeight});

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const changeElementSize = () => {
    if (!ref.current || !elementSize) return;

    const {x, y} = ref.current.getBoundingClientRect();
    const screenWidth = screen.width;
    const screenHeight = screen.height;
    const elementWidth = elementSize.width;
    const elementHeight = elementSize.height;
    let xBias = 0, yBias = 0;

    if (elementWidth + x > screenWidth) {
      xBias = screenWidth - (elementWidth + x);
    }
    if (elementHeight + y > screenHeight) {
      yBias = screenHeight - (elementHeight + y);
    }
    setDynamicStyles({
      overflow: xBias || yBias ? 'scroll' : '',
      maxHeight: yBias != 0 ? `${elementHeight + yBias}px` : '',
      maxWidth: xBias != 0 ? `${elementWidth + xBias}px` : ''
    });
  };

  useEffect(() => {
    if (!elementSize && ref.current) {
      setElementSize({
        width: ref.current.clientWidth,
        height: ref.current.clientHeight
      });
    }
    changeElementSize();
  }, [ref, elementSize, screen]);

  return dynamicStyles;
}

export default useDynamicResize;
