import * as externalPhotosServices from '../services/externalPhotos';

export const EXTERNAL_PHOTOS_TYPES = {
  GET_EXTERNAL_PHOTOS: {
    REQUEST: 'GET_EXTERNAL_PHOTOS_REQUEST',
    SUCCESS: 'GET_EXTERNAL_PHOTOS_SUCCESS',
    FAILURE: 'GET_EXTERNAL_PHOTOS_FAILURE',
  },
  TRIGGER_UNSPLASH_DOWNLOAD: {
    REQUEST: 'TRIGGER_UNSPLASH_DOWNLOAD_REQUEST',
    SUCCESS: 'TRIGGER_UNSPLASH_DOWNLOAD_SUCCESS',
    FAILURE: 'TRIGGER_UNSPLASH_DOWNLOAD_FAILURE',
  },
};

export const getExternalPhotos = (organizationId, page, pageSize, searchFilter) => async dispatch => {
  try {
    dispatch({
      type: EXTERNAL_PHOTOS_TYPES.GET_EXTERNAL_PHOTOS.REQUEST,
      payload: {},
    });
    const params = {};
    if (searchFilter) {
      params.search_filter = searchFilter;
    }
    if (page) params.page = page;
    if (pageSize) params.page_size = pageSize;
    params.platform = 'unsplash';

    const response = await externalPhotosServices.getExternalPhotos(params);

    dispatch({
      type: EXTERNAL_PHOTOS_TYPES.GET_EXTERNAL_PHOTOS.SUCCESS,
      payload: response.data,
      totalCount: response.total_count,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: EXTERNAL_PHOTOS_TYPES.GET_EXTERNAL_PHOTOS.FAILURE,
      payload: error.response.data,
    });
  }
};

export const triggerUnsplashDownload = url => async dispatch => {
  try {
    dispatch({
      type: EXTERNAL_PHOTOS_TYPES.TRIGGER_UNSPLASH_DOWNLOAD.REQUEST,
      payload: {},
    });

    const response = await externalPhotosServices.triggerUnsplashDownload(url);

    dispatch({
      type: EXTERNAL_PHOTOS_TYPES.TRIGGER_UNSPLASH_DOWNLOAD.SUCCESS,
      payload: response.data,
      totalCount: response.total_count,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: EXTERNAL_PHOTOS_TYPES.TRIGGER_UNSPLASH_DOWNLOAD.FAILURE,
      payload: error.response.data,
    });
  }
};
