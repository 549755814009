import {ModalMedium as Modal} from '../index';
import styles from './GeneralModalDesignV2.module.scss';
import React from 'react';
import cs from 'classnames';

const ModalContent = (
  {
    children,
    footerButtons,
    headerLeftContent,
    headerRightContent,
    classNames = {},
  }
) => {
  return (
    <div className={cs(styles.root, classNames.root)}>
      <div className={styles.contentWrapper}>
        <header className={cs(styles.header, classNames.header)}>
          <div className={styles.column}>
            {headerLeftContent}
          </div>
          <div className={cs(styles.column)}>
            {headerRightContent}
          </div>
        </header>

        <main className={cs(styles.contentContainer, classNames.main)}>
          {children}
        </main>

        <footer className={cs(styles.footer, classNames.footer)}>
          <div className={styles.footerButtons}>
            {footerButtons}
          </div>
        </footer>
      </div>
    </div>
  );
};

const GeneralModalDesignV2 = props => {
  return (
    <Modal classNames={styles.modalRoot} {...props} maxWidth={1070}>
      <ModalContent {...props} />
    </Modal>
  );
}

export default GeneralModalDesignV2;
