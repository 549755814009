import Centrifuge from 'centrifuge';

export default class AppCentrifuge {
  static instance;

  constructor(onConnectCallback: () => {}, onDisconnectCallback: () => {}) {
    if (typeof AppCentrifuge.instance === 'object') {
      return AppCentrifuge.instance;
    }

    this.centrifuge = new Centrifuge(process.env.REACT_APP_WS_URL);
    this.onConnectCallback = onConnectCallback;
    this.onDisconnectCallback = onDisconnectCallback;
    this.onConnect = this.onConnect.bind(this);
    this.onDisconnect = this.onDisconnect.bind(this);
    AppCentrifuge.instance = this;
    return AppCentrifuge.instance;
  }

  onConnect() {
    // console.log('centrifuge connect');
    this.onConnectCallback();
  }

  onDisconnect() {
    // console.log('centrifuge disconnect');
    this.onDisconnectCallback();
  }

  connect(JWT) {
    // console.log('connect...');
    this.centrifuge.setConnectData({ token: JWT });
    this.centrifuge.on('connect', this.onConnect);
    this.centrifuge.on('disconnect', this.onDisconnect);
    this.centrifuge.connect();
  }

  disconnect() {
    // console.log('disconnect...');
    this.centrifuge.disconnect();
    this.centrifuge.off('connect', this.onConnect);
    this.centrifuge.off('disconnect', this.onDisconnect);
  }
}
