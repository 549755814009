import { USER_TYPES } from '../actions/user.actions';

const initialState = {
  data: [],
  failure: {
    message: '',
    name: '',
    code: '',
  },
  success: {},
  loading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case USER_TYPES.GET_USERS.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case USER_TYPES.GET_USERS.SUCCESS: {
      return {
        ...state,
        data: payload,
        loading: false,
      };
    }
    case USER_TYPES.GET_USERS.FAILURE: {
      return {
        ...state,
        loading: false,
        failure: payload,
      };
    }
    case USER_TYPES.INVITE_MEMBER.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case USER_TYPES.INVITE_MEMBER.SUCCESS: {
      return {
        ...state,
        loading: false,
        success: payload,
      };
    }
    case USER_TYPES.INVITE_MEMBER.FAILURE: {
      return {
        ...state,
        loading: false,
        failure: payload,
      };
    }
    case USER_TYPES.CLEAR_FAILURE: {
      return {
        ...state,
        failure: {},
        success: {},
      };
    }
    default:
      return state;
  }
};
