import React from 'react';

const Lookalike = () => {
  return(
    <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.6403 9.81415V10.3772C18.6403 11.0609 18.0773 11.6239 17.4138 11.6239H10.9188C10.4765 10.5179 9.5917 9.65329 8.50586 9.23102C8.78737 7.7028 10.1145 6.55664 11.7232 6.55664H15.3828C17.1725 6.55664 18.6403 8.02453 18.6403 9.81415Z" fill="#4E3B8B"/>
      <path d="M13.5326 0C15.0609 0 16.2875 1.22659 16.2875 2.75481C16.2875 4.28303 15.0609 5.50962 13.5326 5.50962C12.0044 5.50962 10.7778 4.28303 10.7778 2.75481C10.7778 1.2467 12.0245 0 13.5326 0Z" fill="#4E3B8B"/>
      <path d="M10.2149 13.2102V13.7733C10.2149 14.4569 9.65189 14.9999 8.98832 14.9999H1.2467C0.563027 14.9999 0 14.4368 0 13.7733V13.2102C0 11.4005 1.46789 9.93262 3.27762 9.93262H6.95741C7.50032 9.93262 8.00303 10.0533 8.44541 10.2946C9.02854 10.5962 9.51114 11.0587 9.81276 11.6217C10.0742 12.0842 10.2149 12.6271 10.2149 13.2102Z" fill="#4E3B8B"/>
      <path d="M7.86265 6.15227C7.86265 6.91638 7.56103 7.60006 7.05833 8.10276C6.55562 8.60546 5.87195 8.90708 5.10784 8.90708C4.34373 8.90708 3.66005 8.60546 3.15735 8.10276C2.65465 7.60006 2.35303 6.91638 2.35303 6.15227C2.35303 5.38816 2.65465 4.70449 3.15735 4.20179C3.66005 3.69908 4.34373 3.39746 5.10784 3.39746C6.63606 3.39746 7.86265 4.62406 7.86265 6.15227Z" fill="#4E3B8B"/>
    </svg>
  )
}

export default Lookalike;
