import React from 'react';
import { useSelector } from 'react-redux';
import LoadingContainer from '../../../../../components/LoadingContainer';
import usePlatformPlacementSettings from '../../hooks/usePlatformPlacementSettings';

const Preview = ({ assets, callToAction, initialIndex }) => {
  const { openedDeliverable, deliverableLoading } = useSelector(state => state.contentApproval);
  const { settings } = usePlatformPlacementSettings();
  const PreviewComponent = settings?.PreviewComponent;

  return (
    <>
      {(deliverableLoading || !openedDeliverable) && <LoadingContainer height="480px" borderRadius="5px" />}
      {!deliverableLoading && openedDeliverable && !!settings && <>{<PreviewComponent assets={assets} callToAction={callToAction} initialIndex={initialIndex} />}</>}
    </>
  );
};

export default Preview;
