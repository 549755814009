import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ForgotPassword.module.scss';
import { resetPasswordRequest } from '../../../actions/auth.actions';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import { ReactComponent as LetterSVG} from '../../../assets/img/icons/letter.svg';

const ForgotPasswordStep2 = ({ email }) => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.forgotPasswordLoading);

  const onResend = () => {
    dispatch(resetPasswordRequest(email));
  };

  return (
    <div className={styles.resendEmailForm}>
      <LetterSVG />
      <span className={styles.title}>An email has been sent</span>
      <span className={styles.text}>Check your mail box and click the link when you get it</span>
      <ButtonCustom outline onClick={onResend} clearBg loading={loading}>
        Send Email again
      </ButtonCustom>
    </div>
  );
};

export default ForgotPasswordStep2;
