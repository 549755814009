import React from 'react';
import styles from './Loader.module.scss';
import cs from 'classnames';
import logoUrl from '../../../assets/img/icons/otterfish_smal_logo.svg';

const Loader = ({ className, mainColor, fillColor, accentColor, textContent }) => {
  const first = {
    borderColor: `${mainColor ? mainColor : styles.mainColor}`,
    borderTopColor: `${accentColor ? accentColor : styles.accentColor}`,
    backgroundColor: `${fillColor ? fillColor : 'transparent'}`,
  };
  const global = {
    borderColor: `transparent`,
    borderTopColor: `${accentColor ? accentColor : styles.accentColor}`,
  };
  return (
    <>
      {textContent ? (
        <div className={styles.textContainer}>
          <img src={logoUrl} alt={'Otterfish'} className={styles.logo} />
          <div>{textContent}</div>
        </div>
      ) : (
        <div className={cs(styles.loader, className ? className : null)}>
          <div className={styles.animatedBlock} style={first} />
          <div className={styles.animatedBlock} style={global} />
          <div className={styles.animatedBlock} style={global} />
          <div className={styles.animatedBlock} style={global} />
        </div>
      )}
    </>
  );
};

export default Loader;
