import React from 'react';

function DownloadSVG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        stroke="#4E3B8B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
        d="M8 1v11.2m0 0l5-4.667M8 12.2L3 7.533M13 15H3"
      ></path>
    </svg>
  );
}

export default DownloadSVG;
