import React from 'react';
import styles from './AuthTwoSideLayout.module.scss';
import cs from 'classnames';
import dayjs from 'dayjs';

const  AuthTwoSideLayout = ({ children, leftPartContent, isLaptop }) => (
    <div className={styles.root}>
      <div className={styles.container}>
        {leftPartContent}
        <div className={cs(styles.main)}>
          {children}
          {
            isLaptop &&
            <div className={styles.copyright}>
              © {dayjs().format('YYYY')} Otterfish Limited
            </div>
          }
        </div>
      </div>
    </div>
  );

export default  AuthTwoSideLayout;
