import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './EditCampaignBriefItem.module.scss';
import MainLayout from '../../../components/MainLayout';
import { useFormik } from 'formik';
import BriefSubHeader from '../components/BriefSubHeader';
import { useDispatch } from 'react-redux';
import { editCampaignBriefItem, getBriefItem } from '../../../actions/brief.actions';
import { briefPageType } from '../briefPageType';
import { addFormikValuesFromBrief, briefItemFromFormikToAPI, formikBriefItemInitialValues } from '../formikBriefItemUtils';
import TabBriefDetails from '../components/tabs/TabBriefDetails';
import DocumentSVG from '../../../expert/components/IconsSvg/DocumentSVG';
import BriefTab from '../components/BriefTab';
import BriefFailureModal from '../components/BriefFailureModal';
import BriefPageContentWrapper from '../components/BriefPageContentWrapper';
import * as Yup from 'yup';
import useDeliverablesFormik from '../../../components/DeliverablesPlatforms/hooks/useDeliverablesFormik';

function EditCampaignBriefItem() {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();

  const [isAutofill, setIsAutofill] = useState(false);
  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const currentBriefItem = useSelector(state => state.brief.currentBrief.briefItem);

  const pageType = useMemo(
    () => (match.params.creatorBriefID ? briefPageType.CREATOR_BRIEF_ITEM : briefPageType.CAMPAIGN_BRIEF_ITEM),
    [match.params.creatorBriefID]
  );

  const formik = useFormik({
    initialValues: {
      ...formikBriefItemInitialValues(),
    },
    validationSchema: Yup.object().shape({
      date_end: Yup.date()
        .nullable(true)
        .when('date_start', {
          is: value => value,
          then: Yup.date().nullable(true).required('End date is required'),
        }),
      suggestedCreators: Yup.string().when('showCreators', {
        is: value => value,
        then: Yup.string().required('Suggested Creators is required'),
      }),
      brandProvidesProduct: Yup.string().when('showBrandProvidesProduct', {
        is: value => value,
        then: Yup.string().required('Brand Provides Product is required'),
      }),
      repostingPlatform: Yup.array().when('repostingDuration', {
        is: value => value,
        then: Yup.array().min(1, 'Reposting Platform is required').required('Reposting Platform is required'),
      }),
      boosting_budget: Yup.number().when('showBoosting', {
        is: value => value,
        then: Yup.number().min(1, 'Boosting budget is required').required('Boosting budget is required'),
      }),
      boosting_duration: Yup.number().when('showBoosting', {
        is: value => value,
        then: Yup.number().min(1, 'Boosting duration is required').required('Boosting duration is required'),
      }),
      itemName: Yup.string().trim().max(100, 'Please write less than 100 characters').required('Name is required'),
      exclusivity: Yup.string().when('showExclusivity', {
        is: value => value,
        then: Yup.string().trim().required('Exclusivity is required'),
      }),
    }),
    validateOnChange: true,
    onSubmit: () => {
      submitHandler();
    },
  });

  const deliverablesFormik = useDeliverablesFormik({});

  useEffect(() => {
    if (currentOrganizationID) {
      // if creatorBriefID in url get creator brief, else get campaign brief
      dispatch(
        getBriefItem(currentOrganizationID, match.params.campaignID, match.params.creatorBriefID || match.params.briefItemID, () =>
          history.push('/campaign/')
        )
      );
    }
  }, [currentOrganizationID]);

  useEffect(() => {
    if (currentBriefItem) {
      addFormikValuesFromBrief(formik, deliverablesFormik, currentBriefItem).then(() => {
        setIsAutofill(true);
      });
    }
  }, [currentBriefItem]);

  const submitHandler = () => {
    const editBriefData = briefItemFromFormikToAPI(formik, deliverablesFormik);

    dispatch(
      editCampaignBriefItem(currentOrganizationID, match.params.campaignID, currentBriefItem.id, editBriefData, () => {
        if (match.params.creatorBriefID) {
          history.push(
            `/campaign/${match.params.campaignID}/campaign-brief/${match.params.briefItemID}/creator-brief/${match.params.creatorBriefID}`
          );
        } else {
          history.push(`/campaign/${match.params.campaignID}/campaign-brief/${match.params.briefItemID}`);
        }
      })
    );
  };

  const handleBack = () => {
    if (match.params.creatorBriefID) {
      history.push(
        `/campaign/${match.params.campaignID}/campaign-brief/${match.params.briefItemID}/creator-brief/${match.params.creatorBriefID}`
      );
    } else {
      history.push(`/campaign/${match.params.campaignID}/campaign-brief/${match.params.briefItemID}`);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} onKeyDown={e => e.keyCode === 13 && e.preventDefault()}>
      <BriefFailureModal formik={formik} />
      <MainLayout
        title={pageType == briefPageType.CAMPAIGN_BRIEF_ITEM ? 'Edit Campaign Brief' : 'Edit Creator brief'}
        contentClassName={styles.root}
        coloredHeader
        backButtonAction={handleBack}
      >
        <BriefSubHeader
          title={pageType == briefPageType.CAMPAIGN_BRIEF_ITEM ? 'Edit Brief' : 'Edit Creator Brief'}
          description={pageType == briefPageType.CAMPAIGN_BRIEF_ITEM ? 'Edit Brief' : 'Edit Creator Brief'}
          buttonTitle={'Save'}
          onCancel={handleBack}
          canSubmit={currentOrganizationID && formik.dirty && formik.isValid && deliverablesFormik.isValid && !formik.isSubmitting}
        />
        <BriefPageContentWrapper>
          {isAutofill && currentBriefItem && (
            <BriefTab
              data={{
                title: `${pageType == briefPageType.CAMPAIGN_BRIEF_ITEM ? 'Campaign' : 'Creator'} brief details`,
                content: (
                  <TabBriefDetails
                    formik={formik}
                    deliverablesFormik={deliverablesFormik}
                    disableSuggestedCreators={pageType == briefPageType.CREATOR_BRIEF_ITEM}
                  />
                ),
                icon: <DocumentSVG />,
                display: true,
              }}
            />
          )}
        </BriefPageContentWrapper>
      </MainLayout>
    </form>
  );
}

export default EditCampaignBriefItem;
