import React from 'react';

const SavedSVG = () => {
  return(
    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.01762 13.3915L4.30703 11.1053C4.26167 11.078 4.20723 11.078 4.17094 11.1053L0.496644 13.3915C0.342414 13.4913 0.142822 13.3733 0.142822 13.1919V1.62466C0.142822 1.04403 0.614584 0.572266 1.19521 0.572266H7.31905C7.89968 0.572266 8.37144 1.04403 8.37144 1.62466V13.201C8.37144 13.3824 8.17185 13.4913 8.01762 13.3915Z" fill="#4E3B8B"/>
    </svg>

  )
}

export default SavedSVG;
