import React, { useEffect, useState } from 'react';
import ErrorModal from '../../../expert/components/Modal/ErrorModal';

const AdBlockDetectedModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const detectAdBlock = async () => {
    let adBlockEnabled = false;
    const googleAdUrl = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
    try {
      await fetch(new Request(googleAdUrl)).catch(_ => adBlockEnabled = true);
    } catch (e) {
      adBlockEnabled = true;
    } finally {
      setIsOpen(adBlockEnabled);
    }
  };

  useEffect(() => {
    detectAdBlock();
  }, []);

  return (
    <ErrorModal
      open={isOpen}
      text={'You have adblock enabled. Please turn it off for the application to work correctly.'}
      onClose={() => setIsOpen(false)}
      title={'Detected "AdBlock" extension.'}
    />
  )
}

export default AdBlockDetectedModal;
