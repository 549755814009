import React, { useEffect } from 'react';
import styles from './WidgetModal.module.scss';
import { Modal as ReactModal } from 'react-responsive-modal';
import cs from 'classnames';
import { CloseIcon } from '../../../expert/components/IconsSvg/ReactIcons/ReactIcons';


export const TellUsModal = props => {
  return (
    <WidgetModal
      {...props}
      widgetName={"otterfish-feature-request-"}
      widgetToken={"tt-2fb601"}
    />
  );
};

export const BugReportModal = props => {
  return (
    <WidgetModal
      {...props}
      widgetName={"Bug-Reporting-Survey"}
      widgetToken={"tt-9e4cb7"}
    />
  )
};


const Widget = ({open, widgetName, widgetToken}) => {
  useEffect(() => {
    if (open) {
      setWidgetGetScript();
    }
  }, []);

  const setWidgetGetScript = () => {
    const existingScript = document.getElementById("ss-widget");
    if(existingScript){
      document.body.removeChild(existingScript);
    }
    let scripts, scriptElement, lastScript;
    window.SS_ACCOUNT="otterfish.surveysparrow.com";
    window.SS_WIDGET_TOKEN = widgetToken;
    window.SS_SURVEY_NAME = widgetName;
    let S=function(){S.update(arguments)};
    S.args=[];
    S.update=function(e){S.args.push(e)};
    window.SparrowLauncher=S;
    scripts=document.getElementsByTagName("script");
    lastScript = scripts[scripts.length-1];
    scriptElement=document.createElement("script");
    scriptElement.type="text/javascript";
    scriptElement.async=!0;
    scriptElement.id="ss-widget";
    scriptElement.src=`https://otterfish.surveysparrow.com/widget/${window.SS_WIDGET_TOKEN}`;
    lastScript.parentNode.insertBefore(scriptElement, lastScript);
  }

  return (
    <>
      <div className={styles.widgetContainer}>
        <div id="ss_survey_widget"></div>
      </div>
      <div className={styles.loaderContainer}>
        <div className={styles.loader}></div>
      </div>
    </>
  );
};


function addModalContainer(widgetName) {
  const container = document.createElement('div');
  container.classList.add(widgetName);
  container.style.position = 'fixed';
  container.style.zIndex = 1E10;
  const existing = document.querySelector(`.${widgetName}`);
  if (existing) {
    document.body.removeChild(existing);
  }
  document.body.insertAdjacentElement('afterbegin', container);
}

const WidgetModal = props => {
  const modalStyles = {
    overlay: {
      padding: 0,
      backdropFilter: 'blur(3px)',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    modal: {
      background: 'transparent',
      padding: 0,
      borderRadius: '10px',
      maxWidth: props.maxWidth || '700px',
      width: '100%',
      maxHeight: props.maxHeight || '700px',
      height: '100%',
      boxShadow: ' 0 0 20px rgba(0, 0, 0, 0.1)',
    },
    closeButton: {
      display: 'none',
    },
  };

  useEffect(() => {
    addModalContainer(props.widgetName);
  }, []);

  const modalContainer = document.querySelector(`.${props.widgetName}`);

  return (
    <ReactModal container={modalContainer} center open={props.open} onClose={() => props.onClose()} styles={modalStyles}>
      <div className={cs(styles.root, props.className)}>
        <button onClick={props.onClose} className={styles.close} disabled={props.loading}>
          <CloseIcon size={30} />
        </button>
        {props.open && (
          <Widget {...props} />
        )}
      </div>
    </ReactModal>
  )
};
