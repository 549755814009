import React from 'react';
import cs from 'classnames';
import styles from './SwitcherTab.module.scss';

const SwitcherTab = ({ switcher, value, setSwitcher, count }) => {
  return (
    <div
      className={cs(styles.root, { [styles.active]: switcher === value }, { [styles.disabled]: !count })}
      onClick={() => {
        if (!count) return;
        setSwitcher(value);
      }}
    >
      {value}
      <div className={styles.counter}>{count || 0}</div>
    </div>
  );
};

export default SwitcherTab;
