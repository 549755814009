import React, { useState, useEffect, useRef } from 'react';
import styles from './TeamDetails.module.scss';
import cs from 'classnames';
import EditSVG from '../../../expert/components/IconsSvg/EditSVG';
import Button from '../../../expert/components/Buttons/ButtonCustom';
import TextField from '../../../components/FormControls/TextField';
import { useSelector, useDispatch } from 'react-redux';
import { clearCities, getCities, getCounties, getCountries, getIndustries } from '../../../actions/config.actions';
import { updateOrganization } from '../../../actions/organizations.actions';
import SelectDropdown from '../../../expert/components/FormControls/SelectDropdown';
import TeamDetailsLoading from './TeamDetailsLoading';
import LoaderModal from '../../../expert/components/Modal/LoaderModal';
import PhoneInput from 'react-phone-input-2';
import { isValidPhoneNumber } from 'react-phone-number-input';

export default () => {
  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const dispatch = useDispatch();
  const orgData = useSelector(state => state.organizations.data?.[0]);
  const loadingOrg = useSelector(state => state.organizations.loading);
  const updateLoading = useSelector(state => state.organizations.updateLoading);
  const configData = useSelector(state => state.config);
  const industries = useSelector(state => state.config.industries.data);
  const [editingField, setEditingField] = useState();
  const [editingValue, setEditingValue] = useState();
  const [loading, setLoading] = useState(true);
  const configLoading = useSelector(state => state.config.loading);
  const { country_id, county_id } = orgData || {};
  const componentMounted = useRef(true);
  const isFreePlan = currentOrg?.owner_pricing_plan_id === 1001;
  const validPhoneNumber = isValidPhoneNumber(`+${editingValue}`);

  useEffect(() => {
    loadPageInfo();
    dispatch(getIndustries());
    return () => {
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    loadLocationInfo();
  }, [country_id, county_id]);

  useEffect(() => {
    dispatch(clearCities());
  }, [country_id]);

  const loadLocationInfo = async () => {
    if (configLoading || !orgData) return;
    if (country_id) await dispatch(getCounties(country_id || null));
    if (county_id) await dispatch(getCities(county_id));
  };

  const loadPageInfo = async () => {
    await dispatch(getCountries());
    // if component still mounted
    if (componentMounted.current) {
      await loadLocationInfo();
    }
    // if component still mounted while loadLocationInfo
    if (componentMounted.current) {
      setLoading(false);
    }
  };

  /**
   * @param id {number}
   * @return {string, undefined}
   */
  const getIndustryNameById = id => {
    return industries.find(industry => industry.id == id)?.name;
  };

  const handleEditPopUpOpen = (fieldName, initValue) => {
    setEditingField(fieldName);
    setEditingValue(initValue);
  };

  const handleEditPopupClose = confirmed => {
    if (confirmed) {
      switch (editingField) {
        case 'name':
        case 'contact_email':
        case 'contact_phone_number':
        case 'city_id':
          dispatch(updateOrganization(orgData.id, { [editingField]: editingValue }));
          break;
        case 'county_id':
          dispatch(updateOrganization(orgData.id, { [editingField]: editingValue, city_id: null }));
          break;
        case 'country_id':
          dispatch(updateOrganization(orgData.id, { [editingField]: editingValue, county_id: null, city_id: null }));
          break;
        case 'type_specific_data.industry_id':
          dispatch(
            updateOrganization(orgData.id, {
              type_specific_data: {
                ...orgData.type_specific_data,
                industry_id: editingValue,
              },
            })
          );
          break;
        default:
          break;
      }
    }

    setEditingField(undefined);
    setEditingValue(undefined);
  };

  if (loading || configLoading || !orgData || loadingOrg) return <TeamDetailsLoading value={7} />;

  return (
    <div className={styles.TeamDetails}>
        {/* this field is only for influencer */}
          <InfoItem
            open={editingField === 'name'}
            onEditPopupOpen={() => handleEditPopUpOpen('name', orgData?.name)}
            name="Company name"
            value={orgData?.name}
            onEditPopupClose={handleEditPopupClose}
            editable={currentOrg?.role === 'owner'}
            isBlur={!currentOrg?.owner_has_valid_subscription && !loading && !isFreePlan}
          >
            <TextField value={editingValue || ''} onChange={e => setEditingValue(e.target.value)} />
          </InfoItem>
          <InfoItem
            open={editingField === 'type_specific_data.industry_id'}
            onEditPopupOpen={() =>
              handleEditPopUpOpen('type_specific_data.industry_id', orgData?.type_specific_data?.industry_id)
            }
            name="Company industry"
            value={getIndustryNameById(orgData?.type_specific_data?.industry_id) ?? '-'}
            onEditPopupClose={handleEditPopupClose}
            editable={currentOrg?.role === 'owner'}
            isBlur={!currentOrg?.owner_has_valid_subscription && !loading && !isFreePlan}
          >
            <SelectDropdown
              name="industry_id"
              options={industries}
              placeholder="Industry"
              withNothing
              selectedId={editingValue > 0 ? industries.findIndex(item => item.id === editingValue) : -1}
              onSelected={index => setEditingValue(industries.find((_, idx) => idx === index)?.id || -1)}
              optionKey={industry => industry?.name}
              renderOption={industry => industry?.name}
            />
          </InfoItem>
          <InfoItem
            open={editingField === 'contact_email'}
            onEditPopupOpen={() => handleEditPopUpOpen('contact_email', orgData?.contact_email)}
            name="Contact Email"
            value={orgData?.contact_email}
            onEditPopupClose={handleEditPopupClose}
            editable={currentOrg?.role === 'owner'}
            isBlur={!currentOrg?.owner_has_valid_subscription && !loading && !isFreePlan}
          >
            <TextField value={editingValue || ''} onChange={e => setEditingValue(e.target.value)} />
          </InfoItem>
          <InfoItem
            open={editingField === 'contact_phone_number'}
            onEditPopupOpen={() => handleEditPopUpOpen('contact_phone_number', orgData?.contact_phone_number)}
            name="Contact Phone Number"
            value={orgData?.contact_phone_number}
            onEditPopupClose={handleEditPopupClose}
            editable={currentOrg?.role === 'owner'}
            isBlur={!currentOrg?.owner_has_valid_subscription && !loading && !isFreePlan}
            isValid={validPhoneNumber}
          >
            <PhoneInput
              value={`${editingValue}`}
              onChange={setEditingValue}
              inputClass={styles.inputClass}
              buttonClass={styles.buttonClass}
            />
          </InfoItem>
          <InfoItem
            open={editingField === 'country_id'}
            onEditPopupOpen={() => handleEditPopUpOpen('country_id', orgData?.country_id)}
            name="Country"
            value={configData.countries.data.find(c => c.id === orgData?.country_id)?.name ?? '-'}
            onEditPopupClose={handleEditPopupClose}
            editable={currentOrg?.role === 'owner'}
            isBlur={!currentOrg?.owner_has_valid_subscription && !loading && !isFreePlan}
          >
            <SelectDropdown
              selectedId={configData.countries.data.findIndex(c => c.id === editingValue)}
              options={configData.countries.data}
              onSelected={option => {
                setEditingValue(configData.countries.data[option]?.id);
              }}
              optionKey={option => option?.id}
              renderOption={option => option?.name}
            />
          </InfoItem>
          <InfoItem
            open={editingField === 'county_id'}
            onEditPopupOpen={() => handleEditPopUpOpen('county_id', orgData?.county_id)}
            name="County/State"
            value={configData.counties.data.find(c => c.id === orgData?.county_id)?.name ?? '-'}
            onEditPopupClose={handleEditPopupClose}
            editable={currentOrg?.role === 'owner'}
            isBlur={!currentOrg?.owner_has_valid_subscription && !loading && !isFreePlan}
          >
            <SelectDropdown
              selectedId={configData.counties.data.findIndex(c => c.id === editingValue)}
              options={configData.counties.data}
              onSelected={option => {
                setEditingValue(configData.counties.data[option]?.id);
              }}
              optionKey={option => option?.id}
              renderOption={option => option?.name}
            />
          </InfoItem>
          <InfoItem
            open={editingField === 'city_id'}
            onEditPopupOpen={() => handleEditPopUpOpen('city_id', orgData?.city_id)}
            name="City"
            value={configData.cities.data.find(c => c.id === orgData?.city_id)?.name ?? '-'}
            onEditPopupClose={handleEditPopupClose}
            editable={currentOrg?.role === 'owner'}
            isBlur={!currentOrg?.owner_has_valid_subscription && !loading && !isFreePlan}
          >
            <SelectDropdown
              selectedId={configData.cities.data.findIndex(c => c.id === editingValue)}
              options={configData.cities.data}
              onSelected={option => {
                setEditingValue(configData.cities.data[option]?.id);
              }}
              optionKey={option => option?.id}
              renderOption={option => option?.name}
            />
          </InfoItem>
      <LoaderModal open={updateLoading} />
    </div>
  );
};

const InfoItem = ({ name, value, onEditPopupClose, open, onEditPopupOpen, children, editable, isBlur, isValid }) => {
  const handleEditPopupClose = confirmed => {
    onEditPopupClose(confirmed);
  };

 const isDisabled = typeof isValid === 'boolean' ? !isValid : false;

  return (
    <div className={styles.item}>
      {isBlur && <div className={styles.isBlur} />}
      <div className={styles.header}>
        <div className={styles.name}>{name}</div>
        {editable ? (
          <div className={styles.edit} onClick={onEditPopupOpen}>
            <div className={styles.icon}>
              <EditSVG />
            </div>
            <div className={styles.label}>Edit</div>
          </div>
        ) : null}
      </div>
      <div className={styles.value}>{value}</div>
      {editable ? (
        <div className={cs(styles.popup, { [styles.hidden]: !open })}>
          <label>{name}</label>
          <div className={styles.inputContainer}>{children}</div>
          <div className={styles.buttonsContainer}>
            <Button outline onClick={() => handleEditPopupClose(false)}>
              Cancel
            </Button>
            <Button onClick={() => handleEditPopupClose(true)} disabled={isDisabled}>Save</Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};
