import React from 'react';
import styles from './CollaboratorsAvatar.module.scss';

const CollaboratorsAvatar = ({ name, imageUrl, width, height }) => {
  name = name.split(' ');
  if (name.length === 1) {
    name = name[0].substring(0, 1);
  } else {
    name = name[0].substring(0, 1) + name[1].substring(0, 1);
  }

  return (
    <>
      {imageUrl && name && (
        <img
          style={{ width: width + 'px', height: height + 'px', minWidth: width + 'px' }}
          className={styles.collaborator}
          src={imageUrl}
          alt={'collaborator'}
        />
      )}
      {!imageUrl && name && (
        <span
          style={{ width: width + 'px', height: height + 'px', minWidth: width + 'px', fontSize: height / 2 }}
          className={styles.collaborator}
        >
          {name}
        </span>
      )}
    </>
  );
};

export default CollaboratorsAvatar;
