import React from 'react';
import styles from '../Statuses.module.scss';
import { ReactComponent as CheckedIcon } from '../../../../../../../assets/img/icons/checked_.svg';
import cs from 'classnames';

const ApprovedStatusTile = ({ outline, text = 'Approved', disableCheckIcon }) => {
  return (
    <div className={cs(styles.approvedStatusTile, { [styles.outline]: outline })}>
      <span>{text}</span>
      {!disableCheckIcon && <CheckedIcon />}
    </div>
  );
};

export default ApprovedStatusTile;
