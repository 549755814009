import React, { useEffect, useMemo } from 'react';
import styles from './ContentList.module.scss';
import cs from 'classnames';
import Pagination from '../../../../../components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import BoardsList from '../BoardsList';
import { useMediaQuery } from 'react-responsive/src';
import { setContentApprovalTab } from '../../../../../actions/contentApproval.actions';
import SwitcherTab from '../../../../../components/BriefsAndBoardsFlows/RootListPagesComponents/SwitcherTab';

const ContentList = ({ page, setPage, pageSize, sort, setSort, onArchive, isNoSearchFiltersYet }) => {
  const dispatch = useDispatch();
  const { contentApprovalTabSwitcher, boards, boardsLoading, boardsTotalCount, archivedCount } = useSelector(
    state => state.contentApproval
  );
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  const switcherValues = useMemo(
    () => [
      { value: 'boards', count: boardsTotalCount },
      { value: 'archived', count: archivedCount },
    ],
    [boardsTotalCount, archivedCount]
  );

  const paginationTotalCount = useMemo(() => {
    return switcherValues.find(item => item.value === contentApprovalTabSwitcher).count;
  }, [contentApprovalTabSwitcher, switcherValues]);

  const handleSwitcherChange = value => {
    if (value === contentApprovalTabSwitcher) return;
    dispatch(setContentApprovalTab(value));
  };

  const handleArchive = (board, action) => {
    if (contentApprovalTabSwitcher === 'archived' && action === 'unzip' && boards.length === 1) {
      handleSwitcherChange('boards');
      return;
    } else if (contentApprovalTabSwitcher === 'boards' && action === 'archive' && boards.length === 1) {
      handleSwitcherChange('archived');
      return;
    }
    onArchive(board, action);
  };

  useEffect(() => {
    if (boards.length && boards[0].is_archived && contentApprovalTabSwitcher === 'boards' && isNoSearchFiltersYet) {
      dispatch(setContentApprovalTab('archived'));
    }
  }, [boards]);

  return (
    <div className={cs(styles.content, { [styles.contentMobile]: isTablet })}>
      <div className={styles.switch}>
        {switcherValues.map(item => (
          <SwitcherTab
            key={item.value}
            switcher={contentApprovalTabSwitcher}
            setSwitcher={handleSwitcherChange}
            value={item.value}
            count={item.count}
          />
        ))}
      </div>
      <div className={styles.cellsWrapper}>
        <BoardsList
          boards={boards}
          boardsLoading={boardsLoading}
          sort={sort}
          setSort={setSort}
          isTablet={isTablet}
          onArchive={handleArchive}
        />
      </div>
      {!!boards.length && paginationTotalCount > pageSize && (
        <Pagination
          className={styles.pagination}
          amount={pageSize}
          totalCount={paginationTotalCount}
          page={page}
          setPage={id => setPage(id)}
        />
      )}
    </div>
  );
};

export default ContentList;
