import React from 'react';
import useTooltip from '../useTooltip';
import styles from './ReportButton.module.scss';
import cs from 'classnames';
import { ReactComponent as TriangleIcon } from '../../../../../../assets/img/icons/play-white-arrow.svg';

const ReportButton = ({ onClick, icons, isMinimized }) => {
  const { toolTip, onEnter, onLeave } = useTooltip('Report a Bug', 'right', styles.tooltip);
  const Icon1 = icons.icon1.icon;

  return (
    <li
      className={cs(styles.dropdownButton, { [styles.minimized]: isMinimized })}
      onClick={onClick}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
    >
      <div className={styles.contentContainer}>
        <span className={styles.icon}>
          <Icon1 className={styles[icons.icon1.stylesKey]} />
        </span>
        <span className={styles.text}>
          Report a Bug
          <TriangleIcon className={styles.arrow} />
        </span>
      </div>
      {toolTip}
    </li>
  );
};

export default ReportButton;
