/* eslint-disable camelcase */
import React from 'react';
import styles from './ExpiredBriefInviteModal.module.scss';
import { Modal } from '..';
import Button from '../../../expert/components/Buttons/ButtonCustom';
import { ReactComponent as Expired } from '../../../assets/img/icons/expired.svg';

const ExpiredBriefInviteModal = ({ onSubmit, inviteDetails }) => {
  return (
    <div className={styles.root}>
      <Expired />
      <div className={styles.title}>Invite expired</div>
      <div className={styles.details}>
        Your invitation to {inviteDetails?.object_type === 'campaign_brief_item' ? 'campaign brief' : 'content board'} <span>“{inviteDetails?.object_name}”</span> expired. Please contact the below person to get a new invite
      </div>
      <div className={styles.inviteDetails}>
        <div className={styles.avatar}>
          {inviteDetails?.inviter_first_name.charAt(0)}
          {inviteDetails?.inviter_last_name.charAt(0)}
        </div>
        <div className={styles.name}>
          {inviteDetails?.inviter_first_name} {inviteDetails?.inviter_last_name}
        </div>
        <div className={styles.organization}>{inviteDetails?.organization_name}</div>
        <div className={styles.description}>has invited you to collaborate on</div>
        <div className={styles.briefName}>
          nume brief
          {inviteDetails?.object_name
            ? `"${inviteDetails?.object_name}"`
            : `a ${inviteDetails?.object_type === 'campaign_brief_item' ? 'campaign brief' : 'content board'}`}
        </div>
      </div>

      <div className={styles.navigationBtns}>
        <Button className={styles.btn} onClick={() => onSubmit()} outline>
          Close
        </Button>
      </div>
    </div>
  );
};

export default props => {
  return (
    <Modal {...props} maxWidth={510}>
      <ExpiredBriefInviteModal {...props} />
    </Modal>
  );
};
