import React from 'react';
import styles from './LoaderModal.module.scss';
import Loader from '../../Loader';
import { ModalSmall as Modal } from '..';
import cs from 'classnames';

export const LoaderModalContent = ({ text, title, titleClassName }) => {
  return (
    <>
      <Loader />
      <p className={cs(styles.title, titleClassName)}>{title || 'Need a few seconds...'}</p>
      <p className={styles.text}>{text}</p>
    </>
  );
};

const LoaderModal = props => {
  return (
    <Modal {...props} onClose={() => {}} closable={false}>
      <LoaderModalContent {...props} />
    </Modal>
  );
};

export default LoaderModal;
