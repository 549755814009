import { instance, instanceWithForm } from '../shared/utils/api.config.creator';

export const getContentBoards = async (organizationId, params) => {
  const response = await instance.get(`/organizations/${organizationId}/content-boards`, { params });
  return response.data;
};

export const getContentBoard = async (organizationId, boardId) => {
  const response = await instance.get(`/organizations/${organizationId}/content-boards/${boardId}`);
  return response.data;
};

export const archiveBoard = async (organizationId, boardId) => {
  const response = await instance.post(`/organizations/${organizationId}/content-boards/${boardId}/archive`);
  return response.data;
};

export const unzipBoard = async (organizationId, boardId) => {
  const response = await instance.delete(`/organizations/${organizationId}/content-boards/${boardId}/archive`);
  return response.data;
};

export const getBoardCollaborators = async (organizationId, boardId, params) => {
  const response = await instance.get(`/organizations/${organizationId}/content-boards/${boardId}/collaborators`, { params });
  return response.data;
};

export const deleteBoardCollaborator = async (organizationId, boardId, collaboratorOrganizationId) => {
  const response = await instance.delete(
    `/organizations/${organizationId}/content-boards/${boardId}/collaborators/${collaboratorOrganizationId}`
  );
  return response.data;
};

export const deleteInvitedBoardCollaborator = async (organizationId, boardId, invitedId) => {
  const response = await instance.delete(`/organizations/${organizationId}/content-boards/${boardId}/invites/${invitedId}`);
  return response.data;
};

export const inviteBoardCollaborator = async (organizationId, boardId, inviteData) => {
  const response = await instance.post(`/organizations/${organizationId}/content-boards/${boardId}/invites`, inviteData);
  return response.data;
};

export const getInvitedBoardCollaborators = async (organizationId, boardId) => {
  const response = await instance.get(`/organizations/${organizationId}/content-boards/${boardId}/invites?accepted_at=null`);
  return response.data;
};

export const createBoardDeliverable = async (organizationId, boardId, deliverableData) => {
  const response = await instance.post(`/organizations/${organizationId}/content-boards/${boardId}/deliverables`, deliverableData);
  return response.data;
};

export const getBoardDeliverables = async (organizationId, boardId, params) => {
  const response = await instance.get(`/organizations/${organizationId}/content-boards/${boardId}/deliverables`, { params });
  return response.data;
};

export const getBoardDeliverableItem = async (organizationId, boardId, deliverableId) => {
  const response = await instance.get(`/organizations/${organizationId}/content-boards/${boardId}/deliverables/${deliverableId}`);
  return response.data;
};

export const updateDeliverableItem = async (organizationId, boardId, deliverableId, data) => {
  try {
    const response = await instance.patch(`/organizations/${organizationId}/content-boards/${boardId}/deliverables/${deliverableId}`, data);
    return response.data;
  } catch (e) {
    return new Error(e);
  }
};

export const updateContentBoard = async (organizationId, boardId, data) => {
  try {
    const response = await instance.patch(`/organizations/${organizationId}/content-boards/${boardId}`, data);
    return response.data;
  } catch (e) {
    return new Error(e);
  }
};

// CRUD assets
export const updateDeliverableItemAsset = async (organizationId, boardId, deliverableId, assetId, data) => {
  try {
    const formData = new FormData();
    formData.append('caption', data.caption || ' ');
    const response = await instanceWithForm.patch(
      `/organizations/${organizationId}/content-boards/${boardId}/deliverables/${deliverableId}/assets/${assetId}`,
      formData
    );
    return response.data;
  } catch (e) {
    return new Error(e);
  }
};

export const deleteDeliverableItemAsset = async (organizationId, boardId, deliverableId, assetId) => {
  try {
    const response = await instance.delete(
      `/organizations/${organizationId}/content-boards/${boardId}/deliverables/${deliverableId}/assets/${assetId}`
    );
    return response.data;
  } catch (e) {
    return new Error(e);
  }
};

export const createDeliverableItemAsset = async (organizationId, boardId, deliverableId, assetsArray, startIndex) => {
  try {
    const formData = new FormData();
    const captions = {};
    const orders = {};

    for (let i = 0; i < assetsArray.length; i++) {
      const asset = assetsArray[i];
      const newFileName = i + '_' + asset.file.name;
      formData.append('files', asset.file, newFileName);
      if (asset.caption) {
        captions[newFileName] = asset.caption;
      }
      orders[newFileName] = startIndex;
      startIndex++;
    }

    formData.append('captions', JSON.stringify(captions));
    formData.append('display_orders', JSON.stringify(orders));
    formData.append('type', assetsArray[0]?.type);

    const response = await instanceWithForm.post(
      `/organizations/${organizationId}/content-boards/${boardId}/deliverables/${deliverableId}/assets`,
      formData
    );
    return response.data;
  } catch (e) {
    return new Error(e);
  }
};

// Approve
export const approveDeliverableItem = async (organizationId, boardId, deliverableId, data) => {
  const response = await instance.post(
    `/organizations/${organizationId}/content-boards/${boardId}/deliverables/${deliverableId}/approvals`,
    data
  );
  return response.data;
};

export const getDeliverableApprovals = async (organizationId, boardId, deliverableId) => {
  const response = await instance.get(`/organizations/${organizationId}/content-boards/${boardId}/deliverables/${deliverableId}/approvals`);
  return response.data;
};

export const createBoard = async (organizationId, data) => {
  const formData = new FormData();
  for (const key in data) {
    formData.append(key, data[key]);
  }

  const response = await instanceWithForm.post(`/organizations/${organizationId}/content-boards`, formData);
  return response.data;
};
