import React, { useEffect, useState } from 'react';
import styles from './DeliverableItem.module.scss';
import Header from './components/Header';
import Concept from './components/Concept';
import Visuals from './components/Visuals';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearContentApprovalErrors,
  clearOpenedDeliverableItem,
  getBoardCollaborators,
  getBoardDeliverableItem,
  getDeliverableApprovals,
} from '../../../actions/contentApproval.actions';
import Preview from './components/Preview';
import useDeliverableState from './hooks/useDeliverableState';
import { getCallToActionLabelByValue } from './components/Visuals/SelectCallToAction';
import ModalsContainer from './components/ModalsContainer';
import useDeliverableSockets from './hooks/useDeliverableSockets';
import { useMediaQuery } from 'react-responsive';

const DeliverableItem = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const { deliverableItemError } = useSelector(state => state.contentApproval);
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  const [openedAsset, setOpenedAsset] = useState(null);
  const [openAssetModal, setOpenAssetModal] = useState(false);

  useEffect(() => {
    if (!currentOrg) return;
    dispatch(getBoardDeliverableItem(currentOrg.organization_id, params.boardId, params.deliverableId));
    dispatch(getDeliverableApprovals(currentOrg.organization_id, params.boardId, params.deliverableId));
    dispatch(getBoardCollaborators(currentOrg.organization_id, params.boardId));
    return () => {
      dispatch(clearOpenedDeliverableItem());
    };
  }, [currentOrg]);

  useEffect(() => {
    if (deliverableItemError) {
      dispatch(clearContentApprovalErrors());
      history.push('/content-approval');
    }
  }, [deliverableItemError]);

  const {
    deliverableContent,
    onChangeConcept,
    onChangeCallToAction,
    visualAssetsFlow,
    visualConceptsFlow,
    handleSaveSubmit,
    onDeleteAsset,
    onApprove,
    statusesState,
  } = useDeliverableState();

  useDeliverableSockets();

  const onOpenAsset = asset => {
    setOpenedAsset(asset);
    setOpenAssetModal(true);
  };

  return (
    <>
      <div className={styles.root}>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <Header />
            <Concept
              concept={deliverableContent.concept}
              isConceptFilled={deliverableContent.isFilledFromServer}
              onChangeConcept={onChangeConcept}
              visualConceptsFlow={visualConceptsFlow}
              openAsset={asset => onOpenAsset(asset)}
              onApprove={onApprove}
              onSave={handleSaveSubmit}
              conceptStatus={statusesState.conceptStatus}
              deliverableConceptStatus={statusesState.deliverableStatuses}
            />
            <Visuals
              callToActionValue={deliverableContent.call_to_action[0]}
              setCallToAction={onChangeCallToAction}
              visualAssetsFlow={visualAssetsFlow}
              openAsset={asset => onOpenAsset(asset)}
              onApprove={onApprove}
              onSave={handleSaveSubmit}
              contentStatus={statusesState.contentStatus}
              deliverableConceptStatus={statusesState.deliverableStatuses.concept}
              deliverableContentStatus={statusesState.deliverableStatuses.content}
            />
          </div>
          {!isTablet && (
            <div className={styles.preview}>
              <Preview
                assets={visualAssetsFlow.visualAssets}
                callToAction={getCallToActionLabelByValue(deliverableContent.call_to_action[0])}
              />
            </div>
          )}
        </div>
        <ModalsContainer
          openedAsset={openedAsset}
          setOpenedAsset={setOpenedAsset}
          openAssetModal={openAssetModal}
          setOpenAssetModal={setOpenAssetModal}
          onDeleteAsset={onDeleteAsset}
          deliverableStatuses={statusesState.deliverableStatuses}
          assetsPrev={visualAssetsFlow.visualAssets}
          callToActionPrev={getCallToActionLabelByValue(deliverableContent.call_to_action[0])}
        />
      </div>
    </>
  );
};

export default DeliverableItem;
