import { instance } from '../shared/utils/api.config.creator';

export const inviteMember = async (organization_id, email) => {
  const response = await instance.post(`/users/invite`, { organization_id, email });
  return response.data;
};

export const inviteConfirm = async data => {
  const response = await instance.post(`/users/invite/confirm`, { ...data });
  return response.data;
};

export const getUsers = async id => {
  const response = await instance.get(`/users/${id || ''}`);
  return response.data;
};

export const getOrganizationUsers = async organizationID => {
  const response = await instance.get('/users', { params: { organization_id: organizationID } });
  return response.data;
};

export const updateUser = async (id, data) => {
  const response = await instance.patch(`/users/${id}`, data);
  return response.data;
};

export const removeUserPhoto = async id => {
  const response = await instance.delete(`/users/${id}/photo`);
  return response.data;
};

export const getInviteDetails = async (inviteToken, inviteType) => {
  const response = await instance.get(`/invites/${inviteToken}?invite_type=${inviteType}`);
  return response.data;
};

export const acceptInvite = async (userId, inviteToken, inviteType, organizationId) => {
  const response =
    inviteType === 'organization_user'
      ? await instance.post(`/users/${userId}/invite/confirm`, { invite_token: inviteToken, invite_type: inviteType })
      : await instance.post(`/users/${userId}/invite/confirm`, {
          invite_token: inviteToken,
          invite_type: inviteType,
          organization_id: organizationId,
        });
  return response.data;
};

export const getPricingPlanForUser = async (userId, orgId) => {
  const response = orgId
    ? await instance.get(`/users/${userId}/pricing-plan?organization_id=${orgId}`)
    : await instance.get(`/users/${userId}/pricing-plan`);
  return response.data.data[0];
};

export const updatePricingPlanForUser = async (userId, newPlanId) => {
  const response = await instance.patch(`/users/${userId}/pricing-plan`, {
    pricing_plan_id: newPlanId,
  });
  return response.data;
};
