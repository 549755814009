import React from 'react';
import styles from './CollaboratorsDropdown.module.scss';
import PlusSVG from '../../expert/components/IconsSvg/PlusSVG';
import ButtonCustom from '../../expert/components/Buttons/ButtonCustom';
import cs from 'classnames';

const CollaboratorsDropdown = ({
  collaborators,
  invitedCollaborators,
  counter,
  onAddClick,
  collaboratorsTableHeader,
  renderCollaboratorCell,
  renderInvitedCollaboratorCell,
  forwardedRef,
  disableAddButton = false,
  rootStyle = {},
  onClose,
  isTablet
}) => {
  return (
    <div className={styles.root} ref={forwardedRef} style={rootStyle}>
      <div className={styles.header}>
        <div className={styles.column}>
          <div className={styles.title}>Collaborators</div>
          {counter && <div className={styles.counter}>{counter}</div>}
        </div>
        <div className={styles.column}>
          {onAddClick && !disableAddButton && (
            <ButtonCustom className={styles.addButton} Icon={PlusSVG} onClick={onAddClick}>
              Add
            </ButtonCustom>
          )}
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.list}>
          <div className={styles.collaboratorsSection}>
            <div className={styles.sectionTitle}>{collaboratorsTableHeader}</div>
            <div className={styles.sectionList}>{collaborators?.map(collaborator => renderCollaboratorCell(collaborator))}</div>
          </div>
          {!!invitedCollaborators.length && (
            <div className={styles.invitedSection}>
              <div className={cs(styles.sectionTitle, styles.invitedTitle)}>Invited collaborators</div>
              <div className={styles.sectionList}>
                {invitedCollaborators.map(collaborator => renderInvitedCollaboratorCell(collaborator))}
              </div>
            </div>
          )}
        </div>
        {isTablet &&
          <ButtonCustom
          className={styles.buttonClose}
          onClick={onClose}
          outline
        >
          Close
        </ButtonCustom>
        }
      </div>
    </div>
  );
};

export default CollaboratorsDropdown;
