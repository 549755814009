/* eslint-disable camelcase */
import React from 'react';
import styles from './ExpiredInviteModal.module.scss';
import { Modal } from '..';
import Button from '../../../expert/components/Buttons/ButtonCustom';
import avatar from '../../../assets/img/no-avatar.svg';
import { ReactComponent as Copy } from '../../../assets/img/icons/copy.svg';
import { ReactComponent as Warning } from '../../../assets/img/icons/warning.svg';

const ExpiredInviteModal = ({ onSubmit, orgData }) => {
  return (
    <div className={styles.root}>
      <div className={styles.warning}>
        <Warning />
      </div>
      <div className={styles.description}>The invitation link has expired, please contact the organization owner for a new invite.</div>
      <div className={styles.flexRow}>
        <div className={styles.avatar}>
          <img src={orgData.inviter_photo_thumbnail_location || avatar} alt="avatar" />
        </div>
        <div className={styles.onlyFlex}>
          <div className={styles.name}>{orgData.inviter_first_name}</div>
          <div className={styles.email}>{orgData.inviter_email}</div>
        </div>
        <div
          className={styles.copyButton}
          onClick={() => {
            navigator.clipboard.writeText(orgData.inviter_email);
          }}
        >
          <Copy />
        </div>
      </div>
      <div className={styles.navigationBtns}>
        <Button className={styles.btn} onClick={() => onSubmit()}>
          Okay
        </Button>
      </div>
    </div>
  );
};

export default props => {
  return (
    <Modal {...props} maxWidth={500}>
      <ExpiredInviteModal {...props} />
    </Modal>
  );
};
