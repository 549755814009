import React from 'react';

const FacebookSVG = ({className}) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path d="M8.5 0C3.80583 0 0 3.82881 0 8.55131C0 12.8188 3.10791 16.356 7.17217 17V11.0237H5.01295V8.55131H7.17217V6.66727C7.17217 4.52239 8.44134 3.33962 10.3821 3.33962C11.3121 3.33962 12.2863 3.50639 12.2863 3.50639V5.60937H11.2118C10.1577 5.60937 9.82783 6.2696 9.82783 6.94607V8.5496H12.1834L11.8068 11.022H9.82783V16.9983C13.8921 16.3577 17 12.8197 17 8.55131C17 3.82881 13.1942 0 8.5 0Z" fill="#1877F2"/>
    </svg>
  );
};

export default FacebookSVG;
