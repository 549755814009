import React, { useEffect, useState } from 'react';
import styles from './DiscussionCreateModal.module.scss';
import ButtonCustom from '../../Buttons/ButtonCustom';
import { Modal } from '../../../../components/Modals';
import { TYPES_CAMPAIGNS_OBJECTS } from '../../../../shared/constants/constants';
import TextField from '../../../../components/FormControls/TextField';
import CheckBox from '../../../../pages/CampaignBrief/components/CheckBox';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';


const DiscussionCreateModal = ({ onClose, modalStatus, createRoom, availableOrganizations }) => {
  const [organizationsIds, setOrganizationsIds] = useState([]);
  const [showError, setShowError] = useState(false);
  const { openedBoard } = useSelector(state => state.contentApproval);

  const formik = useFormik({
    initialValues: {
      name_room: ''
    },
    validationSchema: Yup.object().shape({
      name_room: Yup.string().required('Name is a required field')
    })
  });

  const handleSubmit = () => {
    if (organizationsIds.length === 0) {
      setShowError(true);
    } else {
      createRoom(formik.values.name_room, organizationsIds);
      setOrganizationsIds([]);
      formik.setFieldValue('name_room','')
    }
  };

  const handleClose = () => {
      onClose();
      formik.setFieldValue('name_room','')
  }

  const typeOrg = (type) => {
    return TYPES_CAMPAIGNS_OBJECTS.filter(item => item.type === type)[0]?.name
  };

  const getInitials = (first, last) => {
    return first[0].toUpperCase() + last[0].toUpperCase()
  };

  const changeCheckBox = (id, type) => {
    if (organizationsIds.includes(id)) {
      setOrganizationsIds(organizationsIds.filter(itemId => itemId !== id));
    } else if (type !== 'advertiser') {
      setOrganizationsIds([...organizationsIds, id]);
    }
  };

  useEffect(() => {
    if (organizationsIds.length > 0) setShowError(false);
  }, [organizationsIds]);

  return (
    <Modal className={styles.root} onClose={handleClose} open={modalStatus}>
      <div className={styles.descriptions}>Create new discussion</div>
      <div className={styles.inputContainer}>
        <TextField
          type={'text'}
          name="name_room"
          placeholder={'Name new discussion'}
          className={styles.createInput}
          value={formik.values.name_room}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name_room && formik.errors.name_room}
        />
      </div>
      <div className={styles.titleUserList}>Choose who you want to add:</div>
      <div className={styles.userList}>
        {availableOrganizations.map(item => (
          <div className={styles.organizationItem} key={item.organization_name}>
            <CheckBox
              isActive={openedBoard?.creator_id === item?.approver_id ? true : organizationsIds.includes(item.organization_id, item.organization_type)}
              onClick={() => changeCheckBox(item.organization_id, item.organization_type)}
            />
            <div className={styles.iconOrg}>{getInitials(item.approver_first_name, item.approver_last_name)}</div>
            <div>
              <div className={styles.organizationName}>{item.approver_first_name} {item.approver_last_name}</div>
              <div className={styles.organizationType}>
                  {typeOrg(item.type)} {openedBoard?.creator_id === item?.approver_id && '/Initiator'}
              </div>
            </div>
          </div>
        ))}
      </div>
      {showError &&
        <div className={styles.errorContainer}>You need to choose at least one member to create a room</div>}
      <div className={styles.createButtons}>
        <ButtonCustom
          onClick={handleSubmit}
          disabled={!formik.values.name_room}
          parentWidth clearBg
          className={styles.submitButton}
        >
          Create
        </ButtonCustom>
      </div>
    </Modal>
  );
};

export default DiscussionCreateModal;
