/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import Header from '../Header';
import styles from './MainLayout.module.scss';
import Menu from '../Menu';
import { useMediaQuery } from 'react-responsive/src';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizations } from '../../actions/organizations.actions';
import { deleteMarkAsNew } from '../../actions/user.actions';
import ConfirmInviteModal from '../Modals/ConfirmInviteModal';
import cs from 'classnames';
import { Route, Switch } from 'react-router-dom';
import ContentApprovalDiscussionWrapper from '../../pages/ContentApproval/ContentApprovalDiscussionWrapper';

const MainLayout = ({
  children,
  renderHeaderContent,
  title,
  isFullWidth,
  isFlow,
  contentClassName,
  coloredHeader = false,
  backButtonAction,
}) => {
  const dispatch = useDispatch();
  const isMinimizedMenu = useSelector(state => state.sidebarMenu.isMinimized);
  const organization = useSelector(state => state.organizations.data[0]);
  const isNewMember = useSelector(state => state.auth.isNewMember);

  const isDesktopLandscape = useMediaQuery({
    query: '(min-width: 992px) and (orientation: landscape)',
  });

  useEffect(() => {
    if (!organization) {
      dispatch(getOrganizations());
    }
  }, []);

  const onCloseModal = () => {
    dispatch(deleteMarkAsNew());
  };

  const onCloseChat = () => {
    if (!window.HubSpotConversations) return;
    window.HubSpotConversations.widget.close();
  };

  useEffect(() => {
    if (!isDesktopLandscape) dispatch({ type: 'TOGGLE_MOBILE_SIDEBAR', payload: false });
  }, [isDesktopLandscape]);

  return (
    <div className={styles.root} onClick={onCloseChat}>
      {isDesktopLandscape && (
        <div className={styles.menuContainer}>
          <Menu isOpen />
        </div>
      )}
      <div className={cs(styles.layoutContentContainer, { [styles.minimized]: isMinimizedMenu })}>
        <div>
          {isDesktopLandscape && (
            <Header
              extended
              renderContent={renderHeaderContent}
              title={title}
              isFlow={isFlow}
              backgroundColor={coloredHeader}
              backButtonAction={backButtonAction}
            />
          )}
        </div>
        <div className={styles.layout}>
          {!isDesktopLandscape && <DiscussionRoutes />}
          <Header />
          <div className={cs(styles.main, { [styles.fullWidth]: isFullWidth }, contentClassName)}>
            {children}
            {isDesktopLandscape && <DiscussionRoutes />}
          </div>
        </div>
      </div>

      <ConfirmInviteModal open={isNewMember && !!organization} onClose={onCloseModal} name={organization?.name} />
    </div>
  );
};

const DiscussionRoutes = () => {
  return (
    <Switch>
      <Route
        path={['/content-board/:boardId', '/content-board/:boardId/deliverables/:deliverableId']}
        render={() => <ContentApprovalDiscussionWrapper />}
      />
    </Switch>
  );
};

export default MainLayout;
