/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React from 'react';
import cs from 'classnames';
import styles from './MobileMenu.module.scss';
import MenuInner from '../components/MenuInner';

const MobileMenu = ({ isOpen, handleCloseMenu, classNames }) => {
  return (
    <div className={cs(styles.wrapper, isOpen ? styles.open : styles.hide, classNames)}>
      <div className={styles.backdrop} onClick={handleCloseMenu} />
      <MenuInner className={styles.inner} handleCloseMenu={handleCloseMenu} />
    </div>
  );
};

export default MobileMenu;
