import { instance, instanceWithForm } from '../shared/utils/api.config.creator';
import * as creativeStudioServices from '../services/creativeStudio';
import { v4 as uuidv4 } from 'uuid';

export const CREATIVE_STUDIO_TYPES = {
  GET_IMAGES: {
    REQUEST: 'GET_IMAGES_REQUEST',
    SUCCESS: 'GET_IMAGES_SUCCESS',
    FAILURE: 'GET_IMAGES_FAILURE',
  },
  UPLOAD_IMAGES: {
    REQUEST: 'UPLOAD_IMAGES_REQUEST',
    SUCCESS: 'UPLOAD_IMAGES_SUCCESS',
    FAILURE: 'UPLOAD_IMAGES_FAILURE',
  },
  DELETE_IMAGES: {
    REQUEST: 'DELETE_IMAGES_REQUEST',
    SUCCESS: 'DELETE_IMAGES_SUCCESS',
    FAILURE: 'DELETE_IMAGES_FAILURE',
  },
  RENAME_IMAGE: {
    REQUEST: 'RENAME_IMAGE_REQUEST',
    SUCCESS: 'RENAME_IMAGE_SUCCESS',
    FAILURE: 'RENAME_IMAGE_FAILURE',
  },
  GET_CREATIVES: {
    REQUEST: 'GET_CREATIVES_REQUEST',
    SUCCESS: 'GET_CREATIVES_SUCCESS',
    FAILURE: 'GET_CREATIVES_FAILURE',
  },
  UPLOAD_CREATIVES: {
    REQUEST: 'UPLOAD_CREATIVES_REQUEST',
    SUCCESS: 'UPLOAD_CREATIVES_SUCCESS',
    FAILURE: 'UPLOAD_CREATIVES_FAILURE',
  },
  DELETE_CREATIVES: {
    REQUEST: 'DELETE_CREATIVES_REQUEST',
    SUCCESS: 'DELETE_CREATIVES_SUCCESS',
    FAILURE: 'DELETE_CREATIVES_FAILURE',
  },
  UPLOAD_CREATIVE_IMAGES: {
    REQUEST: 'UPLOAD_CREATIVE_IMAGES_REQUEST',
    SUCCESS: 'UPLOAD_CREATIVE_IMAGES_SUCCESS',
    FAILURE: 'UPLOAD_CREATIVE_IMAGES_FAILURE',
  },
  RENAME_CREATIVES: {
    REQUEST: 'RENAME_CREATIVES_REQUEST',
    SUCCESS: 'RENAME_CREATIVES_SUCCESS',
    FAILURE: 'RENAME_CREATIVES_FAILURE',
  },
  GET_LOGOS: {
    REQUEST: 'GET_LOGOS_REQUEST',
    SUCCESS: 'GET_LOGOS_SUCCESS',
    FAILURE: 'GET_LOGOS_FAILURE',
  },
  UPLOAD_LOGOS: {
    REQUEST: 'UPLOAD_LOGOS_REQUEST',
    SUCCESS: 'UPLOAD_LOGOS_SUCCESS',
    FAILURE: 'UPLOAD_LOGOS_FAILURE',
  },
  DELETE_LOGOS: {
    REQUEST: 'DELETE_LOGOS_REQUEST',
    SUCCESS: 'DELETE_LOGOS_SUCCESS',
    FAILURE: 'DELETE_LOGOS_FAILURE',
  },
  UPLOAD_AD: {
    REQUEST: 'UPLOAD_AD_REQUEST',
    SUCCESS: 'UPLOAD_AD_SUCCESS',
    FAILURE: 'UPLOAD_AD_FAILURE',
  },
  EDIT_AD: {
    REQUEST: 'EDIT_AD_REQUEST',
    SUCCESS: 'EDIT_AD_SUCCESS',
    FAILURE: 'EDIT_AD_FAILURE',
  },
  GET_ADS: {
    REQUEST: 'GET_ADS_REQUEST',
    SUCCESS: 'GET_ADS_SUCCESS',
    FAILURE: 'GET_ADS_FAILURE',
  },
  GET_AD: {
    REQUEST: 'GET_AD_REQUEST',
    SUCCESS: 'GET_AD_SUCCESS',
    FAILURE: 'GET_AD_FAILURE',
  },
  DELETE_AD: {
    REQUEST: 'DELETE_AD_REQUEST',
    SUCCESS: 'DELETE_AD_SUCCESS',
    FAILURE: 'DELETE_AD_FAILURE',
  },
  CLEAR_FAILURE: 'CLEAR_FAILURE',
};

export const clearFailure = () => async dispatch => {
  dispatch({ type: CREATIVE_STUDIO_TYPES.CLEAR_FAILURE });
};

export const uploadAd = ad => async (dispatch, getState) => {
  try {
    dispatch({
      type: CREATIVE_STUDIO_TYPES.UPLOAD_AD.REQUEST,
      payload: ad,
    });
    const currentOrg = getState().auth.currentOrganization; // JSON.parse(localStorage.getItem('OTTERFISH_CURRENT_ORGANIZATION'));

    const data = await creativeStudioServices.createAd({ ...ad, organization_id: currentOrg.organization_id });

    dispatch({
      type: CREATIVE_STUDIO_TYPES.UPLOAD_AD.SUCCESS,
      payload: data.data,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: CREATIVE_STUDIO_TYPES.UPLOAD_AD.FAILURE,
      payload: error.response.data,
    });
  }
};

export const editAd = (id, ad) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CREATIVE_STUDIO_TYPES.EDIT_AD.REQUEST,
      payload: { ad, id },
    });

    const data = await creativeStudioServices.editAd(id, ad);

    dispatch({
      type: CREATIVE_STUDIO_TYPES.EDIT_AD.SUCCESS,
      payload: data.data[0],
    });
    return data.data[0];
  } catch (error) {
    dispatch({
      type: CREATIVE_STUDIO_TYPES.EDIT_AD.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getAds = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CREATIVE_STUDIO_TYPES.GET_ADS.REQUEST,
      payload: {},
    });

    const data = await creativeStudioServices.getAds();

    dispatch({
      type: CREATIVE_STUDIO_TYPES.GET_ADS.SUCCESS,
      payload: data.data,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: CREATIVE_STUDIO_TYPES.GET_ADS.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getAd = id => async (dispatch, getState) => {
  try {
    dispatch({
      type: CREATIVE_STUDIO_TYPES.GET_AD.REQUEST,
      payload: {},
    });

    const data = await creativeStudioServices.getAds(id);

    dispatch({
      type: CREATIVE_STUDIO_TYPES.GET_AD.SUCCESS,
      payload: data.data,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: CREATIVE_STUDIO_TYPES.GET_AD.FAILURE,
      payload: error.response.data,
    });
  }
};

export const deleteAds = ads => async (dispatch, getState) => {
  try {
    dispatch({
      type: CREATIVE_STUDIO_TYPES.DELETE_AD.REQUEST,
      payload: ads,
    });

    const adsReqs = ads.map(ad => {
      return creativeStudioServices.deleteAd(ad);
    });
    await Promise.all(adsReqs);

    dispatch({
      type: CREATIVE_STUDIO_TYPES.DELETE_AD.SUCCESS,
      payload: ads,
    });
    return ads;
  } catch (error) {
    dispatch({
      type: CREATIVE_STUDIO_TYPES.DELETE_AD.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getCreatives = (page, pageSize) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CREATIVE_STUDIO_TYPES.GET_CREATIVES.REQUEST,
      payload: {},
    });
    const params = {};
    if (page) params.page = page;
    if (pageSize) params.page_size = pageSize;

    const data = await creativeStudioServices.getCreatives(params);

    dispatch({
      type: CREATIVE_STUDIO_TYPES.GET_CREATIVES.SUCCESS,
      payload: data.data,
      totalCount: data.total_count,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: CREATIVE_STUDIO_TYPES.GET_CREATIVES.FAILURE,
      payload: error.response.data,
    });
  }
};

export const uploadCreatives = creatives => async (dispatch, getState) => {
  try {
    dispatch({
      type: CREATIVE_STUDIO_TYPES.UPLOAD_CREATIVES.REQUEST,
      payload: {
        creatives,
      },
    });
    const currentOrg = getState().auth.currentOrganization; // JSON.parse(localStorage.getItem('OTTERFISH_CURRENT_ORGANIZATION'));

    const creativesReqs = creatives.map(c => {
      return creativeStudioServices.uploadCreativeTemplate(currentOrg.organization_id, c, uuidv4());
    });
    const creativesResponses = await Promise.all(creativesReqs);

    const data = creativesResponses.map(resp => {
      return resp.data[0];
    });

    dispatch({
      type: CREATIVE_STUDIO_TYPES.UPLOAD_CREATIVES.SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: CREATIVE_STUDIO_TYPES.UPLOAD_CREATIVES.FAILURE,
      payload: error.response.data,
    });
  }
};

export const deleteCreatives = creatives => async (dispatch, getState) => {
  try {
    dispatch({
      type: CREATIVE_STUDIO_TYPES.DELETE_CREATIVES.REQUEST,
      payload: {},
    });

    const creativesReqs = creatives.map(c => {
      return creativeStudioServices.deleteCreative(c);
    });
    await Promise.all(creativesReqs);

    dispatch({
      type: CREATIVE_STUDIO_TYPES.DELETE_CREATIVES.SUCCESS,
      payload: creatives,
    });
    return creatives;
  } catch (error) {
    dispatch({
      type: CREATIVE_STUDIO_TYPES.DELETE_CREATIVES.FAILURE,
      payload: error.response.data,
    });
  }
};
export const uploadCreativeImages = creatives => async (dispatch, getState) => {
  const formData = new FormData();
  dispatch({
    type: CREATIVE_STUDIO_TYPES.UPLOAD_CREATIVE_IMAGES.REQUEST,
  });
  await asyncForEach(creatives, async creative => {
    const blob = await fetch(creative.url).then(r => r.blob());
    formData.append('creative', blob, creative.name);
  });
  const currentOrg = getState().auth.currentOrganization; // JSON.parse(localStorage.getItem('OTTERFISH_CURRENT_ORGANIZATION'));
  formData.append('organization_id', currentOrg.organization_id);

  return instanceWithForm
    .post('/creatives/upload', formData)
    .then(response => {
      return dispatch({
        type: CREATIVE_STUDIO_TYPES.UPLOAD_CREATIVE_IMAGES.SUCCESS,
        payload: response,
      });
    })
    .catch(e => {
      return dispatch({
        type: CREATIVE_STUDIO_TYPES.UPLOAD_CREATIVE_IMAGES.FAILURE,
        payload: e,
      });
    });
};

export const renameCreative = (id, name) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CREATIVE_STUDIO_TYPES.RENAME_CREATIVES.REQUEST,
      payload: {},
    });

    const data = await creativeStudioServices.renameCreative(id, name);

    dispatch({
      type: CREATIVE_STUDIO_TYPES.RENAME_CREATIVES.SUCCESS,
      payload: data.data[0],
    });
    return data.data[0];
  } catch (error) {
    dispatch({
      type: CREATIVE_STUDIO_TYPES.RENAME_CREATIVES.FAILURE,
      payload: error.response.data,
    });
  }
};

export function getImages(type) {
  return dispatch => {
    dispatch({
      type: CREATIVE_STUDIO_TYPES.GET_IMAGES.REQUEST,
    });

    return instance
      .get(`/images?type=${type}`)
      .then(response => {
        return dispatch({
          type: CREATIVE_STUDIO_TYPES.GET_IMAGES.SUCCESS,
          payload: response.data.data,
        });
      })
      .catch(e => {
        return dispatch({
          type: CREATIVE_STUDIO_TYPES.GET_IMAGES.FAILURE,
          payload: e,
        });
      });
  };
}

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export const uploadImages = images => async (dispatch, getState) => {
  dispatch({
    type: CREATIVE_STUDIO_TYPES.UPLOAD_IMAGES.REQUEST,
  });
  const formData = new FormData();
  await asyncForEach(images, async image => {
    const blob = await fetch(image.url).then(r => r.blob());
    formData.append('image', blob, image.name);
  });
  const currentOrg = getState().auth.currentOrganization; // JSON.parse(localStorage.getItem('OTTERFISH_CURRENT_ORGANIZATION'));
  formData.append('organization_id', currentOrg.organization_id);
  formData.append('type', 'image');

  return instanceWithForm
    .post('/images/upload', formData)
    .then(response => {
      return dispatch({
        type: CREATIVE_STUDIO_TYPES.UPLOAD_IMAGES.SUCCESS,
        payload: response,
      });
    })
    .then(() => dispatch(getImages('image')))
    .catch(e => {
      return dispatch({
        type: CREATIVE_STUDIO_TYPES.UPLOAD_IMAGES.FAILURE,
        payload: e,
      });
    });
};

export function deleteImages(selectedImages) {
  return dispatch => {
    dispatch({
      type: CREATIVE_STUDIO_TYPES.DELETE_IMAGES.REQUEST,
    });
    const deleteString = selectedImages.join(',');

    return instance
      .delete(`/images/${deleteString}`)
      .then(response => {
        return dispatch({
          type: CREATIVE_STUDIO_TYPES.DELETE_IMAGES.SUCCESS,
          payload: response,
        });
      })
      .then(() => dispatch(getImages('image')))
      .catch(e => {
        return dispatch({
          type: CREATIVE_STUDIO_TYPES.DELETE_IMAGES.FAILURE,
          payload: e,
        });
      });
  };
}

export function renameImage(id, newName) {
  return dispatch => {
    dispatch({
      type: CREATIVE_STUDIO_TYPES.RENAME_IMAGE.REQUEST,
    });
    return instance
      .patch(`/images/${id}`, { filename: newName })
      .then(response => {
        return dispatch({
          type: CREATIVE_STUDIO_TYPES.RENAME_IMAGE.SUCCESS,
          payload: response.data.data[0],
        });
      })
      .catch(e => {
        return dispatch({
          type: CREATIVE_STUDIO_TYPES.RENAME_IMAGE.FAILURE,
          payload: e,
        });
      });
  };
}

export function getLogos(type) {
  return dispatch => {
    dispatch({
      type: CREATIVE_STUDIO_TYPES.GET_LOGOS.REQUEST,
    });

    return instance
      .get(`/images?type=${type}`)
      .then(response => {
        return dispatch({
          type: CREATIVE_STUDIO_TYPES.GET_LOGOS.SUCCESS,
          payload: response.data.data,
        });
      })
      .catch(e => {
        return dispatch({
          type: CREATIVE_STUDIO_TYPES.GET_LOGOS.FAILURE,
          payload: e,
        });
      });
  };
}

export const uploadLogos = images => async (dispatch, getState) => {
  dispatch({
    type: CREATIVE_STUDIO_TYPES.UPLOAD_LOGOS.REQUEST,
  });
  const formData = new FormData();
  const currentOrg = getState().auth.currentOrganization; // JSON.parse(localStorage.getItem('OTTERFISH_CURRENT_ORGANIZATION'))
  await asyncForEach(images, async image => {
    const blob = await fetch(image.url).then(r => r.blob());
    formData.append('image', blob, image.name);
  });
  formData.append('organization_id', currentOrg.organization_id);
  formData.append('type', 'logo');

  return instanceWithForm
    .post('/images/upload', formData)
    .then(response => {
      return dispatch({
        type: CREATIVE_STUDIO_TYPES.UPLOAD_LOGOS.SUCCESS,
        payload: response,
      });
    })
    .then(() => dispatch(getLogos('logo')))
    .catch(e => {
      return dispatch({
        type: CREATIVE_STUDIO_TYPES.UPLOAD_LOGOS.FAILURE,
        payload: e,
      });
    });
};

export function deleteLogo(id) {
  return dispatch => {
    dispatch({
      type: CREATIVE_STUDIO_TYPES.DELETE_LOGOS.REQUEST,
    });

    return instance
      .delete(`/images/${id}`)
      .then(response => {
        return dispatch({
          type: CREATIVE_STUDIO_TYPES.DELETE_LOGOS.SUCCESS,
          payload: response,
        });
      })
      .then(() => dispatch(getLogos('logo')))
      .catch(e => {
        return dispatch({
          type: CREATIVE_STUDIO_TYPES.DELETE_LOGOS.FAILURE,
          payload: e,
        });
      });
  };
}
