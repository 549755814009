import axios from 'axios';
import localforage from 'localforage';
import { ALLOWED_IMAGE_FORMATS, placementsFormats } from '../constants/constants';
import { isArray } from 'lodash';

export const clearLocalStorage = () => {
  localStorage.removeItem('OTTERFISH_USER');
  localStorage.removeItem('OTTERFISH_ACCESS_TOKEN');
  localStorage.removeItem('OTTERFISH_USER_EMAIL');
  localStorage.removeItem('OTTERFISH_ACCOUNT_MANAGER');
  localStorage.removeItem('OTTERFISH_CURRENT_ORGANIZATION');
  localStorage.removeItem('OTTERFISH_PRESELECTED_PLAN');
  localStorage.removeItem('OTTERFISH_TEST_CREATION_DRAFT_V1');
  localStorage.removeItem('SEARCH_CASH');
  localStorage.removeItem('OTTERFISH_GENERATED_CREATIVES_FOLDER');
  localStorage.removeItem('OTTERFISH_FILTERS_APPROVALS');
  localStorage.removeItem('OTTERFISH_FILTERS_CAMPAIGNS');
  localforage.removeItem('OTTERFISH_GENERATED_CREATIVE');
};

export const toTitleCase = str => {
  return str?.replace(/\w\S*/g, v => v.charAt(0).toUpperCase() + v.slice(1));
};

export const downloadFile = (fileLocation, fileName) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: fileLocation,
      responseType: 'arraybuffer',
    })
      .then(response => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
        link.download = fileName;

        document.body.appendChild(link);

        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(link);
        }, 200);

        resolve(link);
      })
      .catch(error => reject(error));
  });
};

export const uploadingPercent = onPercentChange => ({
  onUploadProgress: progressEvent => {
    const { loaded, total } = progressEvent;
    onPercentChange(loaded / total);
  },
});

export const onloadPromise = obj => {
  return new Promise((resolve, reject) => {
    obj.onload = () => resolve(obj);
    obj.onerror = reject;
  });
};

export const resizeImage = async (image, url) => {
  const img = new Image();
  img.src = url;
  await onloadPromise(img).catch(() => {});
  const canvas = document.createElement('canvas');
  const MAX = 1080;
  canvas.width = MAX;
  canvas.height = MAX;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0, MAX, MAX);
  return canvas.toDataURL(image.file ? image.file.type : image.type);
};

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
};

export const onClickEnter = (clickAction, e) => {
  if (e.keyCode === 13 && clickAction) {
    clickAction();
  }
};

export const getMainMetricValue = (mainMetricValue, status) => {
  if (status === 'paused') return '-';
  return mainMetricValue === 'NaN%' || mainMetricValue === 'Infinity%' ? '-' : mainMetricValue;
};

export const loadingImage = (url, action) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      action();
      resolve(url);
    };
    img.onerror = function () {
      reject(url);
    };
    img.src = url;
  });

export const replaceHtml = str => str.replace(/(<[^<>]*>)|(\&nbsp;)/g, '').trim();

export const getFormattedPlacement = placement => {
  if (!Object.keys(placementsFormats).includes(placement)) {
    return `N/A (${placement})`;
  }
  return placementsFormats[placement];
};

export const validateImage = file => {
  return ALLOWED_IMAGE_FORMATS.includes(file.name.split('.').pop()) ? file : null;
};
