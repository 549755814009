import React from 'react';
import styles from './CheckBox.module.scss';
import cs from 'classnames';
import CheckmarkSVG from '../../../../../components/IconsSvg/CheckmarkSVG';

function CheckBox({ isActive }) {
  return (
    <span className={cs(styles.checkbox, { [styles.active]: isActive })}>
      <CheckmarkSVG />
    </span>
  );
}

export default CheckBox;
