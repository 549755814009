import { ASSETS_TYPES } from '../actions/assets.actions';

const initialState = {
  assets: [],
  facebookPages: [],
  instagramAccounts: [],
  facebookPixels: [],
  entireFbList: [],
  entireIgList: [],
  entirePixList: [],
  loadingEntireFb: false,
  loadingEntireIg: false,
  loadingEntirePix: false,
  loadingFacebook: false,
  loadingInstagram: false,
  loadingPixels: false,
  facebookTotalCount: 0,
  instagramTotalCount: 0,
  pixelsTotalCount: 0,
  loading: false,
  isImporting: false,
  deleteLoading: false,
  failure: {
    message: '',
    name: '',
    code: '',
  },
};

export default (state = initialState, { type, payload, totalCount }) => {
  switch (type) {
    case ASSETS_TYPES.IMPORT_ASSETS.REQUEST: {
      return {
        ...state,
        isImporting: true,
      };
    }
    case ASSETS_TYPES.IMPORT_ASSETS.SUCCESS: {
      return {
        ...state,
        assets: [...payload],
        isImporting: false,
      };
    }
    case ASSETS_TYPES.IMPORT_ASSETS.FAILURE: {
      return {
        ...state,
        loading: false,
        failure: payload,
        isImporting: false,
      };
    }
    case ASSETS_TYPES.IMPORT_ALL_ASSETS.REQUEST: {
      return {
        ...state,
        isImporting: true,
      };
    }
    case ASSETS_TYPES.IMPORT_ALL_ASSETS.SUCCESS: {
      return {
        ...state,
        assets: payload,
        isImporting: false,
      };
    }
    case ASSETS_TYPES.IMPORT_ALL_ASSETS.FAILURE: {
      return {
        ...state,
        loading: false,
        failure: payload,
        isImporting: false,
      };
    }
    case ASSETS_TYPES.IMPORT_ALL_CUSTOM_CONVERSIONS.REQUEST: {
      return {
        ...state,
        isImporting: true,
      };
    }
    case ASSETS_TYPES.IMPORT_ALL_CUSTOM_CONVERSIONS.SUCCESS: {
      return {
        ...state,
        facebookPixels: payload,
        isImporting: false,
      };
    }
    case ASSETS_TYPES.IMPORT_ALL_CUSTOM_CONVERSIONS.FAILURE: {
      return {
        ...state,
        loading: false,
        failure: payload,
        isImporting: false,
      };
    }
    case ASSETS_TYPES.GET_CONVERSIONS_EVENTS.REQUEST: {
      return {
        ...state,
        isImporting: true,
      };
    }
    case ASSETS_TYPES.GET_CONVERSIONS_EVENTS.SUCCESS: {
      return {
        ...state,
        facebookPixels: payload,
        isImporting: false,
      };
    }
    case ASSETS_TYPES.GET_CONVERSIONS_EVENTS.FAILURE: {
      return {
        ...state,
        loading: false,
        failure: payload,
        isImporting: false,
      };
    }
    case ASSETS_TYPES.GET_ASSETS.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ASSETS_TYPES.GET_ASSETS.SUCCESS: {
      return {
        ...state,
        loading: false,
        assets: payload,
      };
    }
    case ASSETS_TYPES.GET_ASSETS.FAILURE: {
      return {
        ...state,
        loading: false,
        failure: payload,
      };
    }
    case ASSETS_TYPES.GET_FACEBOOK_PAGES.REQUEST: {
      return {
        ...state,
        loadingFacebook: true,
      };
    }
    case ASSETS_TYPES.GET_FACEBOOK_PAGES.SUCCESS: {
      return {
        ...state,
        loadingFacebook: false,
        facebookPages: payload,
        facebookTotalCount: totalCount,
      };
    }
    case ASSETS_TYPES.GET_FACEBOOK_PAGES.FAILURE: {
      return {
        ...state,
        loadingFacebook: false,
        failure: payload,
      };
    }
    case ASSETS_TYPES.GET_FACEBOOK_PIXELS.REQUEST: {
      return {
        ...state,
        loadingPixels: true,
      };
    }
    case ASSETS_TYPES.GET_FACEBOOK_PIXELS.SUCCESS: {
      return {
        ...state,
        loadingPixels: false,
        facebookPixels: payload,
        pixelsTotalCount: totalCount,
      };
    }
    case ASSETS_TYPES.GET_FACEBOOK_PIXELS.FAILURE: {
      return {
        ...state,
        loadingPixels: false,
        failure: payload,
      };
    }
    case ASSETS_TYPES.GET_INSTAGRAM_ACCOUNTS.REQUEST: {
      return {
        ...state,
        loadingInstagram: true,
      };
    }
    case ASSETS_TYPES.GET_INSTAGRAM_ACCOUNTS.SUCCESS: {
      return {
        ...state,
        loadingInstagram: false,
        instagramAccounts: payload,
        instagramTotalCount: totalCount,
      };
    }
    case ASSETS_TYPES.GET_INSTAGRAM_ACCOUNTS.FAILURE: {
      return {
        ...state,
        loadingInstagram: false,
        failure: payload,
      };
    }
    case ASSETS_TYPES.GET_ENTIRE_FB_LIST.REQUEST: {
      return {
        ...state,
        loadingEntireFb: true,
      };
    }
    case ASSETS_TYPES.GET_ENTIRE_FB_LIST.SUCCESS: {
      return {
        ...state,
        loadingEntireFb: false,
        entireFbList: payload,
      };
    }
    case ASSETS_TYPES.GET_ENTIRE_FB_LIST.FAILURE: {
      return {
        ...state,
        loadingEntireFb: false,
        failure: payload,
      };
    }
    case ASSETS_TYPES.GET_ENTIRE_IG_LIST.REQUEST: {
      return {
        ...state,
        loadingEntireIg: true,
      };
    }
    case ASSETS_TYPES.GET_ENTIRE_IG_LIST.SUCCESS: {
      return {
        ...state,
        loadingEntireIg: false,
        entireIgList: payload,
      };
    }
    case ASSETS_TYPES.GET_ENTIRE_IG_LIST.FAILURE: {
      return {
        ...state,
        loadingEntireIg: false,
        failure: payload,
      };
    }
    case ASSETS_TYPES.GET_ENTIRE_PIXELS_LIST.REQUEST: {
      return {
        ...state,
        loadingEntirePix: true,
      };
    }
    case ASSETS_TYPES.GET_ENTIRE_PIXELS_LIST.SUCCESS: {
      return {
        ...state,
        loadingEntirePix: false,
        entirePixList: payload,
      };
    }
    case ASSETS_TYPES.GET_ENTIRE_PIXELS_LIST.FAILURE: {
      return {
        ...state,
        loadingEntirePix: false,
        failure: payload,
      };
    }
    case ASSETS_TYPES.DELETE_ASSET.REQUEST: {
      return {
        ...state,
        deleteLoading: true,
      };
    }
    case ASSETS_TYPES.DELETE_ASSET.SUCCESS: {
      return {
        ...state,
        deleteLoading: false,
        assets: payload,
      };
    }
    case ASSETS_TYPES.DELETE_ASSET.FAILURE: {
      return {
        ...state,
        deleteLoading: false,
        failure: payload,
      };
    }
    case ASSETS_TYPES.CLEAR_FAILURE: {
      return {
        ...state,
        failure: {},
      };
    }

    default: {
      return state;
    }
  }
};
