import React from 'react';
import styles from './PrivacyPolicy.module.scss';
import UserAgreements from "../../../containers/UserAgreements";
import { useHistory } from "react-router";

const PrivacyPolicy = () => {
  const history = useHistory()

  const goBack = () => {
    const prevUrl = localStorage.getItem('PREVIOUS_URL');
    if (prevUrl) history.push(prevUrl);
    else history.push('/');
    localStorage.removeItem('PREVIOUS_URL');
  }

  return (
    <div className={styles.root}>
      <UserAgreements
        title={'Privacy Policy'}
        onSubmit={goBack}
      >
        <div className={styles.textBlock}>
          Updated: 29 June 2022<br/>
          Effective: 04 July 2021
        </div>
        <div className={styles.textBlock}>
          This Privacy Policy (<b>“Policy“</b>) describes how Otterfish ( <b>“we“</b>, <b>“our“</b>, or <b>“our“</b>) and its affiliates collect, use, and share information
          in connection with an end user’s (<b>“you“</b>, <b>“your“</b>, or <b>“user“</b>) use of the Otterfish website and app located at
          <a href='https://otterfish.com'>https://otterfish.com</a>
          and
          <a href='https://app.otterfish.com'>https://app.otterfish.com</a>
          and other websites and online services that we make available to you and that link to this Policy (collectively, the <b>“Services“</b>).
          It also describes your rights and choices with respect to the information we collect.
        </div>
        <div className={styles.textBlock}>
          By agreeing to this Policy when registering for an account on the Services and/or using any of our Services, or the features made available to you
          through the Services, you are agreeing to the terms of this Policy and consenting to the collection, use, and sharing of information in a manner
          consistent with this Policy.
        </div>


        <div className={styles.header}>1. Collected Information.</div>
        <div className={styles.textBlock}>We collect a variety of information in connection with the Services, including the following:</div>
        <div className={styles.textBlock}>
          (a) You can visit the Site without submitting any information that we can use to identify you personally.
          However, if you use certain features on the Site, such as the “Contact Us” or “Book Demo” feature, you will be required to provide personal information.
          Such information could include, for example, your name, email address, or phone number.
        </div>
        <div className={styles.textBlock}>
          (b) <em>Registration and Profile Information</em>. We collect your name, email address, and password when you register for an account on the Services.
          We also may collect other optional information you choose to provide as part of such registration and as part of creating your user profile and account within the Services.
          This may include, for example, your phone number, your name, organization name, your email address
        </div>
        <div className={styles.textBlock}>
          (c) <em>Company, and Business Information.</em> We collect information and data related to your company and the services that you provide on the business profile or member page.
          For example, this may include information included in a company profile, company address, team members name and email address
        </div>
        <div className={styles.textBlock}>
          (d) <em>Usage and Communications Information.</em> When you use the Services, we collect information about your use of the Services,
          such as the searches you conduct on the Services, the content that you post, share, and access on the Services,
          your interactions with your collaborators  on the Services, and communications made through the Services.
        </div>
        <div className={styles.textBlock}>
          (e) <em>Information about Your Customers and Prospects.</em> To help manage your business and customer relationships,
          we may collect certain information that you provide to us about your customers and prospective customers, such as name, email, address,
          and information about your interactions with them, and other information that you choose to provide to us.
        </div>
        <div className={styles.textBlock}>
          (f) <em>Other Information You Provide to Us.</em> We collect other information you choose to provide to us as part of using the Services or through customer service requests,
          surveys, and other communications. To the extent you decline to share certain information with us, we may not be able to provide some or all of the
          features and functionalities found on the Services.
        </div>
        <div className={styles.textBlock}>
          (g) <em>Automatically Collected Information.</em> When you use our Services, we or our third-party service providers may automatically receive and record
          certain information from your device or through the Services. For example, this may include your device’s IP address, user-agent,
          web pages you visit or features you use within the Services, the date and time of your activities on the Services, time since your last visit,
          links you click, searches conducted, the website visited before navigating to the Services,
          your software and hardware attributes (including browser and operating system type and version, device screen size, mobile app version, device type,
          and device identifiers), and your general location inferred from IP address. To obtain such information,
          we or our third-party service providers may use the following technologies to recognize your device and collect information about your device and Services usage:
        </div>
        <ul>
          <li>Server logs. When you use the Services, we automatically receive and record certain information from your computer (or other device) and your browser.
            To obtain such information, we may use server logs or applications that recognize your device and gather information about its online activity.
            These are not linked to any information that is personally identifiable.
          </li>
          <li>Cookies and Web beacons. We also use cookies and we beacons to collect and sore information such as user preferences. </li>
        </ul>
        <div className={styles.textBlock}>What’s a cookie?</div>
        <div className={styles.textBlock}>
          A cookie is a small piece of data (a text file) that a website asks your browser to store on your device in order to remember
          information about you when you visit it, such as your language preference or login information.
        </div>
        <div className={styles.textBlock}>What’s a web beacon?</div>
        <div className={styles.textBlock}>
          A web beacon is a string of HTML or JavaScript code embedded in a website or email that provides a method for transferring
          data to a company’s servers. For example, it may load a single-pixel image that permits us or our service providers to generate a record of your visit
          and set or read cookies. This allows us to understand the features you use or the pages you visit on our Services and whether you have opened a particular email.
        </div>
        <div className={styles.textBlock}>
          Cookies that we’ve set up ourselves are called “first party” cookies. We also use “third party” cookies,
          which are cookies from domains that aren’t our website domain, for our advertising and marketing efforts.
        </div>
        <div className={styles.textBlock}>More specifically, we use cookies and other tracking technologies to:</div>
        <ul>
          <li>Assist you in navigation;</li>
          <li>Assist you in registering for our products, sign in, and your ability to provide feedback;</li>
          <li>Analyze your use of our products, services or applications;</li>
          <li>Improve our promotional and marketing efforts.</li>
        </ul>
        <div className={styles.textBlock}>
          Below is a detailed list of the cookies we use on our website. Our website is scanned with our cookie scanning tool regularly
          to maintain a list as accurate as possible. We classify cookies in the following categories:
        </div>
        <ul>
          <li>Strictly Necessary Cookies</li>
          <li>Performance Cookies</li>
          <li>Functional Cookies</li>
        </ul>
        <div className={styles.textBlock}>
          Strictly Necessary Cookies
        </div>
        <div className={styles.textBlock}>
          These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to
          actions made by you which amount to a request for services, such as setting your privacy preferences, signing in, or filling in forms.
          You can set your browser to block or alert you about these cookies, but then some parts of the site will not work.
          These cookies do not store any personally identifiable information.
        </div>
        <div className={styles.textBlock}>Analytics Cookies</div>
        <div className={styles.textBlock}>
          These cookies allow us to count visits and traffic sources, so we can measure and improve the performance of our site.
          They help us know which pages are the most and least popular and see how visitors move around the site.
          All information these cookies collect is aggregated and therefore anonymous.
          If you do not allow these cookies, we will not know when you have visited our site.
        </div>
        <div className={styles.textBlock}>Functional Cookies</div>
        <div className={styles.textBlock}>
          These cookies enable the website to provide enhanced functionality and personalisation.
          They may be set by us or by third party providers whose services we have added to our pages.
          If you do not allow these cookies then some or all of these services may not function properly.
        </div>
        <div className={styles.textBlock}>
          We might sometimes partner with third-party services who may use various tracking technologies to provide certain services or features on our sites,
          including targeted online marketing or relevant on-site messaging.
          These third-party services use cookies to anonymously collect data and allow them to recognize your computer or mobile device each time you visit any of our sites.
          No personally identifiable information is collected by these cookies.
          The anonymous data they collect is kept separate from the personal information about you as a user that we collect.
        </div>
        <div className={styles.textBlock}>Your options when it comes to cookies, web beacons and similar technologies</div>
        <div className={styles.textBlock}>
          You can change your web browser’s settings to reflect your cookie preferences.
          Use these links to find out more information about cookie settings for these common browsers:
        </div>
        <ul>
          <li><a href='http://windows.microsoft.com/en-US/windows7/Block-enable-or-allow-cookies'>Internet Explorer</a></li>
          <li><a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">Firefox</a></li>
          <li><a href="https://support.google.com/chrome/answer/95647?hl=en">Chrome</a></li>
          <li><a href="https://support.apple.com/en-us/HT201265">Safari</a></li>
        </ul>
        <div className={styles.textBlock}>
          (i) <em>Transaction and Payment Information.</em> Our payment services providers Stripe, collect your credit or debit card and billing information directly to facilitate payments.
          Please visit the privacy policies of stripe https://stripe.com/en-nz/privacy if you have questions regarding their privacy practices.
        </div>

        <div className={styles.header}>2. Use of Information.</div>
        <div className={styles.textBlock}>We and our service providers use information for a variety of purposes, including as follows:</div>
        <div className={styles.textBlock}>
          (a) <em>Provision of Services.</em> We use collected information for the purposes for which you provided the information including,
          for example, to create and maintain a Services account for you, respond to a question that you e-mail to us, or process payments.
          We also use the collected information as necessary to provide the features and functionality of the Services to you and to manage our business.
        </div>
        <div className={styles.textBlock}>
          (b) <em>Updates and Troubleshooting.</em> We may use the collected information to contact you regarding updates or modifications to the Services,
          to authenticate your account, to help troubleshoot problems, or to alert you to changes in our policies or agreements that may affect your use of the Services.
        </div>
        <div className={styles.textBlock}>
          (c) <em>Personalized Content and Advertising, Surveys, and Improvements.</em> We may use the collected information to personalize the content and
          advertising that you and others see. We may also conduct surveys and analyze collected information relating to your use of the Services
          in order to help us improve the Services, and develop and improve other products and services.
        </div>
        <div className={styles.textBlock}>
          (d) <em>Newsletters, Promotional Emails, Offers, and Joint Marketing.</em> We may use the collected information to provide you with newsletters,
          promotional materials, and offers via e-mail. We also may use your information to engage in joint marketing with other businesses that
          we believe may be of interest to you. We will give you the ability to opt out of receiving such e-mails in accordance with applicable law.
        </div>
        <div className={styles.textBlock}>
          (e) <em>Compiling Aggregated Information.</em> We may use the collected information to compile aggregated and non-personally identifiable statistics
          or reports that we may share with third parties.
        </div>
        <div className={styles.textBlock}>
          (g) <em>Legal Purposes.</em> We may use the collected information for legal and other necessary purposes, including as described below in the section titled “Disclosure of Information.”
        </div>
        <div className={styles.textBlock}>
          (h) <em>Combination.</em> We may combine information collected through the Services with other information that we or third parties collect about you in other contexts,
          such as our communications with you via e-mail, phone, or your customer service records. We will treat such combined information in accordance with this Policy.
        </div>


        <div className={styles.header}>3. Disclosure of Information.</div>
        <div className={styles.textBlock}>We disclose information to third parties in various contexts, including as follows:</div>
        <div className={styles.textBlock}>
          (a) <em>Third-Party Plugins.</em> The Services may integrate third-party plugins (such as a Facebook “like” button and Twitter “follow” button).
          Even if you do not click on these plugins, they may collect information about you, such as your IP address and the pages that you view.
          They also may set and/or access a cookie or use similar tracking technologies. These plugins are governed by the privacy policy of the company providing them.
        </div>
        <div className={styles.textBlock}>
          (b) <em>Legal Requirements.</em> We may use and disclose any information where we believe, in our sole reasonable discretion, that doing so is necessary:
        </div>
        <ul>
          <li>To comply with applicable law or a court order, subpoena, or other legal processes;</li>
          <li>
            To investigate, prevent, or take action regarding illegal activities, suspected fraud, violations of our terms and conditions,
            or situations involving threats to our property or the property or physical safety of any person or third party;
          </li>
          <li>
            To establish, protect, or exercise our legal rights or defend against legal claims;
          </li>
          <li>
            To facilitate the financing, securitization, insuring, sale, assignment, bankruptcy, or other disposal of all or part of our business or assets.
          </li>
        </ul>
        <div className={styles.textBlock}>
          (c) <em>Affiliates.</em> We may disclose collected information to our affiliates for use as described in this Policy.
        </div>
        <div className={styles.textBlock}>
          (d) <em>Transfer as Corporate Asset.</em> In the event of a merger, sale of capital stock or assets, investment, reorganization, bankruptcy,
          consolidation, or similar transaction involving Company, we may share the information we possess to facilitate the transaction,
          including during due diligence, or as a corporate asset to the acquiring entity.
        </div>
        <div className={styles.textBlock}>(e) To manage our business.</div>

        <div className={styles.header}>4. Third-Party Analytics</div>
        <div className={styles.textBlock}>
          We partner with third parties to engage in analytics, auditing, research, and reporting on our Services.
          These third parties collect information regarding your usage of the Services and may use server logs, cookies,
          web beacons, tags, pixels, mobile advertising IDs (such as Apple’s IDFA or Google’s Advertising ID), and similar technologies.
          We also may share user IDs with these third parties to track user activities across sessions and devices.
          These service providers include, but are not limited to, Google Analytics, Hotjar, Segment, Customer.io, Hubspot and Mixpanel.
        </div>
        <ul>
          <li>You can learn more about Google Analytics’ data practices by clicking
            <a href="https://www.google.com/analytics/learn/privacy.html">here</a>
            and opt out by clicking
            <a href="https://tools.google.com/dlpage/gaoptout">here</a>.
          </li>
          <li>
            Mixpanel collects and analyzes users’ movements while visiting the Services, including the links you click and features you use.
            You can review Mixpanel’s privacy policy by clicking
            <a href="https://mixpanel.com/privacy/">here</a>
            and opt out by clicking
            <a href="https://mixpanel.com/optout">here</a>.
          </li>
          <li>
            We use <a href="https://www.hotjar.com/">Hotjar</a> in order to better understand our users’ needs and to optimize this service and experience.
            Hotjar is a technology service that helps us better understand our users experience (e.g. how much time they spend on which pages,
            which links they choose to click, what users do and don’t like, etc.) and this enables us to build and maintain our service with user feedback.
            Hotjar uses cookies and other technologies to collect data on our users’ behavior and their devices (in particular device's IP address
            (captured and stored only in anonymized form), device screen size, device type (unique device identifiers), browser information, geographic
            location (country only), preferred language used to display our website). Hotjar stores this information in a pseudonymized user profile.
            Neither Hotjar nor we will ever use this information to identify individual users or to match it with further data on an individual user. For further details,
            please see Hotjar’s privacy policy by clicking on <a href="https://www.hotjar.com/legal/policies/privacy">this link</a>.
            You can opt-out to the creation of a user profile, Hotjar’s storing of data about your usage of our site and Hotjar’s use of tracking cookies on other websites by following this
            <a href="https://www.hotjar.com/legal/compliance/opt-out">opt-out link</a>.
          </li>
          <li>You can learn more about Segment’s data practices by clicking <a href="https://www.twilio.com/legal/privacy">here</a> . You can also contact Segment’s
            <a href="https://support.twilio.com/hc/en-us">Customer Support</a> Team to communicate your choice to opt out.
          </li>
          <li>You can learn more about customer.io’s data practices by clicking
            <a href="https://customer.io/legal/privacy-policy/">here</a>
            and opt out by clicking
            <a href="https://customer.io/legal/personal-information-rights-request/">here</a>.
          </li>
        </ul>
        <div className={styles.textBlock}>We do not currently engage in interest-based advertising.</div>
        <div className={styles.textBlock}>Our Services do not respond to browser Do Not Track signals at this time.</div>


        <div className={styles.header}>5. California Privacy Notice,  CCPA Privacy Rights (Do Not Sell My Personal Information)</div>
        <div className={styles.textBlock}>
          If you are a California resident, the California Consumer Privacy Act (<b>“CCPA“</b>) provides you with certain
          rights regarding your personal information, including the right to know about personal information collected, disclosed, or sold;
          the right to request that we delete personal information that we have collected from you; the right to opt-out of the sale of your personal information;
          and the right not to be discriminated against for exercising your rights. These rights are subject to certain exceptions.
        </div>
        <div className={styles.textBlock}>Collection of Personal Information</div>
        <div className={styles.textBlock}>
          If you are an Otterfish  user and you create an account with us to use the Services, we may collect information that identifies,
          relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly,
          with a particular consumer or household (“personal information”). We may collect the categories of personal data described in Section 1 above,
          including following categories of personal information about you,
        </div>
        <ul>
          <li>Identifiers, such as your name, email address, online identifier, account name, and IP address.</li>
          <li>Categories of personal information described in subdivision (e) of California Civil Code Section 1798.80, such as your name and address, if you choose to provide it to us.</li>
          <li>Commercial information, such as your transaction history / services purchased.</li>
          <li>Internet or other electronic network activity information, such as your browsing history, cookies, web logs, pixels, and IP addresses.</li>
          <li>Geolocation data.</li>
          <li>Professional or employment-related information.</li>
          <li>Inferences drawn from any of the information identified above.</li>
        </ul>
        <div className={styles.textBlock}>We collect the personal information listed above from the sources specified in Section 1, including following sources:</div>
        <ul>
          <li>From you directly when you sign up to use the Services.</li>
          <li>Your operating device (such as your computer or mobile device) and your web browser.</li>
          <li>Third-party services providers, such as those we engage to provide data analytics services.</li>
          <li>Payment service providers.</li>
        </ul>
        <div className={styles.textBlock}><u>Your Rights</u></div>
        <div className={styles.textBlock}>If we maintain personal information about you that is subject to CCPA, you may exercise certain rights in connection with this data if you are a California resident.</div>
        <div className={styles.textBlock}>Right to Know</div>
        <div className={styles.textBlock}>
          California residents have the right to request that we disclose certain information about our collection and use of your personal information
          over the past twelve months (<b>“Right to Know“</b>). Once we receive and confirm your verifiable consumer request, we will disclose to you:
        </div>
        <ul>
          <li>The categories of personal information we collected about you.</li>
          <li>The categories of sources for the personal information we collected about you.</li>
          <li>The categories of personal information that we have disclosed for a business or commercial purpose.</li>
          <li>Our business or commercial purposes for collecting or selling personal information.</li>
          <li>The categories of third parties with whom we share that personal information.</li>
          <li>The specific pieces of personal information we collected about you (i.e., a data portability request).</li>
        </ul>
          <div className={styles.textBlock}><em>Right to Delete</em></div>
          <div className={styles.textBlock}>
            California residents have the right to request that we delete the personal information that we collected from you and retain,
            subject to certain exceptions (<b>“Right to Delete“</b>). Once we receive and verify your request, we will delete (and direct our service providers/vendors to delete)
            your personal information from our records, unless an exception applies.
          </div>
          <div className={styles.textBlock}><em>Right to Opt-Out of Sale</em></div>
          <div className={styles.textBlock}>The CCPA provides California residents with the right to opt-out of the sale of their personal information. <b>We do not sell your personal information.</b></div>
          <div className={styles.textBlock}><em>Right to Nondiscrimination</em></div>
          <div className={styles.textBlock}>
            The CCPA provides California residents with the right not to receive discriminatory treatment for the exercise of their privacy rights conferred by the CCPA.
            We will not discriminate against you for exercising any of your CCPA rights.
          </div>
          <div className={styles.textBlock}><u>Exercising Your Rights</u></div>
          <div className={styles.textBlock}>
            If you are a California resident and wish to request for us to provide you with <b>your Right to Know or Right to Delete</b>,
            you may submit a request by us by contacting us in accordance with Section <b>12 below.</b>
          </div>
          <div className={styles.textBlock}>
            When you submit a request, we will confirm your identity by asking you to provide your name and email address associated with your account.
            We will verify your identity by matching the information you provide with what we have on file for you.
            We may ask you for additional information to verify your identity or to comply with your request.
          </div>
          <div className={styles.textBlock}><em>Authorized Agent</em></div>
          <div className={styles.textBlock}>
            You may authorize an agent to make a request on your behalf. To designate an agent, please provide a written and signed document by both you and the
            agent that authorizes the agent to act on your behalf. You may also use a power of attorney. We will still require you to provide information to allow
            us to reasonably verify that you are the person about whom we collected personal information.
          </div>
          <div className={styles.textBlock}><u>Contact for More Information</u></div>
          <div className={styles.textBlock}>If you wish to contact us regarding this California Privacy Notice, you may do so in accordance with Section 12 below.</div>


          <div className={styles.header}>6. Your Privacy Rights and Choices.</div>
          <div className={styles.textBlock}>Otterfish provides you with choices to review, access, and update your information or to exercise your privacy or data protection rights, as follows:</div>
          <ul>
            <li>
              You can log into your account and view, amend, or delete your account information at any time. Please
              note that while changes to your profile information are reflected promptly in active user databases, our
              servers or backups may retain previously provided information. In addition, we may retain certain
              information if you previously shared it with other users. Deletion of a mobile device application from
              your device or deletion of your Services user account will not result in the deletion of information from
              our servers.
            </li>
            <li>
              If you no longer want to receive marketing communications from Otterfish, please follow the “unsubscribe”
              instructions that are included at the bottom of each message. Please note that if you unsubscribe from our newsletter
              or marketing communications, you will still receive administrative messages.
            </li>
            <li>
              You may have rights under applicable laws to request access to, correction or deletion of, or restrictions on the processing of certain information.
              You also may have rights under applicable laws to object to processing, opt out or withdraw consent to further processing,
              or request copies of your data. To make such request and/or inquire about such rights, please email at
              <a>support@otterfish.com</a>
              with “Attn: Privacy Questions” in the subject line.
              For your protection, we may need to verify your identity or authenticate your information before implementing your request.
            </li>
            <li>
              If you are a user in the European Economic Area, you have the right to lodge a complaint regarding our data processing with a supervisory authority.
              The EU Commission provides a list of supervisory authorities here:
              <a href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm">https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm</a>
            </li>
          </ul>

        <div className={styles.header}>7. Third-Party Links, and Services.</div>
        <div className={styles.textBlock}>
          The Services may contain  links to websites or mobile apps of other third parties,
          including social media sharing features that link to third-party websites.
          If you follow a link to any of these websites or apps, please note that these websites and apps,
          and any services that may be accessible through them, have their own privacy policies.
          We are not responsible for the privacy practices of other websites or apps or the information you share through such other websites or apps.
          We encourage our users to be aware when they leave the Services and to read the privacy policies applicable to such third-party websites and apps.
          This Policy applies solely to information collected in connection with the Services.
        </div>


        <div className={styles.header}>8. Data Security and Retention.</div>
        <div className={styles.textBlock}>
          Otterfish uses reasonable physical, technical, and administrative safeguards to protect your information against loss or unauthorized access,
          use, modification, or deletion. However, no security program is 100% secure, and thus we cannot guarantee the absolute security of your information.
          We retain your information for as long as reasonably necessary for our legitimate business purposes, to provide the Services to you,
          to fulfill the purposes described in this Policy, or as otherwise required or permitted by law. When we destroy your personal information,
          we do so in a way that prevents that information from being restored or reconstructed.
        </div>


        <div className={styles.header}>9. Children’s Privacy.</div>
        <div className={styles.textBlock}>
          The Services are not directed to children under 13 and the minimum age for use of Otterfish is 18.
          If you are a parent and believe your child under the age of 13 has used the Services and provided information to us through the Services,
          please contact us as described in the section below titled “Contacting Us” and we will work to delete that Services account and any such information.
        </div>


        <div className={styles.header}>10. GDPR Data Protection Rights</div>
        <div className={styles.textBlock}>
          We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
        </div>
        <ul>
          <li>The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</li>
          <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</li>
          <li>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</li>
          <li>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
          <li>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</li>
          <li>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
        </ul>
        <div className={styles.textBlock}>
          If you make a request, we have one month to respond to you. If you would like to exercise any of these rights,
          please contact us by emailing us at
          <a>support@otterfish.com</a>
          with “Attn: Privacy Questions” in the subject line
        </div>


        <div className={styles.header}>11. Sensitive Information</div>
        <div className={styles.textBlock}>
          We ask that you not send us, and you not disclose, any sensitive information that is not required to obtain or provide the Services
          (e.g., information related to racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership,
          genetic or biometric data, health data, or sexual orientation) on or through the Services or otherwise to us.
        </div>


        <div className={styles.header}>12. Changes to this Policy.</div>
        <div className={styles.textBlock}>
          We may revise this Policy periodically in the future.
          When we make any material changes to this Policy, we will change the Effective Date and post the revised Policy to our Services.
          We encourage you to review the Policy periodically. We will treat your continued use of the Services following such change as your acceptance of the changes.
        </div>

        <div className={styles.header}>13. Contacting Us.</div>
        <div className={styles.textBlock}>
          If you have any questions about this Policy, please email us at
          <a>support@otterfish.com</a>
          with “Attn: Privacy Questions” in the subject line or contact us at:
        </div>
        <div className={styles.textBlock}>
          Otterfish, Inc.<br/>
          Attn: Privacy Questions<br/>
          QB 20, 208 Ponsonby Rd,<br/>
          Auckland, NZ, 1011<br/>
        </div>
      </UserAgreements>
    </div>
  );
}

export default PrivacyPolicy;
