import React from 'react';
import styles from './NoOrganizations.module.scss';
import OtterfishBackImg from '../../../assets/img/icons/otterfish_background_no_organizations.png';

const NoOrganizations = ({ title, text }) => (
  <div className={styles.root}>
    <img src={OtterfishBackImg} alt="otterfish back image" />
    <div className={styles.description}>
      <div className={styles.title}>{title}</div>
      <div className={styles.text}>{text}</div>
    </div>
  </div>
);

export default NoOrganizations;
