import React, { useEffect, useMemo } from 'react';
import styles from './InstagramStoryPreview.module.scss';
import Stories from 'react-insta-stories';
import { ReactComponent as EmptyImage } from '../../../assets/img/icons/not_uploaded_icon.svg';
import VideoRenderer from './renderers/VideoRenderer';
import ImageRenderer from './renderers/ImageRenderer';

/**
 * @description
 * Provide {assets} array with objects, with different CTA in each object. Or provide {callToAction} for all pages.
 * Provide {currentIndex}, {setIndex} and {onStoryEnd} to manage slides externally. If not provided, default controls will be used.
 * @param {Array<{url?: string, cta?: string, copy?: string}>} assets
 * @param {string} callToAction
 * @param {string} pageName
 * @param {number} currentIndex
 * @param {(index: number, story: object) => void} onStoryEnd
 * @param {boolean} disableDefaultControls
 */
const InstagramStoryPreview = ({
  assets,
  callToAction,
  pageName,
  currentIndex,
  setIndex,
  onStoryEnd = () => {},
  disableDefaultControls = false,
}) => {
  const stories = useMemo(
    () =>
      assets.map(item => {
        const story = {
          pageName,
          url: item.url,
          copy: item.copy,
          type: item.mime_type.match(/^\w*/g)[0],
          cta: item.cta || callToAction,
        };
        return story;
      }),
    [assets]
  );

  useEffect(() => {
    if (stories.length - 1 < currentIndex) {
      setIndex(0);
    }
  }, [stories]);

  return (
    <div className={styles.root}>
      {stories?.length ? (
        <Stories
          renderers={[VideoRenderer, ImageRenderer]}
          preventDefault={disableDefaultControls || !(onStoryEnd && currentIndex)}
          currentIndex={currentIndex > stories.length - 1 ? 0 : currentIndex}
          loop
          stories={stories || []}
          onStoryEnd={onStoryEnd}
          defaultInterval={14000}
          width="100%"
          height="100%"
        />
      ) : (
        <div className={styles.emptyImage}>
          <EmptyImage />
          <span>No image yet</span>
        </div>
      )}
    </div>
  );
};

export default InstagramStoryPreview;
