import dayjs from 'dayjs';

export function formikBriefItemInitialValues(values) {
  return {
    itemName: '',
    messaging: '',
    campaignObjective: [],
    hashtags: [],
    socialTagging: [],
    gender: '',
    showExclusivity: false,
    exclusivity: '',
    ageMax: null,
    ageMin: null,
    total_budget: '',
    boosting_duration: undefined,
    boosting_manager: [],
    boosting_budget: undefined,
    total_budget_currency: 'NZD',
    boosting_budget_currency: 'NZD',
    date_start: null,
    date_end: null,
    date_post: null,
    adInCaption: false,
    repostingPlatform: [],
    repostingDuration: 1,
    city: [],
    country: [],
    interests: [],
    suggestedCreators: '',
    suggestedCreatorsDescription: '',
    placementsQuantity: 1,
    other_infos: '',
    showBoosting: false,
    showSocial: false,
    showCreators: false,
    ...values,
  };
}

export function briefItemFromFormikToAPI(formik, deliverablesFormik) {
  return {
    name: formik.values.itemName || null,
    key_message: formik.values.messaging || null,
    objective: formik.values.campaignObjective,
    date_start: formik.values.date_start ? dayjs(formik.values.date_start).format('YYYY-MM-DD') : null,
    date_end: formik.values.date_end ? dayjs(formik.values.date_end).format('YYYY-MM-DD') : null,
    live_date_start: formik.values.date_post ? dayjs(formik.values.date_post).format('YYYY-MM-DD') : null,
    live_date_end: null,
    target_audience: {
      location: {
        country: formik.values.country,
        city: formik.values.city,
      },
      gender: formik.values.gender ? [formik.values.gender] : [],
      age: {
        min: formik.values.ageMin, // Number 13-65, 65 = 65+
        max: formik.values.ageMax, // Number 13-65
      },
      interests_and_behaviours: formik.values.interests,
    },
    exclusivity: formik.values.exclusivity,
    reposting_platform: formik.values.repostingPlatform?.length ? formik.values.repostingPlatform : null,
    reposting_duration: formik.values.repostingPlatform?.length ? formik.values.repostingDuration : null,
    hashtags: formik.values.hashtags,
    social_tagging: formik.values.socialTagging,
    deliverables: deliverablesFormik.values.deliverables,
    total_budget: formik.values.total_budget || null,
    boosting_budget: formik.values.boosting_budget || null,
    boosting_duration: formik.values.boosting_duration || null,
    boosting_manager: !!formik.values.boosting_manager.length ? formik.values.boosting_manager : null,
    total_budget_currency: formik.values.total_budget_currency,
    boosting_budget_currency: formik.values.total_budget_currency, // INTENDED!!!
    preferred_ad_declaration: formik.values.adInCaption ? 'ad_in_caption' : 'paid_partnership_tool',
    ...(formik.values.suggestedCreators && { suggested_creators: formik.values.suggestedCreators }),
    ...(formik.values.suggestedCreatorsDescription && {
      suggested_creators_description: formik.values.suggestedCreatorsDescription ? formik.values.suggestedCreatorsDescription : '',
    }),
    other_infos: formik.values.other_infos || null,
  };
}

export async function addFormikValuesFromBrief(formik, deliverablesFormik, briefItemData) {
  await formik.setValues(
    {
      messaging: briefItemData.key_message,
      campaignObjective: briefItemData.objective,
      hashtags: briefItemData.hashtags,
      socialTagging: briefItemData.social_tagging,
      total_budget_currency: briefItemData.total_budget_currency,
      boosting_budget_currency: briefItemData.boosting_budget_currency,
      adInCaption: briefItemData.preferred_ad_declaration === 'ad_in_caption',
      gender: briefItemData.target_audience.gender[0] || '',
      ageMin: briefItemData.target_audience.age.min || null,
      ageMax: briefItemData.target_audience.age.max || null,
      interests: briefItemData.target_audience.interests_and_behaviours,
      city: briefItemData.target_audience.location.city,
      country: briefItemData.target_audience.location.country,
      exclusivity: briefItemData.exclusivity,
      showExclusivity: !!briefItemData.exclusivity,
      itemName: briefItemData.name || '',
      date_start: briefItemData.date_start ? dayjs(briefItemData.date_start).toDate() : null,
      date_end: briefItemData.date_end ? dayjs(briefItemData.date_end).toDate() : null,
      date_post: briefItemData.live_date_start ? dayjs(briefItemData.live_date_start).toDate() : dayjs(new Date()).toDate(),
      total_budget: briefItemData.total_budget || null,
      boosting_budget: briefItemData.boosting_budget || undefined,
      boosting_duration: briefItemData.boosting_duration || undefined,
      boosting_manager: briefItemData.boosting_manager || [],
      repostingPlatform: briefItemData.reposting_platform || [],
      repostingDuration: briefItemData.reposting_duration || null,
      suggestedCreators: briefItemData.suggested_creators || '',
      suggestedCreatorsDescription: briefItemData.suggested_creators_description || '',
      other_infos: briefItemData.other_infos,
    },
    false
  );

  await deliverablesFormik.setValues(
    {
      deliverables: briefItemData.deliverables,
      socialPlatforms: briefItemData.deliverables.map(item => item.platform),
      deliverablesFacebook: briefItemData.deliverables.filter(item => item.platform == 'facebook'),
      deliverablesInstagram: briefItemData.deliverables.filter(item => item.platform == 'instagram'),
      deliverablesTiktok: briefItemData.deliverables.filter(item => item.platform == 'tiktok'),
      deliverablesYoutube: briefItemData.deliverables.filter(item => item.platform == 'youtube'),
    },
    true
  );
}
