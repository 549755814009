import React, { useEffect, useRef, useState } from 'react';
import styles from './ContentName.module.scss';
import { ReactComponent as EditIcon } from '../../../../../../assets/img/icons/edit_pen.svg';
import { useSelector } from 'react-redux';
import TextField from '../../../../../../components/FormControls/TextField';
import cs from 'classnames';
import * as contentApprovalServices from '../../../../../../services/contentApproval';
import { useParams } from 'react-router-dom';
import { updateContentBoard } from '../../../../../../services/contentApproval';

const ContentName = () => {
  const params = useParams();
  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const { openedBoard } = useSelector(state => state.contentApproval);
  const [isEdit, setIsEdit] = useState(false);
  const [nameSaveLoading, setNameSaveLoading] = useState(false);
  const [contentName, setContentName] = useState(openedBoard?.name);

  const inputRef = useRef(null);

  useEffect(() => {
    if (isEdit) {
      inputRef.current.focus();
    }
  }, [isEdit]);

  const updateContentName = async () => {
    setNameSaveLoading(true);
    await contentApprovalServices.updateContentBoard(currentOrg.organization_id, params.boardId, {
      name: contentName.trim(),
    });
    setNameSaveLoading(false);
  };

  const onSubmit = () => {
    if (contentName.trim() === openedBoard?.name || !contentName.trim()) {
      setContentName(openedBoard?.name);
      setIsEdit(false);
      return;
    }

    setIsEdit(false);
    updateContentName().then(() => setContentName(contentName.trim()));
  };

  const onEdit = () => {
    if (nameSaveLoading) return;
    setIsEdit(true);
  };

  return (
    <div className={cs(styles.root, { [styles.editing]: isEdit })}>
      {isEdit ? (
        <TextField
          ref={inputRef}
          wrapperClassName={styles.inputWrapper}
          className={styles.input}
          value={contentName}
          placeholder="Deliverable name"
          onBlur={onSubmit}
          onChange={e => setContentName(e.target.value)}
        />
      ) : (
        <>
          <div className={styles.name}>{contentName}</div>
          <div className={styles.edit} onClick={onEdit}>
            <EditIcon />
          </div>
        </>
      )}
    </div>
  );
};

export default ContentName;
