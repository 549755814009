import React from 'react';
import DefaultStatusTile from '../DeliverableItem/components/Header/statuses/DefaultStatusTile';
import ApprovedStatusTile from '../DeliverableItem/components/Header/statuses/ApprovedStatusTile';

export const CONTENT_BOARD_STATUSES = {
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
};

const statuses = {
  [CONTENT_BOARD_STATUSES.IN_PROGRESS]: <DefaultStatusTile statusText="In progress" status={CONTENT_BOARD_STATUSES.IN_PROGRESS} />,
  [CONTENT_BOARD_STATUSES.COMPLETED]: <ApprovedStatusTile text="Completed" disableCheckIcon />,
};

const useContentBoardStatusTile = status => statuses[status] || 'N/A';

export default useContentBoardStatusTile;
