import React, { useCallback, useRef } from 'react';
import styles from './HeaderBreadcrumbs.module.scss';
import { ReactComponent as Icon } from '../../../assets/img/icons/menu-icons/content-approval.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/img/icons/calendar_arrow_right.svg';
import useTooltip from '../../Menu/components/MenuInner/components/useTooltip';
import cs from 'classnames';
import BreadcrumbsDropdown from './BreadcrumbsDropdown';

const isLastIndex = (index, items) => index === items.length - 1;

const HeaderBreadcrumbs = ({ items }) => {
  const rootRef = useRef(null);

  const onClick = useCallback(
    (callback, index) => {
      if (isLastIndex(index, items)) return;
      return event => callback(event);
    },
    [items]
  );

  return (
    <>
      {items.length > 1 && (
        <div className={styles.root} ref={rootRef}>
          <div className={styles.navWrapper}>
            <nav className={styles.nav}>
              {items.length > 3 && (
                <>
                  <Breadcrumb emptyItem itemsLength={items.length} index={0} />
                  <BreadcrumbsDropdown items={items} onClick={onClick} />
                  <Breadcrumb
                    onClick={onClick}
                    item={items[items.length - 1]}
                    index={items.length - 1}
                    rootRef={rootRef}
                    itemsLength={items.length}
                  />
                </>
              )}
              {!(items.length > 3) &&
                items.map((item, i) => (
                  <Breadcrumb key={item.name + i} onClick={onClick} item={item} index={i} rootRef={rootRef} itemsLength={items.length} />
                ))}
            </nav>
          </div>
        </div>
      )}
    </>
  );
};

const Breadcrumb = ({ onClick, item, index, rootRef, emptyItem = false }) => {
  const rootRect = rootRef?.current?.getBoundingClientRect();

  const { toolTip, onEnter, onLeave } = useTooltip(
    item?.name || '',
    {
      top: rect => rect.y - (rootRect?.y || 0) + rect.height,
      left: rect => rect.x - (rootRect?.x || 0) + rect.width / 2,
    },
    styles.tooltip
  );

  return (
    <div
      data-tooltip-container
      className={cs(styles.breadcrumbWrapper, { [styles.empty]: emptyItem })}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
      onClick={onClick ? onClick(item?.callback, index) : null}
    >
      <Icon className={styles.iconContainer} />
      <span className={styles.separator}>
        <ArrowLeftIcon className={styles.arrow} />
      </span>
      {!emptyItem && <span className={styles.breadcrumb}>{item.name}</span>}
      {!emptyItem && toolTip}
    </div>
  );
};

export default HeaderBreadcrumbs;
