import React from 'react';

function ClockForTimer() {
  return (
<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11 0.34375C5.11328 0.34375 0.34375 5.11328 0.34375 11C0.34375 16.8867 5.11328 21.6562 11 21.6562C16.8867 21.6562 21.6562 16.8867 21.6562 11C21.6562 5.11328 16.8867 0.34375 11 0.34375ZM11 19.5938C6.25195 19.5938 2.40625 15.748 2.40625 11C2.40625 6.25195 6.25195 2.40625 11 2.40625C15.748 2.40625 19.5938 6.25195 19.5938 11C19.5938 15.748 15.748 19.5938 11 19.5938ZM13.6555 15.1078L10.0074 12.4566C9.87422 12.3578 9.79688 12.2031 9.79688 12.0398V4.98438C9.79688 4.70078 10.0289 4.46875 10.3125 4.46875H11.6875C11.9711 4.46875 12.2031 4.70078 12.2031 4.98438V11.073L15.0734 13.1613C15.3055 13.3289 15.3527 13.6512 15.1852 13.8832L14.3773 14.9961C14.2098 15.2238 13.8875 15.2754 13.6555 15.1078Z" fill="white"/>
</svg>
  );
}

export default ClockForTimer;
