/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React from 'react';
import cs from 'classnames';
import styles from './DesktopMenu.module.scss';
import MenuHeader from '../components/MenuHeader';
import MenuInner from '../components/MenuInner';
import { useDispatch, useSelector } from 'react-redux';

const DesktopMenu = ({ isOpen, handleCloseMenu, classNames }) => {
  const dispatch = useDispatch();
  const isMinimized = useSelector((state) => state.sidebarMenu.isMinimized);
  const toggleSidebar = () => dispatch({type: 'TOGGLE_SIDEBAR'});

  return (
    <div className={cs(styles.wrapper, isOpen ? styles.open : styles.hide, {[styles.minimizedWrapper]: isMinimized}, classNames)}>
      <MenuHeader isMinimized={isMinimized} onBurgerClick={toggleSidebar} />
      <MenuInner className={styles.inner} isMinimized={isMinimized} handleCloseMenu={handleCloseMenu} />
    </div>
  );
};

export default DesktopMenu;
