import React from 'react';

function ArrowLeftSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="18"
      fill="none"
      viewBox="0 0 10 18"
    >
      <path
        stroke="#4E3B8B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
        d="M9 1L1 9l8 8"
      />
    </svg>
  );
}

export default ArrowLeftSVG;
