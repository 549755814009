import React from 'react';
import styles from './LogoModal.module.scss';
import { Modal } from '../index';
import { ReactComponent as OtterfishLogo} from '../../../assets/img/icons/otterfish_smal_logo.svg';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';

const LogoModalContent = ({ text, onSubmit, onClose, buttonText}) => {
  return (
    <div className={styles.root}>
      <OtterfishLogo />
      <p className={styles.text}>{text}</p>
      <ButtonCustom onClick={onSubmit || onClose}>{buttonText || 'Continue'}</ButtonCustom>
    </div>
  )
}

const LogoModal = props => {
  return (
    <Modal {...props}>
      <LogoModalContent {...props} />
    </Modal>
  )
}

export default LogoModal;
