import { ADS_TYPES } from '../actions/ads.actions';

const initialState = {
  data: [],
  loading: false,
  refresh: false,
  changeStatus: false,
  overview: {
    data:{},
    loading: false,
    failure: {},
  },
  failure: {},
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ADS_TYPES.GET_ADS.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADS_TYPES.GET_ADS.SUCCESS: {
      return {
        ...state,
        data: payload,
        loading: false,
      };
    }
    case ADS_TYPES.GET_ADS.FAILURE: {
      return {
        ...state,
        loading: false,
        failure: payload,
      };
    }
    case ADS_TYPES.REFRESH_ADS.REQUEST: {
      return {
        ...state,
        refresh: true,
      };
    }
    case ADS_TYPES.REFRESH_ADS.SUCCESS: {
      const ids = payload.map(ad => ad.id);
      const data = state.data.map(ad => (ids.includes(ad.id) ? payload.find(a => a.id === ad.id) : ad));
      return {
        ...state,
        refresh: false,
        data,
      };
    }
    case ADS_TYPES.REFRESH_ADS.FAILURE: {
      return {
        ...state,
        refresh: false,
        failure: payload,
      };
    }
    case ADS_TYPES.CHANGE_STATUS_ADS.REQUEST: {
      return {
        ...state,
        changeStatus: true,
      };
    }
    case ADS_TYPES.CHANGE_STATUS_ADS.SUCCESS: {
      const ids = payload.map(ad => ad.id);
      const data = state.data.map(ad => (ids.includes(ad.id) ? payload.find(a => a.id === ad.id) : ad));
      return {
        ...state,
        data,
        changeStatus: false,
      };
    }
    case ADS_TYPES.CHANGE_STATUS_ADS.FAILURE: {
      return {
        ...state,
        changeStatus: false,
        failure: payload,
      };
    }
    case ADS_TYPES.GET_OVERVIEW.REQUEST: {
      return {
        ...state,
        overview: {
          loading: true,
        }
      }
    }
    case ADS_TYPES.GET_OVERVIEW.SUCCESS: {
      return {
        ...state,
        overview: {
          data: payload,
          loading: false,
        }
      }
    }
    case ADS_TYPES.GET_OVERVIEW.FAILURE: {
      return {
        ...state,
        overview: {
          failure: payload,
          loading: false,
        }
      }
    }
    default: {
      return state;
    }
  }
};
