import * as discussionServices from '../services/discussion';

export const DISCUSSION_TYPES = {
  GET_DISCUSSION_ROOMS: {
    REQUEST: 'GET_DISCUSSION_ROOMS_REQUEST',
    SUCCESS: 'GET_DISCUSSION_ROOMS_SUCCESS',
    FAILURE: 'GET_DISCUSSION_ROOMS_FAILURE',
  },
  GET_COMMENTS: {
    REQUEST: 'GET_COMMENTS_REQUEST',
    SUCCESS: 'GET_COMMENTS_SUCCESS',
    FAILURE: 'GET_COMMENTS_FAILURE',
  },
  ADD_NEW_COMMENT: {
    SUCCESS: 'ADD_NEW_COMMENT_SUCCESS',
  },
  DELETE_COMMENT: {
    SUCCESS: 'DELETE_COMMENT',
  },
  REPLACE_COMMENT: {
    SUCCESS: 'REPLACE_COMMENT',
  },
  SET_NEW_DISCUSSION_ROOM: 'SET_NEW_DISCUSSION_ROOM',
  GET_COLLABORATORS: {
    REQUEST: 'GET_COLLABORATORS_DISCUSSION_REQUEST',
    SUCCESS: 'GET_COLLABORATORS_DISCUSSION_SUCCESS',
    FAILURE: 'GET_COLLABORATORS_DISCUSSION_FAILURE',
  },
  CLEAR_DISCUSSIONS_STATE: 'CLEAR_DISCUSSIONS_STATE',
  SET_DISCUSSIONS_IS_EXPANDED: 'SET_DISCUSSIONS_IS_EXPANDED',
};

/**
 * Create Discussion room
 * @param {Number} organizationID
 * @param {{name: String, object_type: "campaign_brief_item", object_id: Number, collaborator_id: Array<Number>}} data
 * @param {Number} creatorBriefId
 * @return {Promise<*>}
 */
export const createDiscussionRoom = (organizationID, data, creatorBriefId) => async (dispatch, getState) => {
  const userId = getState().auth.user.id;
  discussionServices
    .createDiscussionRoom(organizationID, data)
    .then(res => {
      window.analytics.track('discussion_room_created', {
        category: 'User Communications',
        label: data.name,
        userId: userId,
        discussion_room_id: res.data[0].id,
        ...(data.object_type === 'content_board' && { content_board_id: data.object_id }),
        ...(data.object_type === 'campaign_brief_item' && !creatorBriefId && { campaign_brief_id: data.object_id }),
        ...(data.object_type === 'campaign_brief_item' && creatorBriefId && { creator_brief_id: data.object_id }),
      });
    })
    .catch(error => {
      console.error(error);
    });
};

let currentObjectId = null;

/**
 * Get Discussion rooms
 * @param {Number} organizationID
 * @param {object_type: "campaign_brief_item" | "content_board", object_id: Number} params
 * @return {Promise<*>}
 */
export const getDiscussionRooms = (organizationID, params) => async dispatch => {
  dispatch({ type: DISCUSSION_TYPES.GET_DISCUSSION_ROOMS.REQUEST });
  currentObjectId = params.object_id;
  discussionServices
    .getDiscussionRooms(organizationID, params)
    .then(async response => {
      const rooms = await modifyRooms(response.data, organizationID);
      const roomObjectId = rooms[0]?.object_id;
      if (roomObjectId && +roomObjectId !== +currentObjectId) return;
      dispatch({ type: DISCUSSION_TYPES.GET_DISCUSSION_ROOMS.SUCCESS, payload: rooms });
    })
    .catch(error => {
      dispatch({ type: DISCUSSION_TYPES.GET_DISCUSSION_ROOMS.FAILURE });
    });
};

export const setDiscussionRoom = (organizationID, room) => async dispatch => {
  if (!room.name) {
    const roomCollaborators = await discussionServices.getDiscussionRoomOrganizations(organizationID, room.id);
    setRoomName(organizationID, roomCollaborators, room);
  }
  dispatch({ type: DISCUSSION_TYPES.SET_NEW_DISCUSSION_ROOM, payload: room });
};

const setRoomName = (organizationID, roomCollaborators, room) => {
  const chatCollaborators = roomCollaborators.data.filter(org => org.organization_id !== organizationID);
  room.name = chatCollaborators.map(org => org.organization_name).join(', ');
};

const modifyRooms = async (rooms, organizationID) => {
  const sortData = [];
  for (const room of rooms) {
    const roomCollaborators = await discussionServices.getDiscussionRoomOrganizations(organizationID, room.id);

    if (!room.name) {
      setRoomName(organizationID, roomCollaborators, room);
    }

    sortData.push({ collaboratorsLength: roomCollaborators.data.length, room });
  }

  return sortData.sort((a, b) => b.collaboratorsLength - a.collaboratorsLength).map(item => item.room);
};

/**
 * Add comment message to discussion room
 * @param {Number} organizationID
 * @param {Number} discussionRoomID
 * @param {String} commentMessage
 * @param {{first_name: String, last_name: String}} user
 * @param {Array<{file: File, fileUrl: String}>} attachments
 * @return {Promise<*>}
 */
export const createDiscussionRoomComment = (organizationID, discussionRoomID, commentMessage, user, attachments) => async dispatch => {
  commentMessage = commentMessage || ` `; // todo: Remove - "Space to bypass validation on the server".

  const response = await discussionServices.createDiscussionRoomComment(organizationID, discussionRoomID, { comment: commentMessage });
  const createdComment = response.data[0];

  if (createdComment) {
    dispatch(
      addDiscussionRoomComment({
        ...createdComment,
        attachments: attachments,
      })
    );
  }

  const commentID = createdComment?.id;
  if (commentID && attachments) {
    for (const attachment of attachments) {
      await discussionServices.addDiscussionRoomCommentAttachment(organizationID, discussionRoomID, commentID, attachment.file);
    }
  }
};

export const addDiscussionRoomComment = comment => (dispatch, getState) => {
  const comments = getState().discussion.comments;
  const existingComment = comments.find(c => c.id === comment.id);

  if (!existingComment) {
    dispatch({ type: DISCUSSION_TYPES.ADD_NEW_COMMENT.SUCCESS, payload: comment });
  } else if (!existingComment.attachments.length && !!comment.attachments.length) {
    const newComment = { ...existingComment, attachments: [...comment.attachments] };
    dispatch({
      type: DISCUSSION_TYPES.REPLACE_COMMENT.SUCCESS,
      payload: {
        replaceable: existingComment,
        replacement: newComment,
      },
    });
  }
};

export const addDiscussionRoomCommentAttachment = attachment => (dispatch, getState) => {
  const state = getState();
  let isUpdate = false;

  const comments = state.discussion.comments.map(comment => {
    const isAttachmentRelatedToComment = +comment.id === +attachment.discussion_room_comment_id;
    const attachmentsEmptyOrHasId = !comment.attachments.length || comment.attachments[0].id;

    if (isAttachmentRelatedToComment && attachmentsEmptyOrHasId) {
      isUpdate = true;
      return { ...comment, attachments: [...comment.attachments, attachment] };
    }
    return comment;
  });

  if (isUpdate) {
    dispatch({ type: DISCUSSION_TYPES.GET_COMMENTS.SUCCESS, payload: { data: comments } });
  }
};

/**
 * Get discussion room comments
 * @param {Number} organizationID
 * @param {Number} discussionRoomID
 * @return {Promise<*>}
 */
export const getDiscussionRoomComments = (organizationID, discussionRoomID) => async dispatch => {
  dispatch({ type: DISCUSSION_TYPES.GET_COMMENTS.REQUEST });
  discussionServices
    .getDiscussionRoomComments(organizationID, discussionRoomID)
    .then(response => {
      dispatch({ type: DISCUSSION_TYPES.GET_COMMENTS.SUCCESS, payload: response });
    })
    .catch(error => {
      dispatch({ type: DISCUSSION_TYPES.GET_COMMENTS.FAILURE });
    });
};

/**
 * Get discussion room comments
 * @param {Number} organizationID
 * @param {Number} discussionRoomID
 * @return {Promise<*>}
 */
export const getDiscussionRoomOrganizations = (organizationID, discussionRoomID) => async dispatch => {
  dispatch({ type: DISCUSSION_TYPES.GET_COLLABORATORS.REQUEST });

  discussionServices
    .getDiscussionRoomOrganizations(organizationID, discussionRoomID)
    .then(response => {
      dispatch({ type: DISCUSSION_TYPES.GET_COLLABORATORS.SUCCESS, payload: response });
    })
    .catch(error => {
      dispatch({ type: DISCUSSION_TYPES.GET_COLLABORATORS.FAILURE });
    });
};

export const clearDiscussionsState = () => ({ type: DISCUSSION_TYPES.CLEAR_DISCUSSIONS_STATE });

export const setDiscussionsIsExpanded = isExpanded => ({ type: DISCUSSION_TYPES.SET_DISCUSSIONS_IS_EXPANDED, payload: isExpanded });
