import React from 'react';
import styles from './ActionButton.module.scss';

import {CloseIcon} from '../../../expert/components/IconsSvg/ReactIcons/ReactIcons';
import PauseSVG from '../../../expert/components/IconsSvg/PauseSVG';
import {ReactComponent as RetryIcon} from '../../../assets/img/icons/retry-icon.svg';
import {ReactComponent as CircleErrorIcon} from '../../../assets/img/icons/cirlce-error.svg';
import {ReactComponent as GreenOKIcon} from '../../../assets/img/icons/green-ok-icon-2.svg';
import {ReactComponent as CropIcon} from '../../../assets/img/icons/crop_icon.svg';
import cs from 'classnames';


const Icons = {
  delete: <CloseIcon />,
  pause: <PauseSVG />,
  crop: <CropIcon />,
  retry: <RetryIcon />,
  success: <GreenOKIcon />,
  error: <CircleErrorIcon />
};
const squareButtons = ['delete', 'retry', 'pause', 'crop'];

/**
 * @param type - delete, retry, pause, crop, success, error
 */
const ActionButton = ({type, text, withText, onClick}) => {
    return (
        <>
          {withText ? (
            <div className={styles.container}>
              <span>{text ?? type}</span>
              <IconButton type={type} onClick={onClick} />
            </div>
          ) : (
            <IconButton type={type} onClick={onClick} />
          )}
        </>
    );
};

const IconButton = ({type, onClick}) => {
  return (
    <div
      className={cs(styles[type], {[styles.squareButton]: squareButtons.includes(type)})}
      onClick={onClick}
    >
      {Icons[type]}
    </div>
  );
};

export default ActionButton;
