import React, { useState } from 'react';
import styles from './WelcomeEmptyPage.module.scss';
import ButtonCustom from '../../../../expert/components/Buttons/ButtonCustom';
import { ReactComponent as CheckedIcon } from '../../../../assets/img/icons/checked.svg';
import cs from 'classnames';
import VideoModal from '../VideoModal';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive/src';

const WelcomeEmptyPage = ({ pageContent }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const userId = useSelector(state => state.auth.user.id);
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const watchDemoClick = () => {
    setIsOpenModal(true);
  };
  const onPlayAction = () => {
    window.analytics.track('Play', {
      category: 'Videos',
      userId: userId,
      label: `Content Board Demo Video`,
    });
  };

  const onPauseAction = () => {
    window.analytics.track('Pause', {
      category: 'Videos',
      userId: userId,
      label: `Content Board Demo Video`,
    });
  };
  const onCloseAction = () => {
    setIsOpenModal(false);
    window.analytics.track('Closed', {
      category: 'Videos',
      userId: userId,
      label: `Content Board Demo Video`,
    });
  };
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.textsColumn}>
          {!isMobile && <div className={styles.pageName}>{pageContent.pageName}</div>}
          <div className={styles.pageTitle}>{pageContent.title}</div>
          <div className={styles.pageSubTitle}>{pageContent.subTitle}</div>
          <ul className={styles.list}>
            {pageContent.listItems?.map((text, idx) => (
              <li key={idx}>
                <CheckedIcon />
                {text}
              </li>
            ))}
          </ul>
          <div className={styles.buttons}>
            {pageContent.button1 && (
              <ButtonCustom className={styles.btn} Icon={pageContent.button1.icon} onClick={pageContent.button1.action}>
                {pageContent.button1.text}
              </ButtonCustom>
            )}

            {pageContent.button2 && (
              <ButtonCustom
                className={cs(styles.btn, styles.whiteBtn)}
                Icon={pageContent.button2.icon}
                onClick={pageContent.button2.action}
              >
                {pageContent.button2.text}
              </ButtonCustom>
            )}
          </div>
        </div>
        {!isMobile &&
          <div className={styles.demoColumn}>
          <img src={pageContent.sectionImageUrl}/>
          {pageContent.button3 && !pageContent.hasModal && (
            <ButtonCustom
              className={styles.watchDemoButton}
              Icon={pageContent.button3.icon}
              onClick={pageContent.button3.action}
            >
              {pageContent.button3.text}
            </ButtonCustom>
          )}
          {pageContent.button3 && pageContent.hasModal && (
            <ButtonCustom className={styles.watchDemoButton} Icon={pageContent.button3.icon} onClick={watchDemoClick}>
              {pageContent.button3.text}
            </ButtonCustom>
          )}
        </div>
        }
        <VideoModal open={isOpenModal} onClose={() => onCloseAction()} onPause={onPauseAction} onPlay={onPlayAction}/>
      </div>
    </div>
  );
};

export default WelcomeEmptyPage;
