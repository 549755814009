import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import styles from './EmailConfirmation.module.scss';
import { clearFailure, resentEmail } from '../../../../../actions/auth.actions';
import ButtonCustom from '../../../../components/Buttons/ButtonCustom';
import SentEmail from '../../../../components/IconsSvg/SentEmail';
import ProgressBarTimer from '../../../../components/ProgressBarTimer';
import { BackIcon } from '../../../../components/IconsSvg/ReactIcons/ReactIcons';
import Auth from '../../../../containers/Auth';

const EmailConfirmation = ({ history, failure, clearFailure, sendUserData, resentEmail, loading }) => {
  const onCloseAlert = () => {
    clearFailure();
  };

  const loginRedirect = () => {
    clearFailure().then(() => history.push(`/expert/login`));
  };

  useEffect(() => {
    if (sendUserData && !sendUserData.email) return loginRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResentEmail = () => {
    resentEmail(sendUserData);
  };

  return (
    <Auth failure={failure} onCloseAlert={onCloseAlert}>
      <div className={styles.back}>
        <ButtonCustom Icon={BackIcon} outline onClick={() => history.push('/expert/login')} type={'button'}>
          Back
        </ButtonCustom>
      </div>
      <div className={styles.container}>
        <div className={styles.image}>
          <SentEmail />
        </div>
        <div className={styles.textContainer}>
          <p className={styles.title}>We've sent you an email!</p>
          <div className={styles.text}>Please, check you inbox and spam folder</div>
          <ProgressBarTimer loadingBtn={loading} sentEmail={handleResentEmail} />
          <div className={styles.btnContainer}>
            <button onClick={loginRedirect} className={styles.btnBack} disabled={loading}>
              Back to <b>sign-in</b>
            </button>
          </div>
        </div>
      </div>
    </Auth>
  );
};

const mapStateToProps = state => ({
  sendUserData: state.auth.sendUserData,
  failure: state.auth.failure,
  industries: state.config.industries.data,
  loading: state.auth.emailConf.loading,
});

const mapDispatchToProps = dispatch => ({
  resentEmail: email => dispatch(resentEmail(email)),
  clearFailure: () => dispatch(clearFailure()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EmailConfirmation));
