import React from 'react';

const ListSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
      <path d="M5 0.5H18V2.5H5V0.5ZM0 0H3V3H0V0ZM0 6H3V9H0V6ZM0 12H3V15H0V12ZM5 6.5H18V8.5H5V6.5ZM5 12.5H18V14.5H5V12.5Z" fill="#46CFB0" />
    </svg>
  );
};

export default ListSVG;
