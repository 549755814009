import React from 'react';

function CheckedSVG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12">
      <path stroke="#4E3B8B" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4" d="M2 6l3 3 5-5"></path>
    </svg>
  );
}

export default CheckedSVG;
