import React from 'react';
import styles from '../../pages/MyPlan/CurrentPlan/CurrentPlan.module.scss';
import { ReactComponent as UsersSVG } from '../../assets/img/icons/users.svg';
import { ReactComponent as StorageSVG } from '../../assets/img/icons/creative_storage.svg';
import { ReactComponent as AudiencesSVG } from '../../assets/img/icons/audiences_new_design_icon.svg';
import { ReactComponent as InstagramSVG } from '../../assets/img/icons/instagram.svg';
import { ReactComponent as FacebookSVG } from '../../assets/img/icons/facebookCircle.svg';
import numeral from 'numeral';

const CardsPlan = ({ title, currentNumber, total }) => {
  const icons = {
    'Team Members': <UsersSVG />,
    'Creative storage': <StorageSVG />,
    'Audiences imported': <AudiencesSVG />,
    'IG accounts connected': <InstagramSVG />,
    'FB pages connected': <FacebookSVG />,
  };

  const currentValue = `${title === 'Creative storage' ? numeral(currentNumber).format('0.0b') : currentNumber}`;
  const totalValue = `${+total === -1 ? 'Unlimited' : title === 'Creative storage' ? numeral(total).format('0.0b') : total}`;

  return (
    <div className={styles.card}>
      <div className={styles.flexRow}>
        <div className={styles.icon}>{icons[title]}</div>
        <div className={styles.count}>{currentValue + `${total ? '/' + totalValue : ''}`}</div>
      </div>
      <div className={styles.title}>{title}</div>
    </div>
  );
};

export default CardsPlan;
