import React from 'react';
import PropTypes from 'prop-types';
import styles from './SuccessModal.module.scss';
import SuccessSVG from '../../IconsSvg/SuccessSVG';
import ButtonCustom from '../../Buttons/ButtonCustom';
import { ModalSmall as Modal } from '..';

export const SuccessModalContent = ({ onSubmit, onClose, text, submitText }) => {
  return (
    <>
      <SuccessSVG />
      <p className={styles.title}>Success!</p>
      <p className={styles.text}>{text}</p>
      <div className={styles.controls}>
        <ButtonCustom onClick={onSubmit || onClose}>{submitText ?? 'Continue'}</ButtonCustom>
      </div>
    </>
  );
};

const SuccessModal = props => {
  return (
    <Modal {...props}>
      <SuccessModalContent {...props} />
    </Modal>
  );
};

SuccessModal.propTypes = {
  onSubmit: PropTypes.func,
  text: PropTypes.string,
  submitText: PropTypes.string,
  ...Modal.propTypes,
};

export default SuccessModal;
