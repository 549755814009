import * as tagsService from '../services/tags';


export const TAGS = {
  GET_ALL_TAGS: {
    REQUEST: 'GET_ALL_TAGS_REQUEST',
    SUCCESS: 'GET_ALL_TAGS_SUCCESS',
    FAILURE: 'GET_ALL_TAGS_FAILURE',
  },
  ADD_TAG: {
    REQUEST: 'ADD_TAG_REQUEST',
    SUCCESS: 'ADD_TAG_SUCCESS',
    FAILURE: 'ADD_TAG_FAILURE',
  },
  DELETE_TAG: {
    REQUEST: 'DELETE_TAG_REQUEST',
    SUCCESS: 'DELETE_TAG_SUCCESS',
    FAILURE: 'DELETE_TAG_FAILURE',
  },
  EDIT_TAG: {
    REQUEST: 'EDIT_TAG_REQUEST',
    SUCCESS: 'EDIT_TAG_SUCCESS',
    FAILURE: 'EDIT_TAG_FAILURE',
  },
}

export const getAllTags = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: TAGS.GET_ALL_TAGS.REQUEST,
      payload: {},
    })

    const params = {};
    params.page_size = 100;
    const currentOrg = getState().auth.currentOrganization;
    params.organization_id = currentOrg?.organization_id;
    const response = await tagsService.getAllTags(params)

    dispatch({
      type: TAGS.GET_ALL_TAGS.SUCCESS,
      payload: response.data,
      totalCount: response.total_count,
    })
  } catch (error){
    dispatch({
      type: TAGS.GET_ALL_TAGS.FAILURE,
      payload: error.response.data,
    })
  }
}

export const addTag = (organizationId, name) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TAGS.ADD_TAG.REQUEST,
      payload: {},
    })

    const params = {};
    const currOrg = organizationId || getState().auth.currentOrganization?.organization_id;
    if (currOrg) params.organization_id = currOrg;
    if (name) params.name = name;

    const response = await tagsService.addTag(params);

    dispatch({
      type: TAGS.ADD_TAG.SUCCESS,
      payload: response.data,
    })
    return response.data;
  } catch (error){
    dispatch({
      type: TAGS.ADD_TAG.FAILURE,
      payload: error.response.data,
    })
  }
}

export const deleteTag = id => async dispatch => {
  try {
    dispatch({
      type: TAGS.DELETE_TAG.REQUEST,
      payload: {},
    })

    const response = await tagsService.deleteTag(id);

    dispatch({
      type: TAGS.DELETE_TAG.SUCCESS,
      payload: response.data,
    })
  } catch (error){
    dispatch({
      type: TAGS.DELETE_TAG.FAILURE,
      payload: error.response.data,
    })
  }
}

export const editTag = (id, name) => async dispatch => {
  try {
    dispatch({
      type: TAGS.EDIT_TAG.REQUEST,
      payload: {},
    })

    const params = {};
    if (name) params.name = name;
    const response = await tagsService.editTag(id, params);

    dispatch({
      type: TAGS.EDIT_TAG.SUCCESS,
      payload: response.data,
    })
  } catch (error){
    dispatch({
      type: TAGS.EDIT_TAG.FAILURE,
      payload: error.response.data,
    })
  }
}
