import * as adSetsServices from '../services/adSets';

export const AD_SETS_TYPES = {
  GET_AD_SET: {
    REQUEST: 'GET_AD_SET_REQUEST',
    SUCCESS: 'GET_AD_SET_SUCCESS',
    FAILURE: 'GET_AD_SET_FAILURE',
  },
  GET_AD_SETS: {
    REQUEST: 'GET_AD_SETS_REQUEST',
    SUCCESS: 'GET_AD_SETS_SUCCESS',
    FAILURE: 'GET_AD_SETS_FAILURE',
  },
  CHANGE_STATUS_AD_SETS: {
    REQUEST: 'CHANGE_STATUS_AD_SETS_REQUEST',
    SUCCESS: 'CHANGE_STATUS_AD_SETS_SUCCESS',
    FAILURE: 'CHANGE_STATUS_AD_SETS_FAILURE',
  },
  REFRESH_AD_SETS: {
    REQUEST: 'REFRESH_AD_SETS_REQUEST',
    SUCCESS: 'REFRESH_AD_SETS_SUCCESS',
    FAILURE: 'REFRESH_AD_SETS_FAILURE',
  },
};

export const getAdSets = (include_insights, fb_campaign_ids, insights_date_preset) => async (dispatch, getState) => {
  try {
    dispatch({
      type: AD_SETS_TYPES.GET_AD_SETS.REQUEST,
    });

    const {
      ad_integration: { ad_integrations },
    } = getState();

    const organization_id = ad_integrations.find(ad => ad.type === 'facebook')?.organization_id;
    const params = {};
    if (include_insights) params.include_insights = include_insights;
    if (include_insights) params.insights_date_preset = insights_date_preset;
    if (organization_id) params.organization_id = organization_id;
    if (fb_campaign_ids?.length) params.fb_campaign_id = fb_campaign_ids.join(',');

    const res = await adSetsServices.getAdSets(null, params);

    dispatch({
      type: AD_SETS_TYPES.GET_AD_SETS.SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: AD_SETS_TYPES.GET_AD_SETS.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getAdSet = (adSetId, include_insights) => async (dispatch, getState) => {
  try {
    dispatch({
      type: AD_SETS_TYPES.GET_AD_SET.REQUEST,
    });

    const params = {};
    if (include_insights) params.include_insights = include_insights;

    const res = await adSetsServices.getAdSets(adSetId, params);

    dispatch({
      type: AD_SETS_TYPES.GET_AD_SET.SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: AD_SETS_TYPES.GET_AD_SET.FAILURE,
      payload: error.response.data,
    });
  }
};

export const changeStatusAdSets = (ids, status) => async (dispatch, getState) => {
  try {
    dispatch({
      type: AD_SETS_TYPES.CHANGE_STATUS_AD_SETS.REQUEST,
    });

    const {
      ad_integration: { ad_integrations },
    } = getState();

    const organization_id = ad_integrations.find(ad => ad.type === 'facebook')?.organization_id;
    const res = await adSetsServices.changeStatusAdSets({ organization_id, ids, status });

    dispatch({
      type: AD_SETS_TYPES.CHANGE_STATUS_AD_SETS.SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: AD_SETS_TYPES.CHANGE_STATUS_AD_SETS.FAILURE,
      payload: error.response.data,
    });
  }
};

export const refreshAdSets = ids => async (dispatch, getState) => {
  try {
    dispatch({
      type: AD_SETS_TYPES.REFRESH_AD_SETS.REQUEST,
    });

    const {
      ad_integration: { ad_integrations },
    } = getState();

    const organization_id = ad_integrations.find(ad => ad.type === 'facebook')?.organization_id;

    const res = adSetsServices.refreshAdSets({ organization_id, ids });

    dispatch({
      type: AD_SETS_TYPES.REFRESH_AD_SETS.SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: AD_SETS_TYPES.REFRESH_AD_SETS.FAILURE,
      payload: error.response.data,
    });
  }
};
