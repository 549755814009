import React from 'react';
import { useFormik } from 'formik';
import styles from './ForgotPassword.module.scss';
import InputControl from '../../../expert/components/FormControls/InputControl';
import * as Yup from 'yup';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { onClickEnter } from '../../../shared/utils/helpers';
import LogoSVG from '../../../components/IconsSvg/LogoSVG';

const ForgotPasswordStep1 = ({ onSendForm }) => {
  const loading = useSelector(state => state.auth.forgotPasswordLoading);
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('It is not a valid email').required('Email is required')
    }),
    onSubmit: values => {
      onSendForm(values.email);
    }
  });

  return (
    <div className={styles.emailForm}>
      <div className={styles.logo}>
        <LogoSVG/>
      </div>
      <h1 className={styles.title}>Recover your password</h1>
      <div className={styles.formGroup}>
        <p className={styles.text}>Enter your email where we’ll send a recovery link</p>
        <InputControl
          placeholder='Email address'
          type='text'
          name='email'
          value={formik.values.email}
          errorMessage={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className={styles.input}
          onKeyDown={e => onClickEnter(formik.handleSubmit, e)}
        />
      </div>
      <div className={styles.formGroup}>
        <ButtonCustom onClick={formik.handleSubmit} loading={loading}>Email me a recovery link</ButtonCustom>
        <NavLink to={'/'}>
          <div className={styles.back}>Back to log in</div>
        </NavLink>
      </div>
    </div>
  );
};

export default ForgotPasswordStep1;
