import React from 'react';
import Section from '../../CampaignBriefPage/components/Section';
import styles from './BriefOverviewSection.module.scss';
import LightPurpleButton from '../../CampaignBriefPage/components/LightPurpleButton';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FormattedText from '../../CampaignBriefPage/components/FormattedText';

const BriefOverviewSection = ({ currentBrief, masterBrief, isHideEdit = true, isApproved }) => {
  const match = useRouteMatch();

  return (
    <Section className={styles.root}>
      <div className={styles.flexRow}>
        <div>{!masterBrief && <div className={styles.title}>Brand overview</div>}</div>
        <div className={styles.justFlex} style={{ gap: 10 }}>
          {!isHideEdit && (
            <NavLink to={`/campaign/${match.params.campaignID}/edit`}>
              <LightPurpleButton isSmall>Edit</LightPurpleButton>
            </NavLink>
          )}
        </div>
      </div>

      <div className={styles.brandDetails}>
        <div className={styles.header}>
          {!!currentBrief.brand_logo_location && (
            <img className={styles.logo} src={currentBrief.brand_logo_location} alt={currentBrief.brand_name} />
          )}
          <div>
            <div className={styles.grayText}>Brand Name</div>
            <div className={styles.blackText}>{currentBrief.brand_name}</div>
          </div>
        </div>
        <div className={styles.textGroup}>
          <div className={styles.title}>Brand background</div>
          <FormattedText text={currentBrief.brand_description || ''} />
        </div>
        {!!currentBrief.brand_provides_product && (
          <div className={styles.textGroup}>
            <div className={styles.title}>
              Brand provides product <span>Yes</span>
            </div>
            <FormattedText text={currentBrief.brand_provides_product || ''} />
          </div>
        )}
        {!!currentBrief.notes && (
          <div className={styles.textGroup}>
            <div className={styles.title}>Brand guidelines and mandatories</div>
            <FormattedText text={currentBrief.notes || ''} />
          </div>
        )}
      </div>
    </Section>
  );
};

export default BriefOverviewSection;
