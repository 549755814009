import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { approveDeliverableItem, submitDeliverableItemSave } from '../../../../actions/contentApproval.actions';
import { useParams } from 'react-router-dom';
import useDeliverableGeneralState from './useDeliverableGeneralState';
import useAssetsCRUDState from './useAssetsCRUDState';
import useStatusesState from './useStatusesState';

const useDeliverableState = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const { openedDeliverable, deliverableApprovals } = useSelector(state => state.contentApproval);

  const { deliverableContent, onChangeConcept, onChangeCallToAction } = useDeliverableGeneralState();

  const {
    crudState: visualAssets,
    onUpdate: onUpdateVisualAssets,
    onCreate: onCreateVisualAssets,
    onDelete: onDeleteVisualAssets,
  } = useAssetsCRUDState('deliverable');

  const {
    crudState: visualConcepts,
    onCreate: onCreateVisualConcept,
    onUpdate: onUpdateVisualConcept,
    onDelete: onDeleteVisualConcept,
    assetsWithoutFile,
    setAssetsWithoutFile,
  } = useAssetsCRUDState('visual_concept');

  const handleSaveSubmit = () => {
    if (visualConcepts.isCreate) {
      const isValid = validateNewVisualConcepts(visualConcepts.create, setAssetsWithoutFile);
      if (!isValid) return;
    }

    const updates = getUpdates({
      deliverableContent,
      visualAssets,
      visualConcepts,
    });
    updates.visualAssets = visualAssets.current;
    updates.visualConcepts = visualConcepts.current;
    dispatch(submitDeliverableItemSave(currentOrg.organization_id, updates, params.boardId, params.deliverableId));
  };

  const onDeleteAsset = asset => {
    const deleteFunctionsByTypes = {
      deliverable: onDeleteVisualAssets,
      visual_concept: onDeleteVisualConcept,
    };
    const deleteFunction = deleteFunctionsByTypes[asset?.type];
    if (deleteFunction && asset) {
      deleteFunction(asset);
    }
  };

  const onApprove = type => {
    if (!currentOrg || !deliverableApprovals || !openedDeliverable || !type) return;
    dispatch(approveDeliverableItem(currentOrg.organization_id, params.boardId, params.deliverableId, type));
  };

  const visualAssetsFlow = {
    visualAssets: visualAssets.current,
    onCreateVisualAssets,
    onUpdateVisualAssets,
    onDeleteVisualAssets,
    isVisualExists: !!visualAssets.current.length && !visualAssets.create.length,
    isSectionChanged: visualAssets.isCreate || visualAssets.isUpdate || visualAssets.isDelete || deliverableContent.isCallToActionChanged,
  };

  const visualConceptsFlow = {
    visualConcepts: visualConcepts.current,
    onCreateVisualConcept,
    onUpdateVisualConcept,
    onDeleteVisualConcept,
    isVisualExists: !!visualConcepts.current.length && !visualConcepts.create.length,
    isSectionChanged: visualConcepts.isCreate || visualConcepts.isUpdate || visualConcepts.isDelete || deliverableContent.isConceptChanged,
    assetsWithoutFile,
  };

  const statusesState = useStatusesState();

  return {
    deliverableContent,
    onChangeConcept,
    onChangeCallToAction,
    visualAssetsFlow,
    visualConceptsFlow,
    handleSaveSubmit,
    onApprove,
    onDeleteAsset,
    statusesState,
  };
};

function getUpdates(allStates) {
  const { deliverableContent, visualAssets, visualConcepts } = allStates;
  const updates = {};

  if (deliverableContent.isChanged) {
    updates.deliverable = {};

    if (deliverableContent.concept && deliverableContent.isConceptChanged) {
      updates.deliverable.concept = deliverableContent.concept;
    }

    if (deliverableContent.call_to_action.length && deliverableContent.isCallToActionChanged) {
      updates.deliverable.call_to_action = deliverableContent.call_to_action;
    }
  }

  if (visualAssets.isDelete || visualConcepts.isDelete) {
    updates.deleteAssets = visualAssets.delete.concat(visualConcepts.delete);
  }

  if (visualAssets.isUpdate || visualConcepts.isUpdate) {
    updates.updateAssets = visualAssets.update.concat(visualConcepts.update);
  }

  if (visualAssets.isCreate || visualConcepts.isCreate) {
    updates.createAssets = visualAssets.create.concat(visualConcepts.create);
  }

  return updates;
}

function validateNewVisualConcepts(visualConcepts, setAssetsWithoutFile) {
  const visualsWithoutFile = visualConcepts.filter(item => !item.file);
  if (visualsWithoutFile.length) {
    const ids = visualsWithoutFile.map(item => item.id);
    setAssetsWithoutFile(ids);
    return false;
  }
  return true;
}

export default useDeliverableState;
