import React from 'react';
import styles from './ContentBoardAndDeliverableRoutesWrapper.module.scss';
import MainLayout from '../../../../components/MainLayout';
import { Route } from 'react-router-dom';
import DeliverableItem from '../../DeliverableItem';
import ContentBoard from '../../ContentBoard';
import { Switch, useLocation } from 'react-router';
import HeaderBreadcrumbs from '../../../../components/BriefsAndBoardsFlows/HeaderBreadcrumbs';
import ContentApprovalHeaderBreadcrumbs from '../ContentApprovalHeaderBreadcrumbs';
import DiscussionBackground from '../../../../expert/components/Discussion/DiscussionBackground';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { useMediaQuery } from 'react-responsive/src';

const ContentBoardTitle = () => {
  return <ContentApprovalHeaderBreadcrumbs page="content-board" BreadcrumbsComponent={HeaderBreadcrumbs} />;
};

const DeliverableItemTitle = () => {
  return <ContentApprovalHeaderBreadcrumbs page="deliverable" BreadcrumbsComponent={HeaderBreadcrumbs} />;
};

const pagesConfig = {
  contentBoard: {
    className: styles.contentBoardLayout,
    title: <ContentBoardTitle />,
  },
  deliverableItem: {
    className: styles.deliverableItemLayout,
    title: <DeliverableItemTitle />,
  },
};

const ContentBoardAndDeliverableRoutesWrapper = () => {
  const location = useLocation();
  const isDeliverable = /deliverables/g.test(location.pathname);
  const config = isDeliverable ? pagesConfig.deliverableItem : pagesConfig.contentBoard;
  const isDesktopLandscape = useMediaQuery({
    query: '(min-width: 992px) and (orientation: landscape)',
  });

  return (
    <>
      <MainLayout coloredHeader contentClassName={config.className} title={config.title}>
        {!isDesktopLandscape && (
          <ContentApprovalHeaderBreadcrumbs page={isDeliverable ? 'deliverable' : 'content-board'} BreadcrumbsComponent={Breadcrumbs} />
        )}
        <Switch>
          <Route path="/content-board/:boardId" exact>
            <ContentBoard />
          </Route>
          <Route path="/content-board/:boardId/deliverables/:deliverableId" exact>
            <DeliverableItem />
          </Route>
        </Switch>
        <DiscussionBackground />
      </MainLayout>
    </>
  );
};

export default ContentBoardAndDeliverableRoutesWrapper;
