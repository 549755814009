import React from 'react';
import styles from './VisualCard.module.scss';
import cs from 'classnames';
import DeleteAssetButton from '../../DeleteAssetButton';
import AddAssetButton from '../../AddAssetButton';
import SquareAsset from '../../../../../../components/SquareAsset';
import VisualHover from '../../VisualHover';
import useVisualCardHandlers from '../../../hooks/useVisualCardHandlers';
import EditorComponent from '../../../../../../components/EditorComponent';
import { useMediaQuery } from 'react-responsive/src';
import { ReactComponent as DeleteIcon } from '../../../../../../assets/img/icons/trash.svg';
import useDownloadAsset from '../../../../../../shared/hooks/useDownloadAsset';
import DownloadSVG from '../../../../../../expert/components/IconsSvg/DownloadSVG';
import SmallLoader from '../../../../../../components/SmallLoader';

const VisualCard = ({
  isStoryTile = false,
  fileTypes = ['image'],
  asset,
  openAsset,
  onCreateVisual,
  onDeleteVisual,
  onUpdateVisual,
  isDisabledSection,
  hideIcons,
}) => {
  const caption = (asset && asset.caption) || '';
  const assetUrl = (asset?.thumbnail_file_id && asset?.thumbnail_location) || asset?.preview_location || asset?.location;
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const isAssetAdded = !!asset?.file || !!asset?.location;

  const { deleteConfirmModal, handleCreate, handleUpdate, handleDelete, isImageDisplay } = useVisualCardHandlers(
    onCreateVisual,
    onUpdateVisual,
    onDeleteVisual,
    isDisabledSection,
    asset
  );

  const { isAssetLoading, downloadAsset } = useDownloadAsset();

  const onDownload = () => {
    if (isAssetLoading) return;
    downloadAsset(asset.location, asset.extension);
  };

  return (
    <div className={cs(styles.root, { [styles.removable]: isAssetAdded })}>
      <div className={cs(styles.visualWrapper, { [styles.storyWrapper]: isStoryTile })}>
        {assetUrl ? (
          <>
            {isImageDisplay(asset.mime_type, asset.thumbnail_location) ? (
              <img className={styles.cardImage} src={assetUrl} alt="no image" />
            ) : (
              <SquareAsset src={assetUrl} type={'video'} className={styles.cardImage} />
            )}
            <VisualHover className={styles.imageHover} onClick={() => openAsset(asset)} />
          </>
        ) : (
          <AddAssetButton
            actionText={!isTablet && (fileTypes.length > 1 ? 'Add Asset' : fileTypes[0] === 'image' ? 'Add Photo' : 'Add Video')}
            onAddAsset={handleCreate}
            fileTypes={fileTypes}
          />
        )}
        {isMobile && !hideIcons && (
          <div className={styles.iconsMobile}>
            <div className={styles.background}>
              <div onClick={onDownload} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {isAssetLoading ? <SmallLoader /> : <DownloadSVG />}
              </div>
            </div>
            <div className={styles.background}>
              <DeleteIcon onClick={handleDelete} />
            </div>
          </div>
        )}
      </div>
      <div className={styles.content}>
        {isAssetAdded && (
          <EditorComponent
            wrapperClassName={styles.editorWrapper}
            editorClassName={styles.editorInput}
            initialValue={caption}
            onChange={handleUpdate}
            placeholder="Add caption..."
            disabled={isDisabledSection || !asset}
          />
        )}
      </div>
      {!isDisabledSection && <DeleteAssetButton className={styles.deleteButton} onDelete={handleDelete} />}
      {deleteConfirmModal}
    </div>
  );
};

export default VisualCard;
