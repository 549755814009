import React from 'react';
import styles from './DefaultAttachmentTile.module.scss';
import { CloseSmallSvg } from '../../../../IconsSvg/CloseSmallSvg';
import { BsFileEarmarkText } from 'react-icons/bs';

const DefaultAttachmentTile = ({ attachment, onDelete }) => {
  return (
    <div className={styles.attachmentItem}>
      <div className={styles.icon}>
        <span className={styles.format}>{attachment.extension?.toUpperCase()}</span>
        <BsFileEarmarkText />
        <span>{attachment.file.name}</span>
      </div>
      <div className={styles.removeFile} onClick={() => onDelete(attachment.helperFileId)}>
        <CloseSmallSvg />
      </div>
    </div>
  );
};

export default DefaultAttachmentTile;
