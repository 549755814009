/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styles from './ImportInstagramAssetsModal.module.scss';
import { ModalMedium as Modal } from '..';
import TooltipControl from '../../../expert/components/FormControls/TooltipControl';
import { InfoIcon } from '../../../expert/components/IconsSvg/ReactIcons/ReactIcons';
import { useDispatch, useSelector } from 'react-redux';
import { importAllFacebookPages, importAllInstagramPages } from '../../../services/facebookIntegrations';
import AssetsCell from '../../Cells/AssetsCell';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import InstagramSVG from '../../IconsSvg/InstagramSVG';
import LoadingState from '../ImportAssetsModal/LoadingState';
import { getEntireIgList } from '../../../actions/assets.actions';
import { getPricingPlanForUser } from '../../../actions/user.actions';

const ImportInstagramAssets = ({ onClose, onSelect, onRemove, selected, onCancel, onImport, selectedFbPages }) => {
  const dispatch = useDispatch();
  const fbIntegration = useSelector(state => state.ad_integration.ad_integrations.find(item => item.type === 'facebook'));
  const hasFbIntegrationToken = !!fbIntegration?.params?.ACCESS_TOKEN;
  const currentPlan = useSelector(state => state.user.pricingPlan.instagram_accounts);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [data, setData] = useState([]);
  const pages = useSelector(state => state.assets.facebookPages.map(i => i.external_data.id));

  const handleImportAssets = async () => {
    setIsLoadingData(true);
    const { ACCESS_TOKEN, USER_ID } = fbIntegration.params;
    const facebookPages = await importAllFacebookPages(ACCESS_TOKEN, USER_ID);
    const imported = facebookPages.filter(i => pages.length ? pages?.includes(i.id) : selectedFbPages?.includes(i.id));

    const slices = [];
    while (imported.length > 0) slices.push(imported.splice(0, 50));

    const instagram = [];
    for (const item of slices) {
      const accounts = await importAllInstagramPages(item, ACCESS_TOKEN);
      instagram.push(...accounts);
    }
    setData(instagram);
    await dispatch(getEntireIgList(1, 500));
    setIsLoadingData(false);
  };

  useEffect(() => {
    if (hasFbIntegrationToken) {
      handleImportAssets();
    }
    if (!currentPlan) {
      dispatch(getPricingPlanForUser());
    }
  }, [hasFbIntegrationToken]);

  const handleCancel = () => {
    if (onCancel) onCancel();
    onClose();
  };

  const handleImport = () => {
    if (onImport) onImport();
    onClose();
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <span className={styles.title}>Select Instagram accounts</span>
        <div className={styles.counter}>
          <span>
            Accounts selected:
            {currentPlan === -1 ? (
              <b>{selected.length}</b>
            ) : (
              <b>
                {selected.length}/{currentPlan}
              </b>
            )}
          </span>
          {currentPlan !== -1 && (
            <TooltipControl text={`You can add up to ${currentPlan} Instagram account based on your active plan`} place={'right'}>
              <InfoIcon color="#9FA2B4" />
            </TooltipControl>
          )}
        </div>
      </div>
      <div className={styles.container}>
        {isLoadingData ? (
          <LoadingState />
        ) : data.length ? (
          data.map(item => {
            const page = { ...item, type: 'instagram_account', name: item.username };
            return <AssetsCell key={page.id} asset={page} withSelect onSelect={onSelect} onRemove={onRemove} selectedList={selected} />;
          })
        ) : (
          <div className={styles.empty}>
            <div>
              <InstagramSVG />
              <span>No Instagram accounts found</span>
            </div>
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <ButtonCustom outline onClick={handleCancel}>
          Cancel
        </ButtonCustom>
        <ButtonCustom disabled={!selected.length || (selected.length > currentPlan && currentPlan !== -1)} onClick={handleImport}>
          Import
        </ButtonCustom>
      </div>
    </div>
  );
};

const ImportInstagramAssetsModal = props => {
  return (
    <Modal {...props} maxWidth={'500px'}>
      <ImportInstagramAssets {...props} />
    </Modal>
  );
};

export default ImportInstagramAssetsModal;
