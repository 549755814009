import * as visualAssetsServices from '../services/visualAssets';
import { setLastGeneratedCreatives } from './generateCreatives.actions';

export const VISUAL_ASSETS_TYPES = {
  GET_VISUAL_ASSETS: {
    REQUEST: 'GET_VISUAL_ASSETS_REQUEST',
    SUCCESS: 'GET_VISUAL_ASSETS_SUCCESS',
    FAILURE: 'GET_VISUAL_ASSETS_FAILURE',
  },
  GET_SPECIFIC_VISUAL_ASSET: {
    REQUEST: 'GET_SPECIFIC_VISUAL_ASSET_REQUEST',
    SUCCESS: 'GET_SPECIFIC_VISUAL_ASSET_SUCCESS',
    FAILURE: 'GET_SPECIFIC_VISUAL_ASSET_FAILURE',
  },
  CREATE_FOLDER: {
    REQUEST: 'CREATE_FOLDER_REQUEST',
    SUCCESS: 'CREATE_FOLDER_SUCCESS',
    FAILURE: 'CREATE_FOLDER_FAILURE',
  },
  UPLOAD_IMAGES: {
    REQUEST: 'UPLOAD_IMAGES_REQUEST',
    SUCCESS: 'UPLOAD_IMAGES_SUCCESS',
    FAILURE: 'UPLOAD_IMAGES_FAILURE',
  },
  DELETE_VISUAL_ASSETS: {
    REQUEST: 'DELETE_VISUAL_ASSETS_REQUEST',
    SUCCESS: 'DELETE_VISUAL_ASSETS_SUCCESS',
    FAILURE: 'DELETE_VISUAL_ASSETS_FAILURE',
  },
  UPDATE_VISUAL_ASSET: {
    REQUEST: 'UPDATE_VISUAL_ASSET_REQUEST',
    SUCCESS: 'UPDATE_VISUAL_ASSET_SUCCESS',
    FAILURE: 'UPDATE_VISUAL_ASSET_FAILURE',
  },
  GET_ALL_FOLDERS: {
    REQUEST: 'GET_ALL_FOLDERS_REQUEST',
    SUCCESS: 'GET_ALL_FOLDERS_SUCCESS',
    FAILURE: 'GET_ALL_FOLDERS_FAILURE',
  },
  CLEAR_FAILURE: 'CLEAR_FAILURE',
  CLEAR_SPECIFIC_VISUAL_ASSET: 'CLEAR_SPECIFIC_VISUAL_ASSET',
  CLEAR_VISUAL_ASSETS_DATA: 'CLEAR_VISUAL_ASSETS_DATA',
  MARK_FEEDBACK: {
    TRUE: 'MARK_FEEDBACK_TRUE',
    FALSE: 'MARK_FEEDBACK_FALSE'
  },
  SMART_ADS_FEEDBACK_SURVEY: {
    TRUE: 'SMART_ADS_FEEDBACK_SURVEY_TRUE',
    FALSE: 'SMART_ADS_FEEDBACK_SURVEY_FALSE'
  }
};

export const clearFailure = () => async dispatch => {
  dispatch({ type: VISUAL_ASSETS_TYPES.CLEAR_FAILURE });
};

export const clearVisualAssets = () => {
  return {type: VISUAL_ASSETS_TYPES.CLEAR_VISUAL_ASSETS_DATA};
};

export const getVisualAssets = (organizationId, page, pageSize, parentFolder = 0, searchFilter, tags) => async dispatch => {
  try {
    dispatch({
      type: VISUAL_ASSETS_TYPES.GET_VISUAL_ASSETS.SUCCESS,
      payload: [],
      totalCount: 0,
    });
    dispatch({
      type: VISUAL_ASSETS_TYPES.GET_VISUAL_ASSETS.REQUEST,
      payload: {},
    });
    const params = {};
    if (organizationId) params.organization_id = organizationId;
    params.folder_id = parentFolder;
    if (searchFilter) params.search_filter = searchFilter;
    if (page) params.page = page;
    if (pageSize) params.page_size = pageSize;
    if (tags?.length) params.tags = tags.join(',');

    const response = await visualAssetsServices.getVisualAssets(params);

    dispatch({
      type: VISUAL_ASSETS_TYPES.GET_VISUAL_ASSETS.SUCCESS,
      payload: response.data,
      totalCount: response.total_count,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: VISUAL_ASSETS_TYPES.GET_VISUAL_ASSETS.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getSpecificVisualAsset = assetId => async dispatch => {
  try {
    dispatch({
      type: VISUAL_ASSETS_TYPES.GET_SPECIFIC_VISUAL_ASSET.REQUEST,
      payload: {},
    });

    const response = await visualAssetsServices.getSpecificVisualAsset(assetId);

    dispatch({
      type: VISUAL_ASSETS_TYPES.GET_SPECIFIC_VISUAL_ASSET.SUCCESS,
      payload: response.data[0],
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: VISUAL_ASSETS_TYPES.GET_SPECIFIC_VISUAL_ASSET.FAILURE,
      payload: error.response.data,
    });
  }
};

export const createFolder = (organizationId, parentId, name) => async dispatch => {
  try {
    dispatch({
      type: VISUAL_ASSETS_TYPES.CREATE_FOLDER.REQUEST,
      payload: {},
    });
    const params = {};
    if (organizationId) params.organization_id = organizationId;
    if (parentId) params.parent_id = parentId;
    if (name) params.name = name;
    const response = await visualAssetsServices.createFolder(params);

    dispatch({
      type: VISUAL_ASSETS_TYPES.CREATE_FOLDER.SUCCESS,
      payload: response,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: VISUAL_ASSETS_TYPES.CREATE_FOLDER.FAILURE,
      payload: error.response.data,
    });
    throw error.response.data;
  }
};

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export const uploadImages = (organizationId, folderId, images, tags, option) => async dispatch => {
  try {
    dispatch({
      type: VISUAL_ASSETS_TYPES.UPLOAD_IMAGES.REQUEST,
      payload: {},
    });

    const formData = new FormData();
    await asyncForEach(images, async image => {
      const blob = await fetch(image.url).then(r => r.blob());
      formData.append('image', blob, image.name);
    });

    formData.append('organization_id', organizationId);
    formData.append('folder_id', folderId);
    tags.forEach(item => formData.append(`tags`, item));

    const response = await visualAssetsServices.uploadImages(formData, option);

    dispatch({
      type: VISUAL_ASSETS_TYPES.UPLOAD_IMAGES.SUCCESS,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: VISUAL_ASSETS_TYPES.UPLOAD_IMAGES.FAILURE,
      payload: error.response.data,
    });
  }
};

export const uploadVideos = (organizationId, folderId, videos, tags, option) => async dispatch => {
  try {
    dispatch({
      type: VISUAL_ASSETS_TYPES.UPLOAD_IMAGES.REQUEST,
      payload: {},
    });

    const formData = new FormData();
    await asyncForEach(videos, async video => {
      const blob = await fetch(video.url).then(r => r.blob());
      formData.append('video', blob, video.name);
    });

    formData.append('organization_id', organizationId);
    formData.append('folder_id', folderId);
    tags.forEach(item => formData.append(`tags`, item));

    const response = await visualAssetsServices.uploadImages(formData, option);

    dispatch({
      type: VISUAL_ASSETS_TYPES.UPLOAD_IMAGES.SUCCESS,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: VISUAL_ASSETS_TYPES.UPLOAD_IMAGES.FAILURE,
      payload: error.response.data,
    });
  }
};

export const deleteVisualAssets = idsArray => async dispatch => {
  try {
    dispatch({
      type: VISUAL_ASSETS_TYPES.DELETE_VISUAL_ASSETS.REQUEST,
      payload: {},
    });
    const response = await visualAssetsServices.deleteVisualAssets(idsArray.join());

    dispatch({
      type: VISUAL_ASSETS_TYPES.DELETE_VISUAL_ASSETS.SUCCESS,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: VISUAL_ASSETS_TYPES.DELETE_VISUAL_ASSETS.FAILURE,
      payload: error.response.data,
    });
  }
};

export const renameVisualAsset = (assetId, name) => async dispatch => {
  try {
    dispatch({
      type: VISUAL_ASSETS_TYPES.UPDATE_VISUAL_ASSET.REQUEST,
      payload: {},
    });
    const params = {};
    if (name) params.name = name;
    const response = await visualAssetsServices.updateVisualAsset(assetId, params);

    dispatch({
      type: VISUAL_ASSETS_TYPES.UPDATE_VISUAL_ASSET.SUCCESS,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: VISUAL_ASSETS_TYPES.UPDATE_VISUAL_ASSET.FAILURE,
      payload: error.response.data,
    });
  }
};

export const moveVisualAsset = (assetId, folderId) => async dispatch => {
  try {
    dispatch({
      type: VISUAL_ASSETS_TYPES.UPDATE_VISUAL_ASSET.REQUEST,
      payload: {},
    });
    const params = {};
    if (folderId) params.folder_id = folderId;
    const response = await visualAssetsServices.updateVisualAsset(assetId, params);

    dispatch({
      type: VISUAL_ASSETS_TYPES.UPDATE_VISUAL_ASSET.SUCCESS,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: VISUAL_ASSETS_TYPES.UPDATE_VISUAL_ASSET.FAILURE,
      payload: error.response.data,
    });
  }
};

export const uploadCreatives = (creatives, folderId, tags, folderName, creativeTemplateId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: 'VISUAL_ASSETS_UPLOAD_CREATIVES_REQUEST',
    });

    const currentOrg = getState().auth.currentOrganization; // JSON.parse(localStorage.getItem('OTTERFISH_CURRENT_ORGANIZATION'))
    const organization_id = currentOrg.organization_id;

    const chunkSize = 10;

    const creativeChunks = creatives.reduce((all, one, i) => {
      const ch = Math.floor(i / chunkSize);
      all[ch] = [].concat(all[ch] || [], one);
      return all;
    }, []);

    for (let i = 0; i < creativeChunks.length; i++) {
      const ch = creativeChunks[i];
      await Promise.all(
        ch.map((c, index) =>
          visualAssetsServices.uploadCreative({
            organization_id,
            folder_id: folderId,
            name: `${folderName}_${index + 1}.jpg`,
            source_code: c,
            creative_template_id: creativeTemplateId,
            tags,
          })
        )
      ).then(res => {
        const creatives = res.map(response => response.data?.[0]);
        if (creatives?.length) {
          dispatch(setLastGeneratedCreatives(creatives));
        }
      });
    }

    dispatch({
      type: 'VISUAL_ASSETS_UPLOAD_CREATIVES_SUCCESS',
      payload: [],
    });
  } catch (error) {
    dispatch({
      type: 'VISUAL_ASSETS_UPLOAD_CREATIVES_FAILURE',
    });
  }
};

export const addAssetTags = (assetId, tagsIds) => async dispatch => {
  try {
    dispatch({
      type: VISUAL_ASSETS_TYPES.UPDATE_VISUAL_ASSET.REQUEST,
      payload: {},
    });
    const params = {};
    if (tagsIds) params.tags = tagsIds;
    const response = await visualAssetsServices.updateVisualAsset(assetId, params);

    dispatch({
      type: VISUAL_ASSETS_TYPES.UPDATE_VISUAL_ASSET.SUCCESS,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: VISUAL_ASSETS_TYPES.UPDATE_VISUAL_ASSET.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getAllFolders = organization_id => async dispatch => {
  try {
    dispatch({
      type: VISUAL_ASSETS_TYPES.GET_ALL_FOLDERS.REQUEST,
      payload: {},
    });

    const response = await visualAssetsServices.getAllFolders(organization_id);

    dispatch({
      type: VISUAL_ASSETS_TYPES.GET_ALL_FOLDERS.SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: VISUAL_ASSETS_TYPES.GET_ALL_FOLDERS.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getAllFiles = (organization_id, page, pageSize, parentFolder = 0, searchFilter, tags, type) => async dispatch => {
  try {
    dispatch({
      type: VISUAL_ASSETS_TYPES.GET_VISUAL_ASSETS.REQUEST,
      payload: {},
    });

    const params = { type: type ?? 'image,creative' };
    if (type) {
      params.folder_id = parentFolder;
      params.include_sub_folders = true;
    }
    if (organization_id) params.organization_id = organization_id;
    if (page) params.page = page;
    if (pageSize) params.page_size = pageSize;
    if (searchFilter) params.search_filter = searchFilter;
    if (tags?.length) params.tags = tags.join(',');

    const response = await visualAssetsServices.getVisualAssets(params);

    dispatch({
      type: VISUAL_ASSETS_TYPES.GET_VISUAL_ASSETS.SUCCESS,
      payload: response.data,
      totalCount: response.total_count,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: VISUAL_ASSETS_TYPES.GET_VISUAL_ASSETS.FAILURE,
      payload: error.response.data,
    });
  }
};

export const clearSpecificVisualAsset = () => async dispatch => {
  dispatch({ type: VISUAL_ASSETS_TYPES.CLEAR_SPECIFIC_VISUAL_ASSET });
};

export const markFeedbackTrue = () => dispatch => {
  dispatch({ type: VISUAL_ASSETS_TYPES.MARK_FEEDBACK.TRUE });
};

export const markFeedbackFalse = () => dispatch => {
  dispatch({ type: VISUAL_ASSETS_TYPES.MARK_FEEDBACK.FALSE });
};

export const SmartAdsFeedbackSurveyTrue = () => dispatch => {
  dispatch({ type: VISUAL_ASSETS_TYPES.SMART_ADS_FEEDBACK_SURVEY.TRUE });
}

export const SmartAdsFeedbackSurveyFalse = () => dispatch => {
  dispatch({ type: VISUAL_ASSETS_TYPES.SMART_ADS_FEEDBACK_SURVEY.FALSE });
}
