import React from 'react';
import styles from './DraftedTestModal.module.scss';
import { Modal } from '..';
import { ReactComponent as DraftedTestIcon } from '../../../assets/img/icons/draftedTest.svg';
import Button from '../../../expert/components/Buttons/ButtonCustom';

const DraftedTestModalContent = ({ onSubmit }) => {
  return (
    <div className={styles.root}>
      <DraftedTestIcon className={styles.draftTestIcon} />
      <div className={styles.title}>Test creation in progress</div>
      <div className={styles.description}>You can continue with the previous test or start over.</div>
      <div className={styles.navigationBtns}>
        <Button className={styles.btn} outline onClick={() => onSubmit(true)}>
          Start Over
        </Button>
        <Button className={styles.btn} onClick={() => onSubmit(false)}>
          Continue
        </Button>
      </div>
    </div>
  );
};

export default props => {
  return (
    <Modal {...props} maxWidth={600}>
      <DraftedTestModalContent {...props} />
    </Modal>
  );
};
