import { GENERATE_CREATIVES_TYPES } from '../actions/generateCreatives.actions';

const initialValue = {
  draftCreative: {},
  lastGeneratedCreatives: [],
}

export default (state = initialValue, action) => {
  switch (action.type) {
    case GENERATE_CREATIVES_TYPES.INIT_DRAFT_CREATIVE_STATE:
      return {
        ...state,
        draftCreative: {...action.payload}
      }
    case GENERATE_CREATIVES_TYPES.UPDATE_DRAFT_CREATIVE:
      const newState = {
        ...state,
        draftCreative: {
          ...state.draftCreative,
          [action.orgId]: {
            ...state.draftCreative[action.orgId],
            ...action.updates
          }
        }
      };
      return newState
    case GENERATE_CREATIVES_TYPES.CLEAR_DRAFT_CREATIVE:
      return {
        ...state,
        draftCreative: {}
      };
    case GENERATE_CREATIVES_TYPES.SET_LAST_GENERATED_CREATIVES:
      return {
        ...state,
        lastGeneratedCreatives: action.creatives || [],
      }
    default: {
      return state;
    }
  }
};
