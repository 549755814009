import React, { useState } from 'react';
import styles from './ImageAttachment.module.scss';
import { Modal } from '../../../../../../components/Modals';

const ImageAttachment = ({ attachment }) => {
  const [modalState, setModalState] = useState(false);
  const imageUrl = attachment.fileUrl || attachment.location;

  return (
    <>
      <div className={styles.root}>
        <img src={imageUrl} className={styles.messageImage} alt={''} />
        <div className={styles.showImageIcon} onClick={() => setModalState(true)}>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 14H0L0.007 7H2V12H7V14ZM13.992 7H12V2H7V0H14L13.992 7Z" fill="#4E3B8B" />
          </svg>
        </div>
      </div>
      <Modal open={modalState} onClose={() => setModalState(false)} maxWidth={'920px'} className={styles.modalRoot}>
        <div className={styles.modalContentWrapper}>
          <img className={styles.imageBackground} src={imageUrl} alt={''} />
          <img className={styles.image} src={imageUrl} alt={''} />
        </div>
        {/* <div className={styles.closeIcon} onClick={() => setModalState(false)}>*/}
        {/*  <CloseIcon />*/}
        {/* </div>*/}
      </Modal>
    </>
  );
};

export default ImageAttachment;
