// TODO: unused component, please delete me at code cleanup

import React, { useMemo, useState } from 'react';
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import './StripeForm.scss';
import styles from '../CreditCardDetails.module.scss';
import ButtonCustom from '../../../../../expert/components/Buttons/ButtonCustom';
import { NavLink, useHistory } from 'react-router-dom';

const useOptions = () => {
  const options = useMemo(
    () => ({
      showIcon: true,
      style: {
        base: {
          fontSize: '14px',
          lineHeight: '22px',
          color: '#4e3b8b',
          padding: '11px 20px 7px !important',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#9fa2b4',
          },
        },
        invalid: {
          color: '#ea3e4a',
        },
      },
    }),
    []
  );

  return options;
};

const StripeForm = ({ billingDetails, selectedPlan }) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const history = useHistory();
  const [formError, setFormError] = useState(null);

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        address: {
          city: billingDetails.city.name,
          // country: 'RO',
          line1: billingDetails.address,
          line2: billingDetails.address2,
          postal_code: billingDetails.zipcode,
        },
        name:
          billingDetails.company_name.length > 0 ? billingDetails.company_name : `${billingDetails.first_name} ${billingDetails.last_name}`,
      },
    });
    console.log('[PaymentMethod]', payload);
    if (payload.error) {
      setFormError(payload.error.message);
    } else {
      setFormError(null);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="stripeForm">
      {formError && <div className={styles.formError}>{formError}</div>}
      <div className="flexRow">
        <div className="itemFull">
          <label>
            Name on card
            <input name="name" type="text" placeholder="Jane Doe" required className="nameInput" />
          </label>
        </div>
      </div>
      <div className="flexRow">
        <div className="itemFull">
          <label>
            Card number
            <CardNumberElement
              options={options}
              onReady={() => {
                console.log('CardNumberElement [ready]');
              }}
              onChange={event => {
                console.log('CardNumberElement [change]', event);
              }}
              onBlur={() => {
                console.log('CardNumberElement [blur]');
              }}
              onFocus={() => {
                console.log('CardNumberElement [focus]');
              }}
            />
          </label>
        </div>
      </div>
      <div className="flexRow">
        <div className="item">
          <label>
            Expiration date
            <CardExpiryElement
              options={options}
              onReady={() => {
                console.log('CardNumberElement [ready]');
              }}
              onChange={event => {
                console.log('CardNumberElement [change]', event);
              }}
              onBlur={() => {
                console.log('CardNumberElement [blur]');
              }}
              onFocus={() => {
                console.log('CardNumberElement [focus]');
              }}
            />
          </label>
        </div>
        <div className="item">
          <label>
            CVC/CVV
            <CardCvcElement
              options={{ ...options, placeholder: '3 or 4 digits' }}
              onReady={() => {
                console.log('CardNumberElement [ready]');
              }}
              onChange={event => {
                console.log('CardNumberElement [change]', event);
              }}
              onBlur={() => {
                console.log('CardNumberElement [blur]');
              }}
              onFocus={() => {
                console.log('CardNumberElement [focus]');
              }}
            />
          </label>
        </div>
      </div>
      <div className={styles.amountContainer}>
        <div className={styles.firstRow}>
          <div className={styles.smallText}>Billed monthly</div>
          <div className={styles.smallText}>
            {selectedPlan.currency.toLowerCase() === 'usd' ? '$' : `${selectedPlan.currency} `}
            {selectedPlan.price}
          </div>
        </div>
        <div className={styles.secondRow}>
          <div className={styles.bigText}>
            Total <span>(% of the spent is not included)</span>
          </div>
          <div className={styles.bigText}>
            {selectedPlan.currency.toLowerCase() === 'usd' ? '$' : `${selectedPlan.currency} `}
            {selectedPlan.price}
          </div>
        </div>
      </div>
      <div className={styles.navigation}>
        <ButtonCustom onClick={() => {}} type="submit" disabled={!stripe}>
          Start 30 days free trial
        </ButtonCustom>
        <ButtonCustom
          outline
          clearBg
          onClick={() => {
            history.push('billing-address');
          }}
        >
          Go back
        </ButtonCustom>
      </div>
      <div className={styles.disclaimer}>
        By clicking “Start 30-day free trial” above, you acknowledge that you have read and understood, and agree to Otterfish’s{' '}
        <NavLink to="/terms-of-service/">Terms of Service</NavLink> and Refund Policy.
      </div>
    </form>
  );
};

export default StripeForm;
