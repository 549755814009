/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import styles from './ResetPassword.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import InputControl from '../../../expert/components/FormControls/InputControl';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import { resetPassword } from '../../../actions/auth.actions';
import { NavLink } from 'react-router-dom';
import { onClickEnter } from '../../../shared/utils/helpers';
import LeftPartForgotReset from '../../../components/AuthTwoSideLayout/LeftPartForgotReset';
import AuthTwoSideLayout from '../../../components/AuthTwoSideLayout';
import LogoSVG from '../../../components/IconsSvg/LogoSVG';

const ResetPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.forgotPasswordLoading);
  const searchParams = new URLSearchParams(history.location.search);
  const urlToken = searchParams.get('token');
  const urlEmail = searchParams.get('email');

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordRepeat: '',
      email: '',
      token: ''
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters'),
      passwordRepeat: Yup.string()
        .required('You should confirm your password')
        .equals([Yup.ref('password')], 'Passwords do not match'),
      email: Yup.string().required(),
      token: Yup.string().required()
    }),
    onSubmit: async values => {
      await dispatch(resetPassword(values.token, values.email, values.password));
      history.push('/');
    }
  });

  useEffect(() => {
    formik.setFieldValue('email', urlEmail?.split(' ').join('+'));
    formik.setFieldValue('token', urlToken);
  }, [urlToken, urlEmail]);

  return (
    <AuthTwoSideLayout leftPartContent={<LeftPartForgotReset/>} registration={false}>
      <div className={styles.container}>
        <div className={styles.logo}><LogoSVG/></div>
        <div className={styles.content}>
          <span className={styles.title}>Set your new password</span>
          <InputControl
            label='New password'
            placeholder='6+ characters'
            type='password'
            name='password'
            value={formik.values.password}
            errorMessage={formik.touched.password && formik.errors.password ? formik.errors.password : ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={styles.input}
            iconEyes
          />
          <InputControl
            label='Confirm password'
            placeholder='Re-enter your password'
            type='password'
            name='passwordRepeat'
            value={formik.values.passwordRepeat}
            errorMessage={formik.touched.passwordRepeat && formik.errors.passwordRepeat ? formik.errors.passwordRepeat : ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={styles.input}
            onKeyDown={e => onClickEnter(formik.handleSubmit, e)}
            iconEyes
          />
          <ButtonCustom
            disabled={formik.errors.password || formik.errors.passwordRepeat}
            onClick={formik.handleSubmit}
            loading={loading}
          >
            Set new password
          </ButtonCustom>
          <NavLink to={'/'}>
            <div className={styles.back}>Back to log in</div>
          </NavLink>
        </div>
      </div>
      </AuthTwoSideLayout>
  );
};

export default ResetPassword;
