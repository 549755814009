import { USER_TYPES } from '../actions/user.actions';

const initialState = {
  data: {},
  failure: {
    message: '',
    name: '',
    code: '',
  },
  organizationUsers: [],
  loading: false,
  updateLoading: false,
  invite: {
    data: {},
    failure: {
      message: '',
      name: '',
      code: '',
    },
    loading: false,
  },
  pricingPlan: {
    // it will be a dictionary
  },
  userUsage: {
    // it will be a dictionary
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case USER_TYPES.GET_ORGANIZATIONS_USERS.SUCCESS: {
      return { ...state, organizationUsers: payload };
    }
    case USER_TYPES.GET_USER.REQUEST: {
      return {
        ...state,
        data: {},
        loading: true,
      };
    }
    case USER_TYPES.GET_USER.SUCCESS: {
      return {
        ...state,
        data: payload,
        loading: false,
      };
    }
    case USER_TYPES.GET_USER.FAILURE: {
      return {
        ...state,
        failure: payload,
        loading: false,
      };
    }
    case USER_TYPES.UPDATE_USER.REQUEST: {
      return {
        ...state,
        updateLoading: true,
      };
    }
    case USER_TYPES.UPDATE_USER.SUCCESS: {
      return {
        ...state,
        data: payload,
        updateLoading: false,
      };
    }
    case USER_TYPES.UPDATE_USER.FAILURE: {
      return {
        ...state,
        failure: payload,
        updateLoading: false,
      };
    }
    case USER_TYPES.DELETE_USER_PHOTO.REQUEST: {
      return {
        ...state,
        updateLoading: true,
      };
    }
    case USER_TYPES.DELETE_USER_PHOTO.SUCCESS: {
      return {
        ...state,
        updateLoading: false,
      };
    }
    case USER_TYPES.DELETE_USER_PHOTO.FAILURE: {
      return {
        ...state,
        failure: payload,
        updateLoading: false,
      };
    }
    case USER_TYPES.GET_INVITE_DETAILS.REQUEST: {
      return {
        ...state,
        invite: {
          ...state.invite,
          loading: true,
        },
      };
    }
    case USER_TYPES.GET_INVITE_DETAILS.SUCCESS: {
      return {
        ...state,
        invite: {
          ...state.invite,
          loading: false,
          data: payload,
        },
      };
    }
    case USER_TYPES.GET_INVITE_DETAILS.FAILURE: {
      return {
        ...state,
        invite: {
          ...state.invite,
          loading: false,
          failure: payload,
        },
      };
    }
    case USER_TYPES.ACCEPT_INVITE.REQUEST: {
      return {
        ...state,
        invite: {
          ...state.invite,
          loading: true,
        },
      };
    }
    case USER_TYPES.ACCEPT_INVITE.SUCCESS: {
      return {
        ...state,
        invite: {
          ...state.invite,
          loading: false,
        },
      };
    }
    case USER_TYPES.ACCEPT_INVITE.FAILURE: {
      return {
        ...state,
        invite: {
          ...state.invite,
          loading: false,
          failure: payload,
        },
      };
    }
    case USER_TYPES.GET_PRICING_PLAN.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case USER_TYPES.GET_PRICING_PLAN.FAILURE: {
      return {
        ...state,
        failure: payload,
        loading: false,
      };
    }
    case USER_TYPES.GET_PRICING_PLAN.SUCCESS: {
      return {
        ...state,
        pricingPlan: payload.pricingPlan,
        loading: false,
      };
    }
    case USER_TYPES.GET_USER_USAGE.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case USER_TYPES.GET_USER_USAGE.FAILURE: {
      return {
        ...state,
        failure: payload,
        loading: false,
      };
    }
    case USER_TYPES.GET_USER_USAGE.SUCCESS: {
      return {
        ...state,
        userUsage: payload.pricingPlan,
        loading: false,
      };
    }
    case USER_TYPES.UPDATE_PRICING_PLAN.FAILURE: {
      return {
        ...state,
        failure: payload,
        loading: false,
      };
    }
    case USER_TYPES.CLEAR_FAILURE: {
      return {
        ...state,
        failure: {},
      };
    }
    default: {
      return state;
    }
  }
};
