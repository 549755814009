import React from 'react';

function LinkSVG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
      <path d="M5.13477 10.3656L9.86644 5.63391" stroke="#4E3B8B" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8.68275 11.549L6.71122 13.5206C6.08373 14.1479 5.23277 14.5002 4.34551 14.5001C3.45825 14.5001 2.60735 14.1476 1.97996 13.5202C1.35257 12.8928 1.00008 12.0419 1 11.1546C0.999924 10.2674 1.35228 9.4164 1.97956 8.78891L3.95108 6.81738"
        stroke="#4E3B8B"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0481 9.18287L13.0196 7.21135C13.6469 6.58385 13.9992 5.73289 13.9992 4.84563C13.9991 3.95837 13.6466 3.10747 13.0192 2.48008C12.3918 1.85269 11.5409 1.5002 10.6537 1.50012C9.76639 1.50005 8.91543 1.8524 8.28794 2.47968L6.31641 4.45121"
        stroke="#4E3B8B"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LinkSVG;
