import React, { useMemo } from 'react';
import styles from './CollaboratorColoredIcon.module.scss';

const iconColors = [
  { background: '#B8FBEC', color: '#479E8B' },
  { background: '#D9D3FF', color: '#776FAA' },
  { background: '#FFECA9', color: '#A18E49' },
];

const getRandomColor = name => {
  const hashNumber = name
    .split('')
    .map(char => char.codePointAt())
    .reduce((sum, num) => sum + num, 0);
  return iconColors[hashNumber % iconColors?.length];
};

const CollaboratorColoredIcon = ({ text }) => {
  const randomColor = useMemo(() => {
    if (text) return getRandomColor(text);
  }, [text]);

  return (
    <div className={styles.icon} style={{ backgroundColor: randomColor?.background, color: randomColor?.color }}>
      {text}
    </div>
  );
};

export default CollaboratorColoredIcon;
