import AppCentrifuge from '../centrifuge/centrifuge';
import { store } from '../index';

const SET_IS_CENTRIFUGE_CONNECTED = 'SET_IS_CENTRIFUGE_CONNECTED';

const initialState = {
  appCentrifuge: new AppCentrifuge(
    () => store.dispatch(setIsCentrifugeConnected(true)),
    () => store.dispatch(setIsCentrifugeConnected(false))
  ),
  isCentrifugeConnected: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IS_CENTRIFUGE_CONNECTED: {
      return {
        ...state,
        isCentrifugeConnected: payload,
      };
    }
    default:
      return state;
  }
};

export const setIsCentrifugeConnected = isConnected => ({ type: SET_IS_CENTRIFUGE_CONNECTED, payload: isConnected });
