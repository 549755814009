import React, { useState, useRef, useEffect, useCallback } from 'react';
import { ReactComponent as CalendarArrowRightIcon } from '../../../../assets/img/icons/calendar_arrow_right.svg';
import { ReactComponent as CalendarArrowLeftIcon } from '../../../../assets/img/icons/calendar_arrow_left.svg';
import { ReactComponent as CalendarIcon } from '../../../../assets/img/icons/grayCalendar.svg';
import cs from 'classnames';
import styles from './CalendarDropdown.module.scss';
import Calendar from 'react-calendar';
import './Calendar.scss';
import dayjs from 'dayjs';

const CalendarDropdown = ({ value, onChange, className, disabled, dataFormat, minDate, maxDate, withIcon, error }) => {
  const [expanded, setExpanded] = useState(false);
  const [isLeftArrowDisabled, setIsLeftArrowDisabled] = useState(true);
  const [calendarValue, setCalendarValue] = useState(value);
  const [initValue, setInitValue] = useState(value);
  const isEmpty = !value;
  const rootRef = useRef(null);

  const toggleCalendarOpen = () => {
    if (disabled) return;
    setExpanded(!expanded);
  };

  const handleChange = date => {
    setCalendarValue(date);
    onChange(date);
  };

  const handleSave = e => {
    e.preventDefault();
    setExpanded(false);
  };

  const handleCancel = e => {
    e.preventDefault();
    setCalendarValue(initValue);
    onChange(initValue);
    setExpanded(false);
  };

  useEffect(() => {
    if (expanded) {
      setInitValue(value);
    }
  }, [expanded]);

  const handleClickOutside = useCallback(
    e => {
      // fixed closing of the calendar if select year or month (the elements outside the rootRef)
      if (rootRef.current.contains(e.target) || e.target.getAttribute('aria-label') || e.target.classList[0] == 'react-calendar__tile') {
        return;
      }
      setExpanded(false);
    },
    [rootRef]
  );

  const onActiveStartDateChangeHandler = arrowEvent => {
    const isNeedDisable = new Date().getTime() > new Date(arrowEvent.activeStartDate).getTime();
    if (isNeedDisable) {
      setIsLeftArrowDisabled(true);
    } else if (isLeftArrowDisabled) {
      setIsLeftArrowDisabled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className={cs(styles.root, className)} ref={rootRef}>
      <div
        onClick={toggleCalendarOpen}
        className={cs(styles.date, { [styles.empty]: isEmpty }, { [styles.disabled]: disabled }, { [styles.isError]: !!error })}
      >
        {value ? (
          dayjs(value).format(dataFormat ? dataFormat : 'DD/MM/YYYY')
        ) : (
          <span className={styles.placeholder}>{dayjs().format('DD MMMM YYYY')}</span>
        )}
        {withIcon && <CalendarIcon className={styles.calendarIcon} />}
      </div>
      <div className={cs(styles.dropdown, { [styles.show]: expanded })}>
        <Calendar
          showNeighboringMonth={false}
          nextLabel={<CalendarArrowRightIcon className={cs(styles.calendarArrow)} />}
          prevLabel={<CalendarArrowLeftIcon className={cs(styles.calendarArrow, isLeftArrowDisabled ? styles.arrowDisabled : '')} />}
          onActiveStartDateChange={onActiveStartDateChangeHandler}
          next2Label={null}
          prev2Label={null}
          locale={'en'}
          maxDate={maxDate}
          minDate={minDate ? minDate : new Date(Date.now())}
          value={calendarValue}
          onChange={handleChange}
        />
        <div className={styles.buttonsContainer}>
          <button className={styles.calendarBtn} onClick={handleCancel}>
            Cancel
          </button>
          <button className={styles.calendarBtn} onClick={e => handleSave(e)}>
            Save
          </button>
        </div>
      </div>

      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default CalendarDropdown;
