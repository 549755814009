import React from 'react';
import { Modal } from '../index';
import styles from  './ConfirmInviteModal.module.scss';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';

const ConfirmInvite = ({ name, onClose }) => {
  return(
    <div className={styles.root}>
      <span className={styles.title}>{`You have successfully joined “${name}”`}</span>
      <ButtonCustom onClick={onClose}>
        Okay
      </ButtonCustom>
    </div>
  )
}

const ConfirmInviteModal = props => {
  return(
    <Modal {...props}>
      <ConfirmInvite {...props} />
    </Modal>
  )
}

export default ConfirmInviteModal;
