import React, { useEffect, useRef, useState } from 'react';
import RendererContentWrapper from '../components/RendererContentWrapper';
import Loader from '../../../../expert/components/Loader';

export const VideoRenderer = ({ story, action, isPaused, config, messageHandler }) => {
  const [loaded, setLoaded] = useState(false);
  const [muted, setMuted] = useState(true);
  const { loader, storyStyles } = config;

  const computedStyles = {
    ...styles.storyContent,
    ...(storyStyles || {}),
  };

  const vid = useRef(null);

  useEffect(() => {
    if (vid.current) {
      if (isPaused) {
        vid.current.pause();
      } else {
        vid.current.play().catch(() => {});
      }
    }
  }, [isPaused]);

  useEffect(() => {
    if (loaded) {
      onPlaying();
    }
  }, [isPaused, loaded]);

  const onWaiting = () => {
    action('pause', true);
  };

  const onPlaying = () => {
    action('play', true);
  };

  const videoLoaded = () => {
    messageHandler('UPDATE_VIDEO_DURATION', { duration: vid.current.duration > 14 ? 14 : vid.current.duration });
    setLoaded(true);
    vid.current
      .play()
      .then(() => {
        action('play', true);
      })
      .catch(() => {
        setMuted(true);
        vid.current.play().finally(() => {
          action('play', true);
        });
      });
  };

  return (
    <RendererContentWrapper story={story}>
      <div style={styles.videoContainer}>
        <video
          ref={vid}
          style={computedStyles}
          src={story.url}
          controls={false}
          onLoadedData={videoLoaded}
          onLoadStart={() => {
            action('pause', true);
            setLoaded(false);
          }}
          playsInline
          onWaiting={onWaiting}
          onPlaying={onPlaying}
          onClick={() => {
            if (isPaused) {
              onPlaying();
            } else {
              onWaiting();
            }
          }}
          muted={muted}
          autoPlay
          webkit-playsinline="true"
        />
        {!loaded && (
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              left: 0,
              top: 0,
              background: 'rgba(0, 0, 0, 0.9)',
              zIndex: 9,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#ccc',
            }}
          >
            {loader || <Loader />}
          </div>
        )}
      </div>
    </RendererContentWrapper>
  );
};

const styles = {
  storyContent: {
    width: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 'auto',
  },
  videoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export const tester = story => {
  return {
    condition: story.type === 'video',
    priority: 3,
  };
};

export default {
  renderer: VideoRenderer,
  tester,
};
