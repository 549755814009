import React, { useEffect, useRef, useState } from 'react';
import styles from './ProgressBarTimer.module.scss';
import ClockForTimer from '../IconsSvg/ClockForTimer';
import ButtonCustom from '../Buttons/ButtonCustom';

const ProgressBar = ({ sentEmail, loadingBtn }) => {
  const timer = useRef(null);
  const [isTimer, setIsTimer] = useState(false);

  useEffect(() => {
    if (timer.current && isTimer) {
      timer.current.style.width = '100%';

      setTimeout(() => {
        setIsTimer(false);
      }, 60000);
    }
  }, [isTimer]);

  return (
    <div className={styles.root}>
      {isTimer ? (
        <div className={styles.progressBar}>
          <span className={styles.title}>{<ClockForTimer />}Please, wait</span>
          <div ref={timer} className={styles.track} />
        </div>
      ) : (
        <ButtonCustom className={styles.resendBtn} onClick={sentEmail} loading={loadingBtn}>
          <label className={styles.textBtn}>Resend verification email</label>
        </ButtonCustom>
      )}
    </div>
  );
};

export default ProgressBar;
