import { RiDownloadLine, RiEarthFill, RiFacebookCircleFill, RiFacebookFill } from 'react-icons/ri';
import { BiPencil } from 'react-icons/bi';
import { VscTrash, VscChromeClose } from 'react-icons/vsc';
import { GrUndo } from 'react-icons/gr';
import { HiOutlineArchive, HiPlus } from 'react-icons/hi';
import { FiRefreshCw, FiUpload } from 'react-icons/fi';
import { BsPlayFill, BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { AiOutlinePause } from 'react-icons/ai';
import { FaInfoCircle } from 'react-icons/fa';
import { MdError, MdDone, MdEmail, MdAddCircle } from 'react-icons/md';

export const DownloadIcon = RiDownloadLine;
export const EditIcon = BiPencil;
export const DeleteIcon = VscTrash;
export const RestoreIcon = GrUndo;
export const ArchiveIcon = HiOutlineArchive;
export const RefreshIcon = FiRefreshCw;
export const AddIcon = HiPlus;
export const AddCircleIcon = MdAddCircle;
export const PlayIcon = BsPlayFill;
export const PauseIcon = AiOutlinePause;
export const FacebookIcon = RiFacebookCircleFill;
export const FacebookFillIcon = RiFacebookFill;
export const BackIcon = BsChevronLeft;
export const InfoIcon = FaInfoCircle;
export const WarningIcon = MdError;
export const CloseIcon = VscChromeClose;
export const DoneIcon = MdDone;
export const MailIcon = MdEmail;
export const UploadIcon = FiUpload;
export const EarthIcon = RiEarthFill;
export const ArrowRightIcon = BsChevronRight;
