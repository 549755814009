import React from 'react';
import styles from '../Statuses.module.scss';
import cs from 'classnames';

const DefaultStatusTile = ({ statusText, status }) => {
  return (
    <div className={cs(styles.defaultStatusTile, styles[status])}>
      <span>{statusText}</span>
    </div>
  );
};

export default DefaultStatusTile;
