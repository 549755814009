import React from 'react';
import ImageAttachment from './ImageAttachment';
import VideoAttachment from './VideoAttachment';
import DefaultAttachment from './DefaultAttachment';

const AttachmentItem = ({ attachment }) => {
  const { mime_type: mimetype, extension } = attachment;

  // other (without preview yet / only download)
  let attachmentComponent = <DefaultAttachment attachment={attachment} />;

  if (mimetype.startsWith('image/')) {
    attachmentComponent = <ImageAttachment attachment={attachment} />;
  } else if (mimetype.startsWith('video/')) {
    attachmentComponent = <VideoAttachment attachment={attachment} />;
  } else if (mimetype.startsWith('application/pdf')) {
    // pdf
  } else if (
    extension.endsWith('doc') ||
    extension.endsWith('docx') ||
    extension.endsWith('ppt') ||
    extension.endsWith('pptx') ||
    extension.endsWith('xls') ||
    extension.endsWith('xlsx')
  ) {
    // docs
  }

  return <div style={{ marginBottom: '10px' }}>{attachmentComponent}</div>;
};

export default AttachmentItem;
