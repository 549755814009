import React from 'react';
import { ModalMedium as Modal } from '../index';
import styles from './NotificationDBDisconnection.module.scss';
import FacebookSVG from '../../IconsSvg/FacebookSVG';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import { useHistory } from 'react-router-dom';


const NotificationFBDisconnection = ({ onClose }) => {
  const history = useHistory();

  return (
    <div className={styles.root}>
      <FacebookSVG/>
      <div className={styles.cell}>Disconnected</div>
      <span>You are almost there! All you need is to connect your Facebook account to launch your first Smart Ad.</span>
      <div className={styles.buttonContainer}>
        <ButtonCustom outline onClick={onClose}>Close</ButtonCustom>
        <ButtonCustom onClick={() => {
          history.push({
            pathname: '/platforms/facebook/pages',
            state: {
              routeForAction: '/platforms/facebook/pages',
              isOpenConnectModal: /\/platforms\/facebook\//g.test(history.location.pathname)
            }
          });
          onClose();
        }}>Сonnect facebook</ButtonCustom>
      </div>
    </div>
  )
}

const NotificationFBDisconnectionModal = props => {
  return (
    <Modal {...props} maxWidth="512px">
      <NotificationFBDisconnection {...props} />
    </Modal>
  );
};

export default NotificationFBDisconnectionModal;
