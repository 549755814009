import React, { useEffect, useState } from 'react';
import * as releasesService from '../../../services/releases';

const useReleases = () => {
  const [allReleases, setAllReleases] = useState(null);
  const [currentRelease, setCurrentRelease] = useState(null);
  const [updates, setUpdates] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {getReleasesData()}, []);

  const getReleasesData = async () => {
    setIsLoading(true);
    try {
      const res = await releasesService.getAllReleases();
      setAllReleases(res.data);
      if (res.data.length) {
        let lastRelease = res.data.reduce((maxRelease, release) => {
          if (!maxRelease || maxRelease.id < release.id) {
            maxRelease = release;
          }
          return maxRelease;
        }, null);
        await setReleaseAndUpdates(lastRelease);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setError(e);
    }
  };

  const setReleaseAndUpdates = async (release) => {
    setIsLoading(true);
    try {
      let settingRelease = release;
      if (allReleases) {
        settingRelease = allReleases.find(release => release.id === release.id);
      }
      let updates = await releasesService.getReleaseUpdates(release.id);
      setCurrentRelease(settingRelease);
      setUpdates(updates.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setError(e);
    }
  };

  return {allReleases, currentRelease, updates, isLoading, error, setReleaseAndUpdates};
};


export default useReleases;
