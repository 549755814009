import { TAGS } from '../actions/tags.action';


const initialState = {
  data: [],
  allTags: [],
  loading: false,
  addLoading: false,
  failure: {},
  totalCount: 0,
};

export default (state = initialState, { type, payload, totalCount }) => {
  switch (type){
    case TAGS.GET_ALL_TAGS.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case TAGS.GET_ALL_TAGS.SUCCESS: {
      return {
        ...state,
        allTags: payload,
        loading: false,
        totalCount: totalCount,
      };
    }
    case TAGS.GET_ALL_TAGS.FAILURE: {
      return {
        ...state,
        failure: payload,
        loading: false,
        totalCount: 0,
      };
    }
    case TAGS.ADD_TAG.REQUEST: {
      return {
        ...state,
        addLoading: true,
      };
    }
    case TAGS.ADD_TAG.SUCCESS: {
      return {
        ...state,
        allTags: payload,
        addLoading: false,
        totalCount: totalCount,
      };
    }
    case TAGS.ADD_TAG.FAILURE: {
      return {
        ...state,
        failure: payload,
        addLoading: false,
        totalCount: 0,
      };
    }

    case TAGS.DELETE_TAG.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case TAGS.DELETE_TAG.SUCCESS: {
      return {
        ...state,
        allTags: payload,
        loading: false,
        totalCount: totalCount,
      };
    }
    case TAGS.DELETE_TAG.FAILURE: {
      return {
        ...state,
        failure: payload,
        loading: false,
        totalCount: 0,
      };
    }
    case TAGS.EDIT_TAG.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case TAGS.EDIT_TAG.SUCCESS: {
      return {
        ...state,
        allTags: payload,
        loading: false,
        totalCount: totalCount,
      };
    }
    case TAGS.EDIT_TAG.FAILURE: {
      return {
        ...state,
        failure: payload,
        loading: false,
        totalCount: 0,
      };
    }

    default: {
      return state;
    }
  }
}
