import React from 'react';
import styles from './LoadingState.module.scss';
import LoadingContainer from '../../../../components/LoadingContainer';

const LoadingState = ({ index, isTablet }) => {
  return !isTablet ? (
    <div className={styles.root}>
      <LoadingContainer height={'15px'} maxWidth="200px" borderRadius={'5px'} animationDelay={`${-1 + index * 0.1}s`} />
      <div className={styles.brand}>
        <LoadingContainer height={'30px'} width={'30px'} borderRadius={'5px'} animationDelay={`${-1 + index * 0.2}s`} />
        <LoadingContainer height={'15px'} maxWidth="92px" borderRadius={'5px'} animationDelay={`${-1 + index * 0.2}s`} />
      </div>
      <div className={styles.creators}>
        <LoadingContainer height={'30px'} maxWidth="" borderRadius={'5px'} animationDelay={`${-1 + index * 0.3}s`} />
      </div>
      <LoadingContainer height={'25px'} maxWidth="120px" borderRadius={'5px'} animationDelay={`${-1 + index * 0.4}s`} />
      <LoadingContainer height={'25px'} maxWidth="120px" borderRadius={'5px'} animationDelay={`${-1 + index * 0.5}s`} />
      <LoadingContainer height={'25px'} maxWidth="120px" borderRadius={'5px'} animationDelay={`${-1 + index * 0.6}s`} />
      <div className={styles.archiveWrapper}>
        <LoadingContainer height={'30px'} width={'30px'} borderRadius={'5px'} animationDelay={`${-1 + index * 0.7}s`} />
      </div>
    </div>
  ) : (
    <div className={styles.rootTablet}>
      <div className={styles.item}>
        <LoadingContainer height={'20px'} width={'100px'} borderRadius={'5px'} animationDelay={`${-1 + index * 0.1}s`} />
        <LoadingContainer height={'30px'} width={'133px'} borderRadius={'5px'} animationDelay={`${-1 + index * 0.2}s`} />
      </div>
      <div className={styles.item}>
        <LoadingContainer height={'30px'} width={'30px'} borderRadius={'50%'} animationDelay={`${-1 + index * 0.3}s`} />
      </div>
      <div className={styles.item}>
        <LoadingContainer height={'30px'} width={'25%'} borderRadius={'5px'} animationDelay={`${-1 + index * 0.4}s`} />
        <LoadingContainer height={'30px'} width={'25%'} borderRadius={'5px'} animationDelay={`${-1 + index * 0.5}s`} />
        <LoadingContainer height={'30px'} width={'25%'} borderRadius={'5px'} animationDelay={`${-1 + index * 0.6}s`} />
      </div>
    </div>
  );
};

export default LoadingState;
