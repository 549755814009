import React from 'react';
import styles from './DiscussionRooms.module.scss';
import Line from '../Line';
import LoadingContainer from '../../../../components/LoadingContainer';
import { useSelector } from 'react-redux';
import CollaboratorTooltip from '../../../../components/Cells/ContentApprovalCell/CollaboratorsInfo/CollaboratorTooltip';

const DiscussionRooms = ({ collaborators }) => {
  const collaboratorsRoomLoading = useSelector(state => state.discussion.collaboratorsRoomLoading);

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        {!collaboratorsRoomLoading ? (
          <>
            {collaborators.map(item => {
              const letter = item.organization_name.slice(0, 1);
              return (
                <div key={item.organization_id} className={styles.imageContainer}>
                  <div className={styles.icon}>{letter}</div>
                  <CollaboratorTooltip collaborator={item} iconText={letter} className={styles.tooltip} />
                </div>
              );
            })}
          </>
        ) : (
          <>
            {[...Array(5).keys()].map((_, idx) => (
              <LoadingContainer key={idx} width={'30px'} height={'30px'} borderRadius={'50%'} animationDelay={`${-1 + idx * 0.1}s`} />
            ))}
          </>
        )}
      </div>
      <Line />
    </div>
  );
};

export default DiscussionRooms;
