import React from 'react';
import styles from './DeliverableAssetModal.module.scss';
import { Modal } from '../index';
import TrashSVG from '../../IconsSvg/TrashSVG';
import DownloadSVG from '../../../expert/components/IconsSvg/DownloadSVG';
import cs from 'classnames';
import SmallLoader from '../../SmallLoader';
import VideoConvertingPlaceholderImage from '../../../assets/img/video-converting-placeholder.png';


const ModalContent = ({ asset, onDelete, isVideoProcessing, isDisabledDelete,AssetComponent,src,isMobile,isAssetLoading, onDownloadAsset  }) => (
    <div className={styles.modalContentWrapper}>
      {isVideoProcessing ? (
        <VideoConvertingPlaceholder />
      ) : (
        <>
          <AssetComponent className={styles.imageBackground} src={src} alt={''} />
          <AssetComponent className={styles.image} src={src} alt={''} controls />
        </>
      )}
      {!isDisabledDelete && (
        <div className={cs(styles.button, styles.deleteButton)} onClick={onDelete}>
          <TrashSVG />
          {!isMobile && 'Delete visual'}
        </div>
      )}
      {asset.extension && (
        <div className={cs(styles.button, styles.downloadButton)} onClick={onDownloadAsset}>
          {isAssetLoading ? <SmallLoader /> : <DownloadSVG />}
          {!isMobile && 'Download'}
        </div>
      )}
    </div>
  );

const DeliverableAssetModal = props => {
  return (
    <Modal maxWidth={'1270px'} maxHeigt={'730px'} className={styles.modalRoot} {...props}>
      <ModalContent {...props} />
    </Modal>
  );
};

const VideoConvertingPlaceholder = () => {
  return <img className={styles.convertingImage} src={VideoConvertingPlaceholderImage} alt={''} />;
};

export default DeliverableAssetModal;
