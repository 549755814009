/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styles from './ImportFacebookAssetsModal.module.scss';
import { ModalMedium as Modal } from '..';
import TooltipControl from '../../../expert/components/FormControls/TooltipControl';
import { InfoIcon } from '../../../expert/components/IconsSvg/ReactIcons/ReactIcons';
import { useDispatch, useSelector } from 'react-redux';
import { getManageFacebookUrl, importAllFacebookPages } from '../../../services/facebookIntegrations';
import AssetsCell from '../../Cells/AssetsCell';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import FacebookSVG from '../../IconsSvg/FacebookSVG';
import LoadingState from '../ImportAssetsModal/LoadingState';
import { getEntireFbList } from '../../../actions/assets.actions';
import { getPricingPlanForUser } from '../../../actions/user.actions';

const ImportFacebookAssets = ({ onClose, onSelect, onRemove, selected, onCancel, onImport, dataDelete, onRemoveCell }) => {
  const dispatch = useDispatch();
  const fbIntegration = useSelector(state => state.ad_integration.ad_integrations.find(item => item.type === 'facebook'));
  const hasFbIntegrationToken = !!fbIntegration?.params?.ACCESS_TOKEN;
  const currentPlan = useSelector(state => state.user.pricingPlan.facebook_pages);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [data, setData] = useState([]);

  const handleImportAssets = async () => {
    setIsLoadingData(true);
    const { ACCESS_TOKEN, USER_ID } = fbIntegration.params;
    const facebook = await importAllFacebookPages(ACCESS_TOKEN, USER_ID);
    setData(facebook);
    await dispatch(getEntireFbList(1, 500));
    setIsLoadingData(false);
  };

  useEffect(() => {
    if (hasFbIntegrationToken) {
      handleImportAssets();
    }
    if (!currentPlan) {
      dispatch(getPricingPlanForUser());
    }
  }, [hasFbIntegrationToken]);

  const handleCancel = () => {
    if (onCancel) onCancel();
    onClose();
  };

  const handleImport = () => {
    if (!!dataDelete) dataDelete.map(item => {
      if (!selected.includes(item.id)) onRemoveCell(item.remove_id);
    });
    if (onImport) onImport();
    onClose();
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <span className={styles.title}>Select Facebook pages</span>
        <div className={styles.counter}>
          <span>
            Pages selected:
            {currentPlan === -1 ? (
              <b>{selected.length}</b>
            ) : (
              <b>
                {selected.length}/{currentPlan}
              </b>
            )}
          </span>
          {currentPlan !== -1 && (
            <TooltipControl text={`You can add up to ${currentPlan} Facebook pages based on your active plan`} place={'right'}>
              <InfoIcon color="#9FA2B4" />
            </TooltipControl>
          )}
        </div>
      </div>
      <div className={styles.container}>
        {isLoadingData ? (
          <LoadingState />
        ) : data.length ? (
          data.map(item => {
            const page = { ...item, type: 'page' };
            return <AssetsCell key={page.id} asset={page} withSelect onSelect={onSelect} onRemove={onRemove} selectedList={selected} />;
          })
        ) : (
          <div className={styles.empty}>
            <div>
              <FacebookSVG />
              <span>No Facebook pages found</span>
              <ButtonCustom
                outline
                onClick={() => window.open(getManageFacebookUrl(fbIntegration.params))}
              >
                <FacebookSVG />
                Manage in Facebook
              </ButtonCustom>
            </div>
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <ButtonCustom outline onClick={handleCancel}>
          Cancel
        </ButtonCustom>
        <ButtonCustom disabled={!selected.length || (selected.length > currentPlan && currentPlan !== -1)} onClick={handleImport}>
          Import
        </ButtonCustom>
      </div>
    </div>
  );
};

const ImportFacebookAssetsModal = props => {
  return (
    <Modal {...props} maxWidth={'500px'}>
      <ImportFacebookAssets {...props} />
    </Modal>
  );
};

export default ImportFacebookAssetsModal;
