import React, { useCallback } from 'react';
import styles from './Breadcrumbs.module.scss';
import { ReactComponent as ArrowIcon } from '../../assets/img/icons/chevroneDown.svg';
import cs from 'classnames';
import { useMediaQuery } from 'react-responsive/src';

const isLastIndex = (index, items) => index === items.length - 1;

const Breadcrumbs = ({ className, items }) => {
  const isDesktopLandscape = useMediaQuery({
    query: '(min-width: 992px) and (orientation: landscape)',
  });

  const onClick = useCallback(
    (callback, index) => {
      if (isLastIndex(index, items)) return;
      return event => callback(event);
    },
    [items]
  );

  return (
    <div className={cs(styles.root, { [styles.tabletStyle]: !isDesktopLandscape }, className)}>
      {items.map((item, i) =>
        !isDesktopLandscape ? (
          <React.Fragment key={item.name + i}>
            {i > items.length - 2 && (
              <div className={styles.arrow}>
                <ArrowIcon />
              </div>
            )}
            {i > items.length - 3 && (
              <div className={cs(styles.section, { [styles.active]: isLastIndex(i, items) })} onClick={onClick(item.callback, i)}>
                {item.name}
              </div>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment key={item.name + i}>
            {i !== 0 && (
              <div className={styles.arrow}>
                <ArrowIcon />
              </div>
            )}
            <div className={cs(styles.section, { [styles.active]: isLastIndex(i, items) })} onClick={onClick(item.callback, i)}>
              {item.name}
            </div>
          </React.Fragment>
        )
      )}
    </div>
  );
};

export default Breadcrumbs;
