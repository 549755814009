export const GRAPHIC_ASSETS_TYPES = {
  SET_VALUE: 'SET_VALUE',
  SET_MODAL_VALUE: 'SET_MODAL_VALUE',
  SET_BANNER_VALUE: 'SET_BANNER_VALUE',
  INIT: 'INIT_GRAPHIC_ASSETS'
};

export const setValueGA = (name, value) => dispatch => {
  dispatch({
    type: GRAPHIC_ASSETS_TYPES.SET_VALUE,
    name,
    value,
  });
};

export const setModalValueGA = (modalName, value) => dispatch => {
  dispatch({
    type: GRAPHIC_ASSETS_TYPES.SET_MODAL_VALUE,
    modalName,
    value,
  });
};

export const setBannerValueGA = (bannerName, values = {}) => dispatch => {
  dispatch({
    type: GRAPHIC_ASSETS_TYPES.SET_BANNER_VALUE,
    bannerName,
    values,
  });
};

export const graphicAssetsInit = () => async (dispatch) => {
  dispatch({type: GRAPHIC_ASSETS_TYPES.INIT});
  return Promise.resolve();
}
