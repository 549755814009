import React from 'react';
import styles from './TeamDetailsLoading.module.scss';
import LoadingContainer from '../../../../components/LoadingContainer';
import { useMediaQuery } from 'react-responsive/src';

const TeamDetailsLoading = ({ value, height= '101px' }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 575px)' });

  return (
    <div className={styles.root}>
      {[...Array(value).keys()].map(index => (
        <div className={styles.card} style={{ minHeight: height }} key={index}>
          <div>
            <LoadingContainer
              width={isMobile ? '70px' : '65%'}
              height='20px'
              borderRadius={'100px'}
              margin={isMobile ? '0' : '0 10px 0 0'}
              animationDelay={`${-2 + index * 0.1}s`}
              backgroundColor='#e9e9f9'
            />
            <LoadingContainer
              width={isMobile ? '74px' : '80px'}
              height={isMobile ? '28px' : '30px'}
              borderRadius={'4px'}
              animationDelay={`${-2 + index * 0.1}s`}
              backgroundColor='#e9e9f9'
            />
          </div>
          <LoadingContainer
            width='65%'
            height={isMobile ? '20px' : '16px'}
            borderRadius={'20px'}
            animationDelay={`${-2 + index * 0.1}s`}
            backgroundColor='#e9e9f9'
          />
        </div>
      ))}
    </div>
  );
};

export default TeamDetailsLoading;
