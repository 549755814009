import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import styles from './FacebookPreview.module.scss';
import { DoneIcon, EarthIcon } from '../../../expert/components/IconsSvg/ReactIcons/ReactIcons';
import SquareAsset from '../../SquareAsset';
import { ReactComponent as EmptyImage } from '../../../assets/img/icons/not_uploaded_icon.svg';
import { ReactComponent as AvatarPlaceholder } from '../../../assets/img/icons/organization-placeholders/9.svg';
import FbImageLibrary from 'react-fb-image-grid';

const FacebookPreview = ({
  testDetailsPreview,
  page,
  primaryText,
  headline,
  preview,
  title,
  onClick,
  className,
  selected,
  selectedClassName,
  size,
  callToAction,
  isVideoPreview = false,
}) => {
  const getPreview = () => {
    if (!preview || !preview?.length) {
      return (
        <div className={styles.emptyImage}>
          <EmptyImage />
          <span>No {isVideoPreview ? 'video' : 'image'} yet</span>
        </div>
      );
    }
    if (Array.isArray(preview)) {
      return <FbImageLibrary images={preview} hideOverlay />;
    }
    if (isVideoPreview) {
      return <SquareAsset src={preview} type={'video'} controls />;
    }
    return <img alt={title} src={preview} />;
  };

  return (
    <div
      className={cs(styles.root, {
        [className]: className,
        [styles.selected]: selected,
        [selectedClassName]: selected,
        [styles[size]]: size,
      })}
    >
      <div className={cs(styles.post, { [styles.testDetailsPreview]: testDetailsPreview })}>
        <div className={styles.header}>
          <div className={styles.profile}>
            <div className={styles.logo} style={{ backgroundImage: `url(${page?.image})` }}>
              {!page?.image && <AvatarPlaceholder />}
            </div>
            <div className={styles.credentials}>
              <div className={styles.name}>{page?.name || page || 'Organization name'}</div>
              <div className={styles.secondary}>
                Sponsored &nbsp;
                <EarthIcon />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.text}>{primaryText || ''}</div>
        <div className={styles.preview}>{getPreview()}</div>
        <div className={styles.link}>
          <div className={styles.headline}>{headline || ''}</div>
          {callToAction && <div className={styles.action}>{callToAction}</div>}
        </div>
      </div>
      <div className={styles.title}>{title || ''}</div>
      <div className={styles.tick}>
        <DoneIcon />
      </div>
    </div>
  );
};

FacebookPreview.propTypes = {
  page: PropTypes.object,
  primaryText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  headline: PropTypes.string,
  preview: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  title: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  selected: PropTypes.bool,
  selectedClassName: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md']),
};

export default FacebookPreview;
