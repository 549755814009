/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React  from 'react';
import styles from './TeamMemberCell.module.scss';
import avatar from '../../../assets/img/no-avatar.svg';
import cs from 'classnames';
import { useHistory } from 'react-router-dom';

const TeamMemberTabletCell = ({ member, org, handleRemove }) => {
  const history = useHistory()

  const onRemove = () => {
    handleRemove(member);
  };

  let status = {
    color: '',
    text: '',
  };

  switch (member.status_in_organization.toLowerCase()) {
    case 'active':  status = {
      color: 'green',
      text: 'Accepted',
    };
      break;
    case 'pending':  status = {
      color: 'orange',
      text: 'Pending',
    };
      break;
    case 'inactive':  status = {
      color: 'red',
      text: 'Removed',
    };
      break;
  }

  if (Object.keys(member).length > 0 && member.role_in_organization?.toLowerCase() === 'owner') {
    status = {
      color: 'darkgreen',
      text: 'Admin',
    };
  }

  const toMemberPage = () => {
    if (member.status_in_organization === 'pending') return;
    history.push(`/team-member/${member.user_id}`)
  }

  return (
    <div className={cs(styles.wrapper, styles.tablet)}>
      <div className={cs(styles.cell, styles.cell35)} onClick={toMemberPage}>
        <div className={styles.avatar}>
          <img src={member.photo_location || avatar} alt="avatar" />
        </div>
        <div className={cs(styles.nameWrapper, styles.tablet)}>
          <div className={cs(styles.name, styles.tablet)}>
            {member.first_name} {member.last_name}
            {!member.first_name && member.email}
          </div>
        </div>
      </div>
        <div className={cs(styles.cell, styles.cell25)}>
          <div className={cs(styles.status, styles.tablet, styles[status.color])}>{status.text}</div>
        </div>
        <div className={cs(styles.cell, styles.cell40)}>
          <div className={styles.email}>{member.email}</div>
        </div>
      {
        org.role?.toLowerCase() === 'owner' &&
        member.role_in_organization?.toLowerCase() !== 'owner' &&
        <div className={styles.removeButton} onClick={() => onRemove(member)}>
          Remove
        </div>
      }
    </div>
  );
};

export default TeamMemberTabletCell;
