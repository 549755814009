import { AUDIENCES_TYPES } from '../actions/audiences.actions';

const initialState = {
  audiences: [],
  entireAudiencesList: [],
  loadingEntireAudiences: false,
  audiencesForImport: [],
  loadingAudiencesForImport: false,
  totalCount: 0,
  failure: {},
  loading: false,
  deleteLoading: false,
  importLoading: false,
  refreshLoading: false,
};

export default (state = initialState, { type, payload, totalCount }) => {
  switch (type) {
    case AUDIENCES_TYPES.UPDATE_META.SUCCESS: {
      return {
        ...state,
        ...payload,
      };
    }

    case AUDIENCES_TYPES.CREATE_AUDIENCE.SUCCESS: {
      return {
        ...state,
        audiences: [...state.audiences, { ...payload[0] }],
      };
    }

    case AUDIENCES_TYPES.IMPORT_AUDIENCE.REQUEST: {
      return {
        ...state,
        importLoading: true,
      };
    }
    case AUDIENCES_TYPES.IMPORT_AUDIENCE.SUCCESS: {
      return {
        ...state,
        importLoading: false,
      };
    }
    case AUDIENCES_TYPES.IMPORT_AUDIENCE.FAILURE: {
      return {
        ...state,
        failure: payload,
        importLoading: false,
      };
    }
    case AUDIENCES_TYPES.CHANGE_AUDIENCES_STATUS.SUCCESS: {
      const idsArr = payload.map(i => i.id);
      const newAudiences = [...state.audiences].map(i => {
        const index = idsArr.indexOf(i.id);
        return index !== -1 ? payload[index] : i;
      });
      return {
        ...state,
        audiences: newAudiences,
      };
    }

    case AUDIENCES_TYPES.EDIT_AUDIENCE.SUCCESS: {
      const item = payload[0];
      const editIdx = state.audiences.findIndex(i => i.id === item.id);
      const newAudiences = [...state.audiences];
      newAudiences.splice(editIdx, 1, item);
      return {
        ...state,
        audiences: newAudiences,
      };
    }

    case AUDIENCES_TYPES.GET_AUDIENCES.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case AUDIENCES_TYPES.GET_AUDIENCES.SUCCESS: {
      return {
        ...state,
        audiences: payload,
        totalCount: totalCount,
        loading: false,
      };
    }
    case AUDIENCES_TYPES.GET_AUDIENCES.FAILURE: {
      return {
        ...state,
        failure: payload,
        loading: false,
      };
    }

    case AUDIENCES_TYPES.GET_ENTIRE_AUDIENCES_LIST.REQUEST: {
      return {
        ...state,
        loadingEntireAudiences: true,
      };
    }
    case AUDIENCES_TYPES.GET_ENTIRE_AUDIENCES_LIST.SUCCESS: {
      return {
        ...state,
        entireAudiencesList: payload,
        loadingEntireAudiences: false,
      };
    }
    case AUDIENCES_TYPES.GET_ENTIRE_AUDIENCES_LIST.FAILURE: {
      return {
        ...state,
        failure: payload,
        loadingEntireAudiences: false,
      };
    }

    case AUDIENCES_TYPES.GET_AUDIENCES_FOR_IMPORT.REQUEST: {
      return {
        ...state,
        loadingAudiencesForImport: true,
      };
    }
    case AUDIENCES_TYPES.GET_AUDIENCES_FOR_IMPORT.SUCCESS: {
      return {
        ...state,
        audiencesForImport: payload,
        loadingAudiencesForImport: false,
      };
    }
    case AUDIENCES_TYPES.GET_AUDIENCES_FOR_IMPORT.FAILURE: {
      return {
        ...state,
        failure: payload,
        loadingAudiencesForImport: false,
      };
    }

    case AUDIENCES_TYPES.REFRESH_AUDIENCES.REQUEST: {
      return {
        ...state,
        refreshLoading: true,
      }
    }
    case AUDIENCES_TYPES.REFRESH_AUDIENCES.SUCCESS: {
      return {
        ...state,
        audiences: payload,
        refreshLoading: false,
      };
    }
    case AUDIENCES_TYPES.CLEAR_FAILURE: {
      return {
        ...state,
        failure: {},
        refreshLoading: false,
      };
    }

    case AUDIENCES_TYPES.DELETE_AUDIENCE.REQUEST: {
      return {
        ...state,
        deleteLoading: true,
      }
    }
    case AUDIENCES_TYPES.DELETE_AUDIENCE.SUCCESS: {
      return {
        ...state,
        deleteLoading: false,
        audiences: payload,
      }
    }

    case AUDIENCES_TYPES.DELETE_AUDIENCE.FAILURE: {
      return {
        ...state,
        deleteLoading: false,
        failure: payload,
      }
    }

    default: {
      return state;
    }
  }
};
