import React from 'react';
import styles from './DropdownCollaboratorCell.module.scss';
import { TYPES_CAMPAIGNS_TEXTS } from '../../../../../../../shared/constants/constants';
import { useSelector } from 'react-redux';
import CollaboratorColoredIcon from '../../../../../../../components/CollaboratorColoredIcon';
import { useMediaQuery } from 'react-responsive/src';

const TableHeader = ({ isTablet }) => {
  return isTablet ? (
    <div className={styles.root}>
      <span style={{ textAlign: 'left' }}>Collaborator</span>
    </div>
  ) : (
    <div className={styles.root}>
      <span>Collaborator</span>
      <span>Organization</span>
      <span>Role</span>
    </div>
  );
};

const DropdownCollaboratorCell = ({ collaborator, tableHeader }) => {
  const user = useSelector(state => state.user);
  const { openedBoard } = useSelector(state => state.contentApproval);
  const isInitiator = openedBoard?.creator_id === collaborator?.approver_id;
  const isCurrentUser = user.data.id === +collaborator?.approver_id;
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  if (tableHeader) return <TableHeader isTablet={isTablet} />;
  return (
    <div className={styles.root}>
      <div className={styles.group}>
        <CollaboratorColoredIcon
          text={collaborator.approver_first_name.slice(0, 1).toUpperCase() + collaborator.approver_last_name.slice(0, 1).toUpperCase()}
        />
        {
          !isTablet ?
            <div>
              {collaborator.approver_first_name} {collaborator.approver_last_name} {isCurrentUser && '(You)'}
            </div>
            :
            <div className={styles.info}>
              <div className={styles.name}>
                {collaborator.approver_first_name} {collaborator.approver_last_name} {isCurrentUser && '(You)'}
              </div>
              <div className={styles.org}>{collaborator.organization_name}</div>
              <div className={styles.role}>{collaborator.type[0].toUpperCase() + collaborator.type.slice(1)} {isInitiator && '/Initiator'}</div>
            </div>
        }
      </div>
      <div className={styles.hideElement}>{collaborator.organization_name}</div>
      <div className={styles.hideElement}>
        {TYPES_CAMPAIGNS_TEXTS[collaborator.type]}{' '}
        {isInitiator && (
          <>
            / <span className={styles.gray}>Initiator</span>
          </>
        )}
      </div>
    </div>
  );
};

export default DropdownCollaboratorCell;
