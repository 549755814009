import * as adminServices from '../services/admin';

export const ADMIN_TYPES = {
  GET_ORG_CHART_DATA: {
    REQUEST: 'GET_ORG_CHART_DATA_REQUEST',
    SUCCESS: 'GET_ORG_CHART_DATA_SUCCESS',
    FAILURE: 'GET_ORG_CHART_DATA_FAILURE',
  },
};

export const getOrganizationsChartData = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_TYPES.GET_ORG_CHART_DATA.REQUEST,
      payload: {},
    });

    const response = await adminServices.getOrganizationsChartData();

    dispatch({
      type: ADMIN_TYPES.GET_ORG_CHART_DATA.SUCCESS,
      payload: response.data,
    });
    return response;
  } catch (error) {
    dispatch({
      type: ADMIN_TYPES.GET_ORG_CHART_DATA.FAILURE,
      payload: error.response.data,
    });
  }
};
