import React, { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import styles from './CreditCardDetails.module.scss';
import LogoSVG from '../../../../components/IconsSvg/LogoSVG';
import StripeForm from './StripeForm/index';
import { Redirect } from 'react-router-dom';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

export default props => {
  let billingDetails = null;
  useEffect(() => {
    localStorage.removeItem('OTTERFISH_CREDIT_CARD_DETAILS');
  }, []);

  if (!JSON.parse(localStorage.getItem('OTTERFISH_SELECTED_BILLING_ADDRESS'))) {
    return <Redirect to="/upgrade-plan/billing-address" />;
  } else {
    billingDetails = JSON.parse(localStorage.getItem('OTTERFISH_SELECTED_BILLING_ADDRESS'));
  }

  const selectedPlan = JSON.parse(localStorage.getItem('OTTERFISH_SELECTED_BILLING_PLAN'));

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <LogoSVG />
        </div>
        <div className={styles.subHeader}>Let's Finish Powering You Up With {selectedPlan?.name} Plan</div>
        <div className={styles.step}>
          <span className={styles.gray}>Billing address </span> / Card details
        </div>
        <Elements stripe={stripePromise}>
          <StripeForm billingDetails={billingDetails} selectedPlan={selectedPlan}/>
        </Elements>
      </div>
      <div className={styles.footer}>© 2021 Otterfish Limited</div>
    </div>
  );
};
