import React from 'react';
import styles from './FileToUpload.module.scss';
import cs from 'classnames';
import numeral from 'numeral';
import ImageUploadProgressBar from '../ImageUploadProgressBar';
import ActionButton from './ActionButton';
import SquareAsset from '../SquareAsset';


const FileToUpload = ({ uploaded, loading, onRemove, file, isInvalidRatio, onEdit, isVideo }) => {
  const currentPercent = Math.floor(uploaded * 100);
  const delayPercent = (loading && currentPercent > 2) ?
    currentPercent - 2 :
    currentPercent;

  const fileSize = numeral(file.size || file.file.size).format('0.0b');
  const fileLoadingText = (loading && !file.file.error) ? '• ' + delayPercent + '%' : '';
  const sizeSectionText = fileSize + fileLoadingText;

  return (
        <div className={cs(styles.root, {[styles.error]: !!file.file.error})}>

          <div className={styles.fileInfo}>
            <div className={styles.imageContainer}>
              {isVideo ? (
                <SquareAsset src={file.url} type={'video'} />
              ) : (
                <div
                  className={styles.image}
                  style={{ backgroundImage: `url(${file.url || URL.createObjectURL(file)}` }}
                />
              )}
              <div className={styles.background} />
            </div>
            <div className={styles.filesInfoContainer}>
              <div className={styles.fileName}>
                {file.file.name}
              </div>
              <div className={styles.fileSize}>
                {sizeSectionText}
              </div>
            </div>
          </div>

          <div className={styles.processInfo}>
            {loading && !file.file.error && (
              <ImageUploadProgressBar current={delayPercent} of={100} />
            )}
            {!loading && file.file.error && (
              <div className={styles.errorText}>
                {file.file.error}
              </div>
            )}
          </div>

          <div className={styles.buttons}>
            {(isInvalidRatio && !isVideo && !loading) && (
              <ActionButton type="crop" withText onClick={() => onEdit(file)} />
            )}

            {!!file.file.error && (
              <ActionButton type="error" />
            )}

            {!file.file.error && !isInvalidRatio && !loading && (
              <ActionButton type="success" />
            )}

            {!loading && (
              <ActionButton type="delete" onClick={e => onRemove(e, file)} />
            )}
          </div>
        </div>
  );
};

export default FileToUpload;
