import React from 'react';
import styles from './PhoneNumberField.module.scss';
import './PhoneNumberField.scss';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import cs from 'classnames';

const PhoneNumberField = ({
  formik,
  onValueChange,
  isError,
  isValidNUmber,
  showError,
  setIsTouched,
  isTouched,
  codeSelect,
  name,
}) => {
  const changeBlur = () => {
    if (codeSelect && setIsTouched) {
      setIsTouched();
    }
  };

  return (
    <div
      className={cs(
        styles.phoneNumber,
        isTouched && ( isError || !isValidNUmber ) ?
          'errorBorder' : name === 'phone_number' ?
            'focusBorder' : 'focusBorderSmartAds'
      )}
    >
      <PhoneInput
        name={name}
        onChange={onValueChange}
        placeholder={
        name === 'phone_number' ?
          'Choose Country Code' : 'AudienceBriefItemSection country phone number'
      }
        inputClass={styles.inputClass}
        buttonClass={styles.buttonClass}
        value={formik.values[name]}
        onBlur={changeBlur}
      />
      {
        isTouched && (isError || !isValidNUmber) &&
        <div className={styles.errorMessage}>{showError()}</div>
      }
    </div>
  )
};

export default PhoneNumberField;
