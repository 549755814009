import React from 'react';
import PropTypes from 'prop-types';
import styles from './TestActionModal.module.scss';
import { ModalSmall as Modal } from '../../../expert/components/Modal';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';

export const ModalContent = ({ title, onSubmit, onClose, agreeText, cancelText }) => {
  return (
    <>
      {title ? <p className={styles.title}>{title}</p> : null}
      <div className={styles.controls}>
        <div className={styles.col}>
          <ButtonCustom outline onClick={onClose}>
            {cancelText || 'Cancel'}
          </ButtonCustom>
        </div>
        <div className={styles.col}>
          <ButtonCustom onClick={onSubmit}>{agreeText || 'Okay'}</ButtonCustom>
        </div>
      </div>
    </>
  );
};

const TestActionModal = props => {
  return (
    <Modal {...props}>
      <ModalContent {...props} />
    </Modal>
  );
};

TestActionModal.propTypes = {
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  agreeText: PropTypes.string,
  cancelText: PropTypes.string,
  ...Modal.propTypes,
};

export default TestActionModal;
