import React from 'react';

const MessageIcon = ({ onClick }) => (
    <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_5181_14882)" onClick={() => onClick()}>
        <circle cx="72" cy="57" r="32" fill="#4E3B8B"/>
        <path d="M58.2391 65.163C56.2503 61.8105 55.5539 57.8473 56.2804 54.0177C57.007 50.188 59.1066 46.7553 62.1851 44.3641C65.2635 41.9729 69.1089 40.7878 72.9993 41.0313C76.8897 41.2747 80.5574 42.93 83.3137 45.6863C86.07 48.4426 87.7253 52.1103 87.9687 56.0006C88.2122 59.891 87.0271 63.7365 84.6359 66.8149C82.2448 69.8933 78.8121 71.993 74.9824 72.7195C71.1527 73.4461 67.1895 72.7497 63.837 70.761L63.8371 70.7608L58.3087 72.3404C58.0799 72.4057 57.8379 72.4087 57.6076 72.349C57.3773 72.2894 57.1672 72.1692 56.999 72.001C56.8308 71.8328 56.7106 71.6227 56.651 71.3924C56.5913 71.1621 56.5943 70.9201 56.6596 70.6913L58.2392 65.1629L58.2391 65.163Z" fill="white"/>
      </g>
      <defs>
        <filter id="filter0_d_5181_14882" x="0" y="0" width="144" height="144" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="15"/>
          <feGaussianBlur stdDeviation="20"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0745098 0 0 0 0 0.0392157 0 0 0 0 0.180392 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5181_14882"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5181_14882" result="shape"/>
        </filter>
      </defs>
    </svg>

  );

export default MessageIcon;
