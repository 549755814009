import { ADMIN_TYPES } from '../actions/admin.actions';

const initialState = {
  orgChartData: [],
  failure: {
    message: '',
    name: '',
    code: '',
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ADMIN_TYPES.GET_ORG_CHART_DATA.SUCCESS: {
      return {
        ...state,
        orgChartData: payload.reverse(),
      };
    }
    case ADMIN_TYPES.GET_ORG_CHART_DATA.FAILURE: {
      return {
        ...state,
        failure: payload,
      };
    }
    default: {
      return state;
    }
  }
};
