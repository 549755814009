import React, { useState } from 'react';
import styles from './CreateOrganization.module.scss';
import AuthLayout from '../../../components/AuthLayout';
import InputControl from '../../../expert/components/FormControls/InputControl';
import { useDispatch, useSelector } from 'react-redux';
import { clearFailure, createOrganization } from '../../../actions/organizations.actions';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import ErrorModal from '../../../expert/components/Modal/ErrorModal';
import { useHistory } from 'react-router-dom';
import { me } from '../../../actions/auth.actions';
import cs from 'classnames';
import { getUserUsage } from '../../../actions/user.actions';

const CreateOrganization = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(state => state.user.data)
  const { loading, failure } = useSelector(state => state.organizations);
  const organizations = useSelector(state => state.auth.organizations);
  const isFirstOrganization = !organizations?.length;
  const [companyName, setCompanyName] = useState('');

  const onCreateCompany = async () => {
    await dispatch(createOrganization('advertiser', companyName, user.email, user.phone_number));
    await dispatch(getUserUsage());
    await dispatch(me());
    history.push('/');
  };

  const handleCancel = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push('/');
    }
  };

  return (
    <AuthLayout hideMenu>
      <div className={styles.root}>
        <span className={styles.title}>Create your own organization</span>
        <InputControl
          label="Company name"
          placeholder="Your company name"
          value={companyName}
          onChange={event => setCompanyName(event.target.value)}
          className={styles.input}
        />
        <div className={cs(styles.buttonsContainer, {[styles.singleButton]: isFirstOrganization})}>
          {!isFirstOrganization && (
            <ButtonCustom onClick={handleCancel} outline> Cancel </ButtonCustom>
          )}
          <ButtonCustom onClick={onCreateCompany} loading={loading} disabled={!companyName.length}> Continue </ButtonCustom>
        </div>
      </div>
      <span className={styles.copyright}>© 2021 Otterfish Limited</span>
      <ErrorModal open={!!failure.message} onClose={() => dispatch(clearFailure())} text={failure?.message} />
    </AuthLayout>
  )
}

export default CreateOrganization;
