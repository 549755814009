import React, { useEffect, useState } from 'react';
import styles from './InviteCollaboratorsModal.module.scss';
import { Modal } from '../index';
import SuitcaseSVG from '../../IconsSvg/SuitcaseSVG';
import TextField from '../../FormControls/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Option from '../../../pages/CampaignBrief/components/Option';
import Dropdown from '../../FormControls/Dropdown';
import LightPurpleButton from '../../../pages/CampaignBrief/CampaignBriefPage/components/LightPurpleButton';
import { ReactComponent as PlaneIcon } from '../../../assets/img/icons/plane.svg';
import TextArea from '../../Inputs/TextArea';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';

const ModalContent = ({ onClose, onSubmit, availableOrganizationDropdownOption, isTablet, collaborators }) => {
  const [emailMatch, setEmailMatch] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      organizationType: undefined,
      message: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('It is not a valid email').required('Email is required'),
      organizationType: Yup.object().required('Organization type is required'),
    }),
  });

  const handleSubmit = () => {
    onSubmit(formik.values);
    onClose();
  };

  useEffect(() => {
    for (let i = 0; i < collaborators.length; i++) {
      if (collaborators[i].email == formik.values.email) {
        return setEmailMatch(true);
      }
      setEmailMatch(false);
    }
  }, [formik.values.email]);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <span className={styles.icon}>
          <SuitcaseSVG />
        </span>
        Add collaborator
      </div>
      <div className={styles.agencySection}>
        <TextField
          name={'email'}
          placeholder={'Type Email to invite'}
          className={styles.input}
          onChange={event => formik.setFieldValue('email', event.target.value)}
          value={formik.values.email}
          error={emailMatch ? 'This collaborator already invited' : formik.errors.email}
        />
        <Dropdown
          placeholder="Type of organization"
          className={styles.dropdown}
          valueClassName={styles.dropdownValue}
          placeholderClassName={styles.dropdownPlaceholder}
          items={availableOrganizationDropdownOption}
          getId={i => i.type}
          getDisplayValue={i => i.name}
          renderItem={value => <Option value={value} />}
          selectedItem={formik.values.organizationType}
          onSelect={i => formik.setFieldValue('organizationType', i)}
        />
      </div>
      <TextArea
        placeholder={
          "Hey there! Make sure your collaborator knows why you're inviting them by adding a message here, it will appear as a part of their invite email. You can also add any notes that may help in onboarding them to  your campaign"
        }
        name={'message'}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.message}
        error={formik.errors.message}
        className={styles.messageTextArea}
      />
      <div className={styles.btnBlock}>
        <LightPurpleButton
          onClick={() => handleSubmit()}
          className={styles.actionButton}
          disabled={!formik.isValid || !formik.values.email || emailMatch}
        >
          Send Invite
          <PlaneIcon />
        </LightPurpleButton>
        {isTablet && (
          <ButtonCustom className={styles.closeBtn} outline onClick={() => onClose()}>
            Close
          </ButtonCustom>
        )}
      </div>
    </div>
  );
};

const InviteCollaboratorsModal = ({ ...props }) => {
  return (
    <Modal className={styles.modal} {...props} maxWidth="550px" borderRadius="10px">
      <ModalContent {...props} />
    </Modal>
  );
};

export default InviteCollaboratorsModal;
