import { instance } from '../shared/utils/api.config.creator';

export const getOrganizationMembers = async (organizationId, params) => {
  const response = await instance.get(`/organizations/${organizationId}/users`, { params });
  return response.data;
};

export const getInvitedMembers = async (organizationId, params) => {
  const response = await instance.get(`/organizations/${organizationId}/invites?pending=true`, { params });
  return response.data;
};

export const getOrganizations = async (organizationId = '') => {
  const response = await instance.get(`/organizations/${organizationId}`);
  return response.data;
};

/**
 * Get organizations list
 * @param {{order_by?: String, order_type?: 'desc', search_filter?: String, page?: number, page_size?: number}=} params
 * @return {Promise<*>}
 */
export const getOrganizationList = async params => {
  const response = await instance.get('/organizations', { params });
  return response.data;
};

export const updateOrganization = async (organizationId, data) => {
  const response = await instance.patch(`/organizations/${organizationId}`, data);
  return response.data;
};

export const changeUserStatus = async (organizationId, userIds, status) => {
  const response = await instance.post(`/organizations/${organizationId}/change-user-status`, { status, user_ids: userIds });
  return response.data;
};

export const getOrganizationOverview = async organizationId => {
  const response = await instance.get(`/organizations/${organizationId}/overview`);
  return response.data;
};

export const deleteMemberInvite = async (organizationId, invitationId) => {
  const response = await instance.delete(`/organizations/${organizationId}/invites/${invitationId}`);
  return response.data;
};

export const getAllMembers = async (organizationId, params) => {
  const response = await instance.get(`/organizations/${organizationId}/users-and-invites`, { params });
  return response.data;
};

export const createOrganization = async params => {
  const response = await instance.post(`/organizations`, params);
  return response.data;
};
