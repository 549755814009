import React from 'react';
import styles from './Chapter.module.scss';
import ChapterItem from '../ChapterItem';
import cs from 'classnames';

const Chapter = ({ title, items = [], isMinimized, onCloseMenu }) => (
  <div className={cs(styles.chapter, { [styles.minimized]: isMinimized })}>
    {/* <div className={styles.chapterTitle}>*/}
    {/*  <span>{title}</span>*/}
    {/* </div>*/}
    <ul className={styles.chapterList}>
      {items.map((item, idx) => (
        <ChapterItem
          key={item.text + idx}
          Icon={item.icon.icon}
          iconStyleKey={item.icon.stylesKey}
          text={item.text}
          tooltipText={item.text}
          isBeta={item.isBeta}
          onClick={item.isActive ? () => onCloseMenu() : item.onClick}
          isActive={item.isActive}
          isMinimized={isMinimized}
          isContinue={item.isContinue}
          disabled={item.disabled}
        />
      ))}
    </ul>
  </div>
);

export default Chapter;
