import * as assetsServices from '../services/assets';
import * as customConversionsServices from '../services/customConversions';
import lodash from 'lodash';

export const ASSETS_TYPES = {
  GET_ASSETS: {
    REQUEST: 'GET_ASSETS_REQUEST',
    SUCCESS: 'GET_ASSETS_SUCCESS',
    FAILURE: 'GET_ASSETS_FAILURE',
  },
  GET_FACEBOOK_PAGES: {
    REQUEST: 'GET_FACEBOOK_PAGES_REQUEST',
    SUCCESS: 'GET_FACEBOOK_PAGES_SUCCESS',
    FAILURE: 'GET_FACEBOOK_PAGES_FAILURE',
  },
  GET_INSTAGRAM_ACCOUNTS: {
    REQUEST: 'GET_INSTAGRAM_ACCOUNTS_REQUEST',
    SUCCESS: 'GET_INSTAGRAM_ACCOUNTS_SUCCESS',
    FAILURE: 'GET_INSTAGRAM_ACCOUNTS_FAILURE',
  },
  GET_FACEBOOK_PIXELS: {
    REQUEST: 'GET_FACEBOOK_PIXELS_REQUEST',
    SUCCESS: 'GET_FACEBOOK_PIXELS_SUCCESS',
    FAILURE: 'GET_FACEBOOK_PIXELS_FAILURE',
  },
  GET_ENTIRE_FB_LIST: {
    REQUEST: 'GET_ENTIRE_FB_LIST_REQUEST',
    SUCCESS: 'GET_ENTIRE_FB_LIST_SUCCESS',
    FAILURE: 'GET_ENTIRE_FB_LIST_FAILURE',
  },
  GET_ENTIRE_IG_LIST: {
    REQUEST: 'GET_ENTIRE_IG_LIST_REQUEST',
    SUCCESS: 'GET_ENTIRE_IG_LIST_SUCCESS',
    FAILURE: 'GET_ENTIRE_IG_LIST_FAILURE',
  },
  GET_ENTIRE_PIXELS_LIST: {
    REQUEST: 'GET_ENTIRE_PIXELS_LIST_REQUEST',
    SUCCESS: 'GET_ENTIRE_PIXELS_LIST_SUCCESS',
    FAILURE: 'GET_ENTIRE_PIXELS_LIST_FAILURE',
  },
  IMPORT_ALL_ASSETS: {
    REQUEST: 'IMPORT_ALL_ASSETS_REQUEST',
    SUCCESS: 'IMPORT_ALL_ASSETS_SUCCESS',
    FAILURE: 'IMPORT_ALL_ASSETS_FAILURE',
  },
  IMPORT_ALL_CUSTOM_CONVERSIONS: {
    REQUEST: 'IMPORT_ALL_CUSTOM_CONVERSIONS_REQUEST',
    SUCCESS: 'IMPORT_ALL_CUSTOM_CONVERSIONS_SUCCESS',
    FAILURE: 'IMPORT_ALL_CUSTOM_CONVERSIONS_FAILURE',
  },
  GET_CONVERSIONS_EVENTS: {
    REQUEST: 'GET_CONVERSIONS_EVENTS_REQUEST',
    SUCCESS: 'GET_CONVERSIONS_EVENTS_SUCCESS',
    FAILURE: 'GET_CONVERSIONS_EVENTS_FAILURE',
  },
  IMPORT_ASSETS: {
    REQUEST: 'IMPORT_ASSETS_REQUEST',
    SUCCESS: 'IMPORT_ASSETS_SUCCESS',
    FAILURE: 'IMPORT_ASSETS_FAILURE',
  },
  DELETE_ASSET: {
    REQUEST: 'DELETE_ASSET_REQUEST',
    SUCCESS: 'DELETE_ASSET_SUCCESS',
    FAILURE: 'DELETE_ASSET_FAILURE',
  },
  CLEAR_FAILURE: 'CLEAR_FAILURE',
};

export const getAssets = orgId => async (dispatch, getState) => {
  try {
    dispatch({
      type: ASSETS_TYPES.GET_ASSETS.REQUEST,
      payload: {},
    });

    const currentOrg = getState().auth.currentOrganization;

    const response = await assetsServices.getAssets(orgId || currentOrg.organization_id);

    dispatch({
      type: ASSETS_TYPES.GET_ASSETS.SUCCESS,
      payload: response.data,
    });
    return response;
  } catch (error) {
    dispatch({
      type: ASSETS_TYPES.GET_ASSETS.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getFacebookPages = (page, pageSize, orgId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ASSETS_TYPES.GET_FACEBOOK_PAGES.REQUEST,
      payload: {},
    });
    const currentOrg = getState().auth.currentOrganization;
    const params = {};
    params.organization_id = orgId || currentOrg.organization_id;
    params.type = 'page';
    if (page) params.page = page;
    if (pageSize) params.page_size = pageSize;

    const response = await assetsServices.getFacebookPages(null, params);

    dispatch({
      type: ASSETS_TYPES.GET_FACEBOOK_PAGES.SUCCESS,
      payload: response.data,
      totalCount: response.total_count,
    });
    return response;
  } catch (error) {
    dispatch({
      type: ASSETS_TYPES.GET_FACEBOOK_PAGES.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getInstagramAccounts = (page, pageSize, orgId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ASSETS_TYPES.GET_INSTAGRAM_ACCOUNTS.REQUEST,
      payload: {},
    });
    const currentOrg = getState().auth.currentOrganization;
    const params = {};
    params.organization_id = orgId || currentOrg.organization_id;
    params.type = 'instagram_account';
    if (page) params.page = page;
    if (pageSize) params.page_size = pageSize;
    const response = await assetsServices.getInstagramAccounts(null, params);

    dispatch({
      type: ASSETS_TYPES.GET_INSTAGRAM_ACCOUNTS.SUCCESS,
      payload: response.data,
      totalCount: response.total_count,
    });
    return response;
  } catch (error) {
    dispatch({
      type: ASSETS_TYPES.GET_INSTAGRAM_ACCOUNTS.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getFacebookPixels = (page, pageSize, orgId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ASSETS_TYPES.GET_FACEBOOK_PIXELS.REQUEST,
      payload: {},
    });
    const currentOrg = getState().auth.currentOrganization;
    const params = {};
    params.organization_id = orgId || currentOrg.organization_id;
    params.type = 'pixel';
    if (page) params.page = page;
    if (pageSize) params.page_size = pageSize;

    const pixelsResponse = await assetsServices.getFacebookPixels(null, params);

    dispatch({
      type: ASSETS_TYPES.GET_FACEBOOK_PIXELS.SUCCESS,
      payload: pixelsResponse.data,
      totalCount: pixelsResponse.total_count,
    });
    return pixelsResponse;
  } catch (error) {
    dispatch({
      type: ASSETS_TYPES.GET_FACEBOOK_PIXELS.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getEntireFbList = (page, pageSize) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ASSETS_TYPES.GET_ENTIRE_FB_LIST.REQUEST,
      payload: {},
    });
    const params = {};
    params.type = 'page';
    if (page) params.page = page;
    if (pageSize) params.page_size = pageSize;

    const response = await assetsServices.getFacebookPages(null, params);

    dispatch({
      type: ASSETS_TYPES.GET_ENTIRE_FB_LIST.SUCCESS,
      payload: response.data,
    });
    return response;
  } catch (error) {
    dispatch({
      type: ASSETS_TYPES.GET_ENTIRE_FB_LIST.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getEntireIgList = (page, pageSize) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ASSETS_TYPES.GET_ENTIRE_IG_LIST.REQUEST,
      payload: {},
    });
    const params = {};
    params.type = 'instagram_account';
    if (page) params.page = page;
    if (pageSize) params.page_size = pageSize;

    const response = await assetsServices.getInstagramAccounts(null, params);

    dispatch({
      type: ASSETS_TYPES.GET_ENTIRE_IG_LIST.SUCCESS,
      payload: response.data,
    });
    return response;
  } catch (error) {
    dispatch({
      type: ASSETS_TYPES.GET_ENTIRE_IG_LIST.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getEntirePixelsList = (page, pageSize) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ASSETS_TYPES.GET_ENTIRE_PIXELS_LIST.REQUEST,
      payload: {},
    });
    const params = {};
    params.type = 'pixel';
    if (page) params.page = page;
    if (pageSize) params.page_size = pageSize;

    const response = await assetsServices.getFacebookPixels(null, params);

    dispatch({
      type: ASSETS_TYPES.GET_ENTIRE_PIXELS_LIST.SUCCESS,
      payload: response.data,
    });
    return response;
  } catch (error) {
    dispatch({
      type: ASSETS_TYPES.GET_ENTIRE_PIXELS_LIST.FAILURE,
      payload: error.response.data,
    });
  }
};

export const importAllCustomConversions = (pixelId, orgId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ASSETS_TYPES.IMPORT_ALL_CUSTOM_CONVERSIONS.REQUEST,
      payload: {},
    });
    const currentOrg = getState().auth.currentOrganization; // JSON.parse(localStorage.getItem('OTTERFISH_CURRENT_ORGANIZATION'));
    const data = {};
    data.organization_id = orgId || currentOrg.organization_id;
    data.pixel_id = +pixelId;
    const response = await customConversionsServices.importCustomConversionsAll(data);

    const pixels = getState().assets.facebookPixels;

    const newPixels = pixels.map(pixel => {
      if (+response.data?.[0].pixel_id === +pixel.id) {
        pixel.conversions = response.data;
      }
      return pixel;
    });

    dispatch({
      type: ASSETS_TYPES.IMPORT_ALL_CUSTOM_CONVERSIONS.SUCCESS,
      payload: newPixels,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: ASSETS_TYPES.IMPORT_ALL_CUSTOM_CONVERSIONS.FAILURE,
      payload: error.response?.data,
    });
  }
};

export const getConversionsEvents = (orgId, pixelId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ASSETS_TYPES.GET_CONVERSIONS_EVENTS.REQUEST,
      payload: {},
    });
    const currentOrg = getState().auth.currentOrganization;
    const organization_id = orgId || currentOrg.organization_id;
    const pixel_id = +pixelId;
    const response = await customConversionsServices.getConversionsEvents(organization_id, pixel_id);
    const pixels = getState().assets.facebookPixels;

    const newPixels = pixels.map(pixel => {
      if (+response.data?.[0].pixel_id === +pixel.id) {
        pixel.conversions = response.data;
      }
      return pixel;
    });

    dispatch({
      type: ASSETS_TYPES.GET_CONVERSIONS_EVENTS.SUCCESS,
      payload: newPixels,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: ASSETS_TYPES.GET_CONVERSIONS_EVENTS.FAILURE,
      payload: error.response?.data,
    });
  }
};

export const importAllAssets = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ASSETS_TYPES.IMPORT_ALL_ASSETS.REQUEST,
      payload: {},
    });
    const currentOrg = getState().auth.currentOrganization; // JSON.parse(localStorage.getItem('OTTERFISH_CURRENT_ORGANIZATION'));
    const response = await assetsServices.importAssetsAll(currentOrg.organization_id);

    dispatch({
      type: ASSETS_TYPES.IMPORT_ALL_ASSETS.SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: ASSETS_TYPES.IMPORT_ALL_ASSETS.FAILURE,
      payload: error.response.data,
    });
  }
};

export const importAssets = data => async (dispatch, getState) => {
  try {
    dispatch({
      type: ASSETS_TYPES.IMPORT_ASSETS.REQUEST,
    });
    const currentOrg = getState().auth.currentOrganization; // JSON.parse(localStorage.getItem('OTTERFISH_CURRENT_ORGANIZATION'))
    const userId = getState().auth.user.id;
    const type = data[0].type;
    const response = await assetsServices.importAssets(currentOrg.organization_id, data);

    const fbPages = lodash.filter(data, { type: 'page' });
    const igAccounts = lodash.filter(data, { type: 'instagram_account' });
    const pixels = lodash.filter(data, { type: 'pixel' });

    // if (igAccounts.length > 0) {
    //   window.analytics.track('instagram_imported', {
    //     userId: userId,
    //     count: igAccounts.length,
    //   });
    // }
    // if (fbPages.length > 0) {
    //   window.analytics.track('fb_page_import', {
    //     userId: userId,
    //     count: fbPages.length,
    //   });
    // }
    // if (pixels.length > 0) {
    //   window.analytics.track('pixel_import', {
    //     userId: userId,
    //     count: pixels.length,
    //   });
    // }

    dispatch({
      type: ASSETS_TYPES.IMPORT_ASSETS.SUCCESS,
      payload: [...getState().assets.assets.filter(i => i.type !== type), ...response.data],
    });
    return response;
  } catch (error) {
    dispatch({
      type: ASSETS_TYPES.IMPORT_ASSETS.FAILURE,
      payload: error.response.data,
    });
  }
};

export const deleteAsset = id => async (dispatch, getState) => {
  try {
    dispatch({
      type: ASSETS_TYPES.DELETE_ASSET.REQUEST,
    });

    const response = await assetsServices.deleteAsset(id);

    dispatch({
      type: ASSETS_TYPES.DELETE_ASSET.SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ASSETS_TYPES.DELETE_ASSET.FAILURE,
      payload: error.response.data,
    });
  }
};

export const clearFailure = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ASSETS_TYPES.CLEAR_FAILURE,
    });
  } catch (err) {}
};
