import React from 'react';
import styles from './ArchiveButton.module.scss';
import { ReactComponent as ArchiveIcon } from '../../assets/img/icons/archive-icon.svg';
import { ReactComponent as UnzipIcon } from '../../assets/img/icons/unzip-icon.svg';

const ArchiveButton = ({ onClick, isUnzipType }) => {
  return (
    <div className={styles.root} onClick={onClick}>
      {isUnzipType && <UnzipIcon />}
      {!isUnzipType && <ArchiveIcon />}
    </div>
  );
};

export default ArchiveButton;
