import React from 'react';
import styles from './DeliverableName.module.scss';
import cs from 'classnames';

const DeliverableName = ({ name }) => {
  const emptyNameText = 'No name yet';
  const cellName = name || emptyNameText;

  return (
    <div className={styles.nameInputContainer}>
      <div className={cs(styles.name, { [styles.noName]: cellName === 'No name yet' } /* { [styles.hidden]: openNameEdit }*/)}>
        {cellName}
      </div>
    </div>
  );
};

export default DeliverableName;
