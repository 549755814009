import React, { useRef, useState } from 'react';
import styles from './DotsDropdownButton.module.scss';
import { useClickOutside } from '../../shared/hooks/useClickOutside';
import cs from 'classnames';

const DotsDropdownButton = ({ buttonClassName, renderDropdown = () => {}, onOpenAction = () => {} }) => {
  const refList = useRef(null);
  const refButton = useRef(null);
  const [isListOpen, setIsListOpen] = useState(false);

  useClickOutside(refList, refButton, () => setIsListOpen(false));

  return (
    <div className={styles.root}>
      <div
        className={cs(styles.dots, buttonClassName)}
        ref={refButton}
        onClick={event => {
          event.stopPropagation();
          if (isListOpen == false) {
            onOpenAction();
          }
          setIsListOpen(!isListOpen);
        }}
      >
        <div className={styles.dot} />
        <div className={styles.dot} />
        <div className={styles.dot} />
      </div>

      {isListOpen && renderDropdown(refList, () => setIsListOpen(false))}
    </div>
  );
};

export default DotsDropdownButton;
