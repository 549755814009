import React from 'react';
import styles from './ConceptVisualCard.module.scss';
import AddAssetButton from '../../../AddAssetButton';
import DeleteAssetButton from '../../../DeleteAssetButton';
import cs from 'classnames';
import VisualHover from '../../../VisualHover';
import useVisualCardHandlers from '../../../../hooks/useVisualCardHandlers';
import SquareAsset from '../../../../../../../components/SquareAsset';
import EditorComponent from '../../../../../../../components/EditorComponent';
import { useMediaQuery } from 'react-responsive/src';
import AutoResizeTextArea from '../../AutoResizeTextArea';
import SmallLoader from '../../../../../../../components/SmallLoader';
import DownloadSVG from '../../../../../../../expert/components/IconsSvg/DownloadSVG';
import { ReactComponent as DeleteIcon } from '../../../../../../../assets/img/icons/trash.svg';
import useDownloadAsset from '../../../../../../../shared/hooks/useDownloadAsset';
import CaptionInput from '../../../CaptionInput';

const ConceptVisualCard = ({
  onCreateVisual,
  onDeleteVisual,
  onUpdateVisual,
  asset,
  isError,
  openAsset,
  isDisabledSection,
  hideInput,
  lengthItems,
}) => {
  const caption = (asset && asset.caption) || '';
  const assetUrl = (asset?.thumbnail_file_id && asset?.thumbnail_location) || asset?.preview_location || asset?.location;
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const isAssetAdded = !!asset?.file || !!asset?.location;

  const { deleteConfirmModal, handleCreate, handleUpdate, handleDelete, isImageDisplay } = useVisualCardHandlers(
    onCreateVisual,
    onUpdateVisual,
    onDeleteVisual,
    isDisabledSection,
    asset
  );

  const { isAssetLoading, downloadAsset } = useDownloadAsset();

  const onDownload = () => {
    if (isAssetLoading) return;
    downloadAsset(asset.location, asset.extension);
  };

  return isMobile ? (
    <div className={cs(styles.root, { [styles.removable]: isAssetAdded })}>
      <div
        className={cs(
          styles.imageContainer,
          { [styles.mobileStyle]: isMobile },
          { [styles.heightFirstItem]: hideInput },
          { [styles.error]: isError }
        )}
      >
        {assetUrl ? (
          <>
            {isImageDisplay() ? (
              <>
                <img src={assetUrl} alt="no image" />
                {isMobile && (
                  <div className={styles.iconsMobile}>
                    <div className={styles.background}>
                      <div onClick={onDownload} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {isAssetLoading ? <SmallLoader /> : <DownloadSVG />}
                      </div>
                    </div>
                    {!isDisabledSection && (
                      <div className={cs(styles.background, { [styles.deleteCardButton]: !isDisabledSection })}>
                        <DeleteIcon onClick={handleDelete} />
                      </div>
                    )}
                  </div>
                )}
              </>
            ) : (
              <SquareAsset src={assetUrl} type={'video'} />
            )}
            <VisualHover className={styles.imageHover} onClick={() => openAsset(asset)} />
          </>
        ) : (
          <AddAssetButton
            actionText="Add Asset"
            onAddAsset={handleCreate}
            fileTypes={['image', 'video']}
            lengthItems={lengthItems}
            isMobile={isMobile}
          />
        )}
      </div>
      {!hideInput &&
        isAssetAdded &&
        (isMobile ? (
          <AutoResizeTextArea
            className={styles.textArea}
            placeHolder={`Add caption...`}
            value={caption}
            onChange={handleUpdate}
            disabled={isDisabledSection || !asset}
          />
        ) : (
          <div className={styles.textContainer}>
            <CaptionInput
              disabled={isDisabledSection || !asset}
              caption={caption}
              onChange={handleUpdate}
              placeholder="Describe visual..."
            />
          </div>
        ))}
      {deleteConfirmModal}
    </div>
  ) : (
    <div className={cs(styles.root, { [styles.removable]: !!asset?.file || !!asset?.location })}>
      <div className={cs(styles.imageContainer, { [styles.error]: isError })}>
        {assetUrl ? (
          <>
            {isImageDisplay() ? (
              <img src={assetUrl} alt="no image" />
            ) : (
              <SquareAsset src={assetUrl} type={'video'} />
            )}
            <VisualHover className={styles.imageHover} onClick={() => openAsset(asset)} />
          </>
        ) : (
          <AddAssetButton actionText="Add Asset" onAddAsset={handleCreate} fileTypes={['image', 'video']} />
        )}
      </div>
      <div className={styles.textContainer}>
        <div className={styles.textContainer}>
          {isAssetAdded && (
            <EditorComponent
              initialValue={caption}
              onChange={handleUpdate}
              placeholder="Describe visual..."
              disabled={isDisabledSection || !asset}
            />
          )}
        </div>
      </div>
      {!isDisabledSection && <DeleteAssetButton className={styles.deleteCardButton} onDelete={handleDelete} />}
      {deleteConfirmModal}
    </div>
  );
};

export default ConceptVisualCard;
