import React, { useEffect, useRef } from 'react';
import styles from './AutoResizeTextArea.module.scss';
import cs from 'classnames';

const AutoResizeTextArea = ({ className, value = '', onChange, placeHolder, forwardedRef, ...props }) => {
  const growRef = useRef(null);

  const onChangeHandler = e => {
    const target = e.target;
    target.parentNode.dataset.replicatedValue = target.value;
    onChange(target.value);
  };

  useEffect(() => {
    if (growRef.current) {
      growRef.current.dataset.replicatedValue = value;
    }
  }, []);

  return (
    <div className={cs(styles.growWrap, className)} ref={growRef}>
      <textarea
        {...props}
        ref={forwardedRef}
        rows={1}
        className={cs({ [styles.filled]: !!value?.length })}
        value={value}
        onChange={onChangeHandler}
        placeholder={placeHolder}
      />
    </div>
  );
};

export default AutoResizeTextArea;
