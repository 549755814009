import React from 'react';
import styles from './ChangePasswordForm.module.scss';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import InputFormik from '../../../components/FormControls/InputFormik';
import ButtonCustom from '../../../components/Buttons/ButtonCustom';

const SignupSchema = Yup.object().shape({
  newPassword: Yup.string().min(6, 'Too Short!').required('Required'),
  confirmPassword: Yup.string()
    .min(6, 'Too Short!')
    .required('Required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

const ChangePasswordForm = ({ onSubmitPassword, onCloseModal }) => {
  return (
    <Formik
      initialValues={{
        newPassword: '',
        confirmPassword: '',
      }}
      validationSchema={SignupSchema}
      onSubmit={values => {
        onSubmitPassword(values);
        onCloseModal();
      }}
    >
      {({ errors, touched, setFieldValue, ...props }) => {
        return (
          <Form>
            <div className={styles.form}>
              <div className={styles.header}>
                <p className={styles.title}>Change your password</p>
                <div className={styles.footer}>
                  <div className={styles.flex}>
                    <div className={styles.cancelBtn} onClick={onCloseModal}>
                      Cancel
                    </div>
                    <ButtonCustom
                      onClick={props.handleSubmit}
                      className={styles.col}
                      disabled={props.values.newPassword !== props.values.confirmPassword || props.values.newPassword === ''}
                    >
                      Save
                    </ButtonCustom>
                  </div>
                </div>
              </div>
              <div className={styles.body}>
                <div className={styles.formGroup}>
                  <InputFormik
                    label="New Password"
                    name="newPassword"
                    placeholder="Enter password"
                    errors={errors.newPassword}
                    touched={touched.newPassword}
                    tooltip={false}
                    border={true}
                    type="password"
                    v2
                  />
                </div>
                <div className={styles.formGroup}>
                  <InputFormik
                    label="Confirm Password"
                    name="confirmPassword"
                    placeholder="Confirm password"
                    errors={errors.confirmPassword}
                    touched={touched.confirmPassword}
                    tooltip={false}
                    border={true}
                    type="password"
                    v2
                  />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangePasswordForm;
