import React, { useState, useEffect } from 'react'
import styles from './DatePicker.module.scss'
import Dropdown from '../../components/FormControls/Dropdown'
import TextField from '../../components/FormControls/TextField'
import dayjs from 'dayjs'
import {isInteger} from 'lodash'
import cs from 'classnames';


const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]


export default ({ date, setDate, v2 = false }) => {
  const [dayjsDate, setDayjsDate] = useState(date ? dayjs(date) : dayjs())
  const [year, setYear] = useState(dayjsDate.year())
  const [day, setDay] = useState(dayjsDate.date())

  const handleDateChange = (field, value) => {
    const newDate = dayjsDate[field](value)
    setDate(newDate.format('YYYY-MM-DD'))
    setDayjsDate(newDate)
  }

  const handleYearBlur = () => {
    if (isInteger(+year)) {
      handleDateChange('year', +year)

    } else {
      setYear(dayjsDate.year())
    }
  }

  const handleDayBlur = () => {
    if (isInteger(+day) && day > 0) {
      handleDateChange('date', +day)
    } else {
      setYear(dayjsDate.date())
    }
  }

  useEffect(() => {
    setDay(dayjsDate.date())
  }, [dayjsDate])

  return <div className={styles.simpleDatepicker}>
    <div className={cs(styles.month, {[styles.mountV2]:v2})}>
      <Dropdown
        items={MONTHS}
        selectedItem={MONTHS[dayjsDate.month()]}
        getDisplayValue={i => i}
        getId={i => i}
        className={styles.dropdown}
        onSelect={v => handleDateChange('month', MONTHS.indexOf(v))}
        renderItem={i => <div className={styles.monthItem}>{i}</div>}
        v2
      />
    </div>
    <div className={cs(styles.day, {[styles.dayV2]:v2})}>
      <TextField value={day} onBlur={handleDayBlur} onChange={e => setDay(e.target.value)} className={cs({[styles.inputV2]:v2})} />
    </div>
    <div className={cs(styles.year, {[styles.dayV2]:v2})}>
      <TextField value={year} onBlur={handleYearBlur} onChange={e => setYear(e.target.value)} className={cs({[styles.inputV2]:v2})} />
    </div>
  </div>
}
