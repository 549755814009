import React from 'react';

function DocumentSVG() {
  return (
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3993 22H2.1999C0.984931 22 0 21.0151 0 19.8001V2.20086C0 0.985889 0.984931 0.000958579 2.1999 0.000958579H9.89957C9.90943 -0.000319526 9.91941 -0.000319526 9.92927 0.000958579H9.93587C9.94625 0.0042123 9.95694 0.00642312 9.96777 0.00755829C10.0648 0.0137765 10.1605 0.0326352 10.2527 0.0636558H10.2692H10.2857H10.2989C10.3191 0.0778736 10.3383 0.0936899 10.356 0.110954C10.4759 0.164217 10.5851 0.238751 10.6783 0.330944L17.278 6.93066C17.3702 7.02392 17.4448 7.13311 17.498 7.25294C17.5079 7.27714 17.5156 7.30024 17.5233 7.32554L17.5343 7.35634C17.565 7.44806 17.5832 7.54353 17.5882 7.64013C17.5892 7.65108 17.5918 7.66184 17.5959 7.67202V7.67862C17.5977 7.68583 17.5988 7.6932 17.5992 7.70062V19.8001C17.5992 20.3835 17.3675 20.9431 16.9549 21.3557C16.5423 21.7682 15.9828 22 15.3993 22ZM9.89957 2.20086V7.70062H15.3993L9.89957 2.20086Z"
        fill="#46CFB0"
      />
    </svg>
  );
}

export default DocumentSVG;
