import React, { useState } from 'react';
import styles from './CampaignBriefCell.module.scss';
import { useHistory } from 'react-router-dom';
import cs from 'classnames';
import SortButtons from '../../../pages/ContentApproval/ContentBoard/components/DeliverablesList/SortButtons';
import brandPreviewImage from '../../../assets/img/brandPlaceholder.svg';
import useContentBoardStatusTile from '../../../pages/ContentApproval/ContentBoard/useContentBoardStatusTile';
import CollaboratorsInfo from '../ContentApprovalCell/CollaboratorsInfo';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import TestActionModal from '../../Modals/TestActionModal';
import ArchiveButton from '../../ArchiveButton';
import { archiveBrief, unzipBrief } from '../../../actions/brief.actions';
import { useLocation } from 'react-router';

const SortButtonsWrapper = ({ orderByName, sort, setSort }) => {
  return (
    <SortButtons
      orderBy={orderByName}
      isActive={orderType => orderByName === sort.order_by && orderType === sort.order_type}
      onSort={(orderBy, orderType) => setSort({ order_by: orderBy, order_type: orderType })}
    />
  );
};

const CellHeader = ({ sort, setSort, style }) => {
  return (
    <div className={cs(styles.root, styles.header)} style={style}>
      <span>
        Campaign name
        <SortButtonsWrapper sort={sort} setSort={setSort} orderByName="name" />
      </span>
      <span>
        Brand
        <SortButtonsWrapper sort={sort} setSort={setSort} orderByName="brand_name" />
      </span>
      <span>Collaborators</span>
      <span>
        Creation date
        <SortButtonsWrapper sort={sort} setSort={setSort} orderByName="created_at" />
      </span>
      <span>
        Status
        <SortButtonsWrapper sort={sort} setSort={setSort} orderByName="status" />
      </span>
      <span></span>
    </div>
  );
};
const CellContent = ({ brief, style, onDetail, isTablet, onArchive }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const userId = useSelector(state => state.auth.user.id);
  const brandLogo = brief.brand_logo_thumbnail_location || brief.brand_logo_location || brandPreviewImage;
  const statusTile = useContentBoardStatusTile(brief.status);
  const location = useLocation();

  const [isOpenArchiveModal, setIsOpenArchiveModal] = useState(false);

  const handleDetailClick = () => {
    if (onDetail) {
      onDetail(brief.id);
      return;
    }
    history.push(`/campaign/${brief.id}`);
  };

  const handleArchiveBrief = () => {
    dispatch(archiveBrief(currentOrganizationID, brief.id)).then(() => onArchive(brief, 'archive'));
    window.analytics.page('Campaign Dashboard', 'Archive', {
      title: 'Campaign Dashboard',
      path: location.pathname,
      referrer: document.referrer,
      userId: userId,
    });
  };
  const handleUnzipBrief = () => {
    dispatch(unzipBrief(currentOrganizationID, brief.id)).then(() => onArchive(brief, 'unzip'));
  };

  return (
    <>
      {!isTablet ? (
        <>
          <div className={styles.root} style={style} onClick={handleDetailClick}>
            <div className={styles.boardName}>
              <div className={styles.boardNameText} style={{ overflow: 'visible' }}>
                {brief.name}
              </div>
            </div>
            <div className={styles.brand}>
              <img src={brandLogo} alt="logo" />
              <span>{brief.brand_name}</span>
            </div>
            <div className={styles.creators}>
              <CollaboratorsInfo collaborators={brief?.collaborators} />
            </div>
            <div className={styles.createdDate}>{dayjs(brief.created_at).format('DD / MMM / YYYY')}</div>
            <div className={styles.status}>{statusTile}</div>
            <div className={styles.archive}>
              <ArchiveButton
                isUnzipType={brief.is_archived}
                onClick={e => {
                  e.stopPropagation();
                  setIsOpenArchiveModal(true);
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <div className={cs(styles.root, { [styles.rootMobile]: isTablet })} style={style} onClick={handleDetailClick}>
          <div className={styles.levelOne}>
            <div className={styles.boardName}>{brief.name}</div>
            <div className={styles.status}>{statusTile}</div>
          </div>
          {!!brief?.collaborators.length && (
            <div className={styles.levelTwo}>
              <div className={styles.creators}>
                <CollaboratorsInfo collaborators={brief?.collaborators} />
              </div>
              <div className={styles.archive}>
                <ArchiveButton
                  isUnzipType={brief.is_archived}
                  onClick={e => {
                    e.stopPropagation();
                    setIsOpenArchiveModal(true);
                  }}
                />
              </div>
            </div>
          )}
          <div className={styles.levelThree}>
            {brief?.campaign_brief_name && (
              <div className={styles.container}>
                <div className={styles.head}>Brief:</div>
                <div>{brief?.campaign_brief_name}</div>
              </div>
            )}
            <div className={styles.container}>
              {brief.brand_name && (
                <>
                  <div className={styles.head}>Brand:</div>
                  <div>{brief.brand_name}</div>
                </>
              )}
            </div>
            <div className={styles.container}>
              {brief.created_at && (
                <>
                  <div className={styles.head}>Created:</div>
                  <div>{dayjs(brief.created_at).format('DD / MMM / YYYY')}</div>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      <TestActionModal
        open={isOpenArchiveModal}
        title={
          brief.is_archived
            ? `Are you sure you want to restore "${brief.name}" campaign`
            : `Are you sure you want to archive "${brief.name}" campaign`
        }
        agreeText="Yes"
        cancelText="No"
        onSubmit={() => {
          setIsOpenArchiveModal(false);
          if (brief.is_archived) {
            handleUnzipBrief();
          } else {
            handleArchiveBrief();
          }
        }}
        onClose={() => setIsOpenArchiveModal(false)}
      />
    </>
  );
};

const CampaignBriefCell = ({ sort, setSort, tableHeader, ...props }) => {
  if (tableHeader) return <CellHeader style={props.style} sort={sort} setSort={setSort} />;
  return <CellContent {...props} />;
};

export default CampaignBriefCell;
