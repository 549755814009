/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './SelectDropdown.module.scss';
import cs from 'classnames';
import ArrowDownSVG from '../../IconsSvg/ArrowDownSVG';
import InfoBlackSVG from '../../IconsSvg/InfoBlackSVG';
import RadioButton from '../../../../components/FormControls/RadioButton';
import PlusSVG from '../../IconsSvg/PlusSVG';
import CheckedSVG from '../../IconsSvg/CheckedSVG';

const SelectDropdown = props => {
  const {
    plusActionText,
    plusAction,
    options,
    isDisabledOption,
    selectedId,
    onSelected,
    onToggleDropdown,
    optionKey,
    renderOption,
    label,
    placeholder,
    errorMessage,
    isOver,
    isLocation,
    icon,
    disabled,
    withNothing,
    needRadio,
    className,
    disabledClassName,
    inputClassName,
    activeInputClassName,
    placeholderClassName,
    errorInputClassName,
    dropdownClassName,
    counter,
    backlight,
    IconArrow,
    IconCross,
    onClear,
    text,
    isHeaderSelect,
    onClickSelect = () => {},
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const isGender = placeholder === 'Choose gender';

  const onClose = index => {
    setIsOpen(false);
    if (selectedId !== index) onSelected(index);
    if (onToggleDropdown) onToggleDropdown(false);
  };

  const onWheel = e => {
    if (disabled) return;
    if (e.deltaY < 0 && selectedId > (withNothing ? -1 : 0)) {
      onSelected(selectedId - 1);
    } else if (e.deltaY > 0 && selectedId < options.length - 1) {
      onSelected(selectedId + 1);
    }
    e.preventDefault();
  };

  const onInputClick = e => {
    onClickSelect();
    if (disabled || !options.length) return;
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (onToggleDropdown) onToggleDropdown(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (inputRef) {
      inputRef.current.addEventListener('wheel', onWheel);
    }
    return () => {
      inputRef.current.removeEventListener('wheel', onWheel);
    };
  }, [inputRef, selectedId, disabled]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div
      className={cs(
        styles.root,
        isOpen ? styles.show : styles.hide,
        { [styles.disabled]: disabled || !options.length },
        { [disabledClassName]: (disabled || !options.length) && disabledClassName },
        { [className]: !isGender || (className && isOpen) },
        { [styles.backlight]: backlight }
      )}
    >
      {label ? <label className={styles.label}>{label}</label> : null}
      <div
        className={cs(
          styles.head,
          inputClassName || null,
          isOpen && activeInputClassName,
          errorMessage ? errorInputClassName || styles.error : null
        )}
        onClick={onInputClick}
        ref={inputRef}
      >
        <div className={styles.context}>
          {text && <span className={styles.text}>{text}</span>}
          {!(selectedId === null || selectedId === -1 || !options.length || disabled) ? (
            renderOption ? (
              renderOption(options[selectedId])
            ) : (
              optionKey(options[selectedId])
            )
          ) : (
            <div className={cs(styles.placeholder, placeholderClassName)}>
              {typeof withNothing === 'string' ? withNothing : placeholder}
            </div>
          )}
          {counter && (
            <div className={styles.counter}>
              <span>{counter.length}</span>
            </div>
          )}
        </div>
        <div className={styles.selectIconsBlock}>
          {IconCross && selectedId !== -1 && (
            <span
              onClick={e => {
                e.stopPropagation();
                setIsOpen(false);
                onClear();
              }}
            >
              <IconCross />
            </span>
          )}
          {IconArrow ? <IconArrow /> : <ArrowDownSVG />}
        </div>
      </div>
      {!disabled && !!options.length && (
        <div className={styles.optionContainer} ref={dropdownRef}>
          <ul
            className={cs(styles.options, { [styles.additionalPlus]: plusAction }, { [styles.icon]: icon }, dropdownClassName || null)}
            style={isOver ? { bottom: '100%' } : null}
          >
            {!!withNothing && <li onClick={() => onClose(-1)}>{typeof withNothing === 'string' ? withNothing : 'None'}</li>}
            {options.map((option, index) => {
              if (needRadio)
                return (
                  <li key={index} onClick={() => onClose(index)} className={styles.needRadio}>
                    <RadioButton className={styles.radioBtn} checked={selectedId === index} onChange={() => {}} />
                    {renderOption ? renderOption(option) : optionKey(option)}
                  </li>
                );

              return (
                <li
                  key={index}
                  onClick={() => onClose(index)}
                  className={cs(
                    index === selectedId && styles.selected,
                    index === selectedId && isHeaderSelect && styles.selectedHeader,
                    isDisabledOption && isDisabledOption(option) && styles.disabled
                  )}
                >
                  {renderOption ? renderOption(option) : optionKey(option)}
                  {index === selectedId && (
                    <span className={styles.checkedIconSvg}>
                      <CheckedSVG />
                    </span>
                  )}
                  {isLocation && <InfoBlackSVG />}
                </li>
              );
            })}
          </ul>
          {plusAction && (
            <div className={styles.additional} onClick={plusAction}>
              {' '}
              <PlusSVG /> {plusActionText}{' '}
            </div>
          )}
        </div>
      )}
      {errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>}
    </div>
  );
};

SelectDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  selectedId: PropTypes.number,
  onSelected: PropTypes.func.isRequired,
  optionKey: PropTypes.func.isRequired,
  onToggleDropdown: PropTypes.func,
  isDisabledOption: PropTypes.func,
  renderOption: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  isOver: PropTypes.bool,
  isLocation: PropTypes.bool,
  icon: PropTypes.bool,
  disabled: PropTypes.bool,
  withNothing: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),

  className: PropTypes.string,
  disabledClassName: PropTypes.string,
  inputClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholderClassName: PropTypes.string,
  errorInputClassName: PropTypes.string,
};

export default SelectDropdown;
