import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/${process.env.REACT_APP_API_PATH}`,
  headers: { 'Content-Type': 'application/json' },
});
instance.interceptors.request.use(
  async config => {
    const access_token = await getAccessToken();
    config.headers = {
      ...config.headers,
      'x-token': access_token,
    };
    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);

const instanceWithForm = axios.create({
  baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/${process.env.REACT_APP_API_PATH}`,
  headers: { 'Content-Type': 'multipart/form-data' },
});
instanceWithForm.interceptors.request.use(
  async config => {
    const access_token = await getAccessToken();
    config.headers = {
      ...config.headers,
      'x-token': access_token,
    };
    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);

const getAccessToken = async () => {
  const token = JSON.parse(localStorage.getItem('OTTERFISH_ACCESS_TOKEN'));
  if (!token) return;
  const expires_at = token ? token.expires_at : null;
  let newToken;
  if (Date.parse(expires_at) - 3*60000 < Date.now()) {
    const response = await refreshAccessToken();
    newToken = { ...token, ...response.data[0] };
    localStorage.setItem('OTTERFISH_ACCESS_TOKEN', JSON.stringify(newToken));
  }
  return newToken ? newToken.access_token : token.access_token;
};

let tokenRefreshRequest = null;

const refreshAccessToken = () => {
  if(!tokenRefreshRequest) {
    tokenRefreshRequest = (async ()=> {
      try {
        const refresh_token = JSON.parse(localStorage.getItem('OTTERFISH_ACCESS_TOKEN')).refresh_token;
        const email = localStorage.getItem('OTTERFISH_USER_EMAIL');
        const url = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/${process.env.REACT_APP_API_PATH}`;
        const response = await axios.post(url + '/auth/refresh-token', { refresh_token, email });
        tokenRefreshRequest=null;
        return response.data;
      } catch (error) {
        tokenRefreshRequest=null;
        if (error.response.status === 401) {
          localStorage.removeItem('OTTERFISH_ACCESS_TOKEN');
          localStorage.removeItem('OTTERFISH_USER_EMAIL');
          document.location.reload();
        }
      }
    })();
  }
  return tokenRefreshRequest;
};

export { instance, instanceWithForm };
