import React from 'react';
import styles from './Tag.module.scss';
import cs from 'classnames';

const Tag = ({ tag, onSelect, selected }) => {

  return (
    <div className={cs(styles.tag, {[styles.selected]: selected })} onClick={() => onSelect(tag.id)}>
      {tag.name}
    </div>
  )
}

export default Tag;
