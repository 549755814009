import { AUTH_TYPES } from '../actions/auth.actions';
import { USER_TYPES } from '../actions/user.actions';

const stringToken = localStorage.getItem('OTTERFISH_ACCESS_TOKEN');
const preselectedPlan = localStorage.getItem('OTTERFISH_PRESELECTED_PLAN') || null;

const token = stringToken && stringToken !== 'undefined' ? JSON.parse(stringToken) : null;

const initialState = {
  token,
  user: {},
  signUp: {
    loading: false,
  },
  signIn: {
    loading: false,
  },
  userFields: {},
  failure: {},
  organizations: null,
  currentOrganization: null,
  sendUserData: {},
  emailConf: {
    loading: false,
  },
  letterConf: {
    loading: false,
  },
  isNewMember: false,
  isNewUser: false,
  forgotPasswordLoading: false,
  preselectPlan: preselectedPlan,
};

export default (state = initialState, { type, payload, isNewMember = true }) => {
  switch (type) {
    case AUTH_TYPES.SIGN_UP.REQUEST: {
      return {
        ...state,
        sendUserData: payload,
        signUp: { loading: true },
      };
    }
    case AUTH_TYPES.SIGN_UP.SUCCESS: {
      return {
        ...state,
        token: payload,
        failure: {},
        isNewUser: true,
        signUp: { loading: false },
      };
    }
    case AUTH_TYPES.SIGN_UP.FAILURE: {
      return {
        ...state,
        failure: payload,
        signUp: { loading: false },
      };
    }
    case AUTH_TYPES.CHECK_EMAIL.REQUEST: {
      return {
        ...state,
        signUp: { loading: true },
      };
    }
    case AUTH_TYPES.CHECK_EMAIL.SUCCESS: {
      return {
        ...state,
        signUp: { loading: false },
      };
    }
    case USER_TYPES.INVITE_CONFIRM.REQUEST: {
      return {
        ...state,
        signUp: {
          loading: true,
        },
      };
    }
    case USER_TYPES.INVITE_CONFIRM.FAILURE: {
      return {
        ...state,
        failure: payload,
        signUp: {
          loading: false,
        },
      };
    }
    case USER_TYPES.INVITE_CONFIRM.SUCCESS: {
      return {
        ...state,
        token: payload,
        isNewMember: isNewMember,
        signUp: {
          loading: false,
        },
      };
    }
    case USER_TYPES.DELETE_MARK_AS_NEW.SUCCESS: {
      return {
        ...state,
        isNewMember: false,
      };
    }
    case AUTH_TYPES.FORGOT_PASSWORD.REQUEST: {
      return {
        ...state,
        forgotPasswordLoading: true,
      };
    }
    case AUTH_TYPES.FORGOT_PASSWORD.SUCCESS: {
      return {
        ...state,
        forgotPasswordLoading: false,
      };
    }
    case AUTH_TYPES.FORGOT_PASSWORD.FAILURE: {
      return {
        ...state,
        failure: payload,
        forgotPasswordLoading: false,
      };
    }
    case AUTH_TYPES.SIGN_IN.REQUEST: {
      return {
        ...state,
        sendUserData: payload,
        signIn: {
          loading: true,
        }
      };
    }
    case AUTH_TYPES.SIGN_IN.SUCCESS: {
      return {
        ...state,
        token: payload,
        signIn: {
          loading: false,
        }
      };
    }
    case AUTH_TYPES.SIGN_IN.FAILURE: {
      return {
        ...state,
        failure: payload,
        signIn: {
          loading: false,
        }
      };
    }
    case AUTH_TYPES.GET_ME.SET_VALUE: {
      return {
        ...state,
        ...payload,
      };
    }
    case AUTH_TYPES.SIGN_UP_EDIT: {
      return {
        ...state,
        userFields: payload,
      };
    }
    case AUTH_TYPES.SIGN_UP.CLEANER: {
      return {
        ...state,
        userFields: {},
      };
    }
    case AUTH_TYPES.SIGN_OUT.SUCCESS: {
      return {
        ...initialState,
        token: null,
      };
    }
    case AUTH_TYPES.CLEAR_FAILURE: {
      return {
        ...state,
        failure: {},
      };
    }
    case AUTH_TYPES.EMAIL_CONFIRMATION.REQUEST: {
      return {
        ...state,
        emailConf: { loading: true },
      };
    }
    case AUTH_TYPES.EMAIL_CONFIRMATION.SUCCESS: {
      return {
        ...state,
        emailConf: { loading: false },
      };
    }

    case AUTH_TYPES.EMAIL_CONFIRMATION.FAILURE: {
      return {
        ...state,
        emailConf: { loading: false },
        failure: payload,
      };
    }
    case AUTH_TYPES.LETTER_LINK.REQUEST: {
      return {
        ...state,
        letterConf: {
          loading: true,
        },
      };
    }
    case AUTH_TYPES.LETTER_LINK.SUCCESS: {
      return {
        ...state,
        letterConf: {
          loading: false,
        },
      };
    }
    case AUTH_TYPES.LETTER_LINK.FAILURE: {
      return {
        ...state,
        letterConf: {
          loading: false,
        },
        failure: payload,
      };
    }
    case 'SET_CURRENT_ORGANIZATION': {
      return {
        ...state,
        currentOrganization: payload,
      };
    }
    case 'SET_PRESELECT_PLAN': {
      return {
        ...state,
        preselectPlan: payload,
      };
    }
    case AUTH_TYPES.DELETE_MARK_AS_NEW_USER: {
      return {
        ...state,
        isNewUser: false,
      };
    }
    default: {
      return state;
    }
  }
};
