import React from 'react';
import styles from './TextArea.module.scss';
import cs from 'classnames';

const TextArea = ({ max = null, isValid = true, className, error, ...rest }) => {
  return (
    <div className={cs(styles.root, { [styles.error]: !isValid || error }, className)}>
      <textarea className={styles.textarea} maxLength={max} {...rest} />
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

export default TextArea;
