import React from 'react';

const SpeakerSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path
        d="M21.6106 7.39764C21.7849 7.87781 22.0046 8.47775 21.7343 8.96334C21.4535 9.46898 20.7901 9.69606 20.345 10.0082C20.3387 10.0126 20.3215 10.0223 20.3147 10.0283C20.0245 8.84393 19.6924 7.67097 19.3184 6.50938C19.7359 6.46928 20.1581 6.40966 20.5803 6.4037C20.7891 6.40045 20.9712 6.39124 21.1289 6.54353C21.3674 6.77386 21.4978 7.0871 21.6106 7.39764Z"
        fill="#46CFB0"
      />
      <path
        d="M20.1934 9.54271C20.2344 9.70421 20.275 9.86679 20.315 10.0305C20.3217 10.0245 20.339 10.0147 20.3452 10.0104C20.7904 9.69825 21.4538 9.47117 21.7346 8.96553C21.9637 8.55419 21.8389 8.06101 21.6923 7.62799C21.5754 8.62627 20.8129 9.22025 20.1934 9.54271Z"
        fill="#46CFB0"
      />
      <path
        d="M7.76157 20.9057C7.34404 21.1653 6.93225 21.465 6.49907 21.6932C6.25117 21.8238 5.91715 21.9571 5.6708 21.7474C5.5325 21.6303 5.43021 21.4477 5.33 21.2976C4.83888 20.5594 4.49234 19.7508 4.11343 18.9449C3.66511 17.9922 3.32535 17.0118 3.02734 15.9994C4.06211 15.8119 5.09027 15.5886 6.11182 15.3296C6.50481 16.3907 6.94687 17.421 7.56272 18.3645C7.70781 18.5867 7.8576 18.8062 8.01469 19.0192C8.19371 19.2609 8.47084 19.4842 8.57418 19.7779C8.77146 20.3323 8.13473 20.6749 7.76157 20.9057Z"
        fill="#46CFB0"
      />
      <path
        d="M19.9963 16.8201C19.9191 16.799 19.8439 16.7773 19.7714 16.7578C18.9254 16.5345 18.0846 16.2896 17.2453 16.0386C15.8612 15.6251 14.4792 15.1927 13.0544 14.9428C11.4887 14.6686 9.90732 14.6415 8.33012 14.7905C7.93921 14.8274 7.54726 14.8756 7.16052 14.9298C7.01578 14.424 6.87243 13.9182 6.73047 13.4123C10.7836 12.7078 15.4896 12.4309 19.4796 13.6356C19.5209 13.8329 19.561 14.0313 19.6002 14.2318C19.6132 14.2979 19.6258 14.364 19.6388 14.4301C19.6767 14.6285 19.7133 14.8272 19.7484 15.0263C19.7651 15.1224 19.7815 15.2187 19.7975 15.3151C19.8032 15.3515 19.8095 15.3878 19.8152 15.4235C19.8241 15.4777 19.8324 15.5319 19.8413 15.5861C19.8761 15.8145 19.9078 16.0428 19.9363 16.2711C19.9426 16.3205 19.9483 16.3703 19.954 16.4196C19.9608 16.4787 19.9676 16.5372 19.9739 16.5963C19.9817 16.6733 19.9895 16.7475 19.9963 16.8201Z"
        fill="#46CFB0"
      />
      <path
        d="M21.1471 16.1594C21.1074 16.4212 21.0072 16.7014 20.7635 16.8228C20.5677 16.9214 20.3496 16.9041 20.1372 16.8575C20.1309 16.7908 20.1246 16.7241 20.1173 16.6575C20.1128 16.6098 20.1076 16.5621 20.1017 16.5144C20.0989 16.4873 20.0958 16.4602 20.0923 16.4331C20.0881 16.3935 20.0834 16.3545 20.0782 16.3155C20.0714 16.2564 20.0636 16.1974 20.0558 16.1383C20.0479 16.0792 20.0406 16.0212 20.0323 15.9627C20.0166 15.8543 20.0004 15.7443 19.9837 15.6326L19.9576 15.4652C19.9467 15.3991 19.9362 15.3329 19.9248 15.2668C19.9133 15.2007 19.9028 15.1346 19.8908 15.0685C19.8814 15.0143 19.872 14.9601 19.8621 14.9059C19.8574 14.8815 19.8533 14.8566 19.8486 14.8316C19.8392 14.7774 19.8292 14.726 19.8193 14.6729C19.8 14.5704 19.7807 14.4675 19.7609 14.365C19.741 14.2626 19.7238 14.1753 19.705 14.0805C19.6789 13.9488 19.6528 13.8176 19.6252 13.6865C19.5845 13.4834 19.5439 13.2815 19.5036 13.0806C19.2165 11.7024 18.9018 10.3286 18.5427 8.96826C18.3402 8.2014 18.0861 7.4508 17.834 6.69911C17.4488 5.5518 17.0475 4.40991 16.62 3.27885C16.2547 2.31255 15.9076 1.27363 15.3633 0.39567C15.5809 0.257472 15.8173 0.150165 16.0621 0.130655C16.5496 0.0921766 16.7761 0.557713 16.9781 0.936537C17.1994 1.35167 17.3956 1.78307 17.5814 2.21663C18.8147 5.07813 19.7483 8.11469 20.4357 11.1648C20.6246 12.0027 20.7911 12.8448 20.9253 13.6957C20.9383 13.7786 20.9513 13.8621 20.9633 13.945C20.9686 13.9781 20.9733 14.0117 20.978 14.0447C20.9894 14.1217 20.9999 14.1981 21.0103 14.2751C21.0161 14.3152 21.0213 14.3547 21.0265 14.3948C21.0359 14.4653 21.0448 14.5357 21.0542 14.6062C21.0719 14.7471 21.0897 14.888 21.1064 15.0289C21.1486 15.4045 21.2045 15.7833 21.1471 16.1594Z"
        fill="#46CFB0"
      />
      <path
        d="M6.80295 14.1814C6.73336 13.9339 6.66377 13.6865 6.59419 13.439C4.53944 13.8015 2.659 14.273 1.13086 14.7066C1.24933 14.9131 1.37824 15.1326 1.52229 15.3396C1.55256 15.3835 1.58335 15.4263 1.61467 15.4691C1.7214 15.6144 1.84072 15.7492 1.97113 15.8718C2.20964 16.0918 2.64544 15.9227 2.92727 15.8718C3.19205 15.8241 3.45631 15.7742 3.72004 15.7222C4.79865 15.5094 5.86961 15.2565 6.93291 14.9635C6.93806 14.9612 6.94352 14.9598 6.94908 14.9591C6.95498 14.9584 6.96094 14.9584 6.96683 14.9591L7.02215 14.9516C6.94839 14.6947 6.87532 14.438 6.80295 14.1814Z"
        fill="#46CFB0"
      />
      <path
        d="M6.59275 13.4389C4.53853 13.8014 2.65809 14.2729 1.12995 14.7065L1.12629 14.7C0.620424 13.8173 0.30026 12.8334 0.186862 11.813C0.134671 11.3502 0.0240268 10.6565 0.360657 10.2847C0.687893 9.9227 1.30583 9.84682 1.7458 9.72217C2.94932 9.38183 4.15753 9.06153 5.37045 8.76129C5.74204 10.3308 6.15853 11.8884 6.59275 13.4389Z"
        fill="#46CFB0"
      />
      <path
        d="M21.1469 16.1594C21.1072 16.4212 21.007 16.7014 20.7633 16.8228C20.5676 16.9214 20.3494 16.9041 20.137 16.8575C20.1307 16.7908 20.1245 16.7242 20.1172 16.6575C20.1126 16.6098 20.1074 16.5621 20.1015 16.5144C20.0987 16.4873 20.0956 16.4602 20.0921 16.4331C20.0879 16.3941 20.0832 16.3546 20.078 16.3155C20.0712 16.2565 20.0634 16.1974 20.0556 16.1383C20.0477 16.0792 20.0404 16.0213 20.0321 15.9627C20.0164 15.8543 20.0003 15.7443 19.9836 15.6327L19.9575 15.4652C19.9465 15.3991 19.9361 15.333 19.9246 15.2669C19.9131 15.2007 19.9021 15.1346 19.8906 15.0685C19.8813 15.0143 19.8719 14.9601 19.8619 14.9059C19.8572 14.8815 19.8531 14.8566 19.8484 14.8317C19.839 14.7775 19.8291 14.726 19.8191 14.6729C19.7998 14.5704 19.7805 14.4675 19.7607 14.365C19.7409 14.2626 19.7236 14.1754 19.7049 14.0805C19.6788 13.9488 19.6527 13.8177 19.625 13.6865C20.0926 13.831 20.5491 13.9985 20.9945 14.1889C21.0044 14.2594 21.0138 14.3298 21.0237 14.3997C21.0336 14.4696 21.042 14.5406 21.0514 14.6111C21.0691 14.752 21.0869 14.8929 21.1036 15.0338C21.1484 15.4045 21.2043 15.7833 21.1469 16.1594Z"
        fill="#46CFB0"
      />
      <path
        d="M19.9963 16.8201C19.9191 16.799 19.8439 16.7773 19.7714 16.7578C18.9254 16.5345 18.0846 16.2896 17.2453 16.0386C15.8612 15.6251 14.4792 15.1927 13.0544 14.9428C11.4887 14.6686 9.90732 14.6415 8.33012 14.7905C7.93921 14.8274 7.54726 14.8756 7.16052 14.9298C7.01578 14.424 6.87243 13.9182 6.73047 13.4123C10.7836 12.7078 15.4896 12.4309 19.4796 13.6356C19.5209 13.8329 19.561 14.0313 19.6002 14.2318C19.6132 14.2979 19.6258 14.364 19.6388 14.4301C19.6767 14.6285 19.7133 14.8272 19.7484 15.0263C19.7651 15.1224 19.7815 15.2187 19.7975 15.3151C19.8032 15.3515 19.8095 15.3878 19.8152 15.4235C19.8241 15.4777 19.8324 15.5319 19.8413 15.5861C19.8761 15.8145 19.9078 16.0428 19.9363 16.2711C19.9426 16.3205 19.9483 16.3703 19.954 16.4196C19.9608 16.4787 19.9676 16.5372 19.9739 16.5963C19.9817 16.6733 19.9895 16.7475 19.9963 16.8201Z"
        fill="#46CFB0"
      />
      <path
        d="M7.02215 14.9516L6.96683 14.9592C6.96094 14.9584 6.95498 14.9584 6.94908 14.9592C6.94352 14.9598 6.93806 14.9613 6.93291 14.9635C5.87065 15.2526 4.7997 15.5055 3.72004 15.7223C3.45596 15.7743 3.1917 15.8241 2.92727 15.8718C2.64544 15.9222 2.20964 16.0913 1.97113 15.8718C1.84072 15.7492 1.7214 15.6144 1.61467 15.4692C1.58335 15.4264 1.55256 15.3835 1.52229 15.3396C1.37824 15.1326 1.24933 14.9131 1.13086 14.7066C2.659 14.2698 4.53944 13.801 6.59367 13.4385C6.66325 13.6863 6.73284 13.9338 6.80243 14.1809C6.8755 14.4382 6.94874 14.6951 7.02215 14.9516Z"
        fill="#46CFB0"
      />
      <path
        d="M4.23034 17.2822C4.19798 16.5203 5.45578 16.0412 6.29344 15.807C6.23185 15.6488 6.17131 15.4896 6.11182 15.3296C5.09097 15.5879 4.06281 15.8112 3.02734 15.9994C3.32535 17.0118 3.66511 17.9922 4.11343 18.945C4.49234 19.7508 4.83732 20.56 5.33209 21.2986C5.43229 21.4488 5.53459 21.6314 5.67289 21.7485C5.90097 21.9414 6.20419 21.8417 6.44479 21.7219C5.70942 20.5443 4.26479 18.1315 4.23034 17.2822Z"
        fill="#46CFB0"
      />
      <path
        d="M6.73027 13.4144C6.87223 13.9203 7.01559 14.4261 7.16033 14.9319L7.02098 14.9514C6.94721 14.6945 6.87415 14.4378 6.80178 14.1813C6.73219 13.9338 6.6626 13.6863 6.59301 13.4388C6.15879 11.8872 5.7423 10.3307 5.36914 8.76179L5.50118 8.71518C8.39151 7.37981 10.7954 5.06513 12.9806 2.7407C13.3742 2.32177 13.7635 1.89742 14.1544 1.47795C14.3949 1.21167 14.651 0.960997 14.9211 0.727349C15.0213 0.642805 15.1299 0.556093 15.2447 0.475342C15.7144 1.23407 16.0244 2.12233 16.3454 2.9564C16.7812 4.0842 17.1856 5.22772 17.5734 6.37449C17.8344 7.13647 18.0985 7.90063 18.3161 8.6767C18.6658 9.9259 18.9528 11.1973 19.2274 12.4666C19.3114 12.8552 19.3959 13.2459 19.4773 13.6377C15.4894 12.433 10.7834 12.7115 6.73027 13.4144Z"
        fill="#46CFB0"
      />
    </svg>
  );
};

export default SpeakerSVG;
