import React from 'react';
import styles from './OrganizationTypeDropdown.module.scss';
import SelectDropdown from '../../../../../expert/components/FormControls/SelectDropdown';
import { ReactComponent as IconArrow } from '../../../../../assets/img/icons/play-white-arrow.svg';
import cs from 'classnames';
import { TYPES_CAMPAIGNS, TYPES_CAMPAIGNS_OBJECTS } from '../../../../../shared/constants/constants';

const allowed = [TYPES_CAMPAIGNS.BRAND, TYPES_CAMPAIGNS.AGENCY, TYPES_CAMPAIGNS.TALENT_MANAGER, TYPES_CAMPAIGNS.CONTENT_CREATOR];
const ORGANIZATION_TYPES = TYPES_CAMPAIGNS_OBJECTS.filter(item => allowed.includes(item.type)).map((item, idx) => ({
  ...item,
  id: idx + 1,
}));

const OrganizationTypeDropdown = ({ formik, fieldName, error }) => {
  return (
    <>
      <SelectDropdown
        errorMessage={error || ''}
        errorInputClassName={styles.error}
        className={styles.root}
        inputClassName={cs(styles.input, { [styles.filled]: !!formik.values[fieldName] })}
        activeInputClassName={styles.inputActive}
        dropdownClassName={styles.dropdown}
        placeholder={'Type of organization'}
        options={ORGANIZATION_TYPES.map((call, idx) => ({ id: idx, name: call.name })) || []}
        selectedId={ORGANIZATION_TYPES.findIndex(cta => cta.type === formik.values[fieldName])}
        onSelected={index => {
          if (index === -1) {
            formik.setFieldValue(fieldName, '');
          } else {
            formik.setFieldValue(fieldName, ORGANIZATION_TYPES[index].type);
          }
        }}
        optionKey={option => option.id}
        renderOption={option => option.name}
        IconArrow={ArrowIconComponent}
        onClickSelect={() => formik.setFieldTouched(fieldName, true)}
      />
    </>
  );
};

const ArrowIconComponent = () => {
  return (
    <div className={styles.arrowIcon}>
      <IconArrow />
    </div>
  );
};

export default OrganizationTypeDropdown;
