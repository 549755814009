import { INVOICE_TYPES } from '../actions/invoices.actions';

const initialState = {
  data: [],
  failure: {
    message: '',
    code: '',
    name: '',
  },
  loading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case INVOICE_TYPES.CREATE_INVOICE.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case INVOICE_TYPES.CREATE_INVOICE.SUCCESS: {
      return {
        ...state,
        data: [...state.data, payload],
        loading: false,
      };
    }
    case INVOICE_TYPES.CREATE_INVOICE.FAILURE: {
      return {
        ...state,
        failure: payload,
        loading: false,
      };
    }

    case INVOICE_TYPES.EDIT_INVOICE.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case INVOICE_TYPES.EDIT_INVOICE.SUCCESS: {
      const editIdx = state.data.findIndex(i => i.id === payload.id);
      const newInvoices = [...state.data];
      newInvoices.splice(editIdx, 1, payload);
      return {
        ...state,
        data: newInvoices,
        loading: false,
      };
    }
    case INVOICE_TYPES.EDIT_INVOICE.FAILURE: {
      return {
        ...state,
        failure: payload,
        loading: false,
      };
    }

    case INVOICE_TYPES.GET_INVOICES.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case INVOICE_TYPES.GET_INVOICES.SUCCESS: {
      return {
        ...state,
        data: payload,
        loading: false,
      };
    }
    case INVOICE_TYPES.GET_INVOICES.FAILURE: {
      return {
        ...state,
        failure: payload,
        loading: false,
      };
    }

    case INVOICE_TYPES.GET_INVOICE.REQUEST: {
      return {
        ...state,
      };
    }
    case INVOICE_TYPES.GET_INVOICE.SUCCESS: {
      return { ...state };
    }
    case INVOICE_TYPES.GET_INVOICE.FAILURE: {
      return {
        ...state,
        failure: payload,
      };
    }

    case INVOICE_TYPES.DELETE_INVOICE.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case INVOICE_TYPES.DELETE_INVOICE.SUCCESS: {
      return {
        ...state,
        data: state.data.filter(i => i.id !== payload),
        loading: false,
      };
    }
    case INVOICE_TYPES.DELETE_INVOICE.FAILURE: {
      return {
        ...state,
        failure: payload,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};
