import React from 'react';
import styles from './DoesNotExistInviteModal.module.scss';
import { Modal } from '..';
import Button from '../../../expert/components/Buttons/ButtonCustom';
import inviteDoesNotExistUrl from '../../../assets/img/invite-does-not-exist.png';

const DoesNotExistInviteModal = ({ onClose }) => {
  return (
    <div className={styles.root}>
      <img className={styles.modalImg} src={inviteDoesNotExistUrl} alt="Invite does not exist image" />
      <h2 className={styles.title}>Invite does not exist</h2>
      {/* <p className={styles.subtitle}>Please contact the below person to get a new invite</p>*/}
      <div className={styles.navigationBtns}>
        <Button className={styles.btn} outline onClick={onClose}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default props => {
  return (
    <Modal {...props} maxWidth={530}>
      <DoesNotExistInviteModal {...props} />
    </Modal>
  );
};
