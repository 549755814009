import React from 'react';
import styles from './NoSearchResults.module.scss';
import NoSearchResultsImg from '../../../../assets/img/icons/otterfish_logo_no_Results.svg';
import NoResultsFound from '../../../../assets/img/icons/text_results_found.svg';
import ButtonCustom from '../../../../expert/components/Buttons/ButtonCustom';
import { ReactComponent as Plus } from '../../../../assets/img/icons/plus.svg';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const NoSearchResults = ({ buttonText, route, trackAction = null }) => {
  const history = useHistory();
  const userId = useSelector(state => state.auth.user.id);

  return (
    <div className={styles.root}>
      <div className={styles.noSearchResultsContainer}>
        <div className={styles.contentContainer}>
          <img src={NoSearchResultsImg} alt="no search results image" />
          <div className={styles.content}>
            <div className={styles.imageBlock}>
              <img src={NoResultsFound} alt="text result found" />
            </div>
            <span className={styles.text}>
              We couldn’t find what you searched for.
              <br /> Try searching again.
            </span>
            <div className={styles.btnBlock}>
              {trackAction ? (
                <ButtonCustom
                  Icon={Plus}
                  outline
                  className={styles.buttonCreate}
                  onClick={() => {
                    trackAction(userId);
                    history.push(route);
                  }}
                >
                  {buttonText}
                </ButtonCustom>
              ) : (
                <ButtonCustom
                  Icon={Plus}
                  outline
                  className={styles.buttonCreate}
                  onClick={() => {
                    history.push(route);
                  }}
                >
                  {buttonText}
                </ButtonCustom>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoSearchResults;
