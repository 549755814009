import React from 'react';
import styles from './TabBrandDetails.module.scss';
import TextField from '../../../../../components/FormControls/TextField';
import ImagePreview from '../../../../CreativeBrain/GenerateCreatives/components/ImagePreview';
import { ReactComponent as PlusIcon } from '../../../../../assets/img/icons/plus.svg';
import CheckboxButton from '../../CheckboxButton';
import brandPreviewImage from '../../../../../assets/img/brandPlaceholder.svg';
import EditorComponent from '../../../../../components/EditorComponent';
import { validateImage } from '../../../../../shared/utils/helpers';

function TabBrandDetails({ formik, logoObject, setLogoObject }) {
  const handleLogoUpload = event => {
    const reader = new FileReader();
    const file = validateImage(event.target.files[0]);
    if (!file) return;
    reader.onloadend = () => {
      setLogoObject({ file: file, fileUrl: reader.result });
    };
    reader.readAsDataURL(file);
  };

  const setBrandProduct = () => {
    formik.setFieldValue('showBrandProvidesProduct', true);
  };

  const removeBrandProduct = () => {
    formik.setFieldValue('showBrandProvidesProduct', false);
  };

  return (
    <>
      <div>
        <h3>What is your campaign name?</h3>
        <TextField
          name={'campaignName'}
          placeholder={'Campaign Name'}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.campaignName}
          error={formik.errors.campaignName}
        />
      </div>
      <div>
        <h3>Brand</h3>
        <div className={styles.logoWrapper}>
          {logoObject.fileUrl ? (
            <div className={styles.imagePreviewWrapper}>
              <ImagePreview src={logoObject.fileUrl} onRemoveClick={() => setLogoObject({ file: null, fileUrl: null })} />
            </div>
          ) : (
            <div className={styles.selectLogoContainer}>
              <div className={styles.selectFileVisibleContent}>
                <PlusIcon />
                <span>{'Replace it'}</span>
              </div>
              <img className={styles.brandPreviewImage} src={brandPreviewImage} alt={'Otterfish'} />
              <input type="file" accept="image/png, image/jpeg, image/jpg" onChange={handleLogoUpload} className={styles.fileInput} />
            </div>
          )}
        </div>
        <TextField name="brandName" placeholder="Brand name" onChange={formik.handleChange} value={formik.values.brandName} />
        <EditorComponent
          wrapperClassName={styles.editorWrapperClassName}
          editorClassName={styles.editorClassName}
          initialValue={formik.values.brandBackground}
          onChange={text => formik.setFieldValue('brandBackground', text)}
          placeholder={
            'Brand background - Set the stage. Give creators some colour around your product or brand and what you want to be known for.'
          }
          error={formik.errors.brandBackground}
        />
      </div>
      <div>
        <h3>Do you want to send your product to Creators</h3>
        <div className={styles.checkboxBlock}>
          <CheckboxButton onClick={setBrandProduct} isActive={formik.values.showBrandProvidesProduct} item={'Yes'} />
          <CheckboxButton onClick={removeBrandProduct} isActive={!formik.values.showBrandProvidesProduct} item={'No'} />
        </div>
        {formik.values.showBrandProvidesProduct && (
          <div style={{ marginTop: 10 }}>
            <EditorComponent
              wrapperClassName={styles.editorWrapperClassName}
              editorClassName={styles.editorClassName}
              initialValue={formik.values.brandProvidesProduct}
              onChange={text => formik.setFieldValue('brandProvidesProduct', text)}
              placeholder={
                'Please describe what you would like to send each creator, how they will receive it, how much it is' +
                ' worth at retail, and if you will need it back.'
              }
              error={formik.errors.brandProvidesProduct}
            />
          </div>
        )}
      </div>
      <div>
        <h3>Brand guidelines and mandatories</h3>
        <EditorComponent
          wrapperClassName={styles.editorWrapperClassName}
          editorClassName={styles.editorClassName}
          initialValue={formik.values.notes}
          onChange={text => formik.setFieldValue('notes', text)}
          onBlur={formik.handleBlur}
          placeholder={"Any do's and dont's to think about that is relevant to your campaign."}
          error={formik.errors.notes}
        />
      </div>
    </>
  );
}

export default TabBrandDetails;
