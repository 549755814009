import React from 'react';

function ErrorSVG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="92" height="129" fill="none" viewBox="0 0 92 129">
      <circle cx="49.666" cy="57.114" r="41.825" fill="#F7AA50"></circle>
      <circle cx="28.755" cy="92.403" r="13.07" fill="#F7AA50"></circle>
      <circle cx="15.684" cy="123.772" r="5.228" fill="#F7AA50"></circle>
      <circle cx="5.228" cy="6.14" r="5.228" fill="#F7AA50"></circle>
      <circle cx="73.193" cy="20.517" r="5.228" fill="#F7AA50"></circle>
      <circle cx="45.746" cy="114.623" r="9.149" fill="#F7AA50"></circle>
      <path
        stroke="#4E3B8B"
        strokeLinecap="round"
        strokeWidth="2"
        d="M73.193 67.263s-12.289-7.535-27.448-7.535c-15.158 0-27.447 7.535-27.447 7.535"
      ></path>
      <circle cx="44.745" cy="52.193" r="40.825" stroke="#4E3B8B" strokeWidth="2"></circle>
    </svg>
  );
}

export default ErrorSVG;
