import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

/**
 *
 * @param {number} idIdentifier
 * @param {{
 *   channel: string;
 *   events: (ctx) => void;
 *   opts: {data: object};
 *   onError: (err) => void;
 *   onSubscribe: (ctx) => void;
 *   onUnsubscribe: (ctx) => void;
 * }} subscribeArguments
 * @param {boolean} stopCondition
 */
const useSubscribeChannel = (idIdentifier, subscribeArguments, stopCondition = false) => {
  const JWT = useSelector(state => state.auth?.token?.access_token);
  const { appCentrifuge, isCentrifugeConnected } = useSelector(state => state.centrifuge);
  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const [currentSubscriptions, setCurrentSubscription] = useState(null);
  const refSubscription = useRef();

  useEffect(() => {
    return () => {
      if (refSubscription.current) {
        refSubscription.current.subscription.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (!isCentrifugeConnected || !currentOrganizationID || !JWT || !idIdentifier || stopCondition) {
      return;
    }

    if (!currentSubscriptions || idIdentifier !== currentSubscriptions.id) {
      if (currentSubscriptions) {
        currentSubscriptions.subscription.unsubscribe();
      }
      const newSubscription = appCentrifuge.centrifuge
        .subscribe(subscribeArguments.channel, subscribeArguments.events, subscribeArguments.opts)
        .on('error', error => subscribeArguments.onError && subscribeArguments.onError(error))
        .on('subscribe', ctx => subscribeArguments.onSubscribe && subscribeArguments.onSubscribe(ctx))
        .on('unsubscribe', ctx => subscribeArguments.onUnsubscribe && subscribeArguments.onUnsubscribe(ctx));

      const newSubscriptionObject = { id: idIdentifier, subscription: newSubscription };
      refSubscription.current = newSubscriptionObject;
      setCurrentSubscription(newSubscriptionObject);
    }
    // console.groupEnd();
  }, [isCentrifugeConnected, idIdentifier, JWT, currentOrganizationID]);
};

export default useSubscribeChannel;
