import React from 'react'
import styles from './UserLeaveFlow.module.scss';
import { ReactComponent as UserLeaveFlow } from '../../../assets/img/icons/user_leave_flow.svg';
import Button from '../../../expert/components/Buttons/ButtonCustom';
import { Modal } from '../index';
import { useMediaQuery } from 'react-responsive/src';

const UserLeaveFlowModal = ({ onSubmit, text }) => {
  return (
    <div className={styles.root}>
      <div className={styles.svgContainer}><UserLeaveFlow/></div>
      <div className={styles.text}>
        <span>{text}</span>
        <span>Would you like to save your progress?</span>
      </div>
      <div className={styles.buttonContainer}>
        <Button outline className={styles.btn} onClick={() => onSubmit(true)}>
          Leave without saving
        </Button>
        <Button className={styles.btn} onClick={() => onSubmit(false)}>
          Save and leave
        </Button>
      </div>
    </div>
  );
};

export default props => {
  const isMobile = useMediaQuery({ query: '(max-width: 575px)' });
  return (
    <Modal {...props} maxWidth={isMobile ? 320 : 488} >
      <UserLeaveFlowModal {...props} />
    </Modal>
  );
};
