import { CONTENT_APPROVAL } from '../actions/contentApproval.actions';

const initialState = {
  boards: [],
  archivedCount: 0,
  boardsTotalCount: 0,
  contentApprovalTabSwitcher: 'boards',
  openedBoard: null,
  openedDeliverable: null,
  collaborators: [],
  invitedCollaborators: [],
  deliverables: [],
  deliverablesTotalCount: 0,
  deliverablesApprovedCount: 0,
  deliverableApprovals: null,
  boardsLoading: true,
  boardLoading: true,
  collaboratorsLoading: true,
  deliverablesLoading: true,
  deliverableLoading: true,
  deliverableItemUpdateLoading: false,
  approveLoading: false,
  createBoardLoading: false,
  deliverableApprovalsLoading: false,
  boardError: null,
  deliverableItemError: null,
  deliverableItemUpdateError: null,
  approveError: null,
};

export default (state = initialState, { type, payload, totalCount, loading, approvedCount, archivedTotalCount }) => {
  switch (type) {
    case CONTENT_APPROVAL.SET_CONTENT_APPROVAL_TAB_SWITCHER: {
      return {
        ...state,
        contentApprovalTabSwitcher: payload,
      };
    }

    case CONTENT_APPROVAL.GET_CONTENT_BOARDS.REQUEST:
      return {
        ...state,
        boardsLoading: loading !== false,
      };
    case CONTENT_APPROVAL.GET_CONTENT_BOARDS.SUCCESS:
      return {
        ...state,
        boards: payload,
        boardsLoading: false,
        boardsTotalCount: totalCount,
        archivedCount: archivedTotalCount,
      };
    case CONTENT_APPROVAL.GET_CONTENT_BOARDS.FAILURE:
      return {
        ...state,
        boardsLoading: false,
      };

    case CONTENT_APPROVAL.GET_CONTENT_BOARD.REQUEST:
      return {
        ...state,
        boardLoading: true,
      };
    case CONTENT_APPROVAL.GET_CONTENT_BOARD.SUCCESS:
      return {
        ...state,
        openedBoard: payload,
        boardLoading: false,
      };
    case CONTENT_APPROVAL.GET_CONTENT_BOARD.FAILURE:
      return {
        ...state,
        boardLoading: false,
        boardError: payload,
      };

    case CONTENT_APPROVAL.UPDATE_OPENED_CONTENT_BOARD:
      return {
        ...state,
        openedBoard: payload,
      };

    case CONTENT_APPROVAL.UPDATE_BOARD_DELIVERABLE_ITEM:
      return {
        ...state,
        deliverables: payload,
      };

    case CONTENT_APPROVAL.UPDATE_OPENED_DELIVERABLE_ITEM:
      return {
        ...state,
        openedDeliverable: payload,
      };

    case CONTENT_APPROVAL.UPDATE_CONTENT_APPROVAL_LIST_BOARD:
      return {
        ...state,
        boards: payload,
      };

    case CONTENT_APPROVAL.GET_BOARD_COLLABORATORS.REQUEST:
      return {
        ...state,
        collaboratorsLoading: true,
      };
    case CONTENT_APPROVAL.GET_BOARD_COLLABORATORS.SUCCESS:
      return {
        ...state,
        collaborators: payload,
        collaboratorsLoading: false,
      };
    case CONTENT_APPROVAL.GET_BOARD_COLLABORATORS.FAILURE:
      return {
        ...state,
        collaboratorsLoading: false,
      };

    case CONTENT_APPROVAL.GET_BOARD_INVITED_COLLABORATORS.REQUEST:
      return {
        ...state,
        collaboratorsLoading: true,
      };
    case CONTENT_APPROVAL.GET_BOARD_INVITED_COLLABORATORS.SUCCESS:
      return {
        ...state,
        invitedCollaborators: payload,
        collaboratorsLoading: false,
      };
    case CONTENT_APPROVAL.GET_BOARD_INVITED_COLLABORATORS.FAILURE:
      return {
        ...state,
        collaboratorsLoading: false,
      };

    case CONTENT_APPROVAL.GET_BOARD_DELIVERABLES.REQUEST:
      return {
        ...state,
        deliverablesLoading: true,
      };
    case CONTENT_APPROVAL.GET_BOARD_DELIVERABLES.SUCCESS:
      return {
        ...state,
        deliverables: payload,
        deliverablesLoading: false,
        deliverablesTotalCount: totalCount,
        deliverablesApprovedCount: approvedCount,
      };
    case CONTENT_APPROVAL.GET_BOARD_DELIVERABLES.FAILURE:
      return {
        ...state,
        deliverablesLoading: false,
      };

    case CONTENT_APPROVAL.GET_BOARD_DELIVERABLE_ITEM.REQUEST:
      return {
        ...state,
        deliverableLoading: true,
      };
    case CONTENT_APPROVAL.GET_BOARD_DELIVERABLE_ITEM.SUCCESS:
      return {
        ...state,
        openedDeliverable: payload,
        deliverableLoading: false,
      };
    case CONTENT_APPROVAL.GET_BOARD_DELIVERABLE_ITEM.FAILURE:
      return {
        ...state,
        deliverableLoading: false,
        deliverableItemError: payload,
      };

    case CONTENT_APPROVAL.CLEAR_OPENED_DELIVERABLE_ITEM:
      return {
        ...state,
        openedDeliverable: null,
        deliverableApprovals: null,
      };

    case CONTENT_APPROVAL.CLEAR_OPENED_CONTENT_BOARD:
      return {
        ...state,
        openedBoard: null,
        deliverables: [],
        organizations: [],
        deliverablesTotalCount: 0,
        deliverablesApprovedCount: 0,
      };

    case CONTENT_APPROVAL.UPDATE_DELIVERABLE_ITEM.REQUEST:
      return {
        ...state,
        deliverableItemUpdateLoading: true,
      };

    case CONTENT_APPROVAL.UPDATE_DELIVERABLE_ITEM.SUCCESS:
      return {
        ...state,
        deliverableItemUpdateLoading: false,
      };

    case CONTENT_APPROVAL.UPDATE_DELIVERABLE_ITEM.FAILURE:
      return {
        ...state,
        deliverableItemUpdateLoading: loading,
        deliverableItemUpdateError: payload,
      };

    case CONTENT_APPROVAL.APPROVE_DELIVERABLE.REQUEST:
      return {
        ...state,
        approveLoading: true,
      };

    case CONTENT_APPROVAL.APPROVE_DELIVERABLE.SUCCESS:
      return {
        ...state,
        approveLoading: false,
      };

    case CONTENT_APPROVAL.APPROVE_DELIVERABLE.FAILURE:
      return {
        ...state,
        approveLoading: false,
        approveError: payload,
      };

    case CONTENT_APPROVAL.GET_DELIVERABLE_APPROVALS.REQUEST:
      return {
        ...state,
        deliverableApprovalsLoading: true,
      };

    case CONTENT_APPROVAL.GET_DELIVERABLE_APPROVALS.SUCCESS:
      return {
        ...state,
        deliverableApprovals: payload,
        deliverableApprovalsLoading: false,
      };

    case CONTENT_APPROVAL.GET_DELIVERABLE_APPROVALS.FAILURE:
      return {
        ...state,
        deliverableApprovalsLoading: false,
      };

    case CONTENT_APPROVAL.CREATE_BOARD.REQUEST:
      return {
        ...state,
        createBoardLoading: true,
      };

    case CONTENT_APPROVAL.CREATE_BOARD.SUCCESS:
      return {
        ...state,
        createBoardLoading: false,
      };

    case CONTENT_APPROVAL.CREATE_BOARD.FAILURE:
      return {
        ...state,
        createBoardLoading: false,
      };

    case CONTENT_APPROVAL.CLEAR_CONTENT_APPROVAL_ERRORS:
      return {
        ...state,
        approveError: null,
        deliverableItemUpdateError: null,
        boardError: null,
        deliverableItemError: null,
      };
    default:
      return state;
  }
};
