import React from 'react';

const CustomSVG = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.1259 5.53691L12.1377 5.32209C12.0518 5.059 11.9454 4.80239 11.8197 4.5552L12.3668 3.70425C12.4645 3.55221 12.443 3.35259 12.3153 3.22488L10.7758 1.68542C10.6481 1.5577 10.4485 1.53622 10.2964 1.63392L9.4455 2.18098C9.1983 2.05532 8.94169 1.94889 8.67861 1.86296L8.46379 0.874782C8.42543 0.698216 8.26908 0.572266 8.08839 0.572266H5.91133C5.73065 0.572266 5.57429 0.698216 5.53593 0.874782L5.32111 1.86296C5.05803 1.94889 4.80142 2.05532 4.55423 2.18098L3.70328 1.63392C3.55123 1.53622 3.35161 1.5577 3.2239 1.68542L1.68444 3.22488C1.55672 3.35259 1.53524 3.55221 1.63294 3.70425L2.18 4.5552C2.05435 4.80239 1.94792 5.059 1.86199 5.32209L0.873805 5.53691C0.697239 5.57536 0.571289 5.73162 0.571289 5.91231V8.08937C0.571289 8.27005 0.697239 8.42631 0.873805 8.46477L1.86199 8.67959C1.94792 8.94267 2.05435 9.19928 2.18 9.44647L1.63294 10.2974C1.53524 10.4495 1.55672 10.6491 1.68444 10.7768L3.2239 12.3163C3.35161 12.444 3.55123 12.4655 3.70328 12.3678L4.55423 11.8207C4.80142 11.9464 5.05803 12.0528 5.32111 12.1387L5.53593 13.1269C5.57429 13.3035 5.73065 13.4294 5.91133 13.4294H8.08839C8.26908 13.4294 8.42543 13.3035 8.46379 13.1269L8.67861 12.1387C8.94169 12.0528 9.1983 11.9464 9.4455 11.8207L10.2964 12.3678C10.4485 12.4655 10.6481 12.4441 10.7758 12.3163L12.3153 10.7768C12.443 10.6491 12.4645 10.4495 12.3668 10.2974L11.8197 9.44647C11.9454 9.19928 12.0518 8.94267 12.1377 8.67959L13.1259 8.46477C13.3025 8.42631 13.4284 8.27005 13.4284 8.08937V5.91231C13.4284 5.73162 13.3025 5.57536 13.1259 5.53691ZM9.30493 7.00084C9.30493 8.27182 8.27084 9.30591 6.99986 9.30591C5.72888 9.30591 4.69479 8.27182 4.69479 7.00084C4.69479 5.72986 5.72888 4.69577 6.99986 4.69577C8.27084 4.69577 9.30493 5.72986 9.30493 7.00084Z" fill="#4E3B8B"/>
    </svg>
  )
}

export default CustomSVG;
