import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSubscribeChannel from '../../../centrifuge/useSubscribeChannel';
import { updateContentApprovalListBoard } from '../../../actions/contentApproval.actions';

const SUBSCRIBE_ACTIONS = {
  CONTENT_BOARD_REFRESH: 'content_board_refresh',
};

const useContentApprovalSockets = () => {
  const dispatch = useDispatch();
  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const JWT = useSelector(state => state.auth?.token?.access_token);

  useSubscribeChannel(currentOrganizationID, {
    channel: `content_approval:${currentOrganizationID}`,
    events: ctx => {
      switch (ctx.data.action) {
        case SUBSCRIBE_ACTIONS.CONTENT_BOARD_REFRESH: {
          dispatch(updateContentApprovalListBoard(ctx.data.data));
          break;
        }
      }
    },
    opts: { data: { organization_id: currentOrganizationID, token: JWT } },
    // onError: err => console.error('Centrifuge subscribe error: ', err),
    // onSubscribe: ctx => console.log('+ subscribe content approval', ctx.channel),
    // onUnsubscribe: ctx => console.log('- unsubscribe content approval', ctx.channel),
  });
};

export default useContentApprovalSockets;
