import React, { useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Section from '../../CampaignBriefPage/components/Section';
import { useDispatch, useSelector } from 'react-redux';
import { approveBriefItem } from '../../../../actions/brief.actions';
import LightPurpleButton from '../../CampaignBriefPage/components/LightPurpleButton';
import styles from '../BriefHeaderSection/BriefHeaderSection.module.scss';
import cs from 'classnames';
import CampaignBriefCollaborators from '../CampaignBriefCollaborators';
import { briefPageType } from '../../briefPageType';
import { ReactComponent as CheckmarkIcon } from '../../../../assets/img/icons/checked_.svg';
import { useMediaQuery } from 'react-responsive/src';

const BriefHeaderSection = ({
  currentBrief,
  currentBriefItem,
  isApproved = false,
  pageType,
  isRemoveApprove = true,
  availableOrganizationDropdownOption,
}) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const [disableApproveButton, setDisableApproveButton] = useState(isApproved);
  const collaborators = useSelector(state => state.brief.currentBrief.collaborators);

  useEffect(() => {
    setDisableApproveButton(isApproved);
  }, [isApproved]);

  const approveHandler = () => {
    if (currentOrganizationID && currentBriefItem) {
      dispatch(approveBriefItem(currentOrganizationID, currentBrief.id, currentBriefItem.id));
      setDisableApproveButton(true);
    }
  };

  const briefStatus = currentBriefItem ? currentBriefItem.status : currentBrief.status;

  const sectionTitle = useMemo(() => {
    if (pageType == briefPageType.CAMPAIGN_BRIEF_ITEM) {
      return 'Campaign brief';
    }
    if (pageType == briefPageType.CREATOR_BRIEF_ITEM) {
      return 'Creator brief';
    }
    return 'Campaign';
  }, [pageType]);

  const isDisplayCollaboratorsLength = briefStatus === 'approved' || briefStatus === 'pending_approval';

  return (
    <Section className={styles.root}>
      <div>
        <span className={styles.label}>{sectionTitle}</span>
        {currentBriefItem && currentBriefItem.name && <div className={styles.name}>{currentBriefItem.name}</div>}
        {briefPageType.CAMPAIGN_ITEM === pageType && currentBrief && currentBrief.name && (
          <div className={styles.name}>{currentBrief.name}</div>
        )}
        <span className={styles.campaignName}>Campaign name</span>
      </div>

      <div className={styles.rightSide}>
        {briefPageType.CAMPAIGN_ITEM !== pageType && (
          <div className={styles.rightSideBlock}>
            <span className={styles.label}>Collaborators</span>
            <CampaignBriefCollaborators
              campaignID={match.params.campaignID}
              availableOrganizationDropdownOption={availableOrganizationDropdownOption}
              currentBriefItem={currentBriefItem}
            />
          </div>
        )}

        <div className={styles.rightSideBlock}>
          <span className={styles.label}>{briefPageType.CAMPAIGN_ITEM === pageType ? 'Campaign status' : 'Brief status'}</span>
          <div className={cs(styles.statuses, styles[briefStatus])}>
            {briefStatus.replace('_', ' ')}{' '}
            {briefPageType.CAMPAIGN_ITEM !== pageType && isDisplayCollaboratorsLength && (
              <span className={styles.collaboratorsLengthBlock}>
                {collaborators.filter(item => !!item.approved_at).length + '/' + collaborators.length}
              </span>
            )}
          </div>
          {currentBriefItem && !isRemoveApprove && isMobile && (
            <>
              {isApproved && (
                <div className={styles.approvedButtonBlock}>
                  <div className={cs(styles.approvedButton)}>
                    Approved by you
                    <span>
                      <CheckmarkIcon />
                    </span>
                  </div>
                </div>
              )}
              {!isApproved && (
                <div className={styles.rightSideBlock}>
                  <div className={styles.approvedButtonBlock}>
                    <LightPurpleButton disabled={disableApproveButton} isSmall onClick={approveHandler}>
                      Approve
                    </LightPurpleButton>
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        {currentBriefItem && !isRemoveApprove && !isMobile && (
          <>
            {isApproved && (
              <div className={styles.approvedButtonBlock}>
                <div className={cs(styles.approvedButton)}>
                  Approved by you
                  <span>
                    <CheckmarkIcon />
                  </span>
                </div>
              </div>
            )}
            {!isApproved && (
              <div className={styles.rightSideBlock}>
                <div className={styles.approvedButtonBlock}>
                  <LightPurpleButton disabled={disableApproveButton} isSmall onClick={approveHandler}>
                    Approve
                  </LightPurpleButton>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Section>
  );
};

export default BriefHeaderSection;
