import React, { useEffect, useRef } from 'react';
import styles from './PlanCard.module.scss';
import cs from 'classnames';
import CheckmarkSVG from '../../IconsSvg/CheckmarkSVG';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import lodash from 'lodash';

const PlanCard = ({ plan, popular, onSelect, disabled, isCurrentPlan, trialAvailable, preselect, isModal = false }) => {
  const preselectRef = useRef(null);
  const isPopular = plan.id === popular;
  const preselected = plan.name.toLowerCase() === preselect?.toLowerCase();
  const noPadding = (popular <= 1004 ? plan.id > 1004 : plan.id <= 1004) && !isModal;

  useEffect(() => {
    if (!preselected || !preselectRef?.current) return;

    preselectRef.current.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
  }, [preselected, preselectRef]);

  const prepareObjectives = objectivesData => {
    const finalObjectives = {};
    finalObjectives.Reach = objectivesData.reach;
    finalObjectives.Traffic = objectivesData.link_clicks;
    finalObjectives.Engagement = objectivesData.post_engagement;
    finalObjectives.Conversions = objectivesData.conversions;

    const tempArray = [];
    lodash.forIn(finalObjectives, (value, key) => {
      if (value) {
        tempArray.push(key);
      }
    });

    return tempArray.join(', ');
  };

  return (
    <div
      className={cs(
        styles.root,
        { [styles.marginItem]: plan.id > 1004 && (!isPopular || !preselected) && !isModal },
        { [styles.noPadding]: noPadding },
        { [styles.popular]: isPopular },
        { [styles.preselected]: preselected },
        { [styles.modalStyles]: isModal }
      )}
      ref={preselected ? preselectRef : null}
    >
      <div className={styles.topGroup}>
        {isPopular && <div className={styles.popularLine}>MOST POPULAR</div>}
        {preselected && <div className={styles.preselectedLine}>CURRENT SELECTION</div>}
        <span>{plan.name}</span>
        {plan.price_monthly ? (
          <>
            <div className={cs(styles.row, styles.price)}>
              <div>
              {/*  {plan.currency?.toLowerCase() === 'usd' ? '$' : `${plan.currency} `}*/}
                {plan.price_monthly === '0.00' ? '0' : plan.price_monthly}
              </div>
            </div>
            <div className={styles.disclaimer}>
              No credit card needed
            </div>
          </>
        ) : (
          <div className={styles.contact}>Contact our team</div>
        )}
        <span className={styles.description}>
          {plan.id === 1002 && 'Basics for those wanting to get testing'}
          {plan.id === 1003 && 'Advanced collaboration and support for teams, perfect for SMEs'}
          {plan.id === 1004 && 'The works including agency level testing and creative sets'}
          {plan.id === 1005 && 'Enquire for bespoke customization and white labeling'}
        </span>
        <div className={styles.list}>
          <span>{`${plan.name} includes:`}</span>
            <div className={styles.item}>
              <CheckmarkSVG />
              Campaign Brief Creation
            </div>
          <div className={styles.item}>
            <CheckmarkSVG />
            Campaign Brief Approval
          </div>
          <div className={styles.item}>
            <CheckmarkSVG />
            Discussion Boards
          </div>
          <div className={styles.item}>
            <CheckmarkSVG />
            Content Management
          </div>
          <div className={styles.item}>
            <CheckmarkSVG />
            Up to 250 GB Storage
          </div>
        </div>
      </div>
      <div className={styles.btn}>
        {trialAvailable ? (
          <ButtonCustom onClick={() => onSelect(plan.id, plan.name)} disabled={disabled} outline={!!preselect && !preselected}>
            {plan.id !== 1005 ? (isCurrentPlan ? 'Current plan' : `Start ${plan.trial_days} day trial`) : 'Contact Us'}{' '}
          </ButtonCustom>
        ) : (
          <ButtonCustom onClick={() => onSelect(plan.id, plan.name)} disabled={disabled}>
            {plan.id !== 1005 ? (isCurrentPlan ? 'Current plan' : `Select`) : 'Contact Us'}{' '}
          </ButtonCustom>
        )}
      </div>
    </div>
  );
};

export default PlanCard;
