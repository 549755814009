import { AD_SETS_TYPES } from '../actions/adSets.actions';

const initialState = {
  data: [],
  loading: false,
  refresh: false,
  changeStatus: false,
  failure: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case AD_SETS_TYPES.GET_AD_SETS.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case AD_SETS_TYPES.GET_AD_SETS.SUCCESS: {
      return {
        ...state,
        data: payload,
        loading: false,
      };
    }
    case AD_SETS_TYPES.GET_AD_SETS.FAILURE: {
      return {
        ...state,
        loading: false,
        failure: payload,
      };
    }
    case AD_SETS_TYPES.REFRESH_AD_SETS.REQUEST: {
      return {
        ...state,
        refresh: true,
      };
    }
    case AD_SETS_TYPES.REFRESH_AD_SETS.SUCCESS: {
      const ids = payload.map(set => set.id);
      const data = state.data.map(set => (ids.includes(set.id) ? payload.find(s => s.id === set.id) : set));
      return {
        ...state,
        refresh: false,
        data,
      };
    }
    case AD_SETS_TYPES.REFRESH_AD_SETS.FAILURE: {
      return {
        ...state,
        refresh: false,
        failure: payload,
      };
    }
    case AD_SETS_TYPES.CHANGE_STATUS_AD_SETS.REQUEST: {
      return {
        ...state,
        changeStatus: true,
      };
    }
    case AD_SETS_TYPES.CHANGE_STATUS_AD_SETS.SUCCESS: {
      const ids = payload.map(set => set.id);
      const data = state.data.map(set => {
        let newSet = set;

        if (ids.includes(set.id)) {
          newSet = payload.find(s => s.id === set.id);
          newSet.insights = set.insights;
        }

        return newSet;
      });
      return {
        ...state,
        data,
        changeStatus: false,
      };
    }
    case AD_SETS_TYPES.CHANGE_STATUS_AD_SETS.FAILURE: {
      return {
        ...state,
        changeStatus: false,
        failure: payload,
      };
    }
    default: {
      return state;
    }
  }
};
