import React, { useState } from 'react';
import styles from './ContentApprovalCell.module.scss';
import cs from 'classnames';
import { useHistory } from 'react-router-dom';
import brandPreviewImage from '../../../assets/img/brandPlaceholder.svg';
import CollaboratorsInfo from './CollaboratorsInfo';
import useContentBoardStatusTile from '../../../pages/ContentApproval/ContentBoard/useContentBoardStatusTile';
import dayjs from 'dayjs';
import SortButtons from '../../../pages/ContentApproval/ContentBoard/components/DeliverablesList/SortButtons';
import useTooltip from '../../Menu/components/MenuInner/components/useTooltip';
import TestActionModal from '../../Modals/TestActionModal';
import { useDispatch, useSelector } from 'react-redux';
import { archiveContentBoard, unzipContentBoard } from '../../../actions/contentApproval.actions';
import ArchiveButton from '../../ArchiveButton';

const SortButtonsWrapper = ({ orderByName, sort, setSort }) => {
  return (
    <SortButtons
      orderBy={orderByName}
      isActive={orderType => orderByName === sort.order_by && orderType === sort.order_type}
      onSort={(orderBy, orderType) => setSort({ order_by: orderBy, order_type: orderType })}
    />
  );
};

const CellHeader = ({ sort, setSort, style }) => {
  return (
    <div className={cs(styles.root, styles.header)} style={style}>
      <span>
        Name of board
        <SortButtonsWrapper sort={sort} setSort={setSort} orderByName="name" />
      </span>
      <span>
        Brand
        <SortButtonsWrapper sort={sort} setSort={setSort} orderByName="brand_name" />
      </span>
      <span>Collaborators</span>
      <span>
        Creation date
        <SortButtonsWrapper sort={sort} setSort={setSort} orderByName="created_at" />
      </span>
      <span>Related brief</span>
      <span>
        Board status
        <SortButtonsWrapper sort={sort} setSort={setSort} orderByName="status" />
      </span>
      <span></span>
    </div>
  );
};

const CellContent = ({ board, style, onDetail, isTablet, onArchive }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const brandLogo = board.brand_logo_thumbnail_location || board.brand_logo_location || brandPreviewImage;
  const statusTile = useContentBoardStatusTile(board.status);
  const briefName = board?.campaign_brief_item_id ? board?.campaign_brief_item_name || 'unnamed brief' : 'Stand alone';
  const { toolTip, onEnter, onLeave } = useTooltip(briefName, 'top', styles.tooltip);

  const [isOpenArchiveModal, setIsOpenArchiveModal] = useState(false);

  const handleDetailClick = () => {
    if (onDetail) {
      onDetail(board.id);
      return;
    }
    localStorage.setItem(
      'boardBackRoute',
      JSON.stringify({
        routes: [
          {
            route: '/content-approval',
            routeName: 'Content Approval',
          },
        ],
        boardId: board.id,
      })
    );
    history.push(`/content-board/${board.id}`);
  };

  const handleArchiveBoard = () => {
    dispatch(archiveContentBoard(currentOrganizationID, board.id)).then(() => onArchive(board, 'archive'));
  };
  const handleUnzipBoard = () => {
    dispatch(unzipContentBoard(currentOrganizationID, board.id)).then(() => onArchive(board, 'unzip'));
  };

  return (
    <>
      {!isTablet ? (
        <>
          <div className={styles.root} style={style} onClick={handleDetailClick}>
            <div className={styles.boardName}>
              <div className={styles.boardNameText} style={{ overflow: 'visible' }}>
                {board.name}
              </div>
            </div>
            <div className={styles.brand}>
              <img src={brandLogo} alt="logo" />
              <span>{board.brand_name}</span>
            </div>
            <div className={styles.creators}>
              <CollaboratorsInfo collaborators={board?.collaborators} />
            </div>
            <div className={styles.createdDate}>{dayjs(board.created_at).format('DD / MMM / YYYY')}</div>
            <div
              className={cs(styles.brief, { [styles.alone]: !board?.campaign_brief_item_id })}
              onMouseEnter={onEnter}
              onMouseLeave={onLeave}
            >
              <span>{briefName}</span>
              {briefName.length > 20 ? toolTip : null}
            </div>
            <div className={styles.status}>{statusTile}</div>
            <div className={styles.archive}>
              <ArchiveButton
                isUnzipType={board.is_archived}
                onClick={e => {
                  e.stopPropagation();
                  setIsOpenArchiveModal(true);
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <div className={cs(styles.root, { [styles.rootMobile]: isTablet })} style={style} onClick={handleDetailClick}>
          <div className={styles.levelOne}>
            <div className={styles.boardName}>{board.name}</div>
            <div className={styles.rightContent}>
              <div className={styles.status}>{statusTile}</div>
              <div className={styles.archive}>
                <ArchiveButton
                  isUnzipType={board.is_archived}
                  onClick={e => {
                    e.stopPropagation();
                    setIsOpenArchiveModal(true);
                  }}
                />
              </div>
            </div>
          </div>
          {!!board?.collaborators.length && (
            <div className={styles.levelTwo}>
              <div className={styles.creators}>
                <CollaboratorsInfo collaborators={board?.collaborators} />
              </div>
            </div>
          )}
          <div className={styles.levelThree}>
            {board?.campaign_brief_item_name && (
              <div className={styles.container}>
                <div className={styles.head}>Brief:</div>
                <div>{board?.campaign_brief_item_name}</div>
              </div>
            )}
            {board.brand_name && (
              <div className={styles.container}>
                <div className={styles.head}>Brand:</div>
                <div>{board.brand_name}</div>
              </div>
            )}
            {board.created_at && (
              <div className={styles.container}>
                <div className={styles.head}>Created:</div>
                <div>{dayjs(board.created_at).format('DD / MMM / YYYY')}</div>
              </div>
            )}
          </div>
        </div>
      )}

      <TestActionModal
        open={isOpenArchiveModal}
        title={
          board.is_archived
            ? `Are you sure you want to restore "${board.name}" content board`
            : `Are you sure you want to archive "${board.name}" content board`
        }
        agreeText="Yes"
        cancelText="No"
        onSubmit={() => {
          setIsOpenArchiveModal(false);
          if (board.is_archived) {
            handleUnzipBoard();
          } else {
            handleArchiveBoard();
          }
        }}
        onClose={() => setIsOpenArchiveModal(false)}
      />
    </>
  );
};

const ContentApprovalCell = ({ sort, setSort, tableHeader, ...props }) => {
  if (tableHeader) return <CellHeader style={props.style} sort={sort} setSort={setSort} />;
  return <CellContent {...props} />;
};

export default ContentApprovalCell;
