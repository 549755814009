import React, { useEffect, useState } from 'react';
import styles from './DeliverablesList.module.scss';
import DeliverableCell from '../../../../../components/Cells/DeliverableCell';
import Pagination from '../../../../../components/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getBoardDeliverables } from '../../../../../actions/contentApproval.actions';
import LoadingState from './LoadingState';
import cs from 'classnames';
import SortButtons from './SortButtons';
import { useMediaQuery } from 'react-responsive/src';

const listHeader = ['name', 'platform', 'placement', 'live_date', 'status_concept', 'status_content'];
const columnName = {
  name: 'Name',
  platform: 'Platform',
  placement: 'Placement',
  live_date: 'Live Date',
  status_concept: 'Concept',
  status_content: 'Content Upload',
};

const DeliverablesList = ({ boardId, rootClassName, headerRightContent }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const { openedBoard, deliverables, deliverablesTotalCount, deliverablesLoading } = useSelector(state => state.contentApproval);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({ order_by: 'name', order_type: 'asc' });
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  const PAGE_SIZE = 10;
  const isListEmpty = !deliverablesLoading && !deliverables.length;

  useEffect(() => {
    if (!currentOrg) return;
    dispatch(getBoardDeliverables(currentOrg.organization_id, params.boardId || boardId, page, PAGE_SIZE, sort));
  }, [page, currentOrg, sort]);

  const handleDeliverableItemClick = id => {
    history.push(`/content-board/${openedBoard.id}/deliverables/${id}`);
  };

  return (
    <div className={cs(styles.root, rootClassName)}>
      {!isTablet && (
        <div className={styles.title}>
          <span>Deliverables</span>
          {headerRightContent}
        </div>
      )}

      {isListEmpty && <div className={styles.empty}>No deliverables yet</div>}

      <div className={cs(styles.list, { [styles.hidden]: isListEmpty })}>
        <div className={styles.listHeader}>
          {listHeader.map(item => (
            <div key={item}>
              {columnName[item]}
              <SortButtons
                orderBy={item}
                isActive={orderType => item === sort.order_by && orderType === sort.order_type}
                onSort={(orderBy, orderType) => setSort({ order_by: orderBy, order_type: orderType })}
              />
            </div>
          ))}
          {/* <div data-reason="progress-bar-column" />*/}
        </div>

        {deliverablesLoading ? (
          <>
            {[...Array(deliverables.length || PAGE_SIZE).keys()].map(index => (
              <LoadingState index={index} key={index} isTablet={isTablet} />
            ))}
          </>
        ) : (
          <>
            {deliverables?.map((itemData, idx) => (
              <DeliverableCell key={idx} data={itemData} onClick={() => handleDeliverableItemClick(itemData.id)} isTablet={isTablet} />
            ))}
          </>
        )}
      </div>

      {!!deliverables.length && deliverablesTotalCount > PAGE_SIZE && (
        <Pagination className={styles.pagination} amount={PAGE_SIZE} totalCount={deliverablesTotalCount} page={page} setPage={setPage} />
      )}
    </div>
  );
};

export default DeliverablesList;
