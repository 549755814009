import React, { useState } from 'react';
import styles from './BriefInviteModal.module.scss';
import { Modal } from '..';
import Button from '../../../expert/components/Buttons/ButtonCustom';
import { useSelector } from 'react-redux';
import Dropdown from '../../FormControls/Dropdown';

const BriefInviteModal = ({ onSubmit, inviteDetails }) => {
  const orgs = useSelector(state => state.auth.organizations);
  const [selectedOrg, setSelectedOrg] = useState(orgs[0]);
  return (
    <div className={styles.root}>
      <div className={styles.title}>You have been invited!</div>
      <div className={styles.details}>
        <div className={styles.avatarCircle}>
          {inviteDetails?.inviter_first_name.charAt(0)}
          {inviteDetails?.inviter_last_name.charAt(0)}
        </div>
        <div className={styles.name}>
          {inviteDetails?.inviter_first_name} {inviteDetails?.inviter_last_name}
        </div>
        <div className={styles.organization}>{inviteDetails?.organization_name}</div>
        <div className={styles.description}>has invited you to collaborate on</div>
        <div className={styles.briefName}>
          {inviteDetails?.object_name
            ? `"${inviteDetails?.object_name}"`
            : `a ${inviteDetails?.object_type === 'campaign_brief_item' ? 'campaign brief' : 'content board'}`}
        </div>
        <div className={styles.separator} />
        <div className={styles.flexRow}>
          <div className={styles.text}>Choose the organization under which you want to accept the invite</div>
          {orgs.length > 0 && (
            <Dropdown
              items={orgs}
              getId={i => i.id}
              getDisplayValue={i => i.organization_name}
              placeholder="Select your organization"
              renderItem={i => <div className={styles.dropdownOption}>{i}</div>}
              selectedItem={selectedOrg || ''}
              onSelect={i => setSelectedOrg(i)}
              className={styles.dropdown}
            />
          )}
        </div>
      </div>
      <div className={styles.disclaimer}>
        <div className={styles.bold}>Disclaimer:</div>
        <div className={styles.disclaimerText}>
          For the avoidance of doubt, any material displayed in this brief, including the ideas and concepts, are the copyright of the (name
          of the organization who has sent the invite). By accepting invite you and your organization agree that the information shared in
          this brief solely provides as an outline of the opportunity to be involved in the campaign/ project and should be treated as
          confidential. Should you or your organization be appointed or choose not to be involved in the proposed project/campaign, you or
          your organization must not share, circulate or copy the information included in this brief.{' '}
        </div>
      </div>
      <div className={styles.navigationBtns}>
        <Button className={styles.btn} outline onClick={() => onSubmit(false)}>
          Cancel
        </Button>
        <Button className={styles.btn} onClick={() => onSubmit(selectedOrg)}>
          Accept invite
        </Button>
      </div>
    </div>
  );
};

export default props => {
  return (
    <Modal {...props} maxWidth={530}>
      <BriefInviteModal {...props} />
    </Modal>
  );
};
