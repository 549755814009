import React from 'react';

function InfoQuestionSVG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12">
      <path
        fill="#9FA2B4"
        fillRule="evenodd"
        d="M6 12A6 6 0 116 0a6 6 0 010 12zm-.688-4.526h1.257a1.68 1.68 0 01.092-.551c.056-.145.155-.287.299-.424l.505-.47c.214-.209.37-.412.466-.612a1.5 1.5 0 00.145-.663c0-.557-.18-.986-.538-1.288-.36-.301-.865-.452-1.519-.452-.656 0-1.166.163-1.53.49-.362.326-.547.783-.553 1.369h1.486c.005-.22.063-.394.171-.523a.53.53 0 01.426-.194c.381 0 .572.221.572.664a.814.814 0 01-.17.499c-.112.15-.277.317-.494.498a1.584 1.584 0 00-.47.644c-.097.248-.145.585-.145 1.013zm-.18 1.336c0 .217.078.395.235.534.156.14.352.209.586.209.235 0 .43-.07.587-.209a.682.682 0 00.235-.534.682.682 0 00-.235-.534.851.851 0 00-.587-.209c-.234 0-.43.07-.586.21a.682.682 0 00-.235.533z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default InfoQuestionSVG;
