import React from 'react';

const TrashSVG = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.5 5.66667V13.4444C3.5 14.3036 4.17157 15 5 15H11C11.8284 15 12.5 14.3036 12.5 13.4444V5.66667M9.5 6.44444V11.8889M6.5 6.44444V11.8889M2 3.33333H5M5 3.33333H11M5 3.33333V2.55556C5 1.69645 5.67157 1 6.5 1H9.5C10.3284 1 11 1.69645 11 2.55556V3.33333M11 3.33333H14"
        stroke="#4E3B8B"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrashSVG;
