import React from 'react';
import styles from './Section.module.scss';
import cs from 'classnames';

const Section = ({ className, children }) => {
  return (
    <div className={cs(styles.root, className)}>
      {children}
    </div>
  );
};

export default Section;
