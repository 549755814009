import React, { useState } from 'react';
import Tooltip from './Tooltip';

/**
 *
 * @param className
 * @param {string} tooltipText
 * @param {"left" | "right" | "bottom" | "top" | {top: (rect) => number, left: (rect) => number}} position
 * @return {{onEnter: onEnter, toolTip: React.ReactElement, onLeave: (function(): void)}}
 */
const useTooltip = (tooltipText, position = 'right', className) => {
  const [toolTip, setToolTip] = useState(null);

  let top = rect => rect.y + rect.height / 2;
  let left = rect => rect.right;

  if (position === 'left') {
    top = rect => rect.y + rect.height / 2;
    left = rect => rect.left;
  }

  if (position === 'bottom') {
    top = rect => rect.y + rect.height;
    left = rect => rect.x + rect.width / 2;
  }

  if (position === 'top') {
    top = rect => rect.y;
    left = rect => rect.x + rect.width / 2;
  }

  if (typeof position === 'object') {
    top = position.top;
    left = position.left;
  }

  const onLeave = () => setToolTip(null);
  const onEnter = event => {
    let container = event.target;

    if (!container.getAttribute('data-tooltip-container')) {
      const newContainer = findParentNode(container, node => node.getAttribute('data-tooltip-container'));
      if (newContainer) {
        container = newContainer;
      }
    }
    const rect = container.getBoundingClientRect();
    setToolTip(<Tooltip top={top(rect)} left={left(rect)} text={tooltipText} transformStyle={position} className={className} />);
  };

  return { toolTip, onEnter, onLeave };
};

const findParentNode = (node, callback) => {
  if (node.nodeName === 'BODY') return null;
  if (callback(node)) return node;
  return findParentNode(node.parentNode, callback);
};

export default useTooltip;
