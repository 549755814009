import React, { useState } from 'react';
import styles from './ContentBoardCollaborators.module.scss';
import CollaboratorTooltip from '../../../../../components/Cells/ContentApprovalCell/CollaboratorsInfo/CollaboratorTooltip';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { deleteInvitedBoardCollaborator, inviteBoardCollaborator } from '../../../../../actions/contentApproval.actions';
import DotsDropdownButton from '../../../../../components/DotsDropdownButton';
import CollaboratorsDropdown from '../../../../../components/CollaboratorsDropdown';
import InviteBoardCollaboratorsModal from '../../../../../components/Modals/InviteCollaboratorsModal';
import { TYPES_CAMPAIGNS, TYPES_CAMPAIGNS_OBJECTS } from '../../../../../shared/constants/constants';
import DropdownCollaboratorCell from './components/DropdownCollaboratorCell';
import ConfirmDeleteInvitedCollaboratorsModal from '../../../../../components/Modals/ConfirmDeleteInvitedCollaboratorsModal';
import CollaboratorDropdownInvitedCell from '../../../../../components/CollaboratorDropdownInvitedCell';
import { useMediaQuery } from 'react-responsive/src';
import { Modal } from '../../../../../components/Modals';

const allowed = [TYPES_CAMPAIGNS.BRAND, TYPES_CAMPAIGNS.AGENCY, TYPES_CAMPAIGNS.TALENT_MANAGER, TYPES_CAMPAIGNS.CONTENT_CREATOR];
const ORG_TYPES = TYPES_CAMPAIGNS_OBJECTS.filter(item => allowed.includes(item.type)).map((item, idx) => ({ ...item }));

const ContentBoardCollaborators = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const userId = useSelector(state => state.auth.user.id);
  const currentBoard = useSelector(state => state.contentApproval.openedBoard);
  const { collaborators, invitedCollaborators } = useSelector(state => state.contentApproval);
  const [isOpenInviteModal, setIsOpenInviteModal] = useState(false);
  const [copiedLinkId, setCopiedLinkId] = useState(null);
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  const [invitedCollaboratorIdToDelete, setInvitedCollaboratorIdToDelete] = useState(null);

  const withoutTooltip = true;
  const collaboratorsCount = collaborators.length + invitedCollaborators.length;
  const displayableIconsCount = 3;

  const handleInviteCollaborators = invites => {
    dispatch(inviteBoardCollaborator(currentOrg.organization_id, params.boardId, invites));
  };

  const handleDeleteInvitedCollaborator = collaborator => {
    dispatch(deleteInvitedBoardCollaborator(currentOrg.organization_id, params.boardId, collaborator.id));
  };

  const handleResendInvitedCollaborator = collaborator => {
    dispatch(inviteBoardCollaborator(currentOrg.organization_id, params.boardId, collaborator));
  };

  const getCollaboratorsIcons = () => {
    return collaborators.map((item, index) => {
      if (index <= displayableIconsCount - 1) {
        const letter = item.organization_name.slice(0, 1).toUpperCase();
        return (
          <div key={item.organization_id} className={styles.icon}>
            {letter}
            {!withoutTooltip && <CollaboratorTooltip collaborator={item} iconText={letter} className={styles.collaboratorTooltip} />}
          </div>
        );
      }
      return null;
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.icons}>{getCollaboratorsIcons()}</div>
      <DotsDropdownButton
        buttonClassName={styles.dotsButtonClassName}
        renderDropdown={(dropdownRef, closeDropdown) =>
          isTablet ? (
            <Modal borderRadius={'10px'} open={true} onClose={closeDropdown} className={styles.modalCollaborators}>
              <CollaboratorsDropdown
                forwardedRef={dropdownRef}
                collaborators={collaborators}
                invitedCollaborators={invitedCollaborators}
                counter={collaboratorsCount}
                onAddClick={() => {
                  setIsOpenInviteModal(true);
                  window.analytics.track('initiate_content_board_invite', {
                    category: 'Invite',
                    label: currentBoard.name,
                    userId: userId,
                    content_board_id: currentBoard.id,
                  });
                  closeDropdown();
                }}
                onClose={() => closeDropdown()}
                isTablet={isTablet}
                collaboratorsTableHeader={<DropdownCollaboratorCell tableHeader />}
                renderCollaboratorCell={collaborator => (
                  <DropdownCollaboratorCell key={collaborator.approver_id} collaborator={collaborator} />
                )}
                renderInvitedCollaboratorCell={collaborator => (
                  <CollaboratorDropdownInvitedCell
                    key={collaborator.id}
                    collaborator={collaborator}
                    onDelete={() => {
                      setInvitedCollaboratorIdToDelete(collaborator);
                      closeDropdown();
                    }}
                    onResend={() => handleResendInvitedCollaborator(collaborator)}
                    copy={{
                      id: copiedLinkId,
                      setCopyId: setCopiedLinkId,
                    }}
                  />
                )}
              />
            </Modal>
          ) : (
            <CollaboratorsDropdown
              forwardedRef={dropdownRef}
              collaborators={collaborators}
              invitedCollaborators={invitedCollaborators}
              counter={collaboratorsCount}
              onAddClick={() => {
                setIsOpenInviteModal(true);
                window.analytics.track('initiate_content_board_invite', {
                  category: 'Invite',
                  label: currentBoard.name,
                  userId: userId,
                  content_board_id: currentBoard.id,
                });
                closeDropdown();
              }}
              collaboratorsTableHeader={<DropdownCollaboratorCell tableHeader />}
              renderCollaboratorCell={collaborator => (
                <DropdownCollaboratorCell key={collaborator.approver_id} collaborator={collaborator} />
              )}
              renderInvitedCollaboratorCell={collaborator => (
                <CollaboratorDropdownInvitedCell
                  key={collaborator.id}
                  collaborator={collaborator}
                  copy={{
                    id: copiedLinkId,
                    setCopyId: setCopiedLinkId,
                  }}
                  onDelete={() => {
                    setInvitedCollaboratorIdToDelete(collaborator);
                    closeDropdown();
                  }}
                  onResend={() => handleResendInvitedCollaborator(collaborator)}
                />
              )}
            />
          )
        }
      />
      <InviteBoardCollaboratorsModal
        open={isOpenInviteModal}
        onClose={() => setIsOpenInviteModal(false)}
        onSubmit={values => handleInviteCollaborators(values)}
        availableOrganizationDropdownOption={ORG_TYPES}
        isTablet={isTablet}
        collaborators={[...collaborators, ...invitedCollaborators]}
      />
      <ConfirmDeleteInvitedCollaboratorsModal
        open={!!invitedCollaboratorIdToDelete}
        onClose={() => setInvitedCollaboratorIdToDelete(null)}
        onSubmit={() => {
          handleDeleteInvitedCollaborator(invitedCollaboratorIdToDelete);
          setInvitedCollaboratorIdToDelete(null);
        }}
      />
    </div>
  );
};

export default ContentBoardCollaborators;
