import React from 'react';
import styles from './UserAgreements.module.scss';
import LogoSVG from '../../components/IconsSvg/LogoSVG';
import ButtonCustom from '../../components/Buttons/ButtonCustom';

const UserAgreements = ({ onSubmit, title, children }) => {
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <LogoSVG />
      </div>
      <div className={styles.main}>
        {title ? <h1 className={styles.title}>{title}</h1> : null}

        {children}
      </div>

      <div className={styles.footer}>
        <div className={styles.row}>
          <div className={styles.col}>
            <ButtonCustom type={'button'} onClick={onSubmit}>
              I understand
            </ButtonCustom>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAgreements;
