import { instance } from '../shared/utils/api.config.creator';

export const importAudience = async (organizationId, externalIds) => {
  const response = await instance.post('/fb/audiences/import', { organization_id: organizationId, external_ids: externalIds });
  return response.data;
};

export const getAudiences = async (id, params) => {
  const response = await instance.get(`/fb/audiences/${id || ''}`, { params });
  return response.data;
};

export const editAudience = async (id, data) => {
  const response = await instance.patch(`/fb/audiences/${id}`, data);
  return response.data;
};

export const refreshAudiences = async (organizationId, ids) => {
  const response = await instance.post('/fb/audiences/refresh', { organization_id: organizationId, ids });
  return response.data;
};

export const changeAudienceStatus = async (organizationId, status, ids) => {
  const response = await instance.post('/fb/audiences/change-status', { organization_id: organizationId, status, ids });
  return response.data;
};

export const createAudience = async (organizationId, data) => {
  const response = await instance.post('/fb/audiences/', { organization_id: organizationId, ...data });
  return response.data;
};

export const deleteAudience = async id => {
  const response = await instance.delete(`/fb/audiences/${id}`);
  return response.data;
}

export const getLocations = async (params) => {
  const response = await instance.get('/fb/proxy/locations', { params });
  return response.data;
};

export const getTargetingCategory = async (params) => {
  const response = await instance.get('/fb/proxy/audiences/targeting-categories', { params });
  return response.data;
};

export const getAudiencesForImport = async (params) => {
  const response = await instance.get('/fb/proxy/audiences', { params });
  return response.data;
};
