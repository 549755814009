import React, { useEffect, useState } from 'react';
import RendererContentWrapper from '../components/RendererContentWrapper';
import Loader from '../../../../expert/components/Loader';

export const ImageRenderer = ({ story, action, isPaused, config }) => {
  const [loaded, setLoaded] = useState(false);
  const { width, height, loader, storyStyles } = config;
  const computedStyles = {
    ...styles.storyContent,
    ...(storyStyles || {}),
  };

  useEffect(() => {
    if (loaded) {
      onPlaying();
    }
  }, [isPaused, loaded]);

  const imageLoaded = () => {
    setLoaded(true);
    action('play');
  };

  const onWaiting = () => {
    action('pause', true);
  };

  const onPlaying = () => {
    action('play', true);
  };

  return (
    <RendererContentWrapper story={story}>
      <div
        onClick={() => {
          if (isPaused) {
            onPlaying();
          } else {
            onWaiting();
          }
        }}
      >
        <img style={computedStyles} src={story.url} onLoad={imageLoaded} />
        {!loaded && (
          <div
            style={{
              width: width,
              height: height,
              position: 'absolute',
              left: 0,
              top: 0,
              background: 'rgba(0, 0, 0, 0.9)',
              zIndex: 9,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#ccc',
            }}
          >
            {loader || <Loader />}
          </div>
        )}
      </div>
    </RendererContentWrapper>
  );
};

const styles = {
  story: {
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
  },
  storyContent: {
    width: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 'auto',
  },
};

export const tester = story => {
  return {
    condition: !story.content && (!story.type || story.type === 'image'),
    priority: 3,
  };
};

export default {
  renderer: ImageRenderer,
  tester,
};
