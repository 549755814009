import React from 'react';
import lodash from 'lodash';
import styles from './TabDeliverables.module.scss';
import FacebookSVG from '../IconsSvg/FacebookSVG';
import TagsContainer from '../../pages/ContentApproval/ContentApprovalCreateBoard/components/TagsContainer';
import InstagramSVG from '../IconsSvg/InstagramSVG';
import TikTokSVG from '../IconsSvg/TikTokSVG';
import YoutubeSVG from '../IconsSvg/YoutubeSVG';
import CheckBox from '../../pages/ContentApproval/ContentApprovalCreateBoard/components/CheckBox';
import cs from 'classnames';
import { getFormattedPlacement } from '../../shared/utils/helpers';

function DeliverablesPlatforms({ formik, parentSelectSocial }) {
  const selectSocial = value => {
    formik.setFieldTouched('deliverables', true);
    formik.setFieldTouched('deliverablesFacebook', true);
    formik.setFieldTouched('deliverablesInstagram', true);
    formik.setFieldTouched('deliverablesTiktok', true);
    formik.setFieldTouched('deliverablesYoutube', true);

    const platformsFormikValue = 'deliverables' + value[0].toUpperCase() + value.substring(1);

    if (parentSelectSocial) {
      parentSelectSocial();
    }
    const newFormikState = { ...formik.values, placementsQuantity: 1 };

    if (formik.values.socialPlatforms.includes(value)) {
      newFormikState.socialPlatforms = lodash.remove(formik.values.socialPlatforms, item => item !== value);
      newFormikState[platformsFormikValue] = lodash.reject(formik.values[platformsFormikValue], { platform: value });
      newFormikState.deliverables = lodash.reject(formik.values.deliverables, { platform: value });
    } else {
      newFormikState.socialPlatforms = [...formik.values.socialPlatforms, value];
    }
    formik.setValues(newFormikState);
  };

  const selectPlacement = (value, platform) => {
    const platformsFormikValue = 'deliverables' + platform[0].toUpperCase() + platform.substring(1);
    const newItem = { placement: value, platform: platform };
    if (lodash.find(formik.values[platformsFormikValue], newItem)) {
      const element = lodash.reject(formik.values[platformsFormikValue], newItem);

      formik.setValues(
        {
          ...formik.values,
          [platformsFormikValue]: lodash.reject(formik.values[platformsFormikValue], newItem),
          deliverables: lodash.reject(formik.values.deliverables, newItem),
        },
        true
      );
      if (element.length === 0) {
        selectSocial(platform);
      }
    } else {
      formik.setValues(
        {
          ...formik.values,
          [platformsFormikValue]: [
            ...formik.values[platformsFormikValue],
            {
              placement: value,
              platform: platform,
              quantity: 1,
            },
          ],
          deliverables: [
            ...formik.values.deliverables,
            {
              placement: value,
              platform: platform,
              quantity: 1,
            },
          ],
        },
        true
      );
    }
  };

  const selectQuantity = (placement, platform, qty) => {
    const platformsFormikValue = 'deliverables' + platform[0].toUpperCase() + platform.substring(1);

    formik.setFieldValue(platformsFormikValue, [
      ...lodash.reject(formik.values[platformsFormikValue], { placement: placement, platform: platform }),
      {
        placement: placement,
        platform: platform,
        quantity: qty,
      },
    ]);

    formik.setFieldValue('deliverables', [
      ...lodash.reject(formik.values.deliverables, { placement: placement, platform: platform }),
      {
        placement: placement,
        platform: platform,
        quantity: qty,
      },
    ]);
  };

  const facebookDeliverableOptions = [
    {
      label: getFormattedPlacement('static_post'),
      value: 'static_post',
    },
    {
      label: getFormattedPlacement('video_post'),
      value: 'video_post',
    },
    {
      label: getFormattedPlacement('story_post'),
      value: 'story_post',
    },
    {
      label: getFormattedPlacement('reel'),
      value: 'reel',
    },
  ];
  const instagramDeliverableOptions = [
    {
      label: getFormattedPlacement('static_post'),
      value: 'static_post',
    },
    {
      label: getFormattedPlacement('video_post'),
      value: 'video_post',
    },
    {
      label: getFormattedPlacement('carousel'),
      value: 'carousel',
    },
    {
      label: getFormattedPlacement('live'),
      value: 'live',
    },
    {
      label: getFormattedPlacement('story_post'),
      value: 'story_post',
    },
    {
      label: getFormattedPlacement('highlight'),
      value: 'highlight',
    },
    {
      label: getFormattedPlacement('reel'),
      value: 'reel',
    },
  ];
  const tikTokDeliverableOptions = [
    {
      label: getFormattedPlacement('video_post'),
      value: 'video_post',
    },
  ];
  const youTubeDeliverableOptions = [
    {
      label: getFormattedPlacement('video_post'),
      value: 'video_post',
    },
  ];

  return (
    <>
      <div className={styles.platformsItem}>
        <h2 className={styles.socialHeading} onClick={() => selectSocial('facebook')}>
          <CheckBox isActive={formik.values.socialPlatforms.includes('facebook')} />
          <div className={styles.socialIconWrapper}>
            <FacebookSVG />
          </div>
          <span>Facebook</span>
        </h2>
        {formik.values.socialPlatforms.includes('facebook') && (
          <div className={styles.tagsContainerWrapper}>
            <h3>Placements</h3>
            <TagsContainer
              platform={'facebook'}
              categories={facebookDeliverableOptions}
              deliverables={formik.values.deliverables}
              selectCategory={selectPlacement}
              selectQuantity={selectQuantity}
            />
          </div>
        )}
        {formik.errors.deliverablesFacebook &&
          formik.touched.deliverablesFacebook &&
          formik.values.socialPlatforms.includes('facebook') && (
            <div className={cs(styles.errorContainer, styles.platformsError)}>{formik.errors.deliverablesFacebook}</div>
          )}
      </div>

      <div className={styles.platformsItem}>
        <h2 className={styles.socialHeading} onClick={() => selectSocial('instagram')}>
          <CheckBox isActive={formik.values.socialPlatforms.includes('instagram')} />
          <div className={styles.socialIconWrapper}>
            <InstagramSVG />
          </div>
          <span>Instagram</span>
        </h2>
        {formik.values.socialPlatforms.includes('instagram') && (
          <div className={styles.tagsContainerWrapper}>
            <h3>Placements</h3>
            <TagsContainer
              platform={'instagram'}
              categories={instagramDeliverableOptions}
              deliverables={formik.values.deliverables}
              selectCategory={selectPlacement}
              selectQuantity={selectQuantity}
            />
          </div>
        )}
        {formik.errors.deliverablesInstagram &&
          formik.touched.deliverablesInstagram &&
          formik.values.socialPlatforms.includes('instagram') && (
            <div className={cs(styles.errorContainer, styles.platformsError)}>{formik.errors.deliverablesInstagram}</div>
          )}
      </div>

      <div className={styles.platformsItem}>
        <h2 className={styles.socialHeading} onClick={() => selectSocial('tiktok')}>
          <CheckBox isActive={formik.values.socialPlatforms.includes('tiktok')} />
          <div className={styles.socialIconWrapper}>
            <TikTokSVG />
          </div>
          <span>TikTok</span>
        </h2>
        {formik.values.socialPlatforms.includes('tiktok') && (
          <div className={styles.tagsContainerWrapper}>
            <h3>Placements</h3>
            <TagsContainer
              platform={'tiktok'}
              categories={tikTokDeliverableOptions}
              deliverables={formik.values.deliverables}
              selectCategory={selectPlacement}
              selectQuantity={selectQuantity}
            />
          </div>
        )}
        {formik.errors.deliverablesTiktok && formik.touched.deliverablesTiktok && formik.values.socialPlatforms.includes('tiktok') && (
          <div className={cs(styles.errorContainer, styles.platformsError)}>{formik.errors.deliverablesTiktok}</div>
        )}
      </div>

      <div className={styles.platformsItem}>
        <h2 className={styles.socialHeading} onClick={() => selectSocial('youtube')}>
          <CheckBox isActive={formik.values.socialPlatforms.includes('youtube')} />
          <div className={styles.socialIconWrapper}>
            <YoutubeSVG />
          </div>
          <span>YouTube</span>
        </h2>
        {formik.values.socialPlatforms.includes('youtube') && (
          <div className={styles.tagsContainerWrapper}>
            <h3>Placements</h3>
            <TagsContainer
              platform={'youtube'}
              categories={youTubeDeliverableOptions}
              deliverables={formik.values.deliverables}
              selectCategory={selectPlacement}
              selectQuantity={selectQuantity}
            />
          </div>
        )}
        {formik.errors.deliverablesYoutube && formik.touched.deliverablesYoutube && formik.values.socialPlatforms.includes('youtube') && (
          <div className={cs(styles.errorContainer, styles.platformsError)}>{formik.errors.deliverablesYoutube}</div>
        )}
      </div>

      {formik.errors.deliverables && (
        <div className={styles.errorContainer}>{formik.errors.deliverables}</div>
      )}
    </>
  );
}

export default DeliverablesPlatforms;
