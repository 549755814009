import React from 'react';
import lodash from 'lodash';
import styles from './TagsContainer.module.scss';
import cs from 'classnames';
import { ReactComponent as Checked } from '../../../../../assets/img/icons/checked_.svg';
import TextField from '../../../../../components/FormControls/TextField';

function TagsContainer({ categories, deliverables, selectCategory, selectQuantity, platform }) {
  return (
    <div className={styles.root}>
      {!!categories?.length && (
        <div className={styles.categories}>
          {categories.map(category => (
            <div className={styles.categoryRow} key={category.value}>
              <div
                onClick={() => selectCategory(category.value, platform)}
                className={cs(styles.category, {
                  [styles.active]: lodash.find(deliverables, { placement: category.value, platform: platform }),
                })}
              >
                {lodash.find(deliverables, { placement: category.value, platform: platform }) && <Checked />}
                {category.label}
              </div>
              {lodash.find(deliverables, { placement: category.value, platform: platform }) && (
                <div className={styles.quantityBlock}>
                  <TextField
                    max={100}
                    name={'quantity'}
                    type={'number'}
                    placeholder={'1-100'}
                    onChange={e => {
                      const value = e.target.value;
                      selectQuantity(category.value, platform, value > 100 ? 100 : value);
                    }}
                    value={lodash.find(deliverables, { placement: category.value, platform: platform }).quantity}
                    className={styles.quantityInput}
                    error={lodash.find(deliverables, { placement: category.value, platform: platform }).quantity <= 0}
                  />
                  <span className={styles.quantityLabel}>Quantity</span>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default TagsContainer;
