import React from 'react';
import LoadingContainer from '../../../../../../components/LoadingContainer';

const LoadingState = ({ index, isTablet }) => {
  return isTablet ? (
    <div
      style={{
        backgroundColor: 'white',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '15px',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <LoadingContainer width="34px" height="34px" borderRadius="5px" animationDelay={`${-1 + index * 0.1}s`} />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <LoadingContainer width="23px" height="23px" borderRadius="50%" margin="0 10px 0 0" animationDelay={`${-1 + index * 0.2}s`} />
          <LoadingContainer width="60px" height="30px" borderRadius="5px" animationDelay={`${-1 + index * 0.3}s`} />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <LoadingContainer width="50px" height="20px" borderRadius="5px" margin="0 0 5px 0" animationDelay={`${-1 + index * 0.4}s`} />
          <LoadingContainer width="120px" height="20px" borderRadius="5px" animationDelay={`${-1 + index * 0.5}s`} />
        </div>
        <div>
          <LoadingContainer width="50px" height="20px" borderRadius="5px" margin="0 0 5px 0" animationDelay={`${-1 + index * 0.4}s`} />
          <LoadingContainer width="100px" height="20px" borderRadius="5px" animationDelay={`${-1 + index * 0.5}s`} />
        </div>
        <div>
          <LoadingContainer width="50px" height="20px" borderRadius="5px" margin="0 0 5px 0" animationDelay={`${-1 + index * 0.4}s`} />
          <LoadingContainer width="120px" height="20px" borderRadius="5px" animationDelay={`${-1 + index * 0.5}s`} />
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        display: 'grid',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '50px',
        boxShadow: '0px 1px 0px rgb(78 59 139 / 15%)',
        gridTemplateColumns: 'minmax(230px, 4fr) minmax(100px, 2fr) minmax(136px, 1fr) 100px 133px 133px',
        gap: '10px',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
        <LoadingContainer width="34px" height="34px" borderRadius="5px" animationDelay={`${-1 + index * 0.1}s`} />
        <LoadingContainer width="83px" height="18px" borderRadius="5px" animationDelay={`${-1 + index * 0.2}s`} />
      </div>
      <LoadingContainer width="100px" height="18px" borderRadius="5px" animationDelay={`${-1 + index * 0.3}s`} />
      <LoadingContainer width="136px" height="28px" borderRadius="5px" animationDelay={`${-1 + index * 0.4}s`} />
      <LoadingContainer width="100px" height="28px" borderRadius="5px" animationDelay={`${-1 + index * 0.5}s`} />
      <LoadingContainer width="133px" height="28px" borderRadius="5px" animationDelay={`${-1 + index * 0.5}s`} />
      <LoadingContainer width="133px" height="28px" borderRadius="5px" animationDelay={`${-1 + index * 0.6}s`} />
    </div>
  );
};

export default LoadingState;
