import React from 'react';
import styles from './VisualsCounter.module.scss';

const VisualsCounter = ({ counterText, currentCount, totalCount }) => {
  return (
    <>
      {counterText && <div className={styles.storyInfo}>{counterText}</div>}
      <div className={styles.counter}>
        {currentCount}/{totalCount}
      </div>
    </>
  );
};

export default VisualsCounter;
