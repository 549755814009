import React from 'react';

const MembersSVG = () => {
  return (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6052 16.7162V17.0321C13.6052 17.4978 13.2394 17.8636 12.7737 17.8636C12.3081 17.8636 11.9422 17.4978 11.9422 17.0321V16.7162C11.9422 15.286 11.1107 14.0554 9.91338 13.4733C9.43111 13.2405 8.88232 13.1075 8.3169 13.1075H5.3235C3.3279 13.1075 1.69816 14.7206 1.69816 16.7162V17.0321C1.69816 17.4978 1.3323 17.8636 0.866656 17.8636C0.401016 17.8636 0.0351562 17.4978 0.0351562 17.0321V16.7162C0.0351562 13.8059 2.41325 11.4445 5.3235 11.4445H8.33353C8.49983 11.4445 8.66613 11.4445 8.81579 11.4611C9.39785 11.511 9.96327 11.6606 10.4788 11.8935C12.3247 12.725 13.6052 14.5709 13.6052 16.7162Z"
        fill="#9FA2B4"
      />
      <path
        d="M6.81832 2.99646C4.62316 2.99646 2.84375 4.77587 2.84375 6.97103C2.84375 9.16619 4.62316 10.9456 6.81832 10.9456C9.01348 10.9456 10.7929 9.16619 10.7929 6.97103C10.8095 4.7925 9.01348 2.99646 6.81832 2.99646ZM6.81832 9.29923C5.53781 9.29923 4.50675 8.25154 4.50675 6.98766C4.50675 5.70715 5.55444 4.67609 6.81832 4.67609C8.0822 4.67609 9.12989 5.72378 9.12989 6.98766C9.14652 8.26817 8.09883 9.29923 6.81832 9.29923Z"
        fill="#9FA2B4"
      />
      <path
        d="M23.0866 13.856V14.1719C23.0866 14.6376 22.7207 15.0034 22.2551 15.0034C21.7895 15.0034 21.4236 14.6376 21.4236 14.1719V13.856C21.4236 11.8604 19.7939 10.2306 17.7983 10.2306H14.7882C13.5077 10.2306 12.3935 10.8958 11.7449 11.8936C11.4456 12.3593 11.246 12.8914 11.1961 13.4735C10.7139 13.2407 10.1651 13.1076 9.59967 13.1076H9.56641C9.64956 12.5256 9.83249 11.9768 10.0819 11.4612C10.9633 9.74836 12.7427 8.56763 14.7882 8.56763H17.7983C20.7251 8.56763 23.0866 10.9457 23.0866 13.856Z"
        fill="#9FA2B4"
      />
      <path
        d="M16.3027 0.13623C14.1075 0.13623 12.3281 1.91564 12.3281 4.1108C12.3281 6.30596 14.1075 8.08537 16.3027 8.08537C18.4979 8.08537 20.2773 6.30596 20.2773 4.1108C20.2773 1.91564 18.4979 0.13623 16.3027 0.13623ZM16.3027 6.439C15.0222 6.439 13.9911 5.39131 13.9911 4.12743C13.9911 2.84692 15.0388 1.81586 16.3027 1.81586C17.5832 1.81586 18.6143 2.86355 18.6143 4.12743C18.6143 5.39131 17.5832 6.439 16.3027 6.439Z"
        fill="#9FA2B4"
      />
    </svg>
  );
};

export default MembersSVG;
