import React from 'react';
import styles from './OrgInviteModal.module.scss';
import { Modal } from '..';
import Button from '../../../expert/components/Buttons/ButtonCustom';

const OrgInviteModal = ({ onSubmit, orgName }) => {
  return (
    <div className={styles.root}>
      <div className={styles.description}>You have received an invitation to join “{orgName}”</div>
      <div className={styles.navigationBtns}>
        <Button className={styles.btn} outline onClick={() => onSubmit(false)}>
          Cancel
        </Button>
        <Button className={styles.btn} onClick={() => onSubmit(true)}>
          Accept
        </Button>
      </div>
    </div>
  );
};

export default props => {
  return (
    <Modal {...props} maxWidth={394}>
      <OrgInviteModal {...props} />
    </Modal>
  );
};
