import React, { useState } from 'react';
import ChoosePlanModal from '../../../Modals/ChoosePlanModal';
import NotificationFBDisconnectionModal from '../../../Modals/NotificationFBDisconnection';
import DraftedTestModalContent from '../../../Modals/DraftedTestModal';
import DraftGenerativeModal from '../../../Modals/DraftGenerativeModal';
import UserLeaveFlowModal from '../../../Modals/UserLeaveFlowModal';
import { BugReportModal } from '../../../Modals/WidgetModal';
import { sendEvent } from '../../../../services/hubspot';
import * as ReactGA from 'react-ga';
import { clearDraftCreative } from '../../../../actions/generateCreatives.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

const ModalsFlowContainer = ({ renderContent, handleCloseMenu }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.data);
  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const pathname = document.location.pathname;
  const currentPlan = useSelector(state => state.user?.pricingPlan);
  const isPossibleTask = currentPlan.smart_ads < 0 || currentPlan.smart_ads > currentPlan.usage?.smart_ads;
  const history = useHistory();
  const location = useLocation();
  const [isOpenUpgradePlan, setIsOpenUpgradePlan] = useState(false);
  const [isOpenFbNotification, setIsOpenFbNotification] = useState(false);
  const [draftTestOpenModal, setDraftTestOpenModal] = useState(false);
  const [path, setPath] = useState('');
  const [isContinueGenerateModal, setIsContinueGenerateModal] = useState(false);
  const [isSaveModal, setIsSaveModal] = useState(false);
  const [openBugReport, setOpenBugReport] = useState(false);
  const fb_integration = useSelector(state => state.ad_integration.ad_integrations.find(item => item.type === 'facebook'));
  const hasFbIntegrationToken = !!fb_integration?.params?.ACCESS_TOKEN;
  const draftTest = JSON.parse(localStorage.getItem('OTTERFISH_TEST_CREATION_DRAFT_V1'))?.[currentOrg?.organization_id];
  const draftCreative = useSelector(state => state.generateCreative.draftCreative[currentOrg?.organization_id])?.templateset;

  const onCloseMenu = () => {
    if (handleCloseMenu) handleCloseMenu();
  };

  const onClickSmartAd = isStart => {
    if (!hasFbIntegrationToken) return setIsOpenFbNotification(true);
    if (!isPossibleTask) return setIsOpenUpgradePlan(true);
    if (isStart) {
      localStorage.removeItem('OTTERFISH_TEST_CREATION_DRAFT_V1');
      history.push('/create-test/creative');
    } else {
      sendEvent('activity_test_initiated');

      ReactGA.set({ userId: user.id });
      ReactGA.event({
        category: 'App',
        action: 'Button Click Create Test',
      });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'Button Click Create Test',
        UserID: user.id,
      });
      setDraftTestOpenModal(false);
      history.push('/create-test/review');
      onCloseMenu();
    }
  };

  const onClickGenerate = isStart => {
    if (isStart) {
      dispatch(clearDraftCreative());
      history.push('/my-templates', { from: location });
    } else {
      history.push('/instant-creative/generate-creatives', { from: location });
    }
    setIsContinueGenerateModal(false);
  };

  const onClickSaveCreatives = isRemove => {
    if (isRemove) dispatch(clearDraftCreative());
    if (path !== '/create-test/creative') history.push(path);
    else !!draftTest ? setDraftTestOpenModal(true) : history.push(path);
    setIsSaveModal(false);
  };

  const onClickSaveTest = isRemove => {
    if (isRemove) localStorage.removeItem('OTTERFISH_TEST_CREATION_DRAFT_V1');
    if (path !== '/instant-creative/generate-creatives') history.push(path);
    else !!draftCreative ? setIsContinueGenerateModal(true) : history.push(path);
    setIsSaveModal(false);
  };

  const redirectTo = to => {
    setPath(to);
    if ((pathname.includes('/my-templates') && !!draftCreative) || (pathname.includes('create-test') && !!draftTest)) {
      return setIsSaveModal(true);
    } else {
      if (to === '/create-test/creative' && !!draftTest) return setDraftTestOpenModal(true);
      if (to === '/my-templates' && !!draftCreative) return setIsContinueGenerateModal(true);
    }

    if (to === '/create-test/creative') {
      if (!hasFbIntegrationToken) return setIsOpenFbNotification(true);
      if (!isPossibleTask) return setIsOpenUpgradePlan(true);
    }
    history.push(to);
  };

  const isBuildCreative = pathname.includes('/instant-creative/generate-creatives') || pathname.includes('/my-templates');

  return (
    <>
      {renderContent(redirectTo, () => setOpenBugReport(true))}

      <ChoosePlanModal
        open={isOpenUpgradePlan}
        onClose={() => setIsOpenUpgradePlan(false)}
        text="Looks like you have reached the test limits for your free plan."
        submitText="Let`s upgrade your plan."
      />
      <NotificationFBDisconnectionModal open={isOpenFbNotification} onClose={() => setIsOpenFbNotification(false)} />
      <DraftedTestModalContent open={draftTestOpenModal} onSubmit={onClickSmartAd} onClose={() => setDraftTestOpenModal(false)} />
      <DraftGenerativeModal open={isContinueGenerateModal} onSubmit={onClickGenerate} onClose={() => setIsContinueGenerateModal(false)} />
      <UserLeaveFlowModal
        open={isSaveModal}
        onClose={() => setIsSaveModal(false)}
        onSubmit={isBuildCreative ? onClickSaveCreatives : onClickSaveTest}
        text={isBuildCreative ? 'You are about to leave Instant creative' : 'You are about to leave Smart ad creation'}
      />
      <BugReportModal open={openBugReport} onClose={() => setOpenBugReport(false)} />
    </>
  );
};

export default ModalsFlowContainer;
