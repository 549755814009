import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import styles from './AfterEmailConfirmation.module.scss';
import SentEmail from '../../../../components/IconsSvg/SentEmail';
import ButtonCustom from '../../../../components/Buttons/ButtonCustom';
import { letterLink, clearFailure } from '../../../../../actions/auth.actions';
import Auth from '../../../../containers/Auth';

const AfterEmailConfirmation = ({ history, loading, letterLink, failure, clearFailure }) => {
  const loginRedirect = () => {
    history.push(`/expert/login`);
  };

  const onCloseAlert = async () => {
    await clearFailure();
  };

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const id = params.get('user_id');
    const token = params.get('token');
    letterLink(id, { token: token }).then(setTimeout(() => loginRedirect(), 3000));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Auth failure={failure} onCloseAlert={onCloseAlert}>
      <div className={styles.container}>
        <div className={styles.image}>
          <SentEmail />
        </div>
        <div className={styles.textContainer}>
          <p className={styles.title}>Your email is confirmed!</p>
          <div className={styles.text}>If you was not redirected to the login page automatically, click on the button below</div>
          <ButtonCustom onClick={loginRedirect} children={'Go to Sign-In'} loading={loading} />
        </div>
      </div>
    </Auth>
  );
};

const mapStateToProps = state => ({
  loading: state.auth.letterConf.loading,
  failure: state.auth.failure,
});

const mapDispatchToProps = dispatch => ({
  letterLink: (id, token) => dispatch(letterLink(id, token)),
  clearFailure: () => dispatch(clearFailure()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AfterEmailConfirmation));
