import React from 'react';
import styles from './TabBrandDetails.module.scss';
import TextField from '../../../../../components/FormControls/TextField';
import ImagePreview from '../../../../CreativeBrain/GenerateCreatives/components/ImagePreview';
import { ReactComponent as PlusIcon } from '../../../../../assets/img/icons/plus.svg';
import brandPreviewImage from '../../../../../assets/img/brandPlaceholder.svg';
import EditorComponent from '../../../../../components/EditorComponent';
import { validateImage } from '../../../../../shared/utils/helpers';

function TabBrandDetails({ formik, logoObject, setLogoObject }) {
  const handleLogoUpload = event => {
    const reader = new FileReader();
    const file = validateImage(event.target.files[0]);
    if (!file) return;
    reader.onloadend = () => {
      setLogoObject({ file: file, fileUrl: reader.result });
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <div>
        <h3>What is your board name?</h3>
        <TextField
          name={'boardName'}
          placeholder={'Board Name'}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.boardName}
          error={formik.touched.boardName && formik.errors.boardName}
        />
      </div>
      <div>
        <h3>Brand Logo</h3>
        <div className={styles.logoWrapper}>
          {logoObject.fileUrl ? (
            <div className={styles.imagePreviewWrapper}>
              <ImagePreview src={logoObject.fileUrl} onRemoveClick={() => setLogoObject({})} />
            </div>
          ) : (
            <div className={styles.selectLogoContainer}>
              <div className={styles.selectFileVisibleContent}>
                <PlusIcon />
                <span>{'Replace it'}</span>
              </div>
              <img className={styles.brandPreviewImage} src={brandPreviewImage} alt={'Otterfish'} />
              <input type="file" accept="image/png, image/jpeg, image/jpg" onChange={handleLogoUpload} className={styles.fileInput} />
            </div>
          )}
        </div>
        <TextField
          name="brandName"
          placeholder="Brand name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.brandName}
          error={formik.touched.brandName && formik.errors.brandName}
        />
        <EditorComponent
          wrapperClassName={styles.editorWrapper}
          editorClassName={styles.editorInput}
          initialValue={formik.values.brandBackground}
          onChange={text => formik.setFieldValue('brandBackground', text)}
          placeholder={
            'Brand Background - Set the stage. Give creators some colour around your product or brand and what you want to be know for.'
          }
          onBlur={formik.handleBlur}
          error={formik.touched.brandBackground && formik.errors.brandBackground}
        />
        <div className={styles.inputWrapper}>
          <h3>What is the key messaging for this Campaign?</h3>
          <EditorComponent
            wrapperClassName={styles.editorWrapper}
            editorClassName={styles.editorInput}
            initialValue={formik.values.messaging}
            onChange={text => formik.setFieldValue('messaging', text)}
            onBlur={formik.handleBlur}
            placeholder={
              'Share any key messages you want to convey about the brand or product. Tip: Bullet points help to convey the message clearly.'
            }
            error={formik.errors.messaging}
          />
        </div>
        <div className={styles.inputWrapper}>
          <h3>Brand guidelines and Mandatories</h3>
          <EditorComponent
            wrapperClassName={styles.editorWrapper}
            editorClassName={styles.editorInput}
            initialValue={formik.values.notes}
            onChange={text => formik.setFieldValue('notes', text)}
            onBlur={formik.handleBlur}
            placeholder={'Things to think about. Questions followers may have and possible replies.'}
            error={formik.errors.notes}
          />
        </div>
      </div>
    </>
  );
}

export default TabBrandDetails;
