import React, { useEffect, useState } from 'react';
import styles from './ContentOrganizations.module.scss'
import CardOrganizations from './CardOrganizations';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOrganizations from '../LoadingOrganizations';
import NoOrganizations from '../NoOrganizations';
import { getIndustries } from '../../../actions/config.actions';

const ContentOrganizations = ({ dataOrganizations }) => {
  const dispatch = useDispatch();
  const organizationsAuth = useSelector(state => state.auth.organizations);
  const [isLoading, setLoading] = useState(true);
  const allIndustries = useSelector(state => state.config.industries.data);
  const allOrganizations = useSelector(state => state.organizations.data);
  const plans = useSelector(state => state.payment.allPlans);
  const titleNoOrg = 'You have no organizations yet';
  const titleNotFound = 'No results found';

  useEffect(() => {
    dispatch(getIndustries());
  }, []);

  useEffect(() => {
    if(!!dataOrganizations?.length && !!plans.length){
      setLoading(false);
    }
  }, [dataOrganizations, plans]);

  const getBrandingAgencies = () => {
    const idOrganizations = dataOrganizations?.map(item => item.organization_id);

    if(idOrganizations){
      const allOrg = [];
      const result = [];
      allOrganizations.forEach(item => {
        idOrganizations.forEach(key=>{
          if(key === item.id) allOrg.push(item);
        })
      })
      const allIdIndustries = allOrg?.map(item => item.type_specific_data?.industry_id);
      allIdIndustries.forEach(item => {
        if(!item) result.push(false);
        allIndustries.forEach(key => {
          if(key.id === item){
            result.push(key.name);
          }
        })
      })

      return result;
    }
  }

  if (organizationsAuth?.length === 0) return <NoOrganizations title={titleNoOrg}/>
  if (dataOrganizations?.length === 0 && !isLoading) return <NoOrganizations title={titleNotFound}/>

  return (
        <div className={styles.root}>
          {!isLoading ?
          dataOrganizations.map((item,index) =>
            <CardOrganizations
              organization={item}
              key={item.organization_id}
              nameIndustry={getBrandingAgencies()[index]}
              plans={plans}
            />
          )
            :
          [...Array(5).keys()].map(index => <LoadingOrganizations key={index} index={index}/>)
          }
        </div>
  );
};

export default ContentOrganizations;
