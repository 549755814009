import { CREATIVE_STUDIO_TYPES } from '../actions/creativeStudio.actions';

const initialState = {
  images: {
    data: [],
    failure: {
      message: '',
      name: '',
      code: '',
    },
    loading: false,
  },
  creatives: {
    data: [],
    failure: {
      message: '',
      name: '',
      code: '',
    },
    totalCount: 0,
    loading: false,
    uploadLoading: false,
    deleteLoading: false,
    updateLoading: false,
  },
  logos: {
    data: [],
    failure: {
      message: '',
      name: '',
      code: '',
    },
    loading: false,
  },
  ads: {
    data: [],
    failure: {
      message: '',
      name: '',
      code: '',
    },
    loading: false,
  },
  failure: {},
};

export default (state = initialState, { type, payload, totalCount }) => {
  switch (type) {
    case CREATIVE_STUDIO_TYPES.GET_IMAGES.REQUEST: {
      return {
        ...state,
        images: {
          ...state.images,
          loading: true,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.GET_IMAGES.SUCCESS: {
      return {
        ...state,
        images: {
          ...state.images,
          data: payload,
          loading: false,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.GET_IMAGES.FAILURE: {
      return {
        ...state,
        images: {
          ...state.images,
          loading: false,
        },
        failure: payload,
      };
    }
    case CREATIVE_STUDIO_TYPES.UPLOAD_IMAGES.REQUEST: {
      return {
        ...state,
        images: {
          ...state.images,
          loading: true,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.UPLOAD_IMAGES.SUCCESS: {
      return {
        ...state,
        images: {
          ...state.images,
          loading: false,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.UPLOAD_IMAGES.FAILURE: {
      return {
        ...state,
        images: {
          ...state.images,
          loading: false,
        },
        failure: payload,
      };
    }
    case CREATIVE_STUDIO_TYPES.DELETE_IMAGES.REQUEST: {
      return {
        ...state,
        images: {
          ...state.images,
          loading: true,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.DELETE_IMAGES.SUCCESS: {
      return {
        ...state,
        images: {
          ...state.images,
          loading: false,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.DELETE_IMAGES.FAILURE: {
      return {
        ...state,
        images: {
          ...state.images,
          loading: false,
        },
        failure: payload,
      };
    }
    case CREATIVE_STUDIO_TYPES.GET_CREATIVES.REQUEST: {
      return {
        ...state,
        creatives: {
          ...state.creatives,
          loading: true,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.GET_CREATIVES.SUCCESS: {
      return {
        ...state,
        creatives: {
          ...state.creatives,
          data: payload,
          totalCount: totalCount,
          loading: false,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.GET_CREATIVES.FAILURE: {
      return {
        ...state,
        creatives: {
          ...state.creatives,
          loading: false,
        },
        failure: payload,
      };
    }
    case CREATIVE_STUDIO_TYPES.UPLOAD_CREATIVES.REQUEST: {
      return {
        ...state,
        creatives: {
          ...state.creatives,
          loading: true,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.UPLOAD_CREATIVES.FAILURE: {
      return {
        ...state,
        creatives: {
          ...state.creatives,
          loading: false,
        },
        failure: payload,
      };
    }
    case CREATIVE_STUDIO_TYPES.UPLOAD_CREATIVES.SUCCESS: {
      return {
        ...state,
        creatives: {
          ...state.creatives,
          data: [...state.creatives.data, ...payload],
          loading: false,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.UPLOAD_CREATIVE_IMAGES.REQUEST: {
      return {
        ...state,
        creatives: {
          ...state.creatives,
          loading: true,
          uploadLoading: true,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.UPLOAD_CREATIVE_IMAGES.FAILURE: {
      return {
        ...state,
        creatives: {
          ...state.creatives,
          loading: false,
          uploadLoading: false,
        },
        failure: payload,
      };
    }
    case CREATIVE_STUDIO_TYPES.UPLOAD_CREATIVE_IMAGES.SUCCESS: {
      return {
        ...state,
        creatives: {
          ...state.creatives,
          data: [...state.creatives.data, ...payload.data.data],
          loading: false,
          uploadLoading: false,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.DELETE_CREATIVES.REQUEST: {
      return {
        ...state,
        creatives: {
          ...state.creatives,
          loading: true,
          deleteLoading: true,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.DELETE_CREATIVES.SUCCESS: {
      const data = state.creatives.data.filter(cr => !payload.includes(cr.id));
      return {
        ...state,
        creatives: {
          ...state.creatives,
          data,
          loading: false,
          deleteLoading: false,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.DELETE_CREATIVES.FAILURE: {
      return {
        ...state,
        creatives: {
          ...state.creatives,
          loading: false,
          deleteLoading: false,
        },
        failure: payload,
      };
    }
    case CREATIVE_STUDIO_TYPES.RENAME_CREATIVES.REQUEST: {
      return {
        ...state,
        creatives: {
          ...state.creatives,
          updateLoading: true,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.RENAME_CREATIVES.SUCCESS: {
      const index = state.creatives.data.findIndex(cr => payload.id === cr.id);
      const data = state.creatives.data.filter(cr => payload.id !== cr.id);
      data.splice(index, 0, payload);
      return {
        ...state,
        creatives: {
          ...state.creatives,
          data,
          updateLoading: false,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.RENAME_CREATIVES.FAILURE: {
      return {
        ...state,
        creatives: {
          ...state.creatives,
          updateLoading: false,
        },
        failure: payload,
      };
    }
    case CREATIVE_STUDIO_TYPES.RENAME_IMAGE.SUCCESS: {
      let images;
      let key;
      if (payload.type === 'image') {
        images = state.images.data;
        key = 'images';
      } else if (payload.type === 'logo') {
        images = state.logos.data;
        key = 'logos';
      }
      const index = images.findIndex(img => payload.id === img.id);
      const data = images.filter(img => payload.id !== img.id);
      data.splice(index, 0, payload);
      return {
        ...state,
        [key]: {
          ...state[key],
          data,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.GET_LOGOS.REQUEST: {
      return {
        ...state,
        logos: {
          ...state.logos,
          loading: true,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.GET_LOGOS.SUCCESS: {
      return {
        ...state,
        logos: {
          ...state.logos,
          data: payload,
          loading: false,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.GET_LOGOS.FAILURE: {
      return {
        ...state,
        logos: {
          ...state.logos,
          loading: false,
        },
        failure: payload,
      };
    }
    case CREATIVE_STUDIO_TYPES.UPLOAD_LOGOS.REQUEST: {
      return {
        ...state,
        logos: {
          ...state.logos,
          loading: true,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.UPLOAD_LOGOS.SUCCESS: {
      return {
        ...state,
        logos: {
          ...state.logos,
          loading: false,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.UPLOAD_LOGOS.FAILURE: {
      return {
        ...state,
        logos: {
          ...state.logos,
          loading: false,
        },
        failure: payload,
      };
    }
    case CREATIVE_STUDIO_TYPES.DELETE_LOGOS.REQUEST: {
      return {
        ...state,
        logos: {
          ...state.logos,
          loading: true,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.DELETE_LOGOS.SUCCESS: {
      return {
        ...state,
        logos: {
          ...state.logos,
          loading: false,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.DELETE_LOGOS.FAILURE: {
      return {
        ...state,
        logos: {
          ...state.logos,
          loading: false,
        },
        failure: payload,
      };
    }
    case CREATIVE_STUDIO_TYPES.GET_ADS.REQUEST: {
      return {
        ...state,
        ads: {
          ...state.ads,
          loading: true,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.GET_ADS.SUCCESS: {
      return {
        ...state,
        ads: {
          ...state.ads,
          data: payload,
          loading: false,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.GET_ADS.FAILURE: {
      return {
        ...state,
        ads: {
          ...state.ads,
          loading: false,
        },
        failure: payload,
      };
    }
    case CREATIVE_STUDIO_TYPES.UPLOAD_AD.REQUEST: {
      return {
        ...state,
        ads: {
          ...state.ads,
          loading: true,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.UPLOAD_AD.SUCCESS: {
      return {
        ...state,
        ads: {
          ...state.ads,
          data: [...state.ads.data, ...payload],
          loading: false,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.UPLOAD_AD.FAILURE: {
      return {
        ...state,
        ads: {
          ...state.ads,
          loading: false,
        },
        failure: payload,
      };
    }
    case CREATIVE_STUDIO_TYPES.DELETE_AD.REQUEST: {
      return {
        ...state,
        ads: {
          ...state.ads,
          loading: true,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.DELETE_AD.SUCCESS: {
      const data = state.ads.data.filter(cr => !payload.includes(cr.id));
      return {
        ...state,
        ads: {
          ...state.ads,
          data,
          loading: false,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.DELETE_AD.FAILURE: {
      return {
        ...state,
        ads: {
          ...state.ads,
          loading: false,
        },
        failure: payload,
      };
    }
    case CREATIVE_STUDIO_TYPES.EDIT_AD.REQUEST: {
      return {
        ...state,
        ads: {
          ...state.ads,
          loading: true,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.EDIT_AD.SUCCESS: {
      const index = state.ads.data.findIndex(cr => payload.id === cr.id);
      const data = state.ads.data.filter(cr => payload.id !== cr.id);
      data.splice(index, 0, payload);
      return {
        ...state,
        ads: {
          ...state.ads,
          data,
          loading: false,
        },
      };
    }
    case CREATIVE_STUDIO_TYPES.EDIT_AD.FAILURE: {
      return {
        ...state,
        ads: {
          ...state.ads,
          loading: false,
        },
        failure: payload,
      };
    }
    case CREATIVE_STUDIO_TYPES.CLEAR_FAILURE: {
      return {
        ...state,
        failure: {},
      };
    }
    default: {
      return state;
    }
  }
};
