import * as contentApprovalServices from '../services/contentApproval';

export const CONTENT_APPROVAL = {
  GET_CONTENT_BOARDS: {
    REQUEST: 'GET_CONTENT_BOARDS_REQUEST',
    SUCCESS: 'GET_CONTENT_BOARDS_SUCCESS',
    FAILURE: 'GET_CONTENT_BOARDS_FAILURE',
  },
  GET_CONTENT_BOARD: {
    REQUEST: 'GET_CONTENT_BOARD_REQUEST',
    SUCCESS: 'GET_CONTENT_BOARD_SUCCESS',
    FAILURE: 'GET_CONTENT_BOARD_FAILURE',
  },
  GET_BOARD_COLLABORATORS: {
    REQUEST: 'GET_BOARD_COLLABORATORS_REQUEST',
    SUCCESS: 'GET_BOARD_COLLABORATORS_SUCCESS',
    FAILURE: 'GET_BOARD_COLLABORATORS_FAILURE',
  },
  GET_BOARD_INVITED_COLLABORATORS: {
    REQUEST: 'GET_BOARD_INVITED_COLLABORATORS_REQUEST',
    SUCCESS: 'GET_BOARD_INVITED_COLLABORATORS_SUCCESS',
    FAILURE: 'GET_BOARD_INVITED_COLLABORATORS_FAILURE',
  },
  GET_BOARD_DELIVERABLES: {
    REQUEST: 'GET_BOARD_DELIVERABLES_REQUEST',
    SUCCESS: 'GET_BOARD_DELIVERABLES_SUCCESS',
    FAILURE: 'GET_BOARD_DELIVERABLES_FAILURE',
  },
  GET_BOARD_DELIVERABLE_ITEM: {
    REQUEST: 'GET_BOARD_DELIVERABLE_ITEM_REQUEST',
    SUCCESS: 'GET_BOARD_DELIVERABLE_ITEM_SUCCESS',
    FAILURE: 'GET_BOARD_DELIVERABLE_ITEM_FAILURE',
  },
  UPDATE_DELIVERABLE_ITEM: {
    REQUEST: 'UPDATE_DELIVERABLE_ITEM_REQUEST',
    SUCCESS: 'UPDATE_DELIVERABLE_ITEM_SUCCESS',
    FAILURE: 'UPDATE_DELIVERABLE_ITEM_FAILURE',
  },
  APPROVE_DELIVERABLE: {
    REQUEST: 'APPROVE_DELIVERABLE_REQUEST',
    SUCCESS: 'APPROVE_DELIVERABLE_SUCCESS',
    FAILURE: 'APPROVE_DELIVERABLE_FAILURE',
  },
  GET_DELIVERABLE_APPROVALS: {
    REQUEST: 'GET_DELIVERABLE_APPROVALS_REQUEST',
    SUCCESS: 'GET_DELIVERABLE_APPROVALS_SUCCESS',
    FAILURE: 'GET_DELIVERABLE_APPROVALS_FAILURE',
  },
  CLEAR_CONTENT_BOARDS: 'CLEAR_CONTENT_BOARDS',
  CLEAR_OPENED_DELIVERABLE_ITEM: 'CLEAR_OPENED_DELIVERABLE_ITEM',
  CLEAR_OPENED_CONTENT_BOARD: 'CLEAR_OPENED_CONTENT_BOARD',
  CLEAR_CONTENT_APPROVAL_ERRORS: 'CLEAR_CONTENT_APPROVAL_ERRORS',
  CREATE_BOARD: {
    REQUEST: 'CREATE_BOARD_REQUEST',
    SUCCESS: 'CREATE_BOARD_SUCCESS',
    FAILURE: 'CREATE_BOARD_FAILURE',
  },
  UPDATE_OPENED_CONTENT_BOARD: 'UPDATE_OPENED_CONTENT_BOARD',
  UPDATE_CONTENT_APPROVAL_LIST_BOARD: 'UPDATE_CONTENT_APPROVAL_LIST_BOARD',
  UPDATE_OPENED_DELIVERABLE_ITEM: 'UPDATE_OPENED_DELIVERABLE_ITEM',
  UPDATE_BOARD_DELIVERABLE_ITEM: 'UPDATE_BOARD_DELIVERABLE_ITEM',
  SET_CONTENT_APPROVAL_TAB_SWITCHER: 'SET_CONTENT_APPROVAL_TAB_SWITCHER',
};

const mutateItemInList = (list, item) => {
  let isChanged = false;
  const newList = list.map(listItem => {
    if (listItem.id === item.id) {
      isChanged = true;
      return Object.assign(listItem, item);
    }
    return listItem;
  });
  return { newList, isChanged };
};

export const setContentApprovalTab = switcher => ({ type: CONTENT_APPROVAL.SET_CONTENT_APPROVAL_TAB_SWITCHER, payload: switcher });

export const clearOpenedDeliverableItem = () => ({ type: CONTENT_APPROVAL.CLEAR_OPENED_DELIVERABLE_ITEM });

export const clearContentBoard = () => ({ type: CONTENT_APPROVAL.CLEAR_OPENED_CONTENT_BOARD });

export const clearContentApprovalErrors = () => ({ type: CONTENT_APPROVAL.CLEAR_CONTENT_APPROVAL_ERRORS });

export const clearContentBoardsList = () => ({ type: CONTENT_APPROVAL.UPDATE_CONTENT_APPROVAL_LIST_BOARD, payload: [] });

export const getContentBoards =
  (organizationId, params = {}) =>
  async dispatch => {
    try {
      dispatch({ type: CONTENT_APPROVAL.GET_CONTENT_BOARDS.REQUEST });

      const boardsParams = params.is_archived ? { is_archived: false } : { ...params, is_archived: false };
      const archivedParams = params.is_archived ? { ...params, is_archived: true } : { is_archived: true };

      const boards = await contentApprovalServices.getContentBoards(organizationId, boardsParams);
      const archived = await contentApprovalServices.getContentBoards(organizationId, archivedParams);

      const isNoSearchFiltersYet =
        !params.search_filter && !params.status && !params.approver_id && !params.date_start && !params.date_end && !params.is_archived;

      let currentBoards;
      if (params.is_archived) {
        currentBoards = archived.data;
      } else if (archived.data.length && !boards.data.length && isNoSearchFiltersYet) {
        currentBoards = archived.data;
        dispatch(setContentApprovalTab('archived'));
      } else {
        currentBoards = boards.data;
      }

      dispatch({
        type: CONTENT_APPROVAL.GET_CONTENT_BOARDS.SUCCESS,
        payload: currentBoards,
        totalCount: boards.total_count,
        archivedTotalCount: archived.total_count,
      });
    } catch (e) {
      dispatch({ type: CONTENT_APPROVAL.GET_CONTENT_BOARDS.FAILURE });
    }
  };

export const archiveContentBoard = (organizationId, boardId) => async dispatch => {
  dispatch({ type: CONTENT_APPROVAL.GET_CONTENT_BOARDS.REQUEST });
  try {
    await contentApprovalServices.archiveBoard(organizationId, boardId);
    dispatch({ type: CONTENT_APPROVAL.GET_CONTENT_BOARDS.REQUEST, loading: false });
    return true;
  } catch (e) {
    dispatch({ type: CONTENT_APPROVAL.GET_CONTENT_BOARDS.FAILURE });
  }
};

export const unzipContentBoard = (organizationId, boardId) => async dispatch => {
  dispatch({ type: CONTENT_APPROVAL.GET_CONTENT_BOARDS.REQUEST });
  try {
    await contentApprovalServices.unzipBoard(organizationId, boardId);
    dispatch({ type: CONTENT_APPROVAL.GET_CONTENT_BOARDS.REQUEST, loading: false });
    return true;
  } catch (e) {
    dispatch({ type: CONTENT_APPROVAL.GET_CONTENT_BOARDS.FAILURE });
  }
};

export const getContentBoard = (organizationId, boardId) => async dispatch => {
  try {
    dispatch({ type: CONTENT_APPROVAL.GET_CONTENT_BOARD.REQUEST });
    const response = await contentApprovalServices.getContentBoard(organizationId, +boardId);

    dispatch({
      type: CONTENT_APPROVAL.GET_CONTENT_BOARD.SUCCESS,
      payload: response.data[0],
    });
  } catch (e) {
    dispatch({ type: CONTENT_APPROVAL.GET_CONTENT_BOARD.FAILURE, payload: e.message });
  }
};

export const getBoardCollaborators = (organizationId, boardId) => async dispatch => {
  try {
    dispatch({ type: CONTENT_APPROVAL.GET_BOARD_COLLABORATORS.REQUEST });

    const params = {};
    const response = await contentApprovalServices.getBoardCollaborators(organizationId, +boardId, params);

    dispatch({
      type: CONTENT_APPROVAL.GET_BOARD_COLLABORATORS.SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({ type: CONTENT_APPROVAL.GET_BOARD_COLLABORATORS.FAILURE });
  }
};

export const getBoardDeliverables =
  (organizationId, boardId, page, pageSize, sort, searchFilter = '') =>
  async dispatch => {
    try {
      dispatch({ type: CONTENT_APPROVAL.GET_BOARD_DELIVERABLES.REQUEST });

      const params = {};
      if (searchFilter) params.search_filter = searchFilter;
      if (page) params.page = page;
      if (pageSize) params.page_size = pageSize;
      if (sort?.order_by && sort?.order_type) {
        params.order_by = sort.order_by;
        params.order_type = sort.order_type;
      }

      const response = await contentApprovalServices.getBoardDeliverables(organizationId, +boardId, params);

      dispatch({
        type: CONTENT_APPROVAL.GET_BOARD_DELIVERABLES.SUCCESS,
        payload: response.data,
        totalCount: response.total_count,
        approvedCount: response.total_approved,
      });
    } catch (e) {
      dispatch({ type: CONTENT_APPROVAL.GET_BOARD_DELIVERABLES.FAILURE });
    }
  };

export const getBoardDeliverableItem = (organizationId, boardId, deliverableId) => async dispatch => {
  try {
    dispatch({ type: CONTENT_APPROVAL.GET_BOARD_DELIVERABLE_ITEM.REQUEST });

    const response = await contentApprovalServices.getBoardDeliverableItem(organizationId, +boardId, +deliverableId);

    dispatch({
      type: CONTENT_APPROVAL.GET_BOARD_DELIVERABLE_ITEM.SUCCESS,
      payload: response.data[0],
    });
  } catch (e) {
    dispatch({ type: CONTENT_APPROVAL.GET_BOARD_DELIVERABLE_ITEM.FAILURE, payload: e.message });
  }
};

export const submitDeliverableItemSave = (organizationId, updates, boardId, deliverableId) => async (dispatch, getState) => {
  dispatch({ type: CONTENT_APPROVAL.UPDATE_DELIVERABLE_ITEM.REQUEST });

  let requestCount = 0;
  let responseCount = 0;
  const board = getState().contentApproval.openedBoard;
  const deliverable = getState().contentApproval.openedDeliverable;
  const userId = getState().auth.user.id;

  const checkIsAllRequestsEnded = () => {
    responseCount++;
    if (requestCount === responseCount) {
      dispatch({ type: CONTENT_APPROVAL.UPDATE_DELIVERABLE_ITEM.SUCCESS });
      dispatch(getBoardDeliverableItem(organizationId, boardId, deliverableId));
      dispatch(getDeliverableApprovals(organizationId, boardId, deliverableId));
    }
  };

  const handleResponse = (response, trackEvent) => {
    if (response instanceof Error) {
      dispatch({ type: CONTENT_APPROVAL.UPDATE_DELIVERABLE_ITEM.FAILURE, payload: response.message, loading: true });
    }
    if (trackEvent === 'upload_content') {
      window.analytics.track('content_upload', {
        category: 'Content Deliverables',
        label: deliverable.name,
        userId: userId,
        creator_brief_id: board.campaign_brief_item_id,
        content_board_id: board.id,
        campaign_brief_id: board.campaign_brief_parent_id,
        deliverable_id: deliverable.id,
        status: deliverable.status_content,
      });
    }
    checkIsAllRequestsEnded();
  };

  try {
    if (updates.deliverable) {
      requestCount++;
      contentApprovalServices.updateDeliverableItem(organizationId, boardId, deliverableId, updates.deliverable).then(handleResponse);
    }

    if (updates.updateAssets) {
      for (const asset of updates.updateAssets) {
        requestCount++;
        contentApprovalServices
          .updateDeliverableItemAsset(organizationId, boardId, deliverableId, asset.id, {
            caption: asset.caption,
          })
          .then(handleResponse);
      }
    }

    if (updates.deleteAssets) {
      for (const asset of updates.deleteAssets) {
        requestCount++;
        contentApprovalServices.deleteDeliverableItemAsset(organizationId, boardId, deliverableId, asset.id).then(handleResponse);
      }
    }

    if (updates.createAssets) {
      const deliverableTypes = updates.createAssets.filter(a => a.type === 'deliverable');
      const conceptTypes = updates.createAssets.filter(a => a.type === 'visual_concept');

      if (deliverableTypes.length) {
        requestCount++;
        const startIndex = getNewDeliverableAssetStartIndex(updates.visualAssets);
        contentApprovalServices
          .createDeliverableItemAsset(organizationId, boardId, deliverableId, deliverableTypes, startIndex)
          .then(res => handleResponse(res, 'upload_content'));
      }

      if (conceptTypes.length) {
        requestCount++;
        const startIndex = getNewDeliverableAssetStartIndex(updates.visualConcepts);
        contentApprovalServices
          .createDeliverableItemAsset(organizationId, boardId, deliverableId, conceptTypes, startIndex)
          .then(handleResponse);
      }
    }

    if (requestCount === 0) {
      dispatch({ type: CONTENT_APPROVAL.UPDATE_DELIVERABLE_ITEM.SUCCESS });
      dispatch(getBoardDeliverableItem(organizationId, boardId, deliverableId));
    }
  } catch (e) {
    dispatch({ type: CONTENT_APPROVAL.UPDATE_DELIVERABLE_ITEM.FAILURE, payload: e.message, loading: false });
    dispatch(getBoardDeliverableItem(organizationId, boardId, deliverableId));
  }
};

const getNewDeliverableAssetStartIndex = assets => {
  const sortedByOrder = assets?.filter(asset => asset.display_order).sort((a, b) => b.display_order - a.display_order);
  let startIndex = 1;
  if (sortedByOrder?.length) {
    startIndex = sortedByOrder[0].display_order + 1;
  }
  return startIndex;
};

export const approveDeliverableItem = (organizationId, boardId, deliverableId, type) => async (dispatch, getState) => {
  dispatch({ type: CONTENT_APPROVAL.APPROVE_DELIVERABLE.REQUEST });
  const board = getState().contentApproval.openedBoard;
  const deliverable = getState().contentApproval.openedDeliverable;
  const userId = getState().auth.user.id;
  try {
    const data = { type: type || null };
    await contentApprovalServices.approveDeliverableItem(organizationId, boardId, deliverableId, data).then(response => {
      if (type === 'content') {
        window.analytics.track('content_approved', {
          category: 'Content Deliverables',
          label: deliverable.name,
          userId: userId,
          creator_brief_id: board.campaign_brief_item_id,
          content_board_id: board.id,
          deliverable_id: deliverable.id,
          status: 'approved',
        });
      } else if (type === 'concept') {
        window.analytics.track('concept_approved', {
          category: 'Content Deliverables',
          label: deliverable.name,
          userId: userId,
          creator_brief_id: board.campaign_brief_item_id,
          content_board_id: board.id,
          deliverable_id: deliverable.id,
          status: 'approved',
        });
      }
    });
    dispatch(getBoardDeliverableItem(organizationId, boardId, deliverableId));
    dispatch(getDeliverableApprovals(organizationId, boardId, deliverableId));
    dispatch({ type: CONTENT_APPROVAL.APPROVE_DELIVERABLE.SUCCESS });
  } catch (e) {
    dispatch({ type: CONTENT_APPROVAL.APPROVE_DELIVERABLE.FAILURE, payload: 'Approve failed.' + ' ' + e.message });
  }
};

export const getDeliverableApprovals = (organizationId, boardId, deliverableId) => async dispatch => {
  dispatch({ type: CONTENT_APPROVAL.GET_DELIVERABLE_APPROVALS.REQUEST });
  try {
    const response = await contentApprovalServices.getDeliverableApprovals(organizationId, boardId, deliverableId);
    dispatch({ type: CONTENT_APPROVAL.GET_DELIVERABLE_APPROVALS.SUCCESS, payload: response.data });
  } catch (e) {
    dispatch({ type: CONTENT_APPROVAL.GET_DELIVERABLE_APPROVALS.FAILURE, payload: e.message });
  }
};

export const createBoard = (organizationID, boardData, inviteData, deliverables) => dispatch => {
  return new Promise(async resolve => {
    dispatch({ type: CONTENT_APPROVAL.CREATE_BOARD.REQUEST });
    contentApprovalServices.createBoard(organizationID, boardData).then(res => {
      const boardId = res.data[0].id;

      window.analytics.track('create_board', {
        category: 'content board',
        label: res.data[0].name,
        userId: res.data[0].creator_id,
        company_type: boardData.initiator_organization_type,
        creator_brief_id: null,
        campaign_brief_id: null,
        content_board_id: res.data[0].id,
        source: 'stand_alone',
        status: 'in progress',
      });

      if (boardId) {
        const requestCount = (inviteData ? 1 : 0) + deliverables.reduce((acc, item) => acc + +item.quantity, 0);
        let responseCount = 0;

        const checkResponse = (inviteResp, trackEvent) => {
          if (trackEvent) {
            window.analytics.track('content_board_invite_sent', {
              category: 'Invite',
              label: res.data[0].name,
              userId: res.data[0].creator_id,
              invite_id: inviteResp.data[0].id,
              content_board_id: res.data[0].id,
              company_type: inviteData.organization_type,
              email: inviteData.email,
            });
          }
          responseCount++;
          if (requestCount === responseCount) {
            dispatch({ type: CONTENT_APPROVAL.CREATE_BOARD.SUCCESS });
            resolve(res.data[0]);
          }
        };

        if (inviteData) {
          contentApprovalServices
            .inviteBoardCollaborator(organizationID, boardId, inviteData)
            .then(inviteResp => checkResponse(inviteResp, 'invite'))
            .catch(checkResponse);
        }

        let deliverableIndex = 1;
        for (const deliverable of deliverables) {
          for (let i = 0; i < deliverable.quantity; i++) {
            contentApprovalServices
              .createBoardDeliverable(organizationID, boardId, {
                name: `deliverable ${String(deliverableIndex).padStart(3, '0')}`,
                platform: deliverable.platform,
                placement: deliverable.placement,
              })
              .then(checkResponse)
              .catch(checkResponse);
            deliverableIndex++;
          }
        }
      }
    });
  });
};

export const inviteBoardCollaborator = (organizationID, boardId, inviteData) => (dispatch, getState) => {
  const board = getState().contentApproval.openedBoard;
  const userId = getState().auth.user.id;
  contentApprovalServices
    .inviteBoardCollaborator(organizationID, boardId, {
      email: inviteData.email,
      organization_type: inviteData.organizationType?.type || inviteData.new_organization_type,
      custom_message: inviteData.message || null,
    })
    .then(inviteResp => {
      window.analytics.track('content_board_invite_sent', {
        category: 'Invite',
        label: board.name,
        userId: userId,
        invite_id: inviteResp.data[0].id,
        content_board_id: board.id,
        company_type: inviteData.organizationType?.type || inviteData.new_organization_type,
        email: inviteData.email,
      });
      dispatch(getInvitedBoardCollaborators(organizationID, boardId));
    });
};

export const getInvitedBoardCollaborators = (organizationID, boardId) => async dispatch => {
  dispatch({ type: CONTENT_APPROVAL.GET_BOARD_INVITED_COLLABORATORS.REQUEST });
  try {
    const response = await contentApprovalServices.getInvitedBoardCollaborators(organizationID, boardId);
    dispatch({ type: CONTENT_APPROVAL.GET_BOARD_INVITED_COLLABORATORS.SUCCESS, payload: response.data });
  } catch (e) {
    dispatch({ type: CONTENT_APPROVAL.GET_BOARD_INVITED_COLLABORATORS.FAILURE, payload: e.message });
  }
};

export const deleteBoardCollaborator = (organizationID, boardId, collaboratorOrganizationId) => dispatch => {
  dispatch({ type: CONTENT_APPROVAL.GET_BOARD_COLLABORATORS.REQUEST });
  contentApprovalServices.deleteBoardCollaborator(organizationID, boardId, collaboratorOrganizationId).then(response => {
    dispatch(getBoardCollaborators(organizationID, boardId));
  });
};

export const deleteInvitedBoardCollaborator = (organizationID, boardId, invitedId) => dispatch => {
  dispatch({ type: CONTENT_APPROVAL.GET_BOARD_INVITED_COLLABORATORS.REQUEST });
  contentApprovalServices.deleteInvitedBoardCollaborator(organizationID, boardId, invitedId).then(response => {
    dispatch(getInvitedBoardCollaborators(organizationID, boardId));
  });
};

export const updateOpenedDeliverable = deliverable => ({ type: CONTENT_APPROVAL.UPDATE_OPENED_DELIVERABLE_ITEM, payload: deliverable });

export const updateBoardDeliverableItem = deliverable => (dispatch, getState) => {
  const deliverables = getState().contentApproval.deliverables;
  if (deliverables.length) {
    const { isChanged, newList } = mutateItemInList(deliverables, deliverable);
    if (isChanged) {
      dispatch({ type: CONTENT_APPROVAL.UPDATE_BOARD_DELIVERABLE_ITEM, payload: newList });
    }
  }
};

export const updateOpenedContentBoard = contentBoard => ({ type: CONTENT_APPROVAL.UPDATE_OPENED_CONTENT_BOARD, payload: contentBoard });

export const updateContentApprovalListBoard = contentBoard => (dispatch, getState) => {
  const boards = getState().contentApproval.boards;
  if (boards.length) {
    const { isChanged, newList } = mutateItemInList(boards, contentBoard);
    if (isChanged) {
      dispatch({ type: CONTENT_APPROVAL.UPDATE_CONTENT_APPROVAL_LIST_BOARD, payload: newList });
    }
  }
};
