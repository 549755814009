import { getTemplates } from '../services/creativeTemplates';

export const CREATIVE_TEMPLATE_TYPES = {
  GET_CREATIVE_TEMPLATES: {
    REQUEST: 'GET_CREATIVE_TEMPLATES_REQUEST',
    SUCCESS: 'GET_CREATIVE_TEMPLATES_SUCCESS',
    FAILURE: 'GET_CREATIVE_TEMPLATES_FAILURE',
  }
}

export const getCreativeTemplates = setId => async (dispatch, getState) => {
  try {
    dispatch({
      type: CREATIVE_TEMPLATE_TYPES.GET_CREATIVE_TEMPLATES.REQUEST,
    });

    const items = await getTemplates(setId);

    dispatch({
      type: CREATIVE_TEMPLATE_TYPES.GET_CREATIVE_TEMPLATES.SUCCESS,
      payload: items.data,
    });
    return items.data;
  } catch (error) {
    dispatch({
      type:  CREATIVE_TEMPLATE_TYPES.GET_CREATIVE_TEMPLATES.FAILURE,
      payload: error,
    });
  }
};
