import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const initialState = {
  current: [],
  delete: [],
  update: [],
  create: [],
  isDelete: false,
  isUpdate: false,
  isCreate: false,
};

const useAssetsCRUDState = type => {
  const { openedDeliverable, deliverableLoading } = useSelector(crudState => crudState.contentApproval);
  const [crudState, setCrudState] = useState(initialState);
  const [assetsWithoutFile, setAssetsWithoutFile] = useState([]);

  useEffect(() => {
    if (openedDeliverable && !deliverableLoading) {
      const objectsArray = openedDeliverable.assets.filter(asset => asset.type === type);
      setCrudState({
        ...initialState,
        current: [...objectsArray],
      });
    }
  }, [openedDeliverable]);

  const filter = (array, item) => {
    return array.filter(i => i.id !== item.id);
  };

  const onCreate = object => {
    const newObject = { ...object };
    newObject.type = type;
    setCrudState({
      ...crudState,
      isCreate: true,
      current: [...crudState.current, newObject],
      create: [...crudState.create, newObject],
    });
  };

  const onUpdate = object => {
    const newObject = { ...object };
    const isValidUpdateObject = typeof newObject.id !== 'string';
    const newUpdateObjects = [...filter(crudState.update, newObject)];
    if (isValidUpdateObject) {
      newUpdateObjects.push(newObject);
    }

    setCrudState({
      ...crudState,
      isUpdate: !!newUpdateObjects.length,
      current: crudState.current.map(obj => {
        if (obj.id === newObject.id) return newObject;
        return obj;
      }),
      create: crudState.create.map(obj => {
        if (obj.id === newObject.id) return newObject;
        return obj;
      }),
      update: newUpdateObjects,
    });

    if (assetsWithoutFile.includes(newObject.id) && !!newObject.file) {
      setAssetsWithoutFile(assetsWithoutFile.filter(id => id !== newObject.id));
    }
  };

  const onDelete = object => {
    const isValidDeleteObject = typeof object.id !== 'string';
    const newDeleteObjects = [...crudState.delete];
    if (isValidDeleteObject) {
      newDeleteObjects.push(object);
    }

    const newCreateObjects = filter(crudState.create, object);
    const newUpdateObjects = filter(crudState.update, object);

    setCrudState({
      ...crudState,
      isDelete: !!newDeleteObjects.length,
      isCreate: !!newCreateObjects.length,
      isUpdate: !!newUpdateObjects.length,
      current: filter(crudState.current, object),
      create: newCreateObjects,
      update: newUpdateObjects,
      delete: newDeleteObjects,
    });
  };

  return { crudState, onCreate, onUpdate, onDelete, assetsWithoutFile, setAssetsWithoutFile };
};

export default useAssetsCRUDState;
