import React, { useEffect, useRef, useState } from 'react';
import { loadingImage } from '../../../../../shared/utils/helpers';
import styles from './ImagePreview.module.scss';
import { ReactComponent as CloseIcon } from '../../../../../assets/img/icons/close.svg';
import NewDefaultSwitcher from '../../../../../expert/components/NewDefaultSwitcher';
import LoadingContainer from '../../../../../components/LoadingContainer';
import ChessBackground from '../../../../../components/ChessBackground';

const ImagePreview = ({ index, src, onRemoveClick }) => {
  const [isVisible, setIsVisible] = useState(false);
  const componentMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setIsVisible(false);
    loadingImage(src, () => {
      // if component still mounted
      if (componentMounted.current) {
        setIsVisible(true);
      }
    });
  }, [src]);

  return (
    <div className={styles.imageWrapper}>
      {isVisible ? (
        <>
          <img className={styles.image} src={src} alt={'Preview'} />
          <ChessBackground className={styles.chess} />
          <div className={styles.closeIcon} onClick={onRemoveClick}>
            <CloseIcon />
          </div>
        </>
      ) : (
        <div className={styles.loader}>
          <LoadingContainer borderRadius="3px" animationDelay={`${-1 + index * 0.2}s`} />
        </div>
      )}
    </div>
  );
};

export default ImagePreview;
