import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSubscribeChannel from '../../../../centrifuge/useSubscribeChannel';
import { updateOpenedDeliverable } from '../../../../actions/contentApproval.actions';

const SUBSCRIBE_ACTIONS = {
  CONTENT_BOARD_DELIVERABLE_REFRESH: 'content_board_deliverable_refresh',
};

const useDeliverableSockets = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const JWT = useSelector(state => state.auth?.token?.access_token);
  const deliverableID = useMemo(() => +params.deliverableId, [params.deliverableId]);
  const boardID = useMemo(() => +params.boardId, [params.boardId]);

  useSubscribeChannel(deliverableID, {
    channel: `content_board_deliverable:${deliverableID}`,
    events: ctx => {
      switch (ctx.data.action) {
        case SUBSCRIBE_ACTIONS.CONTENT_BOARD_DELIVERABLE_REFRESH: {
          dispatch(updateOpenedDeliverable(ctx.data.data));
          break;
        }
      }
    },
    opts: {
      data: { organization_id: currentOrganizationID, token: JWT, content_board_id: boardID, content_board_deliverable_id: deliverableID },
    },
    // onError: err => console.error('Centrifuge subscribe error: ', err),
    // onSubscribe: ctx => console.log('+ subscribe deliverable', ctx.channel),
    // onUnsubscribe: ctx => console.log('- unsubscribe deliverable', ctx.channel),
  });
};

export default useDeliverableSockets;
