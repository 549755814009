import React from 'react';
import styles from './SelectInputArrowIcon.module.scss';
import { ReactComponent as IconArrow } from '../../../../assets/img/icons/play-white-arrow.svg';

const SelectInputArrowIcon = () => {
  return (
    <span className={styles.root}>
      <IconArrow />
    </span>
  );
};

export default SelectInputArrowIcon;
