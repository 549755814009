import React from 'react';

const LinkText = ({type, classname}) => {
  return (
    <span>
      <a
        href="https://help.otterfish.com/knowledge/how-to-add-images-logos-to-otterfish"
        className={classname}
        target="_blank" rel="noreferrer"
        onClick={(e) => e.stopPropagation()}
      >
        Click here
      </a>
      &nbsp;for best practices with {type}.
    </span>
  );
};

export default LinkText;
