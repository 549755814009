import { CREATIVE_TEMPLATE_TYPES } from '../actions/creativeTemplates.actions';

const initialState = {
  items: [],
  failure: null,
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATIVE_TEMPLATE_TYPES.GET_CREATIVE_TEMPLATES.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case CREATIVE_TEMPLATE_TYPES.GET_CREATIVE_TEMPLATES.SUCCESS: {
      return {
        ...state,
        items: action.payload,
        loading: false,
      };
    }
    case CREATIVE_TEMPLATE_TYPES.GET_CREATIVE_TEMPLATES.FAILURE: {
      return {
        ...state,
        failure: action.payload,
        loading: false,
      };
    }
    case 'SET_CREATIVE_TEMPLATES_LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case 'SET_CREATIVE_TEMPLATES_FAILURE': {
      return {
        ...state,
        failure: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
