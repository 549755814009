import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Section from '../../CampaignBriefPage/components/Section';
import styles from './VisualDirectionsSection.module.scss';
import ButtonCustom from '../../../../expert/components/Buttons/ButtonCustom';
import { AddIcon } from '../../../../expert/components/IconsSvg/ReactIcons/ReactIcons';
import VisualElement from '../../CampaignBriefPage/components/VisualElement';
import AddVisualModal from '../../CampaignBriefPage/components/AddVisualModal';
import { deleteBriefVisualAsset } from '../../../../actions/brief.actions';
import DownloadAllButton from '../../../ContentApproval/components/DownloadAllButton';
import { useMediaQuery } from 'react-responsive/src';

const VisualDirectionsSection = ({ showAddVisualButton = true }) => {
  const dispatch = useDispatch();
  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const currentBrief = useSelector(state => state.brief.currentBrief.data);
  const currentBriefVisualAssets = useSelector(state => state.brief.currentBrief.visualAssets);
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  const [addVisualModalState, setAddVisualModalState] = useState(false);

  const deleteVisual = assetId => {
    dispatch(deleteBriefVisualAsset(currentOrganizationID, currentBrief.id, assetId));
  };

  return (
    <>
      <AddVisualModal
        modalState={addVisualModalState}
        setModalState={setAddVisualModalState}
        currentCountVisualAssets={currentBriefVisualAssets.length}
      />
      <Section>
        <div className={styles.sectionHeader}>
          <div className={styles.title}>
            Visual directions <span>{currentBriefVisualAssets.length}</span>
          </div>
          <div className={styles.buttonsGroup}>
            {!!currentBriefVisualAssets?.length && <DownloadAllButton assets={currentBriefVisualAssets} />}
            {showAddVisualButton && (
              <ButtonCustom
                disabled={currentBriefVisualAssets.length >= 10}
                onClick={() => setAddVisualModalState(true)}
                Icon={AddIcon}
                className={styles.button}
              >
                Add {!isMobile && 'Visuals'}
              </ButtonCustom>
            )}
          </div>
        </div>
        {!!currentBriefVisualAssets.length && (
          <div className={styles.visualsList}>
            {currentBriefVisualAssets.map((item, index) => (
              <VisualElement data={item} deleteVisual={deleteVisual} key={index} />
            ))}
          </div>
        )}
      </Section>
    </>
  );
};

export default VisualDirectionsSection;
