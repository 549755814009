/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styles from './MyTeam.module.scss';
import MainLayout from '../../components/MainLayout';
import { useRouteMatch } from 'react-router-dom';
import TeamDetails from './TeamDetails';
import TeamMembers from './TeamMembers';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizations } from '../../actions/organizations.actions';
import CurrentPlan from '../MyPlan/CurrentPlan';
import { useHistory } from 'react-router';
import cs from 'classnames';
import MyTeamHeaderLoading from './MyTeamHeaderLoading';
import placeholders from '../../components/Avatar/components/placeholders';
import ButtonCustom from '../../expert/components/Buttons/ButtonCustom';
import { ReactComponent as IconTriangle } from '../../assets/img/icons/play-white-arrow.svg';
import { useMediaQuery } from 'react-responsive/src';
import LoadingContainer from '../../components/LoadingContainer';
import { TYPES_CAMPAIGNS, TYPES_CAMPAIGNS_TEXTS } from '../../shared/constants/constants';

const TAB_NAMES = ['details', 'members', 'plan'];

export default props => {
  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const configLoading = useSelector(state => state.config.loading);
  const industries = useSelector(state => state.config.industries.data);
  const stripe = useSelector(state => state.auth.stripe);
  const plans = useSelector(state => state.payment.allPlans);
  const plansLoading = useSelector(state => state.payment.loading);
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const organizations = useSelector(state => state.organizations.data);
  const subscriptionStatus = stripe?.subscription_status;
  const showWarning =
    currentOrg &&
    (subscriptionStatus === 'canceled' ||
      subscriptionStatus === 'past_due' ||
      subscriptionStatus === 'incomplete' ||
      subscriptionStatus === 'incomplete_expired');
  const currentPlan = plans.find(item => item.id === currentOrg?.owner_pricing_plan_id);
  const isValidPlan = currentOrg?.owner_has_valid_subscription && !showWarning;
  const currentIndustry = industries.find(item => item.id === organizations[0]?.type_specific_data?.industry_id);
  const isLaptop = useMediaQuery({ query: '(max-width: 991px)' });
  const loadingOrg = useSelector(state => state.organizations.loading);
  const isFreePlan = currentOrg?.owner_pricing_plan_id === 1001;
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  const companyType = () => {
    const type = organizations[0]?.type;
    if (type in TYPES_CAMPAIGNS_TEXTS) {
      return TYPES_CAMPAIGNS_TEXTS[type];
    }
    return TYPES_CAMPAIGNS_TEXTS[TYPES_CAMPAIGNS.ADVERTISER];
  };

  const switchTab = tabName => {
    switch (tabName) {
      case TAB_NAMES[0]:
        setSelectedIndex(0);
        break;
      case TAB_NAMES[1]:
        setSelectedIndex(1);
        break;
      case TAB_NAMES[2]:
        setSelectedIndex(2);
        break;
      default:
        setSelectedIndex(0);
    }
  };

  useEffect(() => {
    switchTab(match.params.tabName);
  }, [match.url]);

  useEffect(() => {
    if (currentOrg?.organization_id) dispatch(getOrganizations(currentOrg?.organization_id));
  }, [currentOrg?.organization_id]);

  useEffect(() => {
    const reg = /\/organization\/\w{1,15}/g;
    if (reg.test(history.location.pathname)) {
      const tabName = history.location.pathname.match(/\/organization\/([\w\d\-_]*)\/?\??/)[1];
      switchTab(tabName);
    }
  }, [history.location.pathname]);

  const getPlaceholder = () => {
    const hashNumber = currentOrg?.organization_name
      .split('')
      .map(char => char.codePointAt())
      .reduce((sum, num) => sum + num, 0);
    const placeholderIndex = hashNumber % placeholders?.length;
    const Placeholder = placeholders[placeholderIndex];
    return <Placeholder />;
  };

  const warningText = () => {
    switch (subscriptionStatus) {
      case 'canceled':
        return 'Subscription canceled';
      case 'past_due':
        return 'Renew payment has failed';
      case 'incomplete':
        return 'Initial payment attempt has failed';
      case 'incomplete_expired':
        return 'First invoice is not paid within 23 hours';
      default:
        return 'Subscription canceled';
    }
  };

  const buttonGoBack = () => {
    if (!isLaptop && !configLoading && currentOrg?.organization_name) {
      return (
        <ButtonCustom className={styles.buttonBack} onClick={() => history.push('/organizations')} purple>
          <IconTriangle />
          Back to organizations
        </ButtonCustom>
      );
    } else {
      return <LoadingContainer width="201px" height="40px" backgroundColor="rgba(255, 255, 255, 0.2)" borderRadius="4px" />;
    }
  };

  const textBanner = () => {
    if (isValidPlan) {
      return `${currentPlan?.name || 'Otterfish Internal'} plan`;
    } else {
      if (isFreePlan) return 'Early Access';
      return warningText();
    }
  };

  return (
    <MainLayout coloredHeader contentClassName={styles.layout} renderHeaderContent={buttonGoBack}>
      <div className={styles.root}>
        <Tabs
          selectedTabClassName={styles.selectedTab}
          selectedTabPanelClassName={styles.selectedPanel}
          selectedIndex={selectedIndex}
          onSelect={v => {
            setSelectedIndex(v);
            history.push(`/organization/${TAB_NAMES[v]}`);
          }}
        >
          <div className={styles.back}>
            {isLaptop && !configLoading && currentOrg?.organization_name ? (
              <ButtonCustom className={styles.buttonBack} onClick={() => history.push('/organizations')} purple>
                <IconTriangle />
                Back
              </ButtonCustom>
            ) : (
              <LoadingContainer
                className={styles.loadBack}
                width="70px"
                height="30px"
                backgroundColor="rgba(255, 255, 255, 0.2)"
                borderRadius="4px"
                margin={'0 0 20px 0'}
              />
            )}
            <div className={styles.board}>
              {!configLoading && currentOrg?.organization_name && !plansLoading && !loadingOrg ? (
                <>
                  <div className={styles.leftContent}>
                    <div className={styles.logo}>{getPlaceholder()}</div>
                    <div className={styles.organization}>{organizations[0]?.name}</div>
                    {!isMobile && currentIndustry?.name && <div className={styles.brandingAgency}>{currentIndustry?.name}</div>}
                    {!isMobile && <div className={styles.brandingAgency}>{companyType()}</div>}
                    <div className={cs(styles.bannerPlan, { [styles.warningPlan]: !isValidPlan && !isFreePlan })}>
                      <div className={styles.whiteCircle}></div>
                      {textBanner()}
                    </div>
                  </div>
                  {isMobile && (
                    <div className={styles.brandingBlock}>
                      {currentIndustry?.name && <div className={styles.brandingAgency}>{currentIndustry?.name}</div>}
                      <div className={styles.brandingAgency}>{companyType()}</div>
                    </div>
                  )}
                  {!isValidPlan && !isFreePlan && (
                    <ButtonCustom onClick={() => history.push('/upgrade-plan')} outline className={styles.btnPlans}>
                      Choose plan
                    </ButtonCustom>
                  )}
                </>
              ) : (
                <MyTeamHeaderLoading />
              )}
            </div>
            <TabList className={styles.tabs}>
              <Tab className={cs(styles.tab, { [styles.activeTab]: selectedIndex === 0 })}>Details</Tab>
              <Tab className={cs(styles.tab, { [styles.activeTab]: selectedIndex === 1 })}>Members</Tab>
              <Tab className={cs(styles.tab, { [styles.activeTab]: selectedIndex === 2 })}>Plan</Tab>
            </TabList>
          </div>
          <TabPanel>
            <TeamDetails />
          </TabPanel>
          <TabPanel>
            <TeamMembers />
          </TabPanel>
          <TabPanel>
            <CurrentPlan />
          </TabPanel>
        </Tabs>
      </div>
    </MainLayout>
  );
};
