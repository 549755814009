import React from 'react'
import styles from './DraftGenerativeModal.module.scss';
import { ReactComponent as ContinueSmartAd } from '../../../assets/img/icons/continue_smart_ad.svg';
import Button from '../../../expert/components/Buttons/ButtonCustom';
import { Modal } from '../index';

const DraftGenerativeModal = ({ onSubmit }) => {
  return (
    <div className={styles.root}>
      <div className={styles.svgContainer}><ContinueSmartAd/></div>
      <div className={styles.text}>
        <span>You have Generate Creatives in progress</span>
        <span>You can continue generating or start over.</span>
      </div>
      <div className={styles.buttonContainer}>
        <Button outline className={styles.btn} onClick={() => onSubmit(true)}>
          Start over
        </Button>
        <Button className={styles.btn} onClick={() => onSubmit(false)}>
          Continue
        </Button>
      </div>
    </div>
  );
};

export default props => {
  return (
    <Modal {...props} maxWidth={488}>
      <DraftGenerativeModal {...props} />
    </Modal>
  );
};
