/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';
import styles from './ChoosePlan.module.scss';
import { loadStripe } from '@stripe/stripe-js';
import LogoSVG from '../../components/IconsSvg/LogoSVG';
import { useHistory } from 'react-router-dom';
import PlanCard from '../../components/Cards/PlanCard';
import { useDispatch, useSelector } from 'react-redux';
import { clearFailure } from '../../actions/payment.action';
import { clearFailure as orgClearFailure } from '../../actions/organizations.actions';
import { me, signOut } from '../../actions/auth.actions';
import Loader from '../../expert/components/Loader';
import ErrorModal from '../../expert/components/Modal/ErrorModal';
import { createCheckoutSession } from '../../actions/stripe.actions';
import LogoutSVG from '../../expert/components/IconsSvg/LogoutSVG';
import ButtonCustom from '../../expert/components/Buttons/ButtonCustom';
import SmallBoldRightArrowSVG from '../../components/IconsSvg/SmallBoldRightArrowSVG';
import cs from 'classnames';
import { getPricingPlanForUser, getUserUsage, updatePricingPlanForUser } from '../../actions/user.actions';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const redirectToStripe = async sessionId => {
  const stripe = await stripePromise;
  stripe
    .redirectToCheckout({
      sessionId: sessionId,
    })
    .then();
};

const ChoosePlan = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const failure = useSelector(state => state.payment.failure);
  const userFail = useSelector(state => state.user.failure);
  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const organizations = useSelector(state => state.auth.organizations);
  const user = useSelector(state => state.auth.user);
  const isAuthenticated = useSelector(state => !!state.auth.token);
  const [updatingPlan, setUpdatingPlan] = useState(false);

  /**
   * If at least one organization has a paid plan return true
   * @type {Boolean}
   */
  const backButtonDisplayStatus = useMemo(() => {
    if (currentOrg) {
      return Boolean(currentOrg.owner_pricing_plan_id);
    }
    return false;
  }, [currentOrg]);

  useEffect(() => {
    localStorage.removeItem('OTTERFISH_SELECTED_BILLING_PLAN');
    localStorage.removeItem('OTTERFISH_SELECTED_BILLING_ADDRESS');
    localStorage.removeItem('OTTERFISH_CREDIT_CARD_DETAILS');
    dispatch(me());
  }, []);

  const goBack = () => {
    if (document.referrer.includes(window.location.host)) {
      if (isAuthenticated) history.goBack();
      else history.push('/login');
    } else history.push('/');
  };

  const specialPlan = {
    name: 'Early Access',
    id: 1001,
    price_monthly: "Free"
  };

  const handleSelect = async (planId, planName) => {
    if (planId === 1005) {
      const win = window.open('https://help.otterfish.com/knowledge/kb-tickets/new');
      if (win != null) {
        win.focus();
      }
    } else {
      localStorage.removeItem('OTTERFISH_CURRENT_ORGANIZATION');
      if (user.has_valid_subscription || planId === 1001) {
        setUpdatingPlan(true);
        try {
          const response = await dispatch(updatePricingPlanForUser(user.id, planId));
          setUpdatingPlan(false);
          if (response.success) {
            // we need a timeout cuz API has a delay before it will return updated organization and pricing plan
            setUpdatingPlan(true);
            setTimeout(async () => {
              window.analytics.track('plan_chosen', {
                category: 'User Signup',
                label: planName,
                userId: user.id,
              });

              await dispatch(me());
              await dispatch(getPricingPlanForUser());
              await dispatch(getUserUsage());
              setUpdatingPlan(false);

              const tokenData = JSON.parse(localStorage.getItem('OTTERFISH_ACCESS_TOKEN'));
              if (tokenData.campaign_brief_id) {
                if (!!tokenData.parent_id) {
                  window.analytics.track('signup_successful', {
                    category: 'User Signup',
                    label: 'User Signup',
                    userId: user.id,
                  });
                  history.push(
                    `/campaign/${tokenData.campaign_brief_id}/campaign-brief/${tokenData.parent_id}/creator-brief/${tokenData.campaign_brief_item_id}`
                  );
                } else {
                  window.analytics.track('signup_successful', {
                    category: 'User Signup',
                    label: 'User Signup',
                    userId: user.id,
                  });
                  history.push(`/campaign/${tokenData.campaign_brief_id}/campaign-brief/${tokenData.campaign_brief_item_id}`);
                }
              } else if (tokenData.content_board_id) {
                window.analytics.track('signup_successful', {
                  category: 'User Signup',
                  label: 'User Signup',
                  userId: user.id,
                });
                history.push(`/content-board/${tokenData.content_board_id}`);
              } else {
                window.analytics.track('signup_successful', {
                  category: 'User Signup',
                  label: 'User Signup',
                  userId: user.id,
                });
                history.push('/');
              }
            }, 2500);
          }
        } catch {
          setUpdatingPlan(false);
        }
      } else {
        dispatch(createCheckoutSession(user.id, planId, '/checkout-success', '/upgrade-plan')).then(response => {
          redirectToStripe(response?.data?.[0]?.id);
        });
      }
    }
    localStorage.removeItem('OTTERFISH_PRESELECTED_PLAN');
  };

  const logout = () => {
    dispatch(signOut());
  };

  const backOnClick = () => {
    const changeToAnyValidOrganization = () => {
      const validOrg = organizations.find(org => org.owner_has_valid_subscription);
      if (validOrg) {
        changeOrg(validOrg);
      }
    };
    const changeOrg = org => {
      localStorage.removeItem('OTTERFISH_CURRENT_ORGANIZATION');
      localStorage.setItem('OTTERFISH_CURRENT_ORGANIZATION', JSON.stringify(org));
      dispatch({ type: 'SET_CURRENT_ORGANIZATION', payload: org });
    };

    const prevOrgId = localStorage.getItem('OTTERFISH_PREV_ORGANIZATION_ID');

    if (prevOrgId) {
      const prevOrg = organizations.find(org => org.organization_id == prevOrgId);
      changeOrg(prevOrg);
    } else {
      changeToAnyValidOrganization();
    }

    localStorage.removeItem('OTTERFISH_PREV_ORGANIZATION_ID');
    history.push('/organization/plan');
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        {backButtonDisplayStatus && (
          <ButtonCustom outline clearBg className={styles.backButton} onClick={backOnClick}>
            <SmallBoldRightArrowSVG />
            Back
          </ButtonCustom>
        )}
        <LogoSVG />
        {!user?.pricing_plan_id && (
          <div className={styles.logout} onClick={logout}>
            <LogoutSVG />
            <span>Log out</span>
          </div>
        )}
      </div>
      <div className={styles.body}>
        {!user?.pricing_plan_id ? (
          <div className={styles.title}>
            <span>Create, collaborate and approve campaign briefs in minutes</span>
            <div className={styles.margin}>
              Otterfish is a single workspace where digital agencies, talent managers and creators collaborate from
              brief to reporting.
            </div>
          </div>
        ) : (
          <div className={cs(styles.title, styles.shortTitle)}>
            <span>Choose your new plan</span>
          </div>
        )}
        <div className={styles.planContainer}>
          <PlanCard plan={specialPlan} onSelect={handleSelect}/>
        </div>
      </div>
      <ErrorModal
        open={!!failure?.message}
        title="Oops, something went wrong."
        text={failure?.message}
        onClose={() => {
          dispatch(clearFailure());
          goBack();
        }}
      />
      <ErrorModal
        open={!!userFail?.message}
        title="Oops, something went wrong."
        text={userFail?.message}
        onClose={() => {
          dispatch(orgClearFailure());
          goBack();
        }}
      />
      {updatingPlan && (
        <div className={styles.loaderNew}>
          <Loader textContent="We are completing your profile. You will be ready to go in seconds" />
        </div>
      )}
    </div>
  );
};

export default ChoosePlan;
