import React, { useMemo } from 'react';
import styles from './DeliverableCell.module.scss';
import DeliverablePlatform from './DeliverablePlatform';
import DeliverableName from './DeliverableName';
import useDeliverableStatuses from '../../../pages/ContentApproval/DeliverableItem/components/Header/statuses/useDeliverableStatuses';
import { getFormattedPlacement } from '../../../shared/utils/helpers';
import dayjs from 'dayjs';

const DeliverableCell = ({ data, onClick, isTablet }) => {
  const { id, name, assets, platform, placement, live_datatime, status_concept, status_content } = data;

  const visualAssets = useMemo(
    () =>
      assets.sort(asset => {
        if (asset.type === 'deliverable') {
          return -1;
        }
        return 1;
      }),
    [assets]
  );

  const getVisualAssetPreview = asset => {
    if (/\/\/view/g.test(asset.thumbnail_location)) {
      return asset.location;
    }
    return asset.thumbnail_location;
  };

  const { conceptStatusTile, contentStatusTile } = useDeliverableStatuses({
    concept: status_concept,
    content: status_content,
  });

  return isTablet ? (
    <div className={styles.root} onClick={onClick}>
      <div className={styles.topContent}>
        <div className={styles.nameContainer}>
          {visualAssets.length ? (
            <img className={styles.image} src={getVisualAssetPreview(visualAssets[0])} alt="deliverable asset preview" />
          ) : (
            <div className={styles.imageEmpty} />
          )}
          <DeliverableName name={name} id={id} />
        </div>
        <div className={styles.right}>
          <div className={styles.platform}>
            <DeliverablePlatform platform={platform} isTablet={isTablet} />
          </div>
          <div className={styles.placementContainer}>
            <span className={styles.placement}>{getFormattedPlacement(placement)}</span>
          </div>
        </div>
      </div>
      <div className={styles.bottomContent}>
        <div className={styles.left}>
          <div>Concept</div>
          <div className={styles.status}>{conceptStatusTile}</div>
        </div>
        <div className={styles.center}>
          <div>Live Date</div>
          <div className={styles.status}>{live_datatime ? dayjs(live_datatime).format('DD / MMM / YYYY') : 'No date'}</div>
        </div>
        <div className={styles.right}>
          <div>Content</div>
          <div className={styles.status}>{contentStatusTile}</div>
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.root} onClick={onClick}>
      <div className={styles.nameContainer}>
        {visualAssets.length ? (
          <img className={styles.image} src={getVisualAssetPreview(visualAssets[0])} alt="deliverable asset preview" />
        ) : (
          <div className={styles.imageEmpty} />
        )}
        <DeliverableName name={name} id={id} />
      </div>
      <div className={styles.platform}>
        <DeliverablePlatform platform={platform} isTablet={isTablet} />
      </div>
      <div className={styles.placementContainer}>
        <span className={styles.placement}>{getFormattedPlacement(placement)}</span>
      </div>
      <div className={styles.dateContainer}>
        <span>{live_datatime ? dayjs(live_datatime).format('DD / MMM / YYYY') : 'No date'}</span>
      </div>
      <div className={styles.status}>{conceptStatusTile}</div>
      <div className={styles.status}>{contentStatusTile}</div>
      {/* <div className={styles.progress}>*/}
      {/*  <CirclePercentProgress value={percentageProgress} withCompleted size={30} />*/}
      {/* </div>*/}
    </div>
  );
};

export default DeliverableCell;
