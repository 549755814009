import React from 'react';

function PauseSVG() {
  return (
    <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.909091 0C0.407014 0 0 0.419733 0 0.9375V14.0625C0 14.5803 0.407014 15 0.909091 15H2.72727C3.22935 15 3.63636 14.5803 3.63636 14.0625V0.9375C3.63636 0.419733 3.22935 0 2.72727 0H0.909091Z"
        fill="#9FA2B4"/>
      <path
        d="M7.27273 0C6.77065 0 6.36364 0.419733 6.36364 0.9375V14.0625C6.36364 14.5803 6.77065 15 7.27273 15H9.09091C9.59299 15 10 14.5803 10 14.0625V0.9375C10 0.419733 9.59299 0 9.09091 0H7.27273Z"
        fill="#9FA2B4"/>
    </svg>
  );
}

export default PauseSVG;
