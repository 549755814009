import { GRAPHIC_ASSETS_TYPES } from '../actions/graphicAssets.actions';

const initialValue = {
  searchValue: '',
  page: 1,
  currentFolderId: 0,
  lastOpenedFolderId: 0,
  rootFolders: [],
  selectedFolders: [],
  selectedAssets: [],
  selectedTags: [],
  uploadSwitcherValue: 'images',
  assetModalNewName: '',
  assetModalNewTags: [],
  firstFormingUrl: true,
  isGoBackFolder: false,
  canSelect: false,
  isSelectedAll: false,
  isDownload: false,
  isVisibleAsset: false,
  isAllFilesMode: false,
  isSearchDone: false,
  loadingAssets: true,
  modals: {
    isOpenModalDropzone: false,
    isOpenCreateFolderModal: false,
    isOpenRenameFolderModal: false,
    isOpenDeleteAssetsModal: false,
    isOpenTagsModal: false,
    isOpenMove: false,
    isOpenAsset: false,
  },
  banners: {
    deleted: {
      items: 0,
      text: '',
      isSuccess: false,
    },
    renamed: {
      newName: '',
      item: '',
      isSuccess: false,
    },
    createFolder: {
      name: '',
      isSuccess: false,
    },
    moveToFolder: {
      name: '',
      items: 0,
      folder: {}
    },
    upload: {
      isSuccess: false
    }
  }
};

export default (state = initialValue, action) => {
  switch (action.type) {
    case GRAPHIC_ASSETS_TYPES.SET_VALUE:
      return {
        ...state,
        [action.name]: action.value
      };
    case GRAPHIC_ASSETS_TYPES.SET_MODAL_VALUE:
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.modalName]: action.value
        }
      };
    case GRAPHIC_ASSETS_TYPES.SET_BANNER_VALUE:
      return {
        ...state,
        banners: {
          ...state.banners,
          [action.bannerName]: {
            ...state.banners[action.bannerName],
            ...action.values
          }
        }
      };
    case GRAPHIC_ASSETS_TYPES.INIT:
      return initialValue;
    default: {
      return state;
    }
  }
};
