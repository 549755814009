import React from 'react';

function InfoBlackSVG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" fill="none" viewBox="0 0 14 15">
      <path
        fill="#161419"
        d="M7 .969C3.254.969.219 4.03.219 7.75A6.78 6.78 0 007 14.531c3.719 0 6.781-3.035 6.781-6.781C13.781 4.031 10.72.969 7 .969zm0 3.008c.629 0 1.148.52 1.148 1.148 0 .656-.52 1.148-1.148 1.148a1.121 1.121 0 01-1.148-1.148c0-.629.492-1.148 1.148-1.148zm1.531 6.945a.332.332 0 01-.328.328H5.797a.316.316 0 01-.328-.328v-.656c0-.164.136-.329.328-.329h.328v-1.75h-.328a.316.316 0 01-.328-.328v-.656c0-.164.136-.328.328-.328h1.75c.164 0 .328.164.328.328v2.734h.328c.164 0 .328.165.328.329v.656z"
      ></path>
    </svg>
  );
}

export default InfoBlackSVG;
