import React, {useState} from 'react';
import numeral from 'numeral';
import styles from './AudiencesImportCell.module.scss';
import SavedSVG from '../../IconsSvg/SavedSVG';
import LookalikeSVG from '../../IconsSvg/LookalikeSVG';
import CustomSVG from '../../IconsSvg/CustomSVG';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import {ReactComponent as DoneIcon} from '../../../assets/img/icons/green-ok-icon-2.svg';
import cs from 'classnames';
import Loader from '../../../expert/components/Loader';

const AudiencesImportCell = ({ audience, onRemove, withSelect, selectedList, onImport }) => {
  const count = audience.size || audience.size_upper_bound;
  const isImported = withSelect && selectedList.map(item => item.id === audience.id).includes(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleImport = async () => {
    if (isLoading) return;
    setIsLoading(true);
    await onImport(audience);
    setIsLoading(false);
  }

  const handleRemove = async () => {
    if (isLoading) return;
    setIsLoading(true);
    await onRemove(audience);
    setIsLoading(false);
  }

  const RightButtons = () =>
    <div className={styles.buttonContainer}>
      {(isImported && !isLoading) && (
        <div className={styles.importedDone}>
          <span><DoneIcon /></span>
          Imported
        </div>
      )}
      <ButtonCustom
        className={cs(
          styles.importRemoveButton,
          {[styles.transparentStyle]: isImported || isLoading}
        )}
        onClick={isImported ? handleRemove : handleImport}
      >
        {isLoading && (<Loader className={styles.loader} />)}
        {isImported ? isLoading ? 'Removing' : 'Remove' : isLoading ? 'Importing' : 'Import'}
      </ButtonCustom>
    </div>

  const renderType = (type) => {
    switch (type) {
      case 'saved':
        return <div className={styles.frame}><SavedSVG /></div>;
      case 'lookalike':
        return <div className={styles.frame}><LookalikeSVG /></div>;
      case 'custom':
        return <div className={styles.frame}><CustomSVG /></div>;
      default: return <div className={styles.frame}><SavedSVG /></div>;
    }
  }

  const shortCounter = () => {
    return numeral(count).format('0.0a').replace(/\.0/, '');
  }

  return (
    <div className={cs(styles.root)}>
      <div>
        <div>
          {renderType(audience.type)}
          <div className={styles.name}>
            <span>{audience.name}{count !== -1 && ' - ' + shortCounter()}</span>
          </div>
        </div>
        <div className={styles.type}>
          <span>{audience.type ? audience.type.slice(0, 1).toUpperCase() + audience.type.slice(1) : 'Saved'}</span>
        </div>
        <div className={styles.count}>
          <LookalikeSVG />
            <div>
              {count === -1 ?
                <>
                  Below 1000
                  <span>Low website traffic</span>
                </>
                : count.toLocaleString('en-US')}
            </div>
        </div>
      </div>
      {RightButtons()}
    </div>
  )
}

export default AudiencesImportCell;
