import React from 'react';
import styles from './Header.module.scss';
import SectionWrapper from '../SectionWrapper';
import DeliverablePlatform from '../../../../../components/Cells/DeliverableCell/DeliverablePlatform';
import { useSelector } from 'react-redux';
import LoadingContainer from '../../../../../components/LoadingContainer';
import HeaderColumn from './HeaderColumn';
import { getFormattedPlacement } from '../../../../../shared/utils/helpers';
import { useMediaQuery } from 'react-responsive/src';
import DeliverableName from '../DeliverableName';
import LiveDueDate from '../../../../../components/BriefsAndBoardsFlows/LiveDueDate';

const Header = () => {
  const { openedDeliverable, deliverableLoading } = useSelector(state => state.contentApproval);
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <SectionWrapper>
      <div className={styles.root}>
        {deliverableLoading &&
          Array(isTablet ? 2 : 4)
            .fill('')
            .map((_, i) => (
              <LoadingContainer
                key={i}
                width={isTablet ? '150px' : '15%'}
                height={'63px'}
                borderRadius="5px"
                animationDelay={`${-1 + i * 0.1}s`}
              />
            ))}

        {!deliverableLoading && (
          <>
            <HeaderColumn className={styles.deliverableName} columnName="Deliverable:">
              <DeliverableName />
            </HeaderColumn>
            <HeaderColumn type="group">
              <HeaderColumn columnName="Platform:">
                <div className={styles.platform}>
                  <DeliverablePlatform className={styles.placementComponent} withoutText platform={openedDeliverable?.platform} />
                  {getFormattedPlacement(openedDeliverable?.placement)}
                </div>
              </HeaderColumn>
              <HeaderColumn columnName="Live Date">
                <LiveDueDate />
              </HeaderColumn>
            </HeaderColumn>
          </>
        )}
      </div>
    </SectionWrapper>
  );
};

export default Header;
