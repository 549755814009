import React, { useState } from 'react';
import DeliverableAssetModal from '../../../../../components/Modals/DeliverableAssetModal';
import ConfirmDeleteVisualAssetModal from '../../../../../components/Modals/ConfirmDeleteVisualAssetModal';
import LoaderModal from '../../../../../expert/components/Modal/LoaderModal';
import { useDispatch, useSelector } from 'react-redux';
import ErrorModal from '../../../../../expert/components/Modal/ErrorModal';
import { clearContentApprovalErrors } from '../../../../../actions/contentApproval.actions';
import { DELIVERABLE_STATUSES } from '../../hooks/useStatusesState';
import { useMediaQuery } from 'react-responsive/src';
import MobilePreview from '../MobilePreview';
import { DELIVERABLE_ASSET_STATUSES } from '../../constants';
import useDownloadAsset from '../../../../../shared/hooks/useDownloadAsset';

const ModalsContainer = ({ openedAsset, setOpenedAsset, openAssetModal, setOpenAssetModal, onDeleteAsset, deliverableStatuses, assetsPrev, callToActionPrev }) => {
  const dispatch = useDispatch();
  const { approveLoading, deliverableItemUpdateLoading, deliverableItemUpdateError, approveError } = useSelector(
    state => state.contentApproval
  );
  const { openedDeliverable } = useSelector(state => state?.contentApproval);
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  const isDisabledDelete =
    openedAsset?.type === 'deliverable'
      ? deliverableStatuses.content === DELIVERABLE_STATUSES.APPROVED
      : deliverableStatuses.concept === DELIVERABLE_STATUSES.APPROVED;

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const onCloseAssetModal = () => {
    setOpenAssetModal(false);
    setOpenedAsset(null);
  };

  const onAssetModalDeleteAction = () => {
    setOpenConfirmModal(true);
    setOpenAssetModal(false);
  };

  const onSubmitDelete = () => {
    onDeleteAsset(openedAsset);
    onCloseConfirm();
  };

  const onCloseConfirm = () => {
    setOpenConfirmModal(false);
    setOpenedAsset(null);
  };

  const VideoAsset = props => {
    return <video {...props} />;
  };

  const ImageAsset = ({ controls, ...props }) => {
    return <img {...props} alt={''} />;
  };

  const isVideo = openedAsset && /^video/g.test(openedAsset.file ? openedAsset.file.type : openedAsset.mime_type);
  const isVideoProcessing = isVideo && openedAsset.status === DELIVERABLE_ASSET_STATUSES.PROCESSING;
  const AssetComponent = isVideo ? VideoAsset : ImageAsset;
  const src = openedAsset?.preview_location || openedAsset?.location;
  const { isAssetLoading, downloadAsset } = useDownloadAsset();

  const onDownloadAsset = () => {
    if (isAssetLoading) return;
    downloadAsset(openedAsset.location, openedAsset.extension);
  };

  return (
    <>
      <ErrorModal
        open={!!approveError || !!deliverableItemUpdateError}
        text={approveError || deliverableItemUpdateError || ''}
        onClose={() => dispatch(clearContentApprovalErrors())}
      />
      <LoaderModal open={approveLoading || deliverableItemUpdateLoading} />
      {
        isTablet && openedAsset?.type === 'deliverable' &&
        (openedDeliverable.placement === 'highlight' || openedDeliverable.placement === 'story_post') ?
          <MobilePreview
            open={!!openAssetModal}
            asset={openedAsset || {}}
            onClose={onCloseAssetModal}
            assetsPrev={assetsPrev}
            callToActionPrev={callToActionPrev}
            isDisabledDelete={isDisabledDelete}
            onDownloadAsset={onDownloadAsset}
            onDelete={onAssetModalDeleteAction}
          />
          :
          <DeliverableAssetModal
            open={!!openAssetModal}
            asset={openedAsset || {}}
            onClose={onCloseAssetModal}
            onDelete={onAssetModalDeleteAction}
            isDisabledDelete={isDisabledDelete}
            isVideoProcessing={isVideoProcessing}
            AssetComponent={AssetComponent}
            src={src}
            onDownloadAsset={onDownloadAsset}
            isMobile={isMobile}
            isAssetLoading={isAssetLoading}
          />
      }
      <ConfirmDeleteVisualAssetModal open={openConfirmModal} onSubmit={onSubmitDelete} onClose={onCloseConfirm} />
    </>
  );
};

export default ModalsContainer;
