import React from 'react';

const TikTokSVG = () => {
  return(
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="25" cy="25" r="25" fill="black"/>
      <path d="M20.052 26.0006C17.5714 26.0006 15.5674 28.0046 15.604 30.4608C15.6285 32.0371 16.496 33.4057 17.773 34.1694C17.3392 33.5035 17.0826 32.7153 17.0704 31.866C17.0337 29.4099 19.0377 27.4059 21.5183 27.4059C22.0071 27.4059 22.4776 27.4853 22.9175 27.6258V22.7074C22.4593 22.6402 21.9888 22.6035 21.5183 22.6035C21.4939 22.6035 21.4756 22.6035 21.4511 22.6035V26.2205C21.0112 26.08 20.5408 26.0006 20.052 26.0006Z" fill="#F00044"/>
      <path d="M30.7743 11.252H30.7376H29.4668C29.8334 13.091 30.866 14.6918 32.3079 15.7793C31.3608 14.5268 30.7865 12.9627 30.7743 11.252Z" fill="#F00044"/>
      <path d="M38.2533 18.736C37.7706 18.736 37.3062 18.6871 36.848 18.6016V22.1269C35.1861 22.1269 33.5731 21.8031 32.0579 21.1555C31.0803 20.74 30.1699 20.2085 29.3329 19.5669L29.3573 30.4363C29.3451 32.8803 28.3798 35.1715 26.6323 36.9005C25.2088 38.3058 23.4064 39.204 21.4512 39.4911C20.993 39.5583 20.5225 39.595 20.052 39.595C17.9625 39.595 15.9707 38.9168 14.3516 37.6643C14.5349 37.8842 14.7304 38.0981 14.9442 38.3058C16.7039 40.041 19.0378 41.0002 21.5245 41.0002C21.995 41.0002 22.4654 40.9636 22.9237 40.8964C24.8788 40.6092 26.6812 39.7111 28.1048 38.3058C29.8522 36.5767 30.8176 34.2855 30.8298 31.8416L30.7382 20.9722C31.5691 21.6137 32.4795 22.1514 33.4632 22.5607C34.9845 23.2023 36.5975 23.5322 38.2533 23.5322" fill="#F00044"/>
      <path d="M14.9253 25.3092C16.6666 23.5801 18.9822 22.6209 21.4506 22.6025V21.3011C20.9923 21.2339 20.5219 21.1973 20.0514 21.1973C17.5586 21.1973 15.2185 22.1565 13.4589 23.9039C11.7298 25.6208 10.74 27.967 10.7461 30.4048C10.7461 32.861 11.7176 35.1644 13.4711 36.9057C13.7522 37.1806 14.0393 37.4372 14.3448 37.6694C12.964 36.0259 12.2125 33.9729 12.2064 31.8101C12.2125 29.3722 13.1962 27.0261 14.9253 25.3092Z" fill="#08FFF9"/>
      <path d="M36.848 18.602V17.2945H36.8357C35.1372 17.2945 33.5731 16.7324 32.3145 15.7793C33.4081 17.2212 35.0089 18.2477 36.848 18.602Z" fill="#08FFF9"/>
      <path d="M21.2663 36.1913C21.8467 36.2219 22.4027 36.1425 22.916 35.9775C24.6878 35.3971 25.9648 33.7474 25.9648 31.8045L25.9709 24.5338V11.251H29.4657C29.3741 10.7927 29.3252 10.3284 29.3191 9.8457H24.5045V23.1224L24.4984 30.3931C24.4984 32.336 23.2215 33.9857 21.4496 34.5661C20.9364 34.7372 20.3804 34.8166 19.8 34.78C19.0607 34.7372 18.3702 34.5173 17.7715 34.1629C18.523 35.3237 19.8061 36.1119 21.2663 36.1913Z" fill="#08FFF9"/>
      <path d="M21.451 39.4897C23.4062 39.2025 25.2086 38.3044 26.6321 36.8991C28.3796 35.17 29.3449 32.8788 29.3571 30.4349L29.3327 19.5655C30.1636 20.207 31.074 20.7447 32.0577 21.1541C33.579 21.7956 35.192 22.1255 36.8478 22.1255V18.6001C35.0087 18.2458 33.408 17.2193 32.3143 15.7774C30.8724 14.6898 29.8337 13.0891 29.4732 11.25H25.9723V24.5267L25.9662 31.7974C25.9662 33.7403 24.6892 35.39 22.9174 35.9704C22.4041 36.1415 21.8481 36.2209 21.2677 36.1843C19.8013 36.1048 18.5244 35.3167 17.7729 34.1619C16.4959 33.4043 15.6283 32.0296 15.6039 30.4532C15.5672 27.9971 17.5713 25.9931 20.0518 25.9931C20.5406 25.9931 21.0111 26.0725 21.451 26.213V22.596C18.9826 22.6143 16.667 23.5736 14.9257 25.3026C13.1966 27.0195 12.2068 29.3657 12.2129 31.7974C12.2129 33.9603 12.9644 36.0132 14.3514 37.6567C15.9766 38.9093 17.9623 39.5875 20.0518 39.5875C20.5223 39.5936 20.9928 39.5569 21.451 39.4897Z" fill="white"/>
    </svg>
  )
}

export default TikTokSVG;
