import React, { useEffect, useState } from 'react';
import DefaultStatusTile from './DefaultStatusTile';
import ApprovedStatusTile from './ApprovedStatusTile';
import { DELIVERABLE_STATUSES } from '../../../hooks/useStatusesState';

const statuses = {
  [DELIVERABLE_STATUSES.IN_PROGRESS]: <DefaultStatusTile statusText="In progress" status={DELIVERABLE_STATUSES.IN_PROGRESS} />,
  [DELIVERABLE_STATUSES.PENDING_APPROVAL]: (
    <DefaultStatusTile statusText="Pending approval" status={DELIVERABLE_STATUSES.PENDING_APPROVAL} />
  ),
  [DELIVERABLE_STATUSES.APPROVED]: <ApprovedStatusTile />,
  [DELIVERABLE_STATUSES.PENDING_CONCEPT]: <DefaultStatusTile statusText="Pending concept" status={DELIVERABLE_STATUSES.PENDING_CONCEPT} />,
};

const statusesPercentage = {
  concept: {
    [DELIVERABLE_STATUSES.IN_PROGRESS]: 15,
    [DELIVERABLE_STATUSES.PENDING_APPROVAL]: 33,
    [DELIVERABLE_STATUSES.APPROVED]: 50,
  },
  content: {
    [DELIVERABLE_STATUSES.IN_PROGRESS]: 0,
    [DELIVERABLE_STATUSES.PENDING_APPROVAL]: 25,
    [DELIVERABLE_STATUSES.APPROVED]: 50,
  },
};

const useDeliverableStatuses = deliverableStatuses => {
  const [conceptStatusTile, setConceptStatusTile] = useState(null);
  const [contentStatusTile, setContentStatusTile] = useState(null);
  const [percentageProgress, setPercentageProgress] = useState(0);

  useEffect(() => {
    if (!deliverableStatuses.concept || !deliverableStatuses.content) return;

    const conceptStatus = deliverableStatuses.concept;
    const contentStatus = deliverableStatuses.content;

    const newConceptStatusTile = statuses[conceptStatus];
    const newContentStatusTile =
      conceptStatus === DELIVERABLE_STATUSES.APPROVED ? statuses[contentStatus] : statuses[DELIVERABLE_STATUSES.PENDING_CONCEPT];

    setConceptStatusTile(newConceptStatusTile);
    setContentStatusTile(newContentStatusTile);
    setPercentageProgress(statusesPercentage.concept[conceptStatus] + statusesPercentage.content[contentStatus]);
  }, [deliverableStatuses]);

  return {
    conceptStatusTile,
    contentStatusTile,
    percentageProgress,
  };
};

export default useDeliverableStatuses;
