/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import styles from './LoadingState.module.scss';
import LoadingContainer from '../../../LoadingContainer';
import { useMediaQuery } from 'react-responsive/src';

const LoadingState = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  return (
    <div className={styles.root}>
      {[...Array(8).keys()].map(index => {
        return (
          <div className={styles.item} key={index}>
            <div>
              <LoadingContainer
                width={'35px'}
                height={'35px'}
                borderRadius={'50%'}
                margin={'0 10px 0 0'}
                animationDelay={`${-1 + (index * 0.1)}s`}
              />
              <LoadingContainer
                width={isMobile ? '97px': '150px'}
                height={'16px'}
                animationDelay={`${-1 + (index * 0.15)}s`}
              />
            </div>
            <LoadingContainer
              width={'85px'}
              height={'30px'}
              borderRadius={'5px'}
              animationDelay={`${-1 + (index * 0.2)}s`}
            />
          </div>
        )
      })}
    </div>
  )
}

export default LoadingState;
