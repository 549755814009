import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './CreateCampaignItem.module.scss';
import MainLayout from '../../../components/MainLayout';
import TabBrandDetails from '../components/tabs/TabBrandDetails';
import TabBriefDetails from '../components/tabs/TabBriefDetails';
import TabVisualAssets from '../components/tabs/TabVisualAssets';
import { useFormik } from 'formik';
import SpeakerSVG from '../../../components/IconsSvg/SpeakerSVG';
import BriefTab from '../components/BriefTab';
import VisualElementSVG from '../../../components/IconsSvg/VisualElementSVG';
import SuitcaseSVG from '../../../components/IconsSvg/SuitcaseSVG';
import BriefSubHeader from '../components/BriefSubHeader';
import { useDispatch } from 'react-redux';
import { clearBriefFailure, createBrief } from '../../../actions/brief.actions';
import TabCollaborators from '../components/tabs/TabCollaborators';
import DocumentSVG from '../../../expert/components/IconsSvg/DocumentSVG';
import dayjs from 'dayjs';
import ErrorModal from '../../../expert/components/Modal/ErrorModal';
import * as Yup from 'yup';
import BriefPageContentWrapper from '../components/BriefPageContentWrapper';
import useDeliverablesFormik from '../../../components/DeliverablesPlatforms/hooks/useDeliverablesFormik';
import { briefPageType } from '../briefPageType';

function CreateCampaignItem() {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const failure = useSelector(state => state.brief.failure);

  const [logoObject, setLogoObject] = useState({ file: null, fileUrl: null });

  // Array<{file: File, fileUrl: string, description: string}>
  const [visualElements, setVisualElements] = useState([]);

  const formik = useFormik({
    initialValues: {
      // brief detail
      campaignName: '',
      brandName: '',
      brandBackground: '',
      showBrandProvidesProduct: false,
      brandProvidesProduct: '',
      notes: '',

      // brief items
      messaging: '',
      campaignObjective: [],

      inviteEmail: '',
      inviteType: null,
      inviteTypeValidation: null,

      initiator_organization_type: undefined,

      itemName: '',

      hashtags: [],
      socialTagging: [],

      gender: '',
      showExclusivity: false,
      exclusivity: '',

      ageMax: null,
      ageMin: null,

      total_budget: undefined,
      boosting_budget: undefined,
      boosting_duration: undefined,
      boosting_manager: [],
      total_budget_currency: 'NZD',
      boosting_budget_currency: 'NZD',

      date_start: null,
      date_end: null,
      date_post: null,

      adInCaption: null,

      repostingPlatform: [],
      repostingDuration: 1,
      showSocial: false,
      showCreators: false,
      city: [],
      country: [],
      interests: [],

      suggestedCreators: '',
      suggestedCreatorsDescription: '',
      showBoosting: false,
      other_infos: '',
    },
    validationSchema: Yup.object().shape({
      inviteEmail: Yup.string()
        .email('Incorrect email')
        .when('inviteTypeValidation', {
          is: value => value,
          then: Yup.string().required('Email is required'),
        }),
      inviteType: Yup.object()
        .nullable(true)
        .when('inviteEmail', {
          is: value => !!value,
          then: Yup.object().required('Invite type is required'),
        }),
      date_end: Yup.date()
        .nullable(true)
        .when('date_start', {
          is: value => value,
          then: Yup.date().nullable(true).required('End date is required'),
        }),
      boosting_budget: Yup.number().when('showBoosting', {
        is: value => value,
        then: Yup.number().min(1, 'Boosting budget is required').required('Boosting budget is required'),
      }),
      suggestedCreators: Yup.string().when('showCreators', {
        is: value => value,
        then: Yup.string().required('Suggested Creators is required'),
      }),
      brandProvidesProduct: Yup.string().when('showBrandProvidesProduct', {
        is: value => value,
        then: Yup.string().required('Brand Provides Product is required'),
      }),
      repostingPlatform: Yup.array().when('repostingDuration', {
        is: value => value,
        then: Yup.array().min(1, 'Reposting Platform is required').required('Reposting Platform is required'),
      }),
      boosting_duration: Yup.number().when('showBoosting', {
        is: value => value,
        then: Yup.number().min(1, 'Boosting duration is required').required('Boosting duration is required'),
      }),
      campaignName: Yup.string().trim().max(100, 'Please write less than 100 characters').required('Campaign name is required'),
      brandName: Yup.string().trim().max(100, 'Please write less than 100 characters'),
      itemName: Yup.string().trim().max(100, 'Please write less than 100 characters').required('Name is required'),
      initiator_organization_type: Yup.object().required('Initiator organization type is required'),
      exclusivity: Yup.string().when('showExclusivity', {
        is: value => value,
        then: Yup.string().trim().required('Exclusivity is required'),
      }),
    }),
    onSubmit: () => {
      submitHandler();
    },
  });

  const deliverablesFormik = useDeliverablesFormik({});

  const tabsData = [
    {
      title: 'Campaign Overview',
      content: <TabBrandDetails formik={formik} logoObject={logoObject} setLogoObject={setLogoObject} />,
      icon: <SpeakerSVG />,
    },
    {
      title: 'Visual Direction (Optional)',
      content: <TabVisualAssets formik={formik} visualElements={visualElements} setVisualElements={setVisualElements} />,
      icon: <VisualElementSVG />,
    },
    {
      title: 'Who do you want to collaborate with on the Campaign Brief?',
      content: <TabCollaborators formik={formik} pageType={briefPageType.CAMPAIGN_ITEM} />,
      icon: <SuitcaseSVG />,
    },
    {
      title: 'Campaign brief details',
      content: <TabBriefDetails formik={formik} deliverablesFormik={deliverablesFormik} />,
      icon: <DocumentSVG />,
    },
  ];

  const saveDraftHandler = () => {
    console.log('saveDraftHandler');
  };

  const downloadPDF = () => {
    console.log('downloadPDF');
  };

  const submitHandler = () => {
    if (currentOrganizationID) {
      const createBriefData = {
        name: formik.values.campaignName || null,
        brand_name: formik.values.brandName || null,
        notes: formik.values.notes || null,
        brand_description: formik.values.brandBackground || null,
        brand_provides_product: formik.values.showBrandProvidesProduct ? formik.values.brandProvidesProduct : null,

        // for brief item
        item_name: formik.values.itemName,
        key_message: formik.values.messaging || null,
        initiator_organization_type: formik.values.initiator_organization_type.type,
        objective: JSON.stringify(formik.values.campaignObjective),
        date_start: formik.values.date_start ? dayjs(formik.values.date_start).format('YYYY-MM-DD') : null,
        date_end: formik.values.date_end ? dayjs(formik.values.date_end).format('YYYY-MM-DD') : null,
        live_date_start: formik.values.date_post ? dayjs(formik.values.date_post).format('YYYY-MM-DD') : null,
        live_date_end: null,
        ...(formik.values.suggestedCreators && { suggested_creators: formik.values.suggestedCreators }),
        ...(formik.values.suggestedCreatorsDescription && { suggested_creators_description: formik.values.suggestedCreatorsDescription }),
        // separate by coma
        target_audience: JSON.stringify({
          location: {
            country: formik.values.country,
            city: formik.values.city,
          },
          gender: formik.values.gender ? [formik.values.gender] : [],
          age: {
            min: formik.values.ageMin, // Number 13-65, 65 = 65+
            max: formik.values.ageMax, // Number 13-65
          },
          interests_and_behaviours: formik.values.interests,
        }),
        exclusivity: formik.values.exclusivity,
        reposting_platform: formik.values.repostingPlatform?.length ? JSON.stringify(formik.values.repostingPlatform) : null,
        reposting_duration: formik.values.repostingPlatform?.length ? formik.values.repostingDuration : null,
        hashtags: JSON.stringify(formik.values.hashtags),
        social_tagging: JSON.stringify(formik.values.socialTagging),
        deliverables: JSON.stringify(deliverablesFormik.values.deliverables),
        total_budget: formik.values.total_budget || null,
        boosting_budget: formik.values.boosting_budget || null,
        boosting_duration: formik.values.boosting_duration || null,
        boosting_manager: !!formik.values.boosting_manager.length ? JSON.stringify(formik.values.boosting_manager) : null,
        total_budget_currency: formik.values.total_budget_currency,
        boosting_budget_currency: formik.values.total_budget_currency, // INTENDED!!!
        preferred_ad_declaration: formik.values.adInCaption ? 'ad_in_caption' : 'paid_partnership_tool',
        other_infos: formik.values.other_infos || null,
      };

      if (logoObject.file) {
        createBriefData['brand_logo'] = logoObject.file;
      }

      dispatch(
        createBrief(
          currentOrganizationID,
          createBriefData,
          visualElements,
          {
            inviteEmail: formik.values.inviteEmail?.trim(),
            inviteType: formik.values.inviteType,
          },
          (campaign, campaignBrief) => history.push(`/campaign/${campaign.id}/campaign-brief/${campaignBrief.id}`)
        )
      );
    }
  };

  const clearFailure = () => {
    formik.setSubmitting(false);
    dispatch(clearBriefFailure());
  };

  const cancelHandler = () => {
    history.push('/campaign');
  };

  return (
    <form onSubmit={formik.handleSubmit} onKeyDown={e => e.keyCode === 13 && e.preventDefault()}>
      <ErrorModal
        open={!!failure.errors}
        onClose={() => dispatch(clearFailure)}
        text={failure?.errors?.body.map(item => item.message).join(', ')}
      />
      <MainLayout title={'Campaign Overview'} contentClassName={styles.root} coloredHeader>
        <BriefSubHeader
          buttonTitle={'Create Brief'}
          onSaveDraft={saveDraftHandler}
          onPreviewPDF={downloadPDF}
          onCancel={cancelHandler}
          canSubmit={currentOrganizationID && formik.dirty && formik.isValid && deliverablesFormik.isValid && !formik.isSubmitting}
        />
        <BriefPageContentWrapper>
          {tabsData.map(item => (
            <div key={item.title}>
              <BriefTab data={item} />
            </div>
          ))}
        </BriefPageContentWrapper>
      </MainLayout>
    </form>
  );
}

export default CreateCampaignItem;
