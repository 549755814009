import React from 'react';
import styles from '../WatchDemoModal/WatchDemoModal.module.scss';
import { Modal as ReactModal } from 'react-responsive-modal';
import cs from 'classnames';
import { CloseIcon } from '../../../expert/components/IconsSvg/ReactIcons/ReactIcons';
import Button from '../../../expert/components/Buttons/ButtonCustom';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

const URL = process.env.REACT_APP_BRIEFS_DEMO_VIDEO_URL;
const EXTENSION = URL?.trim().match(/(https?.*\/[^.]*\.)(\w*)/)[2];
const OGG_EXTENSION = /og/.test(EXTENSION) ? 'ogg' : null;

const WatchDemoBriefsModal = props => {
  const modalStyles = {
    overlay: {
      padding: '20px',
      backdropFilter: 'blur(3px)',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    modal: {
      background: '#fff',
      padding: 0,
      borderRadius: '10px',
      maxWidth: props.maxWidth || '700px',
      width: '100%',
      boxShadow: ' 0 0 20px rgba(0, 0, 0, 0.1)',
    },
    closeButton: {
      display: 'none',
    },
  };
  const userId = useSelector(state => state.auth.user.id);

  const onCloseIconHandler = () => {
    if (props.onCloseByIcon) {
      props.onCloseByIcon();
    }
    props.onClose();
  };

  const onPauseAction = () => {
    window.analytics.track('Pause', {
      category: 'Videos',
      userId: userId,
      label: `Campaign Demo Video`,
    });
  };

  return (
    <ReactModal center open={props.open} onClose={props.onClose} styles={modalStyles}>
      <div className={cs(styles.root, props.className)}>
        <button onClick={onCloseIconHandler} className={styles.close} disabled={props.loading}>
          <CloseIcon size={30} />
        </button>

        <div className={styles.watchDemoContainer}>
          <div className={styles.purpleHeader}>Campaign briefs</div>
          <video width="100%" controls autoPlay={true} onPause={() => onPauseAction()} onPlay={() => props.onPlay()}>
            <source src={URL} type={`video/${OGG_EXTENSION ?? EXTENSION}`} />
          </video>
          <div className={styles.popupFooter}>
            <NavLink
              onClick={() => {
                window.analytics.track('campaign_brief_initiate', {
                  category: 'Campaign Brief',
                  userId: userId,
                });
                props.onClose();
              }}
              to={'/campaign/create'}
              style={{ outline: 'none', textDecoration: 'initial' }}
            >
              <Button>{'New Campaign'}</Button>
            </NavLink>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default WatchDemoBriefsModal;
