import React, { useEffect, useState } from 'react';
import styles from './BriefItemCell.module.scss';
import { ReactComponent as FacebookIcon } from '../../../../../assets/img/icons/facebookCircle.svg';
import { ReactComponent as InstagramIcon } from '../../../../../assets/img/icons/instagram.svg';
import { ReactComponent as TriangleIcon } from '../../../../../assets/img/icons/play-white-arrow.svg';
import { getObjectiveDisplayName } from '../../../../CreateTest/utils';
import cs from 'classnames';
import { useHistory, useRouteMatch } from 'react-router-dom';
import LightPurpleButton from '../LightPurpleButton';
import TikTokSVG from '../../../../../components/IconsSvg/TikTokSVG';
import YoutubeSVG from '../../../../../components/IconsSvg/YoutubeSVG';
import { useSelector } from 'react-redux';
import { getBriefItems } from '../../../../../services/brief';
import { useMediaQuery } from 'react-responsive/src';
import CampaignBriefCollaborators from '../../../components/CampaignBriefCollaborators';

const BriefItemCell = ({ data, className, isSubBrief }) => {
  const history = useHistory();
  const [isExpanded, setIsExpanded] = useState(false);
  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  const match = useRouteMatch();
  const [briefSubItems, setbriefSubItems] = useState([]);

  useEffect(() => {
    if (data.parent_id === 0) {
      getBriefItems(currentOrganizationID, match.params.campaignID, { parent_id: data.id }).then(response =>
        setbriefSubItems(response.data.data)
      );
    }
  }, []);

  const arrowContainerHandler = async () => {
    setIsExpanded(!isExpanded);
  };

  const viewDetailsHandler = () => {
    if (data.parent_id) {
      history.push(`/campaign/${match.params.campaignID}/campaign-brief/${data.parent_id}/creator-brief/${data.id}`);
      return;
    }
    history.push(`/campaign/${match.params.campaignID}/campaign-brief/${data.id}`);
  };

  return !isMobile ? (
    <>
      <div>
        <div className={cs(styles.root, className, { [styles.withoutArrow]: briefSubItems.length === 0 })}>
          <div className={styles.flexBox}>
            <div className={styles.briefName}>
              {data.name || 'No name yet'}
              {isSubBrief && <span className={styles.subBriefContent}>Content Brief</span>}
            </div>
          </div>
          <div className={styles.platforms}>
            {data.deliverables?.find(item => item.platform === 'facebook') && (
              <div className={styles.platform}>
                <FacebookIcon />
              </div>
            )}
            {data.deliverables?.find(item => item.platform === 'instagram') && (
              <div className={styles.platform}>
                <InstagramIcon />
              </div>
            )}
            {data.deliverables?.find(item => item.platform === 'tiktok') && (
              <div className={styles.platform}>
                <TikTokSVG />
              </div>
            )}
            {data.deliverables?.find(item => item.platform === 'youtube') && (
              <div className={styles.platform}>
                <YoutubeSVG />
              </div>
            )}
          </div>
          {!isMobile && <div className={styles.objective}>{data.objective.map(item => getObjectiveDisplayName(item)).join(', ')}</div>}
          <div className={styles.collaborators}>
            <CampaignBriefCollaborators campaignID={match.params.campaignID} currentBriefItem={data} disableInvitedCollaborators={true} />
          </div>
          {!isMobile && <div className={styles.statusBox}>{data.status?.replace('_', ' ')}</div>}
          <div className={styles.buttons}>
            <LightPurpleButton className={styles.viewDetailsButton} isSmall onClick={viewDetailsHandler}>
              {!isMobile && 'View'} Details
            </LightPurpleButton>
            {!isSubBrief && data.parent_id === 0 && briefSubItems.length > 0 && (
              <div className={cs(styles.arrowContainer, { [styles.rotated]: isExpanded })} onClick={arrowContainerHandler}>
                <TriangleIcon />
              </div>
            )}
          </div>
        </div>
      </div>
      {data.parent_id === 0 &&
        isExpanded &&
        briefSubItems.map(item => <BriefItemCell data={item} key={item.id} className={styles.subBriefItemCell} isSubBrief={true} />)}
    </>
  ) : (
    <>
      <div>
        <div className={cs(styles.root, className, { [styles.withoutArrow]: briefSubItems.length === 0 })}>
          <div>
            <div className={styles.flexBox}>
              <div className={styles.briefName}>
                <div className={styles.name}>{data.name || 'No name yet'}</div>
                {isSubBrief && <span className={styles.subBriefContent}>Content Brief</span>}
              </div>
            </div>
            <div className={styles.platforms}>
              {data.deliverables?.find(item => item.platform === 'facebook') && (
                <div className={styles.platform}>
                  <FacebookIcon />
                </div>
              )}
              {data.deliverables?.find(item => item.platform === 'instagram') && (
                <div className={styles.platform}>
                  <InstagramIcon />
                </div>
              )}
              {data.deliverables?.find(item => item.platform === 'tiktok') && (
                <div className={styles.platform}>
                  <TikTokSVG />
                </div>
              )}
              {data.deliverables?.find(item => item.platform === 'youtube') && (
                <div className={styles.platform}>
                  <YoutubeSVG />
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={styles.buttons}>
              <LightPurpleButton className={styles.viewDetailsButton} isSmall onClick={viewDetailsHandler}>
                View Details
              </LightPurpleButton>
              {!isSubBrief && data.parent_id === 0 && briefSubItems.length > 0 && (
                <div className={cs(styles.arrowContainer, { [styles.rotated]: isExpanded })} onClick={arrowContainerHandler}>
                  <TriangleIcon />
                </div>
              )}
            </div>
            <div className={styles.collaborators}>
              <CampaignBriefCollaborators campaignID={match.params.campaignID} currentBriefItem={data} disableInvitedCollaborators={true} />
            </div>
          </div>
        </div>
        {data.parent_id === 0 &&
          isExpanded &&
          briefSubItems.map(item => <BriefItemCell data={item} key={item.id} className={styles.subBriefItemCell} isSubBrief={true} />)}
      </div>
    </>
  );
};

export default BriefItemCell;
