import React from 'react';
import LoadingContainer from '../../../components/LoadingContainer';
import styles from './MyTeamHeaderLoading.module.scss';
import { useMediaQuery } from 'react-responsive/src';

const MyTeamHeaderLoading = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  return(
    <div className={styles.loading}>
      <LoadingContainer
        className={!isMobile ? styles.icon : styles.iconMobile}
        width={isMobile ? '34px' : '44px'}
        height={isMobile ? '34px' : '44px'}
        borderRadius='50%'
        backgroundColor='rgba(255, 255, 255, 0.2)'
        margin = '0 15px 0 0'
      />
      <LoadingContainer
        width='89px'
        height='26px'
        borderRadius='100px'
        backgroundColor='rgba(255, 255, 255, 0.2)'
        margin = '0 15px 0 0'
      />
      <LoadingContainer
        className={isMobile && styles.mobileStyle}
        width={isMobile ? '80px' : '135px'}
        height='26px'
        borderRadius='100px'
        backgroundColor='rgba(255, 255, 255, 0.2)'
        margin = '0 15px 0 0'
      />
      {
        !isMobile &&
        <LoadingContainer
          width='130px'
          height='26px'
          borderRadius='100px'
          backgroundColor='rgba(255, 255, 255, 0.2)'
        />
      }
    </div>
  )
};

export default MyTeamHeaderLoading;
