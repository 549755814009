import React, { useEffect, useRef, useState } from 'react';


const useSquareSize = (ref) => {
  const [width, setWidth] = useState(null);

  useEffect(() => {
    resize();
  });

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, []);

  const resize = () => {
    const gif = ref?.current;
    if (gif && width !== gif.clientWidth) {
      gif.style.height = gif.clientWidth + 'px';
      setWidth(gif.clientWidth);
    }
  }
};

export default useSquareSize;
