import React from 'react';

function ArrowDownSVG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" fill="none" viewBox="0 0 14 8">
      <path stroke="#E5E5E5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1l6 6 6-6"></path>
    </svg>
  );
}

export default ArrowDownSVG;
