import React from 'react';
import styles from './ConfirmDeleteVisualAssetModal.module.scss';
import { Modal } from '..';
import Button from '../../../expert/components/Buttons/ButtonCustom';

const ConfirmDeleteVisualAssetModalContent = ({ onSubmit, onClose }) => {
  return (
    <div className={styles.root}>
      <div className={styles.title}>Delete visual?</div>
      <div className={styles.navigationBtns}>
        <Button className={styles.btn} onClick={onSubmit}>
          Yes, delete
        </Button>
        <Button className={styles.btn} outline onClick={onClose}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default props => {
  return (
    <Modal borderRadius="10px" className={styles.modalRoot} {...props} maxWidth={395}>
      <ConfirmDeleteVisualAssetModalContent {...props} />
    </Modal>
  );
};
