import React, { useState } from 'react';
import styles from './VisualElement.module.scss';
import { Modal } from '../../../../../components/Modals';
import { ReactComponent as CloseIcon } from '../../../../../assets/img/icons/close.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/img/icons/trash.svg';
import SmallLoader from '../../../../../components/SmallLoader';
import DownloadSVG from '../../../../../expert/components/IconsSvg/DownloadSVG';
import useDownloadAsset from '../../../../../shared/hooks/useDownloadAsset';
import FormattedText from '../FormattedText';

function VisualElement({ data, deleteVisual }) {
  const [modalState, setModalState] = useState(false);
  const { isAssetLoading, downloadAsset } = useDownloadAsset();

  const onDownloadAsset = () => {
    if (isAssetLoading) return;
    downloadAsset(data.location, data.extension);
  };

  return (
    <>
      <div className={styles.gridItem} onClick={() => setModalState(true)}>
        <img src={data.thumbnail_location || data.location} alt={data.description} />
        <div className={styles.overlay}>See Details</div>
      </div>
      <Modal open={modalState} onClose={() => setModalState(false)} maxWidth={'1270px'} className={styles.modalRoot}>
        <div className={styles.modalContentWrapper}>
          <img className={styles.imageBackground} src={data.location} alt={data.description} />
          <img className={styles.image} src={data.location} alt={data.description} />
        </div>
        <div className={styles.rightSide}>
          <div className={styles.closeIcon} onClick={() => setModalState(false)}>
            <CloseIcon />
          </div>
          <div className={styles.name}>{''}</div>
          <div className={styles.columnFlex}>
            <FormattedText className={styles.description} text={data.description || ''} withoutCollapse />
            <div className={styles.buttonsContainer}>
              {data.extension && (
                <div className={styles.downloadButton} onClick={onDownloadAsset}>
                  {isAssetLoading ? <SmallLoader /> : <DownloadSVG />}
                  Download
                </div>
              )}
              <div
                className={styles.deleteButton}
                onClick={() => {
                  deleteVisual(data.id);
                  setModalState(false);
                }}
              >
                <DeleteIcon />
                <span>Delete visual</span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default VisualElement;
