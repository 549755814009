import React, { useState } from 'react';
import { downloadFile } from '../utils/helpers';

const getFileName = extension => `asset-${Math.floor(Math.random() * 1e6)}.${extension}`;

const useDownloadAsset = () => {
  const [isAssetLoading, setIsAssetLoading] = useState(false);
  const [isAssetsArrayLoading, setIsAssetsArrayLoading] = useState(false);

  /**
   *
   * @param {string} assetUrl
   * @param {string} extension
   */
  const downloadAsset = (assetUrl, extension) => {
    setIsAssetLoading(true);
    downloadFile(assetUrl, getFileName(extension)).then(() => setIsAssetLoading(false));
  };

  /**
   *
   * @param {Array<{assetUrl: string, extension: string}>} assetsArray
   */
  const downloadAssetsArray = assetsArray => {
    const allCount = assetsArray.length;
    if (allCount) {
      setIsAssetsArrayLoading(true);
    }
    let doneCount = 0;
    for (const asset of assetsArray) {
      downloadFile(asset.assetUrl, getFileName(asset.extension)).then(() => {
        doneCount++;
        if (doneCount >= allCount) {
          setIsAssetsArrayLoading(false);
        }
      });
    }
  };

  return {
    isAssetLoading,
    isAssetsArrayLoading,
    downloadAsset,
    downloadAssetsArray,
  };
};

export default useDownloadAsset;
