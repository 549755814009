/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import * as testsServices from '../services/tests';
import * as creativeStudioServices from '../services/creativeStudio';

export const TESTS_TYPES = {
  CREATE_TEST: {
    REQUEST: 'CREATE_TEST_REQUEST',
    SUCCESS: 'CREATE_TEST_SUCCESS',
    FAILURE: 'CREATE_TEST_FAILURE',
  },
  GET_WINNER_ADS: {
    REQUEST: 'GET_WINNER_ADS_REQUEST',
    SUCCESS: 'GET_WINNER_ADS_SUCCESS',
    FAILURE: 'GET_WINNER_ADS_FAILURE',
  },
  GET_TESTS: {
    REQUEST: 'GET_TESTS_REQUEST',
    SUCCESS: 'GET_TESTS_SUCCESS',
    FAILURE: 'GET_TESTS_FAILURE',
  },
  GET_TEST: {
    REQUEST: 'GET_TEST_REQUEST',
    SUCCESS: 'GET_TEST_SUCCESS',
    FAILURE: 'GET_TEST_FAILURE',
  },
  GET_TEST_FULL: {
    REQUEST: 'GET_TEST_FULL_REQUEST',
    SUCCESS: 'GET_TEST_FULL_SUCCESS',
    FAILURE: 'GET_TEST_FULL_FAILURE',
  },
  GET_TEST_ADS: {
    REQUEST: 'GET_TEST_ADS_REQUEST',
    SUCCESS: 'GET_TEST_ADS_SUCCESS',
    FAILURE: 'GET_TEST_ADS_FAILURE',
  },
  CHANGE_STATUS_TESTS: {
    REQUEST: 'CHANGE_STATUS_TESTS_REQUEST',
    SUCCESS: 'CHANGE_STATUS_TESTS_SUCCESS',
    FAILURE: 'CHANGE_STATUS_TESTS_FAILURE',
  },
  REFRESH_TESTS: {
    REQUEST: 'REFRESH_TESTS_REQUEST',
    SUCCESS: 'REFRESH_TESTS_SUCCESS',
    FAILURE: 'REFRESH_TESTS_FAILURE',
  },
  DELETE_TEST: {
    REQUEST: 'DELETE_TEST_REQUEST',
    SUCCESS: 'DELETE_TEST_SUCCESS',
    FAILURE: 'DELETE_TEST_FAILURE',
  },
};

export const createMultipleTests = data => async (dispatch, getState) => {
  try {
    dispatch({
      type: TESTS_TYPES.CREATE_TEST.REQUEST,
    });
    const currentOrg = getState().auth.currentOrganization;
    const ads = await Promise.all(
      data.adsData.map(d => creativeStudioServices.createAd({ ...d, organization_id: currentOrg.organization_id }))
    );

    const fb_ad_template_ids = ads.map(ad => ad.data[0].id);

    const testResp = await testsServices.createTest({
      ...data.testData,
      fb_ad_template_ids,
      organization_id: currentOrg?.organization_id,
      even_budget_distribution: true,
    });
    dispatch({
      type: TESTS_TYPES.CREATE_TEST.SUCCESS,
      payload: testResp.data[0],
    });
    return testResp.data[0];
  } catch (error) {
    dispatch({
      type: TESTS_TYPES.CREATE_TEST.FAILURE,
      payload: error.response.data,
    });

    throw error.response.data;
  }
};

export const createTest = data => async (dispatch, getState) => {
  try {
    dispatch({
      type: TESTS_TYPES.CREATE_TEST.REQUEST,
      payload: {},
    });
    const currentOrg = getState().auth.currentOrganization;
    const sendData = { ...data, organization_id: currentOrg?.organization_id, even_budget_distribution: true };

    const res = await testsServices.createTest(sendData);

    dispatch({
      type: TESTS_TYPES.CREATE_TEST.SUCCESS,
      payload: res.data[0],
    });
    return res.data[0];
  } catch (error) {
    dispatch({
      type: TESTS_TYPES.CREATE_TEST.FAILURE,
      payload: error.response.data,
    });
    return error;
  }
};

export const createTestByEntryData = data => async (dispatch, getState) => {
  try {
    dispatch({
      type: TESTS_TYPES.CREATE_TEST.REQUEST,
      payload: {},
    });

    const res = await testsServices.createTest(data);

    dispatch({
      type: TESTS_TYPES.CREATE_TEST.SUCCESS,
      payload: res.data[0],
    });
    return res.data[0];
  } catch (error) {
    dispatch({
      type: TESTS_TYPES.CREATE_TEST.FAILURE,
      payload: error.response.data,
    });

    throw error.response.data;
  }
}

export const getTests =
  (include_insights, preset, insights_date_start, insights_date_end, filters, searchString, page, pageSize) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: TESTS_TYPES.GET_TESTS.REQUEST,
      });

      const {
        auth: { currentOrganization: currentOrg },
      } = getState();
      const organization_id = currentOrg?.organization_id;
      const params = {};
      params.type = 'test';
      params.include_creator_details = true;
      if (organization_id) params.organization_id = organization_id;
      if (typeof include_insights === 'boolean') params.include_insights = include_insights;
      if (insights_date_start) params.insights_date_start = insights_date_start;
      if (insights_date_end) params.insights_date_end = insights_date_end;
      if (preset && !insights_date_start) params.insights_date_preset = preset;
      if (filters && filters.status !== 'all') params.status = filters.status;
      if (filters && filters.type !== 'all') params.test_type = filters.type;
      if (filters && filters.objective !== 'all') params.objective = filters.objective;
      if (searchString) params.search_filter = searchString;
      if (page) params.page = page;
      if (pageSize) params.page_size = pageSize;
      const res = await testsServices.getTests(null, params);

      dispatch({
        type: TESTS_TYPES.GET_TESTS.SUCCESS,
        payload: res.data,
        totalCount: res.total_count,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: TESTS_TYPES.GET_TESTS.FAILURE,
        payload: error.response.data,
      });
    }
  };

export const getTest = (id, insights_date_start, insights_date_end) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TESTS_TYPES.GET_TEST.REQUEST,
    });
    const params = {};
    if (insights_date_start) params.insights_date_start = insights_date_start;
    if (insights_date_end) params.insights_date_end = insights_date_end;
    const res = await testsServices.getTestOverview(id, params);

    dispatch({
      type: TESTS_TYPES.GET_TEST.SUCCESS,
      payload: res.data[0],
    });
    return res;
  } catch (error) {
    dispatch({
      type: TESTS_TYPES.GET_TEST.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getTestFull = id => async dispatch => {
  try {
    dispatch({
      type: TESTS_TYPES.GET_TEST_FULL.REQUEST,
    });

    const res = await testsServices.getTestFullOverview(id);

    dispatch({
      type: TESTS_TYPES.GET_TEST_FULL.SUCCESS,
      payload: res.data[0],
    });
    return res;
  } catch (error) {
    dispatch({
      type: TESTS_TYPES.GET_TEST_FULL.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getTestAds = id => async (dispatch, getState) => {
  try {
    dispatch({
      type: TESTS_TYPES.GET_TEST_ADS.REQUEST,
    });
    const {
      auth: { currentOrganization: currentOrg },
    } = getState();
    const organization_id = currentOrg.organization_id;
    const res = await testsServices.getTestAds(id, organization_id);

    dispatch({
      type: TESTS_TYPES.GET_TEST_ADS.SUCCESS,
      payload: res.data,
    });
    return res;
  } catch (error) {
    dispatch({
      type: TESTS_TYPES.GET_TEST_ADS.FAILURE,
      payload: error.response.data,
    });
  }
};

export const refreshTests = ids => async (dispatch, getState) => {
  try {
    dispatch({
      type: TESTS_TYPES.REFRESH_TESTS.REQUEST,
    });

    const {
      ad_integration: { ad_integrations },
    } = getState();

    const organization_id = ad_integrations.find(ad => ad.type === 'facebook')?.organization_id;
    const res = await testsServices.refreshTests({ organization_id, ids });

    dispatch({
      type: TESTS_TYPES.REFRESH_TESTS.SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: TESTS_TYPES.REFRESH_TESTS.FAILURE,
      payload: error.response.data,
    });
  }
};

export const changeStatusTests = (ids, status) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TESTS_TYPES.CHANGE_STATUS_TESTS.REQUEST,
      payload: status,
    });

    const {
      ad_integration: { ad_integrations },
    } = getState();

    const organization_id = ad_integrations.find(ad => ad.type === 'facebook')?.organization_id;
    const res = await testsServices.changeStatusTests({ organization_id, ids, status });

    dispatch({
      type: TESTS_TYPES.CHANGE_STATUS_TESTS.SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: TESTS_TYPES.CHANGE_STATUS_TESTS.FAILURE,
      payload: { error, status },
    });
  }
};

export const getWinnerAds =
  (filters, searchString, orderType, orderBy, page, pageSize, insights_date_start, insights_date_end, preset) =>
    async (dispatch, getState) => {
  try {
    dispatch({
      type: TESTS_TYPES.GET_WINNER_ADS.REQUEST,
    });
    const { auth: { currentOrganization: currentOrg } } = getState();
    const params = {};
    params.include_ad_sets = true;
    params.include_ads = true;
    params.include_insights = true;
    params.type = 'campaign';
    params.test_id = '!null';
    if (orderBy) params.order_by = orderBy;
    if (orderType) params.order_type = orderType;
    if (searchString) params.search_filter = searchString;
    if (filters && filters.type !== 'all') params.test_type = filters.type;
    if (filters && filters.objective !== 'all') params.objective = filters.objective;
    if (insights_date_start) params.insights_date_start = insights_date_start;
    if (insights_date_end) params.insights_date_end = insights_date_end;
    if (preset) params.insights_date_preset = preset;
    params.organization_id = currentOrg.organization_id;

    const res = await testsServices.getWinnerAds(params);

    dispatch({
      type: TESTS_TYPES.GET_WINNER_ADS.SUCCESS,
      payload: res.data,
    });
    return res;
  } catch (error) {
    dispatch({
      type: TESTS_TYPES.GET_WINNER_ADS.FAILURE,
      payload: error.response?.data,
    });
  }
};
