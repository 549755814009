export const SIGN_UP_FLOW_TYPES = {
  ONE: 'SIGN_UP_FLOW_ONE',
  TWO: 'SIGN_UP_FLOW_TWO',
  THREE: 'SIGN_UP_FLOW_THREE',
};

export const USER_TYPES = {
  INFLUENCER: 'influencer',
  ADVERTISER: 'advertiser',
};

export const USER_TYPES_TEXT = {
  [USER_TYPES.INFLUENCER]: 'Influencer',
  [USER_TYPES.ADVERTISER]: 'Advertiser',
};

export const FORGOT_PASSWORD_TYPES = {
  ONE: 'FORGOT_PASSWORD_FLOW_ONE',
  TWO: 'FORGOT_PASSWORD_FLOW_TWO',
};

export const allFbPermissions = [
  {
    name: 'ads_management',
    description:
      'The ads_management permission allows your app to both read and manage the Ads account it owns, or has been granted access to, by the Ad account owner.',
  },
  {
    name: 'business_management',
    description: 'The business management permission allows your app to read and write with the Business Manager API',
  },
  {
    name: 'pages_read_engagement',
    description:
      'The pages_read_engagement permission allows your app to read content (posts, photos, videos, events) posted by the Page, read followers data (including name, PSID), and profile picture, and read metadata and other insights',
  },
  {
    name: 'pages_show_list',
    description: 'The pages_show_list permission allows your app to access the list of Pages a person manages.',
  },
  {
    name: 'public_profile',
    description:
      'Allows apps to read the Default Public Profile Fields on the User node. This permission is automatically granted to all apps.',
  },
  {
    name: 'Ads Management Standard Access',
    description: 'The Ads Management Standard Access feature allows your app to access the Marketing API.',
  },
];

export const TYPES_CAMPAIGNS = {
  BRAND: 'brand',
  AGENCY: 'agency',
  TALENT_MANAGER: 'talent_manager',
  CONTENT_CREATOR: 'content_creator',
  ADVERTISER: 'advertiser',
};

export const TYPES_CAMPAIGNS_TEXTS = {
  [TYPES_CAMPAIGNS.ADVERTISER]: 'Advertiser',
  [TYPES_CAMPAIGNS.BRAND]: 'Brand',
  [TYPES_CAMPAIGNS.AGENCY]: 'Agency',
  [TYPES_CAMPAIGNS.TALENT_MANAGER]: 'Talent Manager',
  [TYPES_CAMPAIGNS.CONTENT_CREATOR]: 'Content Creator',
};

// for board
export const TYPES_CAMPAIGNS_OBJECTS = [
  { name: TYPES_CAMPAIGNS_TEXTS[TYPES_CAMPAIGNS.BRAND], type: TYPES_CAMPAIGNS.BRAND },
  { name: TYPES_CAMPAIGNS_TEXTS[TYPES_CAMPAIGNS.AGENCY], type: TYPES_CAMPAIGNS.AGENCY },
  { name: TYPES_CAMPAIGNS_TEXTS[TYPES_CAMPAIGNS.TALENT_MANAGER], type: TYPES_CAMPAIGNS.TALENT_MANAGER },
  { name: TYPES_CAMPAIGNS_TEXTS[TYPES_CAMPAIGNS.CONTENT_CREATOR], type: TYPES_CAMPAIGNS.CONTENT_CREATOR },
];

// for campaign brief
export const TYPES_CAMPAIGN_BRIEF_OBJECT = [
  { name: TYPES_CAMPAIGNS_TEXTS[TYPES_CAMPAIGNS.BRAND], type: TYPES_CAMPAIGNS.BRAND },
  { name: TYPES_CAMPAIGNS_TEXTS[TYPES_CAMPAIGNS.AGENCY], type: TYPES_CAMPAIGNS.AGENCY },
  { name: TYPES_CAMPAIGNS_TEXTS[TYPES_CAMPAIGNS.TALENT_MANAGER], type: TYPES_CAMPAIGNS.TALENT_MANAGER },
];

// for creator brief
export const TYPES_CREATOR_BRIEF_OBJECT = [
  { name: TYPES_CAMPAIGNS_TEXTS[TYPES_CAMPAIGNS.CONTENT_CREATOR], type: TYPES_CAMPAIGNS.CONTENT_CREATOR },
];

export const scriptSmartAdsFeedback =
  '(function(){var e="ss-widget",t="script",a=document,r=window;var s,n,c;r.SS_WIDGET_TOKEN="tt-608a1bcd49";r.SS_ACCOUNT="otterfish.surveysparrow.com";r.SS_SURVEY_NAME="In-App-Smart-Ads-Survey";if(!a.getElementById(e)){var S=function(){S.update(arguments)};S.args=[];S.update=function(e){S.args.push(e)};r.SparrowLauncher=S;s=a.getElementsByTagName(t);c=s[s.length-1];n=a.createElement(t);n.type="text/javascript";n.async=!0;n.id=e;n.src=["https://","otterfish.surveysparrow.com/widget/",r.SS_WIDGET_TOKEN].join("");c.parentNode.insertBefore(n,c)}})();';
export const scriptInstantCreativeFeedback =
  '(function(){var e="ss-widget",t="script",a=document,r=window;var s,n,c;r.SS_WIDGET_TOKEN="tt-db3ad8";r.SS_ACCOUNT="otterfish.surveysparrow.com";r.SS_SURVEY_NAME="How-did-we-do----Instant-Creative";if(!a.getElementById(e)){var S=function(){S.update(arguments)};S.args=[];S.update=function(e){S.args.push(e)};r.SparrowLauncher=S;s=a.getElementsByTagName(t);c=s[s.length-1];n=a.createElement(t);n.type="text/javascript";n.async=!0;n.id=e;n.src=["https://","otterfish.surveysparrow.com/widget/",r.SS_WIDGET_TOKEN].join("");c.parentNode.insertBefore(n,c)}})();';
export const scriptNpsSurvey =
  '(function(){var e="ss-widget",t="script",a=document,r=window;var s,n,c;r.SS_WIDGET_TOKEN="tt-d4bd78";r.SS_ACCOUNT="otterfish.surveysparrow.com";r.SS_SURVEY_NAME="In-App-NPS-Survey";if(!a.getElementById(e)){var S=function(){S.update(arguments)};S.args=[];S.update=function(e){S.args.push(e)};r.SparrowLauncher=S;s=a.getElementsByTagName(t);c=s[s.length-1];n=a.createElement(t);n.type="text/javascript";n.async=!0;n.id=e;n.src=["https://","otterfish.surveysparrow.com/widget/",r.SS_WIDGET_TOKEN].join("");c.parentNode.insertBefore(n,c)}})();';

export const allMessages = [
  {
    name: 'Michaela, agent',
    company: 'WeAreTENZING',
    massage: 'Otterfish allows us to increase the number of campaigns we can run per month, freeing our team up from time consuming tasks',
  },
  {
    name: 'Jordan Watson',
    company: 'How to Dad',
    massage:
      'Existing platforms make the mistake of trying to eliminate digital agencies and talent advocates, meaning the best agencies, creators and their representatives refuse to use them',
  },
];

export const placementsFormats = {
  static_post: 'Static Post',
  video_post: 'Video Post',
  story_post: 'Story Post',
  highlight: 'Highlight',
  carousel: 'Carousel Post',
  reel: 'Reel',
  live: 'Live',
};

export const ALLOWED_IMAGE_FORMATS = ['png', 'jpg', 'jpeg'];
