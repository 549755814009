import React from 'react';
import styles from './Tooltip.module.scss';
import cs from 'classnames';

const Tooltip = ({ text, top, left, transformStyle, className }) => {
  return (
    <div className={cs(styles.tooltip, styles[transformStyle], className)} style={{ top: `${top}px`, left: `${left}px` }}>
      {text}
    </div>
  );
};

export default Tooltip;
