import React, { useEffect, useMemo } from 'react';
import styles from './BriefList.module.scss';
import { useMediaQuery } from 'react-responsive/src';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../../components/Pagination';
import BriefsList from '../BriefsList';
import { setBriefListTab } from '../../../../actions/brief.actions';
import SwitcherTab from '../../../../components/BriefsAndBoardsFlows/RootListPagesComponents/SwitcherTab';

const BriefList = ({ page, setPage, pageSize, sort, setSort, onArchive, isNoSearchFiltersYet }) => {
  const dispatch = useDispatch();
  const { briefList, briefListLoading, totalCount, archivedTotalCount, briefListTabSwitcher } = useSelector(state => state.brief);
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  const switcherValues = useMemo(
    () => [
      { value: 'campaigns', count: totalCount },
      { value: 'archived', count: archivedTotalCount },
    ],
    [totalCount, archivedTotalCount]
  );

  const paginationTotalCount = useMemo(() => {
    return switcherValues.find(item => item.value === briefListTabSwitcher).count;
  }, [briefListTabSwitcher, switcherValues]);

  const handleSwitcherChange = value => {
    if (value === briefListTabSwitcher) return;
    dispatch(setBriefListTab(value));
  };

  const handleArchive = (brief, action) => {
    if (briefListTabSwitcher === 'archived' && action === 'unzip' && briefList.length === 1) {
      handleSwitcherChange('campaigns');
      return;
    } else if (briefListTabSwitcher === 'campaigns' && action === 'archive' && briefList.length === 1) {
      handleSwitcherChange('archived');
      return;
    }
    onArchive(brief, action);
  };

  useEffect(() => {
    if (briefList.length && briefList[0].is_archived && briefListTabSwitcher === 'campaigns' && isNoSearchFiltersYet) {
      dispatch(setBriefListTab('archived'));
    }
  }, [briefList]);

  return (
    <div className={styles.root}>
      <div className={styles.switch}>
        {switcherValues.map(item => (
          <SwitcherTab
            key={item.value}
            switcher={briefListTabSwitcher}
            setSwitcher={handleSwitcherChange}
            value={item.value}
            count={item.count}
          />
        ))}
      </div>

      <div className={styles.cellsWrapper}>
        <BriefsList
          briefs={briefList}
          briefsLoading={briefListLoading}
          sort={sort}
          setSort={setSort}
          isTablet={isTablet}
          onArchive={handleArchive}
        />
      </div>
      {!!briefList.length && paginationTotalCount > pageSize && (
        <Pagination
          className={styles.pagination}
          amount={pageSize}
          totalCount={paginationTotalCount}
          page={page}
          setPage={id => setPage(id)}
        />
      )}
    </div>
  );
};

export default BriefList;
