const initialState = {
  isMinimized: false,
  showMobileSideBar: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'TOGGLE_SIDEBAR':
      return {
        ...state,
        isMinimized: !state.isMinimized,
      };

    case 'TOGGLE_MOBILE_SIDEBAR':
      return {
        ...state,
        showMobileSideBar: payload,
      };
    default:
      return state;
  }
};
