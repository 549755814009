import React from 'react';
import styles from './LiveDueDate.module.scss';
import { ReactComponent as CalendarIcon } from '../../../assets/img/icons/due-live-calendar.svg';
import cs from 'classnames';
import useTooltip from '../../Menu/components/MenuInner/components/useTooltip';

const LiveDueDate = ({ type }) => {
  const styleColor = 'default';
  const tooltipText = 'Set a date to be reminded of when the content is due to go live';
  const { toolTip, onEnter, onLeave } = useTooltip(tooltipText, 'bottom', styles.tooltip);

  return (
    <div className={styles.wrapper}>
      <div className={cs(styles.root, { [styles.disabled]: false }, styles[styleColor])} onMouseEnter={onEnter} onMouseLeave={onLeave}>
        <CalendarIcon className={styles.icon} />
        <div className={styles.text}>Live Date</div>
        <div className={styles.removeBtn}></div>
        {toolTip}
      </div>
    </div>
  );
};

export default LiveDueDate;
