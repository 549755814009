/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';
import styles from './TeamMembers.module.scss';
import cs from 'classnames';
import { useMediaQuery } from 'react-responsive/src';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import { AddIcon } from '../../../expert/components/IconsSvg/ReactIcons/ReactIcons';
import TeamMemberCell from '../../../components/Cells/TeamMemberCell';
import LoaderModal from '../../../expert/components/Modal/LoaderModal';
import AgreeModal from '../../../expert/components/Modal/AgreeModal';
import { useDispatch, useSelector } from 'react-redux';
import { changeUserStatus, deleteMemberInvite, getAllMembers } from '../../../actions/organizations.actions';
import { NavLink } from 'react-router-dom';
import MembersSVG from '../../../components/IconsSvg/MembersSVG';
import { ReactComponent as IconUser } from '../../../assets/img/icons/IconUser.svg';
import PlanProgressBar from '../../../components/PlanProgressBar';
import TeamMembersLoading from './TeamMembersLoading';
import Pagination from '../../../components/Pagination';
import { useHistory } from 'react-router';
import InviteMemberModal from '../../../components/Modals/InviteMemberModal';
import { getPricingPlanForUser, inviteMember } from '../../../actions/user.actions';

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const componentMounted = useRef(true);
  const { data: members, totalCount, loading: memberLoading } = useSelector(state => state.organizations.allMembers);
  const membersInPlan = useSelector(state => state.user.pricingPlan.team_members);
  const currentMembers = useSelector(state => state.user.pricingPlan.usage?.team_members);
  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const user = useSelector(state => state.auth.user);
  const currentPlan = useSelector(state => state.user.pricingPlan);
  const loadingUpdate = useSelector(state => state.organizations.organizationMembers.loadingUpdate);
  const searchParams = new URLSearchParams(history.location.search);
  const urlPage = Number(searchParams.get('page'));
  /* iPad portrait is 768px */
  const isHorizontal = useMediaQuery({
    query: '(min-width: 769px)',
  });
  const [isRemoveModal, setIsRemoveModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState();
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(urlPage || 1);
  const status = 'active,pending';
  const pageSize = 10;
  const isFreePlan = user?.pricing_plan_id === 1001;
  const disabled = isFreePlan || (currentPlan?.team_members !== -1 && currentPlan?.usage?.team_members >= currentPlan?.team_members);
  const disabledInvite =
    currentOrg?.role.toLowerCase() !== 'owner' ||
    (currentPlan?.team_members !== -1 && currentPlan?.usage?.team_members >= currentPlan?.team_members) &&
    !isFreePlan;

  const loadPlanInfo = async () => {
    if (!currentOrg) {
      return;
    }
    history.replace(`/organization/members/?page=${page}`);
    await dispatch(getPricingPlanForUser());
    await dispatch(getAllMembers(currentOrg?.organization_id, page, pageSize, status));
    if (componentMounted.current) {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => (componentMounted.current = false);
  }, []);

  useEffect(() => {
    loadPlanInfo();
  }, [currentOrg]);

  useEffect(() => {
    if (!currentOrg) {
      return;
    }
    dispatch(getAllMembers(currentOrg?.organization_id, page, pageSize, status));
    history.replace(`/organization/members/?page=${page}`);
  }, [page]);

  const handleRemove = member => {
    setSelectedMember(member);
    setIsRemoveModal(true);
  };

  const onRemove = () => {
    setIsRemoveModal(false);
    if (selectedMember.type === 'invite') {
      dispatch(deleteMemberInvite(currentOrg.organization_id, selectedMember.invite_id)).then(() => {
        dispatch(getPricingPlanForUser());
        dispatch(getAllMembers(currentOrg?.organization_id, page, pageSize, status));
      });
    } else {
      dispatch(changeUserStatus(currentOrg.organization_id, [selectedMember.user_id], 'inactive')).then(() => {
        dispatch(getPricingPlanForUser());
        dispatch(getAllMembers(currentOrg?.organization_id, page, pageSize, status));
      });
    }
  };

  const onInvite = async email => {
    await dispatch(inviteMember(email)).then(response => {
      window.analytics.track('team_invite_sent', {
        category: 'Invite',
        userId: user.id,
        invite_id: response.data[0].id,
        email: email,
      });
      // if component still mounted
      if (componentMounted.current) {
        setLoading(false);
        dispatch(getPricingPlanForUser());
        dispatch(getAllMembers(currentOrg?.organization_id, page, pageSize, status));
      }
    });
  };

  return (
    <>
      {loading || memberLoading ? (
        <TeamMembersLoading role={currentOrg?.role?.toLowerCase()} />
      ) : (
        <div className={styles.wrapper}>
          {!currentOrg?.owner_has_valid_subscription && !loading && !isFreePlan && <div className={styles.isBlur} />}
          <div className={cs(styles.inviteMemberWrapper, { [styles.isLandscape]: isHorizontal })}>
            <div className={styles.membersActions}>
              <ButtonCustom
                onClick={() => setIsInviteOpen(true)}
                Icon={AddIcon}
                disabled={disabledInvite}
                className={cs({ [styles.notVisible]: currentOrg?.role.toLowerCase() !== 'owner' })}
              >
                Invite
              </ButtonCustom>
              {currentOrg?.role.toLowerCase() === 'owner' && currentPlan.id !== 1004 && !isFreePlan && (
                <div
                  className={cs(styles.inviteWarning, {
                    [styles.visible]: currentPlan?.team_members !== -1 && currentPlan?.usage?.team_members >= currentPlan?.team_members,
                  })}
                >
                  <NavLink to="/upgrade-plan">Upgrade your account</NavLink> to invite more people to the team.
                </div>
              )}
            </div>
            <div className={cs(styles.membersCounter, { [styles.isLandscape]: isHorizontal })}>
              <IconUser/>
              <div className={styles.title}>
                Members
              </div>
              <div className={styles.progressWrapper}>
                <PlanProgressBar current={currentPlan?.usage?.team_members} of={currentPlan?.team_members} />
              </div>
              <div className={styles.users}>
                {currentMembers}/{membersInPlan === -1 ? 'Unlimited' : membersInPlan}
              </div>
            </div>
          </div>
          <div>
            {members.length ? (
              <>
                {members.map((member, index) => {
                  return <TeamMemberCell member={member} key={index} org={currentOrg} handleRemove={handleRemove} />;
                })}
              </>
            ) : (
              <div className={styles.emptyContainer}>
                <div className={styles.empty}>
                  <MembersSVG />
                  <span>You have no team members yet</span>
                  <ButtonCustom onClick={() => setIsInviteOpen(true)} Icon={AddIcon} disabled={disabled}>
                    Invite Member
                  </ButtonCustom>
                </div>
              </div>
            )}
            {totalCount > pageSize && (
              <Pagination totalCount={totalCount} amount={pageSize} page={page} setPage={setPage} className={styles.pagination} />
            )}
          </div>
          <LoaderModal open={loadingUpdate} />
          {selectedMember && (
            <AgreeModal
              open={isRemoveModal}
              onClose={() => setIsRemoveModal(false)}
              onSubmit={onRemove}
              agreeText={'Yes, remove'}
              title={`Are you sure you want to remove ${
                selectedMember.first_name ? selectedMember.first_name : selectedMember.email
              } from the team?`}
            />
          )}
        </div>
      )}
      <InviteMemberModal open={isInviteOpen} onClose={() => setIsInviteOpen(false)} submitText={'Back to Team'} onSubmit={onInvite} />
    </>
  );
};
