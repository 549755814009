import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './AddVisualModal.module.scss';
import { Modal } from '../../../../../components/Modals';
import { briefAddVisualAsset } from '../../../../../actions/brief.actions';
import ButtonCustom from '../../../../../expert/components/Buttons/ButtonCustom';
import { ReactComponent as ShareIcon } from '../../../../../assets/img/icons/share.svg';
import { ReactComponent as UploadBigSVG } from '../../../../../assets/img/icons/upload-big-svg-2.svg';
import VisualBlock from '../../../components/VisualBlock';
import { validateImage } from '../../../../../shared/utils/helpers';

const AddVisualModal = ({ modalState, setModalState, currentCountVisualAssets }) => {
  const dispatch = useDispatch();

  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const currentBrief = useSelector(state => state.brief.currentBrief.data);

  // Array<{file: File, fileUrl: string, description: string}>
  const [visualElements, setVisualElements] = useState([]);
  const [isError, setIsError] = useState(false);
  const MAX_VISUAL_ASSETS = 10;

  const onSubmit = () => {
    setModalState(false);
    dispatch(briefAddVisualAsset(currentOrganizationID, currentBrief.id, visualElements, true));
    setVisualElements([]);
  };

  const onCancel = () => {
    setModalState(false);
  };

  const handleUploadVisualElements = event => {
    const files = [];
    for (let i = 0; i < event.target.files.length; i++) {
      files.push(validateImage(event.target.files[i]));
    }
    const elements = [];
    for (let i = 0; i < files.length; i++) {
      if (!files[i]) continue;
      const reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onloadend = () => {
        elements.push({ file: files[i], fileUrl: reader.result, description: '' });
      };
    }

    setTimeout(function () {
      const arr = [...visualElements, ...elements];
      if (visualElements.length + elements.length > MAX_VISUAL_ASSETS - currentCountVisualAssets) {
        arr.length = MAX_VISUAL_ASSETS - currentCountVisualAssets;
        setIsError(true);
      }
      setVisualElements(arr);
    }, 100);
    setTimeout(() => {
      setIsError(false);
    }, 3000);
  };

  const deleteVisualElement = fileUrl => {
    setVisualElements(visualElements.filter(element => element.fileUrl != fileUrl));
  };

  const setDescription = (value, fileUrl) => {
    const result = visualElements.map(element => {
      if (element.fileUrl == fileUrl) {
        return { ...element, description: value };
      }
      return element;
    });
    setVisualElements(result);
  };

  const setFileImage = (file, newFileUrl, fileUrl) => {
    const result = visualElements.map(element => {
      if (element.fileUrl == fileUrl) {
        return { ...element, file: file, fileUrl: newFileUrl };
      }
      return element;
    });
    setVisualElements(result);
  };

  return (
    <Modal
      open={modalState}
      onClose={() => setModalState(false)}
      className={styles.modalRoot}
      maxWidth={'760px'}
      closeBtnClassName={styles.closeBtn}
    >
      <div className={styles.header}>
        <h2 className={styles.headerTitle}>Upload Visuals</h2>
        <p className={styles.headerBody}>Uploading visual content will help creators understand the visual style you’re looking for.</p>
      </div>
      {visualElements.length > 0 && (
        <div className={styles.visualAssetsBlock}>
          {visualElements.map((visualElement, index) => (
            <div key={index + visualElement.fileUrl} style={{ marginTop: '15px' }}>
              <VisualBlock
                imgUrl={visualElement.fileUrl}
                description={visualElement.description}
                setDescription={value => setDescription(value, visualElement.fileUrl)}
                setFileImage={(file, newFileUrl) => setFileImage(file, newFileUrl, visualElement.fileUrl)}
                deleteVisualElement={deleteVisualElement}
                editButton={styles.edit}
                deleteButton={styles.delete}
              />
            </div>
          ))}
          {isError && <span className={styles.error}>Maximum number of visuals 10</span>}
        </div>
      )}

      {visualElements.length === 0 && (
        <div className={styles.body}>
          <div className={styles.bodyContent}>
            <UploadBigSVG />
            <ButtonCustom className={styles.visualButton} style={{ marginTop: '20px' }}>
              Upload Visuals
              <input
                type="file"
                multiple="multiple"
                accept="image/png, image/jpeg, image/jpg"
                onChange={handleUploadVisualElements}
                className={styles.fileInput}
              />
            </ButtonCustom>
          </div>
        </div>
      )}

      <div className={styles.footer}>
        <div className={styles.upload}>
          {visualElements.length > 0 && (
            <ButtonCustom
              outline
              Icon={ShareIcon}
              className={styles.visualButton}
              disabled={visualElements.length + currentCountVisualAssets >= MAX_VISUAL_ASSETS}
            >
              Upload Visuals
              <input
                type="file"
                multiple="multiple"
                accept="image/png, image/jpeg, image/jpg"
                onChange={handleUploadVisualElements}
                className={styles.fileInput}
              />
            </ButtonCustom>
          )}
        </div>

        <div className={styles.buttons}>
          <ButtonCustom onClick={onCancel} outline className={styles.cancelButton}>
            Cancel
          </ButtonCustom>
          <ButtonCustom onClick={onSubmit} disabled={visualElements.length === 0} className={styles.submitButton}>
            Upload
          </ButtonCustom>
        </div>
      </div>
    </Modal>
  );
};

export default AddVisualModal;
