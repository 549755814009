import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './BriefItemPage.module.scss';
import MainLayout from '../../../components/MainLayout';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import cs from 'classnames';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import { AddIcon } from '../../../expert/components/IconsSvg/ReactIcons/ReactIcons';
import { ReactComponent as TriangleIcon } from '../../../assets/img/icons/play-white-arrow.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/img/icons/facebookCircle.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/img/icons/instagram.svg';
import BoxedValue from '../CampaignBriefPage/components/BoxedValue';
import LabelText from '../CampaignBriefPage/components/Label';
import Section from '../CampaignBriefPage/components/Section';
import SeparatorLine from '../CampaignBriefPage/components/SeparatorLine';
import {
  createBoardForCreatorBrief,
  getBoardsForCreatorBrief,
  getBrief,
  getBriefItem,
  getBriefItems,
  getCollaborators,
  getInvitedCollaborators,
} from '../../../actions/brief.actions';
import Discussion from '../../../expert/components/Discussion';
import dayjs from 'dayjs';
import TikTokSVG from '../../../components/IconsSvg/TikTokSVG';
import YoutubeSVG from '../../../components/IconsSvg/YoutubeSVG';
import BriefOverviewSection from '../components/BriefOverviewSection';
import VisualDirectionsSection from '../components/VisualDirectionsSection';
import { getObjectiveDisplayName } from '../../CreateTest/utils';
import BriefItemCell from '../CampaignBriefPage/components/BriefItemCell';
import { briefPageType } from '../briefPageType';
import LightPurpleButton from '../CampaignBriefPage/components/LightPurpleButton';
import DeliverablesApprovedLabel from '../../ContentApproval/ContentBoard/components/DeliverablesApprovedLabel';
import ContentApprovalCell from '../../../components/Cells/ContentApprovalCell';
import BriefsBreadcrumbs from '../components/BriefsBreadcrumbs';
import { TYPES_CAMPAIGN_BRIEF_OBJECT, TYPES_CAMPAIGNS, TYPES_CREATOR_BRIEF_OBJECT } from '../../../shared/constants/constants';
import BriefHeaderSection from '../components/BriefHeaderSection';
import FormattedText from '../CampaignBriefPage/components/FormattedText';
import { renderToString } from 'react-dom/server';
import { getFormattedPlacement } from '../../../shared/utils/helpers';
import { useMediaQuery } from 'react-responsive/src';
import * as briefServices from '../../../services/brief';
import HeaderBreadcrumbs from '../../../components/BriefsAndBoardsFlows/HeaderBreadcrumbs';
import Breadcrumbs from '../../../components/Breadcrumbs';
import DiscussionBackground from '../../../expert/components/Discussion/DiscussionBackground';

const repostingDurationInfo = {
  1: '1 month',
  3: '3 months',
  6: '6 months',
  9: '9 months',
  12: '1 year',
  99: '1+ year',
};

const BriefItemPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const currentBrief = useSelector(state => state.brief.currentBrief.data);
  const currentBriefItem = useSelector(state => state.brief.currentBrief.briefItem);
  const [currentCampaignBrief, setCurrentCampaignBrief] = useState(null);
  const contentBriefs = useSelector(state => state.brief.currentBrief.items);
  const creatorBriefBoard = useSelector(state => state.brief.currentBrief.creatorBriefBoards[0]);
  const collaborators = useSelector(state => state.brief.currentBrief.collaborators);
  const userId = useSelector(state => state.auth.user.id);
  const pageType = useMemo(() => {
    return match.params.creatorBriefID ? briefPageType.CREATOR_BRIEF_ITEM : briefPageType.CAMPAIGN_BRIEF_ITEM;
  }, [match.params.creatorBriefID]);
  const isDesktopLandscape = useMediaQuery({
    query: '(min-width: 992px) and (orientation: landscape)',
  });
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  const currentBriefItemId = match.params.creatorBriefID || match.params.briefItemID;

  const deliverables = useMemo(() => {
    if (currentBriefItem && currentBriefItem.deliverables) {
      const getDeliverablesItemsByCategory = category => {
        return currentBriefItem.deliverables
          .filter(item => item.platform === category)
          .map(item => {
            return {
              placement: item.placement.replace('_', ' '), // todo: change the value by key
              quantity: item.quantity,
            };
          });
      };

      const res = [];
      const facebook = getDeliverablesItemsByCategory('facebook');
      if (facebook.length) {
        res.push({ platform: 'facebook', options: facebook });
      }
      const instagram = getDeliverablesItemsByCategory('instagram');
      if (instagram.length) {
        res.push({ platform: 'instagram', options: instagram });
      }
      const tiktok = getDeliverablesItemsByCategory('tiktok');
      if (tiktok.length) {
        res.push({ platform: 'tiktok', options: tiktok });
      }
      const youtube = getDeliverablesItemsByCategory('youtube');
      if (youtube.length) {
        res.push({ platform: 'youtube', options: youtube });
      }
      return res;
    } else {
      return [];
    }
  }, [currentBriefItem]);

  const currentCollaborator = useMemo(() => {
    if (currentOrganizationID && collaborators.length > 0) {
      return collaborators.find(collaborator => collaborator.organization_id == currentOrganizationID);
    }
    return {};
  }, [currentOrganizationID, collaborators]);

  const initiatorCollaborator = useMemo(() => {
    if (currentBriefItem && collaborators.length > 0) {
      return collaborators.find(collaborator => collaborator.approver_id == currentBriefItem.creator_id);
    }
    return {};
  }, [currentBriefItem, collaborators]);

  const canCreateCreatorBrief = useMemo(() => {
    if (currentBriefItem && collaborators.length > 0) {
      if (currentCollaborator?.type === 'talent_manager') {
        return true;
      }

      const collaboratorsTypes = collaborators.map(collaborator => collaborator.type);
      if (currentCollaborator?.type === 'agency' && !collaboratorsTypes.includes('talent_manager')) {
        return true;
      }
      if (
        currentCollaborator?.type === 'brand' &&
        !collaboratorsTypes.includes('agency') &&
        !collaboratorsTypes.includes('talent_manager')
      ) {
        return true;
      }
    }
    return false;
  }, [currentBriefItem, collaborators]);

  const isContentCreatorCollaborator = useMemo(() => {
    return currentCollaborator?.type == TYPES_CAMPAIGNS.CONTENT_CREATOR;
  }, [currentCollaborator]);

  useEffect(() => {
    if (currentOrganizationID) {
      dispatch(getBrief(currentOrganizationID, match.params.campaignID, () => history.push('/campaign/')));
      dispatch(getBriefItem(currentOrganizationID, match.params.campaignID, currentBriefItemId, () => history.push('/campaign/')));
      dispatch(getCollaborators(currentOrganizationID, match.params.campaignID, currentBriefItemId));
      dispatch(getInvitedCollaborators(currentOrganizationID, match.params.campaignID, currentBriefItemId));
      if (pageType === briefPageType.CAMPAIGN_BRIEF_ITEM) {
        dispatch(getBriefItems(currentOrganizationID, match.params.campaignID, { parent_id: currentBriefItemId }));
      }
      if (pageType === briefPageType.CREATOR_BRIEF_ITEM) {
        briefServices.getBriefItem(currentOrganizationID, match.params.campaignID, match.params.briefItemID).then(res => {
          const campaignBrief = res?.data?.[0];
          if (campaignBrief) {
            setCurrentCampaignBrief(campaignBrief);
          }
        });
        dispatch(getBoardsForCreatorBrief(currentOrganizationID, currentBriefItemId));
      }
    }
  }, [currentOrganizationID, currentBriefItemId]);

  const emptyValue = (
    <span style={{ color: '#9fa2b4', fontSize: 13, fontWeight: 'bold', marginTop: 10, textDecoration: 'initial' }}>None</span>
  );

  const createBoardHandler = () => {
    dispatch(
      createBoardForCreatorBrief(
        currentOrganizationID,
        match.params.creatorBriefID,
        match.params.briefItemID,
        collaborators,
        handleBoardDetailClick
      )
    );
  };

  const getEditLink = () => {
    if (match.params.creatorBriefID) {
      return `/campaign/${match.params.campaignID}/campaign-brief/${match.params.briefItemID}/creator-brief/${match.params.creatorBriefID}/edit`;
    }
    if (match.params.briefItemID) {
      return `/campaign/${match.params.campaignID}/campaign-brief/${match.params.briefItemID}/edit`;
    }
    return `/campaign/${match.params.campaignID}/edit`;
  };

  const getDuplicateLink = () => {
    if (match.params.creatorBriefID) {
      return `/campaign/${match.params.campaignID}/campaign-brief/${match.params.briefItemID}/creator-brief/${match.params.creatorBriefID}/duplicate`;
    }
    return `/campaign/${match.params.campaignID}/campaign-brief/${match.params.briefItemID}/duplicate`;
  };

  const handleBoardDetailClick = boardID => {
    const campaignOverviewRoute = `/campaign/${match.params.campaignID}`;
    const briefItemRoute = `${campaignOverviewRoute}/campaign-brief/${match.params.briefItemID}`;
    const creatorBriefItemRoute = `${briefItemRoute}/creator-brief/${match.params.creatorBriefID}`;

    const boardBackRoute = {
      routes: [
        {
          route: '/campaign',
          routeName: 'Campaign',
        },
        {
          route: campaignOverviewRoute,
          routeName: 'Campaign overview: ' + currentBrief?.name,
        },
        {
          route: briefItemRoute,
          routeName: 'Campaign brief: ' + currentCampaignBrief?.name || 'unnamed brief',
        },
        {
          route: creatorBriefItemRoute,
          routeName: 'Creator brief: ' + currentBriefItem?.name || 'unnamed brief',
        },
      ],
      boardId: boardID,
    };

    localStorage.setItem('boardBackRoute', JSON.stringify(boardBackRoute));
    history.push(`/content-board/${boardID}`);
  };

  return (
    <>
      <MainLayout
        title={
          <BriefsBreadcrumbs
            pageType={pageType}
            currentBrief={currentBrief}
            currentBriefItem={currentBriefItem}
            BreadcrumbsComponent={HeaderBreadcrumbs}
          />
        }
        contentClassName={styles.root}
        coloredHeader
      >
        {currentBriefItem && (
          <>
            {!isDesktopLandscape && (
              <BriefsBreadcrumbs
                pageType={pageType}
                currentBrief={currentBrief}
                currentBriefItem={currentBriefItem}
                BreadcrumbsComponent={Breadcrumbs}
              />
            )}
            <div className={styles.row}>
              {currentBrief && currentCollaborator && (
                <BriefHeaderSection
                  currentBrief={currentBrief}
                  isApproved={!!currentCollaborator.approved_at}
                  isRemoveApprove={pageType === briefPageType.CAMPAIGN_BRIEF_ITEM && isContentCreatorCollaborator}
                  currentBriefItem={currentBriefItem}
                  pageType={pageType}
                  availableOrganizationDropdownOption={
                    pageType === briefPageType.CAMPAIGN_BRIEF_ITEM ? TYPES_CAMPAIGN_BRIEF_OBJECT : TYPES_CREATOR_BRIEF_OBJECT
                  }
                />
              )}
              {currentBrief && currentCollaborator && (
                <BriefOverviewSection
                  currentBrief={currentBrief}
                  masterBrief={currentBriefItem}
                  isApproved={!!currentCollaborator.approved_at}
                />
              )}

              <VisualDirectionsSection showAddVisualButton={!isContentCreatorCollaborator} />

              <Section className={styles.masterBriefDetails}>
                <div className={styles.flexRow}>
                  <div className={styles.title}>Campaign Deliverables and Content Usage</div>
                  <div className={styles.platformHeader}>
                    {!(initiatorCollaborator.organization_type == 'talent_manager' && currentCollaborator.organization_type == 'agency') &&
                      !(initiatorCollaborator.organization_type == 'agency' && currentCollaborator.organization_type == 'talent_manager') &&
                      !isContentCreatorCollaborator && (
                        <NavLink to={getDuplicateLink()}>
                          <LightPurpleButton isSmall>Duplicate</LightPurpleButton>
                        </NavLink>
                      )}
                    {!isContentCreatorCollaborator && (
                      <NavLink to={getEditLink()}>
                        <LightPurpleButton isSmall>Edit</LightPurpleButton>
                      </NavLink>
                    )}
                  </div>
                </div>
                <div className={styles.objective}>
                  <LabelText>Campaign Objective</LabelText>

                  {currentBriefItem.objective.length ? (
                    <div style={{ display: 'flex', gap: 10 }}>
                      {currentBriefItem.objective.map((item, index) => (
                        <BoxedValue key={index}>{getObjectiveDisplayName(item)}</BoxedValue>
                      ))}
                    </div>
                  ) : (
                    emptyValue
                  )}
                </div>
                <SeparatorLine />
                <div>
                  <LabelText>What is the key messaging?</LabelText>
                  <FormattedText text={currentBriefItem.key_message || renderToString(emptyValue)} className={styles.formattedText} />
                </div>
                <SeparatorLine />
                <div>
                  <LabelText>Campaign Duration</LabelText>
                  <div className={styles.justFlex}>
                    <BoxedValue>
                      {currentBriefItem.date_start ? dayjs(currentBriefItem.date_start).format('DD MMM YYYY') : emptyValue}
                    </BoxedValue>
                    <div className={styles.arrow}>
                      <TriangleIcon />
                    </div>
                    <BoxedValue>
                      {currentBriefItem.date_end ? dayjs(currentBriefItem.date_end).format('DD MMM YYYY') : emptyValue}
                    </BoxedValue>
                  </div>
                  <div className={styles.flexWrap}>
                    <div className={styles.box}>
                      <LabelText isSmall>Campaign budget</LabelText>
                      <BoxedValue rightMargin>
                        {currentBriefItem.total_budget
                          ? `${currentBriefItem.total_budget.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${
                              currentBriefItem.total_budget_currency
                            }`
                          : emptyValue}
                      </BoxedValue>
                    </div>
                  </div>
                </div>

                <SeparatorLine />
                <div>
                  <LabelText>Boosting</LabelText>
                  <div className={styles.flexWrap}>
                    <div className={styles.box}>
                      <LabelText isSmall>Budget</LabelText>
                      <BoxedValue rightMargin>
                        {currentBriefItem.boosting_budget
                          ? `${currentBriefItem.boosting_budget.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${
                              currentBriefItem.boosting_budget_currency
                            }`
                          : emptyValue}
                      </BoxedValue>
                    </div>
                    <div className={styles.box}>
                      <LabelText isSmall>Duration</LabelText>
                      <BoxedValue rightMargin>
                        {currentBriefItem.boosting_duration
                          ? `${currentBriefItem.boosting_duration} ${currentBriefItem.boosting_duration > 1 ? 'weeks' : 'week'}`
                          : emptyValue}
                      </BoxedValue>
                    </div>
                    <div className={styles.box}>
                      <LabelText isSmall>Manager</LabelText>
                      <BoxedValue rightMargin>
                        {currentBriefItem.boosting_manager?.length
                          ? `${currentBriefItem.boosting_manager
                              .map(element => {
                                return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase().replace('_', ' ');
                              })
                              .join(' ')}`
                          : emptyValue}
                      </BoxedValue>
                    </div>
                  </div>
                </div>

                <SeparatorLine />
                <div>
                  <LabelText>Live Date</LabelText>
                  <div className={styles.justFlex}>
                    <BoxedValue>
                      {currentBriefItem.live_date_start ? dayjs(currentBriefItem.live_date_start).format('DD MMM YYYY') : emptyValue}
                    </BoxedValue>
                  </div>
                </div>
                <SeparatorLine />
                <div>
                  <LabelText>Deliverables</LabelText>
                  {!deliverables.length ? (
                    <>
                      {emptyValue}
                      <SeparatorLine />
                    </>
                  ) : (
                    <div className={styles.platformsWrapper}>
                      {deliverables.map(platformItem => (
                        <div key={platformItem.platform} className={styles.platform}>
                          <div className={styles.platformHeader}>
                            <div className={styles.icon}>
                              {platformItem.platform === 'facebook' && <FacebookIcon />}
                              {platformItem.platform === 'instagram' && <InstagramIcon />}
                              {platformItem.platform === 'tiktok' && <TikTokSVG />}
                              {platformItem.platform === 'youtube' && <YoutubeSVG />}
                            </div>
                            <div className={styles.name}>{platformItem.platform}</div>
                          </div>
                          <div className={styles.platformOptions}>
                            {platformItem.options.map(item => (
                              <div key={item.placement} className={styles.platformItem}>
                                {getFormattedPlacement(item.placement.replace(' ', '_'))}
                                <span className={styles.platformItemQuantity}>{item.quantity}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <div>
                  <LabelText>Exclusivity terms</LabelText>
                  <FormattedText text={currentBriefItem.exclusivity || renderToString(emptyValue)} className={styles.formattedText} />
                </div>

                <SeparatorLine />

                <div>
                  <LabelText>Reposting</LabelText>
                  {currentBriefItem.reposting_duration && (
                    <div className={styles.justFlex} style={{ marginBottom: 10 }}>
                      <BoxedValue>{repostingDurationInfo[currentBriefItem.reposting_duration]}</BoxedValue>
                    </div>
                  )}
                  {!currentBriefItem.reposting_platform && emptyValue}
                  <div className={styles.justFlex}>
                    {currentBriefItem.reposting_platform?.includes('facebook') && (
                      <div className={cs(styles.justFlex, styles.platformHeader)}>
                        <div className={styles.icon}>
                          <FacebookIcon />
                        </div>
                        <div className={styles.name}>Facebook</div>
                      </div>
                    )}
                    {currentBriefItem.reposting_platform?.includes('instagram') && (
                      <div className={cs(styles.justFlex, styles.platformHeader)}>
                        <div className={styles.icon}>
                          <InstagramIcon />
                        </div>
                        <div className={styles.name}>Instagram</div>
                      </div>
                    )}
                    {currentBriefItem.reposting_platform?.includes('tiktok') && (
                      <div className={cs(styles.justFlex, styles.platformHeader)}>
                        <div className={styles.icon}>
                          <TikTokSVG />
                        </div>
                        <div className={styles.name}>TikTok</div>
                      </div>
                    )}
                    {currentBriefItem.reposting_platform?.includes('youtube') && (
                      <div className={cs(styles.justFlex, styles.platformHeader)}>
                        <div className={styles.icon}>
                          <YoutubeSVG />
                        </div>
                        <div className={styles.name}>Youtube</div>
                      </div>
                    )}
                  </div>
                </div>

                <SeparatorLine />
                <div>
                  <LabelText>Audience</LabelText>
                  <div className={styles.flexWrap}>
                    <div className={styles.box}>
                      <LabelText isSmall>Age</LabelText>
                      <div className={styles.justFlex}>
                        <BoxedValue rightMargin>
                          <span>Min</span>{' '}
                          {currentBriefItem.target_audience.age.min == 65 ? '65+' : currentBriefItem.target_audience.age.min || emptyValue}
                        </BoxedValue>
                        <BoxedValue rightMargin>
                          <span>Max</span>{' '}
                          {currentBriefItem.target_audience.age.max == 65 ? '65+' : currentBriefItem.target_audience.age.max || emptyValue}
                        </BoxedValue>
                      </div>
                    </div>
                    <div className={styles.box}>
                      <LabelText isSmall>Gender</LabelText>
                      <div className={styles.justFlex}>
                        {currentBriefItem.target_audience.gender?.length === 0 && <BoxedValue rightMargin>{emptyValue}</BoxedValue>}
                        {currentBriefItem.target_audience.gender.map((item, index) => (
                          <BoxedValue rightMargin key={index}>
                            <span style={{ textTransform: 'capitalize', margin: 0 }}>{item}</span>
                          </BoxedValue>
                        ))}
                      </div>
                    </div>
                    <div className={styles.box}>
                      <LabelText isSmall>Location</LabelText>
                      <div className={styles.justFlex}>
                        <BoxedValue rightMargin>
                          <span>Country</span> {currentBriefItem.target_audience.location.country.join(', ') || emptyValue}
                        </BoxedValue>
                        <BoxedValue rightMargin>
                          <span>City</span> {currentBriefItem.target_audience.location.city.join(', ') || emptyValue}
                        </BoxedValue>
                      </div>
                    </div>
                    <div className={styles.box}>
                      {currentBriefItem.target_audience.interests_and_behaviours.length > 0 && (
                        <>
                          <LabelText isSmall>Interests and behaviours</LabelText>
                          <div className={styles.justFlex}>
                            {currentBriefItem.target_audience.interests_and_behaviours.map((item, index) => (
                              <BoxedValue rightMargin key={index}>
                                {item}
                              </BoxedValue>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <SeparatorLine />
                <div>
                  <LabelText>Tags</LabelText>
                  <LabelText isSmall hasMarginTop>
                    Hashtags
                  </LabelText>
                  <div className={styles.flexWrap}>
                    {!currentBriefItem.hashtags.length && emptyValue}
                    {currentBriefItem.hashtags.map((item, index) => (
                      <BoxedValue rightMargin key={index}>
                        {item}
                      </BoxedValue>
                    ))}
                  </div>
                  <LabelText isSmall hasMarginTop>
                    Social Handles
                  </LabelText>
                  <div className={styles.flexWrap}>
                    {!currentBriefItem.social_tagging.length && emptyValue}
                    {currentBriefItem.social_tagging.map((item, index) => (
                      <BoxedValue rightMargin key={index}>
                        {item}
                      </BoxedValue>
                    ))}
                  </div>
                </div>
                <SeparatorLine />
                {pageType !== briefPageType.CREATOR_BRIEF_ITEM &&
                  (currentBriefItem.suggested_creators || currentBriefItem.suggested_creators_description) && (
                    <>
                      <div>
                        <LabelText>
                          Suggested content creators{' '}
                          {currentBriefItem.suggested_creators ? (
                            <span className={styles.bubble}>Yes</span>
                          ) : (
                            <span className={styles.bubble}>No</span>
                          )}
                        </LabelText>
                        {currentBriefItem.suggested_creators && <FormattedText text={currentBriefItem.suggested_creators} />}
                        {currentBriefItem.suggested_creators_description && (
                          <FormattedText text={currentBriefItem.suggested_creators_description} />
                        )}
                      </div>
                      <SeparatorLine />
                    </>
                  )}
                <div>
                  <LabelText>Preferred Ad Declaration</LabelText>
                  {currentBriefItem.preferred_ad_declaration === 'ad_in_caption' && <p>{'Ad in Caption'}</p>}
                  {currentBriefItem.preferred_ad_declaration === 'paid_partnership_tool' && <p>{'Paid Partnership'}</p>}
                </div>

                <SeparatorLine />
                <div>
                  <LabelText>Additional information</LabelText>
                  <FormattedText text={currentBriefItem.other_infos || renderToString(emptyValue)} className={styles.formattedText} />
                </div>
              </Section>

              {pageType === briefPageType.CAMPAIGN_BRIEF_ITEM && ( // this list can be only on the campaign brief item page
                <div className={styles.briefsSection}>
                  <div className={styles.flexRow}>
                    <div className={styles.text}>
                      Creator briefs <span>{contentBriefs.length}</span>
                    </div>
                    {canCreateCreatorBrief && (
                      <ButtonCustom
                        onClick={() => {
                          window.analytics.track('brief_initiated', {
                            category: 'Creator Brief',
                            userId: userId,
                            campaign_brief_id: currentBriefItem.id,
                            status: currentBriefItem.status,
                          });

                          history.push(
                            `/campaign/${match.params.campaignID}/campaign-brief/${match.params.briefItemID}/create-creator-brief`
                          );
                        }}
                        Icon={AddIcon}
                        className={styles.button}
                      >
                        Add Creator Brief
                      </ButtonCustom>
                    )}
                  </div>
                  {contentBriefs.map(item => (
                    <BriefItemCell data={item} key={item.id} />
                  ))}
                </div>
              )}

            {pageType === briefPageType.CREATOR_BRIEF_ITEM && ( // boards list only for creator briefs
              <Section className={styles.boardListSection}>
                <div className={styles.flexRow}>
                  <div className={styles.sectionTitle}>Content board</div>
                  <div>
                    {creatorBriefBoard ? (
                      <DeliverablesApprovedLabel boardId={creatorBriefBoard.id} />
                    ) : (
                      <ButtonCustom onClick={createBoardHandler} Icon={AddIcon} className={styles.button}>
                        Add Board
                      </ButtonCustom>
                    )}
                  </div>
                </div>
                {creatorBriefBoard && (
                  <ContentApprovalCell
                    board={creatorBriefBoard}
                    detailsButtonLabel={'View details'}
                    style={{ padding: '0 5px', boxShadow: 'none', marginTop: 20, gap: 5 }}
                    onDetail={handleBoardDetailClick}
                    isTablet={isTablet}
                  />
                )}
              </Section>
            )}
          </div>
        </>
      )}
    </MainLayout>
      <DiscussionBackground />
    <Discussion collaborators={collaborators} objectItemID={currentBriefItemId} itemType={'campaign_brief_item'} />
  </>
  );
};

export default BriefItemPage;
