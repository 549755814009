import React, { useEffect, useState } from 'react';
import styles from './DiscussionInput.module.scss';
import { AiOutlinePaperClip, MdSend } from 'react-icons/all';
import cs from 'classnames';
import EditorComponent from '../../../../components/EditorComponent';
import { useMediaQuery } from 'react-responsive/src';
import AttachmentTile from './AttachmentTile';

const DiscussionInput = ({ onSubmitMessage, disabled, onFocus, roomIndex }) => {
  const [message, setMessage] = useState('');

  // Array<{file: File, fileUrl: string}>
  const [attachments, setAttachments] = useState([]);

  const isDesktopLandscape = useMediaQuery({
    query: '(min-width: 992px) and (orientation: landscape)',
  });

  useEffect(() => {
    setMessage('');
  }, [roomIndex]);

  const handleUploadAttachment = async event => {
    const files = event.target.files;
    const elements = [];
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onloadend = () => {
        const extensionRegex = /(?:\.([^.]+))?$/;
        elements.push({
          helperFileId: String(Math.random()),
          file: files[i],
          fileUrl: reader.result,
          description: '',
          mime_type: files[i].type,
          filename: files[i].name,
          extension: extensionRegex.exec(files[i].name)[1],
        });
      };
    }

    setTimeout(function () {
      setAttachments([...attachments, ...elements]);
    }, 100);
  };

  const removeAttachment = id => {
    setAttachments(attachments.filter(item => item.helperFileId !== id));
  };

  const handleMessageChange = text => {
    setMessage(text);
  };

  const handleSubmit = e => {
    e.preventDefault();
    onSubmitMessage(message, attachments);
    setMessage('');
    setAttachments([]);
  };

  return (
    <form className={cs(styles.root, { [styles.mobile]: !isDesktopLandscape })}>
      <EditorComponent
        toolbarOnFocus
        initialValue={message}
        onChange={handleMessageChange}
        onFocus={onFocus}
        placeholder={'Reply...'}
        wrapperClassName={styles.wrapperClassName}
        editorClassName={styles.editorClassName}
        disabled={disabled}
      />

      {attachments.length > 0 && (
        <div className={styles.attachments}>
          {attachments.map(item => (
            <AttachmentTile key={item.helperFileId} attachment={item} onDelete={id => removeAttachment(id)} />
          ))}
        </div>
      )}

      <div className={styles.inputFooter}>
        <div className={styles.inputFooterIconButtons}>
          <span className={styles.iconButton}>
            <AiOutlinePaperClip />
            <input
              type="file"
              accept="*"
              multiple
              disabled={disabled}
              onChange={handleUploadAttachment}
              className={cs(styles.fileInput, { [styles.disabled]: disabled })}
            />
          </span>
        </div>

        <button
          onClick={handleSubmit}
          className={cs(
            styles.sendButton,
            { [styles.sendButtonWithValue]: message || attachments.length },
            { [styles.disabled]: disabled }
          )}
          disabled={message.length === 0 && attachments.length === 0}
        >
          Send
          <span className={styles.buttonSendIcon}>
            <MdSend />
          </span>
        </button>
      </div>
    </form>
  );
};

export default DiscussionInput;
