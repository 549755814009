/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import styles from './ImportAudiencesModal.module.scss';
import SearchBar from '../../SearchBar';
import ImageTypeSwitcher from '../../ImageTypeSwitcher';
import Button from '../../../expert/components/Buttons/ButtonCustom';
import GeneralModalDesignV2 from '../GeneralModalDesignV2';
import AudiencesImportCell from '../../Cells/AudiencesImportCell';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {getManageAudiencesUrl} from '../../../services/facebookIntegrations';
import {getAdIntegrations} from '../../../actions/ad_integration.actions';
import {
  deleteAudience,
  getAudiencesForImport,
  getEntireAudiencesList,
  importAudience
} from '../../../actions/audiences.actions';
import {getPricingPlanForUser} from '../../../actions/user.actions';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import FacebookSVG from '../../IconsSvg/FacebookSVG';
import LookalikeSVG from '../../IconsSvg/LookalikeSVG';
import LoadingState from '../../../pages/Platforms/Facebook/Audiences/LoadingState';

const ImportAudiencesModal = props => {
    const { onClose } = props;

    const dispatch = useDispatch();
    const currentOrg = useSelector(state => state.auth.currentOrganization);
    const fbIntegration = useSelector(state => state.ad_integration.ad_integrations.find(item => item.type === 'facebook'));
    const integrationName = fbIntegration?.params.AD_ACCOUNT_NAME;

    const {entireAudiencesList, audiencesForImport, loadingAudiencesForImport} = useSelector(state => state.audience, shallowEqual);
    const [selectedData, setSelectedData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [middleValue, setMiddleValue] = useState('');
    const [switcher, setSwitcher] = useState('saved');

    useEffect(() => {
      dispatch(getAdIntegrations()).then(() => {
        dispatch(getEntireAudiencesList());
        dispatch(getPricingPlanForUser());
      });
    }, []);

    const getImportAudiences = () => {
      dispatch(getAudiencesForImport(currentOrg.organization_id, switcher, searchValue));
    };

    useEffect(() => {
      if (currentOrg) {
        getImportAudiences();
      }
    }, [currentOrg, searchValue, switcher]);

  useEffect(() => {
    const isDifferentLength = entireAudiencesList.length !== selectedData.length;
    const hasDifferentItems = !entireAudiencesList.every(item => selectedData.some(_item=>item.id === _item.nonFbId));
    if (entireAudiencesList && (isDifferentLength || hasDifferentItems)) {
      setSelectedData(
        entireAudiencesList.map(item => {
          return { id: item.external_id, type: item.type, nonFbId: item.id };
        }).filter(item => item.id)
      );
    }
  }, [entireAudiencesList]);

  const handleRemove = async (item) => {
    const itemToDelete = selectedData.find(selected => selected.id === item.id);
    await dispatch(deleteAudience(itemToDelete.nonFbId));
    await update();
  };

  const handleImport = async (item) => {
    await dispatch(importAudience(fbIntegration, [{
      id: item.id,
      type: item.type?.toLowerCase() || 'saved',
    }]));
    await update();
  };

  const update = async () => {
    await dispatch(getEntireAudiencesList());
  };

  return (
    <GeneralModalDesignV2
      {...props}
      headerLeftContent={(
        <>
          <div className={styles.titleComponent}>
            <div className={styles.title}>
              Select Meta audiences
              <span className={styles.info}>
                i
                <div className={styles.infoContainer}>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, vitae?
                </div>
              </span>
            </div>
            {integrationName && (
              <div className={styles.description}>
                <span className={styles.counter}>{audiencesForImport.length}</span>
                <span>
                Audiences are under <span className={styles.adAccount}>{integrationName}</span> Facebook Ad account
              </span>
              </div>
            )}
          </div>

          <div className={styles.photosSwitcherComponent}>
            <ImageTypeSwitcher
              className={styles.switcher}
              switcher={switcher}
              onChange={value => setSwitcher(value)}
              purpleBackground
              initialTabsArray={[
                { value: 'saved', name: 'Saved' },
                { value: 'custom', name: 'Custom' },
                { value: 'lookalike', name: 'Lookalike' },
              ]}
            />
          </div>
        </>
      )}
      headerRightContent={(
        <>
          <div className={styles.rightActionsContainer}>
            <SearchBar
              className={styles.searchBar}
              onSubmit={() => setSearchValue(middleValue)}
              onChangeSearchValue={(value) => setMiddleValue(value)}
              searchValue={middleValue}
              placeholder={'Search audiences'}
              onClear={() => {
                setMiddleValue('');
                setSearchValue('');
              }}
              designVersion="V2.0"
            />
          </div>
        </>
      )}
      footerButtons={(
        <>
          <Button onClick={onClose}>
            Done
          </Button>
        </>
      )}
    >
      {loadingAudiencesForImport && (
        <div className={styles.loader}>
          {[...Array(12).keys()].map(index => (
            <LoadingState key={index} index={index} />
          ))}
        </div>
      )}

      {(audiencesForImport.length && !loadingAudiencesForImport) && (
        <div className={styles.container}>
          {audiencesForImport.map(item => (
            <AudiencesImportCell
              key={item.id}
              audience={item}
              withSelect
              selectedList={selectedData}
              onRemove={handleRemove}
              onImport={handleImport}
            />
          ))}
        </div>
      )}

      {!audiencesForImport.length && (
        <div className={styles.empty}>
          <LookalikeSVG />
          <span>No audiences found</span>
          <ButtonCustom
            onClick={() => window.open(getManageAudiencesUrl(fbIntegration.params))}
          >
            <FacebookSVG />
            Manage in Facebook
          </ButtonCustom>
        </div>
      )}
    </GeneralModalDesignV2>
  );
};

export default ImportAudiencesModal;
