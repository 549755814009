import React from 'react';
import { useSelector } from 'react-redux';

const Link = 'https://help.otterfish.com/knowledge/how-does-the-campaign-briefing-tool-work';

const BriefLearnMoreLink = ({ children, source }) => {
  const userId = useSelector(state => state.auth.user.id);
  const handleClick = () => {
    window.analytics.track('Click on Learn More', {
      category: 'KB Articles',
      label: source,
      userId: userId,
    });
  };
  return (
    <a target={'_blank'} rel={'noopener noreferrer'} href={Link} style={{ textDecoration: 'initial' }} onClick={handleClick}>
      {children}
    </a>
  );
};

export default BriefLearnMoreLink;
