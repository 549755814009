import React from 'react';
import styles from './PlanProgressBar.module.scss';
import cs from 'classnames';

const PlanProgressBar = ({ current, of, disabled }) => {
  const defaultTrack = Number(of) !== -1 ? (current/of) * 100 : 15;
  const color = () => {
    if (disabled) return 'disabled';
    if (defaultTrack < 75) return 'success';
    if (defaultTrack >= 75 && defaultTrack < 100) return 'warning';
    if (defaultTrack >= 100) return 'danger';
  }

  return (
    <div className={styles.root}>
      <div className={styles.progressBar}>
        <div className={cs(styles.track, styles[color()])} style={{width: `${defaultTrack}%`, display: `${!defaultTrack ? 'none' : 'flex'}`}}/>
      </div>
    </div>
  );
};

export default PlanProgressBar;
