import React from 'react';
import styles from './VisualConcepts.module.scss';
import ConceptVisualCard from './ConceptVisualCard';
import DownloadAllButton from '../../../../components/DownloadAllButton';
import VisualsCounter from '../../VisualsCounter';

const VisualConcepts = ({ visualConceptsFlow, openAsset, isDisabledSection }) => {
  const { visualConcepts, onCreateVisualConcept, onUpdateVisualConcept, onDeleteVisualConcept, assetsWithoutFile } = visualConceptsFlow;
  const maxVisualConceptsLength = 10;
  const isDisplayAddButton = visualConcepts.length < maxVisualConceptsLength;

  const AddVisualCard = ( hideInput ) => {
    if (isDisabledSection) return null;
    return (
      <ConceptVisualCard
        onUpdateVisual={onUpdateVisualConcept}
        onDeleteVisual={onDeleteVisualConcept}
        onCreateVisual={onCreateVisualConcept}
        lengthItems={visualConcepts.length}
        hideInput={hideInput}
      />
    );
  };

  return (
    <div className={styles.visualConceptContainer}>
      {!(isDisabledSection && !visualConcepts.length) && (
        <div className={styles.header}>
          <div className={styles.headerGroup}>
            <div className={styles.title}>Feel free to add visuals for your concept idea</div>
            <VisualsCounter currentCount={visualConcepts.length} totalCount={maxVisualConceptsLength} />
          </div>

          {!!visualConcepts?.length && <DownloadAllButton assets={visualConcepts} />}
        </div>
      )}

      <div className={styles.list}>
        {visualConcepts.length ? (
          <>
            {visualConcepts.map(item => (
              <ConceptVisualCard
                key={item.id}
                asset={item}
                onUpdateVisual={onUpdateVisualConcept}
                onDeleteVisual={onDeleteVisualConcept}
                isError={assetsWithoutFile.includes(item.id)}
                openAsset={openAsset}
                isDisabledSection={isDisabledSection}
              />
            ))}
            {isDisplayAddButton && <AddVisualCard hideInput />}
          </>
        ) : (
          <AddVisualCard />
        )}
      </div>
    </div>
  );
};

export default VisualConcepts;
