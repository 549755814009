import React from 'react';

const SmallBoldRightArrowSVG = () => {
  return(
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 1L6.5 6L1.5 11" stroke="#4E3B8B" strokeWidth="2" strokeLinecap="round"
            strokeLinejoin="round" />
    </svg>
  )
}

export default SmallBoldRightArrowSVG;
