/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React from 'react';
import MobileMenu from './MobileMenu';
import DesktopMenu from './DesktopMenu';

const Menu = ({ isOpen, isMobile = false, handleCloseMenu, classNames }) => {
  return (
    <>
      {isMobile ? (
        <MobileMenu isOpen={isOpen} handleCloseMenu={handleCloseMenu} classNames={classNames} />
      ) : (
        <DesktopMenu isOpen={isOpen} handleCloseMenu={handleCloseMenu} classNames={classNames} />
      )}
    </>
  );
};

export default Menu;
