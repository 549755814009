import React from 'react';
import styles from './VideoModal.module.scss';
import { Modal as ReactModal } from 'react-responsive-modal';
import cs from 'classnames';
import { CloseIcon } from '../../../../expert/components/IconsSvg/ReactIcons/ReactIcons';

const URL = process.env.REACT_APP_CONTENT_APPROVAL_DEMO_VIDEO_URL;
const EXTENSION = URL?.trim().match(/(https?.*\/[^.]*\.)(\w*)/)[2];
const OGG_EXTENSION = /og/.test(EXTENSION) ? 'ogg' : null;

const VideoModal = props => {
  const modalStyles = {
    overlay: {
      padding: '20px',
      backdropFilter: 'blur(3px)',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    modal: {
      background: '#fff',
      padding: 0,
      borderRadius: '10px',
      maxWidth: props.maxWidth || '700px',
      width: '100%',
      boxShadow: ' 0 0 20px rgba(0, 0, 0, 0.1)',
    },
    closeButton: {
      display: 'none',
    },
  };

  const onCloseIconHandler = () => {
    if (props.onCloseByIcon) {
      props.onCloseByIcon();
    }
    props.onClose();
  };

  return (
    <ReactModal center open={props.open} onClose={props.onClose} styles={modalStyles}>
      <div className={cs(styles.root, props.className)}>
        <button onClick={onCloseIconHandler} className={styles.close} disabled={props.loading}>
          <CloseIcon size={30} />
        </button>

        <div className={styles.watchDemoContainer}>
          <div className={styles.purpleHeader}>Welcome to Otterfish</div>
          <video width="100%" controls autoPlay={true} onPause={() => props.onPause()} onPlay={() => props.onPlay()}>
            <source src={URL} type={`video/${OGG_EXTENSION ?? EXTENSION}`} />
          </video>
        </div>
      </div>
    </ReactModal>
  );
};

export default VideoModal;
