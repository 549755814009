import React, { useState, memo } from 'react';
import styles from './LeftPartSignUp.module.scss';
import { ReactComponent as Quote } from '../../../assets/img/icons/quote-icon.svg';
import cs from 'classnames';
import dayjs from 'dayjs';
import CirclesLoader from '../../CirclesLoader';
import { allMessages } from '../../../shared/constants/constants';
import screenCompaign from '../../../assets/img/icons/screen_compaign.png';
import screenChat from '../../../assets/img/icons/chat.png';
import screenCollab from '../../../assets/img/icons/collab.png';
import BackTriangle from '../../../assets/img/icons/triange_background.png';

const LeftPartSignUp = () => {
  const [currentHuman, setCurrentHuman] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    if (!!allMessages.length) {
      setIsLoading(false);
    }
  }, 1000);

  setTimeout(() => {
    if (currentHuman === 1) setCurrentHuman(0);
    else setCurrentHuman(currentHuman + 1);
  }, 10000);

  return (
    <div className={styles.root}>
      <div className={styles.background}>
        <img src={BackTriangle} alt="background" />
      </div>
      <img className={styles.compaign} src={screenCompaign} alt="no screen" />
      <img className={styles.chat} src={screenChat} alt="no screen" />
      <div className={styles.container}>
        <Quote />
        {isLoading ? (
          <div className={styles.loading}>
            <CirclesLoader color="white" />
          </div>
        ) : (
          <div className={styles.content}>
            <div className={styles.title}>The people have spoken...</div>
            {allMessages.map((item, idx) => {
              if (idx === currentHuman)
                return (
                  <div className={styles.text} key={idx}>
                    {allMessages[currentHuman].massage}
                  </div>
                );
            })}
            <div className={styles.people}>
              {allMessages.map((item, idx) => {
                if (idx === currentHuman)
                  return (
                    <div className={styles.peopleInfo} key={idx}>
                      <div className={styles.name}>{item.name}</div>
                      <div className={styles.company}>{item.company}</div>
                    </div>
                  );
              })}
              <div className={styles.dots}>
                <div className={cs(styles.point, { [styles.active]: currentHuman === 0 })} />
                <div className={cs(styles.point, { [styles.active]: currentHuman === 1 })} />
              </div>
            </div>
          </div>
        )}
      </div>
      <img className={styles.collab} src={screenCollab} alt="no screen" />
      <div className={styles.copyright}>© {dayjs().format('YYYY')} Otterfish Limited</div>
    </div>
  );
};

export default memo(LeftPartSignUp);
