import React, { useEffect, useRef, useState } from 'react';
import styles from './DeliverableName.module.scss';
import { ReactComponent as EditIcon } from '../../../../../assets/img/icons/edit_pen.svg';
import { useSelector } from 'react-redux';
import TextField from '../../../../../components/FormControls/TextField';
import cs from 'classnames';
import * as contentApprovalServices from '../../../../../services/contentApproval';
import { useParams } from 'react-router-dom';

const DeliverableName = () => {
  const params = useParams();
  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const { openedDeliverable } = useSelector(state => state.contentApproval);
  const [isEdit, setIsEdit] = useState(false);
  const [nameSaveLoading, setNameSaveLoading] = useState(false);
  const [deliverableName, setDeliverableName] = useState(openedDeliverable?.name);

  const inputRef = useRef(null);

  useEffect(() => {
    if (isEdit) {
      inputRef.current.focus();
    }
  }, [isEdit]);

  const updateDeliverableName = async () => {
    setNameSaveLoading(true);
    await contentApprovalServices.updateDeliverableItem(currentOrg.organization_id, params.boardId, params.deliverableId, {
      name: deliverableName.trim(),
    });
    setNameSaveLoading(false);
  };

  const onSubmit = () => {
    if (deliverableName.trim() === openedDeliverable?.name || !deliverableName.trim()) {
      setDeliverableName(openedDeliverable?.name);
      setIsEdit(false);
      return;
    }

    setIsEdit(false);
    updateDeliverableName().then(() => setDeliverableName(deliverableName.trim()));
  };

  const onEdit = () => {
    if (nameSaveLoading) return;
    setIsEdit(true);
  };

  return (
    <div className={cs(styles.root, { [styles.editing]: isEdit })}>
      {isEdit ? (
        <TextField
          ref={inputRef}
          wrapperClassName={styles.inputWrapper}
          className={styles.input}
          value={deliverableName}
          placeholder="Deliverable name"
          onBlur={onSubmit}
          onChange={e => setDeliverableName(e.target.value)}
        />
      ) : (
        <>
          <div className={styles.name}>{deliverableName}</div>
          <div className={styles.edit} onClick={onEdit}>
            <EditIcon />
          </div>
        </>
      )}
    </div>
  );
};

export default DeliverableName;
