import React, { useState } from 'react';
import styles from './BriefTab.module.scss';
import cs from 'classnames';
import { ReactComponent as TriangleArrow } from '../../../../assets/img/icons/rounded_triangle.svg';

function BriefTab({ data }) {
  const [isTabDisplayed, setIsTabDisplayed] = useState(true);

  return (
    <div className={styles.briefTab}>
      <div className={styles.briefHeader} onClick={() => setIsTabDisplayed(!isTabDisplayed)}>
        <div className={styles.leftSide}>
          <span className={styles.iconWrapper}>{data.icon}</span>
          <h2>{data.title}</h2>
        </div>
        <span className={cs(styles.arrowBlock, { [styles.active]: isTabDisplayed })}>
          <span className={styles.dropdownArrow}>
            <TriangleArrow />
          </span>
        </span>
      </div>
      {isTabDisplayed && <div className={styles.tabContent}>{data.content}</div>}
    </div>
  );
}

export default BriefTab;
