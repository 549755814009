import React from 'react';
import styles from './LightPurpleButton.module.scss';
import cs from 'classnames';

const LightPurpleButton = ({ className, children, onClick = () => {}, isSmall, disabled }) => {
  return (
    <button
      className={cs(styles.viewButton, { [styles.small]: isSmall }, { [styles.disabled]: disabled }, className)}
      onClick={() => onClick()}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default LightPurpleButton;
