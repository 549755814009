import React, { useState, useEffect } from 'react';
import styles from './ImportAdAccount.module.scss';
import LogoSVG from '../../../components/IconsSvg/LogoSVG';
import Button from '../../../expert/components/Buttons/ButtonCustom';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import Dropdown from '../../../components/FormControls/Dropdown';
import { getAdAccounts } from '../../../services/facebookIntegrations';
import { useDispatch } from 'react-redux';
import { MdChevronRight } from 'react-icons/md';
import { createAdIntegration } from '../../../actions/ad_integration.actions';

export default ({
  adAccountState: [adAccount, setAdAccount],
  userData
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const localeStorageData = JSON.parse(localStorage.getItem('OTTERFISH_FB_USER_DATA'));
  const fbData = userData?.authResponse || localeStorageData?.authResponse;

  const [adAccounts, setAdAccounts] = useState([]);

  useEffect(() => {
    getAdAccounts(fbData?.accessToken, fbData.userID).then(data => setAdAccounts(data));
  }, [userData]);

  const handleContinueClick = async () => {
    await dispatch(
      createAdIntegration(
        'facebook',
        {
          USER_ID: fbData?.userID,
          ACCESS_TOKEN: fbData?.accessToken,
          AD_ACCOUNT_ID: adAccount.id,
        },
      )
    );

    history.push(`/`);
  };

  const onSkip = () => {
    localStorage.removeItem('OTTERFISH_FB_USER_DATA');
    history.push('/');
  };

  return (
    <div className={styles.root}>
      <div className={styles.logo}>
        <LogoSVG />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>Import Ad Account</div>
        <div className={styles.label}>Facebook ads account</div>
        <Dropdown
          items={adAccounts}
          getId={i => i.id}
          getDisplayValue={i => i.name}
          placeholder='Select your ads account'
          renderItem={i => <div className={styles.dropdownOption}>{i}</div>}
          selectedItem={adAccount || ''}
          onSelect={i => setAdAccount(i)}
          className={styles.dropdown} />
        <div className={styles.navigationButtons}>
          <Button onClick={handleContinueClick} disabled={!adAccount}>Continue</Button>
          <div className={styles.doubleBtnRow}>
            <Button outline onClick={() => history.push('/connect-facebook')}>Go back</Button>
            <Button parentWidth outline onClick={onSkip}>Skip this step <MdChevronRight /></Button>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        © {dayjs().format('YYYY')} Otterfish Limited
      </div>
    </div>
  )
}
