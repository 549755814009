import React from 'react';
import styles from './CollaboratorsInfo.module.scss';
import cs from 'classnames';
import CollaboratorTooltip from './CollaboratorTooltip';
import CollaboratorsDropdown from './CollaboratorsDropdown';
import DotsDropdownButton from '../../../DotsDropdownButton';

const CollaboratorsInfo = ({ collaborators, withoutTooltip = false, modeReverse = false, dotsButtonClassName }) => {
  const displayableIconsCount = modeReverse ? 4 : 3;

  const getCollaboratorsIcons = () => {
    return collaborators.map((item, index) => {
      if (index <= displayableIconsCount - 1) {
        if (collaborators.length > displayableIconsCount && index === 0) {
          return (
            <div key={index} className={styles.counterIcon}>
              +{collaborators.length - displayableIconsCount}
            </div>
          );
        }

        const letter = item.organization_name.slice(0, 1).toUpperCase();
        return (
          <div key={item.organization_id} className={styles.icon}>
            {letter}
            {!withoutTooltip && <CollaboratorTooltip collaborator={item} iconText={letter} className={styles.collaboratorTooltip} />}
          </div>
        );
      }
      return null;
    });
  };

  return (
    <>
      <div className={cs(styles.icons, { [styles.reversed]: modeReverse })}>{getCollaboratorsIcons()}</div>
      {collaborators.length > displayableIconsCount - 1 && (
        <DotsDropdownButton
          buttonClassName={dotsButtonClassName}
          renderDropdown={dropdownRef => <CollaboratorsDropdown collaborators={collaborators} ref={dropdownRef} />}
        />
      )}
    </>
  );
};

export default CollaboratorsInfo;
