import React, { useState } from 'react';
import styles from './AddAssetButton.module.scss';
import PlusSVG from '../../../../../expert/components/IconsSvg/PlusSVG';
import ErrorModal from '../../../../../expert/components/Modal/ErrorModal';
import { ReactComponent as IconPlus } from '../../../../../assets/img/icons/plus.svg';
import VideoConvertingPlaceholderImage from '../../../../../assets/img/video-converting-placeholder.png';

/**
 *
 * @param {string} actionText
 * @param {(result: {file: File, fileUrl: string}) => any} onAddAsset
 */
const AddAssetButton = ({ actionText, onAddAsset, fileTypes = ['image'], isMobile, lengthItems }) => {
  const [error, setError] = useState('');

  const addAsset = (files, url = '') =>
    new Promise(resolve => {
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const isMov = file.type === 'video/quicktime';
        resolve({
          file: file,
          fileUrl: isMov ? VideoConvertingPlaceholderImage : url || reader.result,
        });
      };
    }).then(onAddAsset);

  const handleAddVisual = event => {
    const files = event.target.files;
    if (!files[0]) return;

    const fileType = files[0].type.match(/^\w*/g)[0];
    if (fileType === 'image') {
      addAsset(files);
    } else {
      addAsset(files, URL.createObjectURL(files[0]));
    }
  };

  return isMobile ? (
    lengthItems === 0 ? (
      <div className={styles.addBtn}>
        <input
          type="file"
          accept={`${fileTypes[0]}/*${fileTypes[1] ? ',' + fileTypes[1] + '/*' : ''}`}
          onChange={handleAddVisual}
          className={styles.fileInput}
        />
        <IconPlus />
        Add
      </div>
    ) : (
      <div className={styles.addButton}>
        <div className={styles.addButtonContentWrapper}>
          <div className={styles.plus}>
            <PlusSVG />
          </div>
        </div>
        <input
          type="file"
          accept={`${fileTypes[0]}/*${fileTypes[1] ? ',' + fileTypes[1] + '/*' : ''}`}
          onChange={handleAddVisual}
          className={styles.fileInput}
        />
        <ErrorModal open={!!error} onClose={() => setError('')} onSubmit={() => setError('')} title={error} />
      </div>
    )
  ) : (
    <div className={styles.addButton}>
      <div className={styles.addButtonContentWrapper}>
        <div className={styles.plus}>
          <PlusSVG />
        </div>
        <div className={styles.actionText}>{actionText}</div>
      </div>
      <input
        type="file"
        accept={`${fileTypes[0]}/*${fileTypes[1] ? ',' + fileTypes[1] + '/*' : ''}`}
        onChange={handleAddVisual}
        className={styles.fileInput}
      />
      <ErrorModal open={!!error} onClose={() => setError('')} onSubmit={() => setError('')} title={error} />
    </div>
  );
};

export default AddAssetButton;
