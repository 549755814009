import React from 'react';
import styles from './EmptyState.module.scss';
import { ReactComponent as CheckIcon } from '../../../../assets/img/icons/checked.svg';
import ButtonCustom from '../../../../expert/components/Buttons/ButtonCustom';
import { ReactComponent as PlusIcon } from '../../../../assets/img/icons/plus.svg';
import BackgroundIcon from '../../../../assets/img/icons/background_Campaign_briefs.png';
import screenCampaing from '../../../../assets/img/icons/screen_compaign.png';
import screenCollab from '../../../../assets/img/icons/collab.png';
import screenChat from '../../../../assets/img/icons/chat.png';
import { ReactComponent as TriangleIcon } from '../../../../assets/img/icons/play_icon.svg';
import SmallBackground from '../../../../assets/img/icons/small_background.png';
import { Link } from 'react-router-dom';
import BriefLearnMoreLink from '../../components/BriefLearnMoreLink';
import { useSelector } from 'react-redux';

const EmptyState = ({ isMobile, openWatchDemoModal }) => {
  const userId = useSelector(state => state.auth.user.id);
  const trackCampaignInitiate = () => {
    window.analytics.track('campaign_brief_initiate', {
      category: 'Campaign Brief',
      userId: userId,
    });
  };
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.leftInfo}>
          <div className={styles.description}>Campaign briefs</div>
          <div className={styles.textHeader}>Create, collaborate and approve campaign briefs in minutes</div>
          <div className={styles.text}>
            Say goodbye to shared docs. Otterfish allows you to create, collaborate, manage and streamline your campaign briefs, all in one
            place.
          </div>
          <div className={styles.items}>
            <div className={styles.itemContent}>
              <CheckIcon />
              <span>Use our template to build a great campaign brief.</span>
            </div>
            <div className={styles.itemContent}>
              <CheckIcon />
              <span>No more emails. Invite and collaborate on your brief in one place.</span>
            </div>
            <div className={styles.itemContent}>
              <CheckIcon />
              <span>Approve your briefs in a click of a button</span>
            </div>
            <div className={styles.itemContent}>
              <CheckIcon />
              <span>Access and manage multiple campaigns from your dashboard.</span>
            </div>
          </div>
          <div className={styles.buttonBlock}>
            <div onClick={() => trackCampaignInitiate()} className={styles.campaignButton}>
              <Link to={'/campaign/create'}>
                <ButtonCustom>
                  <PlusIcon />
                  New Campaign
                </ButtonCustom>
              </Link>
            </div>
            <BriefLearnMoreLink source="Campaigns">
              <ButtonCustom outline>Learn More</ButtonCustom>
            </BriefLearnMoreLink>
          </div>
        </div>
        <div className={styles.rightInfo}>
          {!isMobile ? <img src={BackgroundIcon} alt="Background image" /> : <img src={SmallBackground} alt="Background image" />}
          <div className={styles.mainImages}>
            <ButtonCustom outline className={styles.buttonWatch} onClick={openWatchDemoModal}>
              <TriangleIcon />
              Watch demo
            </ButtonCustom>
            <img src={screenCampaing} alt={'screen'} className={styles.screenPage} />
            <div className={styles.collaborators}>
              <img src={screenCollab} alt={'collab'} />
            </div>
            <div className={styles.chatScreen}>
              <img src={screenChat} alt={'chat'} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyState;
