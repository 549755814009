import React from 'react';
import styles from './RendererContentWrapper.module.scss';
import { ReactComponent as AvatarPlaceholder } from '../../../../../assets/img/icons/organization-placeholders/9.svg';
import ArrowDownSVG from '../../../../../expert/components/IconsSvg/ArrowDownSVG';

const RendererContentWrapper = ({ story, children }) => {
  return (
    <>
      <div className={styles.profileContainer}>
        <div className={styles.profileLogo}>
          <AvatarPlaceholder />
        </div>
        <div className={styles.textContainer}>
          <div>{story.pageName || 'page_name'}</div>
          <div>Sponsored</div>
        </div>
      </div>
      <div className={styles.storyImage}>{children}</div>
      <div className={styles.copyContainer}>
        <span className={styles.copy}>{story.copy || null}</span>
      </div>
      {story.cta && (
        <div className={styles.callToActionContainer}>
          <ArrowDownSVG />
          <span>{story.cta}</span>
        </div>
      )}
    </>
  );
};

export default RendererContentWrapper;
