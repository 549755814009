import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './ContentApprovalCreateBoard.module.scss';
import MainLayout from '../../../components/MainLayout';
import TabBrandDetails from './tabs/TabBrandDetails';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SpeakerSVG from '../../../components/IconsSvg/SpeakerSVG';
import SuitcaseSVG from '../../../components/IconsSvg/SuitcaseSVG';
import { useDispatch } from 'react-redux';
import { createBoard } from '../../../actions/contentApproval.actions';
import TabCollaborators from './tabs/TabCollaborators';
import DocumentSVG from '../../../expert/components/IconsSvg/DocumentSVG';
import BoardSubHeader from './components/BoardSubHeader';
import FormTab from './components/FormTab';
import DeliverablesPlatforms from '../../../components/DeliverablesPlatforms';
import { useHistory } from 'react-router-dom';
import LoaderModal from '../../../expert/components/Modal/LoaderModal';
import { TYPES_CAMPAIGNS } from '../../../shared/constants/constants';
import useDeliverablesFormik from '../../../components/DeliverablesPlatforms/hooks/useDeliverablesFormik';

const ContentApprovalCreateBoard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const { createBoardLoading } = useSelector(state => state.contentApproval);

  const [logoObject, setLogoObject] = useState({ file: null, fileUrl: null });
  const [isValidQuantity, setIsValidQuantity] = useState(false);

  const formik = useFormik({
    initialValues: {
      boardName: '',
      brandName: '',
      brandBackground: '',
      brandType: '',

      messaging: '',
      notes: '',

      // collaborators
      inviteEmail: '',
      inviteOrganizationType: '',
    },
    validationSchema: Yup.object().shape({
      brandType: Yup.string()
        .oneOf([TYPES_CAMPAIGNS.BRAND, TYPES_CAMPAIGNS.AGENCY, TYPES_CAMPAIGNS.TALENT_MANAGER, TYPES_CAMPAIGNS.CONTENT_CREATOR])
        .required('Select a type.'),
      boardName: Yup.string().required('Board name is required'),
      brandName: Yup.string().required('Brand name is required'),
      inviteEmail: Yup.string().email('Incorrect email.'),

      inviteOrganizationType: Yup.string().when('inviteEmail', {
        is: inviteEmail => inviteEmail && inviteEmail.length > 0,
        then: Yup.string()
          .oneOf([TYPES_CAMPAIGNS.BRAND, TYPES_CAMPAIGNS.AGENCY, TYPES_CAMPAIGNS.TALENT_MANAGER, TYPES_CAMPAIGNS.CONTENT_CREATOR])
          .required('Select a type.'),
      }),
    }),
    validateOnMount: true,
    validateOnChange: true,
  });

  const deliverablesFormik = useDeliverablesFormik({});

  const tabsData = [
    {
      title: 'Brand',
      content: <TabBrandDetails formik={formik} logoObject={logoObject} setLogoObject={setLogoObject} />,
      icon: <SpeakerSVG />,
    },
    {
      title: 'Who do you want to collaborate with on the Content Board?',
      content: <TabCollaborators formik={formik} />,
      icon: <SuitcaseSVG />,
    },
    {
      title: 'Deliverables',
      content: <DeliverablesPlatforms formik={deliverablesFormik} />,
      icon: <DocumentSVG />,
    },
  ];

  const backHandler = () => {
    history.push('/content-approval');
  };

  const submitHandler = () => {
    if (formik.isValid && currentOrganizationID) {
      const boardData = {
        name: formik.values.boardName,
        brand_name: formik.values.brandName,
        initiator_organization_type: formik.values.brandType,
        brand_description: formik.values.brandBackground || null,
        key_message: formik.values.messaging || null,
        notes: formik.values.notes || null,
      };

      if (logoObject.file) {
        boardData['brand_logo'] = logoObject.file;
      }

      let inviteData = null;
      if (formik.values.inviteEmail && formik.values.inviteOrganizationType) {
        inviteData = {
          email: formik.values.inviteEmail,
          organization_type: formik.values.inviteOrganizationType,
        };
      }

      dispatch(createBoard(currentOrganizationID, boardData, inviteData, deliverablesFormik.values.deliverables)).then(board =>
        history.push(`/content-board/${board.id}`)
      );
    }
  };

  useEffect(() => {
    if (deliverablesFormik.values.deliverables.length > 0) {
      let isValid = true;
      deliverablesFormik.values.deliverables.forEach(item => {
        if (!item.quantity) isValid = false
      })
      setIsValidQuantity(isValid);
    }
  }, [deliverablesFormik.values.deliverables]);

  return (
    <MainLayout title={'Content board'} contentClassName={styles.root} coloredHeader>
      <BoardSubHeader
        onSubmit={submitHandler}
        onCancel={backHandler}
        isDisabledSubmit={createBoardLoading || !formik.isValid || !deliverablesFormik.isValid || !isValidQuantity}
        title={'Create content board'}
        text={'Build a brief for the Campaign, then invite collaborators to finalize the requirements needed'}
      />
      <div className={styles.pageContent}>
        {tabsData.map((item, index) => (
          <div key={item.title}>
            <FormTab data={item} />
          </div>
        ))}
      </div>
      <LoaderModal open={createBoardLoading} />
    </MainLayout>
  );
};

export default ContentApprovalCreateBoard;
