import React, { useEffect, useState } from 'react';
import styles from './SubTabOtherTerms.module.scss';
import FacebookSVG from '../../../../../../components/IconsSvg/FacebookSVG';
import InstagramSVG from '../../../../../../components/IconsSvg/InstagramSVG';
import TikTokSVG from '../../../../../../components/IconsSvg/TikTokSVG';
import YoutubeSVG from '../../../../../../components/IconsSvg/YoutubeSVG';
import Dropdown from '../../../../../../components/FormControls/Dropdown';
import CheckboxButton from '../../../CheckboxButton';
import CheckBox from '../../../CheckBox';
import Option from '../../../Option';
import EditorComponent from '../../../../../../components/EditorComponent';
import cs from 'classnames';

const categories = [
  {
    key: 1,
    value: '1 month',
  },
  {
    key: 3,
    value: '3 months',
  },
  {
    key: 6,
    value: '6 months',
  },
  {
    key: 9,
    value: '9 months',
  },
  {
    key: 12,
    value: '1 year',
  },
  {
    key: 99,
    value: '1+ year',
  },
];

function TabOtherTerms({ formik }) {
  useEffect(() => {
    formik.setFieldValue('showSocial', !!formik.values.repostingPlatform.length);
  }, [formik.values.repostingPlatform.length]);

  useEffect(() => {
    if (!formik.values.showSocial) {
      formik.setFieldValue('repostingDuration', null);
    }
  }, [formik.values.showSocial]);

  const handleChangePlatform = value => {
    if (formik.values.repostingPlatform.includes(value)) {
      const res = formik.values.repostingPlatform.filter(item => item != value);
      formik.setFieldValue('repostingPlatform', [...res]);
    } else {
      formik.values.repostingPlatform.push(value);
      formik.setFieldValue('repostingPlatform', [...formik.values.repostingPlatform]);
    }
  };

  const enableExclusivity = () => {
    formik.setFieldValue('exclusivity', '');
    formik.setFieldValue('showExclusivity', true);
  };

  const disableExclusivity = () => {
    formik.setFieldValue('exclusivity', '');
    formik.setFieldValue('showExclusivity', false);
  };

  const enablePlatforms = () => {
    formik.setFieldValue('showSocial', true);
    formik.setFieldValue('repostingDuration', 1);
  };

  const disablePlatforms = () => {
    formik.setFieldValue('showSocial', false);
    formik.setFieldValue('repostingDuration', null);
    formik.setFieldValue('repostingPlatform', []);
  };

  return (
    <div className={styles.root}>
      <h3 className={styles.title}>Other terms</h3>

      <div className={styles.exclusivityTerms}>
        <h3 className={styles.subTitle}>Any exclusivity terms?</h3>

        <p style={{ marginBottom: 0 }}>
          Please specify if there you require the content creators to be exclusive for your brand. Also please mention the duration of your
          exclusivity and the exclusion categories and brands.
        </p>

        <div className={styles.checkboxButtonBlock}>
          <CheckboxButton
            onClick={enableExclusivity}
            isActive={formik.values.showExclusivity}
            item={'Yes'}
            className={styles.checkboxButton}
          />
          <CheckboxButton
            onClick={disableExclusivity}
            isActive={!formik.values.showExclusivity}
            item={'No'}
            className={styles.checkboxButton}
          />
        </div>

        {formik.values.showExclusivity && (
          <EditorComponent
            wrapperClassName={styles.editorWrapperClassName}
            editorClassName={styles.editorClassName}
            initialValue={formik.values.exclusivity}
            onChange={text => formik.setFieldValue('exclusivity', text)}
            onBlur={formik.handleBlur}
            placeholder={'Describe exclusivity'}
            error={formik.errors.exclusivity}
          />
        )}
      </div>

      <div className={styles.repurposeRightsBlock}>
        <h3 className={styles.subTitle}>Do you require usage/repurpose rights?</h3>
        <p style={{ marginBottom: 0 }}>This would allow you to reshare the content on to your own brand social channels.</p>

        <div className={styles.checkboxButtonBlock}>
          <CheckboxButton onClick={enablePlatforms} isActive={formik.values.showSocial} item={'Yes'} className={styles.checkboxButton} />
          <CheckboxButton onClick={disablePlatforms} isActive={!formik.values.showSocial} item={'No'} className={styles.checkboxButton} />
        </div>

        {formik.values.showSocial && (
          <>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
              <h3 className={styles.subTitle}>Choose the platform you need the repurpose rights for</h3>
              <div className={styles.socialSectionBlock}>
                <div className={cs(styles.socialSection, { [styles.socialHeadingError]: !!formik.errors.repostingPlatform })}>
                  <div className={styles.socialHeading} onClick={() => handleChangePlatform('facebook')}>
                    <CheckBox isActive={formik.values.repostingPlatform.includes('facebook')} />
                    <div className={styles.socialIconWrapper}>
                      <FacebookSVG />
                    </div>
                    <h3>Facebook</h3>
                  </div>
                </div>

                <div className={cs(styles.socialSection, { [styles.socialHeadingError]: !!formik.errors.repostingPlatform })}>
                  <div className={styles.socialHeading} onClick={() => handleChangePlatform('instagram')}>
                    <CheckBox isActive={formik.values.repostingPlatform.includes('instagram')} />
                    <div className={styles.socialIconWrapper}>
                      <InstagramSVG />
                    </div>
                    <h3>Instagram</h3>
                  </div>
                </div>

                <div className={cs(styles.socialSection, { [styles.socialHeadingError]: !!formik.errors.repostingPlatform })}>
                  <div className={styles.socialHeading} onClick={() => handleChangePlatform('tiktok')}>
                    <CheckBox isActive={formik.values.repostingPlatform.includes('tiktok')} />

                    <div className={styles.socialIconWrapper}>
                      <TikTokSVG />
                    </div>
                    <h3>TikTok</h3>
                  </div>
                </div>

                <div className={cs(styles.socialSection, { [styles.socialHeadingError]: !!formik.errors.repostingPlatform })}>
                  <div className={styles.socialHeading} onClick={() => handleChangePlatform('youtube')}>
                    <CheckBox isActive={formik.values.repostingPlatform.includes('youtube')} />
                    <div className={styles.socialIconWrapper}>
                      <YoutubeSVG />
                    </div>
                    <h3>YouTube</h3>
                  </div>
                </div>
              </div>
            </div>

            {formik.errors.repostingPlatform && <div className={styles.socialError}>{formik.errors.repostingPlatform}</div>}

            <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
              <h3 className={styles.subTitle}>For how long do you need the content repurpose rights for?</h3>
              <div style={{ width: 140 }}>
                <Dropdown
                  className={styles.dropdown}
                  items={categories}
                  getId={i => i.key}
                  getDisplayValue={i => i.value}
                  renderItem={value => <Option value={value} />}
                  selectedItem={categories.find(item => item.key == formik.values.repostingDuration)}
                  onSelect={i => formik.setFieldValue('repostingDuration', i.key)}
                />
              </div>
            </div>
          </>
        )}
      </div>

      <div className={styles.line} />

      <h2 style={{ marginBottom: 10 }}>Additional information (Optional)</h2>
      <EditorComponent
        wrapperClassName={styles.editorWrapperClassName}
        placeholder={'If applicable share any additional information about your campaign here'}
        onChange={text => {
          formik.setFieldValue('other_infos', text);
        }}
        initialValue={formik.values.other_infos || ''}
        error={formik.errors.other_infos}
      />
    </div>
  );
}

export default TabOtherTerms;
