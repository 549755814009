import React from 'react';
import './TooltipControl.scss';
import ReactTooltip from 'react-tooltip';
import cs from 'classnames';

const TooltipControl = ({ id, text, place, type, absolute, className, ...props }) => {
  // ReactTooltip.rebuild();
  return (
    <div className={cs('tooltipControl', {'absolute': absolute}, className )}>
      <span data-tip={true} data-for={id}>
        {props.children}
      </span>
      <ReactTooltip id={id} place={place || 'bottom'} type={type || 'light'} effect="solid" {...props}>
        <span>{text}</span>
      </ReactTooltip>
    </div>
  );
};

export default TooltipControl;
