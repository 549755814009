import React, { useCallback } from 'react';
import styles from './MenuInner.module.scss';
import * as icons from '../icons/index';
import cs from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Chapter from './components/Chapter';
import ModalsFlowContainer from '../ModalsContainer';
import ReportButton from './components/ReportButton';
import KnowledgeDropMenu from '../../../Header/KnowledgeDropMenu';
import { useMediaQuery } from 'react-responsive/src';
// import ButtonCustom from '../../../../expert/components/Buttons/ButtonCustom';
// import { useHistory } from 'react-router';

const MenuInner = ({ className, isMinimized, handleCloseMenu = () => {} }) => {
  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const draftCreative = useSelector(state => state.generateCreative.draftCreative[currentOrg?.organization_id])?.templateset;
  const user = useSelector(state => state.auth.user);
  const pathname = document.location.pathname;
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const dispatch = useDispatch();
  // const history = useHistory();

  const getChaptersConfig = useCallback(
    redirect => {
      return [
        {
          title: 'Instant creative',
          items: [
            // {
            //   text: 'Build Creatives',
            //   icon: iconsFactory(0),
            //   onClick: () => {
            //     window.analytics.track('instant_creative_flow', {
            //       userId: user.id,
            //       properties: {
            //         category: 'generate_creative',
            //         source: 'Menu',
            //       },
            //     });
            //     redirect('/my-templates');
            //   },
            //   disabled: !currentOrg?.owner_has_valid_subscription && !currentOrg?.owner_has_free_pricing_plan,
            //   isContinue: !!draftCreative,
            //   isActive: pathname.includes('my-templates') || pathname.includes('instant-creative/generate-creatives'),
            // },
            // {
            //   text: 'My Uploads',
            //   icon: iconsFactory(1),
            //   onClick: () => redirect('/my-uploads'),
            //   isActive: pathname.includes('my-uploads'),
            // },
            // {
            //   text: 'My Creatives',
            //   icon: iconsFactory(2),
            //   onClick: () => redirect('/my-creatives'),
            //   isActive: pathname.includes('my-creatives'),
            // },
          ],
        },
        {
          title: 'Campaign studio',
          items: [
            // {
            //   text: 'Smart Ads',
            //   icon: iconsFactory(3),
            //   onClick: () => redirect('/tests'),
            //   isActive: pathname.includes('tests'),
            // },
            {
              text: 'Campaigns',
              icon: iconsFactory(11),
              onClick: () => {
                redirect('/campaign');
                dispatch({ type: 'TOGGLE_MOBILE_SIDEBAR', payload: false });
              },
              isActive: pathname.includes('campaign'),
            },
            {
              text: 'Content approval',
              icon: iconsFactory(12),
              onClick: () => {
                redirect('/content-approval');
                dispatch({ type: 'TOGGLE_MOBILE_SIDEBAR', payload: false });
              },
              isActive: pathname.includes('content-approval') || pathname.includes('content-board'),
            },
            // {
            //   text: 'Winner Timeline',
            //   icon: iconsFactory(4),
            //   onClick: () => redirect('/winners-timeline'),
            //   isActive: pathname.includes('winners-timeline'),
            //   isBeta: true,
            // },
          ],
        },
        // {
        //   title: 'Creative brain',
        //   items: [
        //     {
        //       text: 'Dashboard',
        //       icon: iconsFactory(5),
        //       onClick: () => {},
        //       isActive: false
        //     },
        //   ]
        // },
        // {
        //   title: 'Platforms',
        //   items: [
        //     {
        //       text: 'Meta',
        //       icon: iconsFactory(6),
        //       onClick: () => redirect('/platforms/facebook/pages'),
        //       isActive: pathname.includes('platforms/facebook'),
        //     },
        //   ],
        // },
      ];
    },
    [pathname, user, draftCreative]
  );

  // const getActionButtonsConfig = useCallback(
  //   redirect => {
  //     const isActive = pathname.includes('create-test');
  //     return [
  //       {
  //         text: {
  //           default: 'Launch Smart Ads',
  //           continue: 'Continue Smart Ads',
  //         },
  //         icon: iconsFactory(8),
  //         isActive: isActive,
  //         isContinue: !!draftTest,
  //         disabled: !currentOrg?.owner_has_valid_subscription && !currentOrg?.owner_has_free_pricing_plan,
  //         onClick: () => {
  //           if (isActive) return;
  //           window.analytics.track('test_creation_flow', {
  //             userId: user.id,
  //             properties: {
  //               category: 'init_smart_ad',
  //               source: 'Menu',
  //             },
  //           });
  //           redirect('/create-test/creative');
  //         },
  //       },
  //     ];
  //   },
  //   [pathname, user, draftTest, draftCreative]
  // );

  return (
    <div className={cs(styles.inner, { [styles.minimized]: isMinimized }, className)}>
      <ModalsFlowContainer
        handleCloseMenu={handleCloseMenu}
        renderContent={(redirectTo, openBugReport) => (
          <>
            <div className={styles.chaptersContainer}>
              {getChaptersConfig(redirectTo).map((chapter, idx) => (
                <Chapter
                  key={chapter.title + idx}
                  title={chapter.title}
                  items={chapter.items}
                  isMinimized={isMinimized}
                  onCloseMenu={handleCloseMenu}
                />
              ))}
            </div>

            <div className={styles.buttonsContainer}>
              {/* <ul className={styles.actionButtons}>*/}
              {/*  {getActionButtonsConfig(redirectTo).map(buttonSettings => (*/}
              {/*    <ActionButton*/}
              {/*      key={buttonSettings.text.default}*/}
              {/*      isMinimized={isMinimized}*/}
              {/*      disabled={!currentOrg?.owner_has_valid_subscription && !currentOrg?.owner_has_free_pricing_plan}*/}
              {/*      settings={buttonSettings}*/}
              {/*    />*/}
              {/*  ))}*/}
              {/* </ul>*/}

              <ul className={styles.dropdownMenuButtons}>
                {isMobile && <KnowledgeDropMenu isMobile={isMobile} />}
                <ReportButton
                  onClick={openBugReport}
                  icons={{ icon1: iconsFactory(9), icon2: iconsFactory(10) }}
                  isMinimized={isMinimized}
                />
                {/* {isMobile && (*/}
                {/*  <ButtonCustom onClick={() => history.push('/upgrade-plan')} className={styles.updatePlanButton} clearBg>*/}
                {/*    Upgrade Plan*/}
                {/*  </ButtonCustom>*/}
                {/* )}*/}
              </ul>
            </div>
          </>
        )}
      />
    </div>
  );
};

function iconsFactory(index) {
  const keys = Object.keys(icons);
  const currentKey = keys[index];
  const Icon = icons[currentKey];
  return { icon: Icon, stylesKey: currentKey };
}

export default MenuInner;
