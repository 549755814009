import React from 'react';
import styles from './VisualHover.module.scss';
import { FaSearchPlus } from 'react-icons/fa';
import cs from 'classnames';

const VisualHover = ({ onClick, className }) => {
  return (
    <div className={cs(styles.root, className)} onClick={onClick}>
      <FaSearchPlus />
    </div>
  );
};

export default VisualHover;
