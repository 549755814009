import React, { useState, useCallback } from 'react';
import styles from './AvatarUploadModal.module.scss';
import { Modal } from 'react-responsive-modal';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';
import DropzoneControl from '../../FormControls/DropzoneControl';
import {ReactComponent as OnClose} from '../../../../../src/assets/img/icons/close.svg';
import ButtonCustom from '../../Buttons/ButtonCustom';
import {ReactComponent as IconPlus} from '../../../../assets/img/icons/plus_croping.svg';
import {ReactComponent as IconMinus} from '../../../../assets/img/icons/minus_croping.svg';
import cs from 'classnames';

const AvatarUploadModalContent = props => {
  const maxZoom = 3;
  const [files, setFiles] = useState([]);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showPrompt, setShowPrompt] = useState(true);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(files[0].url, croppedAreaPixels);
      props.onUpload(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [files, croppedAreaPixels]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  return (
    <div className={styles.root}>
      {files.length ? (
        <div className={styles.cropWrapper}>
          <Cropper
            image={files[0].url}
            crop={crop}
            zoom={zoom}
            aspect={1}
            maxZoom={maxZoom}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape="round"
          />
          <div className={styles.zoom}>
            <div  className={styles.iconMinus}>
              <IconMinus/>
            </div>
            <input
              type="range"
              value={zoom}
              min={1}
              max={maxZoom}
              step={0.001}
              aria-labelledby="Zoom"
              onChange={e => setZoom(+e.target?.value)}
            />
            <div className={styles.progress} style={{ width: `${((zoom - 1) / (maxZoom - 1)) * 80}%` }} />
            <div className={styles.track} />
            <div className={styles.iconPlus}>
              <IconPlus/>
            </div>
          </div>
          <div className={cs(styles.prompt,{[styles.promptNone]:!showPrompt})} onClick={() => setShowPrompt(false)}>
            <span>Drag to adjust image</span>
          </div>
        </div>
      ) : (
          <DropzoneControl
            files={files}
            onlyOne
            className={styles.dropzone}
            handleAddFile={files => setFiles(files)}
            type="avatar"
            fileTypes={['image/jpeg', 'image/png']}
          />
      )}
      <div className={styles.footer}>
        <ButtonCustom outline onClick={() => props.onClose()}>Cancel</ButtonCustom>
        <ButtonCustom onClick={showCroppedImage} disabled={!files.length}>Set as profile photo</ButtonCustom>
      </div>
    </div>
  );
};

export default props => {
  return (
    <Modal
      showCloseIcon={false}
      center
      {...props}
      classNames={{
        modal: styles.modal,
      }}
    >
      <div className={styles.headerModal}>
        <div className={styles.title}>Select profile photo</div>
        <div className={styles.onClose} onClick={() => props.onClose()}>
          <OnClose/>
        </div>
      </div>
      <AvatarUploadModalContent {...props} />
    </Modal>
  );
};
