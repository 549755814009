import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './Dropdown.module.scss';
import cs from 'classnames';
import { ReactComponent as ChevroneDownIcon } from '../../../assets/img/icons/chevroneDown.svg';
import SearchBar from '../../SearchBar';
import CirclesLoader from '../../CirclesLoader';

export default ({
  items,
  renderItem,
  setOpenDropdown,
  disabled,
  disabledInput,
  disabledClassName,
  getId,
  selectedItem,
  getDisplayValue,
  noValueAvailable = false,
  noValuePlaceholder = 'None',
  placeholder,
  onSelect,
  className,
  precedingIcon,
  withSearching,
  onSubmitSearch,
  onClearSearch,
  searchLoading,
  v2 = false,
  valueClassName,
  placeholderClassName,
  error,
  ...rest
}) => {
  const [expanded, setExpanded] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const dropdownContainerRef = useRef(null);
  const isTimezone = placeholder === 'Choose timezone';
  const handleClickOutside = useCallback(
    e => {
      if (!dropdownContainerRef.current.contains(e.target) && items.length) {
        setExpanded(false);
        setSearchValue('');
      }
    },
    [dropdownContainerRef, items]
  );

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (!setOpenDropdown) return;
    setOpenDropdown(expanded);
  }, [expanded]);

  const handleClickValue = () => {
    if (!items.length || disabledInput) return;
    setExpanded(state => !state);
  };

  const handleSelect = item => {
    if (disabled && disabled(item)) return;
    onSelect(item);
    if (withSearching) return;
    setExpanded(false);
  };

  return (
    <div
      className={cs(
        styles.dropdownContainer,
        { [styles.disabled]: !items.length || disabledInput },
        { [styles.openDropdownContainer]: expanded && withSearching },
        { [className]: !isTimezone || (expanded && withSearching) }
      )}
      ref={dropdownContainerRef}
      tabIndex={0}
      {...rest}
    >
      <div
        className={cs(styles.value, { [styles.v2]: v2 }, { [valueClassName]: valueClassName }, { [styles.error]: error })}
        onClick={handleClickValue}
      >
        {precedingIcon ? <div className={styles.precedingIcon}>{precedingIcon}</div> : null}
        {selectedItem ? (
          <span>{getDisplayValue(selectedItem)}</span>
        ) : noValueAvailable ? (
          <span>{noValuePlaceholder}</span>
        ) : (
          <div className={cs(styles.placeholder, { [placeholderClassName]: placeholderClassName })}>{placeholder}</div>
        )}
        <div className={cs(styles.icon, { [styles.inverted]: expanded })}>
          <ChevroneDownIcon />
        </div>
      </div>
      {expanded && withSearching && (
        <div className={cs(styles.searchContainer, { [styles.searchContainerV2]: v2 })}>
          <SearchBar
            onSubmit={() => onSubmitSearch(searchValue)}
            className={cs(styles.search, { [styles.gray]: !searchValue })}
            placeholder={'Search'}
            onClear={() => {
              setSearchValue('');
              onClearSearch();
            }}
            searchValue={searchValue}
            onChangeSearchValue={setSearchValue}
          />
        </div>
      )}
      <div className={cs(styles.optionsList, { [styles.hidden]: !expanded }, { [styles.search]: withSearching })}>
        {searchLoading ? (
          <div className={cs(styles.loading, { [styles.hidden]: !expanded })}>
            <CirclesLoader size="50px" />
          </div>
        ) : (
          <>
            {noValueAvailable ? (
              <div className={styles.option} onClick={() => handleSelect(null)}>
                {renderItem(noValuePlaceholder)}
              </div>
            ) : null}
            {items.map((i, idx) => (
              <div
                className={cs(styles.option, { [disabledClassName]: disabled && disabled(i) })}
                key={getId(i, idx)}
                onClick={() => handleSelect(i)}
              >
                {renderItem(getDisplayValue(i), withSearching ? i.id === selectedItem?.id : idx)}
              </div>
            ))}{' '}
          </>
        )}
      </div>
    </div>
  );
};
