import * as briefServices from '../services/brief';
import { createBoard } from '../services/contentApproval';

export const BRIEF_TYPES = {
  CLEAR_FAILURE: 'BRIEF_CLEAR_FAILURE',
  CREATE_BRIEF: {
    REQUEST: 'CREATE_BRIEF_REQUEST',
    SUCCESS: 'CREATE_BRIEF_SUCCESS',
    FAILURE: 'CREATE_BRIEF_FAILURE',
  },
  UPDATE_BRIEF: {
    REQUEST: 'UPDATE_BRIEF_REQUEST',
    SUCCESS: 'UPDATE_BRIEF_SUCCESS',
    FAILURE: 'UPDATE_BRIEF_FAILURE',
  },
  DELETE_BRIEF: {
    REQUEST: 'DELETE_BRIEF_REQUEST',
    SUCCESS: 'DELETE_BRIEF_SUCCESS',
    FAILURE: 'DELETE_BRIEF_FAILURE',
  },
  GET_BRIEF_LIST: {
    REQUEST: 'GET_BRIEF_LIST_REQUEST',
    SUCCESS: 'GET_BRIEF_LIST_SUCCESS',
    FAILURE: 'GET_BRIEF_LIST_FAILURE',
  },
  GET_BRIEF: {
    REQUEST: 'GET_BRIEF_REQUEST',
    SUCCESS: 'GET_BRIEF_SUCCESS',
    FAILURE: 'GET_BRIEF_FAILURE',
  },
  GET_BRIEF_ITEM: {
    SUCCESS: 'GET_BRIEF_ITEM_SUCCESS',
  },
  GET_BRIEF_CREATOR_ITEMS: {
    SUCCESS: 'GET_BRIEF_CREATOR_ITEMS_SUCCESS',
  },
  GET_CREATOR_BRIEF_BOARDS: {
    SUCCESS: 'GET_CREATOR_BRIEF_BOARDS_SUCCESS',
  },
  GET_BRIEF_VISUAL_ASSETS: {
    SUCCESS: 'GET_BRIEF_VISUAL_ASSETS_SUCCESS',
  },
  GET_BRIEF_ITEMS: {
    SUCCESS: 'GET_BRIEF_ITEMS_SUCCESS',
  },
  ADD_BRIEF_VISUAL_ASSET: {
    SUCCESS: 'ADD_BRIEF_VISUAL_ASSET_SUCCESS',
  },
  DELETE_BRIEF_VISUAL_ASSET: {
    SUCCESS: 'DELETE_BRIEF_VISUAL_ASSET_SUCCESS',
  },
  GET_COLLABORATORS: {
    SUCCESS: 'GET_COLLABORATORS_BRIEF_SUCCESS',
  },
  INVITE_COLLABORATOR: {
    REQUEST: 'INVITE_COLLABORATORS_REQUEST',
    SUCCESS: 'INVITE_COLLABORATOR_BRIEF_SUCCESS',
    FAILURE: 'INVITE_COLLABORATORS_FAILURE',
  },
  GET_INVITED_COLLABORATORS: {
    SUCCESS: 'GET_INVITED_COLLABORATORS_BRIEF_SUCCESS',
  },
  SET_BRIEF_LIST_TAB_SWITCHER: 'SET_BRIEF_LIST_TAB_SWITCHER',
  UPDATE_BRIEFS_LIST: 'UPDATE_BRIEFS_LIST',
};

/**
 * Create brief action
 * @param {Number} organizationID
 * @param {Object} briefData
 * @param {string} briefData.name
 * @param {string} briefData.notes
 * @param {string} briefData.brand_name
 * @param {string} briefData.brand_description
 * @param {string} briefData.message
 * @param {File} briefData.brand_logo
 * @param {string} briefData.brand_provides_product
 * @param {Array<{file: File, fileUrl: string, description: string}>} visualElements
 * @param {{}} inviteData
 * @param {function} callback
 * @return {(function(*): Promise<void>)|*}
 */
export const createBrief =
  (organizationID, briefData, visualElements, inviteData, callback = () => {}) =>
  async (dispatch, getState) => {
    dispatch({ type: BRIEF_TYPES.CREATE_BRIEF.REQUEST });

    briefServices
      .createBrief(organizationID, briefData)
      .then(response => {
        dispatch({ type: BRIEF_TYPES.CREATE_BRIEF.SUCCESS });
        const briefItemData = response.data[0].campaign_brief;
        const addedItem = response.data[0].campaign_brief_item;
        const userId = getState().auth.user.id;
        window.analytics.track('brief_created', {
          category: 'Campaign Brief',
          label: addedItem.name,
          userId: userId,
          campaign_brief_id: addedItem.id,
          status: 'in_progress',
          collaborator: inviteData?.inviteType?.type || null,
        });

        if (briefItemData.id) {
          if (!!visualElements.length) {
            briefAddVisualAsset(organizationID, briefItemData.id, visualElements)(dispatch);
          }

          if (addedItem.id) {
            if (!!inviteData.inviteEmail && inviteData.inviteType?.type) {
              briefServices
                .inviteMemberToBriefItem(organizationID, briefItemData.id, addedItem.id, {
                  email: inviteData.inviteEmail,
                  organization_type: inviteData.inviteType.type,
                })
                .then(inviteResponse => {
                  if (response.data[0].parent_id === 0) {
                    window.analytics.track('campaign_brief_invite_sent', {
                      category: 'Invite',
                      label: response.data[0].name,
                      userId: userId,
                      invite_id: inviteResponse.data.data[0].id,
                      campaign_brief_id: response.data[0].id,
                      company_type: inviteData.inviteType.type,
                      email: inviteData.inviteEmail,
                    });
                  } else {
                    window.analytics.track('creator_brief_invite_sent', {
                      category: 'Invite',
                      label: response.data[0].name,
                      userId: userId,
                      invite_id: inviteResponse.data.data[0].id,
                      creator_brief_id: response.data[0].id,
                      company_type: inviteData.inviteType.type,
                      email: inviteData.inviteEmail,
                    });
                  }
                });
            }
          }
        }

        callback(briefItemData, addedItem);
      })
      .catch(error => {
        dispatch({ type: BRIEF_TYPES.CREATE_BRIEF.FAILURE, payload: error.response.data });
      });
  };

export const updateBrief = (organizationID, briefData, callback, briefId, removeBrandLogo) => async dispatch => {
  if (removeBrandLogo) {
    await briefServices.deleteBriefLogo(organizationID, briefId);
  }

  briefServices
    .updateBrief(organizationID, briefId, briefData)
    .then(() => {
      callback();
    })
    .catch(error => {
      dispatch({ type: BRIEF_TYPES.UPDATE_BRIEF.FAILURE, payload: error.response.data });
    });
};

export const createCampaignBriefItem = (organizationID, campaignID, data, inviteData, callback) => async (dispatch, getState) => {
  dispatch({ type: BRIEF_TYPES.CREATE_BRIEF.REQUEST });

  briefServices
    .briefAddItem(organizationID, campaignID, data)
    .then(response => {
      dispatch({ type: BRIEF_TYPES.CREATE_BRIEF.SUCCESS });
      const briefItemId = response.data[0].id;
      const userId = getState().auth.user.id;

      if (response.data[0].parent_id === 0) {
        window.analytics.track('brief_created', {
          category: 'Campaign Brief',
          label: response.data[0].name,
          userId: userId,
          campaign_brief_id: response.data[0].id,
          status: 'in_progress',
          collaborator: inviteData?.inviteType?.type || null,
        });
      } else {
        window.analytics.track('brief_created', {
          category: 'Creator Brief',
          label: response.data[0].name,
          userId: userId,
          campaign_brief_id: response.data[0].parent_id,
          creator_brief_id: response.data[0].id,
          status: 'in_progress',
          collaborator: inviteData?.inviteType?.type || null,
        });
      }

      if (briefItemId) {
        if (!!inviteData.inviteEmail && inviteData.inviteType?.type) {
          briefServices
            .inviteMemberToBriefItem(organizationID, campaignID, briefItemId, {
              email: inviteData.inviteEmail,
              organization_type: inviteData.inviteType.type,
            })
            .then(inviteResponse => {
              if (response.data[0].parent_id === 0) {
                window.analytics.track('campaign_brief_invite_sent', {
                  category: 'Invite',
                  label: response.data[0].name,
                  userId: userId,
                  invite_id: inviteResponse.data.data[0].id,
                  campaign_brief_id: response.data[0].id,
                  company_type: inviteData.inviteType.type,
                  email: inviteData.inviteEmail,
                });
              } else {
                window.analytics.track('creator_brief_invite_sent', {
                  category: 'Invite',
                  label: response.data[0].name,
                  userId: userId,
                  invite_id: inviteResponse.data.data[0].id,
                  creator_brief_id: response.data[0].id,
                  company_type: inviteData.inviteType.type,
                  email: inviteData.inviteEmail,
                });
              }
            });
        }
        callback(briefItemId);
      }
    })
    .catch(error => {
      dispatch({ type: BRIEF_TYPES.CREATE_BRIEF.FAILURE, payload: error.response.data });
    });
};

export const editCampaignBriefItem = (organizationID, campaignID, briefID, data, callback) => async dispatch => {
  dispatch({ type: BRIEF_TYPES.CREATE_BRIEF.REQUEST });

  briefServices
    .briefEditItem(organizationID, campaignID, briefID, data)
    .then(() => {
      dispatch({ type: BRIEF_TYPES.CREATE_BRIEF.SUCCESS });
      callback();
    })
    .catch(error => {
      dispatch({ type: BRIEF_TYPES.CREATE_BRIEF.FAILURE, payload: error.response.data });
    });
};

export const createBoardForCreatorBrief =
  (organizationID, creatorBriefID, campaignBriefId, collaborators, callback) => async (dispatch, getState) => {
    createBoard(organizationID, { campaign_brief_item_id: creatorBriefID }).then(response => {
      const id = response.data[0].id;
      const userId = getState().auth.user.id;
      const companyType = collaborators.find(item => item.approver_id.toString() === userId.toString())?.type;

      window.analytics.track('create_board', {
        category: 'content board',
        label: response.data[0].name,
        userId: response.data[0].creator_id,
        company_type: companyType,
        creator_brief_id: creatorBriefID,
        campaign_brief_id: campaignBriefId,
        content_board_id: response.data[0].id,
        source: 'brief',
        status: 'in progress',
      });

      if (id) {
        callback(id);
      }
    });
  };

export const getBoardsForCreatorBrief = (organizationID, creatorBriefID) => async dispatch => {
  briefServices
    .getBoardsForCreatorBrief(organizationID, creatorBriefID)
    .then(response => {
      dispatch({ type: BRIEF_TYPES.GET_CREATOR_BRIEF_BOARDS.SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: BRIEF_TYPES.BRIEF_ERRORS, payload: error });
    });
};

export const deleteBrief = (organizationID, briefID) => async dispatch => {
  briefServices.deleteBrief(organizationID, briefID).then(response => {
    dispatch({ type: BRIEF_TYPES.DELETE_BRIEF.SUCCESS });
  });
};

/**
 * Get Brief List
 * @param {Number} organizationID
 * @param {{search_filter?: String, order_by?: 'id', order_type?: 'desc', page?: Number, page_size?: Number, status?: String}=} params
 * @param {() => any} errorCallback
 * @return {(function(*): Promise<void>)|*}
 */

export const getBriefList =
  (organizationID, params = {}, errorCallback) =>
  async dispatch => {
    try {
      dispatch({ type: BRIEF_TYPES.GET_BRIEF_LIST.REQUEST });

      const briefsParams = params.is_archived ? { is_archived: false } : { ...params, is_archived: false };
      const archivedParams = params.is_archived ? { ...params, is_archived: true } : { is_archived: true };

      const briefs = await briefServices.getBriefList(organizationID, briefsParams);
      const archived = await briefServices.getBriefList(organizationID, archivedParams);

      const isNoSearchFiltersYet =
        !params.search_filter && !params.status && !params.approver_id && !params.date_start && !params.date_end && !params.is_archived;

      let currentBrief;
      if (params.is_archived) {
        currentBrief = archived.data;
      } else if (archived.data.length && !briefs.data.length && isNoSearchFiltersYet) {
        currentBrief = archived.data;
        dispatch(setBriefListTab('archived'));
      } else {
        currentBrief = briefs.data;
      }

      dispatch({
        type: BRIEF_TYPES.GET_BRIEF_LIST.SUCCESS,
        payload: {
          data: currentBrief,
          total_count: briefs.total_count,
          archived_total_count: archived.total_count,
        },
      });
    } catch (e) {
      errorCallback && errorCallback();
      dispatch({ type: BRIEF_TYPES.GET_BRIEF_LIST.FAILURE });
    }
  };

export const archiveBrief = (organizationId, briefItemID) => async dispatch => {
  dispatch({ type: BRIEF_TYPES.GET_BRIEF_LIST.REQUEST });
  try {
    await briefServices.archiveBrief(organizationId, briefItemID);
    dispatch({ type: BRIEF_TYPES.GET_BRIEF_LIST.REQUEST, payload: false });
    return true;
  } catch (e) {
    dispatch({ type: BRIEF_TYPES.GET_BRIEF_LIST.FAILURE });
  }
};

export const unzipBrief = (organizationId, briefItemID) => async dispatch => {
  dispatch({ type: BRIEF_TYPES.GET_BRIEF_LIST.REQUEST });
  try {
    await briefServices.unzipBrief(organizationId, briefItemID);
    dispatch({ type: BRIEF_TYPES.GET_BRIEF_LIST.REQUEST, payload: false });
    return true;
  } catch (e) {
    dispatch({ type: BRIEF_TYPES.GET_BRIEF_LIST.FAILURE });
  }
};

export const setBriefListTab = switcher => ({ type: BRIEF_TYPES.SET_BRIEF_LIST_TAB_SWITCHER, payload: switcher });
export const clearBriefsList = switcher => ({ type: BRIEF_TYPES.UPDATE_BRIEFS_LIST, payload: [] });

export const getBrief = (organizationID, briefID, errorCallback) => async dispatch => {
  dispatch({ type: BRIEF_TYPES.GET_BRIEF.REQUEST });
  briefServices
    .getBrief(organizationID, briefID)
    .then(response => {
      const brief = response.data[0];
      dispatch({ type: BRIEF_TYPES.GET_BRIEF.SUCCESS, payload: { data: brief } });
      briefServices.getBriefVisualAssets(organizationID, brief.id).then(response => {
        // todo: fix it when back will fixed - Array response.data.data
        dispatch({ type: BRIEF_TYPES.GET_BRIEF_VISUAL_ASSETS.SUCCESS, payload: response.data });
      });
    })
    .catch(() => {
      errorCallback && errorCallback();
    });
};

export const getBriefItem = (organizationID, briefID, briefItemID, errorCallback) => async dispatch => {
  briefServices
    .getBriefItem(organizationID, briefID, briefItemID)
    .then(response => {
      dispatch({ type: BRIEF_TYPES.GET_BRIEF_ITEM.SUCCESS, payload: { data: response.data[0] } });
    })
    .catch(() => {
      errorCallback && errorCallback();
    });
};

/**
 * @param organizationID
 * @param briefID
 * @param {{parent_id: Number, search_filter?: String, order_by?: 'id', order_type?: 'desc', page?: Number, page_size?: Number}=} params
 * @returns {(function(*): Promise<void>)|*}
 */
export const getBriefItems = (organizationID, briefID, params, errorCallback) => async dispatch => {
  briefServices.getBriefItems(organizationID, briefID, params).then(response => {
    // todo: fix it when back will fixed - Array response.data.data
    dispatch({ type: BRIEF_TYPES.GET_BRIEF_ITEMS.SUCCESS, payload: response.data });

    briefServices
      .getBriefItems(organizationID, briefID, { parent_id: '!0' })
      .then(response => {
        dispatch({ type: BRIEF_TYPES.GET_BRIEF_CREATOR_ITEMS.SUCCESS, payload: response.data });
      })
      .catch(() => {
        errorCallback && errorCallback();
      });
  });
};

export const inviteCollaborator = (organizationID, briefID, briefItem, inviteData, callback) => async (dispatch, getState) => {
  const userId = getState().auth.user.id;
  briefServices
    .inviteMemberToBriefItem(organizationID, briefID, briefItem.id, {
      email: inviteData.email,
      organization_type: inviteData.organizationType?.type || inviteData.new_organization_type,
      custom_message: inviteData.message || null,
    })
    .then(response => {
      if (briefItem.parent_id === 0) {
        window.analytics.track('campaign_brief_invite_sent', {
          category: 'Invite',
          label: briefItem.name,
          userId: userId,
          invite_id: response.data.data[0].id,
          campaign_brief_id: briefItem.id,
          company_type: inviteData.organizationType?.type || inviteData.new_organization_type,
          email: inviteData.email,
        });
      } else {
        window.analytics.track('creator_brief_invite_sent', {
          category: 'Invite',
          label: briefItem.name,
          userId: userId,
          invite_id: response.data.data[0].id,
          creator_brief_id: briefItem.id,
          company_type: inviteData.organizationType?.type || inviteData.new_organization_type,
          email: inviteData.email,
        });
      }
      dispatch(getInvitedCollaborators(organizationID, briefID, briefItem.id));
      dispatch({ type: BRIEF_TYPES.INVITE_COLLABORATOR.SUCCESS, payload: response });
      if (callback) {
        callback();
      }
    });
};

export const getCollaborators = (organizationID, briefID, briefItem) => async dispatch => {
  briefServices.getBriefOrganization(organizationID, briefID, briefItem).then(response => {
    dispatch({ type: BRIEF_TYPES.GET_COLLABORATORS.SUCCESS, payload: response });
  });
};

export const getInvitedCollaborators = (organizationID, briefID, briefItem) => async dispatch => {
  briefServices.getBriefInvitedCollaborators(organizationID, briefID, briefItem).then(response => {
    dispatch({ type: BRIEF_TYPES.GET_INVITED_COLLABORATORS.SUCCESS, payload: response });
  });
};

/**
 * Add visual asset to the brief item
 * @param {number} organizationID
 * @param {number} briefID
 * @param {Array<{file: File, fileUrl: string, description: string}>} visualElements
 * @param {boolean} addToBriefItem - if true the visual element will add to the current brief item
 * @return {Promise<*>}
 */
export const briefAddVisualAsset =
  (organizationID, briefID, visualElements, addToBriefItem = false) =>
  async dispatch => {
    // add visual assets

    briefServices.briefAddVisualAsset(organizationID, briefID, visualElements).then(response => {
      if (addToBriefItem) {
        dispatch({
          type: BRIEF_TYPES.ADD_BRIEF_VISUAL_ASSET.SUCCESS,
          payload: { visualElements: response.data.data },
        });
      }
    });
  };

export const deleteBriefVisualAsset = (organizationID, briefID, assetID) => async dispatch => {
  dispatch({ type: BRIEF_TYPES.DELETE_BRIEF_VISUAL_ASSET.SUCCESS, payload: { assetID: assetID } }); // call it for removing the asset from state before request is done
  briefServices.deleteBriefVisualAsset(organizationID, briefID, assetID);
};

export const approveBriefItem = (organizationID, campaignID, briefItemID) => async dispatch => {
  briefServices.approveBriefItem(organizationID, campaignID, briefItemID).then(() => {
    getBriefItem(organizationID, campaignID, briefItemID)(dispatch);
    getCollaborators(organizationID, campaignID, briefItemID)(dispatch);
  });
};

export const clearBriefFailure = () => async dispatch => {
  dispatch({ type: BRIEF_TYPES.CLEAR_FAILURE });
};

export const deleteInviteBriefCollaborator = (organizationID, briefId, briefItem, invitedId, callback) => dispatch => {
  briefServices.deleteInvitedBriefCollaborator(organizationID, briefId, briefItem, invitedId).then(response => {
    dispatch(getInvitedCollaborators(organizationID, briefId, briefItem));
    if (callback) {
      callback();
    }
  });
};
