import React, { useEffect, useState } from 'react';
import styles from './DropDownEditAvatar.module.scss';
import cs from 'classnames';
import TrashSVG from '../../../components/IconsSvg/TrashSVG';
import PlusSVG from '../../../components/IconsSvg/PlusSVG';

const DropDownEditAvatar = ({ onOpenModalDropzone, hasImage = true, onRemove, ...props }) => {
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setTimeout(() => {
      if (open) {
        window.addEventListener('click', onClose);
      } else {
        window.removeEventListener('click', onClose);
      }
    }, 100);

    return () => {
      // clear
      window.removeEventListener('click', onClose);
    };
  }, [open]);

  return (
    <div className={cs(styles.root, open ? styles.show : styles.hide)}>
      <div className={styles.head} onClick={onOpen}>
        {props.children}
      </div>
      <ul className={styles.lists}>
        <li className={styles.list} onClick={onOpenModalDropzone}>
          <p className={styles.link}>
            <span className={styles.iconEdit}>
              <PlusSVG />
            </span>
            Add photo
          </p>
        </li>
        {
          hasImage &&
          <li className={styles.list} onClick={onRemove}>
            <p className={styles.link}>
            <span className={styles.iconRemove}>
              <TrashSVG/>
            </span>
              Remove photo
            </p>
          </li>
        }
      </ul>
    </div>
  );
};

export default DropDownEditAvatar;
