import React, { useEffect, useMemo, useState } from 'react';
import MainLayout from '../../../components/MainLayout';
import styles from './ContentApprovalList.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { clearContentBoardsList, getContentBoards, setContentApprovalTab } from '../../../actions/contentApproval.actions';
import ContentList from './components/ContentList';
import ContentApprovalWelcomePage from '../ContentApprovalWelcomePage';
import useContentApprovalSockets from './useContentApprovalListSockets';
import { getOrganizationUsers } from '../../../actions/user.actions';
import useFilters from '../../CampaignBrief/CampaignBriefList/useFilters';
import NoSearchResults from '../../../components/BriefsAndBoardsFlows/RootListPagesComponents/NoSearchResults';
import HeaderComponent from '../../../components/BriefsAndBoardsFlows/RootListPagesComponents/HeaderComponent';
import FiltersMobile from '../../../components/BriefsAndBoardsFlows/RootListPagesComponents/FiltersMobile';
import { useMediaQuery } from 'react-responsive/src';
import IconMobileSearch from '../../../components/IconMobileSearch';

const headerTextsConfig = {
  title: 'Content Approval',
  searchPlaceholder: 'Search Boards or Related Brief',
  statusPlaceholder: 'Status',
  memberPlaceholder: 'Team members',
  createButtonText: 'New Board',
  createButtonRoute: '/content-approval/create',
};

const statusOptions = [
  {
    key: 'in_progress',
    name: 'In progress',
  },
  {
    key: 'completed',
    name: 'Completed',
  },
];

const ContentApprovalList = () => {
  const dispatch = useDispatch();
  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const { boards, boardsLoading, contentApprovalTabSwitcher } = useSelector(state => state.contentApproval);
  const pageSize = 10;
  const [showFiltersMobile, setShowFiltersMobile] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const showMobileSideBar = useSelector(state => state.sidebarMenu.showMobileSideBar);

  const { filter, setPagePagination, onSort, getParams, ...filterFunctions } = useFilters(
    pageSize,
    statusOptions,
    contentApprovalTabSwitcher,
    'OTTERFISH_FILTERS_APPROVALS'
  );

  const isNoSearchFiltersYet =
    !filter.searchValue && filter.statusSelectIndex === -1 && filter.memberSelectIndex === -1 && !filter.dateStart && !filter.dateEnd;

  const isNoBoardsYet = !boards.length && !boardsLoading && isNoSearchFiltersYet;
  const isDisplayEmptyResults = !boards.length && !boardsLoading && !isNoBoardsYet;

  useContentApprovalSockets();

  useEffect(() => {
    if (currentOrganizationID) {
      dispatch(setContentApprovalTab('boards'));
      getContentBoardsHandler();
      dispatch(getOrganizationUsers(currentOrganizationID));
    }
    return () => {
      dispatch(setContentApprovalTab('boards'));
      dispatch(clearContentBoardsList());
    };
  }, [currentOrganizationID]);

  useEffect(() => {
    if (currentOrganizationID) {
      getContentBoardsHandler();
    }
  }, [filter]);

  const getContentBoardsHandler = () => dispatch(getContentBoards(currentOrganizationID, getParams()));

  const showCountFilters = () => {
    const countValues = { ...filter };
    delete countValues.page;
    delete countValues.sort;
    delete countValues.isArchived;

    let count = 0;

    for (const key in countValues) {
      switch (key) {
        case 'searchValue':
          if (countValues[key]) count++;
          break;
        case 'dateStart':
          if (countValues[key] !== null) count++;
          break;
        case 'memberSelectIndex':
          if (countValues[key] !== -1) count++;
          break;
        case 'statusSelectIndex':
          if (countValues[key] !== -1) count++;
          break;
      }
    }
    return count;
  };

  const countFilters = useMemo(() => showCountFilters(), [filter]);

  return (
    <>
      <MainLayout title={'Content approval'} coloredHeader contentClassName={styles.layout}>
        {!isNoBoardsYet && (
          <HeaderComponent
            textsConfig={headerTextsConfig}
            filterFunctions={filterFunctions}
            statusOptions={statusOptions}
            filter={filter}
            switcher={contentApprovalTabSwitcher}
            showFilters={showFiltersMobile}
          />
        )}

        {(!!boards.length || boardsLoading) && (
          <ContentList
            page={filter.page}
            setPage={setPagePagination}
            sort={filter.sort}
            setSort={onSort}
            pageSize={pageSize}
            onArchive={getContentBoardsHandler}
            isNoSearchFiltersYet={isNoSearchFiltersYet}
          />
        )}
        {isDisplayEmptyResults && <NoSearchResults buttonText="Create Board" route="/content-approval/create" />}
        {isNoBoardsYet && <ContentApprovalWelcomePage />}
      </MainLayout>
      {!isNoBoardsYet && !showMobileSideBar && (
        <IconMobileSearch count={countFilters} setShowFiltersMobile={() => setShowFiltersMobile(!showFiltersMobile)} />
      )}
      {isMobile && (
        <FiltersMobile
          onClose={() => setShowFiltersMobile(false)}
          textsConfig={headerTextsConfig}
          filterFunctions={filterFunctions}
          statusOptions={statusOptions}
          filter={filter}
          switcher={contentApprovalTabSwitcher}
          showFilters={showFiltersMobile}
        />
      )}
    </>
  );
};

export default ContentApprovalList;
