import { PAYMENT_TYPES } from '../actions/payment.action';

const initialState = {
  allPlans: [],
  loading: false,
  failure: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PAYMENT_TYPES.GET_ALL_PLANS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PAYMENT_TYPES.GET_ALL_PLANS.SUCCESS:
      return {
        ...state,
        loading: false,
        allPlans: payload,
      };
    case PAYMENT_TYPES.GET_ALL_PLANS.FAILURE:
      return {
        ...state,
        loading: false,
        failure: payload,
      };
    case PAYMENT_TYPES.CLEAR_FAILURE:
      return {
        ...state,
        failure: {},
      };
    default: {
      return state;
    }
  }
};
