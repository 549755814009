import React from 'react';
import styles from './LeftPartForgotReset.module.scss';
import image from '../../../assets/img/icons/signup_left_image.png';
import LogoSVG from '../../IconsSvg/LogoSVG';

const LeftPartForgotReset = () => {
  return (
    <div className={styles.root}>
      <img src={image}/>
      <LogoSVG/>
    </div>
  )
}

export default LeftPartForgotReset;
