import React from 'react';
import styles from './BoardSubHeader.module.scss';
import ButtonCustom from '../../../../../expert/components/Buttons/ButtonCustom';

function BoardSubHeader({ onSubmit, onCancel, title, text, isDisabledSubmit }) {
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{text}</div>
      </div>
      <div className={styles.navigationButtons}>
        {onCancel && (
          <ButtonCustom outline onClick={onCancel} className={styles.opacityButton}>
            Cancel
          </ButtonCustom>
        )}
        {onSubmit && (
          <ButtonCustom outline onClick={onSubmit} disabled={isDisabledSubmit}>
            Complete Board
          </ButtonCustom>
        )}
      </div>
    </div>
  );
}

export default BoardSubHeader;
