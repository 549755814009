/* eslint-disable guard-for-in */
/* eslint-disable camelcase */
import * as audiencesServices from '../services/audiences';
import * as fbServices from '../services/facebookIntegrations';

export const AUDIENCES_TYPES = {
  CREATE_AUDIENCE: {
    REQUEST: 'CREATE_AUDIENCE_REQUEST',
    SUCCESS: 'CREATE_AUDIENCE_SUCCESS',
    FAILURE: 'CREATE_AUDIENCE_FAILURE',
  },
  IMPORT_AUDIENCE: {
    REQUEST: 'IMPORT_AUDIENCE_REQUEST',
    SUCCESS: 'IMPORT_AUDIENCE_SUCCESS',
    FAILURE: 'IMPORT_AUDIENCE_FAILURE',
  },
  EDIT_AUDIENCE: {
    REQUEST: 'EDIT_AUDIENCE_REQUEST',
    SUCCESS: 'EDIT_AUDIENCE_SUCCESS',
    FAILURE: 'EDIT_AUDIENCE_FAILURE',
  },
  GET_AUDIENCES: {
    REQUEST: 'GET_AUDIENCES_REQUEST',
    SUCCESS: 'GET_AUDIENCES_SUCCESS',
    FAILURE: 'GET_AUDIENCES_FAILURE',
  },
  GET_AUDIENCE: {
    REQUEST: 'GET_AUDIENCE_REQUEST',
    SUCCESS: 'GET_AUDIENCE_SUCCESS',
    FAILURE: 'GET_AUDIENCE_FAILURE',
  },
  GET_ENTIRE_AUDIENCES_LIST: {
    REQUEST: 'GET_ENTIRE_AUDIENCES_LIST_REQUEST',
    SUCCESS: 'GET_ENTIRE_AUDIENCES_LIST_SUCCESS',
    FAILURE: 'GET_ENTIRE_AUDIENCES_LIST_FAILURE',
  },
  GET_AUDIENCES_FOR_IMPORT: {
    REQUEST: 'GET_AUDIENCES_FOR_IMPORT_REQUEST',
    SUCCESS: 'GET_AUDIENCES_FOR_IMPORT_SUCCESS',
    FAILURE: 'GET_AUDIENCES_FOR_IMPORT_FAILURE',
  },
  REFRESH_AUDIENCES: {
    REQUEST: 'REFRESH_AUDIENCE_REQUEST',
    SUCCESS: 'REFRESH_AUDIENCE_SUCCESS',
    FAILURE: 'REFRESH_AUDIENCE_FAILURE',
  },
  CHANGE_AUDIENCES_STATUS: {
    REQUEST: 'CHANGE_AUDIENCES_STATUS_REQUEST',
    SUCCESS: 'CHANGE_AUDIENCES_STATUS_SUCCESS',
    FAILURE: 'CHANGE_AUDIENCES_STATUS_FAILURE',
  },
  UPDATE_META: {
    REQUEST: 'UPDATE_META_REQUEST',
    SUCCESS: 'UPDATE_META_SUCCESS',
    FAILURE: 'UPDATE_META_FAILURE',
  },
  DELETE_AUDIENCE: {
    REQUEST: 'DELETE_AUDIENCE_REQUEST',
    SUCCESS: 'DELETE_AUDIENCE_SUCCESS',
    FAILURE: 'DELETE_AUDIENCE_FAILURE',
  },
  CLEAR_FAILURE: 'CLEAR_FAILURE',
};

export const createHierarchy = response => {
  const data = response.data;
  const arr = [];

  for (const i in data) {
    const name = data[i].name;
    const type = data[i].path ? data[i].path[0] : data[i].name;
    const key = data[i].key;
    const path = data[i].path;
    if (key === '__ROOT__') continue;

    if (!arr.filter(item => item.path[0] === type).length) {
      arr.push({ label: type, children: [], path: [type] });
    }

    if (!path) continue;

    const wayId = arr.findIndex(item => item.label === type);

    for (let elem = arr[wayId].children, pId = 1; pId < path.length; pId++) {
      if (pId === path.length - 1) {
        elem.push({ ...data[i], label: name, value: name, path: [...path, name] });
        break;
      } else if (!elem.filter(item => item.label === path[pId]).length) {
        elem.push({ label: path[pId], children: [], path: path.slice(0, pId + 1) });
      }
      elem = elem[elem.findIndex(item => item.label === path[pId])].children;
    }
  }
  return arr;
};

export const updateAudienceMeta = fb_integration => async dispatch => {
  try {
    dispatch({
      type: AUDIENCES_TYPES.UPDATE_META.REQUEST,
      payload: {
        fb_integration,
      },
    });
    const {
      params: { ACCESS_TOKEN, AD_ACCOUNT_ID },
    } = fb_integration;
    const targets = await fbServices.fetchTargets_(ACCESS_TOKEN, AD_ACCOUNT_ID);
    const languages = await fbServices.fetchLocales(ACCESS_TOKEN);
    const data = {
      targets: createHierarchy(targets),
      languages,
    };

    dispatch({
      type: AUDIENCES_TYPES.UPDATE_META.SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: AUDIENCES_TYPES.UPDATE_META.FAILURE,
      payload: error.response.data,
    });
  }
};

export const createAudience = (fb_integration, data) => async dispatch => {
  try {
    dispatch({
      type: AUDIENCES_TYPES.CREATE_AUDIENCE.REQUEST,
      payload: {
        fb_integration,
        data,
      },
    });

    const {
      organization_id,
      params: { ACCESS_TOKEN, AD_ACCOUNT_ID },
    } = fb_integration;

    if (process.env.REACT_APP_SAVE_AUDIENCE_TO_FB === 'true') {
      const fbResponse = await fbServices.createSavedAudience(ACCESS_TOKEN, AD_ACCOUNT_ID, data);
      const external_ids = [{ type: 'saved', id: fbResponse.id }];
      const response = await audiencesServices.importAudience(organization_id, external_ids);
      dispatch({
        type: AUDIENCES_TYPES.CREATE_AUDIENCE.SUCCESS,
        payload: response.data[0],
      });
      return response.data[0];
    } else {
      const response = await audiencesServices.createAudience(organization_id, { name: data.name, type: 'saved', external_data: data });
      dispatch({
        type: AUDIENCES_TYPES.CREATE_AUDIENCE.SUCCESS,
        payload: response.data,
      });
      return response.data;
    }
  } catch (error) {
    dispatch({
      type: AUDIENCES_TYPES.CREATE_AUDIENCE.FAILURE,
      payload: error.response.data,
    });
  }
};

export const importAudience = (fb_integration, external_ids) => async (dispatch, getState) => {
  try {
    dispatch({
      type: AUDIENCES_TYPES.IMPORT_AUDIENCE.REQUEST,
      payload: {
        fb_integration,
        external_ids,
      },
    });

    const { organization_id } = fb_integration;
    const response = await audiencesServices.importAudience(organization_id, external_ids);

    const userId = getState().auth.user.id;
    // window.analytics.track('audience_import', {
    //   userId: userId,
    //   count: external_ids.length,
    // });

    dispatch({
      type: AUDIENCES_TYPES.IMPORT_AUDIENCE.SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: AUDIENCES_TYPES.IMPORT_AUDIENCE.FAILURE,
      payload: error.response.data,
    });
    throw error;
  }
};

export const editAudience = (fb_integration, id, data) => async dispatch => {
  try {
    dispatch({
      type: AUDIENCES_TYPES.EDIT_AUDIENCE.REQUEST,
      payload: {
        fb_integration,
        id,
        data,
      },
    });

    const {
      params: { ACCESS_TOKEN },
    } = fb_integration;

    if (data.external_id) await fbServices.editSavedAudience(ACCESS_TOKEN, data);
    const response = await audiencesServices.editAudience(id, data);

    dispatch({
      type: AUDIENCES_TYPES.EDIT_AUDIENCE.SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: AUDIENCES_TYPES.EDIT_AUDIENCE.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getAudiences = (organizationId, page, pageSize, searchFilter) => async (dispatch, getState) => {
  try {
    dispatch({
      type: AUDIENCES_TYPES.GET_AUDIENCES.REQUEST,
      payload: {},
    });
    const currentOrg = getState().auth.currentOrganization;
    const params = {
      organization_id: organizationId || currentOrg?.organization_id,
    };
    if (page) params.page = page;
    if (pageSize) params.page_size = pageSize;
    if (searchFilter) params.search_filter = searchFilter;
    const response = await audiencesServices.getAudiences(null, params);

    dispatch({
      type: AUDIENCES_TYPES.GET_AUDIENCES.SUCCESS,
      payload: response.data,
      totalCount: response.total_count,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: AUDIENCES_TYPES.GET_AUDIENCES.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getEntireAudiencesList = organizationId => async (dispatch, getState) => {
  try {
    dispatch({
      type: AUDIENCES_TYPES.GET_ENTIRE_AUDIENCES_LIST.REQUEST,
      payload: {},
    });
    const currentOrg = getState().auth.currentOrganization;
    const params = {};
    params.page = 1;
    params.page_size = 500;
    params.organization_id = organizationId || currentOrg.organization_id;
    const response = await audiencesServices.getAudiences(null, params);

    dispatch({
      type: AUDIENCES_TYPES.GET_ENTIRE_AUDIENCES_LIST.SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: AUDIENCES_TYPES.GET_ENTIRE_AUDIENCES_LIST.FAILURE,
      payload: error.response?.data,
    });
  }
};

export const getAudience = (id, organizationId) => async dispatch => {
  try {
    dispatch({
      type: AUDIENCES_TYPES.GET_AUDIENCE.REQUEST,
      payload: {},
    });

    const response = await audiencesServices.getAudiences(id, organizationId);

    dispatch({
      type: AUDIENCES_TYPES.GET_AUDIENCE.SUCCESS,
      payload: response.data[0],
    });
    return response.data[0];
  } catch (error) {
    dispatch({
      type: AUDIENCES_TYPES.GET_AUDIENCE.FAILURE,
      payload: error.response.data,
    });
  }
};

export const refreshAudiences = (organizationId, ids) => async (dispatch, getState) => {
  try {
    dispatch({
      type: AUDIENCES_TYPES.REFRESH_AUDIENCES.REQUEST,
      payload: {},
    });

    const currentOrg = getState().auth.currentOrganization;
    const organization_id = organizationId || currentOrg?.organization_id;

    const response = await audiencesServices.refreshAudiences(organization_id, ids);

    let newAudiences = [...getState().audience.audiences];
    if (response?.data) {
      newAudiences = newAudiences.map(audience => {
        const refreshedItem = response.data.find(item => item.id === audience.id);
        if (refreshedItem) {
          return refreshedItem;
        }
        return audience;
      });
    }

    dispatch({
      type: AUDIENCES_TYPES.REFRESH_AUDIENCES.SUCCESS,
      payload: newAudiences,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: AUDIENCES_TYPES.REFRESH_AUDIENCES.FAILURE,
      payload: error.response.data,
    });
  }
};

export const changeAudienceStatus = (fb_integration, status, ids) => async dispatch => {
  try {
    dispatch({
      type: AUDIENCES_TYPES.CHANGE_AUDIENCES_STATUS.REQUEST,
      payload: {},
    });
    const { organization_id } = fb_integration;
    const response = await audiencesServices.changeAudienceStatus(organization_id, status, ids);

    dispatch({
      type: AUDIENCES_TYPES.CHANGE_AUDIENCES_STATUS.SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: AUDIENCES_TYPES.CHANGE_AUDIENCES_STATUS.FAILURE,
      payload: error.response.data,
    });
  }
};

export const deleteAudience = id => async dispatch => {
  try {
    dispatch({
      type: AUDIENCES_TYPES.DELETE_AUDIENCE.REQUEST,
    });

    const response = await audiencesServices.deleteAudience(id);

    dispatch({
      type: AUDIENCES_TYPES.DELETE_AUDIENCE.SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: AUDIENCES_TYPES.DELETE_AUDIENCE.FAILURE,
      payload: error.response.data,
    });
  }
};

export const clearFailure = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: AUDIENCES_TYPES.CLEAR_FAILURE,
    });
  } catch (err) {}
};

export const getAudiencesForImport = (organizationId, type = 'saved', searchString = '') => async (dispatch, getState) => {
  try {
    dispatch({
      type: AUDIENCES_TYPES.GET_AUDIENCES_FOR_IMPORT.REQUEST,
      payload: {},
    });
    const currentOrg = getState().auth.currentOrganization;
    const params = {};
    params.page = 1;
    params.page_size = 500;
    params.organization_id = organizationId || currentOrg.organization_id;
    params.type = type;
    if (searchString) {
      params.search_string = searchString;
    }
    const response = await audiencesServices.getAudiencesForImport(params);

    dispatch({
      type: AUDIENCES_TYPES.GET_AUDIENCES_FOR_IMPORT.SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: AUDIENCES_TYPES.GET_AUDIENCES_FOR_IMPORT.FAILURE,
      payload: error.response.data,
    });
  }
};
