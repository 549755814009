import React, { useState } from 'react';
import styles from './BreadcrumbsDropdown.module.scss';

const BreadcrumbsDropdown = ({ items, onClick }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <span className={styles.root}>
      <span onClick={() => setIsDropdownOpen(!isDropdownOpen)}>......</span>
      {isDropdownOpen && (
        <div>
          <ul>
            {items.map((item, i) => {
              if (i === items.length - 1) return null;
              return (
                <li key={item.name + i} onClick={onClick ? onClick(item?.callback, i) : null}>
                  {item.name}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </span>
  );
};

export default BreadcrumbsDropdown;
