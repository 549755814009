import axios from 'axios';

export const instance = axios.create({
  baseURL: 'https://graph.facebook.com/v12.0/',
  headers: { 'Content-Type': 'application/json' },
});

export const getAdAccounts = async (token, adAccount) => {
  const route = `${adAccount}/adaccounts`;
  const params = {
    access_token: token,
    fields: 'name',
  };
  const response = await instance.get(route, { params });
  return response.data.data;
};

export const getOwnedAdAccounts = async (token, businessUserId) => {
  const route = `/${businessUserId}/assigned_ad_accounts`;
  const params = {
    access_token: token,
    fields: '["account_id", "name", "tasks"]',
    limit: 500,
  };
  const response = await instance.get(route, { params });
  return response.data.data;
};

export const getBusinesses = async token => {
  const route = `/me/business_users`;
  const params = {
    access_token: token,
    fields: '["id", "name", "business"]',
    limit: 500,
  };
  const response = await instance.get(route, { params });
  return response.data.data;
};

export const fetchLocations = async (value, token) => {
  const route = 'search';
  const params = {
    access_token: token,
    type: 'adgeolocation',
    qs: '["' + value + '"]',
    locale: 'en_US',
  };
  const response = await instance.get(route, { params });
  return response.data.data;
};

export const fetchLocales = async (token, value) => {
  const route = 'search';
  const params = {
    access_token: token,
    type: 'adlocale',
    locale: 'en_US',
    q: value,
  };
  const response = await instance.get(route, { params });
  const data = response.data.data;
  data.sort((a, b) => (a.name < b.name ? -1 : 1));
  return data;
};

export const getLocationsMeta = async (token, data) => {
  const route = 'search';
  const params = {
    access_token: token,
    type: 'adgeolocationmeta',
    locale: 'en_US',
    ...data,
  };
  const response = await instance.get(route, { params });
  return response.data.data;
};

export const fetchTargets = async token => {
  const route = `search`;
  const params = {
    access_token: token,
    type: 'adTargetingCategory',
    locale: 'en_US',
  };
  const response = await instance.get(route, { params });
  return response.data;
};

export const fetchTargets_ = async (token, id, value) => {
  const route = `${id}/targetingbrowse`;
  const params = {
    access_token: token,
    fields: '["id","name","type","path","key","info"]',
    locale: 'en_US',
  };
  if (value) params.q = value;
  const response = await instance.get(route, { params });
  return response.data;
};

export const findTargets = async (value, token, id) => {
  const route = `${id}/targetingsearch`;
  const params = {
    access_token: token,
    fields: '["name", "id", "type", "path"]',
    q: value,
    locale: 'en_US',
  };
  const response = await instance.get(route, { params });
  return response.data;
};

export const importAllFacebookPages = async (token, userID) => {
  const route = `${userID}/accounts`;
  const params = {
    access_token: token,
    fields: '["name", "id", "access_token"]',
    limit: 500,
  };
  const response = await instance.get(route, { params });
  return response.data.data;
};

export const importAllFacebookPixels = async (token, id) => {
  const route = `${id}/adspixels`;
  const params = {
    access_token: token,
    fields: '["name", "id"]',
    limit: 500,
  };
  const response = await instance.get(route, { params });
  return response.data.data;
};

export const importAllConversions = async (token, id) => {
  const route = `${id}/customconversions`;
  const params = {
    access_token: token,
    fields: '["id", "pixel", "name", "description", "custom_event_type", "is_archived"]',
    limit: 100,
  };
  const response = await instance.get(route, { params });
  return response.data.data;
};

export const importAllInstagramPages = async (pages, token) => {
  const route = `/`;
  const reqData = pages.map(item => {
    return {
      "method": "GET",
      "relative_url":`${item.id}/instagram_accounts?fields=id,username&access_token=${item.access_token}`,
    }
  });

  const params = {
    batch: reqData,
    include_headers: false,
    access_token: token
  }

  const response = await instance.post(route, params);
  return response.data.map(i => JSON.parse(i.body).data[0]).filter(i => i !== undefined);
};

export const importCustomAudiences = async (token, id) => {
  const route = `${id}/saved_audiences`; // /customaudiences
  const params = {
    access_token: token,
    fields: 'id, name, local_type, approximate_count_upper_bound',
    limit: 100,
  };
  const response = await instance.get(route, { params });
  return response.data.data;
};

export const createSavedAudience = async (token, id, data) => {
  const config = {
    params: {
      access_token: token,
    },
    data,
  };

  const response = await instance.post(`${id}/saved_audiences`, null, config);
  return response.data;
};

export const editSavedAudience = async (token, data) => {
  const config = {
    params: {
      access_token: token,
    },
    data: data.external_data,
  };
  const response = await instance.post(`${data.external_id}`, null, config);
  return response.data;
};

export const getTargetingSentenceLine = async (token, id, data) => {
  const route = `${id}/targetingsentencelines`;
  const params = {
    access_token: token,
    locale: 'en_US',
    targeting_spec: data,
    discard_placements: true,
  };
  const response = await instance.get(route, { params });
  return response.data;
};

export const getReachEstimate = async (token, id, data) => {
  const route = `${id}/reachestimate`;
  const params = {
    access_token: token,
    locale: 'en_US',
    targeting_spec: data,
  };
  const response = await instance.get(route, { params });
  return response.data;
};

export const getManageFacebookUrl = (fbIntegrationParams) => `https://www.facebook.com/ads/manager/account_settings/account_pages/?act=${fbIntegrationParams.AD_ACCOUNT_ID}`;
export const getManageAudiencesUrl = (fbIntegrationParams) => `https://www.facebook.com/adsmanager/audiences?act=${fbIntegrationParams.AD_ACCOUNT_ID}&tool=AUDIENCES`;
export const getManageInstagramUrl = (fbIntegrationParams) => `https://www.facebook.com/ads/manager/account_settings/account_pages/?act=${fbIntegrationParams.AD_ACCOUNT_ID}`;
export const getManagePixelsUrl = (fbIntegrationParams) => ` https://www.facebook.com/events_manager2/list?act=${fbIntegrationParams.AD_ACCOUNT_ID}`;
export const getManageEventsUrl = (fbIntegrationParams) => ` https://www.facebook.com/events_manager2/list?act=${fbIntegrationParams.AD_ACCOUNT_ID}`;
