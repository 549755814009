import React from 'react';

function SearchIcon({ onClick }) {
  return (
    <svg width="124" height="110" viewBox="0 0 124 110" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_5434_28342)">
        <circle cx="72" cy="57" r="32" fill="white" onClick={() => onClick()}/>
        <path d="M84.8095 67.7816L78.6619 61.646C78.5095 61.5446 78.3571 61.4432 78.2047 61.4432H77.5442C79.1192 59.6177 80.1353 57.1838 80.1353 54.5471C80.1353 48.7665 75.3596 44 69.5677 44C63.725 44 59 48.7665 59 54.5471C59 60.3784 63.725 65.0941 69.5677 65.0941C72.2096 65.0941 74.5975 64.1307 76.4773 62.5588V63.2179C76.4773 63.3701 76.5281 63.5222 76.6297 63.6743L82.7772 69.8098C83.0313 70.0634 83.4377 70.0634 83.6409 69.8098L84.8095 68.6436C85.0635 68.4408 85.0635 68.0351 84.8095 67.7816ZM69.5677 62.6602C65.0459 62.6602 61.4387 59.06 61.4387 54.5471C61.4387 50.0848 65.0459 46.4339 69.5677 46.4339C74.0386 46.4339 77.6966 50.0848 77.6966 54.5471C77.6966 59.06 74.0386 62.6602 69.5677 62.6602Z" fill="#4E3B8B"/>
      </g>
      <defs>
        <filter id="filter0_d_5434_28342" x="0" y="0" width="144" height="144" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="15"/>
          <feGaussianBlur stdDeviation="20"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0745098 0 0 0 0 0.0392157 0 0 0 0 0.180392 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5434_28342"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5434_28342" result="shape"/>
        </filter>
      </defs>
    </svg>
  );
}

export default SearchIcon;
