/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styles from './Header.module.scss';
import { ReactComponent as LogoSVG } from '../../assets/img/icons/otterfish_smal_logo.svg';
import Menu from '../Menu';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../actions/user.actions';
import cs from 'classnames';
import { NavLink } from 'react-router-dom';
import Avatar from '../Avatar';
import KnowledgeDropMenu from './KnowledgeDropMenu';
import { createCustomerPortal } from '../../actions/stripe.actions';
import { ReactComponent as TriangleIcon } from '../../assets/img/icons/play-white-arrow.svg';

const Header = ({ extended, title, renderContent, hideMenu, isFlow = false, backButtonAction }) => {
  const user = useSelector(state => state.user.data);
  const stripe = useSelector(state => state.auth.stripe);
  const dispatch = useDispatch();
  const [orgMenuOpen, setOrgMenuOpen] = useState(false);
  const subscriptionStatus = stripe?.subscription_status;
  const showRenewButton = subscriptionStatus === 'canceled';
  const showManageBillingButton =
    subscriptionStatus === 'past_due' || subscriptionStatus === 'incomplete' || subscriptionStatus === 'incomplete_expired';
  const showMobileSideBar = useSelector(state => state.sidebarMenu.showMobileSideBar);

  useEffect(() => {
    if (user.is_admin) dispatch(getUsers());
  }, [user]);

  const onToggle = () => {
    dispatch({ type: 'TOGGLE_MOBILE_SIDEBAR', payload: !showMobileSideBar });
  };

  const handleCloseMenu = () => {
    dispatch({ type: 'TOGGLE_MOBILE_SIDEBAR', payload: false });
  };

  const redirectToStripe = async () => {
    dispatch(createCustomerPortal(user.id, '/my-plan')).then(response => {
      window.location.replace(response.data[0].url);
    });
  };

  return (
    <header className={cs(styles.header, { [styles.horizontal]: extended })}>
      {extended ? (
        <div className={styles.horizontal}>
          <div className={styles.headerContent}>
            <div className={styles.pageName}>
              {backButtonAction && (
                <div className={styles.backButton} onClick={backButtonAction}>
                  <TriangleIcon />
                </div>
              )}
              {title && <span className={cs(styles.title, { [styles.flowTitle]: isFlow })}>{title}</span>}
              {renderContent && renderContent()}
            </div>
            <div className={styles.userContainer}>
              {showRenewButton && (
                <span className={styles.link} onClick={() => redirectToStripe()}>
                  Renew plan
                </span>
              )}
              {showManageBillingButton && (
                <span className={styles.link} onClick={() => redirectToStripe()}>
                  Manage Billing
                </span>
              )}
              <KnowledgeDropMenu />
              <Avatar user={user} withArrow />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <NavLink to={'/'}>
            <div className={styles.logo}>
              <LogoSVG />
            </div>
          </NavLink>
          {!hideMenu && (
            <div className={styles.rightSide}>
              <div className={cs(styles.userContainer, { [styles.active]: orgMenuOpen })} onClick={() => setOrgMenuOpen(true)}>
                <Avatar user={user} withArrow />
              </div>
              <div className={styles.headerMenus}>
                <div className={styles.menuBtn} onClick={onToggle}>
                  <div className={styles.bigLine} />
                  <div className={styles.smallLine} />
                </div>
              </div>
            </div>
          )}
          <Menu isOpen={showMobileSideBar} handleCloseMenu={handleCloseMenu} isMobile />
        </div>
      )}
    </header>
  );
};

export default Header;
