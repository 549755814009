import React from 'react';
import styles from './HeaderColumn.module.scss';
import cs from 'classnames';

const HeaderColumn = ({ children, columnName, type = 'column', className }) => {
  return (
    <div className={cs(styles.root, className, { [styles.group]: type === 'group' })}>
      {columnName && <span>{columnName}</span>}
      {children}
    </div>
  );
};

export default HeaderColumn;
