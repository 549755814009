import React from 'react';
import styles from './CirclesLoader.module.scss';

const CirclesLoader = ({ size, color }) => {

  return (
    <div className={styles.root} style={{ width: size || '100px', height: size || '100px' }}>
      <div className={styles.container}>
        <div className={styles.circle} style={{backgroundColor: color || '#4e3b8b'}}/>
        <div className={styles.circle} style={{backgroundColor: color || '#4e3b8b'}}/>
        <div className={styles.circle} style={{backgroundColor: color || '#4e3b8b'}}/>
        <div className={styles.circle} style={{backgroundColor: color || '#4e3b8b'}}/>
        <div className={styles.circle} style={{backgroundColor: color || '#4e3b8b'}}/>
        <div className={styles.circle} style={{backgroundColor: color || '#4e3b8b'}}/>
        <div className={styles.circle} style={{backgroundColor: color || '#4e3b8b'}}/>
        <div className={styles.circle} style={{backgroundColor: color || '#4e3b8b'}}/>
      </div>
    </div>
  )
}

export default CirclesLoader;
