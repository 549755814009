import React, { useMemo } from 'react';
import WelcomeEmptyPage from '../components/WelcomeEmptyPage';
import demoBackground from '../../../assets/img/content-approval-demo-background.png';
import PlusSVG from '../../../expert/components/IconsSvg/PlusSVG';
import { ReactComponent as PlayIcon } from '../../../assets/img/icons/play_icon.svg';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ContentApprovalWelcomePage = () => {
  const history = useHistory();
  const userId = useSelector(state => state.auth.user.id);

  const pageContent = useMemo(
    () => ({
      pageName: 'Content approval',
      title: 'Create a content board to achieve best visuals for your campaign',
      subTitle:
        'Say goodbye to shared drives. Otterfish allows you to collaborate, manage, approve and store your content deliverables, all in one place.',
      listItems: [
        'Access and Manage multiple deliverables from your content dashboard.',
        'Approve your content in a click of a button.',
        'No more emails. Invite and collaborate on your deliverables in one place.',
      ],
      sectionImageUrl: demoBackground,
      button1: {
        text: 'New Content Board',
        icon: PlusSVG,
        action: () => {
          history.push('/content-approval/create');
        },
      },
      button2: {
        text: 'Learn More',
        icon: null,
        action: () => {
          window.analytics.track('Click on Learn More', {
            category: 'KB Articles',
            label: 'Content approval',
            userId: userId,
          });
          window.open('https://help.otterfish.com/knowledge/creating-a-content-board', '_blank');
        },
      },
      button3: {
        text: 'Watch demo',
        icon: PlayIcon,
        action: () => {
          console.log('watch demo');
        },
      },
      hasModal: true,
    }),
    []
  );

  return <WelcomeEmptyPage pageContent={pageContent} />;
};

export default ContentApprovalWelcomePage;
