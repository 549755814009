import React from 'react';
import PropTypes from 'prop-types';
import styles from './ErrorModal.module.scss';
import ErrorSVG from '../../IconsSvg/ErrorSVG';
import ButtonCustom from '../../Buttons/ButtonCustom';
import { ModalSmall as Modal } from '..';

export const ErrorModalContent = ({ onSubmit, onClose, text, title, submitText }) => {
  return (
    <>
      <ErrorSVG />
      <p className={styles.title}>{title ?? 'Oops..'}</p>
      <p className={styles.text}>{Array.isArray(text) ? text.map(item => <span>{item}</span>) : text}</p>
      <div className={styles.controls}>
        <ButtonCustom onClick={onSubmit || onClose}>{submitText ?? 'Okay'}</ButtonCustom>
      </div>
    </>
  );
};

const ErrorModal = props => {
  return (
    <Modal {...props}>
      <ErrorModalContent {...props} />
    </Modal>
  );
};

ErrorModal.propTypes = {
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
  submitText: PropTypes.string,
  ...Modal.propTypes,
};

export default ErrorModal;
