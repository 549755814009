import React from 'react';
import styles from './Concept.module.scss';
import SectionWrapper from '../SectionWrapper';
import { useSelector } from 'react-redux';
import LoadingContainer from '../../../../../components/LoadingContainer';
import VisualConcepts from './VisualConcepts';
import SectionStatusesContainer from '../SectionStatusesContainer';
import { DELIVERABLE_STATUSES } from '../../hooks/useStatusesState';
import EditorComponent from '../../../../../components/EditorComponent';
import useDeliverableStatuses from '../Header/statuses/useDeliverableStatuses';
import { useMediaQuery } from 'react-responsive/src';

const Concept = ({
  concept,
  onChangeConcept,
  visualConceptsFlow,
  openAsset,
  conceptStatus,
  onApprove,
  onSave,
  deliverableConceptStatus,
  isConceptFilled,
}) => {
  const { deliverableLoading } = useSelector(state => state.contentApproval);
  const isDisabledSection = deliverableConceptStatus.concept === DELIVERABLE_STATUSES.APPROVED;
  const { conceptStatusTile } = useDeliverableStatuses(deliverableConceptStatus);
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <SectionWrapper>
      <div className={styles.root}>
        <div className={styles.titleRow}>
          <div className={styles.title}>Concept</div>
          <div className={styles.buttonsContainer}>
            {isTablet ? (
              deliverableLoading ? (
                <LoadingContainer width={'100px'} height="30px" borderRadius="5px" animationDelay={'-0.9s'} />
              ) : (
                <div className={styles.rightPart}>
                  {conceptStatusTile}
                  <SectionStatusesContainer
                    status={conceptStatus}
                    concept={concept}
                    isChanged={visualConceptsFlow.isSectionChanged}
                    isVisualExists={visualConceptsFlow.isVisualExists}
                    onSave={onSave}
                    onApprove={() => onApprove('concept')}
                    sectionName="Concept"
                  />
                </div>
              )
            ) : (
              <SectionStatusesContainer
                status={conceptStatus}
                concept={concept}
                isChanged={visualConceptsFlow.isSectionChanged}
                isVisualExists={visualConceptsFlow.isVisualExists}
                onSave={onSave}
                onApprove={() => onApprove('concept')}
                sectionName="Concept"
              />
            )}
          </div>
        </div>
        {deliverableLoading && <LoadingContainer height="100px" borderRadius="5px" animationDelay={'-0.9s'} />}

        {!deliverableLoading && isConceptFilled && (
          <>
            <EditorComponent
              placeholder={`This is where you share your concept/idea about your content with your collaborators and get them approved. You can also add visuals below to bring your ideas to life.`}
              initialValue={concept}
              onChange={onChangeConcept}
              disabled={isDisabledSection}
              editorClassName={styles.editorClassName}
            />
            <VisualConcepts visualConceptsFlow={visualConceptsFlow} openAsset={openAsset} isDisabledSection={isDisabledSection} />
          </>
        )}
      </div>
    </SectionWrapper>
  );
};

export default Concept;
