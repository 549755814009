import React from 'react';
import styles from './DeleteAssetButton.module.scss';
import { CloseSvg } from '../../../../../expert/components/IconsSvg/CloseSvg';
import cs from 'classnames';

const DeleteAssetButton = ({ onDelete, className }) => {
  return (
    <div className={cs(styles.root, className)} onClick={onDelete}>
      <CloseSvg />
    </div>
  );
};

export default DeleteAssetButton;
