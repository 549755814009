import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { clearBriefFailure } from '../../../../actions/brief.actions';
import ErrorModal from '../../../../expert/components/Modal/ErrorModal';

function BriefFailureModal({ formik }) {
  const dispatch = useDispatch();
  const failure = useSelector(state => state.brief.failure);

  const clearFailure = () => {
    formik && formik.setSubmitting(false);
    dispatch(clearBriefFailure());
  };

  return (
    <>
      <ErrorModal
        open={!!failure.errors}
        onClose={() => dispatch(clearFailure)}
        text={failure?.errors?.body.map(item => item.message).join(', ')}
      />
    </>
  );
}

export default BriefFailureModal;
