import { STRIPE_TYPES } from '../actions/stripe.actions';

const initialState = {
  data: {},
  loading: false,
  failure: {
    message: '',
    name: '',
    code: '',
  },
  customerPortal: {
    data: {},
    loading: false,
    failure: {
      message: '',
      name: '',
      code: '',
    },
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case STRIPE_TYPES.CREATE_CHECKOUT_SESSION.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case STRIPE_TYPES.CREATE_CHECKOUT_SESSION.SUCCESS: {
      return {
        ...state,
        data: payload,
        loading: false,
      };
    }
    case STRIPE_TYPES.CREATE_CHECKOUT_SESSION.FAILURE: {
      return {
        ...state,
        loading: false,
        failure: payload,
      };
    }
    case STRIPE_TYPES.CREATE_CUSTOMER_PORTAL.REQUEST: {
      return {
        ...state,
        customerPortal: {
          loading: true,
        },
      };
    }
    case STRIPE_TYPES.CREATE_CUSTOMER_PORTAL.SUCCESS: {
      return {
        ...state,
        customerPortal: {
          data: payload,
          loading: false,
        },
      };
    }
    case STRIPE_TYPES.CREATE_CUSTOMER_PORTAL.FAILURE: {
      return {
        ...state,
        customerPortal: {
          loading: false,
          failure: payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};
