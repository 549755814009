/* eslint-disable camelcase */
import * as stripeServices from '../services/stripe';

export const STRIPE_TYPES = {
  CREATE_CHECKOUT_SESSION: {
    REQUEST: 'CREATE_CHECKOUT_SESSION_REQUEST',
    SUCCESS: 'CREATE_CHECKOUT_SESSION_SUCCESS',
    FAILURE: 'CREATE_CHECKOUT_SESSION_FAILURE',
  },
  CREATE_CUSTOMER_PORTAL: {
    REQUEST: 'CREATE_CUSTOMER_PORTAL_REQUEST',
    SUCCESS: 'CREATE_CUSTOMER_PORTAL_SUCCESS',
    FAILURE: 'CREATE_CUSTOMER_PORTAL_FAILURE',
  },
};

export const createCheckoutSession = (userId, pricing_plan_id, success_route, cancel_route) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STRIPE_TYPES.CREATE_CHECKOUT_SESSION.REQUEST,
      payload: {},
    });

    const response = await stripeServices.createCheckoutSession(userId, pricing_plan_id, success_route, cancel_route);

    dispatch({
      type: STRIPE_TYPES.CREATE_CHECKOUT_SESSION.SUCCESS,
      payload: response.data[0],
    });
    return response;
  } catch (error) {
    dispatch({
      type: STRIPE_TYPES.CREATE_CHECKOUT_SESSION.FAILURE,
      payload: error.response.data,
    });
  }
};

export const createCustomerPortal = (userId, return_route) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STRIPE_TYPES.CREATE_CUSTOMER_PORTAL.REQUEST,
      payload: {},
    });

    const response = await stripeServices.createCustomerPortal(userId, return_route);

    dispatch({
      type: STRIPE_TYPES.CREATE_CUSTOMER_PORTAL.SUCCESS,
      payload: response.data[0],
    });
    return response;
  } catch (error) {
    dispatch({
      type: STRIPE_TYPES.CREATE_CUSTOMER_PORTAL.FAILURE,
      payload: error.response.data,
    });
  }
};
