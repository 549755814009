import React, { useRef } from 'react';
import cs from 'classnames';
import styles from './Dropdown.module.scss';
import { useClickOutside } from '../../../../shared/hooks/useClickOutside';

const Dropdown = ({ options, isActive, containerRef, onClose, containerClassName }) => {
  const tabletDropdownRef = useRef();
  useClickOutside(containerRef, tabletDropdownRef, onClose);

  return (
    <div className={cs(styles.container, { [styles.active]: isActive }, containerClassName)} ref={tabletDropdownRef}>
      <div className={styles.content}>
        {options?.map((opt, idx) => (
          <div key={opt.text + idx} className={styles.menuItem} onClick={opt.onClick}>
            <div className={cs(styles.itemIcon, { [styles.stroke]: opt.stroke })}>{opt.icon}</div>
            <div className={styles.itemText}>{opt.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
