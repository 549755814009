import React from 'react';
import {ReactComponent as Placeholder1} from '../../../assets/img/icons/organization-placeholders/1.svg';
import {ReactComponent as Placeholder2} from '../../../assets/img/icons/organization-placeholders/2.svg';
import {ReactComponent as Placeholder3} from '../../../assets/img/icons/organization-placeholders/3.svg';
import {ReactComponent as Placeholder4} from '../../../assets/img/icons/organization-placeholders/4.svg';
import {ReactComponent as Placeholder5} from '../../../assets/img/icons/organization-placeholders/5.svg';
import {ReactComponent as Placeholder6} from '../../../assets/img/icons/organization-placeholders/6.svg';
import {ReactComponent as Placeholder7} from '../../../assets/img/icons/organization-placeholders/7.svg';
import {ReactComponent as Placeholder8} from '../../../assets/img/icons/organization-placeholders/8.svg';
import {ReactComponent as Placeholder9} from '../../../assets/img/icons/organization-placeholders/9.svg';
import {ReactComponent as Placeholder10} from '../../../assets/img/icons/organization-placeholders/10.svg';
import {ReactComponent as Placeholder11} from '../../../assets/img/icons/organization-placeholders/11.svg';
import {ReactComponent as Placeholder12} from '../../../assets/img/icons/organization-placeholders/12.svg';
import {ReactComponent as Placeholder13} from '../../../assets/img/icons/organization-placeholders/13.svg';
import {ReactComponent as Placeholder14} from '../../../assets/img/icons/organization-placeholders/14.svg';

export default [
  Placeholder1,
  Placeholder2,
  Placeholder3,
  Placeholder4,
  Placeholder5,
  Placeholder6,
  Placeholder7,
  Placeholder8,
  Placeholder9,
  Placeholder10,
  Placeholder11,
  Placeholder12,
  Placeholder13,
  Placeholder14,
];
