import React from 'react';
import styles from './LoadingContainer.module.scss';
import cs from 'classnames';

const LoadingContainer = ({ children, width, height, borderRadius, backgroundColor, margin, animationDelay, className, maxWidth }) => {
  return (
    <div
      className={cs(styles.loading, className)}
      style={{
        width: width || '100%',
        maxWidth: maxWidth || 'auto',
        height: height || '100%',
        borderRadius: borderRadius || '100px',
        backgroundColor: backgroundColor || '#e3e6eb',
        margin: margin || '0px',
        animationDelay: animationDelay || '0s',
      }}
    >
      {children}
    </div>
  );
};

export default LoadingContainer;
