import React, { useEffect, useState } from 'react';
import styles from './FiltersMobile.module.scss';
import ButtonCustom from '../../../../expert/components/Buttons/ButtonCustom';
import SearchBar from '../../../SearchBar';
import SelectDropdown from '../../../../expert/components/FormControls/SelectDropdown';
import SelectInputArrowIcon from '../SelectInputArrowIcon';
import cs from 'classnames';
import CalendarRange from '../../../CalendarRange';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

const FiltersMobile = ({ textsConfig = {}, filterFunctions = {}, statusOptions, filter, switcher, onClose, showFilters }) => {
  const organizationUsers = useSelector(state => state.user.organizationUsers);
  const [searchValue, setSearchValue] = useState(filter.searchValue);
  const [boardStatus, setBoardStatus] = useState(filter.statusSelectIndex);
  const [teamMembers, setTeamMembers] = useState(filter.memberSelectIndex);
  const [creationDate, setCreationDate] = useState('');

  useEffect(() => {
    setSearchValue('');
  }, [switcher]);

  const applyFilters = () => {
    filterFunctions.addAllFilters(searchValue, boardStatus, teamMembers, creationDate.startDate, creationDate.endDate);
    onClose();
  };

  const clearFilters = () => {
    setSearchValue('');
    setBoardStatus(-1);
    setTeamMembers(-1);
    setCreationDate('');
    filterFunctions.onClearAll();
    onClose();
  };

  useEffect(() => {
    setCreationDate({ startDate: filter.dateStart, endDate: filter.dateEnd });
  }, [filter.dateStart, filter.dateEnd]);

  return (
    <>
      <div className={cs(styles.background, { [styles.hide]: !showFilters })} onClick={onClose}></div>
      <div className={cs(styles.root, { [styles.hideModal]: !showFilters })}>
        <div className={styles.content}>
          <div className={styles.firstBlock} onClick={onClose}>
            <div className={styles.grayLine}></div>
          </div>
          <div className={styles.title}>Search for Content boards</div>
          <SearchBar
            searchValue={searchValue}
            onChangeSearchValue={val => setSearchValue(val)}
            onClear={() => {
              setSearchValue('');
            }}
            onSubmit={() => applyFilters()}
            placeholder={textsConfig.searchPlaceholder}
            className={styles.searchMobile}
            designVersion="V3.0"
          />
          <div className={styles.description}>Filter by:</div>
          <div className={styles.filters}>
            <SelectDropdown
              options={statusOptions}
              optionKey={opt => opt.key}
              renderOption={opt => opt.name}
              selectedId={boardStatus}
              onSelected={setBoardStatus}
              placeholderClassName={styles.dropdownPlaceholder}
              placeholder={boardStatus !== -1 ? statusOptions[boardStatus].name : textsConfig.statusPlaceholder}
              withNothing
              IconArrow={SelectInputArrowIcon}
              className={cs(styles.dropdown, {
                [styles.filledSelectInput]: filter.statusSelectIndex !== -1,
              })}
              inputClassName={styles.selectDropdownInput}
            />
            <SelectDropdown
              options={organizationUsers}
              optionKey={user => user.id}
              renderOption={user => `${user?.first_name} ${user?.last_name}`}
              selectedId={teamMembers}
              onSelected={setTeamMembers}
              placeholderClassName={styles.dropdownPlaceholder}
              placeholder={
                teamMembers !== -1
                  ? `${organizationUsers[teamMembers]?.first_name} ${organizationUsers[teamMembers]?.last_name}`
                  : textsConfig.memberPlaceholder
              }
              withNothing
              IconArrow={SelectInputArrowIcon}
              className={cs(styles.dropdown, {
                [styles.filledSelectInput]: filter.memberSelectIndex !== -1,
              })}
              inputClassName={styles.selectDropdownInput}
            />
            <CalendarRange
              className={cs(styles.dropdownStatesStyles, styles.forMobile, {
                [styles.filledSelectInput]: filter.dateStart && filter.dateEnd,
              })}
              inputClassName={styles.selectDropdownInput}
              IconArrow={SelectInputArrowIcon}
              onSubmit={setCreationDate}
              onCancel={() => setCreationDate('')}
              buttonText={
                creationDate.startDate && creationDate.endDate
                  ? `${dayjs(creationDate.startDate).format('DD MMM YYYY')} / ${dayjs(creationDate.endDate).format('DD MMM YYYY')}`
                  : null
              }
              forMobile
            />
          </div>
        </div>
        <div className={styles.btnBlock}>
          <ButtonCustom onClick={clearFilters} outline>
            Clear filters
          </ButtonCustom>
          <ButtonCustom onClick={applyFilters}>Search</ButtonCustom>
        </div>
      </div>
    </>
  );
};

export default FiltersMobile;
