import React, { useState } from 'react';
import styles from './BrandDetail.module.scss';
import cs from 'classnames';
import { ReactComponent as ArrowSVG } from '../../../../../assets/img/icons/play-white-arrow.svg';
import FormattedText from '../../../../CampaignBrief/CampaignBriefPage/components/FormattedText';
import { useSelector } from 'react-redux';
import brandPreviewImage from '../../../../../assets/img/brandPlaceholder.svg';

const BrandDetail = () => {
  const { openedBoard } = useSelector(state => state.contentApproval);
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.title}>Brand details and key messaging</div>
        <div className={cs(styles.collapseBtn, { [styles.open]: !collapsed })} onClick={() => setCollapsed(!collapsed)}>
          <ArrowSVG />
        </div>
      </div>

      {!collapsed && (
        <>
          <div className={styles.brand}>
            <div className={styles.brandImageContainer}>
              <img
                src={openedBoard?.brand_logo_thumbnail_location || openedBoard?.brand_logo_location || brandPreviewImage}
                alt="Brand logo"
              />
            </div>
            <div className={styles.textContainer}>
              <span>Brand Name</span>
              <span>{openedBoard?.brand_name}</span>
            </div>
          </div>
          {openedBoard?.brand_description && (
            <div className={styles.textSection}>
              <span>Brand background</span>
              <FormattedText buttonClassName={styles.lessMoreBtn} text={openedBoard?.brand_description || ''} />
            </div>
          )}
          {openedBoard?.key_message && (
            <div className={styles.textSection}>
              <span>Key messaging</span>
              <FormattedText buttonClassName={styles.lessMoreBtn} text={openedBoard?.key_message || ''} />
            </div>
          )}
          {openedBoard?.notes && (
            <div className={styles.textSection}>
              <span>Brand guidelines and Mandatories</span>
              <FormattedText buttonClassName={styles.lessMoreBtn} text={openedBoard?.notes || ''} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BrandDetail;
