/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './DropzoneControl.module.scss';
import './DropzoneControl.scss';
import { useDropzone } from 'react-dropzone';
import ButtonCustom from '../../Buttons/ButtonCustom';
import cs from 'classnames';
import { useSelector } from 'react-redux';
import { onloadPromise } from '../../../../shared/utils/helpers';
import { ReactComponent as UploadBigSVG } from '../../../../assets/img/icons/upload-big-svg-2.svg';
import FileToUpload from '../../../../components/FileToUpload';
import LinkText from './LinkText';
import { CloseIcon } from '../../IconsSvg/ReactIcons/ReactIcons';
import MobileDetect from 'mobile-detect';

const IMAGE_ERRORS = {
  RATIO: 'Ratio is not 1:1, please crop your photo.',
  SIZE_OVER: {
    MB25: 'Size over 25MB',
    GB1: 'Size over 1Gb',
  },
};

const DropzoneControl = ({
  onlyOne,
  minHeight,
  handleAddFile,
  isShowLists,
  fileTypes,
  maxImageSize,
  maxVideoSize,
  className,
  files,
  text,
  textStyle,
  loaded,
  handleEditImage,
  type = 'images',
}) => {
  const loading = useSelector(state => state.visualAssets.uploadLoading);
  const [errors, setErrors] = useState([]);
  const [notSupportedModalVisibility, setNotSupportedModalVisibility] = useState('none');
  const errorFiles = files.filter(file => file.file.error);
  const plan = useSelector(state => state.user.pricingPlan);
  const browseButtonText = type === 'images' ? 'Photos' : type.substring(0, 1).toLocaleUpperCase() + type.substring(1, type.length);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isDesktop = useMemo(() => new MobileDetect(navigator.userAgent).mobile() === null, [windowWidth]);

  const defaultAllowedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4', 'video/quicktime'];
  const allowedFileTypes = fileTypes ? fileTypes : defaultAllowedFileTypes;

  const videoOptions = allowedFileTypes.filter(type => /^video\/.*/.test(type));
  if (plan?.ad_types?.video) {
    videoOptions.push('image/gif');
  }

  function closeWarningModal(e) {
    e.stopPropagation();
    setNotSupportedModalVisibility('none');
  }

  const allValidationOptions = {
    images: {
      text: (
        <>
          <LinkText type="photos" classname={styles.clickHere} />
        </>
      ),
      types: allowedFileTypes.filter(type => /^image\/.*/.test(type)),
    },
    logos: {
      text: (
        <>
          <span>Maximum file size 25MB.</span>
          <span>We recommend to upload logos in .png format for best results.</span>
          <LinkText type="logos" classname={styles.clickHere} />
        </>
      ),
      types: allowedFileTypes.filter(type => /^image\/.*/.test(type)),
    },
    videos: {
      text: (
        <>
          <span>Maximum file size 1GB.</span>
          <span>
            File type: MP4, MOV or GIF
            <br />
            Video settings: H.264 compression, square pixels, fixed frame rate, progressive scan and stereo AAC audio compression at 128
            kbps+
            <br />
          </span>
        </>
      ),
      types: videoOptions,
    },
    avatar: {
      text: 'Maximum file size 25MB',
      types: allowedFileTypes.filter(type => /^image\/.*/.test(type)),
    },
  };

  useEffect(() => {
    setErrors([]);
  }, [type]);

  useEffect(() => {
    if (!errorFiles.length) {
      setErrors([]);
    }
  }, [errorFiles.length]);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const validationOptions = allValidationOptions[type];
  const isType = Object.keys(allValidationOptions).reduce((obj, key) => {
    obj[key] = key === type;
    return obj;
  }, {});

  // use 1000 as order of multiplication instead of 1024
  const defaultImageMaxSize = 25000000; // 25MB
  const defaultVideoMaxSize = 1000000000; // 1GB - 1000MB
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: !onlyOne,
    accept: allowedFileTypes.join(', '),
    getFilesFromEvent: event => customFileGetter(event),
  });

  const validation = event => {
    return new Promise(async resolve => {
      const error = [];
      const res = [];
      const data = [...(event.dataTransfer ? event.dataTransfer.files : event.target.files)];
      let isAllValidType = true;
      let isAllValidSize = true;
      const isAllValidDimension = true;
      const isAllValidRatio = true;
      for (let i = 0; i < data.length; i++) {
        await (async () => {
          const f = data[i].file || data[i];
          const fileType = f.type;
          const isValidType = validationOptions.types.includes(fileType);

          const isValidImageSize = f.size <= (maxImageSize || defaultImageMaxSize);
          const isValidVideoSize = f.size <= (maxVideoSize || defaultVideoMaxSize);

          if (!isValidType) {
            isAllValidType = false;
            setNotSupportedModalVisibility('flex');
          }

          if (!isType.videos && validationOptions.types.includes(fileType)) {
            const img = new Image();
            img.src = f.url || URL.createObjectURL(f);
            await onloadPromise(img);
            // const isValidRatio = img.width === img.height;
            //
            // if (!isValidRatio && isType.images) {
            //   isAllValidRatio = false;
            //   f.error = IMAGE_ERRORS.RATIO
            // }
          }
          if (!isValidImageSize && !isType.videos) {
            isAllValidSize = false;
            f.error = IMAGE_ERRORS.SIZE_OVER.MB25;
          }
          if (!isValidVideoSize && isType.videos) {
            isAllValidSize = false;
            f.error = IMAGE_ERRORS.SIZE_OVER.GB1;
          }

          if (isValidType) {
            res.push(f);
          }
        })();
      }

      const sizeErrorMessage =
        (!isType.videos && ((maxImageSize || defaultImageMaxSize) / (1000 * 1000)).toFixed(2)) ||
        (isType.videos && ((maxVideoSize || defaultVideoMaxSize) / (1000 * 1000)).toFixed(2));

      if (!isAllValidType) error.push(`Invalid file type. Valid types: ${validationOptions.types.join(', ')}.`);
      // if (!isAllValidDimension) error.push(`Please, select an image with 1080px width and 1080px height`);
      // if (!isAllValidRatio) error.push(`Ratio width and height must be 1:1`);
      if (!isAllValidSize) error.push(`Maximum upload file size: ${sizeErrorMessage} MB`);

      resolve({ validFiles: res, err: error });
    });
  };

  const customFileGetter = async event => {
    const { validFiles, err } = await validation(event);
    setErrors(err);
    handleAddFile([...files, ...validFiles.map(file => ({ file, url: URL.createObjectURL(file) }))]);
    return validFiles;
  };

  const removeFile = useCallback(
    (e, file) => {
      const newFiles = [...files];
      newFiles.splice(newFiles.indexOf(file), 1);
      handleAddFile(newFiles);
    },
    [files]
  );

  const getDropzoneArea = () => {
    return (
      <DropzoneArea
        type={type}
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        text={text || validationOptions.text}
        browseButtonText={browseButtonText}
        isDesktop={isDesktop}
        textStyle={textStyle}
        closeWarningModal={closeWarningModal}
        notSupportedModalVisibility={notSupportedModalVisibility}
      />
    );
  };

  return (
    <div className={cs(styles.root, className)}>
      <div className={styles.body}>
        {isDesktop && (
          <section className={cs('dropzoneControl', { hover: isDragActive })} style={{ minHeight: minHeight || '450px' }}>
            {getDropzoneArea()}
          </section>
        )}
        {!isDesktop && getDropzoneArea()}
      </div>

      {!!files?.length && (
        <div className={styles.selected}>
          <div className={styles.listContainer}>
            {isShowLists &&
              files.map((item, index) => (
                <FileToUpload
                  key={index}
                  isInvalidRatio={item.file?.error === IMAGE_ERRORS.RATIO}
                  uploaded={loaded}
                  file={item}
                  loading={loading}
                  onRemove={removeFile}
                  onEdit={handleEditImage}
                  isVideo={/^video\/.*/.test(item.file.type)}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

const DropzoneArea = ({
  type,
  isDesktop,
  getRootProps,
  getInputProps,
  browseButtonText,
  textStyle,
  text,
  closeWarningModal,
  notSupportedModalVisibility,
}) => {
  const uploadBtnProps = isDesktop ? {} : getRootProps();
  const containerProps = isDesktop ? getRootProps({ className: 'dropzone' }) : { className: styles.mobileBodyContainer };

  const getTextData = () => {
    if (type === 'videos') return { incorrect: 'image', under: 'videos', select: '“Images” or “Logos”' };
    return { incorrect: 'video', under: 'images', select: '“Videos”' };
  };
  const textData = getTextData();
  const warningText = `You can not upload ${textData.incorrect} files under ${textData.under} folder. Please select ${textData.select} tab to upload your files`;

  return (
    <div {...containerProps}>
      <div className={isDesktop ? 'svg' : styles.svg}>
        <UploadBigSVG />
      </div>

      {isDesktop && <input {...getInputProps()} />}

      <div className={styles.browseBtn}>
        {!isDesktop && <input {...getInputProps()} />}

        <ButtonCustom {...uploadBtnProps} className={styles.btn} type="button">
          {type === 'avatar' ? 'Browse files' : `Upload ${browseButtonText}`}
        </ButtonCustom>
      </div>

      {type !== 'avatar' && (
        <div className={styles.textContainer}>
          <span className={cs(styles.text, textStyle)}>{text}</span>
        </div>
      )}

      <div className={styles.warningModalBox} style={{ display: notSupportedModalVisibility }}>
        <span className={styles.warningStick}></span>
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" className={styles.warningIcon}>
          <circle cx="17" cy="17" r="17" fill="#EA3E4A" />
          <rect x="15" y="6" width="4" height="15" rx="2" fill="white" />
          <rect x="15" y="23" width="4" height="4" rx="2" fill="white" />
        </svg>
        <div className={styles.warningContentBlock}>
          <h3 className={styles.warningTitle}>Warning</h3>
          <p className={styles.warningText}>{warningText}</p>
        </div>
        <CloseIcon size={30} onClick={closeWarningModal} />
      </div>
    </div>
  );
};

export default DropzoneControl;
