import React from 'react';
import styles from './TextField.module.scss';
import cs from 'classnames';

export default React.forwardRef(({ wrapperClassName, className, isValid = true, type = 'text', error, icon, ...other }, ref) => {
  return (
    <span className={cs(styles.inputContainer , wrapperClassName)}>
      <input ref={ref} type={type} className={cs(styles.TextField, { [styles.error]: !isValid || error }, className)} {...other} />
      {icon}
      {error && <span className={styles.error}>{error}</span>}
    </span>
  );
});
