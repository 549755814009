import React, { useEffect } from 'react';
import styles from './ContentBoard.module.scss';
import ContentHeader from './components/ContentHeader';
import DeliverablesList from './components/DeliverablesList';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearContentApprovalErrors,
  clearContentBoard,
  getBoardCollaborators,
  getContentBoard,
  getInvitedBoardCollaborators,
} from '../../../actions/contentApproval.actions';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import useContentBoardSockets from './useContentBoardSockets';
import { useMediaQuery } from 'react-responsive/src';
import BrandDetail from './components/BrandDetail';

const ContentBoard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const { boardError } = useSelector(state => state.contentApproval);
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    if (!currentOrg) {
      return;
    }
    dispatch(getBoardCollaborators(currentOrg.organization_id, params.boardId));
    dispatch(getInvitedBoardCollaborators(currentOrg.organization_id, params.boardId));
    dispatch(getContentBoard(currentOrg.organization_id, params.boardId));
    return () => {
      dispatch(clearContentBoard());
    };
  }, [currentOrg]);

  useEffect(() => {
    if (boardError) {
      dispatch(clearContentApprovalErrors());
      history.push('/content-approval');
    }
  }, [boardError]);

  useContentBoardSockets();

  return (
    <>
      <div className={styles.rootWrapper}>
        <div className={styles.root}>
          <div className={styles.content}>
            <ContentHeader />
            <BrandDetail />
            {isTablet && <span className={styles.titleTablet}>Deliverables</span>}
            <DeliverablesList />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentBoard;
