import React, { useEffect, useState } from 'react';
import styles from './ImageTypeSwitcher.module.scss';
import cs from 'classnames';

const ImageTypeSwitcher = ({
  switcher,
  onChange,
  tabNames = [],
  initialTabsArray = [
    { value: 'images', name: 'Photos' },
    { value: 'logos', name: 'Logos' },
    { value: 'videos', name: 'Videos' },
  ],
  className,
}) => {
  const [tabsArray, setTabsArray] = useState([]);

  useEffect(() => {
    if (tabNames?.length && tabNames.length > 0) {
      initialTabsArray = initialTabsArray.filter(tab => tabNames.some(name => tab.value === name));
    }

    const positionStylesArray = [styles.left, styles.center, styles.right];

    for (let i = 0; i < initialTabsArray.length; i++) {
      let styleIndex = i;
      if (i === 1 && initialTabsArray?.length === 2) {
        styleIndex = 2;
      }
      initialTabsArray[i].style = positionStylesArray[styleIndex];
    }

    setTabsArray(initialTabsArray);
  }, []);

  const getTabsStyle = () => tabsArray.filter(tab => tab.value === switcher)[0]?.style;

  return (
    <div className={styles.switchContainer}>
      <div className={cs(styles.switcher, className)}>
        <div className={cs(styles.selected, getTabsStyle())} />
        {tabsArray.map(tab => (
          <div key={tab.value} className={cs({ [styles.checked]: switcher === tab.value })} onClick={() => onChange(tab.value)}>
            {tab.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageTypeSwitcher;
