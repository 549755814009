import React, { useState } from 'react';
import styles from './DefaultAttachment.module.scss';
import { downloadFile } from '../../../../../../shared/utils/helpers';
import { BsFileEarmarkText } from 'react-icons/bs';
import { FiDownload } from 'react-icons/fi';
import Loader from '../../../../Loader';

const DefaultAttachment = ({ attachment }) => {
  const { extension } = attachment;
  const [loadingDownload, setLoadingDownload] = useState(false);

  return (
    <div className={styles.root}>
      <div className={styles.column}>
        <div className={styles.icon}>
          <BsFileEarmarkText />
        </div>
        <div className={styles.name}>{attachment.filename ? attachment.filename : `file-${attachment.id}.${extension}`}</div>
      </div>
      {attachment.id && (
        <div
          className={styles.downloadButton}
          onClick={() => {
            if (loadingDownload) return;
            setLoadingDownload(true);
            downloadFile(attachment.location, attachment.filename ? attachment.filename : `file-${attachment.id}.${extension}`).finally(
              () => setLoadingDownload(false)
            );
          }}
        >
          {loadingDownload ? <Loader className={styles.loader} /> : <FiDownload />}
        </div>
      )}
    </div>
  );
};

export default DefaultAttachment;
