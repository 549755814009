import { AUDIENCE_CREATION } from '../actions/createAudience.actions';

const initialState = {
  interests_and_behaviors: [],
  geo_locations: [],
  createdAudiences: [],
  sources: [],
  failure: null,
  loading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case AUDIENCE_CREATION.GET_LOCATIONS.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case AUDIENCE_CREATION.GET_LOCATIONS.SUCCESS: {
      return {
        ...state,
        geo_locations: payload,
        loading: false,
      };
    }
    case AUDIENCE_CREATION.GET_LOCATIONS.FAILURE: {
      return {
        ...state,
        failure: payload,
        loading: false,
      };
    }
    case AUDIENCE_CREATION.GET_LOCATIONS.CLEAR: {
      return {
        ...state,
        geo_locations: [],
        loading: false,
      };
    }

    case AUDIENCE_CREATION.GET_TARGETING_CATEGORY.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case AUDIENCE_CREATION.GET_TARGETING_CATEGORY.SUCCESS: {
      return {
        ...state,
        interests_and_behaviors: payload,
        loading: false,
      };
    }
    case AUDIENCE_CREATION.GET_TARGETING_CATEGORY.CLEAR: {
      return {
        ...state,
        interests_and_behaviors: [],
        loading: false,
      };
    }
    case AUDIENCE_CREATION.GET_TARGETING_CATEGORY.FAILURE: {
      return {
        ...state,
        failure: payload,
        loading: false,
      };
    }

    case AUDIENCE_CREATION.GET_SOURCES.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case AUDIENCE_CREATION.GET_SOURCES.SUCCESS: {
      return {
        ...state,
        sources: payload,
        loading: false,
      };
    }
    case AUDIENCE_CREATION.GET_SOURCES.FAILURE: {
      return {
        ...state,
        failure: payload,
        loading: false,
      };
    }

    case AUDIENCE_CREATION.CREATE_AUDIENCE.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case AUDIENCE_CREATION.CREATE_AUDIENCE.SUCCESS: {
      return {
        ...state,
        createdAudiences: payload,
        loading: false,
      };
    }

    case AUDIENCE_CREATION.CREATE_AUDIENCE.FAILURE: {
      return {
        ...state,
        failure: payload,
        loading: false,
      };
    }

    case AUDIENCE_CREATION.CLEAR_FAILURE: {
      return {
        ...state,
        failure: null,
      };
    }

    case AUDIENCE_CREATION.CLEAR_DATA: {
      return {
        ...state,
        geo_locations: [],
        interests_and_behaviors: []
      }
    }

    default: {
      return state;
    }
  }
};
