import React from 'react';
import PropTypes from 'prop-types';
import styles from './Modal.module.scss';
import { CloseIcon } from '../IconsSvg/ReactIcons/ReactIcons';
import { Modal as ReactModal } from 'react-responsive-modal';
import { useMediaQuery } from 'react-responsive';
import cs from 'classnames';

export const ModalSmall = ({ onClose, open, children, maxWidth, closable = true, ...props }) => {
  const modalStyles = {
    overlay: {
      backdropFilter: 'blur(3px)',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    modal: {
      background: '#fff',
      padding: 0,
      borderRadius: '4px',
      maxWidth: maxWidth || '420px',
      width: '100%',
      boxShadow: ' 0 0 20px rgba(0, 0, 0, 0.1)',
    },
    closeButton: {
      display: 'none',
    },
  };

  return (
    <ReactModal center open={open} onClose={onClose} closeOnOverlayClick={closable} styles={modalStyles} showCloseIcon={closable} {...props}>
      <div className={styles.rootSmall}>
        {
          closable
            ? <button onClick={onClose} className={styles.closeSmall}>
              <CloseIcon size={25} />
            </button>
            : null
        }
        {children}
      </div>
    </ReactModal>
  );
};

ModalSmall.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export const ModalMedium = ({ open, onClose, title, children, maxWidth, className = null }) => {
  const modalStyles = {
    overlay: {
      backdropFilter: 'blur(3px)',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    modal: {
      background: '#fff',
      padding: 0,
      borderRadius: '5px',
      maxWidth: maxWidth || '1120px',
      width: '100%',
      boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
    },
    closeButton: {
      display: 'none',
    },
  };
  return (
    <ReactModal center open={open} onClose={onClose} styles={modalStyles}>
      <div className={cs(styles.rootMedium, className)}>
        <div className={styles.head}>
          <p className={styles.title}>{title}</p>
          <button onClick={onClose} className={styles.closeMedium}>
            <CloseIcon size={30} />
          </button>
        </div>
        {children}
      </div>
    </ReactModal>
  );
};

ModalMedium.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

export const ModalBig = ({ open, onClose, children }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768.98px)' });
  const modalStyles = {
    overlay: {
      backdropFilter: 'blur(3px)',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      padding: isMobile ? '0' : '0 0 0 7%',
    },
    modal: {
      background: '#fff',
      padding: '0',
      borderRadius: isMobile ? '12px 12px 0 0' : '12px 0 0 12px',
      maxWidth: '100%',
      width: '100%',
      height: '100%',
      boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
    },
    closeButton: {
      display: 'none',
    },
  };

  return (
    <ReactModal
      open={open}
      onClose={onClose}
      styles={modalStyles}
      classNames={{
        overlay: styles.overlayBig,
        modal: styles.modalBig,
        animationIn: styles.customEnterAnimation,
        animationOut: styles.customLeaveAnimation,
      }}
      animationDuration={300}
    >
      <div className={styles.rootBig}>
        <button onClick={onClose} className={styles.closeBig}>
          <CloseIcon size={25} />
        </button>

        {children}
      </div>
    </ReactModal>
  );
};

ModalBig.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export const ModalEmpty = ({ open, onClose, children }) => {
  const modalStyles = {
    overlay: {
      backdropFilter: 'blur(3px)',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      paddingRight: '25px',
      paddingLeft: '25px',
    },
    modal: {
      padding: 0,
      background: 'transparent',
      maxWidth: '1120px',
      width: '100%',
      height: '564px',
      boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
    },
    closeButton: {
      display: 'none',
    },
  };
  return (
    <ReactModal
      center
      open={open}
      onClose={onClose}
      styles={modalStyles}
      classNames={{
        modal: styles.modalEmpty,
      }}
    >
      <div className={styles.rootEmpty}>
        <button onClick={onClose} className={styles.closeEmpty}>
          <CloseIcon size={25} />
        </button>
        {children}
      </div>
    </ReactModal>
  );
};

ModalEmpty.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
