import React from 'react';

const VisualElementSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <g clipPath="url(#clip0_403_11302)">
        <path
          d="M0 20.1561V1.88577C0.0778809 1.52913 0.177783 1.18215 0.404443 0.885132C0.848633 0.302369 1.4459 0.0413338 2.16938 0.0418709H7.12207C11.3316 0.0418709 15.5409 0.0418709 19.75 0.0418709C20.2565 0.0418709 20.727 0.149293 21.1363 0.462428C21.7191 0.909303 21.9635 1.51301 21.9629 2.23489C21.9629 7.201 21.9629 12.1669 21.9629 17.1327C21.9629 18.0404 21.9629 18.9476 21.9629 19.8548C21.9629 20.5702 21.6944 21.1594 21.1191 21.5977C20.8227 21.8233 20.4746 21.9232 20.119 22.0011H1.84443C1.82002 21.9877 1.79404 21.9774 1.76709 21.9704C1.2555 21.9008 0.790002 21.6378 0.466211 21.2357C0.201416 20.9198 0.0843262 20.546 0 20.1561Z"
          fill="#46CFB0"
        />
        <path
          d="M12.3404 13.5067C12.8684 12.976 13.3566 12.4862 13.847 11.9937C14.2299 11.6107 14.5989 11.608 14.9814 11.9872C16.0738 13.0775 17.166 14.1686 18.2577 15.2604C18.4973 15.4988 18.6144 15.7669 18.4763 16.0907C18.3383 16.4146 18.0649 16.5129 17.7271 16.5129C13.2304 16.5097 8.73387 16.5088 4.23755 16.5102C3.8562 16.5102 3.6043 16.3668 3.49473 16.0805C3.37119 15.7583 3.48076 15.4999 3.71333 15.2679C5.26665 13.7178 6.8189 12.1666 8.37007 10.6144C8.73907 10.2459 9.11558 10.2491 9.48887 10.6213C10.3841 11.5165 11.2792 12.4117 12.1744 13.3069C12.2281 13.3601 12.2732 13.4251 12.3404 13.5067Z"
          fill="#DAF5EF"
        />
        <path
          d="M15.0984 7.58244C15.107 8.70285 14.1698 9.64441 13.044 9.64763C12.4984 9.64984 11.9743 9.43522 11.5869 9.051C11.1996 8.66677 10.9807 8.14441 10.9785 7.59882C10.9763 7.05324 11.1909 6.52912 11.5752 6.14178C11.9594 5.75443 12.4818 5.53558 13.0273 5.53337C14.1451 5.51887 15.0898 6.45129 15.0984 7.58244Z"
          fill="#DAF5EF"
        />
      </g>
      <defs>
        <clipPath id="clip0_403_11302">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VisualElementSVG;
