import React from 'react';
import styles from './AssetsCell.module.scss';
import FacebookSVG from '../../IconsSvg/FacebookSVG';
import InstagramSVG from '../../IconsSvg/InstagramSVG';
import { DoneIcon } from '../../../expert/components/IconsSvg/ReactIcons/ReactIcons';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import cs from 'classnames';

const AssetsCell = ({ className, asset, withSelect, onSelect, selectedList, onRemove }) => {

  const handleSelect = () => {
    onSelect(asset.id, asset.type);
  }

  const handleRemove = () => {
    onRemove(asset.id, asset.type);
  }

  const forAdd = withSelect && !selectedList.includes(asset.id);
  const forSelected = withSelect && selectedList.includes(asset.id);

  const RightButtons = () =>
    <>
      {(!withSelect || forSelected) && <button className={styles.button} onClick={handleRemove}>Remove</button>}
      {forAdd && <ButtonCustom className={styles.addButton} onClick={handleSelect}>Add</ButtonCustom>}
    </>


  return (
    <div className={cs(styles.root, className, withSelect && {[styles.selected]:selectedList.includes(asset.id)})}>
      <div className={styles.title}>
        {asset.type === 'page' && <FacebookSVG />}
        {asset.type === 'instagram_account' && <InstagramSVG />}
        <span className={styles.name}>{asset.name}</span>
      </div>
      {forSelected && <div className={styles.tick}><DoneIcon /></div>}
      {RightButtons()}
    </div>
  )
}

export default AssetsCell;
