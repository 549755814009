import React from 'react';
import styles from './LeftPartSignIn.module.scss';
import LogoSVG from '../../IconsSvg/LogoSVG';
import image from '../../../assets/img/icons/singin_email_image.png';

const LeftPartSignIn = () => {

  return (
    <div className={styles.leftPart}>
      <div className={styles.header}>
        <LogoSVG/>
      </div>
      <img src={image} alt='signIn' />
      <div className={styles.footer}>
        <div className={styles.title}>Create, collaborate and approve.</div>
      </div>
    </div>
  );
};

export default  LeftPartSignIn;
