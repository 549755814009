import React from 'react';
import styles from './RadioButtons.module.scss';
import cs from 'classnames';

function RadioButtons({ items, setItem, activeItems, className }) {
  return (
    <div className={styles.radioButtonsWrapper}>
      {items.map(item => (
        <div
          key={item.key}
          className={cs(styles.radioButton, className, { [styles.active]: activeItems.includes(item.key) })}
          onClick={() => setItem(item)}
        >
          <h3>{item.title}</h3>
          {item.description && <p>{item.description}</p>}
        </div>
      ))}
    </div>
  );
}

export default RadioButtons;
