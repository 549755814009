import React from 'react';

function TrashSVG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" fill="none" viewBox="0 0 14 17">
      <path
        fill="#9FA2B4"
        d="M9.382 6.16a.4.4 0 00-.404.397v7.525c0 .22.18.398.404.398a.401.401 0 00.403-.398V6.557a.4.4 0 00-.403-.398zM4.618 6.16a.4.4 0 00-.403.397v7.525c0 .22.18.398.403.398a.401.401 0 00.404-.398V6.557a.4.4 0 00-.404-.398z"
      ></path>
      <path
        fill="#9FA2B4"
        d="M1.147 5.061v9.809c0 .58.215 1.124.592 1.515A2 2 0 003.18 17h7.638a2 2 0 001.442-.615 2.18 2.18 0 00.593-1.515V5.061a1.522 1.522 0 001.133-1.667 1.535 1.535 0 00-1.53-1.324h-2.066v-.497A1.553 1.553 0 009.925.457C9.625.16 9.217-.003 8.792 0H5.208a1.598 1.598 0 00-1.133.457c-.3.295-.468.697-.466 1.116v.497H1.542c-.774 0-1.429.567-1.529 1.324-.1.758.385 1.471 1.134 1.667zm9.672 11.143H3.18c-.69 0-1.227-.585-1.227-1.334V5.096h10.092v9.774c0 .749-.537 1.334-1.227 1.334zM4.416 1.573a.761.761 0 01.23-.554.783.783 0 01.562-.223h3.584a.783.783 0 01.562.223.76.76 0 01.23.554v.497H4.416v-.497zM1.542 2.867h10.916c.401 0 .726.32.726.716a.722.722 0 01-.726.717H1.542a.722.722 0 01-.726-.717c0-.396.325-.716.726-.716z"
      ></path>
      <path fill="#9FA2B4" d="M7 6.16a.4.4 0 00-.404.397v7.525c0 .22.18.398.404.398a.401.401 0 00.404-.398V6.557A.4.4 0 007 6.16z"></path>
    </svg>
  );
}

export default TrashSVG;
