import React, { useMemo } from 'react';
import styles from './EmptyMessagesState.module.scss';
import emptyChatMessagesImg from '../../../../assets/img/empty-chat-messages.png';
import { useSelector } from 'react-redux';

const EmptyMessagesState = ({ collaborators }) => {
  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const organizations = useMemo(
    () =>
      collaborators
        .filter(collaborator => +collaborator.organization_id !== +currentOrganizationID)
        .map(collaborator => collaborator.organization_name)
        .join(', '),
    [collaborators]
  );

  return (
    <div className={styles.root}>
      <img src={emptyChatMessagesImg} alt="Empty messages image" />
      {!organizations.length ? (
        <p>Use this discussion room to collaborate and leave notes for your team members</p>
      ) : (
        <p>
          This is the very beginning of your discussion with <span>{organizations}</span>
        </p>
      )}
    </div>
  );
};

export default EmptyMessagesState;
