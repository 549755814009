import React from 'react';
import Discussion from '../../../expert/components/Discussion';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const ContentApprovalDiscussionWrapper = () => {
  const params = useParams();
  const { collaborators } = useSelector(state => state.contentApproval);
  return <Discussion collaborators={collaborators} objectItemID={params.boardId} itemType={'content_board'} />;
};

export default ContentApprovalDiscussionWrapper;
