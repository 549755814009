import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSubscribeChannel from '../../../centrifuge/useSubscribeChannel';
import { updateBoardDeliverableItem, updateOpenedContentBoard } from '../../../actions/contentApproval.actions';

const SUBSCRIBE_ACTIONS = {
  CONTENT_BOARD_DELIVERABLE_REFRESH: 'content_board_deliverable_refresh',
  CONTENT_BOARD_REFRESH: 'content_board_refresh',
};

const useContentBoardSockets = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const JWT = useSelector(state => state.auth?.token?.access_token);
  const boardID = useMemo(() => +params.boardId, [params.boardId]);

  useSubscribeChannel(boardID, {
    channel: `content_board:${boardID}`,
    events: ctx => {
      switch (ctx.data.action) {
        case SUBSCRIBE_ACTIONS.CONTENT_BOARD_REFRESH: {
          dispatch(updateOpenedContentBoard(ctx.data.data));
          break;
        }
        case SUBSCRIBE_ACTIONS.CONTENT_BOARD_DELIVERABLE_REFRESH: {
          dispatch(updateBoardDeliverableItem(ctx.data.data));
          break;
        }
      }
    },
    opts: { data: { organization_id: currentOrganizationID, token: JWT, content_board_id: boardID } },
    // onError: err => console.error('Centrifuge subscribe error: ', err),
    // onSubscribe: ctx => console.log('+ subscribe board', ctx.channel),
    // onUnsubscribe: ctx => console.log('- unsubscribe board', ctx.channel),
  });
};

export default useContentBoardSockets;
