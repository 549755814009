import * as invoicesServices from '../services/invoices';

export const INVOICE_TYPES = {
  CREATE_INVOICE: {
    REQUEST: 'CREATE_INVOICE_REQUEST',
    SUCCESS: 'CREATE_INVOICE_SUCCESS',
    FAILURE: 'CREATE_INVOICE_FAILURE',
  },
  GET_INVOICES: {
    REQUEST: 'GET_INVOICES_REQUEST',
    SUCCESS: 'GET_INVOICES_SUCCESS',
    FAILURE: 'GET_INVOICES_FAILURE',
  },
  GET_INVOICE: {
    REQUEST: 'GET_INVOICE_REQUEST',
    SUCCESS: 'GET_INVOICE_SUCCESS',
    FAILURE: 'GET_INVOICE_FAILURE',
  },
  EDIT_INVOICE: {
    REQUEST: 'EDIT_INVOICE_REQUEST',
    SUCCESS: 'EDIT_INVOICE_SUCCESS',
    FAILURE: 'EDIT_INVOICE_FAILURE',
  },
  DELETE_INVOICE: {
    REQUEST: 'DELETE_INVOICE_REQUEST',
    SUCCESS: 'DELETE_INVOICE_SUCCESS',
    FAILURE: 'DELETE_INVOICE_FAILURE',
  },
};

export const createInvoice = invoice => async (dispatch, getState) => {
  try {
    dispatch({
      type: INVOICE_TYPES.CREATE_INVOICE.REQUEST,
    });

    const data = await invoicesServices.createInvoice(invoice);

    dispatch({
      type: INVOICE_TYPES.CREATE_INVOICE.SUCCESS,
      payload: data.data[0],
    });
    return data.data[0];
  } catch (error) {
    dispatch({
      type: INVOICE_TYPES.CREATE_INVOICE.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getInvoice = invoiceId => async (dispatch, getState) => {
  try {
    dispatch({
      type: INVOICE_TYPES.GET_INVOICE.REQUEST,
    });

    const data = await invoicesServices.getInvoice(invoiceId);

    dispatch({
      type: INVOICE_TYPES.GET_INVOICE.SUCCESS,
      payload: data.data,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: INVOICE_TYPES.GET_INVOICE.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getInvoices = organizationId => async (dispatch, getState) => {
  try {
    dispatch({
      type: INVOICE_TYPES.GET_INVOICES.REQUEST,
    });
    const currentOrg = getState().auth.currentOrganization; // JSON.parse(localStorage.getItem('OTTERFISH_CURRENT_ORGANIZATION'))
    if (currentOrg || organizationId) {
      const data = (await invoicesServices.getInvoices(organizationId || currentOrg.organization_id)).data;
      dispatch({
        type: INVOICE_TYPES.GET_INVOICES.SUCCESS,
        payload: data,
      });
      return data;
    }
  } catch (error) {
    dispatch({
      type: INVOICE_TYPES.GET_INVOICES.FAILURE,
      payload: error.response.data,
    });
  }
};

export const editInvoice = (invoiceId, invoice) => async (dispatch, getState) => {
  try {
    dispatch({
      type: INVOICE_TYPES.EDIT_INVOICE.REQUEST,
    });

    const data = await invoicesServices.editInvoice(invoiceId, invoice);

    dispatch({
      type: INVOICE_TYPES.EDIT_INVOICE.SUCCESS,
      payload: data.data[0],
    });
    return data.data[0];
  } catch (error) {
    dispatch({
      type: INVOICE_TYPES.EDIT_INVOICE.FAILURE,
      payload: error.response.data,
    });
  }
};

export const deleteInvoice = invoiceId => async (dispatch, getState) => {
  try {
    dispatch({
      type: INVOICE_TYPES.DELETE_INVOICE.REQUEST,
    });

    await invoicesServices.deleteInvoice(invoiceId);

    dispatch({
      type: INVOICE_TYPES.DELETE_INVOICE.SUCCESS,
      payload: invoiceId,
    });
    return invoiceId;
  } catch (error) {
    dispatch({
      type: INVOICE_TYPES.DELETE_INVOICE.FAILURE,
      payload: error.response.data,
    });
  }
};
