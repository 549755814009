/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from 'react';
import styles from './TeamMemberDetails.module.scss';
import cs from 'classnames';
import MainLayout from '../../components/MainLayout';
import Loader from '../../expert/components/Loader';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { patchUser, clearFailure } from '../../actions/user.actions';
import { getOrganizationMembers, getInvitedMembers, getOrganizations } from '../../actions/organizations.actions';
import TextField from '../../components/FormControls/TextField';
import EditSVG from '../../expert/components/IconsSvg/EditSVG';
import Button from '../../expert/components/Buttons/ButtonCustom';
import * as Yup from 'yup';
import { PHONE_MASK } from '../../shared/constants/masks';
import { toTitleCase } from '../../shared/utils/helpers';
import DatePicker from './DatePicker';
import ErrorModal from '../../expert/components/Modal/ErrorModal';
import dayjs from 'dayjs';
import Dropdown from '../../components/FormControls/Dropdown';
import LoaderModal from '../../expert/components/Modal/LoaderModal';
import avatar from '../../assets/img/no-avatar.svg';

const STATUS_NAMES = {
  active: 'Active',
  pending: 'Pending',
  inactive: 'Inactive',
};

const GENDERS = ['male', 'female'];

const VALIDATION_SCHEMAS = {
  first_name: Yup.string()
    .required()
    .test('nonEmpty', 'Length must be at least 2 characters', val => val.length >= 2),
  last_name: Yup.string()
    .required()
    .test('nonEmpty', 'Length must be at least 2 characters', val => val.length >= 2),
  email: Yup.string().email().required(),
  phone_number: Yup.string().matches(PHONE_MASK).required(),
  gender: Yup.string().required(),
};

export default props => {
  const dispatch = useDispatch();
  const orgData = useSelector(state => state.organizations);
  const params = useParams();
  const updateLoading = useSelector(state => state.user.updateLoading);
  const orgLoading = useSelector(state => state.organizations.organizationMembers.loading);
  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const failure = useSelector(state => state.user.failure);

  const [editingField, setEditingField] = useState();
  const [editingValue, setEditingValue] = useState();
  const [error, setError] = useState();

  const { memberId } = params;

  useEffect(() => {
    if (!member) {
      if (orgData.data.length > 0) {
        dispatch(getOrganizationMembers(orgData.data[0].id));
        dispatch(getInvitedMembers(orgData.data[0].id));
      } else {
        dispatch(getOrganizations());
      }
    }
  }, [orgData.data]);

  const mergedMembers = useMemo(() => [...orgData.organizationMembers.data, ...orgData.invitedMembers.data], [
    orgData.invitedMembers.data,
    orgData.organizationMembers.data,
  ]);

  const member = useMemo(() => {
    return mergedMembers.find(m => m.id === +memberId);
  }, [mergedMembers, memberId]);

  const handleEditPopUpOpen = (fieldName, initValue) => {
    setEditingField(fieldName);
    setEditingValue(initValue);
  };

  const handleEditPopupClose = async confirmed => {
    if (confirmed) {
      const schema = VALIDATION_SCHEMAS[editingField];
      try {
        schema && (await schema.validate(editingValue));

        switch (editingField) {
          case 'last_name':
          case 'first_name':
          case 'email':
          case 'phone_number':
          case 'birth_date':
          case 'gender':
            dispatch(patchUser(memberId, { [editingField]: editingValue })).then(() => {
              dispatch(getOrganizationMembers(orgData.data[0].id));
              dispatch(getInvitedMembers(orgData.data[0].id));
            });
            break;
          default:
            break;
        }
      } catch (err) {
        setError(err.errors[0]);
      }
    }

    setEditingField(undefined);
    setEditingValue(undefined);
  };

  return (
    <MainLayout title="My Team">
      <div className={styles.root}>
        {!member ? (
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        ) : (
          <div className={styles.memberCard}>
            <div className={styles.headerBar}>
              <div className={styles.avatar}>
                <img alt="avatar" src={member.photo_location || avatar} />
              </div>
            </div>
            <div className={styles.details}>
              <div className={styles.nameContainer}>
                <div className={styles.name}>
                  {member.first_name || '-'} {member.last_name || '-'}
                </div>
                <div
                  className={cs(styles.status, {
                    [styles.pending]: member.status_in_organization === 'pending',
                    [styles.inactive]: member.status_in_organization === 'inactive',
                  })}
                >
                  {member?.role_in_organization === 'owner' ? 'OWNER' : STATUS_NAMES[member?.status_in_organization]}
                </div>
              </div>
              <div className={styles.info}>
                <div className={styles.group}>
                  <InfoItem
                    open={editingField === 'first_name'}
                    onEditPopupOpen={() => handleEditPopUpOpen('first_name', member?.first_name)}
                    name="First Name"
                    value={member?.first_name ?? '-'}
                    onEditPopupClose={handleEditPopupClose}
                    editable={currentOrg?.role === 'owner'}
                  >
                    <TextField value={editingValue || ''} onChange={e => setEditingValue(e.target.value)} />
                  </InfoItem>
                  <InfoItem
                    open={editingField === 'last_name'}
                    onEditPopupOpen={() => handleEditPopUpOpen('last_name', member?.last_name)}
                    name="Last Name"
                    value={member?.last_name ?? '-'}
                    onEditPopupClose={handleEditPopupClose}
                    editable={currentOrg?.role === 'owner'}
                  >
                    <TextField value={editingValue || ''} onChange={e => setEditingValue(e.target.value)} />
                  </InfoItem>
                </div>
                <div className={styles.group}>
                  <InfoItem
                    open={editingField === 'email'}
                    onEditPopupOpen={() => handleEditPopUpOpen('email', member?.email)}
                    name="Email"
                    value={member?.email}
                    onEditPopupClose={handleEditPopupClose}
                    editable={currentOrg?.role === 'owner'}
                  >
                    <TextField value={editingValue || ''} onChange={e => setEditingValue(e.target.value)} />
                  </InfoItem>
                  <InfoItem
                    open={editingField === 'phone_number'}
                    onEditPopupOpen={() => handleEditPopUpOpen('phone_number', member?.phone_number)}
                    name="Phone number"
                    value={member?.phone_number}
                    onEditPopupClose={handleEditPopupClose}
                    editable={currentOrg?.role === 'owner'}
                  >
                    <TextField value={editingValue || ''} onChange={e => setEditingValue(e.target.value)} />
                  </InfoItem>
                </div>
                <div className={styles.group}>
                  <InfoItem
                    open={editingField === 'birth_date'}
                    onEditPopupOpen={() => handleEditPopUpOpen('birth_date', member?.birth_date ?? '')}
                    name="Date of birth"
                    value={member?.birth_date ? dayjs(member?.birth_date).format('YYYY-MM-DD') : '-'}
                    onEditPopupClose={handleEditPopupClose}
                    editable={currentOrg?.role === 'owner'}
                  >
                    <DatePicker date={editingValue} setDate={d => setEditingValue(d)} />
                  </InfoItem>
                  <InfoItem
                    open={editingField === 'gender'}
                    onEditPopupOpen={() => handleEditPopUpOpen('gender', member?.gender ?? '')}
                    name="Gender"
                    value={member?.gender ? toTitleCase(member?.gender) : '-'}
                    onEditPopupClose={handleEditPopupClose}
                    editable={currentOrg?.role === 'owner'}
                  >
                    <Dropdown
                      items={GENDERS}
                      getId={i => i}
                      getDisplayValue={i => toTitleCase(i)}
                      placeholder="Gender"
                      renderItem={i => <div className={styles.genderOption}>{i}</div>}
                      selectedItem={editingValue || ''}
                      onSelect={i => setEditingValue(i)}
                    />
                  </InfoItem>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <LoaderModal open={!(failure.message || error) && (updateLoading || (orgLoading && member))} />
      <ErrorModal
        open={!!(error || failure.message)}
        onClose={() => {
          dispatch(clearFailure());
          setError(null);
        }}
        title="Validation Error"
        text={error || 'Forbidden'}
      />
    </MainLayout>
  );
};

const InfoItem = ({ name, value, onEditPopupClose, open, onEditPopupOpen, children, editable }) => {
  const handleEditPopupClose = confirmed => {
    onEditPopupClose(confirmed);
  };

  return (
    <div className={styles.item}>
      <div className={styles.header}>
        <div className={styles.name}>{name}</div>
        {editable ? (
          <div className={styles.edit} onClick={onEditPopupOpen}>
            <div className={styles.icon}>
              <EditSVG />
            </div>
            <div className={styles.label}>Edit</div>
          </div>
        ) : null}
      </div>
      <div className={styles.value}>{value}</div>
      {editable ? (
        <div className={cs(styles.popup, { [styles.hidden]: !open })}>
          <label>{name}</label>
          <div className={styles.inputContainer}>{children}</div>
          <div className={styles.buttonsContainer}>
            <Button outline onClick={() => handleEditPopupClose(false)}>
              Cancel
            </Button>
            <Button onClick={() => handleEditPopupClose(true)}>Save</Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};
