import React, { useRef, useState } from 'react';
import styles from './CaptionInput.module.scss';
import AutoResizeTextArea from '../Concept/AutoResizeTextArea';
import cs from 'classnames';
import TextWithTagsHighlight from '../../../../../components/TextWithTagsHighlight';

const CaptionInput = ({ caption = '', onChange, placeholder = 'Add caption...', disabled }) => {
  const [openInput, setOpenInput] = useState(false);
  const refTextArea = useRef(null);

  const openTextArea = () => {
    if (disabled) return;
    setOpenInput(true);
    setTimeout(() => {
      refTextArea.current.focus();
      refTextArea.current.setSelectionRange(refTextArea.current.value.length, refTextArea.current.value.length);
    }, 0);
  };

  const closeTextArea = () => {
    setOpenInput(false);
  };

  return (
    <div className={styles.caption}>
      <AutoResizeTextArea
        forwardedRef={refTextArea}
        onBlur={closeTextArea}
        className={cs(styles.textArea, { [styles.hidden]: !openInput })}
        placeHolder={placeholder}
        value={caption}
        onChange={onChange}
      />
      <TextWithTagsHighlight
        placeholder={placeholder}
        text={caption}
        className={cs(styles.mask, { [styles.hidden]: openInput })}
        onClick={openTextArea}
      />
    </div>
  );
};

export default CaptionInput;
