import { TEMPLATE_SETS_TYPES } from '../actions/templatesets.actions';

const initialState = {
  items: [],
  newItems: [],
  popularItems: [],
  favoriteItems: [],
  totalCount: 0,
  totalNewCount: 0,
  totalPopularCount: 0,
  totalFavoriteCount: 0,
  failure: null,
  loading: false,
  loadingNew: false,
  loadingPopular: false,
  loadingFavorite: false,
  loadingMarkFavorite: false,
  loadingDeleteFavorite: false,
  categories: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TEMPLATE_SETS_TYPES.GET_TEMPLATE_SETS.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case TEMPLATE_SETS_TYPES.GET_TEMPLATE_SETS.SUCCESS: {
      return {
        ...state,
        items: action.payload,
        loading: false,
        totalCount: action.totalCount,
      };
    }
    case TEMPLATE_SETS_TYPES.GET_TEMPLATE_SETS.FAILURE: {
      return {
        ...state,
        failure: action.payload,
        loading: false,
      };
    }
    case TEMPLATE_SETS_TYPES.GET_NEW_TEMPLATE_SETS.REQUEST: {
      return {
        ...state,
        loadingNew: true,
      };
    }
    case TEMPLATE_SETS_TYPES.GET_NEW_TEMPLATE_SETS.SUCCESS: {
      return {
        ...state,
        newItems: action.payload,
        loadingNew: false,
        totalNewCount: action.totalCount,
      };
    }
    case TEMPLATE_SETS_TYPES.GET_NEW_TEMPLATE_SETS.FAILURE: {
      return {
        ...state,
        failure: action.payload,
        loadingNew: false,
      };
    }
    case TEMPLATE_SETS_TYPES.GET_POPULAR_TEMPLATE_SETS.REQUEST: {
      return {
        ...state,
        loadingPopular: true,
      };
    }
    case TEMPLATE_SETS_TYPES.GET_POPULAR_TEMPLATE_SETS.SUCCESS: {
      return {
        ...state,
        popularItems: action.payload,
        loadingPopular: false,
        totalPopularCount: action.totalCount,
      };
    }
    case TEMPLATE_SETS_TYPES.GET_POPULAR_TEMPLATE_SETS.FAILURE: {
      return {
        ...state,
        failure: action.payload,
        loadingPopular: false,
      };
    }
    case TEMPLATE_SETS_TYPES.GET_FAVORITE_TEMPLATE_SETS.REQUEST: {
      return {
        ...state,
        loadingFavorite: true,
      };
    }
    case TEMPLATE_SETS_TYPES.GET_FAVORITE_TEMPLATE_SETS.SUCCESS: {
      return {
        ...state,
        favoriteItems: action.payload,
        loadingFavorite: false,
        totalFavoriteCount: action.totalCount,
      };
    }
    case TEMPLATE_SETS_TYPES.GET_FAVORITE_TEMPLATE_SETS.FAILURE: {
      return {
        ...state,
        failure: action.payload,
        loadingFavorite: false,
      };
    }
    case TEMPLATE_SETS_TYPES.MARK_AS_FAVORITE.REQUEST: {
      return {
        ...state,
        loadingMarkFavorite: true,
      };
    }
    case TEMPLATE_SETS_TYPES.MARK_AS_FAVORITE.SUCCESS: {
      return {
        ...state,
        loadingMarkFavorite: false,
      };
    }
    case TEMPLATE_SETS_TYPES.MARK_AS_FAVORITE.FAILURE: {
      return {
        ...state,
        failure: action.payload,
        loadingMarkFavorite: false,
      };
    }
    case TEMPLATE_SETS_TYPES.DELETE_AS_FAVORITE.REQUEST: {
      return {
        ...state,
        loadingDeleteFavorite: true,
      };
    }
    case TEMPLATE_SETS_TYPES.DELETE_AS_FAVORITE.SUCCESS: {
      return {
        ...state,
        loadingDeleteFavorite: false,
      };
    }
    case TEMPLATE_SETS_TYPES.DELETE_AS_FAVORITE.FAILURE: {
      return {
        ...state,
        failure: action.payload,
        loadingDeleteFavorite: false,
      };
    }
    case 'SET_TEMPLATE_SETS_LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case 'SET_TEMPLATE_SETS_FAILURE': {
      return {
        ...state,
        failure: action.payload,
      };
    }
    case TEMPLATE_SETS_TYPES.SET_TEMPLATE_SETS_CATEGORIES: {
      return {
        ...state,
        categories: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
