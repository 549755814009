import { instance } from '../shared/utils/api.config.creator';

const ASSETS_ROUTE = '/fb/assets';

export const importAssets = async (organization_id, external_ids) => {
  const response = await instance.post(`${ASSETS_ROUTE}/import`, { organization_id, external_ids });
  return response.data;
};

export const importAssetsAll = async organization_id => {
  const response = await instance.post(`${ASSETS_ROUTE}/import-all`, { organization_id });
  return response.data;
};

export const getAssets = async orgId => {
  const response = await instance.get(`${ASSETS_ROUTE}`, { params: { page_size: 100, organization_id: orgId } });
  return response.data;
};

export const getFacebookPages = async (id, params) => {
  const response = await instance.get(`${ASSETS_ROUTE}/${id || ''}`, { params });
  return response.data;
};

export const getInstagramAccounts = async (id, params) => {
  const response = await instance.get(`${ASSETS_ROUTE}/${id || ''}`, { params });
  return response.data;
};

export const getFacebookPixels = async (id, params) => {
  const response = await instance.get(`${ASSETS_ROUTE}/${id || ''}`, { params });
  return response.data;
}

export const deleteAsset = async id => {
  const response = await instance.delete(`${ASSETS_ROUTE}/${id}`);
  return response.data;
};
