export const TOAST_NOTIFICATION_TYPES = {
  CREATE_NOTIFICATIONS: 'CREATE_NOTIFICATIONS',
  DELETE_NOTIFICATIONS: 'DELETE_NOTIFICATIONS',
};

/**
 * @param {Object} data Information about the notification.
 * @param {string} data.text The text of the notification.
 * @param {string} data.title The title of the notification.
 * @param {'success' | 'error'} data.type The type of the notification.
 * @param {number} data.delay The delay for the hidden notification.
 * @param {{}} data.primaryButtonData The primary button in notification.
 * @param {{}} data.secondaryButtonData The secondary button in notification.
 * @param { string } data.dateString The created date in notification.
 *
 */

export const createNotification = data => async dispatch => {
  dispatch({ type: TOAST_NOTIFICATION_TYPES.CREATE_NOTIFICATIONS, payload: data });
  if (data.delay) {
    setTimeout(() => {
      dispatch(deleteNotification(data));
    }, data.delay);
  }
  return data;
};

export const deleteNotification = data => async dispatch => {
  dispatch({ type: TOAST_NOTIFICATION_TYPES.DELETE_NOTIFICATIONS, payload: data });
};
