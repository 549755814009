import React from 'react';
import styles from './CollaboratorTooltip.module.scss';
import cs from 'classnames';
import { TYPES_CAMPAIGNS_TEXTS } from '../../../../../shared/constants/constants';

const CollaboratorTooltip = ({ collaborator, iconText, className }) => {
  return (
    <div className={cs(styles.collaboratorTooltip, className)}>
      <div className={styles.tooltipIcon}>{iconText}</div>
      <div className={styles.text}>
        <div>{collaborator.organization_name}</div>
        <div>{TYPES_CAMPAIGNS_TEXTS[collaborator.organization_type] || 'N/A'}</div>
      </div>
    </div>
  );
};

export default CollaboratorTooltip;
