import { TOAST_NOTIFICATION_TYPES } from '../actions/toastNotification.actions';

const testNotifications = [
  {
    type: 'success',
    title: 'Add an action',
    text:
      'Add an action toast notification for using everywhere in the system Add an action toast notification' +
      ' for using everywhere in the system Add an action toast notification for using everywhere in the system',
    dateString: 'data',
    primaryButtonData: {
      text: 'primary Button ',
      action: callback => {
        callback();
      },
    },
    secondaryButtonData: {
      text: ' secondary Button ',
      action: callback => {
        callback();
      },
    },
  },
  {
    type: 'error',
    title: 'Error',
    text: 'Add an action toast notification for using everywhere in the system Add an action toast notification',
    primaryButtonData: {
      text: 'primary Button ',
      action: callback => {
        callback();
      },
    },
    secondaryButtonData: {
      text: ' secondary Button ',
      action: callback => {
        callback();
      },
    },
  },
];

const initialState = {
  notification: [
    // ...testNotifications
  ],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TOAST_NOTIFICATION_TYPES.CREATE_NOTIFICATIONS: {
      return {
        ...state,
        notification: [payload, ...state.notification],
      };
    }
    case TOAST_NOTIFICATION_TYPES.DELETE_NOTIFICATIONS: {
      return {
        ...state,
        notification: state.notification.filter(n => n != payload),
      };
    }
    default: {
      return state;
    }
  }
};
