import React, { useState } from 'react';
import styles from './InputFormik.module.scss';
import cs from 'classnames';
import InfoQuestionSVG from '../../IconsSvg/InfoQuestionSVG';
import TooltipControl from '../TooltipControl';
import { Field } from 'formik';
import { ReactComponent as EyeClosedIcon } from '../../../../assets/img/icons/eye_closed.svg';
import { ReactComponent as EyeIcon } from '../../../../assets/img/icons/eye.svg';

const InputFormik = ({ name, placeholder, type, errors, touched, tooltip, label, v2 = false }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div
      className={
      cs(
        styles.control,
        {[styles.error]: errors && touched},
        {[styles.errorsV2]: v2 && !errors}
      )
    }
    >
      <div className={styles.flex}>
        {label ? <label className={styles.label}>{label}</label> : null}
        {tooltip ? (
          <TooltipControl id={label} text={tooltip}>
            <InfoQuestionSVG />
          </TooltipControl>
        ) : null}
      </div>

      <div className={v2 ? styles.inputV2 : styles.inputContainer}>
        <Field name={name} placeholder={placeholder} type={showPassword ? 'text' : type} />
        <div className={cs(styles.image, {[styles.showText]: showPassword}, {[styles.imageV2]: v2})} onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? <EyeClosedIcon /> : <EyeIcon />}
        </div>
      </div>
      {errors && touched ? <div className={styles.errorMessage}>{errors}</div> : null}
    </div>
  );
};

export default InputFormik;
