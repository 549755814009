import React from 'react';
import styles from './DownloadAllButton.module.scss';
import DownloadSVG from '../../../../expert/components/IconsSvg/DownloadSVG';
import ButtonCustom from '../../../../expert/components/Buttons/ButtonCustom';
import useDownloadAsset from '../../../../shared/hooks/useDownloadAsset';
import SmallLoader from '../../../../components/SmallLoader';

const DownloadAllButton = ({ assets }) => {
  const { isAssetsArrayLoading, downloadAssetsArray } = useDownloadAsset();

  const downloadAssets = () => {
    if (isAssetsArrayLoading) return;
    downloadAssetsArray(
      assets.map(asset => ({
        assetUrl: asset.location,
        extension: asset.extension,
      }))
    );
  };

  return (
    <>
      {!assets.some(asset => !asset.extension) && (
        <ButtonCustom
          disabled={isAssetsArrayLoading}
          className={styles.root}
          onClick={downloadAssets}
          Icon={isAssetsArrayLoading ? DownloadLoader : DownloadSVG}
        >
          Download all
        </ButtonCustom>
      )}
    </>
  );
};

const DownloadLoader = props => <SmallLoader className={styles.loader} {...props} />;

export default DownloadAllButton;
