import React from 'react';

function EditSVG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" fill="none" viewBox="0 0 20 18">
      <path
        fill="#4E3B8B"
        d="M3.185 11.174L2.5 16l4.874-.607L17.5 5.213 13.308 1 3.185 11.174zm3.689 3.187l-3.107.389.435-3.072 6.774-6.8 2.668 2.679-6.77 6.804zm7.532-7.57L11.74 4.11l1.568-1.573 2.668 2.675-1.57 1.578z"
      ></path>
    </svg>
  );
}

export default EditSVG;
