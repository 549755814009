import React, { useRef } from 'react';
import styles from './PostCarousel.module.scss';
import cs from 'classnames';
import ArrowLeftSVG from '../../../../expert/components/IconsSvg/ArrowLeftSVG';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/navigation/navigation.scss';
import './PostCarouselSliderCustomStyles.scss';

import SwiperCore, { Pagination, Navigation } from 'swiper';
import SquareAsset from '../../../SquareAsset';
SwiperCore.use([Pagination, Navigation]);

const PostCarousel = ({ assets }) => {
  const sliderPrevButtonRef = useRef(null);
  const sliderNextButtonRef = useRef(null);

  return (
    <div className={styles.root}>
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        slidesPerView={1}
        navigation={{
          prevEl: sliderPrevButtonRef.current,
          nextEl: sliderNextButtonRef.current,
        }}
        onBeforeInit={swiper => {
          swiper.params.navigation.prevEl = sliderPrevButtonRef.current;
          swiper.params.navigation.nextEl = sliderNextButtonRef.current;
        }}
        className="PostCarouselSlider"
      >
        {assets.map((asset, idx) => (
          <SwiperSlide key={idx}>
            <div className={styles.slide}>
              {asset.isVideo && !asset.isVideoProcessing && <SquareAsset src={asset.url} type={'video'} controls />}
              {(!asset.isVideo || (asset.isVideo && asset.isVideoProcessing)) && <img src={asset.url} alt="preview image" />}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={cs(styles.button, styles.prev, { [styles.hide]: assets.length < 2 })} ref={sliderPrevButtonRef}>
        <ArrowLeftSVG />
      </div>
      <div className={cs(styles.button, styles.next, { [styles.hide]: assets.length < 2 })} ref={sliderNextButtonRef}>
        <ArrowLeftSVG />
      </div>
    </div>
  );
};

export default PostCarousel;
