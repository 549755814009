/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';

export default ({ children, ...rest }) => {
  const location = useLocation();
  const isAuthenticated = useSelector(state => !!state.auth.token);
  const isCompleteSignUp = location.pathname.includes('/complete-signup');

  return (
    <Route
      {...rest}
      render={() => (!isAuthenticated || isCompleteSignUp ? children : <Redirect to= {'/campaign'} />)}
    />
  );
};
