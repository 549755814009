import React from 'react';
import styles from './DeliverablePlatform.module.scss';
import FacebookSVG from '../../../IconsSvg/FacebookSVG';
import InstagramSVG from '../../../IconsSvg/InstagramSVG';
import cs from 'classnames';
import { FaYoutube } from 'react-icons/fa';
import { SiTiktok } from 'react-icons/si';

const DeliverablePlatform = ({ platform, isTablet, className, withoutText = false }) => {
  const getPlatformContent = () => {
    switch (platform) {
      case 'facebook':
        return (
          <>
            <FacebookSVG />
            {!isTablet && !withoutText && 'Facebook'}
          </>
        );
      case 'instagram':
        return (
          <>
            <InstagramSVG />
            {!isTablet && !withoutText && 'Instagram'}
          </>
        );
      case 'youtube':
        return (
          <>
            <FaYoutube className={styles.youtube} />
            {!isTablet && !withoutText && 'Youtube'}
          </>
        );
      case 'tiktok':
        return (
          <>
            <SiTiktok />
            {!isTablet && !withoutText && 'Tiktok'}
          </>
        );
      default:
        return <span className={styles.gray}>No platform</span>;
    }
  };

  return <div className={cs(styles.root, className)}>{getPlatformContent()}</div>;
};

export default DeliverablePlatform;
