import React, { forwardRef, useState } from 'react';
import styles from './InputControl.module.scss';
import cs from 'classnames';
import InfoQuestionSVG from '../../IconsSvg/InfoQuestionSVG';
import TooltipControl from '../TooltipControl';
import { ReactComponent as EyeClosedIcon } from '../../../../assets/img/icons/eye_closed.svg';
import { ReactComponent as EyeIcon } from '../../../../assets/img/icons/eye.svg';

const InputControl = forwardRef((
  {
    label,
    name,
    tooltip,
    placeholder,
    type,
    value,
    onChange,
    onBlur,
    errorMessage,
    id,
    tabIndex,
    className,
    max,
    iconEyes = false,
    ...other
  }, ref) => {
    const [showPass, setShowPass] = useState(false);

    return (
      <div className={cs(styles.root, errorMessage ? styles.error : null, className)}>
        <div className={styles.flex}>
          {label ? <label className={styles.label}>{label}</label> : null}
          {tooltip ? (
            <TooltipControl id={label} text={tooltip}>
              <InfoQuestionSVG />
            </TooltipControl>
          ) : null}
        </div>

        <input
          className={styles.input}
          type={!showPass ? type : 'text'}
          placeholder={placeholder ? placeholder : null}
          maxLength={max ? max : ''}
          value={value}
          name={name}
          onBlur={onBlur}
          id={id}
          onChange={onChange}
          tabIndex={tabIndex}
          ref={ref}
          {...other}
        />
        {errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>}
        {iconEyes &&
          <div className={styles.show} onClick={()=>setShowPass(!showPass)}>
            {showPass ? <EyeClosedIcon /> : <EyeIcon />}
          </div>
        }
      </div>
    );
  }
);

export default InputControl;
