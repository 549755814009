import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { clearContentBoard, getContentBoard } from '../../../../actions/contentApproval.actions';

const ContentApprovalHeaderBreadcrumbs = ({ page, BreadcrumbsComponent }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const { openedBoard, openedDeliverable, boardLoading } = useSelector(state => state.contentApproval);
  const [boardName, setBoardName] = useState(openedBoard?.name);
  const [deliverableName, setDeliverableName] = useState(openedDeliverable?.name);
  const [backRoute, setBackRoute] = useState(JSON.parse(localStorage.getItem('boardBackRoute')));

  useEffect(() => {
    if (!openedBoard && page === 'deliverable' && currentOrg) {
      dispatch(getContentBoard(currentOrg.organization_id, params.boardId));
    }
    if (openedBoard) {
      setBoardName(openedBoard.name || 'Board');
    }
    if (openedDeliverable) {
      setDeliverableName(openedDeliverable.name || 'Deliverable');
    }
  }, [openedBoard, boardLoading, openedDeliverable, currentOrg]);

  const items = useMemo(() => {
    let rootRouteName = 'Content Approval';
    let rootRoute = '/content-approval';

    if (backRoute) {
      if (+backRoute.boardId === +params.boardId) {
        rootRouteName = backRoute.routes[0].routeName;
        rootRoute = backRoute.routes[0].route;
      } else if (page !== 'deliverable') {
        localStorage.removeItem('boardBackRoute');
        setBackRoute(null);
      }
    }

    const array = [];

    if (boardName) {
      array.push({
        name: rootRouteName,
        callback() {
          history.push(rootRoute);
          localStorage.removeItem('boardBackRoute');
          dispatch(clearContentBoard());
        },
      });

      if (backRoute.routes[0].route === '/campaign') {
        const newRoutes = [...backRoute.routes];
        newRoutes.shift();
        array.push(
          ...newRoutes.map(item => ({
            name: item.routeName,
            callback() {
              history.push(item.route);
              localStorage.removeItem('boardBackRoute');
              dispatch(clearContentBoard());
            },
          }))
        );
      }

      array.push({
        name: 'Board: ' + boardName,
        callback() {
          history.push(`/content-board/${params.boardId}`);
        },
      });
    }

    if (page === 'deliverable' && deliverableName) {
      array.push({
        name: 'Type of deliv: ' + deliverableName,
        callback() {},
      });
    }

    return array;
  }, [page, boardName, deliverableName, backRoute, openedBoard]);

  return <BreadcrumbsComponent items={items} />;
};

export default ContentApprovalHeaderBreadcrumbs;
