import React, { useEffect } from 'react';
import styles from './CurrentPlan.module.scss';
import { createCustomerPortal } from '../../../actions/stripe.actions';
import { useDispatch, useSelector } from 'react-redux';
import CurrentPlanLoading from './CurrentPlanLoading';
import CardsPlan from '../../../components/CardsPlan/CardsPlan';
import cs from 'classnames';
import { getFacebookPages, getInstagramAccounts } from '../../../actions/assets.actions';

const CurrentPlan = () => {
  const dispatch = useDispatch();

  const currentOrg = useSelector(state => state.auth.currentOrganization);
  const user = useSelector(state => state.auth.user);
  const stripe = useSelector(state => state.auth.stripe);
  const currentPlan = useSelector(state => state.user.pricingPlan);
  const loading = useSelector(state => state.user.loading);
  const planId = useSelector(state => state.user.pricingPlan.id);
  const fbIntegration = useSelector(state => state.ad_integration.ad_integrations.find(item => item.type === 'facebook'));
  const hasFbIntegrationToken = !!fbIntegration?.params?.ACCESS_TOKEN;
  const facebookIntegration = useSelector(state => state.assets.facebookPages);
  const instagramIntegration = useSelector(state => state.assets.instagramAccounts);
  const isFreePlan = planId === 1001;

  useEffect(() => {
    if (!currentOrg) return;
    if (!facebookIntegration.length || !instagramIntegration.length) {
      dispatch(getFacebookPages(1, 100));
      dispatch(getInstagramAccounts(1, 10));
    }
  }, [hasFbIntegrationToken, currentOrg]);

  if (loading || !planId) return <CurrentPlanLoading isOwner={currentOrg?.role.toLowerCase() === 'owner'} />;

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <div>
            <span
              className={cs(styles.title, {
                [styles.titleWarning]: !currentOrg?.owner_has_valid_subscription && !isFreePlan,
              })}
            >
              <div
                className={cs(styles.circle, {
                  [styles.circleWarning]: !currentOrg?.owner_has_valid_subscription && !isFreePlan,
                })}
              ></div>
              {currentPlan.name === 'Pro' ? 'Professional' : isFreePlan ? 'Early Access' : currentPlan.name}
            </span>
            {currentOrg?.owner_has_valid_subscription || currentPlan?.id === 1001 ? (
              <span className={styles.text}>Current plan</span>
            ) : (
              <span className={styles.text}>
                {stripe?.subscription_status === 'past_due' ? 'Subscription payment failed' : 'Subscription cancelled'}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <CardsPlan title="Team Members" currentNumber={currentPlan.usage.team_members} total={currentPlan.team_members} />
        <CardsPlan title="Creative storage" currentNumber={currentPlan.usage?.storage} total={currentPlan.storage} />
      </div>
    </div>
  );
};

export default CurrentPlan;
