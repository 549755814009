import React, { useMemo } from 'react';
import cs from 'classnames';
import styles from './TextWithTagsHighlight.module.scss';

const TextWithTagsHighlight = ({ text, className, placeholder = '', withoutHighlight = false, ...props }) => {
  const wrapTagsWithSpans = defaultText => {
    return defaultText.replace(/(@[a-zA-Z0-9]+)|(#[a-zA-Z0-9]+)/g, tag => `<span>${tag}</span>`);
  };
  const textHtml = useMemo(() => (withoutHighlight ? text : wrapTagsWithSpans(text)), [text]);
  return (
    <div
      className={cs(styles.root, { [styles.highlight]: !withoutHighlight }, { [styles.placeholder]: placeholder && !textHtml }, className)}
      dangerouslySetInnerHTML={{ __html: textHtml || placeholder }}
      {...props}
    />
  );
};

export default TextWithTagsHighlight;
