import React from 'react';
import LoadingContainer from '../../.././../../components/LoadingContainer';

const MessageBodyLoadingState = ({ withImages = false }) => {
  return (
    <div style={{ padding: 10, display: 'flex', gap: 10 }}>
      <div style={{ minWidth: 36 }}>
        <LoadingContainer width={'36px'} height={'36px'} borderRadius={'50%'} animationDelay={'0.5s'} />
      </div>
      <div style={{ width: '100%' }}>
        <div style={{ paddingBottom: 10, display: 'flex', gap: 10 }}>
          <LoadingContainer width={'100px'} height={'18px'} borderRadius={'5px'} animationDelay={'0s'} />
          <LoadingContainer width={'80px'} height={'18px'} borderRadius={'5px'} animationDelay={'0s'} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5, marginBottom: 10 }}>
          <LoadingContainer width={'100%'} height={'14px'} borderRadius={'5px'} animationDelay={'0.5s'} />
          <LoadingContainer width={'100%'} height={'14px'} borderRadius={'5px'} animationDelay={'1s'} />
          <LoadingContainer width={'60%'} height={'14px'} borderRadius={'5px'} animationDelay={'1.5s'} />
        </div>
        {withImages && (
          <div style={{ display: 'flex', gap: 7 }}>
            <LoadingContainer width={'50%'} height={'60px'} borderRadius={'5px'} animationDelay={'1s'} />
            <LoadingContainer width={'50%'} height={'60px'} borderRadius={'5px'} animationDelay={'1s'} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageBodyLoadingState;
