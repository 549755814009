import localforage from 'localforage';

export const GENERATE_CREATIVES_TYPES = {
  INIT_DRAFT_CREATIVE_STATE: 'INIT_DRAFT_CREATIVE_STATE',
  UPDATE_DRAFT_CREATIVE: 'UPDATE_DRAFT_CREATIVE',
  CLEAR_DRAFT_CREATIVE: 'CLEAR_DRAFT_CREATIVE',
  SET_LAST_GENERATED_CREATIVES: 'SET_LAST_GENERATED_CREATIVES',
};

const saveGeneratedCreative = data => {
  return localforage.setItem('OTTERFISH_GENERATED_CREATIVE', data).catch(catchError);
};

const getGeneratedCreative = () => {
  return localforage.getItem('OTTERFISH_GENERATED_CREATIVE').catch(catchError);
};

const removeGeneratedCreative = () => {
  return localforage.removeItem('OTTERFISH_GENERATED_CREATIVE').catch(catchError);
};

const catchError = err => {
  console.error('It is necessary to give access to store data: ', err);
};

export const updateDraftCreative = (orgId, updates) => async (dispatch, getState) => {
  try {
    const state = getState();
    const id = orgId || state.auth.currentOrganization.organization_id;
    const draftCreative = state.generateCreative.draftCreative;

    const newDraft = {
      ...draftCreative,
      [orgId]: {
        ...draftCreative[orgId],
        ...updates,
      },
    };

    dispatch({
      type: GENERATE_CREATIVES_TYPES.UPDATE_DRAFT_CREATIVE,
      updates: updates,
      orgId: id,
    });
    saveGeneratedCreative(newDraft);
  } catch (error) {}
};

export const initDraftCreative = () => async dispatch => {
  try {
    const data = await getGeneratedCreative();
    if (data) {
      dispatch({
        type: GENERATE_CREATIVES_TYPES.INIT_DRAFT_CREATIVE_STATE,
        payload: data,
      });
    }
  } catch (error) {}
};

export const clearDraftCreative = () => async dispatch => {
  try {
    dispatch({ type: GENERATE_CREATIVES_TYPES.CLEAR_DRAFT_CREATIVE });
    await removeGeneratedCreative();
  } catch (error) {}
};

export const setLastGeneratedCreatives = creatives => {
  return {
    type: GENERATE_CREATIVES_TYPES.SET_LAST_GENERATED_CREATIVES,
    creatives: creatives,
  };
};
