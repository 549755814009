import { instance } from '../shared/utils/api.config.creator';

export const getAgeIntervals = async () => {
  const response = await instance.get('age-intervals');
  return response.data;
};

export const getBudgetIntervals = async () => {
  const response = await instance.get('budget-intervals');
  return response.data;
};

export const getCities = async countyId => {
  const response = await instance.get(`cities${countyId ? `?county_id=${countyId}&page_size=2000` : ''}`);
  return response.data;
};

export const getCounties = async countryId => {
  const response = await instance.get(`counties${countryId ? `?country_id=${countryId}&page_size=500` : ''}`);
  return response.data;
};

export const getCountries = async () => {
  const response = await instance.get('countries?page_size=500');
  return response.data;
};

export const getIndustries = async () => {
  const response = await instance.get('industries?page_size=100');
  return response.data;
};

export const getTimezones = async params => {
  const response = await instance.get('timezones?page_size=500', { params });
  return response.data;
};

export const getTestimonials = async () => {
  const response = await instance.get('testimonials');
  return response.data;
};
