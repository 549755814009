import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './DeliverablesApprovedLabel.module.scss';
import { clearContentBoard, getBoardDeliverables } from '../../../../../actions/contentApproval.actions';

function DeliverablesApprovedLabel({ boardId }) {
  const dispatch = useDispatch();

  const { deliverablesTotalCount, deliverablesApprovedCount } = useSelector(state => state.contentApproval);
  const currentOrg = useSelector(state => state.auth.currentOrganization);

  useEffect(() => {
    // get board only if boardId not empty
    if (currentOrg && boardId) {
      dispatch(getBoardDeliverables(currentOrg.organization_id, boardId));
    }

    return () => {
      if (boardId) {
        dispatch(clearContentBoard());
      }
    };
  }, [currentOrg]);

  return (
    <div className={styles.counterContainer}>
      <span>Approved</span>
      <span className={styles.counter}>
        {deliverablesApprovedCount}/{deliverablesTotalCount}
      </span>
    </div>
  );
}

export default DeliverablesApprovedLabel;
