import React from 'react';
import styles from './ClearFilterCross.module.scss';

const ClearFilterCross = () => {
  return (
    <span className={styles.root}>
      <div></div>
    </span>
  );
};

export default ClearFilterCross;
