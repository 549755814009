import React from 'react';
import styles from './ContentHeader.module.scss';
import { useSelector } from 'react-redux';
import LoadingContainer from '../../../../../components/LoadingContainer';
import DeliverablesApprovedLabel from '../DeliverablesApprovedLabel';
import useContentBoardStatusTile from '../../useContentBoardStatusTile';
import HeaderColumn from '../../../DeliverableItem/components/Header/HeaderColumn';
import ContentBoardCollaborators from '../ContentBoardCollaborators';
import { useMediaQuery } from 'react-responsive/src';
import ContentName from './ContentName';

const ContentHeader = () => {
  const { openedBoard, deliverablesTotalCount, boardLoading, deliverablesLoading } = useSelector(state => state.contentApproval);
  const statusTile = useContentBoardStatusTile(openedBoard?.status);
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  return !isMobile ? (
    <div className={styles.root}>
      <HeaderColumn columnName="Content board" className={styles.boardName}>
        <LoadingWrapper isLoading={boardLoading} loaderWidth={130} delay={1}>
          <ContentName />
        </LoadingWrapper>
      </HeaderColumn>
      <HeaderColumn columnName="Related brief">
        <LoadingWrapper isLoading={boardLoading} loaderWidth={91} delay={0.9}>
          <div className={styles.briefName}>
            {openedBoard?.campaign_brief_item_id ? openedBoard?.campaign_brief_item_name || 'unnamed brief' : 'Stand alone'}
          </div>
        </LoadingWrapper>
      </HeaderColumn>
      <HeaderColumn type="group" className={styles.columnGroup}>
        <HeaderColumn columnName="Collaborators">
          <LoadingWrapper isLoading={boardLoading} loaderWidth={96} delay={0.8}>
            <ContentBoardCollaborators />
          </LoadingWrapper>
        </HeaderColumn>
        <HeaderColumn columnName="Deliverables">
          <LoadingWrapper isLoading={deliverablesLoading && !deliverablesTotalCount} loaderWidth={125} delay={0.7}>
            {!!deliverablesTotalCount && <DeliverablesApprovedLabel />}
          </LoadingWrapper>
        </HeaderColumn>
        <HeaderColumn columnName="Board status:">
          <LoadingWrapper isLoading={boardLoading} loaderWidth={133} delay={0.6}>
            <div className={styles.boardStatus}>{statusTile}</div>
          </LoadingWrapper>
        </HeaderColumn>
      </HeaderColumn>
    </div>
  ) : (
    <div className={styles.root}>
      <HeaderColumn columnName="Content board">
        <LoadingWrapper isLoading={boardLoading} loaderWidth={130} delay={1}>
          <ContentName />
        </LoadingWrapper>
      </HeaderColumn>
      <div className={styles.grayLine}></div>
      <HeaderColumn type="group">
        <HeaderColumn columnName="Related brief">
          <LoadingWrapper isLoading={boardLoading} loaderWidth={91} delay={0.9}>
            <div className={styles.briefName}>
              {openedBoard?.campaign_brief_item_id ? openedBoard?.campaign_brief_item_name || 'unnamed brief' : 'Stand alone'}
            </div>
          </LoadingWrapper>
        </HeaderColumn>
        <HeaderColumn columnName="Collaborators">
          <LoadingWrapper isLoading={boardLoading} loaderWidth={96} delay={0.8}>
            <ContentBoardCollaborators />
          </LoadingWrapper>
        </HeaderColumn>
      </HeaderColumn>
      <HeaderColumn type="group" className={styles.columnGroup}>
        <HeaderColumn columnName="Deliverables">
          <LoadingWrapper isLoading={deliverablesLoading && !deliverablesTotalCount} loaderWidth={125} delay={0.7}>
            {!!deliverablesTotalCount && <DeliverablesApprovedLabel />}
          </LoadingWrapper>
        </HeaderColumn>
        <HeaderColumn columnName="Board status:">
          <LoadingWrapper isLoading={boardLoading} loaderWidth={133} delay={0.6}>
            <div className={styles.boardStatus}>{statusTile}</div>
          </LoadingWrapper>
        </HeaderColumn>
      </HeaderColumn>
    </div>
  );
};

const LoadingWrapper = ({ isLoading, loaderWidth, delay, children }) => {
  return (
    <>
      {isLoading ? (
        <LoadingContainer width={`${loaderWidth}px`} height="30px" borderRadius="5px" animationDelay={`${-delay}s`} />
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default ContentHeader;
