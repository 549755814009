import { CONFIG_TYPES } from '../actions/config.actions';

const initialState = {
  industries: {
    data: [],
    error: {},
  },
  cities: {
    data: [],
    error: {},
  },
  counties: {
    data: [],
    error: {},
  },
  budget_intervals: {
    data: [],
    error: {},
  },
  age_intervals: {
    data: [],
    error: {},
  },
  countries: {
    data: [],
    error: {},
  },
  timezones: {
    data: [],
    error: {},
  },
  testimonials: {
    data: [],
    error: {},
  },
  loading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CONFIG_TYPES.GET_INDUSTRIES.SUCCESS: {
      return {
        ...state,
        industries: {
          ...state.industries,
          data: payload,
        },
      };
    }
    case CONFIG_TYPES.GET_INDUSTRIES.FAILURE: {
      return {
        ...state,
        industries: {
          ...state.industries,
          error: payload,
        },
      };
    }
    case CONFIG_TYPES.GET_CITIES.REQUEST: {
      return {
        ...state,
        cities: {
          ...state.cities,
          data: [],
        },
        loading: true,
      };
    }
    case CONFIG_TYPES.GET_CITIES.SUCCESS: {
      return {
        ...state,
        cities: {
          ...state.cities,
          data: payload,
        },
        loading: false,
      };
    }
    case CONFIG_TYPES.GET_CITIES.FAILURE: {
      return {
        ...state,
        cities: {
          ...state.cities,
          error: payload,
        },
        loading: false,
      };
    }
    case CONFIG_TYPES.GET_COUNTIES.REQUEST: {
      return {
        ...state,
        counties: {
          ...state.counties,
          data: [],
        },
        loading: true,
      };
    }
    case CONFIG_TYPES.GET_COUNTIES.SUCCESS: {
      return {
        ...state,
        counties: {
          ...state.counties,
          data: payload,
        },
        loading: false,
      };
    }
    case CONFIG_TYPES.GET_COUNTIES.FAILURE: {
      return {
        ...state,
        counties: {
          ...state.counties,
          error: payload,
        },
        loading: false,
      };
    }
    case CONFIG_TYPES.GET_BUDGET_INTERVAL.SUCCESS: {
      return {
        ...state,
        budget_intervals: {
          ...state.budget_intervals,
          data: payload,
        },
      };
    }
    case CONFIG_TYPES.GET_BUDGET_INTERVAL.FAILURE: {
      return {
        ...state,
        budget_intervals: {
          ...state.budget_intervals,
          error: payload,
        },
      };
    }
    case CONFIG_TYPES.GET_AGE_INTERVAL.SUCCESS: {
      return {
        ...state,
        age_intervals: {
          ...state.age_intervals,
          data: payload,
        },
      };
    }
    case CONFIG_TYPES.GET_AGE_INTERVAL.FAILURE: {
      return {
        ...state,
        age_intervals: {
          ...state.age_intervals,
          error: payload,
        },
      };
    }
    case CONFIG_TYPES.GET_COUNTRIES.SUCCESS: {
      return {
        ...state,
        countries: {
          ...state.countries,
          data: payload,
        },
      };
    }
    case CONFIG_TYPES.GET_COUNTRIES.FAILURE: {
      return {
        ...state,
        countries: {
          ...state.countries,
          error: payload,
        },
      };
    }
    case CONFIG_TYPES.GET_TIMEZONES.REQUEST: {
      return {
        ...state,
        timezones: {
          ...state.timezones,
        },
        loading: true,
      };
    }
    case CONFIG_TYPES.GET_TIMEZONES.SUCCESS: {
      return {
        ...state,
        timezones: {
          ...state.timezones,
          data: payload,
        },
        loading: false,
      };
    }
    case CONFIG_TYPES.GET_TIMEZONES.FAILURE: {
      return {
        ...state,
        timezones: {
          ...state.timezones,
          error: payload,
        },
        loading: false,
      };
    }
    case CONFIG_TYPES.GET_TESTIMONIALS.REQUEST: {
      return {
        ...state,
        testimonials: {
          ...state.testimonials,
        },
        loading: true,
      };
    }
    case CONFIG_TYPES.GET_TESTIMONIALS.SUCCESS: {
      return {
        ...state,
        testimonials: {
          ...state.testimonials,
          data: payload,
        },
        loading: false,
      };
    }
    case CONFIG_TYPES.GET_TESTIMONIALS.FAILURE: {
      return {
        ...state,
        testimonials: {
          ...state.testimonials,
          error: payload,
        },
        loading: false,
      };
    }
    case CONFIG_TYPES.CLEAR_CITIES: {
      return {
        ...state,
        cities: {
          data: [],
          error: {},
        },
      };
    }
    default: {
      return state;
    }
  }
};
