import React, { useEffect, useRef } from 'react';
import styles from './DiscussionMessagesContainer.module.scss';
import MessageBody from '../MessageBody';
import dayjs from 'dayjs';
import EmptyMessagesState from '../EmptyMessagesState';
import MessageBodyLoadingState from '../MessageBody/LoadingState';

const DiscussionMessagesContainer = ({ comments, commentsLoading, collaboratorsRoom, setNewMessagesCount, newMessages, oldMessages }) => {
  const messagesBodyRef = useRef(null);

  useEffect(() => {
    if (messagesBodyRef.current) {
      messagesBodyRef.current.scrollTop = messagesBodyRef.current.scrollHeight;
    }
  }, [comments]);


  useEffect(() => {
    setNewMessagesCount(newMessages.length);
  }, [newMessages]);

  const renderMessages = messages => {
    return messages.map((item, idx) => (
      <MessageBody
        key={item.created_at + idx}
        text={item.comment}
        author={`${item.creator_first_name} ${item.creator_last_name}`}
        date={dayjs(item.created_at).format('MMM D, hh:mm A')}
        authorImg={item.creator_photo_location || item.creator_photo_thumbnail_location}
        attachments={item.attachments}
      />
    ));
  };

  return (
    <div ref={messagesBodyRef} className={styles.discussionBody}>
      {!commentsLoading && comments.length > 0 && (
        <>
          {renderMessages(oldMessages)}
          {!!newMessages.length && (
            <div className={styles.newMessagesLine}>
              <div />
              <span>New Messages</span>
              <div />
            </div>
          )}
          {renderMessages(newMessages)}
        </>
      )}
      {!commentsLoading && comments.length === 0 && <EmptyMessagesState collaborators={collaboratorsRoom} />}
      {commentsLoading && (
        <>
          <MessageBodyLoadingState />
          <MessageBodyLoadingState />
          <MessageBodyLoadingState withImages />
        </>
      )}
    </div>
  );
};

export default DiscussionMessagesContainer;
