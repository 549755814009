import React from 'react';

const EmptyAvatarSVG = () => {
  return (
    <svg width="100" height="101" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M73.2438 91.8976V85.0683C73.2438 83.4218 72.6929 81.8487 71.7821 80.6101C70.3325 78.6899 68.1729 77.5401 65.7156 77.5401H34.2632C30.1077 77.5401 26.735 80.9129 26.735 85.0683V91.8976H73.2438ZM78.7958 75.383C75.8003 71.389 71.1074 68.793 65.7156 68.793H34.2632C25.2768 68.793 17.9878 76.0819 17.9878 85.0683V94.554C17.9878 97.849 20.6837 100.645 24.0786 100.645H75.9002C79.1952 100.645 81.9909 97.9488 81.9909 94.554V85.0683C81.9909 81.4738 80.7928 78.0789 78.7958 75.383Z" fill="#E3E6EB"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M55.9866 51.0749L56.0364 51.0266C57.5362 49.5737 58.4678 47.5448 58.4678 45.1276L58.4677 44.9946L58.4357 44.8348L58.3949 44.3157C58.0495 39.925 54.4074 36.5011 49.9411 36.5011C47.7108 36.5011 45.5034 37.4727 43.9447 39.0314L43.8956 39.0805L43.8458 39.1288C42.346 40.5816 41.4144 42.6106 41.4144 45.0278C41.4144 47.258 42.386 49.4655 43.9447 51.0242L43.9938 51.0732L44.0421 51.1231C45.495 52.6228 47.5239 53.5545 49.9411 53.5545C50.3165 53.5545 50.5099 53.5535 50.6773 53.5476C50.7606 53.5446 50.8047 53.5412 50.8187 53.54L50.9728 53.518L51.1455 53.5003C52.8701 53.3234 54.5395 52.522 55.9375 51.124L55.9866 51.0749ZM52.0379 62.2018C55.932 61.8024 59.4267 60.0051 62.1227 57.3092C65.3178 54.2139 67.215 49.9204 67.215 45.1276V45.1276C67.215 44.6283 67.215 44.1291 67.1151 43.6299C66.4162 34.7433 59.0273 27.7539 49.9411 27.7539C45.2482 27.7539 40.8548 29.7509 37.7595 32.8462C34.5644 35.9415 32.6672 40.235 32.6672 45.0278C32.6672 49.7207 34.6642 54.114 37.7595 57.2093C40.8548 60.4045 45.1483 62.3016 49.9411 62.3016H49.9412C50.6401 62.3016 51.339 62.3016 52.0379 62.2018Z" fill="#E3E6EB"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M74.9757 9.39169H24.9919C16.0202 9.39169 8.74717 16.6647 8.74716 25.6364V75.6202C8.74717 84.5919 16.0202 91.8649 24.9919 91.8649H74.9757C83.9474 91.8649 91.2204 84.5919 91.2204 75.6202V25.6364C91.2204 16.6647 83.9474 9.39169 74.9757 9.39169ZM74.9757 0.644531C88.7783 0.644531 99.9676 11.8338 99.9676 25.6364V75.6202C99.9676 89.4228 88.7783 100.612 74.9757 100.612H24.9919C11.1893 100.612 1.6454e-05 89.4229 0 75.6202V25.6364C1.6454e-05 11.8337 11.1893 0.644531 24.9919 0.644531H74.9757Z" fill="#E3E6EB"/>
    </svg>
  )
}

export default EmptyAvatarSVG;
