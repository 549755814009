import React, { useState } from 'react';
import { Modal } from "../index";
import styles from './AcceptFacebookPermissionsModal.module.scss';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import Checkbox from '../../FormControls/Checkbox';
import { allFbPermissions } from '../../../shared/constants/constants';

const AcceptFacebookPermissionsModal = ({ onClose, onClickNext }) => {
  const [checkbox, setCheckbox] = useState(false);

  const onCloseModal = () =>{
    onClose();
    onClickNext();
  }

  return (
    <div className={styles.content}>
      <div className={styles.header}>Accept Facebook Permissions</div>
      <div className={styles.table}>
        <div className={styles.head}>
          <div className={styles.leftBlock}>
            <span>Name</span>
            <span>Description</span>
          </div>
        </div>
        <div className={styles.tableContent}>
          {
            allFbPermissions.map( (item, index) =>
              <div key={index} className={styles.box}>
                <div className={styles.leftInfo}>{item.name}</div>
                <div className={styles.rightInfo}>{item.description}</div>
              </div>
            )
          }
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.checkboxMenu}>
          <Checkbox checked={checkbox} onChange={()=>setCheckbox(!checkbox)} className={styles.check}/>
          <span>I accept above permissions</span>
        </div>
        <div className={styles.buttonsBox}>
          <ButtonCustom outline onClick={() => onClose()}>
            Cancel
          </ButtonCustom>
          <ButtonCustom disabled={!checkbox} onClick={() => onCloseModal()}>
            Next
          </ButtonCustom>
        </div>
      </div>
    </div>
  )
};

export default props => {
  return (
    <Modal {...props} maxWidth={'640px'} className={styles.changeIndentation}>
      <AcceptFacebookPermissionsModal {...props} />
    </Modal>
  );
};
