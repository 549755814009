import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import './index.scss';
import 'react-responsive-modal/styles.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';

import createStore from './store';

export const store = createStore();

// Google Tag manager code start
const tagManagerArgs = {
  gtmId: 'GTM-MK2JKKP',
};

TagManager.initialize(tagManagerArgs);
// Google Tag manager code end

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
