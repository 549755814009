import { toTitleCase } from '../../shared/utils/helpers';
import dayjs from 'dayjs';

export const formatDoubleDigit = v => ('0' + v).slice(-2); // works only for integer values

export const getTimeOptions = () => {
  const timeOptions = [];
  for (let h = 0; h < 24; h++) {
    for (let m = 0; m < 60; m += 15) {
      timeOptions.push(`${formatDoubleDigit(h)}:${formatDoubleDigit(m)}`);
    }
  }
  return timeOptions;
};

export const disabledTime = (i, dateStart, dateEnd, checkDiff, isStartDate) => {
  const end = dayjs(dateEnd).toDate();
  const start = dayjs(dateStart).toDate();
  const minEnd = dayjs(dateStart).add(1, 'd').toDate();

  // diff must be at least 24h (?)
  const diff = (end - minEnd) / 1000 / 60 / 60;

  // if start time is not today user can set any time
  // else the set time cannot be less than now
  const startChecking = start.getFullYear() < end.getFullYear() || start.getMonth() < end.getMonth() || start.getDate() < end.getDate();

  if ((isStartDate && startChecking) || (checkDiff && diff >= 24)) return;

  const disabledN = +i.split(':')[0] <= start.getHours();
  const disabledH = +i.split(':')[0] < start.getHours();
  const disabledM = disabledH || +i.split(':')[1] < start.getMinutes();

  return disabledH || (disabledN && disabledM);
};

export const CTA = {
  reach: [
    { label: 'Apply Now', value: 'apply_now' },
    { label: 'Book Now', value: 'book_travel' },
    { label: 'Call Now', value: 'call_now' },
    { label: 'Contact Us', value: 'contact_us' },
    { label: 'Download', value: 'download' },
    { label: 'Get Directions', value: 'get_directions' },
    { label: 'Get Quote', value: 'get_quote' },
    { label: 'Get Showtimes', value: 'get_showtimes' },
    { label: 'Learn More', value: 'learn_more' },
    { label: 'Listen Now', value: 'listen_now' },
    { label: 'Order Now', value: 'order_now' },
    { label: 'Request Time', value: 'request_time' },
    { label: 'Save', value: 'save' },
    { label: 'See Menu', value: 'see_menu' },
    { label: 'Send Message', value: 'message_page' },
    { label: 'Send Whatsapp Message', value: 'whatsapp_message' },
    { label: 'Shop Now', value: 'shop_now' },
    { label: 'Sign Up', value: 'sign_up' },
    { label: 'Subscribe', value: 'subscribe' },
    { label: 'Watch More', value: 'watch_more' },
  ],
  post_engagement: [
    { label: 'Call Now', value: 'call_now' },
    { label: 'Get Quote', value: 'get_quote' },
    { label: 'Learn More', value: 'learn_more' },
    { label: 'Send Message', value: 'message_page' },
    { label: 'Send Whatsapp Message', value: 'whatsapp_message' },
    { label: 'Shop Now', value: 'shop_now' },
  ],
  link_clicks: [
    { label: 'Apply Now', value: 'apply_now' },
    { label: 'Book Now', value: 'book_travel' },
    { label: 'Contact Us', value: 'contact_us' },
    { label: 'Download', value: 'download' },
    { label: 'Get Offer', value: 'get_offer' },
    { label: 'Get Quote', value: 'get_quote' },
    { label: 'Get Showtimes', value: 'get_showtimes' },
    { label: 'Learn More', value: 'learn_more' },
    { label: 'Listen Now', value: 'listen_now' },
    { label: 'Order Now', value: 'order_now' },
    { label: 'Request Time', value: 'request_time' },
    { label: 'See Menu', value: 'see_menu' },
    { label: 'Shop Now', value: 'shop_now' },
    { label: 'Sign Up', value: 'sign_up' },
    { label: 'Subscribe', value: 'subscribe' },
    { label: 'Watch More', value: 'watch_more' },
  ],
  conversions: [
    { label: 'Apply Now', value: 'apply_now' },
    { label: 'Book Now', value: 'book_travel' },
    { label: 'Contact Us', value: 'contact_us' },
    { label: 'Download', value: 'download' },
    { label: 'Get Offer', value: 'get_offer' },
    { label: 'Get Quote', value: 'get_quote' },
    { label: 'Get Showtimes', value: 'get_showtimes' },
    { label: 'Learn More', value: 'learn_more' },
    { label: 'Listen Now', value: 'listen_now' },
    { label: 'Order Now', value: 'order_now' },
    { label: 'Play Game', value: 'play_game' },
    { label: 'Request Time', value: 'request_time' },
    { label: 'See Menu', value: 'see_menu' },
    { label: 'Shop Now', value: 'shop_now' },
    { label: 'Sign Up', value: 'sign_up' },
    { label: 'Subscribe', value: 'subscribe' },
    { label: 'Watch More', value: 'watch_more' },
  ],
};

export const getObjectiveDisplayName = value => {
  if (value === 'sales_leads') {
    return 'Sales/Leads';
  }

  return toTitleCase(value?.replace(/_/g, ' '));
};
