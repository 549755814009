import React from 'react';
import styles from './CollaboratorCell.module.scss';
import { TYPES_CAMPAIGNS_TEXTS } from '../../../../../shared/constants/constants';

const CollaboratorCell = ({ collaborator }) => {
  return (
    <div className={styles.collaboratorCell}>
      <div className={styles.imageContainer}>
        <div className={styles.icon}>{collaborator.organization_name.slice(0, 1)}</div>
      </div>
      <div className={styles.nameContainer}>
        <span className={styles.blackText}>{collaborator.organization_name}</span>
      </div>
      <div className={styles.type}>{TYPES_CAMPAIGNS_TEXTS[collaborator.organization_type] || 'N/A'}</div>
    </div>
  );
};

export default CollaboratorCell;
