/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styles from './ImportPixelsModal.module.scss';
import { ModalMedium as Modal } from '..';
import { useDispatch, useSelector } from 'react-redux';
import {
  getManagePixelsUrl,
  importAllConversions,
  importAllFacebookPixels
} from '../../../services/facebookIntegrations';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import CirclesLoader from '../../CirclesLoader';
import EmptyAvatarSVG from '../../IconsSvg/EmptyAvatarSVG';
import FacebookSVG from '../../IconsSvg/FacebookSVG';
import PixelImportCell from '../../Cells/PixelImportCell';
import { getEntirePixelsList } from '../../../actions/assets.actions';
import SearchBar from '../../SearchBar';
import cs from 'classnames';

const NoPixels = (params)=>(
  <div className={styles.empty}>
    <EmptyAvatarSVG />
    <span>No Pixels found</span>
    <ButtonCustom
      outline
      onClick={() => window.open(getManagePixelsUrl(params.fbIntegration.params))}
    >
      <FacebookSVG />
      Manage in Facebook
    </ButtonCustom>
  </div>
);

const ImportPixels = ({ onClose, onRemove, onSelect, selected, onCancel, onImport }) => {
  const dispatch = useDispatch();
  const fbIntegration = useSelector(state => state.ad_integration.ad_integrations.find(item => item.type === 'facebook'));
  const hasFbIntegrationToken = !!fbIntegration?.params?.ACCESS_TOKEN;
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState([]);

  const handleImportAssets = async () => {
    setIsLoadingData(true);
    const { ACCESS_TOKEN, AD_ACCOUNT_ID } = fbIntegration.params;
    const pixels = await importAllFacebookPixels(ACCESS_TOKEN, AD_ACCOUNT_ID);
    const conversions = await importAllConversions(ACCESS_TOKEN, AD_ACCOUNT_ID);
    const concatWithEvents = pixels.map(pixel=> {
      pixel.conversions = conversions.filter(conv=> conv.pixel.id === pixel.id && !conv.is_archived);
      return pixel;
    });

    setData(concatWithEvents);
    await dispatch(getEntirePixelsList(1, 500));
    setIsLoadingData(false);
  };

  useEffect(() => {
    if (hasFbIntegrationToken) {
      handleImportAssets();
    }
  }, [hasFbIntegrationToken]);

  const handleCancel = () => {
    if (onCancel) onCancel();
    onClose();
  };

  const handleImport = () => {
    if (onImport) onImport();
    onClose();
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <span className={styles.title}><FacebookSVG />Select Facebook Pixels</span>
        <div className={styles.counter}>
          <span>
            Pixels selected:
            <b>{selected.length}</b>
          </span>
        </div>
      </div>

      <div className={styles.body}>
        {isLoadingData ? (
          <div className={styles.loader}>
            <CirclesLoader />
          </div>
        ) : !data.length ? (<NoPixels fbIntegration={fbIntegration}/>) : (
          <>
            <div className={styles.container}>
              <div className={cs(styles.searchContainer, styles.mobile)}>
                {data.length && (
                  <div className={styles.pixelsSearch}>
                    <SearchBar
                      onChangeSearchValue={(value) => setSearchValue(value)}
                      searchValue={searchValue}
                      placeholder={'Search pixels'}
                    />
                  </div>
                )}
              </div>
              {data.filter(item => !searchValue || item.name.toLowerCase().includes(String(searchValue).trim().toLowerCase()))
                .map(item => {
                  const pixel = { ...item, type: 'pixel' };
                  return (
                    <PixelImportCell
                      key={item.id}
                      pixel={pixel}
                      withSelect
                      selectedList={selected}
                      onRemove={onRemove}
                      onSelect={onSelect}
                    />
                  )
              })}
            </div>
          </>
        )}
      </div>

      <div className={styles.footer}>
        <ButtonCustom outline onClick={handleCancel}>
          Cancel
        </ButtonCustom>
        <ButtonCustom disabled={!selected.length} onClick={handleImport}>
          Import
        </ButtonCustom>
      </div>
    </div>
  );
};

const ImportPixelsModal = props => {
  return (
    <Modal {...props} maxWidth={'900px'}>
      <ImportPixels {...props} />
    </Modal>
  );
};

export default ImportPixelsModal;
