import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import SignIn from './pages/Auth/SignIn/EmailForm';
// import StartPage from './pages/StartPage';
// import SignUp from './pages/Auth/SignUp';
import SignUpNew from './pages/Auth/SignUpNew';
import AnonRoute from './components/AnonRoute';
import PrivateRoute from './expert/components/PrivateRoute';
// import Sandbox from './pages/Sandbox';
// import Tests from './pages/Tests';
// import Dashboard from './pages/Dashboard';
// import ImageLibrary from './pages/ImageLibrary';
import MyProfile from './pages/MyProfile';
import MyTeam from './pages/MyTeam';
import TeamMemberDetails from './pages/TeamMemberDetails';
// import Test from './pages/Test';
// import AccountAssets from './pages/AccountAssets';
// import MarketingIntegration from './pages/MarketingIntegration';
import ForgotPassword from './pages/Auth/ForgotPassword';
import ResetPassword from './pages/Auth/ResetPassword';
import ChoosePlan from './pages/ChoosePlan';
import CompleteProfile from './pages/Auth/CompleteSignUp/CompleteProfile';
// import MyPlan from './pages/MyPlan';
import CreditCardDetails from './pages/MyPlan/CurrentPlan/CreditCardDetails';
import BillingAddress from './pages/MyPlan/CurrentPlan/BillingAddress';
import EmailConformation from './expert/pages/Auth/EmailVerification/EmailConfirmation';
import AfterEmailConfirmation from './expert/pages/Auth/EmailVerification/AfterEmailConfirmation';
import ConnectFacebook from './pages/ConnectFacebook';
import ImportAssetsAudiences from './pages/ImportAssetsAudiences';
// import Audiences from './pages/Audiences';
// import CreateTest from './pages/CreateTest';
// import GenerateCreatives from './pages/CreativeBrain/GenerateCreatives';
import NoOrganizationState from './pages/NoOrganizationState';
import CreateOrganization from './pages/NoOrganizationState/CreateOrganization';
import PrivacyPolicy from './expert/pages/ServiceAndPolicy/PrivacyPolicy';
import TermsOfService from './expert/pages/ServiceAndPolicy/TermsService';
// import EmptyPage from './pages/EmptyPage';
// import Facebook from './pages/Platforms/Facebook';
// import WinnerTimelines from './pages/ WinnerTimelines';
// import LaunchWinningCreative from './pages/TestReloadSettingsPages/LaunchWinningCreative';
// import FindBestAudience from './pages/TestReloadSettingsPages/FindBestAudience';
// import MyUploads, { MY_UPLOADS_ROUTE } from './pages/GraphicAssets/MyUploads';
// import MyCreatives, { MY_CREATIVES_ROUTE } from './pages/GraphicAssets/MyCreatives';
// import MyTemplates from './pages/MyTemplates';
import CheckoutSuccess from './pages/CheckoutSuccess';
import CreateCampaignItem from './pages/CampaignBrief/CreateCampaignItem';
import CreateCampaignBriefItem from './pages/CampaignBrief/CreateCampaignBriefItem';
import CampaignBriefPage from './pages/CampaignBrief/CampaignBriefPage';
import CampaignBriefList from './pages/CampaignBrief/CampaignBriefList';
// import CreateAudiences from './pages/CreateAudiences';
import Organizations from './pages/Organizations';
import BriefItemPage from './pages/CampaignBrief/BriefItemPage';
import ContentApprovalList from './pages/ContentApproval/ContentApprovalList';
import ContentApprovalCreateBoard from './pages/ContentApproval/ContentApprovalCreateBoard';
import EditCampaignBriefItem from './pages/CampaignBrief/EditCampaignBriefItem';
import EditCampaignItem from './pages/CampaignBrief/EditCampaignItem';
import DuplicateCampaignItem from './pages/CampaignBrief/DuplicateCampaignItem';
// import TestTooltipPage from './components/Menu/components/MenuInner/components/Tooltip/TestTooltipPage';
import ContentBoardAndDeliverableRoutesWrapper from './pages/ContentApproval/components/ContentBoardAndDeliverableRoutesWrapper';

// import templates preview
// import FashionTemplates from './pages/CreativeBrain/GenerateCreatives/POC/fashion/FashionTemplates';
// import RealEstateTemplates from './pages/CreativeBrain/GenerateCreatives/POC/realEstate/RealEstateTemplates';
// import MinimalistBohemianTemplates from './pages/CreativeBrain/GenerateCreatives/POC/simple/minimalistBohemian/MinimalistBohemianTemplates';
// import AnOdeToArtDecoTemplates from './pages/CreativeBrain/GenerateCreatives/POC/simple/anOdeToArtDeco/AnOdeToArtDecoTemplates';
// import AcidicTemplates from './pages/CreativeBrain/GenerateCreatives/POC/simple/acidic/AcidicTemplates';
// import CookieCuttersTemplates from './pages/CreativeBrain/GenerateCreatives/POC/simple/cookieCutters/CookieCuttersTemplates';
// import GraphicPopTemplates from './pages/CreativeBrain/GenerateCreatives/POC/simple/graphicPop/GraphicPopTemplates';
// import CapturedCollectionsTemplates from './pages/CreativeBrain/GenerateCreatives/POC/simple/capturedCollections/capturedCollectionsTemplates';
// import TestimonialsTemplates from './pages/CreativeBrain/GenerateCreatives/POC/testimonials/TestimonialsTemplates';
// import RestaurantsTemplates from './pages/CreativeBrain/GenerateCreatives/POC/restaurants/RestaurantsTemplates';
// import ChristmasTemplates from './pages/CreativeBrain/GenerateCreatives/POC/christmas/ChristmasTemplates';
// import EventsTemplates from './pages/CreativeBrain/GenerateCreatives/POC/events/EventsTemplates';
// import ValentineTemplates from './pages/CreativeBrain/GenerateCreatives/POC/valentine/ValentineTemplates';
// import MothersDayTemplates from './pages/CreativeBrain/GenerateCreatives/POC/mothersDay/MothersDayTemplates';
// import EasterTemplates from './pages/CreativeBrain/GenerateCreatives/POC/easter/EasterTemplates';

const Routes = () => {
  return (
    <Switch>
      {/* <AnonRoute path="/sandbox">*/}
      {/*  <Sandbox />*/}
      {/* </AnonRoute>*/}
      <AnonRoute exact path="/">
        <SignIn />
      </AnonRoute>
      <AnonRoute path="/login">
        <SignIn />
      </AnonRoute>
      {/* <AnonRoute path="/sign-up-old" exact>*/}
      {/*  <StartPage />*/}
      {/* </AnonRoute>*/}
      <AnonRoute path="/sign-up">
        <SignUpNew />
      </AnonRoute>
      {/* <AnonRoute path="/sign-up-old/:type">*/}
      {/*  <SignUp />*/}
      {/* </AnonRoute>*/}
      <AnonRoute path="/confirm-email-resend">
        <EmailConformation />
      </AnonRoute>
      <AnonRoute path="/confirm-email">
        <AfterEmailConfirmation />
      </AnonRoute>
      <AnonRoute path="/forgot-password">
        <ForgotPassword />
      </AnonRoute>
      <AnonRoute path="/reset-password">
        <ResetPassword />
      </AnonRoute>
      <AnonRoute path="/complete-signup">
        <CompleteProfile />
      </AnonRoute>

      {/* <PrivateRoute path="/winners-timeline">*/}
      {/*  <WinnerTimelines />*/}
      {/* </PrivateRoute>*/}
      {/* <PrivateRoute path="/test-tooltip-page">*/}
      {/*  <TestTooltipPage />*/}
      {/* </PrivateRoute>*/}
      {/* <PrivateRoute path="/my-templates">*/}
      {/*  <MyTemplates />*/}
      {/* </PrivateRoute>*/}
      {/* <PrivateRoute path="/dashboard">*/}
      {/*  <Dashboard />*/}
      {/* </PrivateRoute>*/}
      {/* <PrivateRoute path="/tests/" exact>*/}
      {/*  <Tests />*/}
      {/* </PrivateRoute>*/}
      {/* <PrivateRoute path="/tests/:testId/:testType">*/}
      {/*  <Test />*/}
      {/* </PrivateRoute>*/}
      {/* <PrivateRoute path="/tests/run-winning">*/}
      {/*  <LaunchWinningCreative />*/}
      {/* </PrivateRoute>*/}
      {/* <PrivateRoute path="/tests/find-best-audience">*/}
      {/*  <FindBestAudience />*/}
      {/* </PrivateRoute>*/}
      {/* <PrivateRoute path="/create-test">*/}
      {/*  <CreateTest />*/}
      {/* </PrivateRoute>*/}
      {/* <PrivateRoute path="/create-audiences">*/}
      {/*  <CreateAudiences />*/}
      {/* </PrivateRoute>*/}
      {/* <PrivateRoute path="/creative-studio/image-library">*/}
      {/*  <ImageLibrary />*/}
      {/* </PrivateRoute>*/}
      <PrivateRoute path="/profile">
        <MyProfile />
      </PrivateRoute>
      <PrivateRoute path="/confirm-invite">
        <MyProfile completeInviteMode />
      </PrivateRoute>
      {/* <PrivateRoute path="/account-assets/:tabName">*/}
      {/*  <AccountAssets />*/}
      {/* </PrivateRoute>*/}
      {/* <PrivateRoute path="/marketing-integration">*/}
      {/*  <MarketingIntegration />*/}
      {/* </PrivateRoute>*/}
      <PrivateRoute path="/organizations">
        <Organizations />
      </PrivateRoute>
      <PrivateRoute path="/organization/:tabName">
        <MyTeam />
      </PrivateRoute>
      <PrivateRoute path="/team-member/:memberId">
        <TeamMemberDetails />
      </PrivateRoute>
      <PrivateRoute path="/connect-facebook">
        <ConnectFacebook />
      </PrivateRoute>
      <PrivateRoute path="/upgrade-plan">
        <Switch>
          <Route path="/upgrade-plan/billing-address">
            <BillingAddress />
          </Route>
          <Route path="/upgrade-plan/credit-card-details">
            <CreditCardDetails />
          </Route>
          <Route path="/upgrade-plan" exact>
            <ChoosePlan />
          </Route>
          <Redirect to="/upgrade-plan" />
        </Switch>
      </PrivateRoute>
      {/* <PrivateRoute path="/my-plan">*/}
      {/*  <MyPlan />*/}
      {/* </PrivateRoute>*/}
      <PrivateRoute path="/import-assets-audiences">
        <ImportAssetsAudiences />
      </PrivateRoute>
      {/* <PrivateRoute path="/audiences/:tabName">*/}
      {/*  <Audiences />*/}
      {/* </PrivateRoute>*/}
      {/* <PrivateRoute path="/platforms/facebook">*/}
      {/*  <Switch>*/}
      {/*    <Route path="/platforms/facebook/:tabName">*/}
      {/*      <Facebook />*/}
      {/*    </Route>*/}
      {/*    <Redirect to="/platforms/facebook/pages" />*/}
      {/*  </Switch>*/}
      {/* </PrivateRoute>*/}
      {/* <PrivateRoute path={MY_UPLOADS_ROUTE}>*/}
      {/*  <MyUploads />*/}
      {/* </PrivateRoute>*/}
      {/* <PrivateRoute path={MY_CREATIVES_ROUTE}>*/}
      {/*  <MyCreatives />*/}
      {/* </PrivateRoute>*/}
      {/* <PrivateRoute path="/instant-creative">*/}
      {/*  <Switch>*/}
      {/*    <Route path="/instant-creative/generate-creatives">*/}
      {/*      <GenerateCreatives />*/}
      {/*    </Route>*/}
      {/*    <Redirect to="/my-creatives" />*/}
      {/*  </Switch>*/}
      {/* </PrivateRoute>*/}
      <PrivateRoute path="/no-organizations">
        <NoOrganizationState />
      </PrivateRoute>
      <PrivateRoute path="/create-organization">
        <CreateOrganization />
      </PrivateRoute>
      <Route path="/privacy-policy">
        <PrivacyPolicy />
      </Route>
      <Route path="/terms-of-service">
        <TermsOfService />
      </Route>
      {/* <PrivateRoute path="/empty">*/}
      {/*  <EmptyPage />*/}
      {/* </PrivateRoute>*/}
      <PrivateRoute path="/checkout-success">
        <CheckoutSuccess />
      </PrivateRoute>
      <PrivateRoute path="/campaign">
        <Switch>
          <Route path="/campaign/create">
            <CreateCampaignItem />
          </Route>
          <Route path="/campaign/:campaignID/create-campaign-brief">
            <CreateCampaignBriefItem />
          </Route>
          <Route path="/campaign/:campaignID/campaign-brief/:briefItemID/create-creator-brief">
            <CreateCampaignBriefItem />
          </Route>
          <Route path="/campaign/:campaignID/edit">
            <EditCampaignItem />
          </Route>
          <Route path="/campaign/:campaignID/campaign-brief/:briefItemID/edit">
            <EditCampaignBriefItem />
          </Route>
          <Route path="/campaign/:campaignID/campaign-brief/:briefItemID/creator-brief/:creatorBriefID/edit">
            <EditCampaignBriefItem />
          </Route>
          <Route path="/campaign/:campaignID/campaign-brief/:briefItemID/duplicate">
            <DuplicateCampaignItem />
          </Route>
          <Route path="/campaign/:campaignID/campaign-brief/:briefItemID/creator-brief/:creatorBriefID/duplicate">
            <DuplicateCampaignItem />
          </Route>
          <Route path="/campaign/:campaignID/campaign-brief/:briefItemID/creator-brief/:creatorBriefID">
            <BriefItemPage />
          </Route>
          <Route path="/campaign/:campaignID/campaign-brief/:briefItemID">
            <BriefItemPage />
          </Route>
          <Route path="/campaign/:campaignID">
            <CampaignBriefPage />
          </Route>
          <Route path="/campaign" exact>
            <CampaignBriefList />
          </Route>
          <Redirect to="/campaign" />
        </Switch>
      </PrivateRoute>
      <PrivateRoute path="/content-approval">
        <Switch>
          <Route path="/content-approval/create">
            <ContentApprovalCreateBoard />
          </Route>
          <Route path="/content-approval" exact>
            <ContentApprovalList />
          </Route>
          <Redirect to="/content-approval" />
        </Switch>
      </PrivateRoute>
      <PrivateRoute path="/content-board">
        <Switch>
          <Route
            path={['/content-board/:boardId', '/content-board/:boardId/deliverables/:deliverableId']}
            render={() => <ContentBoardAndDeliverableRoutesWrapper />}
          />
          <Redirect to="/content-approval" />
        </Switch>
      </PrivateRoute>

      {/* <Route path="/templates/fashion">*/}
      {/*  <FashionTemplates />*/}
      {/* </Route>*/}
      {/* <Route path="/templates/real-estate">*/}
      {/*  <RealEstateTemplates />*/}
      {/* </Route>*/}
      {/* <Route path="/templates/simple/minimalist-bohemian">*/}
      {/*  <MinimalistBohemianTemplates />*/}
      {/* </Route>*/}
      {/* <Route path="/templates/simple/an-ode-to-art-deco">*/}
      {/*  <AnOdeToArtDecoTemplates />*/}
      {/* </Route>*/}
      {/* <Route path="/templates/simple/acidic">*/}
      {/*  <AcidicTemplates />*/}
      {/* </Route>*/}
      {/* <Route path="/templates/simple/captured-collections">*/}
      {/*  <CapturedCollectionsTemplates />*/}
      {/* </Route>*/}
      {/* <Route path="/templates/simple/cookie-cutters">*/}
      {/*  <CookieCuttersTemplates />*/}
      {/* </Route>*/}
      {/* <Route path="/templates/simple/graphic-pop">*/}
      {/*  <GraphicPopTemplates />*/}
      {/* </Route>*/}
      {/* <Route path="/templates/testimonials">*/}
      {/*  <TestimonialsTemplates />*/}
      {/* </Route>*/}
      {/* <Route path="/templates/restaurants">*/}
      {/*  <RestaurantsTemplates />*/}
      {/* </Route>*/}
      {/* <Route path="/templates/christmas">*/}
      {/*  <ChristmasTemplates />*/}
      {/* </Route>*/}
      {/* <Route path="/templates/events">*/}
      {/*  <EventsTemplates />*/}
      {/* </Route>*/}
      {/* <Route path="/templates/valentine">*/}
      {/*  <ValentineTemplates />*/}
      {/* </Route>*/}
      {/* <Route path="/templates/motherDay">*/}
      {/*  <MothersDayTemplates />*/}
      {/* </Route>*/}
      {/* <Route path="/templates/easter">*/}
      {/*  <EasterTemplates />*/}
      {/* </Route>*/}

      <Redirect to="/" />
    </Switch>
  );
};

export default Routes;
