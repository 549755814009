import React from 'react';

const ContactSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <g opacity="0.7">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5 0C19.5376 0 22 2.46243 22 5.5V16.5C22 19.5376 19.5376 22 16.5 22H5.5C2.46244 22 3.62106e-06 19.5376 0 16.5V5.49999C3.62106e-06 2.46243 2.46244 0 5.5 0H16.5ZM17.3393 14.2463C16.6801 13.3674 15.6474 12.796 14.4608 12.796H7.53898C5.56133 12.796 3.95724 14.4001 3.95724 16.3778V17.3653C3.95724 18.0904 4.55053 18.7057 5.29765 18.7057H16.7021C17.4272 18.7057 18.0425 18.1124 18.0425 17.3653V16.3778C18.0425 15.5867 17.7788 14.8396 17.3393 14.2463ZM13.6681 10.2686C13.0748 10.8619 12.3057 11.2574 11.4487 11.3453C11.2949 11.3673 11.1411 11.3673 10.9873 11.3673C9.93251 11.3673 8.98763 10.9498 8.30644 10.2466C7.62525 9.56542 7.18577 8.59857 7.18577 7.56579C7.18577 6.51105 7.60328 5.56617 8.30644 4.88498C8.98763 4.20379 9.95448 3.76431 10.9873 3.76431C12.9869 3.76431 14.6129 5.30248 14.7668 7.25816C14.7887 7.36802 14.7887 7.47789 14.7887 7.58775V7.58777C14.7887 8.64252 14.3712 9.58739 13.6681 10.2686Z"
          fill="#46CFB0"
        />
      </g>
    </svg>
  );
};

export default ContactSVG;
