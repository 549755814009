import React from 'react';
import styles from './DiscussionBackground.module.scss';
import { setDiscussionsIsExpanded } from '../../../../actions/discussion.actions';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';

const DiscussionBackground = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const isExpanded = useSelector(state => state.discussion.isExpanded);

  return <>{!isExpanded && isMobile && <div className={styles.root} onClick={() => dispatch(setDiscussionsIsExpanded(true))} />}</>;
};

export default DiscussionBackground;
