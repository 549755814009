import React from 'react';
import styles from './AttachmentImageTile.module.scss';
import { CloseSmallSvg } from '../../../../IconsSvg/CloseSmallSvg';

const AttachmentImageTile = ({ attachment, onDelete }) => {
  return (
    <div className={styles.attachmentItem}>
      <img width={100} height={100} src={attachment.fileUrl} alt={attachment.file.name} />
      <div className={styles.removeImage} onClick={() => onDelete(attachment.helperFileId)}>
        <CloseSmallSvg />
      </div>
    </div>
  );
};

export default AttachmentImageTile;
