import React, { useEffect, useRef, useState } from 'react';
import styles from './PreviewStorySlider.module.scss';
import InstagramStoryPreview from '../PreviewCard/InstagramStoryPreview';
import { ReactComponent as TriangleIcon } from '../../assets/img/icons/play-white-arrow.svg';

const PreviewStorySlider = ({ assets, callToAction, initialIndex = 0 }) => {
  const [index, setIndex] = useState(initialIndex);
  const [justForRerender, setJustForRerender] = useState(false);
  const refAssets = useRef(assets);

  useEffect(() => {
    refAssets.current = assets;
  }, [assets]);

  const prevStory = idx => {
    const prevIndex = idx - 1;
    setIndex(prevIndex < 0 ? assets.length - 1 : prevIndex);
  };

  const nextStory = idx => {
    const nextIndex = idx + 1;
    const isNextIndexZero = nextIndex >= refAssets.current.length;
    if (isNextIndexZero && refAssets.current.length === 1) {
      setJustForRerender(!justForRerender);
    }
    setIndex(isNextIndexZero ? 0 : nextIndex);
  };

  return (
    <div className={styles.root}>
      <InstagramStoryPreview
        assets={assets}
        callToAction={callToAction}
        currentIndex={index}
        setIndex={setIndex}
        onStoryEnd={nextStory}
        disableDefaultControls
      />
      {!!assets?.length && (
        <div className={styles.footer}>
          <div className={styles.title}>Preview</div>
          <div className={styles.prevButton} onClick={() => prevStory(index)}>
            <TriangleIcon />
          </div>
          <span>{`${index + 1} / ${assets.length}`}</span>
          <div className={styles.nextButton} onClick={() => nextStory(index)}>
            <TriangleIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export default PreviewStorySlider;
