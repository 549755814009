import React from 'react';
import styles from './ReleaseNewsPage.module.scss';
import ButtonCustom from '../../../../../expert/components/Buttons/ButtonCustom';
import {ReactComponent as ArrowLeft} from '../../../../../assets/img/icons/calendar_arrow_left.svg';
import LoaderReleaseNewsItem from '../LoaderReleaseNewsItem';
import ReleaseNewsItem from '../ReleaseNewsItem';

const ReleaseNewsPage = ({isLoading, release, updates, goBack, handleTellUsClick}) => {
  return (
    <>
      <div className={styles.titleContainer}>
        <ButtonCustom className={styles.btn} Icon={ArrowLeft} outline onClick={goBack} />
        <div className={styles.title}>
          {release.title2}
        </div>
      </div>

      {isLoading ? (
        <LoaderReleaseNewsItem />
      ) : updates && updates.map(updateData => (
        <ReleaseNewsItem key={updateData.id} data={updateData} />
      ))}

      <div className={styles.releasesFooter}>
        <div>
          Have an idea or feature?
          <span className={styles.footerLink} onClick={handleTellUsClick}>Do tell us</span>
        </div>
      </div>
    </>
  );
};

export default ReleaseNewsPage;
