import React from 'react';
import styles from './ImageUploadProgressBar.module.scss';
import cs from 'classnames';

const ImageUploadProgressBar = ({ current, of }) => {
  const defaultTrack = (current/of) * 100;
  return (
    <div className={styles.root}>
      <div className={styles.progressBar}>
        <div className={cs(styles.track)} style={{width: `${defaultTrack}%`, display: `${!defaultTrack ? 'none' : 'flex'}`}}/>
      </div>
    </div>
  );
};

export default ImageUploadProgressBar;
