import React from 'react';
import styles from './SectionStatusesContainer.module.scss';
import ApprovedStatusTile from '../Header/statuses/ApprovedStatusTile';
import ButtonCustom from '../../../../../expert/components/Buttons/ButtonCustom';
import { useSelector } from 'react-redux';
import LoadingContainer from '../../../../../components/LoadingContainer';
import { DELIVERABLE_STATUSES } from '../../hooks/useStatusesState';

const SectionStatusesContainer = ({ status, concept, isChanged, isVisualExists, onSave, onApprove, sectionName }) => {
  const { approveLoading, deliverableItemUpdateLoading, deliverableLoading } = useSelector(state => state.contentApproval);

  return (
    <div className={styles.root}>
      {deliverableItemUpdateLoading || deliverableLoading ? (
        <LoadingContainer width="200px" borderRadius="5px" />
      ) : (
        <>
          {status === DELIVERABLE_STATUSES.APPROVED ? (
            <ApprovedStatusTile outline text={`${sectionName} Approved`} />
          ) : (
            !isChanged &&
            (isVisualExists || !!concept) && (
              <ButtonCustom disabled={approveLoading || deliverableItemUpdateLoading} className={styles.approveButton} onClick={onApprove}>
                Approve {sectionName}
              </ButtonCustom>
            )
          )}
          {isChanged && (
            <ButtonCustom disabled={approveLoading || deliverableItemUpdateLoading} className={styles.saveButton} onClick={onSave}>
              Save changes
            </ButtonCustom>
          )}
        </>
      )}
    </div>
  );
};

export default SectionStatusesContainer;
