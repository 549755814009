import React from 'react';


const AppScripts = () => {
  return (
    <>
      <div id="scriptSmartAds">
        <div id="ss_survey_widget" />
      </div>
      <div id="scriptNPS">
        <div id="ss_survey_widget" />
      </div>
    </>
  )
};

export default AppScripts;
