import React from 'react';
import styles from './SortButtons.module.scss';
import { ReactComponent as TriangleSvg } from '../../../../../../assets/img/icons/play-white-arrow.svg';
import cs from 'classnames';

const SortButtons = ({ orderBy, isActive, onSort }) => {
  const handleSortClick = orderType => {
    if (isActive(orderType)) {
      onSort('', '');
    } else {
      onSort(orderBy, orderType);
    }
  };
  return (
    <div className={styles.sortIcons}>
      <TriangleSvg className={cs({ [styles.active]: isActive('asc') })} onClick={() => handleSortClick('asc')} />
      <TriangleSvg className={cs({ [styles.active]: isActive('desc') })} onClick={() => handleSortClick('desc')} />
    </div>
  );
};

export default SortButtons;
