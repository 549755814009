import React from 'react';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import FacebookPreview from '../../../../components/PreviewCard/FacebookPreview';
import PreviewStorySlider from '../../../../components/PreviewStorySlider';
import InstagramPreview from '../../../../components/PreviewCard/InstagramPreview';
import SimpleVideoPreview from '../../../../components/PreviewCard/SimpleVideoPreview';
import FormattedText from '../../../CampaignBrief/CampaignBriefPage/components/FormattedText';
import { DELIVERABLE_ASSET_STATUSES } from '../constants';
import VideoConvertingPlaceholderImage from '../../../../assets/img/video-converting-placeholder.png';

const createAssetConditionsAndUrl = asset => {
  const isVideo = asset && /^video/g.test(asset.file ? asset.file.type : asset.mime_type);
  const isVideoProcessing = isVideo && asset.status === DELIVERABLE_ASSET_STATUSES.PROCESSING;
  const url = isVideoProcessing ? VideoConvertingPlaceholderImage : isVideo ? asset.preview_location || asset?.location : asset?.location;
  return { isVideo, isVideoProcessing, url };
};

const createPreviewStorySliderItem = asset => {
  const { isVideoProcessing, url } = createAssetConditionsAndUrl(asset);
  return {
    url: url,
    mime_type: isVideoProcessing ? 'image/png' : asset.mime_type,
    copy: <FormattedText text={asset.caption} withoutCollapse />,
  };
};

const createPreviewUrl = (asset, fullInfo = false) => {
  const fullInfoWithUrl = createAssetConditionsAndUrl(asset);
  if (fullInfo) return fullInfoWithUrl;
  return fullInfoWithUrl.url;
};

const settingsConfig = {
  facebook: {
    static_post: {
      visualsMaxCount: 10,
      fileTypes: ['image'],
      isStoryTile: false,
      PreviewComponent: ({ assets, callToAction }) => (
        <FacebookPreview
          callToAction={callToAction}
          primaryText={<FormattedText text={assets?.[0]?.caption} withoutCollapse />}
          preview={assets.map(createPreviewUrl)}
        />
      ),
    },
    video_post: {
      visualsMaxCount: 1,
      fileTypes: ['video'],
      isStoryTile: false,
      PreviewComponent: ({ assets, callToAction }) => {
        const { url, isVideoProcessing } = createAssetConditionsAndUrl(assets?.[0]);
        return (
          <FacebookPreview
            preview={url}
            callToAction={callToAction}
            primaryText={<FormattedText text={assets?.[0]?.caption} withoutCollapse />}
            isVideoPreview={!isVideoProcessing}
          />
        );
      },
    },
    story_post: {
      visualsMaxCount: 10,
      fileTypes: ['image', 'video'],
      isStoryTile: true,
      PreviewComponent: ({ assets, callToAction, initialIndex }) => (
        <PreviewStorySlider callToAction={callToAction} assets={assets.map(createPreviewStorySliderItem)} initialIndex={initialIndex}/>
      ),
    },
    reel: {
      visualsMaxCount: 1,
      fileTypes: ['video'],
      isStoryTile: true,
      PreviewComponent: ({ assets, callToAction }) => {
        const { url, isVideoProcessing } = createAssetConditionsAndUrl(assets?.[0]);
        return (
          <SimpleVideoPreview
            preview={url}
            isPlaceholder={isVideoProcessing}
            callToAction={callToAction}
            primaryText={<FormattedText text={assets?.[0]?.caption} withoutCollapse />}
          />
        );
      },
    },
  },
  instagram: {
    static_post: {
      visualsMaxCount: 1,
      fileTypes: ['image'],
      isStoryTile: false,
      PreviewComponent: ({ assets, callToAction }) => (
        <InstagramPreview
          preview={createPreviewUrl(assets?.[0])}
          callToAction={callToAction}
          primaryText={<FormattedText text={assets?.[0]?.caption} withoutCollapse />}
        />
      ),
    },
    carousel: {
      visualsMaxCount: 10,
      fileTypes: ['image', 'video'],
      isStoryTile: false,
      PreviewComponent: ({ assets, callToAction }) => (
        <InstagramPreview
          preview={assets.map(asset => createPreviewUrl(asset, true))}
          callToAction={callToAction}
          primaryText={<FormattedText text={assets?.[0]?.caption} withoutCollapse />}
        />
      ),
    },
    video_post: {
      visualsMaxCount: 1,
      fileTypes: ['video'],
      isStoryTile: false,
      PreviewComponent: ({ assets, callToAction }) => {
        const { url, isVideoProcessing } = createAssetConditionsAndUrl(assets?.[0]);
        return (
          <InstagramPreview
            preview={url}
            callToAction={callToAction}
            primaryText={<FormattedText text={assets?.[0]?.caption} withoutCollapse />}
            isVideoPreview={!isVideoProcessing}
          />
        );
      },
    },
    story_post: {
      visualsMaxCount: 10,
      fileTypes: ['image', 'video'],
      isStoryTile: true,
      PreviewComponent: ({ assets, callToAction, initialIndex }) => (
        <PreviewStorySlider callToAction={callToAction} assets={assets.map(createPreviewStorySliderItem)} initialIndex={initialIndex}/>
      ),
    },
    highlight: {
      visualsMaxCount: 10,
      fileTypes: ['image', 'video'],
      isStoryTile: true,
      PreviewComponent: ({ assets, callToAction, initialIndex }) => (
        <PreviewStorySlider callToAction={callToAction} assets={assets.map(createPreviewStorySliderItem)} initialIndex={initialIndex}/>
      ),
    },
    live: {
      visualsMaxCount: 1,
      fileTypes: ['video'],
      isStoryTile: true,
      PreviewComponent: ({ assets, callToAction }) => {
        const { url, isVideoProcessing } = createAssetConditionsAndUrl(assets?.[0]);
        return (
          <SimpleVideoPreview
            preview={url}
            isPlaceholder={isVideoProcessing}
            callToAction={callToAction}
            primaryText={<FormattedText text={assets?.[0]?.caption} withoutCollapse />}
          />
        );
      },
    },
    reel: {
      visualsMaxCount: 1,
      fileTypes: ['video'],
      isStoryTile: true,
      PreviewComponent: ({ assets, callToAction }) => {
        const { url, isVideoProcessing } = createAssetConditionsAndUrl(assets?.[0]);
        return (
          <SimpleVideoPreview
            preview={url}
            isPlaceholder={isVideoProcessing}
            callToAction={callToAction}
            primaryText={<FormattedText text={assets?.[0]?.caption} withoutCollapse />}
          />
        );
      },
    },
  },
  youtube: {
    video_post: {
      visualsMaxCount: 1,
      fileTypes: ['video'],
      isStoryTile: false,
      PreviewComponent: ({ assets, callToAction }) => {
        const { url, isVideoProcessing } = createAssetConditionsAndUrl(assets?.[0]);
        return (
          <SimpleVideoPreview
            orientation="landscape"
            preview={url}
            isPlaceholder={isVideoProcessing}
            callToAction={callToAction}
            primaryText={<FormattedText text={assets?.[0]?.caption} withoutCollapse />}
          />
        );
      },
    },
  },
  tiktok: {
    video_post: {
      visualsMaxCount: 1,
      fileTypes: ['video'],
      isStoryTile: true,
      PreviewComponent: ({ assets, callToAction }) => {
        const { url, isVideoProcessing } = createAssetConditionsAndUrl(assets?.[0]);
        return (
          <SimpleVideoPreview
            preview={url}
            isPlaceholder={isVideoProcessing}
            callToAction={callToAction}
            primaryText={<FormattedText text={assets?.[0]?.caption} withoutCollapse />}
          />
        );
      },
    },
  },
};

const availablePlatforms = ['facebook', 'instagram', 'youtube', 'tiktok'];
const availablePlacements = {
  facebook: ['static_post', 'video_post', 'story_post', 'reel'],
  instagram: ['static_post', 'video_post', 'story_post', 'carousel', 'highlight', 'live', 'reel'],
  youtube: ['video_post'],
  tiktok: ['video_post'],
};

const usePlatformPlacementSettings = () => {
  const { openedDeliverable } = useSelector(state => state.contentApproval);
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    if (openedDeliverable && openedDeliverable.platform && openedDeliverable.placement) {
      if (
        !availablePlatforms.includes(openedDeliverable.platform) ||
        !availablePlacements[openedDeliverable.platform].includes(openedDeliverable.placement)
      ) {
        return;
      }

      setSettings(settingsConfig[openedDeliverable.platform][openedDeliverable.placement]);
    }
  }, [openedDeliverable]);

  return { settings };
};

export default usePlatformPlacementSettings;
