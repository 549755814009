import { TESTS_TYPES } from '../actions/tests.actions';

const initialState = {
  data: [],
  totalCount: 0,
  totalWinnerCount: 0,
  loading: false,
  loadingTestAds: false,
  loadingWinnerAd: false,
  failure: {},
  refresh: false,
  changeStatus: false,
  test: {},
  testAds: [],
  winnerAd: [],
};

export default (state = initialState, { type, payload, totalCount }) => {
  switch (type) {
    case TESTS_TYPES.CREATE_TEST.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case TESTS_TYPES.CREATE_TEST.SUCCESS: {
      return {
        ...state,
        data: [...state.data, payload],
        totalCount: state.totalCount + 1,
        loading: false,
      };
    }
    case TESTS_TYPES.CREATE_TEST.FAILURE: {
      return {
        ...state,
        loading: false,
        failure: payload,
      };
    }
    case TESTS_TYPES.GET_TESTS.REQUEST: {
      return {
        ...state,
        data: [],
        loading: true,
      };
    }
    case TESTS_TYPES.GET_TESTS.SUCCESS: {
      return {
        ...state,
        data: payload,
        totalCount: totalCount,
        loading: false,
      };
    }
    case TESTS_TYPES.GET_TESTS.FAILURE: {
      return {
        ...state,
        loading: false,
        failure: payload,
      };
    }
    case TESTS_TYPES.GET_TEST.REQUEST: {
      return {
        ...state,
        test: {},
        loading: true,
      };
    }
    case TESTS_TYPES.GET_TEST.SUCCESS: {
      return {
        ...state,
        test: payload,
        loading: false,
      };
    }
    case TESTS_TYPES.GET_TEST.FAILURE: {
      return {
        ...state,
        loading: false,
        test: {},
        failure: payload,
      };
    }
    case TESTS_TYPES.GET_TEST_FULL.REQUEST: {
      return {
        ...state,
        test: {},
        loading: true,
      };
    }
    case TESTS_TYPES.GET_TEST_FULL.SUCCESS: {
      return {
        ...state,
        test: payload,
        loading: false,
      };
    }
    case TESTS_TYPES.GET_TEST_FULL.FAILURE: {
      return {
        ...state,
        loading: false,
        test: {},
        failure: payload,
      };
    }
    case TESTS_TYPES.GET_TEST_ADS.REQUEST: {
      return {
        ...state,
        testAds: [],
        loadingTestAds: true,
      };
    }
    case TESTS_TYPES.GET_TEST_ADS.SUCCESS: {
      return {
        ...state,
        testAds: payload,
        loadingTestAds: false,
      };
    }
    case TESTS_TYPES.GET_TEST_ADS.FAILURE: {
      return {
        ...state,
        loadingTestAds: false,
        testAds: [],
        failure: payload,
      };
    }
    case TESTS_TYPES.DELETE_TEST.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case TESTS_TYPES.DELETE_TEST.SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case TESTS_TYPES.DELETE_TEST.FAILURE: {
      return {
        ...state,
        loading: false,
        failure: payload,
      };
    }
    case TESTS_TYPES.REFRESH_TESTS.REQUEST: {
      return {
        ...state,
        refresh: true,
      };
    }
    case TESTS_TYPES.REFRESH_TESTS.SUCCESS: {
      const ids = payload.map(t => t.id);
      const data = state.data.map(test => (ids.includes(test.id) ? payload.find(t => t.id === test.id) : test));
      return {
        ...state,
        refresh: false,
        data,
      };
    }
    case TESTS_TYPES.REFRESH_TESTS.FAILURE: {
      return {
        ...state,
        refresh: false,
        failure: payload,
      };
    }
    case TESTS_TYPES.CHANGE_STATUS_TESTS.REQUEST: {
      return {
        ...state,
        changeStatus: true,
      };
    }
    case TESTS_TYPES.CHANGE_STATUS_TESTS.SUCCESS: {
      const ids = payload.map(t => t.id);
      const data = state.data.map(test => (ids.includes(test.id) ? payload.find(t => t.id === test.id) : test));
      return {
        ...state,
        changeStatus: false,
        data,
      };
    }
    case TESTS_TYPES.CHANGE_STATUS_TESTS.FAILURE: {
      return {
        ...state,
        changeStatus: false,
        failure: payload,
      };
    }
    case TESTS_TYPES.GET_WINNER_ADS.REQUEST: {
      return {
        ...state,
        test: {},
        loadingWinnerAd: true,
      };
    }
    case TESTS_TYPES.GET_WINNER_ADS.SUCCESS: {
      return {
        ...state,
        winnerAd: payload,
        totalWinnerCount: totalCount,
        loadingWinnerAd: false,
      };
    }
    case TESTS_TYPES.GET_WINNER_ADS.FAILURE: {
      return {
        ...state,
        loadingWinnerAd: false,
        failure: payload,
      };
    }
    default: {
      return state;
    }
  }
};
