import React from 'react';
import PropTypes from 'prop-types';
import ButtonCustom from '../../Buttons/ButtonCustom';
import styles from './AgreeModal.module.scss';
import { ModalSmall as Modal } from '..';

export const AgreeContent = ({ title, message, onSubmit, onClose, agreeText, cancelText }) => {
  return (
    <>
      {title ? <p className={styles.title}>{title}</p> : null}
      {message ? <p className={styles.text}>{message}</p> : null}
      <div className={styles.controls}>
        <div className={styles.col}>
          <ButtonCustom outline onClick={onClose}>
            {cancelText || 'Cancel'}
          </ButtonCustom>
        </div>
        <div className={styles.col}>
          <ButtonCustom onClick={onSubmit}>{agreeText || 'Okay'}</ButtonCustom>
        </div>
      </div>
    </>
  );
};

const AgreeModal = props => {
  return (
    <Modal {...props}>
      <AgreeContent {...props} />
    </Modal>
  );
};

AgreeModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onSubmit: PropTypes.func,
  agreeText: PropTypes.string,
  cancelText: PropTypes.string,
  ...Modal.propTypes,
};

export default AgreeModal;
