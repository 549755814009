import React, { useEffect, useState } from 'react';
import styles from './TagBlock.module.scss';
import TextField from '../../../../components/FormControls/TextField';
import { CloseSvg } from '../../../../expert/components/IconsSvg/CloseSvg';
import cs from 'classnames';
import ButtonCustom from '../../../../expert/components/Buttons/ButtonCustom';

// disableSpace means new tag is not added on space press. also stops removing blanks from value
function TagBlock({ title, tags, addTag, removeTag, placeholder, className, disableSpace = false, isDisplayButtonAdd }) {
  const [tagValue, setTagValue] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (tagValue.length >= 100) setError('Please write less than 100 characters');
    else setError('');
  }, [tagValue]);

  const addTagHandler = () => {
    let filteredTag;
    if (disableSpace) {
      filteredTag = tagValue.replace(/[^\w ]/g, '').replace(/\s/g, '');
    } else {
      filteredTag = tagValue.replace(/[^\w ]/g, '');
    }
    if (filteredTag.length > 0) {
      addTag(filteredTag);
      setTagValue('');
    }
  };

  const onKeyUp = e => {
    // add if press enter (13) // if press comma (188) // if press space (32) but if disableSpace is true
    if (!error && (e.keyCode === 13 || e.keyCode == 188 || (disableSpace && e.keyCode == 32))) {
      addTagHandler(tagValue);
    }
  };

  return (
    <div className={styles.root}>
      <div className={cs(styles.tagCategories, [{ className: className }])}>
        {title && <h3 className={styles.tagCategoriesHeader}>{title}</h3>}
        <div style={{ position: 'relative' }}>
          <TextField
            placeholder={placeholder}
            onChange={e => setTagValue(e.target.value)}
            onKeyUp={e => onKeyUp(e)}
            value={tagValue}
            className={styles.input}
          />
          {isDisplayButtonAdd && (
            <ButtonCustom
              onClick={addTagHandler}
              className={cs(styles.addButton, { [styles.disabledActive]: !tagValue })}
              disabled={!tagValue || !!error}
              clearBg
            >
              Add
            </ButtonCustom>
          )}
        </div>
        {error && <div className={styles.error}>{error}</div>}
        {tags.length > 0 && (
          <div className={styles.tags}>
            {tags.map(tag => (
              <div className={styles.tag} key={tag}>
                <span>{tag}</span>
                <span onClick={() => removeTag(tag)}>
                  <CloseSvg />
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default TagBlock;
