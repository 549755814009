import * as adIntegrationServices from '../services/ad_integrations';

export const AD_INTEGRATIONS_TYPES = {
  CREATE_AD_INTEGRATION: {
    REQUEST: 'CREATE_AD_INTEGRATION_REQUEST',
    SUCCESS: 'CREATE_AD_INTEGRATION_SUCCESS',
    FAILURE: 'CREATE_AD_INTEGRATION_FAILURE',
  },
  DELETE_AD_INTEGRATION: {
    REQUEST: 'DELETE_AD_INTEGRATION_REQUEST',
    SUCCESS: 'DELETE_AD_INTEGRATION_SUCCESS',
    FAILURE: 'DELETE_AD_INTEGRATION_FAILURE',
  },
  GET_AD_INTEGRATIONS: {
    REQUEST: 'GET_AD_INTEGRATIONS_REQUEST',
    SUCCESS: 'GET_AD_INTEGRATIONS_SUCCESS',
    FAILURE: 'GET_AD_INTEGRATIONS_FAILURE',
  },
  CLEAR_FAILURE: 'CLEAR_FAILURE',
  IS_FIRST_CONNECTION: 'IS_FIRST_CONNECTION',
};

export const createAdIntegration = (type, params, organizationId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: AD_INTEGRATIONS_TYPES.CREATE_AD_INTEGRATION.REQUEST,
      payload: {},
    });
    const currentOrg = getState().auth.currentOrganization; // JSON.parse(localStorage.getItem('OTTERFISH_CURRENT_ORGANIZATION'));
    const data = await adIntegrationServices.createAdIntegration(organizationId || currentOrg.organization_id, type, params);

    dispatch({
      type: AD_INTEGRATIONS_TYPES.CREATE_AD_INTEGRATION.SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: AD_INTEGRATIONS_TYPES.CREATE_AD_INTEGRATION.FAILURE,
      payload: error.response.data,
    });
  }
};

export const deleteAdIntegration = id => async (dispatch, getState) => {
  try {
    dispatch({
      type: AD_INTEGRATIONS_TYPES.DELETE_AD_INTEGRATION.REQUEST,
      payload: {},
    });
    const currentOrg = getState().auth.currentOrganization;
    const data = await adIntegrationServices.deleteAdIntegration(id);

    const updatedTestCreationFlows = JSON.parse(localStorage.getItem('OTTERFISH_TEST_CREATION_DRAFT_V1')) || {};
    updatedTestCreationFlows[currentOrg.organization_id] = null;
    localStorage.setItem('OTTERFISH_TEST_CREATION_DRAFT_V1', JSON.stringify(updatedTestCreationFlows));

    await dispatch(getAdIntegrations());
    dispatch({
      type: AD_INTEGRATIONS_TYPES.DELETE_AD_INTEGRATION.SUCCESS,
    });
    return data;
  } catch (error) {
    dispatch({
      type: AD_INTEGRATIONS_TYPES.DELETE_AD_INTEGRATION.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getAdIntegrations = orgId => async (dispatch, getState) => {
  try {
    dispatch({
      type: AD_INTEGRATIONS_TYPES.GET_AD_INTEGRATIONS.REQUEST,
    });
    const currentOrg = getState().auth.currentOrganization;
    const data = await adIntegrationServices.getAdIntegrations(orgId || currentOrg?.organization_id);

    dispatch({
      type: AD_INTEGRATIONS_TYPES.GET_AD_INTEGRATIONS.SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: AD_INTEGRATIONS_TYPES.GET_AD_INTEGRATIONS.FAILURE,
      payload: error?.response?.data,
    });
  }
};

export const clearFailure = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: AD_INTEGRATIONS_TYPES.CLEAR_FAILURE,
    });
  } catch (err) {}
};

export const setFirstConnection = () => async (dispatch, getState) => {
  try {
    const fbIntegration = getState().ad_integration.ad_integrations

    dispatch({
      type: AD_INTEGRATIONS_TYPES.IS_FIRST_CONNECTION,
      payload: !fbIntegration.length
    });
  } catch (err) {}
}
