import React from 'react';
import styles from './ButtonCustom.module.scss';
import cs from 'classnames';
import Loader from '../../Loader';

const ButtonCustom = ({
  children,
  disabled,
  loading,
  mobileSmall,
  shortTitle,
  className,
  Icon,
  SecondIcon,
  onSecondIconHandler,

  outline,
  danger,
  warning,
  primary,
  success,
  secondary,
  purple,
  parentWidth,
  iconSize,
  clearBg,
  type = 'button',
  ...props
}) => {
  let propClassName;
  if (success) propClassName = styles.success;
  else if (warning) propClassName = styles.warning;
  else if (danger) propClassName = styles.danger;
  else if (primary) propClassName = styles.primary;
  else if (secondary) propClassName = styles.secondary;
  else if (purple) propClassName = styles.purple;
  propClassName = cs(propClassName, outline ? styles.outline : styles.regular);

  return (
    <button
      type={type}
      className={cs(
        styles.btn,
        propClassName,
        {
          [styles.mobileSmall]: mobileSmall,
          [styles.parentWidth]: parentWidth,
          [styles.clearBg]: clearBg,
          [styles.btnShortTitle]: shortTitle,
        },
        className
      )}
      disabled={disabled || loading}
      {...props}
    >
      <span className={styles.iconBlock}>
        {loading ? <Loader mainColor={'#f8f8fb'} className={styles.loader} /> : Icon ? <Icon size={iconSize ? iconSize : 20} /> : null}
        {SecondIcon ? (
          <span
            onClick={e => {
              e.stopPropagation();
              onSecondIconHandler();
            }}
          >
            <SecondIcon size={iconSize ? iconSize : 20} />
          </span>
        ) : null}
      </span>

      <span className={styles.text}>{children}</span>
      {shortTitle && <span className={styles.shortTitle}>{shortTitle}</span>}
    </button>
  );
};

export default ButtonCustom;
