/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styles from './MyProfile.module.scss';
import avatar from '../../assets/img/no-avatar_for_my_profile.svg';
import DropDownEditAvatar from '../../expert/containers/DropDowns/DropDownEditAvatar';
import EditSVG from '../../expert/components/IconsSvg/EditSVG';
import { useDispatch, useSelector } from 'react-redux';
import UnlockSVG from '../../expert/components/IconsSvg/UnlockSVG';
import {
  updateUser,
  patchUser,
  removeProfilePicture,
  getUser,
  getInviteDetails,
  clearFailure,
  acceptInvite,
  getUserUsage,
} from '../../actions/user.actions';
import { getTimezones } from '../../actions/config.actions';
import MainLayout from '../../components/MainLayout';
import Button from '../../expert/components/Buttons/ButtonCustom';
import TextField from '../../components/FormControls/TextField';
import * as Yup from 'yup';
import { PHONE_MASK } from '../../shared/constants/masks';
import ErrorModal from '../../expert/components/Modal/ErrorModal';
import LoaderModal from '../../expert/components/Modal/LoaderModal';
import AvatarUploadModal from '../../expert/components/Modal/AvatarUploadModal';
import OrgInviteModal from '../../components/Modals/OrgInviteModal';
import ExpiredInviteModal from '../../components/Modals/ExpiredInviteModal';
import Dropdown from '../../components/FormControls/Dropdown';
import { me } from '../../actions/auth.actions';
import { useHistory, useLocation } from 'react-router-dom';
import RadioButton from '../../components/FormControls/RadioButton';
import cs from 'classnames';
import ChangePasswordForm from '../../expert/containers/Forms/ChangePasswordForm';
import BriefInviteModal from '../../components/Modals/BriefInviteModal';
import LoadingContainer from '../../components/LoadingContainer';
import TeamDetailsLoading from '../MyTeam/TeamDetails/TeamDetailsLoading';
import { useMediaQuery } from 'react-responsive/src';
import PhoneInput from 'react-phone-input-2';
import DoesNotExistInviteModal from '../../components/Modals/DoesNotExistInviteModal';

const VALIDATION_SCHEMAS = {
  name: Yup.string().required().min(2, 'Length must be at least 2 characters').required('Name cannot be empty'),
  email: Yup.string().email('Email is not valid').required('Email cannot be empty'),
  phoneNumber: Yup.string().matches(PHONE_MASK, 'Phone number is not valid').required('Phone number cannot be empty'),
  birth_date: Yup.date()
    .transform((val, original) => new Date(original))
    .max(new Date(), 'The date of birth must be earlier than today date'),
  gender: Yup.string().required(),
  timezone: Yup.object().required(),
};

const MyProfile = ({ completeInviteMode }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const user = useSelector(state => state.user.data);
  const authUser = useSelector(state => state.auth.user);
  const loading = useSelector(state => state.user.loading);
  const updateLoading = useSelector(state => state.user.updateLoading);
  const timezones = useSelector(state => state.config.timezones?.data);
  const configLoading = useSelector(state => state.config.loading);
  const inviteDetails = useSelector(state => state.user.invite?.data);
  const loadingInvite = useSelector(state => state.user.invite?.loading);
  const errorInvite = useSelector(state => state.user.invite?.failure);
  const errorUser = useSelector(state => state.user.failure);

  const [editingField, setEditingField] = useState();
  const [editingValue, setEditingValue] = useState();
  const [error, setError] = useState(false);
  const [inviteError, setInviteError] = useState(errorInvite);
  const [avatarUploadOpen, setAvatarUploadOpen] = useState(false);
  const [uploadingAvatar, setUploadingAvatar] = useState(false);
  const [isOpenAcceptInviteModal, setIsOpenAcceptInviteModal] = useState(false);
  const [isOpenExpiredInviteModal, setIsOpenExpiredInviteModal] = useState(false);
  const [isOpenAlreadyAcceptedModal, setIsOpenAlreadyAcceptedModal] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const params = new URLSearchParams(location.search);
  const inviteType = params.get('inviteType');
  const isMobile = useMediaQuery({ query: '(max-width: 575px)' });
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  const onCloseEdit = () => {
    setEditingField(undefined);
    setEditingValue(undefined);
    if (editingField === 'timezone') {
      dispatch(getTimezones());
    }
  };

  const handleSubmitEdit = async () => {
    const schema = VALIDATION_SCHEMAS[editingField];
    try {
      schema && (await schema.validate(editingValue));
      if (editingField === 'timezone') {
        await dispatch(updateUser(user.id, { email: user.email, timezone_id: editingValue.id }));
        dispatch(me());
      } else {
        await dispatch(updateUser(user.id, { email: user.email, [editingField]: editingValue }));
        dispatch(me());
      }
    } catch (err) {
      setError(err);
    }
    onCloseEdit();
  };

  const handleSubmitPassword = form => {
    form.email = user.email;
    form.firstName = user.first_name;
    dispatch(updateUser(user.id, form));
  };

  const onAddAvatar = async file => {
    try {
      setUploadingAvatar(true);
      if (file && file.length > 0) {
        const res = await dispatch(
          patchUser(user.id, {
            photo: await fetch(file).then(r => r.blob()),
          })
        );
      }
    } catch (error) {
    } finally {
      setAvatarUploadOpen(false);
      setUploadingAvatar(false);
    }
  };

  const handleAcceptModalActions = async boolValue => {
    if (boolValue) {
      try {
        const inviteToken = params.get('inviteToken');
        await dispatch(acceptInvite(user.id, inviteToken, inviteType));
        await dispatch(me());
        history.push('/profile');
      } catch (e) {
        setError(e);
      }
    }
    setIsOpenAcceptInviteModal(false);
  };

  const handleAcceptBriefModalInvite = async org => {
    if (org) {
      localStorage.setItem('OTTERFISH_CURRENT_ORGANIZATION', JSON.stringify(org));
      dispatch({
        type: 'SET_CURRENT_ORGANIZATION',
        payload: org,
      });
      try {
        const inviteToken = params.get('inviteToken');
        await dispatch(acceptInvite(user.id, inviteToken, inviteType, org.organization_id)).then(async response => {
          await dispatch(me());
          if (response.campaign_brief_id) {
            if (response.parent_id) {
              history.push(
                `/campaign/${response.campaign_brief_id}/campaign-brief/${response.parent_id}/creator-brief/${response.campaign_brief_item_id}`
              );
            } else {
              history.push(`/campaign/${response.campaign_brief_id}/campaign-brief/${response.campaign_brief_item_id}`);
            }
          }
          if (response.content_board_id) {
            history.push(`/content-board/${response.content_board_id}`);
          }
        });
      } catch (e) {
        setError(e);
      }
    }
    setIsOpenAcceptInviteModal(false);
  };

  useEffect(() => {
    if (!timezones.length) dispatch(getTimezones());
  }, [user]);

  useEffect(() => {
    if (authUser.pricing_plan_id) dispatch(getUserUsage());
  }, [authUser]);

  useEffect(() => {
    if (completeInviteMode && Object.keys(inviteDetails).length > 0) {
      if (inviteDetails.accepted_at) {
        setIsOpenAlreadyAcceptedModal(true);
      } else {
        const now = Date.now();
        const expiresAt = Date.parse(inviteDetails.expires_at);
        if (now < expiresAt) {
          setIsOpenAcceptInviteModal(true);
        } else {
          setIsOpenExpiredInviteModal(true);
        }
      }
    }
  }, [inviteDetails]);

  useEffect(() => {
    if (completeInviteMode) {
      const inviteToken = params.get('inviteToken');
      dispatch(getInviteDetails(inviteToken, inviteType));
    }
  }, []);

  useEffect(() => {
    setInviteError(errorInvite);
  }, [errorInvite]);

  return (
    <MainLayout title="My Profile" contentClassName={styles.removePadding} coloredHeader>
      <div className={styles.head}>
        <div className={styles.board}>
          {!user.id || loading ? (
            <div className={styles.loadingHeader}>
              <LoadingContainer
                width={isTablet || isMobile ? '70px' : '100px'}
                height={isTablet || isMobile ? '70px' : '100px'}
                borderRadius={'50%'}
                backgroundColor="rgba(255, 255, 255, 0.2)"
                margin={isTablet ? '0 10px 0 0' : '0 15px 0 0'}
              />
              <LoadingContainer
                width={isMobile ? '150px' : '200px'}
                height={isTablet || isMobile ? '20px' : '28px'}
                borderRadius={'20px'}
                backgroundColor="rgba(255, 255, 255, 0.2)"
              />
            </div>
          ) : (
            <>
              <div className={styles.avatar}>
                {user.photo_location ? (
                  <img src={user.photo_location} alt="avatar" />
                ) : (
                  <div className={styles.emptyAvatar}>
                    <img src={avatar} alt="avatar" />
                  </div>
                )}
                <div className={styles.editAvatar}>
                  <DropDownEditAvatar
                    onOpenModalDropzone={() => setAvatarUploadOpen(true)}
                    onRemove={() => dispatch(removeProfilePicture(user.id)).then(() => dispatch(getUser(user.id)))}
                    hasImage={!!user.photo_location}
                  >
                    <div className={styles.btnEdit}>
                      <EditSVG />
                    </div>
                  </DropDownEditAvatar>
                </div>
              </div>
              <div className={styles.nameUser}>
                {user.first_name} {user.last_name}
              </div>
            </>
          )}
        </div>
      </div>
      {!user.id || loading ? (
        <TeamDetailsLoading value={6} height={'92px'} />
      ) : (
        <div className={styles.body}>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <div className={styles.header}>
                <span>First name</span>
                <div
                  className={styles.btn}
                  onClick={() => {
                    setEditingField('firstName');
                    setEditingValue(user.first_name);
                  }}
                >
                  <EditSVG />
                  <div>Edit</div>
                </div>
              </div>
              {editingField === 'firstName' && (
                <EditField onSubmit={handleSubmitEdit} onClose={onCloseEdit} name={'First name'}>
                  <TextField
                    value={editingValue}
                    onChange={e => setEditingValue(e.target.value)}
                    className={cs(styles.textField, { [styles.textFieldNoValue]: editingValue === '' }, styles.v2)}
                  />
                </EditField>
              )}
            </div>
            <div className={styles.value}>{user.first_name}</div>
          </div>

          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <div className={styles.header}>
                <span>Last name</span>
                <div
                  className={styles.btn}
                  onClick={() => {
                    setEditingField('lastName');
                    setEditingValue(user.last_name);
                  }}
                >
                  <EditSVG />
                  <div>Edit</div>
                </div>
              </div>
              {editingField === 'lastName' && (
                <EditField onSubmit={handleSubmitEdit} onClose={onCloseEdit} name={'Last name'}>
                  <TextField
                    value={editingValue}
                    onChange={e => setEditingValue(e.target.value)}
                    className={cs(styles.textField, { [styles.textFieldNoValue]: editingValue === '' }, styles.v2)}
                  />
                </EditField>
              )}
            </div>
            <div className={styles.value}>{user.last_name}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <div className={styles.header}>
                <span>Email</span>
                <div
                  className={styles.btn}
                  onClick={() => {
                    setEditingField('email');
                    setEditingValue(user.email);
                  }}
                >
                  <EditSVG />
                  <div>Edit</div>
                </div>
              </div>
              {editingField === 'email' && (
                <EditField onSubmit={handleSubmitEdit} onClose={onCloseEdit} name={'Email'}>
                  <TextField
                    value={editingValue}
                    onChange={e => setEditingValue(e.target.value)}
                    className={cs(styles.textField, { [styles.textFieldNoValue]: editingValue === '' }, styles.v2)}
                  />
                </EditField>
              )}
            </div>
            <div className={styles.value}>{user.email}</div>
          </div>

          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <div className={styles.header}>
                <span>Phone number</span>
                <div
                  className={styles.btn}
                  onClick={() => {
                    setEditingField('phoneNumber');
                    setEditingValue(user.phone_number);
                  }}
                >
                  <EditSVG />
                  <div>Edit</div>
                </div>
              </div>
              {editingField === 'phoneNumber' && (
                <EditField onSubmit={handleSubmitEdit} onClose={onCloseEdit} name={'Phone number'}>
                  <PhoneInput
                    value={editingValue}
                    onChange={setEditingValue}
                    containerClass={styles.containerClass}
                    buttonClass={styles.buttonClass}
                  />
                </EditField>
              )}
            </div>
            <div className={styles.value}>{user.phone_number}</div>
          </div>

          <div className={styles.item}>
            <div className={styles.header}>
              <span>Password</span>
              <div className={styles.btnPass} onClick={() => setEditingField('changePassword')}>
                <UnlockSVG />
                Change
              </div>
            </div>
            <div className={styles.password}>
              {[...Array(22).keys()].map((_, index) => (
                <div key={index} className={styles.blackPoint}></div>
              ))}
            </div>
            {editingField === 'changePassword' && (
              <div className={styles.changePassword}>
                <ChangePasswordForm onCloseModal={() => setEditingField('')} onSubmitPassword={handleSubmitPassword} />
              </div>
            )}
          </div>

          <div className={styles.item}>
            <div className={styles.header}>
              <span>Time zone</span>
              <div
                className={styles.btn}
                onClick={() => {
                  setEditingField('timezone');
                  setEditingValue(timezones.filter(timezone => timezone.id === user.timezone_id)[0]);
                }}
              >
                <EditSVG />
                <div>Edit</div>
              </div>
              {editingField === 'timezone' && (
                <EditField onSubmit={handleSubmitEdit} onClose={onCloseEdit} name={'Timezone'} openDropdown={openDropdown}>
                  <Dropdown
                    setOpenDropdown={setOpenDropdown}
                    withSearching
                    onClearSearch={() => dispatch(getTimezones())}
                    onSubmitSearch={filter => dispatch(getTimezones(filter))}
                    searchLoading={configLoading}
                    items={timezones}
                    getId={i => i.id}
                    getDisplayValue={i => {
                      return `${i.name} UTC${i.utc_offset_hours_minutes} `;
                    }}
                    placeholder="Choose timezone"
                    renderItem={DropdownItem}
                    selectedItem={editingValue}
                    onSelect={i => {
                      setEditingValue(i);
                      dispatch(getTimezones());
                    }}
                    className={styles.dropdown}
                    v2
                  />
                </EditField>
              )}
            </div>
            <div className={styles.value}>{timezones.filter(timezone => timezone.id === user.timezone_id)[0]?.name || '-'}</div>
          </div>
        </div>
      )}

      {!loadingInvite &&
        Object.keys(inviteDetails).length > 0 &&
        (inviteType === 'campaign_brief_item' || inviteType === 'content_board' ? (
          <BriefInviteModal
            open={isOpenAcceptInviteModal}
            onClose={() => setIsOpenAcceptInviteModal(false)}
            onSubmit={handleAcceptBriefModalInvite}
            inviteDetails={inviteDetails}
          />
        ) : (
          <OrgInviteModal
            open={isOpenAcceptInviteModal}
            onClose={() => setIsOpenAcceptInviteModal(false)}
            onSubmit={handleAcceptModalActions}
            orgName={inviteDetails.organization_name}
          />
        ))}
      {!loadingInvite && Object.keys(inviteDetails).length > 0 && (
        <ExpiredInviteModal
          open={isOpenExpiredInviteModal}
          orgData={inviteDetails}
          onSubmit={() => setIsOpenExpiredInviteModal(false)}
          onClose={() => setIsOpenExpiredInviteModal(false)}
        />
      )}
      <DoesNotExistInviteModal open={!!inviteError?.message} onClose={() => setInviteError(false)} />
      <ErrorModal open={!!errorUser?.message} onClose={() => dispatch(clearFailure())} text={errorUser?.errors?.body[0]?.message} />
      <ErrorModal open={error} onClose={() => setError(false)} text={error?.message} />
      <ErrorModal
        open={isOpenAlreadyAcceptedModal}
        onClose={() => setIsOpenAlreadyAcceptedModal(false)}
        text={'Invite was already accepted!'}
      />
      <LoaderModal open={updateLoading || uploadingAvatar || loadingInvite} />
      <AvatarUploadModal onUpload={onAddAvatar} open={avatarUploadOpen} onClose={() => setAvatarUploadOpen(false)} />
    </MainLayout>
  );
};

const EditField = ({ name, onClose, children, onSubmit, openDropdown }) => {
  return (
    <div className={styles.popup}>
      <div className={styles.leftBlock}>
        <label>{name}</label>
        <div className={styles.inputContainer}>{children}</div>
      </div>
      <div className={cs(styles.buttonsContainer, { [styles.buttonsContainerDropdown]: openDropdown })}>
        <div className={styles.buttons}>
          <Button className={styles.save} onClick={onSubmit}>
            Save
          </Button>
          <div className={styles.cancelBtn} onClick={onClose}>
            Cancel
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;

const DropdownItem = (i, checked) => {
  return (
    <div className={styles.dropdownItem}>
      <RadioButton checked={checked} />
      <span>{i}</span>
    </div>
  );
};
