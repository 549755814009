import React from 'react';
import CampaignBriefCell from '../../../../components/Cells/CampaignBriefCell';
import LoadingState from '../LoadingState';

const BriefsList = ({ briefs, briefsLoading, sort, setSort, isTablet, onArchive }) => {
  return (
    <>
      {!isTablet && <CampaignBriefCell tableHeader sort={sort} setSort={setSort} />}
      {briefsLoading ? (
        <>
          {[...Array(10).keys()].map(index => (
            <LoadingState index={index} key={index} isTablet={isTablet} />
          ))}
        </>
      ) : (
        <>
          {briefs.map(item => (
            <CampaignBriefCell key={item.id} brief={item} isTablet={isTablet} onArchive={onArchive} />
          ))}
        </>
      )}
    </>
  );
};

export default BriefsList;
