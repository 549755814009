import * as configServices from '../services/config';

export const CONFIG_TYPES = {
  GET_AGE_INTERVAL: {
    REQUEST: 'GET_AGE_INTERVAL_REQUEST',
    SUCCESS: 'GET_AGE_INTERVAL_SUCCESS',
    FAILURE: 'GET_AGE_INTERVAL_FAILURE',
  },
  GET_BUDGET_INTERVAL: {
    REQUEST: 'GET_BUDGET_INTERVAL_REQUEST',
    SUCCESS: 'GET_BUDGET_INTERVAL_SUCCESS',
    FAILURE: 'GET_BUDGET_INTERVAL_FAILURE',
  },
  GET_INDUSTRIES: {
    REQUEST: 'GET_INDUSTRIES_REQUEST',
    SUCCESS: 'GET_INDUSTRIES_SUCCESS',
    FAILURE: 'GET_INDUSTRIES_FAILURE',
  },
  GET_COUNTRIES: {
    REQUEST: 'GET_COUNTRIES_REQUEST',
    SUCCESS: 'GET_COUNTRIES_SUCCESS',
    FAILURE: 'GET_COUNTRIES_FAILURE',
  },
  GET_COUNTIES: {
    REQUEST: 'GET_COUNTIES_REQUEST',
    SUCCESS: 'GET_COUNTIES_SUCCESS',
    FAILURE: 'GET_COUNTIES_FAILURE',
  },
  GET_CITIES: {
    REQUEST: 'GET_CITIES_REQUEST',
    SUCCESS: 'GET_CITIES_SUCCESS',
    FAILURE: 'GET_CITIES_FAILURE',
  },
  GET_TIMEZONES: {
    REQUEST: 'GET_TIMEZONES_REQUEST',
    SUCCESS: 'GET_TIMEZONES_SUCCESS',
    FAILURE: 'GET_TIMEZONES_FAILURE',
  },
  GET_TESTIMONIALS: {
    REQUEST: 'GET_TESTIMONIALS_REQUEST',
    SUCCESS: 'GET_TESTIMONIALS_SUCCESS',
    FAILURE: 'GET_TESTIMONIALS_FAILURE',
  },
  CLEAR_CITIES: 'CLEAR_CITIES',
};

export const getAgeIntervals = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONFIG_TYPES.GET_AGE_INTERVAL.REQUEST,
      payload: {},
    });

    const data = await configServices.getAgeIntervals();

    dispatch({
      type: CONFIG_TYPES.GET_AGE_INTERVAL.SUCCESS,
      payload: data.data,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: CONFIG_TYPES.GET_AGE_INTERVAL.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getBudgetIntervals = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONFIG_TYPES.GET_BUDGET_INTERVAL.REQUEST,
      payload: {},
    });

    const data = await configServices.getBudgetIntervals();

    dispatch({
      type: CONFIG_TYPES.GET_BUDGET_INTERVAL.SUCCESS,
      payload: data.data,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: CONFIG_TYPES.GET_BUDGET_INTERVAL.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getCities = countyId => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONFIG_TYPES.GET_CITIES.REQUEST,
      payload: {},
    });

    const data = await configServices.getCities(countyId);

    dispatch({
      type: CONFIG_TYPES.GET_CITIES.SUCCESS,
      payload: data.data,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: CONFIG_TYPES.GET_CITIES.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getCounties = countryId => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONFIG_TYPES.GET_COUNTIES.REQUEST,
      payload: {},
    });

    const data = await configServices.getCounties(countryId);

    dispatch({
      type: CONFIG_TYPES.GET_COUNTIES.SUCCESS,
      payload: data.data,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: CONFIG_TYPES.GET_COUNTIES.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getCountries = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONFIG_TYPES.GET_COUNTRIES.REQUEST,
      payload: {},
    });

    const data = await configServices.getCountries();

    dispatch({
      type: CONFIG_TYPES.GET_COUNTRIES.SUCCESS,
      payload: data.data,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: CONFIG_TYPES.GET_COUNTRIES.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getIndustries = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONFIG_TYPES.GET_INDUSTRIES.REQUEST,
      payload: {},
    });

    const data = await configServices.getIndustries();

    dispatch({
      type: CONFIG_TYPES.GET_INDUSTRIES.SUCCESS,
      payload: data.data,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: CONFIG_TYPES.GET_INDUSTRIES.FAILURE,
      payload: error?.response?.data,
    });
  }
};

export const getTimezones = (searchFilter) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONFIG_TYPES.GET_TIMEZONES.REQUEST,
      payload: {},
    });

    const params = {};
    if (searchFilter) params.search_filter = searchFilter;

    const data = await configServices.getTimezones(params);

    dispatch({
      type: CONFIG_TYPES.GET_TIMEZONES.SUCCESS,
      payload: data.data,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: CONFIG_TYPES.GET_TIMEZONES.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getTestimonials = () => async dispatch => {
  try {
    dispatch({
      type: CONFIG_TYPES.GET_TESTIMONIALS.REQUEST,
      payload: {},
    });

    const data = await configServices.getTestimonials();

    dispatch({
      type: CONFIG_TYPES.GET_TESTIMONIALS.SUCCESS,
      payload: data.data,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: CONFIG_TYPES.GET_TESTIMONIALS.FAILURE,
      payload: error,
    });
  }
};

export const clearCities = () => async dispatch => {
    dispatch({
      type: CONFIG_TYPES.CLEAR_CITIES,
      payload: {},
    });
}
