import React from 'react';
import styles from './MenuHeader.module.scss';
import { NavLink } from 'react-router-dom';
import LogoSVG from '../../../IconsSvg/LogoSVG';
import cs from 'classnames';
import {ReactComponent as MinimizedLogo} from '../../../../assets/img/icons/menu-icons/minimized-otter.svg';


const MenuHeader = ({ isMinimized, onBurgerClick }) => {
  return (
    <div className={cs(styles.menuHeader, {[styles.minimized]: isMinimized})}>
      <NavLink to={'/'}>
        <div className={styles.logo}>
          {isMinimized ? (
            <MinimizedLogo />
          ) : (
            <LogoSVG />
          )}
        </div>
      </NavLink>
      <div className={styles.burgerButton} onClick={onBurgerClick}><span /></div>
    </div>
  );
};

export default MenuHeader;
