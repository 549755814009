/* eslint-disable guard-for-in */
/* eslint-disable camelcase */
import * as audiencesServices from '../services/audiences';
import * as assetsServices from '../services/assets';

export const AUDIENCE_CREATION = {
  GET_LOCATIONS: {
    CLEAR: 'GET_LOCATIONS_CLEAR',
    REQUEST: 'GET_LOCATIONS_REQUEST',
    SUCCESS: 'GET_LOCATIONS_SUCCESS',
    FAILURE: 'GET_LOCATIONS_FAILURE',
  },
  GET_TARGETING_CATEGORY: {
    CLEAR: 'GET_TARGETING_CATEGORY_CLEAR',
    REQUEST: 'GET_TARGETING_CATEGORY_REQUEST',
    SUCCESS: 'GET_TARGETING_CATEGORY_SUCCESS',
    FAILURE: 'GET_TARGETING_CATEGORY_FAILURE',
  },
  GET_SOURCES: {
    REQUEST: 'GET_SOURCES_REQUEST',
    SUCCESS: 'GET_SOURCES_SUCCESS',
    FAILURE: 'GET_SOURCES_FAILURE',
  },
  CREATE_AUDIENCE: {
    REQUEST: 'CREATE_AUDIENCE_REQUEST',
    SUCCESS: 'CREATE_AUDIENCE_SUCCESS',
    FAILURE: 'CREATE_AUDIENCE_FAILURE',
  },
  CLEAR_FAILURE: 'CLEAR_FAILURE',
  CLEAR_DATA: 'CLEAR_DATA',
};

export const AUDIENCE_CREATION_ALL_LOCATIONS = [
  'country_group',
  'country',
  'geo_market',
  'region',
  'city',
  'subcity',
  'zip',
  'electoral_district',
  'neighborhood',
  'subneighborhood',
  'small_geo_area',
  'medium_geo_area',
  'large_geo_area',
  'metro_area',
];

export const getLocations = (organizationId, searchFilter, type) => async (dispatch, getState) => {
  try {
    dispatch({
      type: AUDIENCE_CREATION.GET_LOCATIONS.REQUEST,
      payload: {},
    });

    if (!searchFilter?.trim()) {
      dispatch({ type: AUDIENCE_CREATION.GET_LOCATIONS.CLEAR });
      return;
    }

    const currentOrg = getState().auth.currentOrganization;
    const params = {
      organization_id: organizationId || currentOrg?.organization_id,
      page: 1,
      page_size: 100,
    };

    // If type='internal' leave empty type prop
    if (type === 'lookalike') {
      params.type = 'country,country_group';
    } else {
      params.type = AUDIENCE_CREATION_ALL_LOCATIONS.join(',');
    }

    if (searchFilter) params.search_string = searchFilter;

    const response = await audiencesServices.getLocations(params);

    dispatch({
      type: AUDIENCE_CREATION.GET_LOCATIONS.SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: AUDIENCE_CREATION.GET_LOCATIONS.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getTargetingCategory = (organizationId, searchFilter) => async (dispatch, getState) => {
  try {
    dispatch({
      type: AUDIENCE_CREATION.GET_TARGETING_CATEGORY.REQUEST,
      payload: {},
    });

    if (!searchFilter?.trim()) {
      dispatch({ type: AUDIENCE_CREATION.GET_TARGETING_CATEGORY.CLEAR });
      return;
    }

    const currentOrg = getState().auth.currentOrganization;
    const params = {
      organization_id: organizationId || currentOrg?.organization_id,
      page_size: 100,
    };

    if (searchFilter) params.search_string = searchFilter;

    const response = await audiencesServices.getTargetingCategory(params);

    dispatch({
      type: AUDIENCE_CREATION.GET_TARGETING_CATEGORY.SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: AUDIENCE_CREATION.GET_TARGETING_CATEGORY.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getSources =
  (organizationId, type = 'page') =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: AUDIENCE_CREATION.GET_SOURCES.REQUEST,
        payload: {},
      });

      const currentOrg = getState().auth.currentOrganization;
      const params = {
        organization_id: organizationId || currentOrg?.organization_id,
        page_size: 100,
        page: 1,
        type: type,
      };

      const MOCK = [
        { id: '123124123', name: 'wadawds' },
        { id: '123123', name: 'wadawawdawdds' },
        { id: '123', name: 'wada awwds' },
      ];

      let response;

      if (type === 'page') {
        response = await assetsServices.getFacebookPages(null, params);
      } else if (type === 'custom') {
        response = await audiencesServices.getAudiences(null, params);
      }

      // const response = {data: MOCK};

      dispatch({
        type: AUDIENCE_CREATION.GET_SOURCES.SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (error) {
      dispatch({
        type: AUDIENCE_CREATION.GET_SOURCES.FAILURE,
        payload: error.response.data,
      });
    }
  };

export const createAudience = (organization_id, data) => async dispatch => {
  try {
    dispatch({
      type: AUDIENCE_CREATION.CREATE_AUDIENCE.REQUEST,
      payload: {},
    });

    const response = await audiencesServices.createAudience(organization_id, data);
    dispatch({
      type: AUDIENCE_CREATION.CREATE_AUDIENCE.SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: AUDIENCE_CREATION.CREATE_AUDIENCE.FAILURE,
      payload: error.response.data,
    });
  }
};

export const clearAudienceCreationFailure = () => ({
  type: AUDIENCE_CREATION.CLEAR_FAILURE,
});

export const clearCreateAudienceData = () => ({
  type: AUDIENCE_CREATION.CLEAR_DATA,
});
