import { ORGANIZATIONS_TYPES } from '../actions/organizations.actions';

const initialState = {
  data: [],
  failure: {
    message: '',
    name: '',
    code: '',
  },
  loading: false,
  updateLoading: false,
  organizationSearch: {
    data: [],
    loading: false,
  },
  organizationOverview: {
    data: {},
    failure: {
      message: '',
      name: '',
      code: '',
    },
    loading: false,
  },
  organizationMembers: {
    data: [],
    totalCount: 0,
    failure: {
      message: '',
      name: '',
      code: '',
    },
    loading: false,
    loadingUpdate: false,
  },
  invitedMembers: {
    data: [],
    totalCount: 0,
    failure: {
      message: '',
      name: '',
      code: '',
    },
    loading: false,
  },
  allMembers: {
    data: [],
    totalCount: 0,
    failure: {
      message: '',
      name: '',
      code: '',
    },
    loading: false,
  },
};

export default (state = initialState, { type, payload, totalCount }) => {
  switch (type) {
    case ORGANIZATIONS_TYPES.GET_ORGANIZATIONS.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ORGANIZATIONS_TYPES.GET_ORGANIZATIONS.SUCCESS: {
      return {
        ...state,
        data: payload,
        loading: false,
      };
    }
    case ORGANIZATIONS_TYPES.GET_ORGANIZATIONS.FAILURE: {
      return {
        ...state,
        failure: payload,
        loading: false,
      };
    }
    case ORGANIZATIONS_TYPES.SEARCH_ORGANIZATIONS.REQUEST: {
      return { ...state, organizationSearch: { data: [], loading: true } };
    }
    case ORGANIZATIONS_TYPES.SEARCH_ORGANIZATIONS.SUCCESS: {
      return { ...state, organizationSearch: { data: payload.data, loading: false } };
    }
    case ORGANIZATIONS_TYPES.SEARCH_ORGANIZATIONS.FAILURE: {
      return { ...state, organizationSearch: { data: [], loading: false } };
    }
    case ORGANIZATIONS_TYPES.UPDATE_ORGANIZATION.REQUEST: {
      return {
        ...state,
        updateLoading: true,
      };
    }
    case ORGANIZATIONS_TYPES.UPDATE_ORGANIZATION.SUCCESS: {
      const newData = [...state.data];
      const organizationIndex = state.data.findIndex(org => org.id === payload.id);
      newData.splice(organizationIndex, 1, payload);
      return {
        ...state,
        data: newData,
        updateLoading: false,
      };
    }
    case ORGANIZATIONS_TYPES.UPDATE_ORGANIZATION.FAILURE: {
      return {
        ...state,
        failure: payload,
        updateLoading: false,
      };
    }
    case ORGANIZATIONS_TYPES.GET_ORGANIZATION_OVERVIEW.REQUEST: {
      return {
        ...state,
        organizationOverview: {
          ...state.organizationOverview,
          loading: true,
        },
      };
    }
    case ORGANIZATIONS_TYPES.GET_ORGANIZATION_OVERVIEW.SUCCESS: {
      return {
        ...state,
        organizationOverview: {
          ...state.organizationOverview,
          data: payload,
          loading: false,
        },
      };
    }
    case ORGANIZATIONS_TYPES.GET_ORGANIZATION_OVERVIEW.FAILURE: {
      return {
        ...state,
        organizationOverview: {
          ...state.organizationOverview,
          loading: false,
          failure: payload,
        },
      };
    }
    case ORGANIZATIONS_TYPES.GET_ORGANIZATION_MEMBERS.REQUEST: {
      return {
        ...state,
        organizationMembers: {
          ...state.organizationMembers,
          loading: true,
        },
      };
    }
    case ORGANIZATIONS_TYPES.GET_ORGANIZATION_MEMBERS.SUCCESS: {
      return {
        ...state,
        organizationMembers: {
          ...state.organizationMembers,
          data: payload,
          totalCount: totalCount,
          loading: false,
        },
      };
    }
    case ORGANIZATIONS_TYPES.GET_ORGANIZATION_MEMBERS.FAILURE: {
      return {
        ...state,
        organizationMembers: {
          ...state.organizationMembers,
          loading: false,
          failure: payload,
        },
      };
    }
    case ORGANIZATIONS_TYPES.GET_INVITED_MEMBERS.REQUEST: {
      return {
        ...state,
        invitedMembers: {
          ...state.invitedMembers,
          loading: true,
        },
      };
    }
    case ORGANIZATIONS_TYPES.GET_INVITED_MEMBERS.SUCCESS: {
      return {
        ...state,
        invitedMembers: {
          ...state.invitedMembers,
          data: payload,
          totalCount: totalCount,
          loading: false,
        },
      };
    }
    case ORGANIZATIONS_TYPES.GET_INVITED_MEMBERS.FAILURE: {
      return {
        ...state,
        invitedMembers: {
          ...state.invitedMembers,
          loading: false,
          failure: payload,
        },
      };
    }
    case ORGANIZATIONS_TYPES.GET_ALL_MEMBERS.REQUEST: {
      return {
        ...state,
        allMembers: {
          ...state.allMembers,
          loading: true,
        },
      };
    }
    case ORGANIZATIONS_TYPES.GET_ALL_MEMBERS.SUCCESS: {
      return {
        ...state,
        allMembers: {
          ...state.allMembers,
          data: payload,
          totalCount: totalCount,
          loading: false,
        },
      };
    }
    case ORGANIZATIONS_TYPES.GET_ALL_MEMBERS.FAILURE: {
      return {
        ...state,
        allMembers: {
          ...state.allMembers,
          loading: false,
          failure: payload,
        },
      };
    }
    case ORGANIZATIONS_TYPES.CHANGE_USER_STATUS.REQUEST: {
      return {
        ...state,
        organizationMembers: {
          ...state.organizationMembers,
          loadingUpdate: true,
        },
      };
    }
    case ORGANIZATIONS_TYPES.CHANGE_USER_STATUS.SUCCESS: {
      return {
        ...state,
        organizationMembers: {
          ...state.organizationMembers,
          data: payload,
          loadingUpdate: false,
        },
      };
    }
    case ORGANIZATIONS_TYPES.CHANGE_USER_STATUS.FAILURE: {
      return {
        ...state,
        organizationMembers: {
          ...state.organizationMembers,
          loadingUpdate: false,
        },
      };
    }
    case ORGANIZATIONS_TYPES.DELETE_MEMBER_INVITE.REQUEST: {
      return {
        ...state,
        organizationMembers: {
          ...state.organizationMembers,
          loadingUpdate: true,
        },
      };
    }
    case ORGANIZATIONS_TYPES.DELETE_MEMBER_INVITE.SUCCESS: {
      return {
        ...state,
        organizationMembers: {
          ...state.organizationMembers,
          loadingUpdate: false,
        },
      };
    }
    case ORGANIZATIONS_TYPES.DELETE_MEMBER_INVITE.FAILURE: {
      return {
        ...state,
        organizationMembers: {
          ...state.organizationMembers,
          loadingUpdate: false,
          failure: payload,
        },
      };
    }
    case ORGANIZATIONS_TYPES.ADD_ORGANIZATION.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ORGANIZATIONS_TYPES.ADD_ORGANIZATION.FAILURE: {
      return {
        ...state,
        failure: payload,
        loading: false,
      };
    }
    case ORGANIZATIONS_TYPES.ADD_ORGANIZATION.SUCCESS: {
      return {
        ...state,
        data: [...state.data, ...payload],
        loading: false,
      };
    }
    case ORGANIZATIONS_TYPES.CLEAR_FAILURE: {
      return {
        ...state,
        failure: {
          message: '',
          name: '',
          code: '',
        },
      };
    }
    default: {
      return state;
    }
  }
};
