import React, { useRef, useState } from 'react';
import styles from './SearchBar.module.scss';
import cs from 'classnames';
import { ReactComponent as SearchIcon } from '../../assets/img/icons/search.svg';
import { ReactComponent as ClearIcon } from '../../assets/img/icons/close.svg';
import { onClickEnter } from '../../shared/utils/helpers';
import Tag from '../SlidingMenu/Tag';
import EditSVG from '../../expert/components/IconsSvg/EditSVG';
import { useClickOutside } from '../../shared/hooks/useClickOutside';

const SearchBar = ({
  disabled,
  searchValue = '',
  onChangeSearchValue,
  onClear,
  onSubmit,
  placeholder,
  className,
  tags,
  withCash,
  onSelectTag,
  selectedTags,
  onOpenTagsModal,
  onClearDisabled,
  designVersion,
}) => {
  const searchBarRef = useRef(null);
  const cashContainerRef = useRef(null);
  const [historySearchValue, setHistorySearchValue] = useState(JSON.parse(localStorage.getItem('SEARCH_CASH'))?.historySearchValue || []);
  const [openDropdown, setOpenDropdown] = useState(false);

  const onCashItemDelete = element => {
    const history = JSON.parse(localStorage.getItem('SEARCH_CASH'))?.historySearchValue.filter(item => item !== element);
    setHistorySearchValue(history);
    localStorage.setItem('SEARCH_CASH', JSON.stringify({ historySearchValue: history }));
  };

  const handleSubmit = () => {
    onSubmit();
    if (!searchValue) return;
    const cash = JSON.parse(localStorage.getItem('SEARCH_CASH'))?.historySearchValue || [];
    if (!cash.includes(searchValue)) cash.push(searchValue);
    setHistorySearchValue(cash);
    localStorage.setItem('SEARCH_CASH', JSON.stringify({ historySearchValue: cash }));
  };

  const onClickCash = item => {
    onChangeSearchValue(item);
    onSubmit(item);
  };

  const handleClear = e => {
    e.stopPropagation();
    onClear();
  };

  useClickOutside(searchBarRef, cashContainerRef, () => setOpenDropdown(false));

  return (
    <div
      className={cs(styles.search, className, getDesignVersionStyle(designVersion), { [styles.filled]: !!searchValue })}
      ref={searchBarRef}
      onClick={() => setOpenDropdown(true)}
    >
      <input
        onKeyDown={e => onClickEnter(handleSubmit, e)}
        value={searchValue}
        onChange={event => onChangeSearchValue(event.target.value)}
        placeholder={placeholder || 'Search...'}
        disabled={disabled}
      />
      {!onClearDisabled && (
        <div data-search="clear-btn" className={cs(styles.clearSearch, { [styles.hidden]: !searchValue })} onClick={e => handleClear(e)}>
          <ClearIcon />
        </div>
      )}
      <div
        data-search="search-btn"
        className={cs(styles.button, { [styles.disabled]: disabled })}
        onClick={(!disabled && handleSubmit) || undefined}
      >
        <SearchIcon />
      </div>
      {withCash && openDropdown && (
        <div className={styles.dropdown} ref={cashContainerRef}>
          <div className={styles.dropdownTitle}>Recent search</div>
          <div className={styles.cashContainer}>
            {!!historySearchValue.length ? (
              historySearchValue?.map(item => (
                <div key={item} className={styles.cashItems} onClick={() => onClickCash(item)}>
                  <span>{item}</span>
                  <div onClick={() => onCashItemDelete(item)}>Remove</div>
                </div>
              ))
            ) : (
              <div className={styles.noItems}>No items</div>
            )}
          </div>
          {!!tags.length && <div className={cs(styles.dropdownTitle, styles.border)}>Tags:</div>}
          <div className={styles.tagsContainer}>
            <div onClick={onOpenTagsModal}>
              <EditSVG />
              Edit tags
            </div>
            {tags.map(item => (
              <Tag key={item.id} tag={item} onSelect={onSelectTag} selected={selectedTags?.includes(item.id)} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

function getDesignVersionStyle(version) {
  switch (version) {
    case 'V2.0':
      return styles.designV2_0;
    case 'V2.1':
      return styles.designV2_1;
    case 'V2.2':
      return styles.designV2_2;
    case 'V2.3':
      return styles.designV2_3;
    case 'V3.0':
      return styles.designV3_0;
    default:
      return null;
  }
}

export default SearchBar;
