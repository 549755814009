import React from 'react';
import styles from './AttachmentVideoTile.module.scss';
import { CloseSmallSvg } from '../../../../IconsSvg/CloseSmallSvg';
import { BsCameraVideo } from 'react-icons/bs';

const AttachmentVideoTile = ({ attachment, onDelete }) => {
  return (
    <div className={styles.attachmentItem}>
      {attachment.mime_type === 'video/quicktime' ? (
        <div className={styles.icon}>
          <span className={styles.format}>MOV</span>
          <BsCameraVideo />
          <span>{attachment.file.name}</span>
        </div>
      ) : (
        <video width={100} height={100} src={attachment.fileUrl} />
      )}
      <div className={styles.removeVideo} onClick={() => onDelete(attachment.helperFileId)}>
        <CloseSmallSvg />
      </div>
    </div>
  );
};

export default AttachmentVideoTile;
