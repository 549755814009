import * as adsServices from '../services/ads';

export const ADS_TYPES = {
  GET_AD: {
    REQUEST: 'GET_FB_AD_REQUEST',
    SUCCESS: 'GET_FB_AD_SUCCESS',
    FAILURE: 'GET_FB_AD_FAILURE',
  },
  GET_ADS: {
    REQUEST: 'GET_FB_ADS_REQUEST',
    SUCCESS: 'GET_FB_ADS_SUCCESS',
    FAILURE: 'GET_FB_ADS_FAILURE',
  },
  CHANGE_STATUS_ADS: {
    REQUEST: 'CHANGE_STATUS_FB_ADS_REQUEST',
    SUCCESS: 'CHANGE_STATUS_FB_ADS_SUCCESS',
    FAILURE: 'CHANGE_STATUS_FB_ADS_FAILURE',
  },
  REFRESH_ADS: {
    REQUEST: 'REFRESH_FB_ADS_REQUEST',
    SUCCESS: 'REFRESH_FB_ADS_SUCCESS',
    FAILURE: 'REFRESH_FB_ADS_FAILURE',
  },
  GET_OVERVIEW: {
    REQUEST: 'GET_OVERVIEW_REQUEST',
    SUCCESS: 'GET_OVERVIEW_SUCCESS',
    FAILURE: 'GET_OVERVIEW_FAILURE',
  },
};

export const getFBAds = (include_insights, fb_ad_set_ids) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADS_TYPES.GET_ADS.REQUEST,
    });

    const {
      ad_integration: { ad_integrations },
    } = getState();
    const organization_id = ad_integrations.find(ad => ad.type === 'facebook')?.organization_id;
    const params = {};
    if (include_insights) params.include_insights = include_insights;
    if (organization_id) params.organization_id = organization_id;
    if (fb_ad_set_ids.length) params.fb_ad_set_id = fb_ad_set_ids.join(',');

    const res = await adsServices.getAds(null, params);

    dispatch({
      type: ADS_TYPES.GET_ADS.SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: ADS_TYPES.GET_ADS.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getFBAd = (adId, include_insights) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADS_TYPES.GET_AD.REQUEST,
    });

    const params = {};
    if (include_insights) params.include_insights = include_insights;

    const res = await adsServices.getAds(adId, params);

    dispatch({
      type: ADS_TYPES.GET_AD.SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: ADS_TYPES.GET_AD.FAILURE,
      payload: error.response.data,
    });
  }
};

export const changeStatusAds = (ids, status) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADS_TYPES.CHANGE_STATUS_ADS.REQUEST,
    });

    const {
      ad_integration: { ad_integrations },
    } = getState();

    const organization_id = ad_integrations.find(ad => ad.type === 'facebook')?.organization_id;
    const res = await adsServices.changeStatusAds({ organization_id, ids, status });

    dispatch({
      type: ADS_TYPES.CHANGE_STATUS_ADS.SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: ADS_TYPES.CHANGE_STATUS_ADS.FAILURE,
      payload: error.response.data,
    });
  }
};

export const refreshAds = ids => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADS_TYPES.REFRESH_ADS.REQUEST,
    });

    const {
      ad_integration: { ad_integrations },
    } = getState();

    const organization_id = ad_integrations.find(ad => ad.type === 'facebook')?.organization_id;

    const res = adsServices.refreshAds({ organization_id, ids });

    dispatch({
      type: ADS_TYPES.REFRESH_ADS.SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: ADS_TYPES.REFRESH_ADS.FAILURE,
      payload: error.response.data,
    });
  }
};

export const getOverviewAd = (id, preset, insights_date_start, insights_date_end)  => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADS_TYPES.GET_OVERVIEW.REQUEST,
    })
    const params = {};
    if (insights_date_start) params.insights_date_start = insights_date_start;
    if (insights_date_end) params.insights_date_end = insights_date_end;
    if (preset) params.insights_date_preset = preset;
    if (!preset &&!insights_date_start) params.insights_date_preset = 'last_30d';

    const res = await adsServices.overviewAd(id, params);

    dispatch({
      type: ADS_TYPES.GET_OVERVIEW.SUCCESS,
      payload: res.data[0],
    })
  } catch (error){
    dispatch({
      type: ADS_TYPES.GET_OVERVIEW.FAILURE,
      payload: error.response.data,
    })
  }
}
