import { useFormik } from 'formik';
import * as Yup from 'yup';

const useDeliverablesFormik = values => {
  return useFormik({
    initialValues: {
      deliverables: values.deliverables || [],
      deliverablesFacebook: values.deliverablesFacebook || [],
      deliverablesInstagram: values.deliverablesInstagram || [],
      deliverablesTiktok: values.deliverablesTiktok || [],
      deliverablesYoutube: values.deliverablesYoutube || [],
      socialPlatforms: values.socialPlatforms || [],
      placementsQuantity: values.placementsQuantity || 1,
    },
    validationSchema: Yup.object().shape({
      deliverables: Yup.array()
        .when('socialPlatforms', {
          is: value => value?.length === 0,
          then: Yup.array().min(1, 'Deliverables must have at least one item selected.').required(),
        })
        .test('deliverables', 'Placements quantity should be more more than 1', deliverablesArray => {
          for (const deliverablesArrayElement of deliverablesArray) {
            if (!deliverablesArrayElement.quantity || deliverablesArrayElement.quantity <= 0) {
              return false;
            }
          }
          return true;
        }),
      deliverablesFacebook: Yup.array().when('socialPlatforms', {
        is: value => value && value.find(element => element === 'facebook'),
        then: Yup.array().min(1, 'Deliverables field must have at least one item selected.').required(),
      }),
      deliverablesInstagram: Yup.array().when('socialPlatforms', {
        is: value => value && value.find(element => element === 'instagram'),
        then: Yup.array().min(1, 'Deliverables field must have at least one item selected.').required(),
      }),
      deliverablesTiktok: Yup.array().when('socialPlatforms', {
        is: value => value && value.find(element => element === 'tiktok'),
        then: Yup.array().min(1, 'Deliverables field must have at least one item selected.').required(),
      }),
      deliverablesYoutube: Yup.array().when('socialPlatforms', {
        is: value => value && value.find(element => element === 'youtube'),
        then: Yup.array().min(1, 'Deliverables field must have at least one item selected.').required(),
      }),
    }),
    validateOnMount: true,
    validateOnChange: true,
  });
};

export default useDeliverablesFormik;
