import React, { useState } from 'react';
import styles from './PixelImportCell.module.scss';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import cs from 'classnames';
import { DoneIcon } from '../../../expert/components/IconsSvg/ReactIcons/ReactIcons';
import FacebookSVG from '../../IconsSvg/FacebookSVG';

const PixelImportCell = ({ pixel, onSelect, onRemove, withSelect, selectedList }) => {

  const handleSelect = () => {
    onSelect(pixel.id, pixel.type);
  }

  const forAdd = withSelect && !selectedList.includes(pixel.id);
  const forSelected = withSelect && selectedList.includes(pixel.id);

  const RightButtons = () =>
    <div className={styles.buttonsContainer}>
      {(!withSelect || forSelected) && <button className={styles.button} onClick={() => onRemove(pixel.id)}>Remove</button>}
      {forAdd && <ButtonCustom className={styles.addButton} onClick={handleSelect}>Add</ButtonCustom>}
    </div>

  return (
    <div className={cs(styles.root, {[styles.selected] : selectedList.includes(pixel.id)})}>
      <div className={styles.rootInnerContainer}>
        <div>
          <div className={styles.icon}>
            <FacebookSVG />
            {forSelected && <div className={styles.tick}><DoneIcon /></div>}
          </div>

          <div className={styles.textInfoContainer}>
            <span className={cs(styles.col, styles.name)}>
              {pixel.name}
            </span>
            <span className={cs(styles.col,styles.conversions)}>
              {pixel.conversions?.length || 0} conversions
            </span>
          </div>
        </div>
        {RightButtons()}
      </div>
    </div>
  )
}

export default PixelImportCell;
