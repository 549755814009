import { VISUAL_ASSETS_TYPES } from '../actions/visualAssets.actions';

const initialState = {
  data: [],
  allFolders: [],
  uploadLoading: false,
  deleteLoading: false,
  updateLoading: false,
  loading: false,
  failure: {},
  totalCount: 0,
  specificVisualAsset: {
    data: {},
    loading: false,
    failure: {},
  },
  isShowFeedback: false,
  isShowSmartADS: false
};

export default (state = initialState, { type, payload, totalCount }) => {
  switch (type) {
    case VISUAL_ASSETS_TYPES.GET_VISUAL_ASSETS.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case VISUAL_ASSETS_TYPES.GET_VISUAL_ASSETS.SUCCESS: {
      return {
        ...state,
        data: payload,
        loading: false,
        totalCount: totalCount,
      };
    }
    case VISUAL_ASSETS_TYPES.GET_VISUAL_ASSETS.FAILURE: {
      return {
        ...state,
        failure: payload,
        loading: false,
        totalCount: 0,
      };
    }
    case VISUAL_ASSETS_TYPES.GET_SPECIFIC_VISUAL_ASSET.REQUEST: {
      return {
        ...state,
        specificVisualAsset: {
          ...state.specificVisualAsset,
          loading: true,
        },
      };
    }
    case VISUAL_ASSETS_TYPES.GET_SPECIFIC_VISUAL_ASSET.SUCCESS: {
      return {
        ...state,
        specificVisualAsset: {
          ...state.specificVisualAsset,
          loading: false,
          data: payload,
        },
      };
    }
    case VISUAL_ASSETS_TYPES.GET_SPECIFIC_VISUAL_ASSET.FAILURE: {
      return {
        ...state,
        specificVisualAsset: {
          ...state.specificVisualAsset,
          loading: false,
          failure: payload,
        },
      };
    }
    case VISUAL_ASSETS_TYPES.CLEAR_SPECIFIC_VISUAL_ASSET: {
      return {
        ...state,
        specificVisualAsset: {
          ...state.specificVisualAsset,
          data: {},
        },
      };
    }
    case VISUAL_ASSETS_TYPES.CREATE_FOLDER.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case VISUAL_ASSETS_TYPES.CREATE_FOLDER.SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case VISUAL_ASSETS_TYPES.CREATE_FOLDER.FAILURE: {
      return {
        ...state,
        failure: payload,
        loading: false,
      };
    }
    case VISUAL_ASSETS_TYPES.UPLOAD_IMAGES.REQUEST: {
      return {
        ...state,
        uploadLoading: true,
      };
    }
    case VISUAL_ASSETS_TYPES.UPLOAD_IMAGES.SUCCESS: {
      return {
        ...state,
        uploadLoading: false,
      };
    }
    case VISUAL_ASSETS_TYPES.UPLOAD_IMAGES.FAILURE: {
      return {
        ...state,
        failure: payload,
        uploadLoading: false,
      };
    }
    case VISUAL_ASSETS_TYPES.DELETE_VISUAL_ASSETS.REQUEST: {
      return {
        ...state,
        deleteLoading: true,
      };
    }
    case VISUAL_ASSETS_TYPES.DELETE_VISUAL_ASSETS.SUCCESS: {
      return {
        ...state,
        deleteLoading: false,
      };
    }
    case VISUAL_ASSETS_TYPES.DELETE_VISUAL_ASSETS.FAILURE: {
      return {
        ...state,
        failure: payload,
        deleteLoading: false,
      };
    }
    case VISUAL_ASSETS_TYPES.UPDATE_VISUAL_ASSET.REQUEST: {
      return {
        ...state,
        updateLoading: true,
      };
    }
    case VISUAL_ASSETS_TYPES.UPDATE_VISUAL_ASSET.SUCCESS: {
      return {
        ...state,
        updateLoading: false,
      };
    }
    case VISUAL_ASSETS_TYPES.UPDATE_VISUAL_ASSET.FAILURE: {
      return {
        ...state,
        failure: payload,
        updateLoading: false,
      };
    }
    case VISUAL_ASSETS_TYPES.CLEAR_FAILURE: {
      return {
        ...state,
        loading: false,
        failure: {},
      };
    }
    case VISUAL_ASSETS_TYPES.GET_ALL_FOLDERS.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case VISUAL_ASSETS_TYPES.GET_ALL_FOLDERS.SUCCESS: {
      return {
        ...state,
        allFolders: payload,
        loading: false,
      };
    }
    case VISUAL_ASSETS_TYPES.GET_ALL_FOLDERS.FAILURE: {
      return {
        ...state,
        failure: payload,
        loading: false,
      };
    }
    case VISUAL_ASSETS_TYPES.CLEAR_VISUAL_ASSETS_DATA: {
      return {
        ...state,
        data: [],
        totalCount: 0
      }
    }
    case VISUAL_ASSETS_TYPES.MARK_FEEDBACK.TRUE: {
      return {
        ...state,
        isShowFeedback: true,
      }
    }
    case VISUAL_ASSETS_TYPES.MARK_FEEDBACK.FALSE: {
      return {
        ...state,
        isShowFeedback: false,
      }
    }
    case VISUAL_ASSETS_TYPES.SMART_ADS_FEEDBACK_SURVEY.TRUE: {
      return {
        ...state,
        isShowSmartADS: true,
      }
    }
    case VISUAL_ASSETS_TYPES.SMART_ADS_FEEDBACK_SURVEY.FALSE: {
      return {
        ...state,
        isShowSmartADS: false,
      }
    }
    default: {
      return state;
    }
  }
};
