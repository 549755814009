import React, { useEffect, useState, useMemo } from 'react';
import MainLayout from '../../../components/MainLayout';
import styles from './CampaignBriefPage.module.scss';
import ButtonCustom from '../../../expert/components/Buttons/ButtonCustom';
import cs from 'classnames';
import { AddIcon } from '../../../expert/components/IconsSvg/ReactIcons/ReactIcons';
import SearchBar from '../../../components/SearchBar';
import BriefItemCell from './components/BriefItemCell';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getBrief, getBriefItems } from '../../../actions/brief.actions';
import BriefOverviewSection from '../components/BriefOverviewSection';
import VisualDirectionsSection from '../components/VisualDirectionsSection';
import { briefPageType } from '../briefPageType';
import BriefsBreadcrumbs from '../components/BriefsBreadcrumbs';
import { TYPES_CAMPAIGNS } from '../../../shared/constants/constants';
import BriefHeaderSection from '../components/BriefHeaderSection';
import { useMediaQuery } from 'react-responsive/src';
import { setDiscussionsIsExpanded } from '../../../actions/discussion.actions';
import Breadcrumbs from '../../../components/Breadcrumbs';
import HeaderBreadcrumbs from '../../../components/BriefsAndBoardsFlows/HeaderBreadcrumbs';

function CampaignBriefPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const currentOrganizationID = useSelector(state => state.auth.currentOrganization?.organization_id);
  const userID = useSelector(state => state.auth.user.id);
  const currentBrief = useSelector(state => state.brief.currentBrief.data);
  const currentBriefItems = useSelector(state => state.brief.currentBrief.items);
  const currentBriefCreatorItems = useSelector(state => state.brief.currentBrief.creatorItems);
  const leftSideBar = useSelector(state => state.sidebarMenu.isMinimized);
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const isDesktopLandscape = useMediaQuery({
    query: '(min-width: 992px) and (orientation: landscape)',
  });

  const [searchValue, setSearchValue] = useState('');

  const isShowBriefsSection = useMemo(() => {
    if (currentBriefItems.length == 0 && currentBriefCreatorItems.length > 0) {
      for (const creatorItem of currentBriefCreatorItems) {
        const currentCollaborator = creatorItem.collaborators.find(collaborator => collaborator.organization_id == currentOrganizationID);
        if (currentCollaborator.organization_type != TYPES_CAMPAIGNS.CONTENT_CREATOR) {
          return true;
        }
      }
      return false;
    }
    return true;
  }, [currentBriefItems, currentBriefCreatorItems]);

  useEffect(() => {
    if (currentOrganizationID) {
      dispatch(getBrief(currentOrganizationID, match.params.campaignID, () => history.push('/campaign/')));
      dispatch(getBriefItems(currentOrganizationID, match.params.campaignID, { parent_id: 0 }, () => history.push('/campaign/')));
    }
  }, [currentOrganizationID]);

  const onSearch = () => {
    dispatch(
      getBriefItems(
        currentOrganizationID,
        match.params.campaignID,
        searchValue ? { search_filter: searchValue, parent_id: 0 } : { parent_id: 0 }
      )
    );
  };

  const onClearSearch = () => {
    setSearchValue('');
    dispatch(getBriefItems(currentOrganizationID, match.params.campaignID, { parent_id: 0 }));
  };

  useEffect(() => {
    if (!isMobile) {
      dispatch(setDiscussionsIsExpanded(false));
    } else {
      dispatch(setDiscussionsIsExpanded(true));
    }
  }, []);

  return (
    <>
      <MainLayout
        title={
          <BriefsBreadcrumbs
            currentBrief={currentBrief}
            currentBriefItem={false}
            pageType={briefPageType.CAMPAIGN_ITEM}
            BreadcrumbsComponent={HeaderBreadcrumbs}
          />
        }
        contentClassName={styles.root}
        coloredHeader
      >
        {!isDesktopLandscape && (
          <BriefsBreadcrumbs
            currentBrief={currentBrief}
            currentBriefItem={false}
            pageType={briefPageType.CAMPAIGN_ITEM}
            BreadcrumbsComponent={Breadcrumbs}
          />
        )}
        {currentBrief && userID && (
          <div className={cs(styles.pageContent, { [styles.fixWidth]: !leftSideBar })}>
            <div style={{ width: '100%', height: 'min-content' }}>
              <BriefHeaderSection currentBrief={currentBrief} pageType={briefPageType.CAMPAIGN_ITEM} />
              <BriefOverviewSection currentBrief={currentBrief} isHideEdit={userID != currentBrief.creator_id} />
              <VisualDirectionsSection showAddVisualButton={isShowBriefsSection} />
              {isShowBriefsSection && (
                <div className={styles.briefsSection}>
                  <div className={styles.flexRow}>
                    <div className={styles.leftSide}>
                      <div className={styles.text}>
                        Campaign Briefs <span>{currentBriefItems.length}</span>
                      </div>
                      {!isMobile && (
                        <div className={styles.search}>
                          <SearchBar
                            onSubmit={onSearch}
                            className={cs(styles.search)}
                            placeholder={'Search briefs'}
                            onClear={onClearSearch}
                            searchValue={searchValue}
                            onChangeSearchValue={setSearchValue}
                            designVersion="V2.2"
                          />
                        </div>
                      )}
                    </div>
                    <ButtonCustom
                      onClick={() => history.push(`/campaign/${match.params.campaignID}/create-campaign-brief`)}
                      Icon={AddIcon}
                      className={styles.button}
                    >
                      Add Campaign Brief
                    </ButtonCustom>
                  </div>
                  {!!currentBriefItems.length && (
                    <div className={styles.briefsList}>
                      {currentBriefItems.map(item => (
                        <BriefItemCell data={item} key={item.id} />
                      ))}
                    </div>
                  )}
                </div>
              )}
              {currentBriefCreatorItems.length > 0 && (
                <div className={styles.briefsSection}>
                  <div className={styles.flexRow}>
                    <div className={styles.leftSide}>
                      <div className={styles.text}>
                        Creator Briefs <span>{currentBriefCreatorItems.length}</span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.briefsList}>
                    {currentBriefCreatorItems.map(item => (
                      <BriefItemCell data={item} key={item.id} />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </MainLayout>
    </>
  );
}

export default CampaignBriefPage;
