import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import Routes from './Routes';
import { me } from './actions/auth.actions';
import { getAdIntegrations } from './actions/ad_integration.actions';
import AdBlockDetectedModal from './components/Modals/AddBlockDetectedModal';
import { initDraftCreative } from './actions/generateCreatives.actions';
import AppScripts from './components/AppScripts';
import { getAllPlans } from './actions/payment.action';
import { getPricingPlanForUser, getUser, getUserUsage } from './actions/user.actions';
import ToastNotification from './components/ToastNotification';

const App = () => {
  // Google analytics code start
  ReactGA.initialize('UA-199543875-1', {
    debug: false,
  });
  // Google analytics code end

  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticated = useSelector(state => !!state.auth.token);
  const appCentrifuge = useSelector(state => state.centrifuge.appCentrifuge);
  const JWT = useSelector(state => state.auth?.token?.access_token);
  const organizations = useSelector(state => state.auth.organizations);
  const currentOrgRedux = useSelector(state => state.auth.currentOrganization);
  const user = useSelector(state => state.auth.user);
  const loc = useLocation();
  const currentOrglocalStorage = JSON.parse(localStorage.getItem('OTTERFISH_CURRENT_ORGANIZATION'));
  const preselectedPlan = useSelector(state => state.auth.preselectPlan);

  useEffect(() => {
    if (currentOrgRedux) {
      localStorage.setItem('OTTERFISH_CURRENT_ORGANIZATION', JSON.stringify(currentOrgRedux));
    }
  }, [currentOrgRedux]);

  useEffect(() => {
    dispatch(initDraftCreative());
  }, []);

  useEffect(() => {
    if (!isAuthenticated || !organizations || !organizations.length) {
      return;
    }

    if (!currentOrglocalStorage || !currentOrgRedux) {
      const org = user?.has_valid_subscription ? currentOrgRedux : currentOrglocalStorage;

      if (user?.has_valid_subscription && org?.role === 'owner') {
        dispatch({
          type: 'SET_CURRENT_ORGANIZATION',
          payload: org,
        });
        return;
      }

      const ownerOrg = organizations.find(o => o.role === 'owner');

      const validOrg = organizations.find(o => o.owner_pricing_plan_id);

      let newOrg;
      if (localStorage.getItem('OTTERFISH_CURRENT_ORGANIZATION')) {
        newOrg = JSON.parse(localStorage.getItem('OTTERFISH_CURRENT_ORGANIZATION'));
      } else {
        newOrg = ownerOrg || validOrg || organizations[0];
      }
      dispatch({
        type: 'SET_CURRENT_ORGANIZATION',
        payload: newOrg,
      });
    }
  });

  useEffect(() => {
    const isCompleteSignUp = loc.pathname.includes('/complete-signup');
    if (isAuthenticated && !isCompleteSignUp) {
      dispatch(me());
      if (currentOrgRedux) {
        dispatch(getAllPlans());
        dispatch(getAdIntegrations());
        dispatch(getUser(user.id));
        if (user.pricing_plan_id || currentOrgRedux.owner_pricing_plan_id) {
          dispatch(getPricingPlanForUser());
          if (user.pricing_plan_id) {
            dispatch(getUserUsage());
          }
        }
      }
    }
  }, [isAuthenticated, currentOrgRedux]);

  useEffect(() => {
    if (isAuthenticated && organizations != null) {
      if (organizations.length > 0) {
        const ownerOrg = organizations.find(o => o.role === 'owner');
        const validOrg = organizations.find(o => o.owner_pricing_plan_id);

        const newOrg = ownerOrg || validOrg || organizations[0];
        const userHasPaidPlan = user.pricing_plan_id || newOrg.owner_has_valid_subscription;
        const permittedRoutesWithoutPlan = ['/upgrade-plan', '/checkout-success'];
        // redirect to choose plan page
        if (!userHasPaidPlan && !permittedRoutesWithoutPlan.includes(loc.pathname)) {
          history.push(`/upgrade-plan${preselectedPlan ? '#' + preselectedPlan : ''}`);
        }
      } else {
        const permittedRoutesWithoutOrg = ['/no-organizations', '/profile', '/create-organization'];
        // redirect to the no organization page
        if (!permittedRoutesWithoutOrg.includes(loc.pathname)) {
          history.push('/no-organizations');
        }
      }
    }
  }, [organizations]);

  useEffect(() => {
    if (isAuthenticated && JWT) {
      appCentrifuge.connect(JWT);
    } else {
      appCentrifuge.disconnect();
    }
  }, [isAuthenticated, JWT, appCentrifuge]);

  return (
    <>
      <AdBlockDetectedModal />
      <ToastNotification />
      <Switch>
        <Route path="/" render={() => <Routes />} />
      </Switch>
      <AppScripts />
    </>
  );
};

export default App;
