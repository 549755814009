import { AD_INTEGRATIONS_TYPES } from '../actions/ad_integration.actions';

const initialState = {
  ad_integrations: [],
  isLoading: false,
  isLoadingDelete: false,
  isFirstConnection: false,
  failure: {
    message: '',
    name: '',
    code: '',
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case AD_INTEGRATIONS_TYPES.CREATE_AD_INTEGRATION.SUCCESS: {
      return {
        ...state,
        ad_integrations: payload.data,
      };
    }
    case AD_INTEGRATIONS_TYPES.CREATE_AD_INTEGRATION.FAILURE: {
      return {
        ...state,
        failure: payload,
      };
    }

    case AD_INTEGRATIONS_TYPES.GET_AD_INTEGRATIONS.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case AD_INTEGRATIONS_TYPES.GET_AD_INTEGRATIONS.SUCCESS: {
      return {
        ...state,
        ad_integrations: payload.data,
        isLoading: false,
      };
    }

    case AD_INTEGRATIONS_TYPES.DELETE_AD_INTEGRATION.REQUEST: {
      return {
        ...state,
        isLoadingDelete: true,
      };
    }

    case AD_INTEGRATIONS_TYPES.DELETE_AD_INTEGRATION.SUCCESS: {
      return {
        ...state,
        isLoadingDelete: false,
      };
    }

    case AD_INTEGRATIONS_TYPES.DELETE_AD_INTEGRATION.FAILURE: {
      return {
        ...state,
        isLoadingDelete: false,
      };
    }

    case AD_INTEGRATIONS_TYPES.CLEAR_FAILURE: {
      return {
        ...state,
        failure: {},
      };
    }

    case AD_INTEGRATIONS_TYPES.IS_FIRST_CONNECTION: {
      return {
        ...state,
        isFirstConnection: payload,
      };
    }

    default: {
      return state;
    }
  }
};
