import React from 'react';
import styles from './TabCollaborators.module.scss';
import TextField from '../../../../../components/FormControls/TextField';
import Option from '../../Option';
import Dropdown from '../../../../../components/FormControls/Dropdown';
import { briefPageType } from '../../../briefPageType';
import {
  TYPES_CAMPAIGN_BRIEF_OBJECT,
  TYPES_CAMPAIGNS,
  TYPES_CAMPAIGNS_OBJECTS,
  TYPES_CREATOR_BRIEF_OBJECT,
} from '../../../../../shared/constants/constants';
import cs from 'classnames';

const allowedCategories = [TYPES_CAMPAIGNS.BRAND, TYPES_CAMPAIGNS.AGENCY, TYPES_CAMPAIGNS.TALENT_MANAGER];

function TabCollaborators({ formik, pageType }) {
  const categories = TYPES_CAMPAIGNS_OBJECTS.filter(item => allowedCategories.includes(item.type)).map((item, idx) => ({ ...item }));

  const selectInitiatorType = key => {
    formik.setFieldValue('initiator_organization_type', key);
  };

  const selectOrgType = item => {
    formik.setFieldValue('inviteType', item);
    formik.setFieldValue('inviteTypeValidation', item);
  };

  return (
    <>
      <div className={styles.tabSubHeading}>
        {pageType == briefPageType.CREATOR_BRIEF_ITEM
          ? 'This is where you can invite a content creator to work on your brief. If you are working with multiple content creators, it is advised that you create a creator brief for each content creator separately.'
          : 'This is where you can invite an individual to work with you on your brief. You will be able to invite more people once the brief is created.'}
        <span className={styles.learnMore}> Learn more</span>
      </div>
      <div>
        <h3>
          1. I am a:
          <span>(Required)</span>
        </h3>
        <Dropdown
          placeholder="Type of organization"
          className={styles.dropdown}
          items={categories}
          getId={i => i.type}
          getDisplayValue={i => i.name}
          renderItem={value => <Option value={value} />}
          selectedItem={formik.values.initiator_organization_type}
          onSelect={item => selectInitiatorType(item)}
        />
        {formik.errors.initiator_organization_type && <p className={styles.errorMessage}>{formik.errors.initiator_organization_type}</p>}
        <div className={styles.split} />
        <h3>
          2. I want to collaborate with <span>(Optional)</span>
        </h3>
        <p>
          {pageType == briefPageType.CREATOR_BRIEF_ITEM ? (
            <>
              You can leave this field blank if you want to review the brief before you share it. Please be aware that this brief will only
              be shared between you and your content creator.
            </>
          ) : (
            <>
              You can leave this field blank if you want to review the brief before you share it.{' '}
              <span className={styles.warning}>
                Please be aware, to work directly with a content creator, it is advised to collaborate on a creator brief after you have
                created a campaign brief.
              </span>
            </>
          )}
        </p>
        <div className={styles.flexRow}>
          <TextField
            name={'inviteEmail'}
            placeholder={'Type Email to invite'}
            className={cs(styles.input, styles.inviteEmail)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.inviteEmail?.trim() || ''}
            error={formik.errors.inviteEmail}
          />
          <Dropdown
            placeholder="Type of organization"
            className={styles.dropdown}
            items={pageType === briefPageType.CREATOR_BRIEF_ITEM ? TYPES_CREATOR_BRIEF_OBJECT : TYPES_CAMPAIGN_BRIEF_OBJECT}
            getId={i => i.type}
            getDisplayValue={i => i.name}
            renderItem={value => <Option value={value} />}
            selectedItem={formik.values.inviteType}
            onSelect={i => selectOrgType(i)}
            error={formik.errors.inviteType}
          />
        </div>
      </div>
    </>
  );
}

export default TabCollaborators;
