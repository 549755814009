import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const DELIVERABLE_STATUSES = {
  APPROVED: 'approved',
  IN_PROGRESS: 'in_progress',
  PENDING_CONCEPT: 'pending_concept',
  PENDING_APPROVAL: 'pending_approval',
};

const useStatusesState = () => {
  const organizationId = useSelector(state => state.auth.currentOrganization?.organization_id);
  const { openedDeliverable, deliverableApprovals, collaborators } = useSelector(state => state.contentApproval);

  const [statusesState, setStatusesState] = useState({
    deliverableStatuses: {
      concept: DELIVERABLE_STATUSES.IN_PROGRESS,
      content: DELIVERABLE_STATUSES.IN_PROGRESS,
    },
    conceptStatus: DELIVERABLE_STATUSES.IN_PROGRESS,
    contentStatus: DELIVERABLE_STATUSES.IN_PROGRESS,
  });

  useEffect(() => {
    if (!openedDeliverable || !organizationId) {
      return;
    }

    const generalConcept = openedDeliverable?.status_concept;
    const generalContent = openedDeliverable?.status_content;
    let sectionConcept = generalConcept;
    let sectionContent = generalContent;

    if (deliverableApprovals?.length) {
      const conceptApprovals = [];
      const contentApprovals = [];

      for (const approval of deliverableApprovals) {
        if (approval.approval_type === 'concept') {
          conceptApprovals.push(approval);
        } else {
          contentApprovals.push(approval);
        }
      }

      const isConceptApprovedByCurrentApprover = !!conceptApprovals.find(approval => +approval.organization_id === +organizationId);
      const isContentApprovedByCurrentApprover = !!contentApprovals.find(approval => +approval.organization_id === +organizationId);
      sectionConcept = isConceptApprovedByCurrentApprover ? DELIVERABLE_STATUSES.APPROVED : sectionConcept;
      sectionContent = isContentApprovedByCurrentApprover ? DELIVERABLE_STATUSES.APPROVED : sectionContent;
    }

    setStatusesState({
      deliverableStatuses: {
        concept: generalConcept,
        content: generalContent,
      },
      conceptStatus: sectionConcept,
      contentStatus: sectionContent,
    });
  }, [openedDeliverable, collaborators, organizationId, deliverableApprovals]);

  return statusesState;
};

export default useStatusesState;
