import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const deliverableContentInitValue = {
  concept: '',
  call_to_action: [],
  isChanged: false,
  isConceptChanged: false,
  isCallToActionChanged: false,
  isFilledFromServer: false,
};

const isFieldChanged = (field, nextState, currentState, initChangedValue = false) => {
  let res = initChangedValue;
  const incomeValue = nextState[field];
  const currentValue = currentState[field];
  if (Array.isArray(incomeValue)) {
    if (incomeValue.some(incomeItem => currentValue.every(currentItem => incomeItem !== currentItem))) {
      res = true;
    }
  } else if (currentValue !== incomeValue && !/^is/.test(field)) {
    res = true;
  }
  return res;
};

const isChanged = (nextState, currentState) => {
  let res = false;
  for (const key of Object.keys(nextState)) {
    res = isFieldChanged(key, nextState, currentState, res);
  }
  return res;
};

const useDeliverableGeneralState = () => {
  const { openedDeliverable, deliverableLoading } = useSelector(state => state.contentApproval);
  const [deliverableContent, setDeliverableContent] = useState(deliverableContentInitValue);
  const [initValue, setInitValue] = useState({});

  useEffect(() => {
    if (openedDeliverable && !deliverableLoading) {
      const newInitValue = {
        ...deliverableContentInitValue,
        concept: openedDeliverable.concept || '',
        call_to_action: openedDeliverable.call_to_action ? [...openedDeliverable.call_to_action] : [],
        isFilledFromServer: true,
      };
      setDeliverableContent(newInitValue);
      setInitValue(newInitValue);
    }
  }, [openedDeliverable]);

  const onChangeConcept = value => {
    const nextState = {
      ...deliverableContent,
      concept: value,
    };
    setDeliverableContent({
      ...nextState,
      isChanged: isChanged(nextState, initValue),
      isConceptChanged: isFieldChanged('concept', nextState, initValue),
    });
  };

  const onChangeCallToAction = value => {
    const nextState = {
      ...deliverableContent,
      call_to_action: value ? [value] : [],
    };
    setDeliverableContent({
      ...nextState,
      isChanged: isChanged(nextState, initValue),
      isCallToActionChanged: isFieldChanged('call_to_action', nextState, initValue),
    });
  };

  return { deliverableContent, onChangeConcept, onChangeCallToAction };
};

export default useDeliverableGeneralState;
