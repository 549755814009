import React from 'react';
import Header from '../Header';
import styles from './AuthLayout.module.scss';
import cs from 'classnames';

const AuthLayout = ({ withoutHeader, children, hideMenu }) => {
  return (
    <div className={styles.root}>
      {!withoutHeader && <Header hideMenu={hideMenu}/>}
      <div className={cs(styles.main, withoutHeader && styles.flex)}>{children}</div>
    </div>
  );
};

export default AuthLayout;
