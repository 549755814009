import { instance } from '../shared/utils/api.config.creator';

export const createAdIntegration = async (organization_id, type, params) => {
  const response = await instance.post('/ad-integrations/connect', { organization_id, type, params });
  return response.data;
};

export const deleteAdIntegration = async id => {
  const response = await instance.post(`/ad-integrations/${id}/disconnect`);
  return response.data;
};

export const getAdIntegrations = async orgId => {
  const response = await instance.get('/ad-integrations', { params: { organization_id: orgId } });
  return response.data;
};
