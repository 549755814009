import React, { forwardRef, useState } from 'react';
import styles from './InputPassword.module.scss';
import cs from 'classnames';
import { ReactComponent as EyeClosedIcon } from '../../../assets/img/icons/eye_closed.svg';
import { ReactComponent as EyeIcon } from '../../../assets/img/icons/eye.svg';

const InputPassword = forwardRef(({ value, onChange, onBlur, id, className, errorMessage, ...other }, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={cs(styles.root, { [styles.error]: errorMessage }, className)}>
      <input
        name="password"
        placeholder="Enter password"
        className={styles.input}
        type={showPassword ? 'text' : 'password'}
        value={value}
        onBlur={onBlur}
        id={id}
        onChange={onChange}
        ref={ref}
        {...other}
      />
      <div className={styles.passwordIcon} onClick={() => setShowPassword(state => !state)}>
        {showPassword ? <EyeClosedIcon /> : <EyeIcon />}
      </div>
      {errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>}
    </div>
  );
});

export default InputPassword;
