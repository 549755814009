import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './CheckoutSuccess.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../expert/components/Loader';
import { me } from '../../actions/auth.actions';
import { useHistory } from 'react-router-dom';

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};

const CheckoutSuccess = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(state => state.auth.user);

  const [count, setCount] = useState(0);

  useInterval(() => {
    if (count < 5) {
      if (!user?.pricing_plan_id) {
        dispatch(me());
      } else {
        history.push('/');
      }
      setCount(count + 1);
    } else {
      history.push('/upgrade-plan');
    }
  }, 2500);

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <Loader />
      </div>
    </div>
  );
};

export default CheckoutSuccess;
