import React, { useState } from 'react';
import styles from './TabVisualAssets.module.scss';
import { ReactComponent as ShareIcon } from '../../../../../assets/img/icons/share.svg';
import ButtonCustom from '../../../../../expert/components/Buttons/ButtonCustom';
import VisualBlock from '../../VisualBlock';
import { validateImage } from '../../../../../shared/utils/helpers';

function TabVisualAssets({ visualElements, setVisualElements }) {
  const [isError, setIsError] = useState(false);
  const MAX_VISUAL_ASSETS = 10;

  const handleUploadVisualElements = event => {
    const files = [];
    for (let i = 0; i < event.target.files.length; i++) {
      files.push(validateImage(event.target.files[i]));
    }
    const elements = [];
    for (let i = 0; i < files.length; i++) {
      if (!files[i]) continue;
      const reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onloadend = () => {
        elements.push({ file: files[i], fileUrl: reader.result, description: '' });
      };
    }

    setTimeout(function () {
      const arr = [...visualElements, ...elements];
      if (arr.length > MAX_VISUAL_ASSETS) {
        arr.length = MAX_VISUAL_ASSETS;
        setIsError(true);
      }
      setVisualElements(arr);
    }, 100);
    setTimeout(() => {
      setIsError(false);
    }, 3000);
  };

  const setDescription = (value, fileUrl) => {
    const result = visualElements.map(element => {
      if (element.fileUrl == fileUrl) {
        return { ...element, description: value };
      }
      return element;
    });
    setVisualElements(result);
  };

  const setFileImage = (file, newFileUrl, fileUrl) => {
    const result = visualElements.map(element => {
      if (element.fileUrl == fileUrl) {
        return { ...element, file: file, fileUrl: newFileUrl };
      }
      return element;
    });
    setVisualElements(result);
  };

  const deleteVisualElement = fileUrl => {
    setVisualElements(visualElements.filter(element => element.fileUrl != fileUrl));
  };

  return (
    <>
      {visualElements.length > 0 && (
        <div className={styles.visualElementsWrapper}>
          {visualElements.map((visualElement, index) => (
            <div key={index + visualElement.fileUrl}>
              <VisualBlock
                imgUrl={visualElement.fileUrl}
                description={visualElement.description}
                setDescription={value => setDescription(value, visualElement.fileUrl)}
                setFileImage={(file, newFileUrl) => setFileImage(file, newFileUrl, visualElement.fileUrl)}
                deleteVisualElement={deleteVisualElement}
              />
            </div>
          ))}
        </div>
      )}
      {isError && <span className={styles.error}>Maximum number of visuals 10</span>}
      <ButtonCustom outline Icon={ShareIcon} className={styles.visualButton} disabled={visualElements.length >= 10}>
        Upload Visuals
        <input
          type="file"
          multiple="multiple"
          accept="image/png, image/jpeg, image/jpg"
          onChange={handleUploadVisualElements}
          className={styles.fileInput}
        />
      </ButtonCustom>
      <p className={styles.visualNotes}>
        Uploading visual content will help creators understand the type of content you are looking for your campaign. You can upload you own
        image or could choose an image from our stock library.
      </p>
    </>
  );
}

export default TabVisualAssets;
