import React from 'react';
import styles from './SimpleVideoPreview.module.scss';
import cs from 'classnames';
import { ReactComponent as EmptyImage } from '../../../assets/img/icons/not_uploaded_icon.svg';

const SimpleVideoPreview = ({ preview, callToAction, primaryText, isPlaceholder, orientation = 'portrait' }) => {
  return (
    <div className={styles.root}>
      <div className={cs(styles.videoContainer, styles['orientation-' + orientation])}>
        {preview ? (
          <>{isPlaceholder ? <img src={preview} alt="Video is converting" /> : <video src={preview} controls autoPlay loop muted />}</>
        ) : (
          <div className={styles.emptyImage}>
            <EmptyImage />
            <span>No video yet</span>
          </div>
        )}
      </div>
      <div className={styles.textContainer}>
        <div className={styles.text}>{primaryText}</div>
        {callToAction && (
          <div className={styles.callToAction}>
            <span>{callToAction}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SimpleVideoPreview;
