import {
  getTemplatesSets,
  getCategories,
  getNewTemplatesSets,
  getFavoriteTemplatesSets,
  getPopularTemplatesSets,
  markAsFavorites,
  deleteAsFavorites
} from '../services/templatesets';

export const TEMPLATE_SETS_TYPES = {
  GET_TEMPLATE_SETS: {
    REQUEST: 'GET_TEMPLATE_SETS_REQUEST',
    SUCCESS: 'GET_TEMPLATE_SETS_SUCCESS',
    FAILURE: 'GET_TEMPLATE_SETS_FAILURE',
  },
  GET_NEW_TEMPLATE_SETS: {
    REQUEST: 'GET_NEW_TEMPLATE_SETS_REQUEST',
    SUCCESS: 'GET_NEW_TEMPLATE_SETS_SUCCESS',
    FAILURE: 'GET_NEW_TEMPLATE_SETS_FAILURE',
  },
  GET_POPULAR_TEMPLATE_SETS: {
    REQUEST: 'GET_POPULAR_TEMPLATE_SETS_REQUEST',
    SUCCESS: 'GET_POPULAR_TEMPLATE_SETS_SUCCESS',
    FAILURE: 'GET_POPULAR_TEMPLATE_SETS_FAILURE',
  },
  GET_FAVORITE_TEMPLATE_SETS: {
    REQUEST: 'GET_FAVORITE_TEMPLATE_SETS_REQUEST',
    SUCCESS: 'GET_FAVORITE_TEMPLATE_SETS_SUCCESS',
    FAILURE: 'GET_FAVORITE_TEMPLATE_SETS_FAILURE',
  },
  MARK_AS_FAVORITE: {
    REQUEST: 'MARK_AS_FAVORITE_REQUEST',
    SUCCESS: 'MARK_AS_FAVORITE_SUCCESS',
    FAILURE: 'MARK_AS_FAVORITE_FAILURE',
  },
  DELETE_AS_FAVORITE: {
    REQUEST: 'DELETE_AS_FAVORITE_REQUEST',
    SUCCESS: 'DELETE_AS_FAVORITE_SUCCESS',
    FAILURE: 'DELETE_AS_FAVORITE_FAILURE',
  },
  SET_TEMPLATE_SETS_CATEGORIES: 'SET_TEMPLATE_SETS_CATEGORIES'
}

export const getTemplateSets = (orgId, page, pageSize, categories, searchFilter, orderBy, orderType) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TEMPLATE_SETS_TYPES.GET_TEMPLATE_SETS.REQUEST,
    });

    const currentOrg = getState().auth.currentOrganization;
    const items = await getTemplatesSets(
      orgId || currentOrg.organization_id,
      page || 1,
      pageSize || 20,
      categories,
      searchFilter,
      orderBy,
      orderType
    );

    dispatch({
      type: TEMPLATE_SETS_TYPES.GET_TEMPLATE_SETS.SUCCESS,
      payload: items.data,
      totalCount: items.total_count,
    });
    return items.data;
  } catch (error) {
    dispatch({
      type: TEMPLATE_SETS_TYPES.GET_TEMPLATE_SETS.FAILURE,
      payload: error,
    });
  }
};

export const getNewTemplateSets = (orgId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TEMPLATE_SETS_TYPES.GET_NEW_TEMPLATE_SETS.REQUEST,
    });

    const currentOrg = getState().auth.currentOrganization;
    const items = await getNewTemplatesSets(orgId || currentOrg.organization_id);

    dispatch({
      type: TEMPLATE_SETS_TYPES.GET_NEW_TEMPLATE_SETS.SUCCESS,
      payload: items.data,
      totalCount: items.total_count,
    });
    return items.data;
  } catch (error) {
    dispatch({
      type: TEMPLATE_SETS_TYPES.GET_NEW_TEMPLATE_SETS.FAILURE,
      payload: error,
    });
  }
};

export const getPopularTemplateSets = (orgId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TEMPLATE_SETS_TYPES.GET_POPULAR_TEMPLATE_SETS.REQUEST,
    });

    const currentOrg = getState().auth.currentOrganization;
    const items = await getPopularTemplatesSets(orgId || currentOrg.organization_id);

    dispatch({
      type: TEMPLATE_SETS_TYPES.GET_POPULAR_TEMPLATE_SETS.SUCCESS,
      payload: items.data,
      totalCount: items.total_count,
    });
    return items.data;
  } catch (error) {
    dispatch({
      type: TEMPLATE_SETS_TYPES.GET_POPULAR_TEMPLATE_SETS.FAILURE,
      payload: error,
    });
  }
};

export const getFavoriteTemplateSets = (orgId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TEMPLATE_SETS_TYPES.GET_FAVORITE_TEMPLATE_SETS.REQUEST,
    });

    const currentOrg = getState().auth.currentOrganization;
    const items = await getFavoriteTemplatesSets(orgId || currentOrg.organization_id);

    dispatch({
      type: TEMPLATE_SETS_TYPES.GET_FAVORITE_TEMPLATE_SETS.SUCCESS,
      payload: items.data,
      totalCount: items.total_count,
    });
    return items.data;
  } catch (error) {
    dispatch({
      type: TEMPLATE_SETS_TYPES.GET_FAVORITE_TEMPLATE_SETS.FAILURE,
      payload: error,
    });
  }
};

export const markAsFavorite = (orgId, itemId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TEMPLATE_SETS_TYPES.MARK_AS_FAVORITE.REQUEST,
    });

    const currentOrg = getState().auth.currentOrganization;
    const items = await markAsFavorites(orgId || currentOrg.organization_id, itemId);

    dispatch({
      type: TEMPLATE_SETS_TYPES.MARK_AS_FAVORITE.SUCCESS,
      payload: items.data,
    });
    return items.data;
  } catch (error) {
    dispatch({
      type: TEMPLATE_SETS_TYPES.MARK_AS_FAVORITE.FAILURE,
      payload: error,
    });
  }
};

export const deleteAsFavorite = (orgId, itemId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TEMPLATE_SETS_TYPES.DELETE_AS_FAVORITE.REQUEST,
    });

    const currentOrg = getState().auth.currentOrganization;
    const items = await deleteAsFavorites(orgId || currentOrg.organization_id, [itemId]);

    dispatch({
      type: TEMPLATE_SETS_TYPES.DELETE_AS_FAVORITE.SUCCESS,
      payload: items.data,
    });
    return items.data;
  } catch (error) {
    dispatch({
      type: TEMPLATE_SETS_TYPES.DELETE_AS_FAVORITE.FAILURE,
      payload: error,
    });
  }
};

export const getTemplateSetCategories = () => async dispatch => {
  try {
    const items = await getCategories();

    dispatch({
      type: TEMPLATE_SETS_TYPES.SET_TEMPLATE_SETS_CATEGORIES,
      payload: items.data,
    });

  } catch {}
};
