import * as paymentServices from '../services/payment';

export const PAYMENT_TYPES = {
  GET_ALL_PLANS: {
    REQUEST: 'GET_ALL_PLANS_REQUEST',
    SUCCESS: 'GET_ALL_PLANS_SUCCESS',
    FAILURE: 'GET_ALL_PLANS_FAILURE',
  },
  CLEAR_FAILURE: 'CLEAR_FAILURE',
};

export const getAllPlans = () => async dispatch => {
  try {
    dispatch({
      type: PAYMENT_TYPES.GET_ALL_PLANS.REQUEST,
    });

    const res = await paymentServices.getAllPlans();

    dispatch({
      type: PAYMENT_TYPES.GET_ALL_PLANS.SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: PAYMENT_TYPES.GET_ALL_PLANS.FAILURE,
      payload: error?.response?.data,
    });
  }
};

export const clearFailure = () => async dispatch => {
  try {
    dispatch({
      type: PAYMENT_TYPES.CLEAR_FAILURE,
    });
  } catch (e) {}
};
