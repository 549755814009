import React, { useState } from 'react';
import ConfirmDeleteVisualAssetModal from '../../../../components/Modals/ConfirmDeleteVisualAssetModal';
import { DELIVERABLE_ASSET_STATUSES } from '../constants';

const useVisualCardHandlers = (onCreate, onUpdate, onDelete, isDisabledSection, asset) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const handleCreate = ({ file, fileUrl }) => {
    if (isDisabledSection) return;
    const isVideo = /^video/g.test(file.type);
    const isMov = file.type === 'video/quicktime';
    onCreate({
      id: 'new' + Math.random(),
      location: fileUrl,
      file: file,
      mime_type: file.type,
      caption: '',
      status: isVideo ? DELIVERABLE_ASSET_STATUSES.PROCESSING : DELIVERABLE_ASSET_STATUSES.ACTIVE,
      isImagePreview: !isVideo || isMov,
    });
  };

  const handleUpdate = captionValue => {
    if (isDisabledSection) return;
    if (asset) {
      onUpdate({
        ...asset,
        caption: captionValue,
      });
    }
  };

  const handleDelete = () => {
    if (isDisabledSection) return;
    setOpenConfirmModal(true);
  };

  const onSubmitDelete = () => {
    setOpenConfirmModal(false);
    onDelete(asset);
  };

  const isImageDisplay = () => {
    const type = asset?.mime_type?.match(/^\w*/g)[0];
    return type === 'image' || asset?.isImagePreview || (type === 'video' && !!asset?.thumbnail_location);
  };

  return {
    deleteConfirmModal: (
      <ConfirmDeleteVisualAssetModal open={openConfirmModal} onSubmit={onSubmitDelete} onClose={() => setOpenConfirmModal(false)} />
    ),
    handleCreate,
    handleUpdate,
    handleDelete,
    isImageDisplay,
  };
};

export default useVisualCardHandlers;
