import React from 'react';

const CoolIcon = () => {
  return (
    <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.5 5.27563L0.5 0.275635L0.5 10.2756L5.5 5.27563Z" fill="#2E3A59" />
    </svg>
  );
};

export default CoolIcon;
